import React from 'react'

const Delete = (props) => {
    return (
        <svg
      xmlns="http://www.w3.org/2000/svg"
      width={13.784}
      height={18.226}
      viewBox="0 0 16.753 17.038"
      {...props}
    >
      <path
        d="M14.084.125H2.669A2.544 2.544 0 00.125 2.669v1.3a.509.509 0 00.51.506h.621v11.929a.509.509 0 00.509.509H14.99a.509.509 0 00.509-.509V4.449h.621a.509.509 0 00.509-.509V2.669A2.544 2.544 0 0014.084.125zm.4 15.77H2.272V4.449h12.209zm1.129-12.464H1.142v-.794a1.526 1.526 0 011.526-1.526h11.416a1.526 1.526 0 011.526 1.526zM5.635 7.575h5.474a.509.509 0 00.509-.509V5.775a.509.509 0 00-.509-.509H5.635a.509.509 0 00-.509.509v1.323a.509.509 0 00.509.509zm.509-1.323h4.461v.305H6.148z"
        fill="#ef476f"
        stroke="#ef476f"
        strokeWidth={0.25}
      />
    </svg>
    )
}

export default Delete
