import { createSlice } from '@reduxjs/toolkit'

const stateSlice = createSlice({
    name: 'webStreet/state',
    initialState: {
        sideBarExpand: true,
        isSaving:false,
        formCurrentIndex:0,
        editBrokerProfile:false,
    },
    reducers: {
        
        toggleSidebar: (state, action) => {
            state.sideBarExpand = action.payload
        },
        toggleIsSaving:(state,action)=>{
            state.isSaving = action.payload
        },
        toggleEditProfile:(state,action)=>{
            state.editBrokerProfile = action.payload
        },
        updateformCurrentIndex:(state,action)=>{
            state.formCurrentIndex = action.payload
        }
    
        
    },
})

export const {
    toggleSidebar,updateformCurrentIndex,toggleIsSaving,toggleEditProfile
} = stateSlice.actions

export default stateSlice.reducer
