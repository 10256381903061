import React from 'react'

const Loading = (props) => {
    return (
        <svg
      xmlns="http://www.w3.org/2000/svg"
     
      width={props.size ? props.size[0] :14}
      height={props.size ? props.size[1] :14}
      viewBox="0 0 20.854 21.879"
      {...props}
    >
      
      <g>
        <circle
          fill="#ef476f"
          cx={2.559}
          cy={2.559}
          r={2.559}
          transform="translate(8.38)"
        />
        <circle
          fill="#ef476f"
          cx={1.535}
          cy={1.535}
          r={1.535}
          transform="translate(9.404 18.808)"
        />
        <circle
          fill="#ef476f"
          cx={2.303}
          cy={2.303}
          r={2.303}
          transform="translate(2.348 2.86)"
        />
        <circle
          fill="#ef476f"
          cx={1.28}
          cy={1.28}
          r={1.28}
          transform="translate(15.948 16.459)"
        />
        <circle
          fill="#ef476f"
          cx={2.047}
          cy={2.047}
          r={2.047}
          transform="translate(0 9.405)"
        />
        <circle
          fill="#ef476f"
          cx={1.023}
          cy={1.023}
          r={1.023}
          transform="translate(18.808 10.428)"
        />
        <path
          fill="#ef476f"
          d="M3.385 16.473a1.791 1.791 0 102.532 0 1.79 1.79 0 00-2.532 0z"
        />
        <circle
          fill="#ef476f"
          cx={0.767}
          cy={0.767}
          r={0.767}
          transform="translate(16.459 4.396)"
        />
      </g>
    </svg>
    )
}

export default Loading
