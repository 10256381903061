
import React, { useContext } from "react";
import PropTypes from "prop-types";
import Header from "components/Header";
import { Layout,Menu } from "antd";
import OpenHeader from "components/OpenHeader";
import { AuthContext } from "app/AuthProvider";
import SearchLeft from "components/SearchLeft";
import SidebarRight from "components/SidebarRight";
import { UploadOutlined, UserOutlined, VideoCameraOutlined } from '@ant-design/icons';
// import { Layout, Menu } from 'antd';
const { Sider, Content, } = Layout;
const TempLayout = ({ children, noNavbar, noFooter }) => {
  const { authUser } = useContext(AuthContext);
  // console.log(authUser);
  return (
    <>
     <Layout>
    <Sider
      breakpoint="lg"
      collapsedWidth="0"
      className="bg-white px-0"
      onBreakpoint={(broken) => {
        console.log(broken);
      }}
      onCollapse={(collapsed, type) => {
        console.log(collapsed, type);
      }}
      width="300"

    >
         <SearchLeft {...children.props} />
     
    </Sider>
    <Layout>
    {!authUser ? (
            <OpenHeader noPadd={true}></OpenHeader>
          ) : (
            <Header noPadd={true}></Header>
          )}
      <Content
        style={{
          margin: '24px 16px 0',
          marginTop:"70px"
        }}
      >
        <div
          className="site-layout-background"
          style={{
            padding: 12,
            minHeight: 360,
          }}
        >
           <Content className="p-0 bg-hg">{children}</Content>
        </div>
      </Content>
     
    </Layout>
  </Layout>
    </>
  );
};

TempLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool,
};

TempLayout.defaultProps = {
  noNavbar: false,
  noFooter: false,
};

export default TempLayout;
