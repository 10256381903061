import { Row, Col, Typography, Checkbox, Input, Button, Spin,notification } from "antd";
import { Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { InfoCircle, XCircle } from "react-bootstrap-icons";
import Tags from "container/common/Tags";
import SucessChecked from "res/icons/SucessChecked";
import _ from "lodash";
import ApiService from "services/ApiService";

const { Paragraph, Text } = Typography;

const SettingsDefault = ({ user, activeWorkSpace }) => {
  const [viewloading, setViewLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const [activeTags, setActiveTags] = useState([]);
  const [defaultOptions, setDefaultOptions] = useState({
    enableUtm: false,
    hastagsOn: true,
    hastagSuggestion: true,
    metakeywords: true,
    quotePunctuation: true,
    quoteBubbles: true,
    utmSource: "",
    utmCampaign: "",
    defaultTags: [],
    usedShortner: "bitly",
  });
  useEffect(() => {
    getDetails()
    // setDefaultOptions({ ...defaultOptions, defaultTags: _.map(activeTags,"value") });
    return () => {
      
    }
  }, [])
  const getDetails = async ()=>{
   
    const q = activeWorkSpace["wid"];
    const details = await ApiService.GetApiCalls(`/creator/workspace/${q}`,"",true)
    if (details[0] && details[1]["IsSuccessful"]) {
      setDefaultOptions({ ...defaultOptions, ...details[1]["info"] });
      setActiveTags(details[1]["info"]["defaultTags"])
    }
    setViewLoading(false)
  }
  const updateTags = (res = {}) => {
    const tags = res["t"] ? res["t"] : activeTags;
    console.log(tags);
    setDefaultOptions({ ...defaultOptions, defaultTags: tags });
  };
  const saveCampaignDetails = async() => {
    setLoading(true);
    // const currenWorkspace = activeWorkSpace
    const q = activeWorkSpace["wid"];
    const data = {
      campaignDefaults:  defaultOptions ,
      wid: q,
    };
    const saved = await ApiService.PostApiCalls(`/creator/workspace/update/${q}`,{workSpace:data},true)
    if (saved[0] && !saved[1]["IsSuccessful"]) {
                   
      notification["error"]({
        message: saved[1]["ErrorMessage"],
        description:""
      });
  }
  else{
      
      notification["success"]({
        message: saved[1]["FriendlyMessage"],
        description:""
      });
    
  }
    setLoading(false);
  };
  if(viewloading){
    return (
      <>
      <Col span={24} className="py-0 px-0 mb-0">
        <div className="bg-white p-3 px-3 flex space-x-3 items-center justify-between">
          <span className="font-bold">Drip Campaign Settings</span>
          <Button
            onClick={() => saveCampaignDetails()}
            type="primary"
            className=" rounded  float-right mr-3"
            loading={loading}
          >
            Save Details
          </Button>
        </div>
      </Col>
      <div className="pb-2 bg-gray-100"></div>
      <Col span={24} className="py-0 px-0 mb-0 h-screen flex items-center justify-center">
      <Spin />
      </Col>
      </>
    )
  }
  return (
    <>
      <Col span={24} className="py-0 px-0 mb-0">
        <div className="bg-white p-3 px-3 flex space-x-3 items-center justify-between">
          <span className="font-bold">Drip Campaign Settings</span>
          <Button
            onClick={() => saveCampaignDetails()}
            type="primary"
            className=" rounded  float-right mr-3"
            loading={loading}
          >
            Save Details
          </Button>
        </div>
      </Col>
      <div className="pb-2 bg-gray-100"></div>
      <Col span={24} className="">
        <Row className="p-2">
          <Col span={24} className="p-2">
            <Paragraph className="text-sm text-hs-dark">
            "Hash Saga" will generate your campaigns based on a few predefined rules right out of the box. Use the form below to personalise these. Please contact us if you have any issues or believe we should give you more control over a certain aspect of your campaign's content.
            </Paragraph>
          </Col>
          <Col className="px-0 pr-4 mt-4 " span={24}>
            <div className="campaign-default-options px-4 py-2 card w-100 shadow-sm">
              <Row className="mx-0">
                <Col className="px-0 fz-14 fw-600 flex items-center" span={24}>
                  <span>Default Hashtags</span>
                  <OverlayTrigger
                    key={0}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-0`}>
                        If you'd like to include default hashtags, alongside the
                        automatic suggestions for each campaign, then enter them
                        here. If they aren't relevant for an individual
                        campaign, you'll have the opportunity to remove them
                        when reviewing it.
                      </Tooltip>
                    }
                  >
                    <InfoCircle className="ml-2" size={14} color={"#646464"} />
                  </OverlayTrigger>
                </Col>
                <Col className="px-0 mt-2 flex items-center" span={24}>
                  <Tags
                    tags={activeTags}
                    update={updateTags}
                    callback={setActiveTags}
                    forceUpdate={true}
                  />
                </Col>
                <Col className="px-0 mt-4" span={24}>
                  <span className="mb-2 float-left">
                    <Checkbox
                      defaultChecked
                      className="mr-2"
                      onChange={(e) =>
                        setDefaultOptions({
                          ...defaultOptions,
                          [e.target.name]: e.target.checked,
                        })
                      }
                      checked={defaultOptions["hastagsOn"]}
                      name="hastagsOn"
                    >
                      Use hashtags ?
                    </Checkbox>
                  </span>
                  <span className="mb-2 float-left ml-4">
                    <Checkbox
                      defaultChecked
                      className="mx-2"
                      onChange={(e) =>
                        setDefaultOptions({
                          ...defaultOptions,
                          [e.target.name]: e.target.checked,
                        })
                      }
                      checked={defaultOptions["hastagSuggestion"]}
                      name="hastagSuggestion"
                    >
                      Enable hashtags suggestions ?
                    </Checkbox>
                  </span>
                </Col>
                <Col className="px-0 mt-4" span={24}>
                  <span className="mb-2 float-left">
                    <Checkbox
                      checked={defaultOptions["metakeywords"]}
                      onChange={(e) =>
                        setDefaultOptions({
                          ...defaultOptions,
                          [e.target.name]: e.target.checked,
                        })
                      }
                      name="metakeywords"
                      defaultChecked
                      className="mr-2"
                    >
                      Look at META keywords and description (if they exist) to
                      generate hashtags ?
                    </Checkbox>
                  </span>
                </Col>
                <Col className="px-0 mt-4" span={24}>
                  <span className="mb-2 float-left">
                    <Checkbox
                      name="quotePunctuation"
                      onChange={(e) =>
                        setDefaultOptions({
                          ...defaultOptions,
                          [e.target.name]: e.target.checked,
                        })
                      }
                      checked={defaultOptions["quotePunctuation"]}
                      defaultChecked
                      className="mr-2"
                    >
                      Allow quote truncation ?
                    </Checkbox>
                  </span>
                  <span className="mb-2 float-left ml-4">
                    <Checkbox
                      name="quoteBubbles"
                      onChange={(e) =>
                        setDefaultOptions({
                          ...defaultOptions,
                          [e.target.name]: e.target.checked,
                        })
                      }
                      checked={defaultOptions["quoteBubbles"]}
                      defaultChecked
                      className="mx-2"
                    >
                      Generate quote bubbles ?
                    </Checkbox>
                  </span>
                </Col>
                <Col className="px-0 mt-2" span={24}></Col>
              </Row>
            </div>
          </Col>

          <Col className="px-0 mt-4 pr-4" span={24}>
            <div className="campaign-default-options px-4 py-2 card w-100 shadow-sm">
              {" "}
              <Row className="mx-0">
                <Col className="px-0 fz-14 fw-600" span={24}>
                  Which URL shortener would you like to use ?
                </Col>
                <Col className="px-0 fz-12  py-2 fw-600" span={24}>
                  If you'd prefer to use your own URL Shortener (or none at
                  all), then please choose one from the list below.{" "}
                  <b>Please note</b> that advanced analytics are only available
                  when using our own <b>hs-ga.com</b> shortener
                </Col>

                <Col className="px-0 mt-4 flex " span={24}>
                  <div
                    className="shortener-block"
                    onClick={() =>
                      setDefaultOptions({
                        ...defaultOptions,
                        usedShortner: "hsapp",
                      })
                    }
                  >
                    <div className="shortener-container color-blue fw-600 fz-16">
                      <span className="font-bold text-lg">hs-ga.com</span>
                    </div>
                    {defaultOptions["usedShortner"] === "hsapp" ? (
                      <span className="items-center space-x-1">
                        <div>Using hs-ga.com</div> <SucessChecked />
                      </span>
                    ) : (
                      <span>Use hs-ga.com </span>
                    )}
                  </div>
                  <div
                    className="shortener-block"
                    onClick={() =>
                      setDefaultOptions({
                        ...defaultOptions,
                        usedShortner: "bitly",
                      })
                    }
                  >
                    <div className="shortener-container">
                      <span className="font-bold text-lg">bit.ly</span>
                    </div>
                    {defaultOptions["usedShortner"] === "bitly" ? (
                      <span className="items-center space-x-1">
                        <div>Using bit.ly</div> <SucessChecked />
                      </span>
                    ) : (
                      <span>Use bit.ly </span>
                    )}
                  </div>
                  <div
                    className="shortener-block"
                    onClick={() =>
                      setDefaultOptions({
                        ...defaultOptions,
                        usedShortner: "none",
                      })
                    }
                  >
                    <div className="shortener-container">
                      <XCircle color={"#1694E3"} size={18} />
                    </div>

                    {defaultOptions["usedShortner"] === "none" ? (
                      <span className="items-center space-x-1">
                        <div>Using none</div> <SucessChecked />
                      </span>
                    ) : (
                      <span>Use none </span>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col className="px-0 mt-4 pr-4" span={24}>
            <div className="campaign-default-options px-4 py-2 card w-100 shadow-sm">
              {" "}
              <Row className="mx-0 flex flex-col">
                <Col className="px-0 mt-4" span={24}>
                  <Checkbox
                    checked={defaultOptions["enableUtm"]}
                    name="enableUtm"
                    onChange={(e) =>
                      setDefaultOptions({
                        ...defaultOptions,
                        [e.target.name]: e.target.checked,
                      })
                    }
                    className="fz-14 fw-600 all-header-dark"
                  >
                    Activate UTM campaign variables?
                  </Checkbox>
                </Col>
                <Col className="px-0 mt-4  pr-4  d-flex flex-column" span={12}>
                  <span className="fz-14 fw-600 all-header-dark">
                    UTM sources
                  </span>
                  <Input
                    disabled={!defaultOptions["enableUtm"]}
                    value={defaultOptions["utmSource"]}
                    name="utmSource"
                    onChange={(e) =>
                      setDefaultOptions({
                        ...defaultOptions,
                        [e.target.name]: e.target.value,
                      })
                    }
                    className="utm-input mt-2"
                    placeholder="Set automatically to back the social profile that generated the click"
                  />
                </Col>
                <Col className="px-0 mt-4 pr-4 d-flex flex-column" span={12}>
                  <span className="fz-14 fw-600 all-header-dark">
                    UTM campaign
                  </span>
                  <Input
                    disabled={!defaultOptions["enableUtm"]}
                    value={defaultOptions["utmCampaign"]}
                    name="utmCampaign"
                    className="utm-input mt-2"
                    onChange={(e) =>
                      setDefaultOptions({
                        ...defaultOptions,
                        [e.target.name]: e.target.value,
                      })
                    }
                    placeholder="Set automatically based on the campaign/ blog post title"
                  />
                </Col>
                <Col className="px-0 my-4" span={24}></Col>
              </Row>
            </div>
          </Col>
          <Col className="px-4 my-4 " span={24}>
            <Button
              onClick={() => saveCampaignDetails()}
              type="primary"
              className=" rounded mx-2 float-right"
              loading={loading}
            >
              Save Details
            </Button>
            <Button className="border-0 mx-2 float-right">Cancel</Button>
          </Col>
          <Col className="px-0 my-4" span={24}></Col>
        </Row>
      </Col>
    </>
  );
};

export default SettingsDefault;
