import { Button, Divider, List, Steps,Tag,Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import ListingBasicInfo from './ListingBasicInfo';
import ListingPropertyDetails from './ListingPropertyDetails';
import ListingPricingDetails from './ListingPricingDetails';
import { useDispatch, useSelector } from 'react-redux';
import { updateformCurrentIndex } from '../store/stateSlice';
import {  useNavigate, } from "react-router-dom";
import ApiService from 'services/ApiService';
import moment from 'moment'
import Utils from 'helper/Utils'
import ListingRequets from './ListingRequets';

const { Step } = Steps;
const NewListingForm = ({authUser,alreadyBroker}) => {
  
  // let history = useHistory();
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalDetails, setmodalDetails] = useState(null)
  const [sending, setsending] = useState(false)
  const [listingDone, setlistingDone] = useState(false)
  const currentStep =  useSelector((state) => state.webStreet.state.formCurrentIndex);
  const updateProfile =  useSelector((state) => state.webStreet.state.editBrokerProfile)
  const [userListings, setuserListings] = useState([])
  const [loading, setloading] = useState(true)
  const showModal = (item) => {
    setmodalDetails(item)
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setmodalDetails(null)
  };
  useEffect(() => {
    
    getUserListings();
    return () => {
      
    }
  }, [])
  const getUserListings = async()=>{
    const result = await ApiService.PostApiCalls("/street/list/i",{filters:{seller:authUser?true:false},pageIndex:1,pageSize:20},authUser?true:false)
    if(result[0]){
      setuserListings(result[1]["data"])
      setloading(false)
    }
  }
  const setcurrentStep = (index) =>{
    if(index === 3){
      setlistingDone(true)
      dispatch(updateformCurrentIndex(0))
    }
    else{
      dispatch(updateformCurrentIndex(index))
    }
    
  }
  const listnew = ()=>{
    setlistingDone(false)
    dispatch(updateformCurrentIndex(0))
  }
  if(loading){
    return (null)
  }
  return (
    <>

    {(userListings.length === 0 || updateProfile) && <div>
    {listingDone?<div className='px-3 flex items-center justify-center flex-col py-6 space-y-4'>
      <div className='h-10'></div>
      <span className='font-semibold'>Your listing has been submitted for approval, once approved it will be lsited for public</span>
      <span className='flex space-x-4'>
        <Button  onClick={() => navigate(-1)}className='rounded-md'>View Listing</Button>
        <Button onClick={listnew} className={`px-3 brd-4 fw-700 mx-2 rounded-lg text-white bg-hs-pink  hover:border-hs-pink `}>List Another</Button>
        
        </span>

    </div>
      
    :<div className='p-3'>
      <Steps current={currentStep}>
      <Step title="Basic Info" />
      <Step title="Property Details" />
      <Step title="Pricing Details" />
      {/* <Step title="Property Pictures" /> */}
  </Steps>
  <Divider />
  { currentStep === 0 && <ListingBasicInfo next={setcurrentStep}/>}
  { currentStep === 1 && <ListingPropertyDetails next={setcurrentStep}/>}
  { currentStep === 2 && <ListingPricingDetails next={setcurrentStep}/>}
  {/* { currentStep === 3 && <ListingPictures next={setcurrentStep}/>} */}
    </div>}
    </div>}
    {(userListings.length > 0 && !updateProfile) && <div>
      <div className="px-4 pb-4 mt-2 ">
      <List
    itemLayout="vertical"
    size="large"
    pagination={{
      onChange: (page) => {
        console.log(page);
      },
      pageSize: 20,
    }}
    dataSource={userListings}
    footer={
      <div>
        More available
      </div>
    }
    renderItem={(item) => (
      <List.Item
        key={item.title}
        className='bg-white rounded-md mb-3 p-3'
        onClick={(e)=>{e.preventDefault();navigate("/re/streets/property/"+item._id)}}
      >
       <div className='flex space-x-3 flex-row w-100'>
       <img
            width={272}
            alt="logo" className='rounded-lg'
            src={item["listingImages"][0]}
          />
          <div className='flex flex-col space-y-2 flex-1'>
          <span className='font-semibold text-lg'>₹{Utils.getAmountInFormat(parseInt(item.price))} </span>
          <span><span className='font-semibold'>{`${item.BHK}`}</span>{` for ${(item.listingFor).toLowerCase()} in ${item.Locality}`}</span>
           <div className='flex flex-col space-y-2'>
            <span className='text-hs-pink-dark'>Property Highlights</span>
            <span>{item.furnishingList.split(",").map((f,i) => <Tag key={i} className='rounded' color="purple">{f}</Tag>)}</span>
            </div> 
            <div className='flex items-end flex-1 justify-between'>
              <span className='flex flex-row space-x-1'>
            
              </span>
              
              <Button onClick={(e)=>{ e.stopPropagation(); showModal(item)}} className={`px-3 brd-4 fw-700 mx-2 rounded-lg text-white bg-hs-pink  hover:border-hs-pink `}>View Requests</Button>
            </div>
            </div>
       </div>
         
       
      </List.Item>
       )}
       />
       </div>
      </div>
    }
    {/* Modal Contact */}
   {modalDetails && <Modal   width={800} bodyStyle={{padding:0}} footer={null} title="Requests for property" visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <div>
        <div className='p-3 max-h-96 overflow-y-auto'>
        <ListingRequets id={modalDetails._id} callback={handleCancel} />
        </div>
        <div className='flex p-3 pb-0 border-t-2 items-center justify-between space-x-3'>

        <span className='text-purple-700'>{modalDetails.ListingType === "guaranteed" && modalDetails.guaranteeType === "Open"?"":"Once you accept highest bid offered, a payment request will be sent to broker."}</span>
        <Button onClick={handleCancel} type="primary"  size='large'  className={`px-3 brd-4 fw-700 mx-2 rounded-lg text-white bg-hs-pink  hover:border-hs-pink `}>
          Okay
        </Button>
       
      
      </div>
        </div>
      </Modal>}
    </>
  )
}

export default NewListingForm