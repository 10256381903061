import React,{useContext,useState,useEffect} from 'react'
import {Clock,Trash,ThreeDotsVertical,Facebook,Twitter,Linkedin,Instagram,People} from "react-bootstrap-icons"
import { Dropdown,Menu,Select ,Button  } from 'antd';
// import Loading from "res/icons/Loading"
import ImageUpload from "components/Reusable/ImageUpload"
import {Review} from "res/icons/Review"
import Regenerate from "res/icons/Regenerate"
import Delete from "res/icons/Delete"
import _ from 'lodash';
import Utils from "helper/Utils"
// import OriginalSource from "res/icons/OriginalSource" profile={Utils.getWorkSpaceSocialProfiles(userDetails["socialprofiles"])} 
import { AuthContext } from "app/AuthProvider";
const { Option } = Select;
const PostCardHeader = ({post,updateContent,selectedProfiles,callback,hideTools,postLink,textlimit,textProfileId,textLength}) => {
  
    const {authUser,activeWorkSpace} = useContext(AuthContext);
    const [type,setType] = useState("All Profiles");
    const [btIcon,setIcon] = useState("All")
    const [textlimiterror,settextlimi] = useState(textlimit?textlimit:0)
    const textLens = {
      "Facebook":999,
      "Twitter": 280,
      "Linkedin":1299
    }
    const socilIcons = {
      "Facebook":<Facebook color="#4267B2"/>,
      "Twitter": <Twitter color="#00acee"/>,
      "Instagram": <Instagram color={"#fbad50"} />,
      "Linkedin":<Linkedin color={"#0077b5"} />,
      "All":<People />
    }
    
    useEffect(() => {
      // console.log(textlimit);
      settextlimi(textlimit)
      
      
    }, [post,textlimit,post["tmpqoute"]],textLength)
    
   
    const ImageUploadCallback = (image) =>{
      updateContent("changeImage",image)
    }
    const setProfileType = (profile) =>{      
     
     
     
      const selction = _.filter(authUser["socialchannels"], function (time,i) {
        return time["id"] === profile;
      });
      if(selction.length === 0){
        setType("All Profiles");
        callback(999,"all")
        setIcon("All")
      }
      else{
        setType(selction[0]["name"]);
        const source = selction[0]["source"];
        const length = textLens[source];
        setIcon(source)
        callback(length,selction[0]["id"])
       
      }      
    
    }
    const socialMenu = (
      <Menu className="p-2">
     
          {
          Utils.getWorkSpaceSocialProfiles(authUser,activeWorkSpace["wid"]).map((p,key) =>(
            selectedProfiles.includes(p.id) && <Menu.Item key={key} onClick={() => setProfileType(p.id)}  className="p-2 d-flex justify-content-between align-items-center brd-4"  >
            <div className="dropdownimage image flex items-center  ">
            {socilIcons[p["source"]]}
        <span className="ml-2  ">{p["name"]}</span></div>
        {/* <span className="ml-3 brd-4 p-2 card-bg float-right "><Trash  className="color-red" size={14} /></span> */}
           </Menu.Item>
          ))
        }
        <Menu.Item className="p-2  brd-4 card-bg" onClick={() => setProfileType("All")}  >
        <div className="dropdownimage image flex items-center ">
            <People />
            <span className="ml-2" >Edit all profiles</span>     </div>       
            
          </Menu.Item>
          </Menu>
    )
    const menu = (
          <Menu>
          <Menu.Item className="p-2 flex items-center "  onClick={() =>updateContent("useimage")}>
             <Review size={[18,19]} />
            <span className="ml-3" >Use Image</span>
          </Menu.Item>
          <Menu.Item className="p-2"  onClick={() =>updateContent("speechbubble")}>
             <Review size={[18,19]} />
            <span className="ml-3" >Use Quote Image</span>
          </Menu.Item>
          <Menu.Item className="p-2"  onClick={() =>updateContent("hideImage")}>
             <Review size={[18,19]} />
            <span className="ml-3" >Dont use image at all</span>
          </Menu.Item>
          <Menu.Item className="p-2">
          <ImageUpload multipleImages={false} campaign={post.day} showUploadList={false} callback={ImageUploadCallback} reset={true} list={[]} limit={10} listType="none"
        view={<div className="flex items-center"><Regenerate size={[18,19]} /><span className="ml-3" >Upload another one</span></div> } 
        viewUploading={<div className="flex items-center"><Regenerate size={[18,19]} /><span className="ml-3" >Upload another one</span></div> }

        /></Menu.Item>
          
          <Menu.Divider></Menu.Divider>
          <Menu.Item className="p-2" onClick={() =>updateContent("delete")}>
            
            <Delete size={[18,19]} />
            <span className="ml-3" >Delete</span>             
            
          </Menu.Item>
          </Menu>
      );
    return (
        <div className="d-flex flex-grow-1 align-items-center justify-content-between flex-wrap pl-4 pt-4 pr-4 pb-0">
        <span className="header-title">{"Day " +post["day"]}
        
        {hideTools?"":<span className="p-2 ml-3 ">{textlimiterror < 0 && <span className="p-1 px-2 rounded-md bg-red-400 fz-12  text-white">{textlimiterror}</span>}{textlimiterror >= 0 && <span className="p-1 px-2 rounded-md bg-green-600 fz-12  text-white">{textlimiterror}</span>}</span>}
        </span>
        
        <div className="flex items-center">
        <span className="header-title mr-2 ">
        {hideTools?"":<Dropdown overlay={socialMenu} placement="bottomRight" trigger={['click']}>
      <Button className="rounded-md flex items-center " icon={socilIcons[btIcon]}>
       <span className="ml-2">{type}</span>
      </Button>
    </Dropdown>}
        </span>
        {hideTools?"":<Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
             <ThreeDotsVertical size={14} /></Dropdown> }
        </div>
        </div>
    )
}

export default PostCardHeader
