import { Avatar, Button, Card, Col, Spin, Tag } from 'antd'
import classNames from 'classnames'
import dayjs from 'dayjs'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { BsAlarm, BsFile } from 'react-icons/bs'
import ApiService from 'services/ApiService'
import  { NumberFormatBase ,NumericFormat} from 'react-number-format'
import { HiFire } from 'react-icons/hi'

const UserBilling = ({user,params}) => {
    const [loading, setloading] = useState(true)
    const [subData, setsubData] = useState(null)
    useEffect(() => {
      
        getSubDetails()
      return () => {
        
      }
    }, [])
    const getSubDetails = async()=>{
        const activesub = await ApiService.PostApiCalls("creator/sub/get",{},true)
        console.log(activesub);
        if(activesub[0] && activesub[1]['IsSuccessful']){
          setsubData(activesub[1]['sub'])
        }
        setloading(false)
    }
    console.log(subData);
  return (
    <><Col span={24} className="py-0 px-0 mb-2">
         <div className="bg-white p-3 px-3 flex space-x-3 items-center ">
        
        <span className="font-bold">App Subscription & Billing</span>
      </div>
    </Col>
    <Col span={24} className={classNames("h-100 bg-white py-0 px-0 flex flex-col items-center",loading?'justify-center':'')}>
        {loading && <Spin />}
     {
      !loading && subData && <div className='p-3 w-100'>
      <Card>
       <div className="flex flex-col lg:flex-row lg:items-center justify-between gap-4">
                        <div className="flex items-center gap-3">
                            <div>
                                <Avatar
                                    className="bg-emerald-500 flex items-center justify-center"
                                    style={{
                                      color: '#fff',
                                      backgroundColor: ' rgb(16 185 129)',
                                    }}
                                    // shape="circle"
                                    icon={<HiFire  />}
                                ></Avatar>
                            </div>
                            <div>
                                <div className="flex items-center capitalize">
                                    <h6 className='mb-0'>{subData.code}</h6>
                                    <Tag style={{
                                      color: 'rgb(5 150 105)',
                                      backgroundColor: 'rgb(209 250 229)',
                                    }} className="bg-emerald-100 text-emerald-600 dark:bg-emerald-500/20 dark:text-emerald-100 rounded-md border-0 mx-2">
                                        <span className="capitalize">
                                            {subData.status}
                                        </span>
                                    </Tag>
                                </div>
                                {subData.isFreeTrail ?<div>
                                    <span>Billing {subData.duration}</span>
                                    <span> | </span>
                                    <span>
                                       Free Trial ends on{' '}
                                        {dayjs
                                            .unix(subData.FreeTrailEndAt)
                                            .format('MM/DD/YYYY')}
                                    </span>
                                    {/* <span>
                                        <span className="mx-1">for</span>
                                        <NumericFormat
                                            className="font-semibold text-gray-900 dark:text-gray-100"
                                            displayType="text"
                                            value={(
                                                Math.round(subData.plan.amount/1000)
                                            ).toFixed(2)}
                                            prefix={'$'}
                                            thousandSeparator={true}
                                        />
                                    </span> */}
                                </div>:<div>
                                    <span>Billing {subData.duration}</span>
                                    <span> | </span>
                                    <span>
                                        Next payment on{' '}
                                        {dayjs
                                            .unix(subData.current_period_end)
                                            .format('MM/DD/YYYY')}
                                    </span>
                                    <span>
                                        <span className="mx-1">for</span>
                                        <NumericFormat
                                            className="font-semibold text-gray-900 dark:text-gray-100"
                                            displayType="text"
                                            value={(
                                                Math.round(subData.plan.amount/1000)
                                            ).toFixed(2)}
                                            prefix={'$'}
                                            thousandSeparator={true}
                                        />
                                    </span>
                                </div>}
                            </div>
                        </div>
                        <div className="flex">
                            {subData && (
                                <Button
                                    // onClick={unsubscribe}
                                    size="sm"
                                    variant="plain"
                                >
                                    Cancel plan
                                </Button>
                            )}
                            <Button
                                // onClick={subscribe}
                                size="sm"
                                className="ml-2 rtl:mr-2"
                            >
                                {subData ? 'Update' : 'Subscribe'} Plan
                            </Button>
                        </div>
                    </div></Card></div>
     }   
    </Col>
    </>
  )
}

export default UserBilling