
import React from 'react'
import { List, Typography, Divider } from 'antd';
import { ChevronBarRight, ChevronRight } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

const data = [
  {slug: 'all',lable:"All Bookmarks"},
  {slug: 'photos',lable:"Photos"},
  {slug: 'videos',lable:"Videos"},
  {slug: 'news',lable:"News"},
  {slug: 'others',lable:"Others"}
  
];
const dataGeneral = [
    
    {slug: 'display',lable:"Display"}
    
  ];
const BookmarkList = ({user,active}) => {
    
  return (
      <>
       {/* <div className='pb-1 bg-gray-100'></div> */}
    <List
      
      className="bg-white pb-3"

      dataSource={data}
      renderItem={item => (
        <List.Item className='cursor-pointer p-2'>
          <Link className='text-hs-dark px-1 flex w-100' to={`/bookmarks/${item["slug"].toLowerCase()}`}>
              <span className={item["slug"].toLowerCase() === active ?'flex justify-between items-center w-100 p-2 bg-blue-50 text-blue-400':'flex justify-between items-center w-100 p-2 hover:bg-blue-50 hover:text-blue-400'}>
              <span>{item["lable"]}</span>
              <ChevronRight size={20} />
              </span></Link>
        </List.Item>
      )}
    />

    
    </>
  )
}

export default BookmarkList