import React,{useState,useEffect,useContext} from 'react'
import {Container,Row,Col,Tabs,Tab,Card } from "react-bootstrap";
import {HashtagIcon,MentionIcon,QuoteIcon} from "res/icons/ReviewIcons"
import {Button,Modal} from "antd";

import _ from 'lodash';
import CardHeader from "./comp/CardHeader"
import Tags from "./comp/Tags"
import Mentions from "./comp/Mentions"
import VideoEditorTabs from "./VideoEditorTabs"
import ImageUploadView from "./VideoComponents/ImageUploadView"
import {ImageAlt,PlayCircle,X} from "react-bootstrap-icons";
import ApiService from "services/ApiService"
import Quotes from "./comp/Quotes"
import VisualStatic from "./VideoComponents/Animations/VisualStatic"
import PreviewContainer from "./VideoComponents/Animations/PreviewContainer"
import { useNavigate } from "react-router-dom";
import { VideoContext } from 'app/VideoProvider';
import "./style.css";

const VideoPost = ({campaign,duration,minQuotes,url,scheduleList,profilesSelected}) => {
    
    // const history = useHistory();
    const navigate = useNavigate()
    const [viewHight, setviewHight] = useState(300);
    const { videoClips,setVideoClips,videoActiveClip, setVideoActiveClip,setVideoId,setVideo} = useContext(VideoContext);
    const [preview,setPreview] = useState(false)
    const [activeKey, setCurrentKey] = useState(0)
    const [activeTags, setActiveTags] = useState(campaign["posts"]["tags"])
    const [activeMentions, setactiveMentions] = useState(campaign["posts"]["mentions"])
    const [activeQuotes, setactiveQuotes] = useState(campaign["posts"]["quotes"])
    // const [selectedQuotes, setselectedQuotes] = useState(campaign["posts"]["selectedQoutes"] ? campaign["posts"]["selectedQoutes"] :[])
    const [selectedQuotes, setselectedQuotes] = useState(campaign["posts"]["selectedQoutes"] ? campaign["posts"]["selectedQoutes"] :_.map(_.chunk(_.shuffle(campaign["posts"]["quotes"]), minQuotes+2)[0],function(k,v){
        return v;
      }))
    const [minQuotesNo,setMinQuotesNo] = useState(minQuotes)
    const [imagesList,setimagesList] = useState(_.map(_.filter(campaign["VideoDetails"][0]["clips"],function(obj) {return obj.visual}), 'visual'))
    const [clips,setClips] = useState(campaign["VideoDetails"][0]["clips"].length === 0?campaign["inVideoClips"]?campaign["inVideoClips"]:campaign["VideoDetails"][0]["clips"]:campaign["VideoDetails"][0]["clips"])
    const [posts, setPosts] = useState(campaign["posts"])
    const [building,setbuilding] = useState(false)
    const [apiError,setApiError] = useState(null)
    const videoId = campaign["VideoDetails"][0]["vid"]
   
    const TabList = [
        {label:"Hashtags",icon:<HashtagIcon size={[14,16]} className="mr-1"/>,secondaryLabel:"Hashtags"},
        {label:"Mentions",icon:<MentionIcon size={[14,14]}  className="mr-1"/>,secondaryLabel:"Mentions"},       
        {label:"Quotes",icon:<QuoteIcon size={[14,11]}  className="mr-1"/>,secondaryLabel:"Quotes"},
    ]
    useEffect(() => {
        
        const h = window.innerHeight;
        setviewHight(h-(64+64))
        setVideoId(videoId)
        
        setVideoClips(clips)
        setVideo(campaign["VideoDetails"][0])
      return () => {
        
      };
    }, [viewHight])
    const updateCampaignPost = async(res={}) =>{
        let postContent = {};       
        postContent["quotes"] = res["q"]?res["q"]:activeQuotes;
        postContent["selectedQoutes"] = selectedQuotes;
        postContent["title"] = posts["title"]
        postContent["tags"] = res["t"]?res["t"]:activeTags
        postContent["mentions"] = activeMentions
        postContent["images"] = res["i"]?res["i"]:[]
        postContent["duration"] = duration
        postContent["type"] = posts["type"]
        postContent["drip"] = posts["drip"]
        postContent["url"] = posts["url"]
         const data = {
          cid:campaign["cid"],
          updates:{posts:postContent,"selectedProfiles":profilesSelected}
         }
         await ApiService.updateCampaign(data)
       }
    const getClipImages = (images) => {
        let existingClips = videoClips;        
            let newClips = []
            for(let i = 0; i < images.length; i++){
                let tmp = existingClips[i] ? existingClips[i] :{}
                tmp["visual"] = {url:images[i]["url"],status:"done",percent:100,type: "image/jpeg",uid: images[i]["uid"]?images[i]["uid"]:Math.random().toString(36).substr(2, 7)};
                newClips.push(tmp);
            }
            setClips(newClips)
        updateServer({clips:newClips})
        setVideoClips(newClips);
        
        setimagesList(images)
    }
    const updateServer = async(input) =>{
        const data = {
            vid:videoId,
            update:input
        }
        // await ApiService.updateVideoData(data);

        await ApiService.PostApiCalls("campaign/videoUpdate",{...data},true)
    }
    const updateCurrentClip = (i) =>{
        setVideoActiveClip(i)
        

    }
    const buildCampaignPost = () =>{
        setbuilding(true)
        setTimeout(
          () =>  start(), 
          1000
      );
       }
       const start = async() =>{
        setApiError(null)
        let postContent = {};
        postContent["quotes"] = activeQuotes;
        postContent["selectedQoutes"] = selectedQuotes;
        postContent["title"] = posts["title"]
        postContent["tags"] = activeTags
        postContent["mentions"] = activeMentions
        postContent["images"] = []
        postContent["duration"] = duration
        postContent["type"] = posts["type"]
        postContent["drip"] = posts["drip"]
        postContent["url"] = posts["url"]
        postContent["socialProfiles"] = profilesSelected;
        postContent["videoId"] = videoId;
         const data = {
          cid:campaign["cid"],
          posts:postContent,
          campaign: campaign,
         }
         const updated = await ApiService.PostApiCalls("campaign/build/"+campaign["cid"],{...data},true)
        
         if(updated[0] && updated[1]["IsSuccessful"]){
            navigate(url+"posts");        
          }
          else{
           setApiError("Error while processing request")
          }
          setbuilding(false)
       }
    return (
        <>
        <div className="video-post-right-tabs">
        <Tabs defaultActiveKey={activeKey}  onSelect={(k) => setCurrentKey(k)}>
        {
            TabList.map((a,key) => (
                <Tab className="pb-4" key={key} eventKey={key} title={<CardHeader showNumber={true} isTab={true} activeTags={key === 0 ? activeTags.length:key === 1 ? activeMentions.length:selectedQuotes.length} activeKey={activeKey} icon={a.icon} text={a.label} eventKey={key+1} secondaryLabel={a.secondaryLabel} minQuotesNo={0}/>}>
                <Card.Body> {key === 0 && <Tags update={updateCampaignPost}  tags={activeTags} callback={setActiveTags}>

                </Tags>}
                
                {key === 1 && <Mentions update={updateCampaignPost}  tags={activeMentions} callback={setactiveMentions} />}
                {key === 2 && <Quotes update={updateCampaignPost} setMinQuotesNo={setMinQuotesNo} activeQuotes={activeQuotes} minQuotesNo={minQuotesNo} callback={setactiveQuotes} callbackSelected={setselectedQuotes} selectedQuotes={selectedQuotes} />}
                </Card.Body>
                </Tab>
            ))
        }
        
        
</Tabs>
        </div>
        <Container fluid="true" className="px-0 position-relative video-middle-container " style={{minHeight:viewHight+"px",paddingBottom:"68px"}}>        
        <Row className="mx-0 p-0 video-content-editor">
        <Col lg={12}  md={12} sm={12} className="py-3 text-center header" >Edit & Add content & media</Col>
        <Col lg={12}  md={12} sm={12} className="pb-2 text-center " >
        <div className="image-preview">
        {clips.length === 0 && <ImageAlt size={30} color="#fff" />}
        {clips.length > 0 && clips[videoActiveClip]["visual"] && <div className="image-holder">
                <VisualStatic image={clips[videoActiveClip]["visual"]["url"]} />
            
        </div>}
        </div>            
        </Col>
        <Col lg={12}  md={12} sm={12} className="pb-2 mt-2 text-center px-0  " >
        <VideoEditorTabs clips={clips} activeClip={videoActiveClip} />

        </Col>
        <Col className="px-0"
             lg={12}      
             md={12}
             sm={12} >
             <Row className="mx-0">
             <Col className="px-0 fz-18 fw-700"
             lg={12}      
             md={12}
             sm={12} >
           
            </Col>
        </Row>
        </Col>
        </Row>
        <div className="video-image-container">
        <ImageUploadView campaign={campaign} callbackImages={getClipImages} list={imagesList} updateCurrentImage={updateCurrentClip} />
        
        </div>
        </Container>
        <div className="position-fixed video-bottomtools pl-2 d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center "> <Button
           onClick = {()=>setPreview(true)} disabled={clips.length === 0 ? true:false}
           className="greenbg-btn brd-4">Preview Video</Button>
           {/* <Button className="bluelink-btn float-right ml-3">Save video post</Button> */}
           </div>
         
          <div className="d-flex align-items-center flex-2 justify-content-center ">
          <a href={"/"}>
          <Button className="bluelink-btn float-right ">Back to board</Button></a>
          <Button onClick={buildCampaignPost} loading={building} className=" brd-4" type="primary" disabled={selectedQuotes.length >= minQuotesNo ? profilesSelected.length === 0 ? true:false:true}>Build Campaign</Button></div>
        </div>
       {preview &&  <Modal visible={preview} closeIcon={<X color="#EF476F" size={24} style={{width:"24px",height:"24px", marginTop:"5px"}} />} onCancel= {()=>setPreview(false)} closable={true} title="" footer={null}>
          <PreviewContainer video={campaign["VideoDetails"][0]} clips={clips} preview={preview} />
        </Modal>}
        </>
    )
}

export default VideoPost
