import { Col, Row,Select } from "antd";
import React, { useContext, useState } from "react";
import { VideoContext } from "app/VideoContext";
const { Option } = Select;
const StoryVideoFormat = () => {
  const {
    showPreview,
    setactiveLefttab,
    updateContent,
    videoContent,
    setvideoContent,videoResolution, setvideoResolution,
  } = useContext(VideoContext);
  
  
  const [activeFormat, setactiveFormat] = useState(videoContent["format"]?videoContent["format"]:"16X9")

  const changeVideoFormat = (e) => {       
    setactiveFormat(e)
    updateContent({format:e})
  }
  return (
    <Row className="h-full w-100">
      <Col className="flex flex-col h-full story-article" lg={24}>
        <div className="w-100 flex p-2 px-3 h-12 border-bottom items-center justify-between">
        <span>Video Resolution</span>
        <Select defaultValue={videoResolution} style={{ width: 120 }} onChange={(e)=>setvideoResolution(e)}>
        <Option value="480">480p SD</Option>
      <Option value="720">720p HD</Option>
      <Option value="1080">1080p HD</Option>
        </Select>
        </div>
        <div className="story-editor overflow-y-auto p-4 grid grid-cols-2 gap-3">
        <div className="FormatSelectionOption "  onClick={(e) => changeVideoFormat("16X9")} >
            <div className="FormatSelectionLabel">Landscape - 16:9</div>
            <div className={activeFormat==="16X9"?"FormatViewOption ShowSelection active":"FormatViewOption ShowSelection"}>
            <div className="LandscapeSample "></div>   
            </div><div className="FormatInfo">Good format to share video on Youtube, Facebook, Website etc.</div>
            </div>
            <div className="FormatSelectionOption"  onClick={(e) => changeVideoFormat("1X1")}>
            <div className="FormatSelectionLabel">Sqaure - 1:1</div>
            <div className={activeFormat==="1X1"?"FormatViewOption ShowSelection active":"FormatViewOption ShowSelection"}>
            <div className="SquareSample "></div>   
            </div>
            <div className="FormatInfo">Good format to share video on Facebook, Twitter & Instagram.</div>
            </div>
            <div className="FormatSelectionOption"   onClick={(e) => changeVideoFormat("9X16")}>
            <div className="FormatSelectionLabel">Verticle - 9:16</div>
            <div className={activeFormat==="9X16"?"FormatViewOption ShowSelection active":"FormatViewOption ShowSelection"}><div className="VerticleSample"></div>
            </div><div className="FormatInfo">Good format to share video on Instagram & Snapchat.</div>
                </div>
        </div>
      </Col>
    </Row>
  );
};

export default StoryVideoFormat;

