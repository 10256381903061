import i18n from 'i18next';
import _ from 'lodash';
import { initReactI18next } from 'react-i18next';
const appLocale = localStorage.getItem("appLocale")
let setDefault = "en";
if(appLocale){
  setDefault = appLocale
}
else{
  let locale = Intl.DateTimeFormat().resolvedOptions().locale;

  if(_.includes(locale,"en-")){
    setDefault = "en";
  }
  else if(_.includes(locale,"fr-")){
    setDefault = "fr";
  }
  else if(_.includes(locale,"es-")){
    setDefault = "es";
  }
  else if(_.includes(locale,"de-")){
    setDefault = "de";
  }
  else if(_.includes(locale,"ja-")){
    setDefault = "ja";
  }
  
  else if(_.includes(locale,"hi-")){
    setDefault = "hi";
  }
  else{
    setDefault = "en";
  
  }
}

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: setDefault,
  resources: {
    en: {
      translations: require('./locales/en_translations.json')
    },
    es: {
      translations: require('./locales/es_translations.json')
    },
    fr: {
      translations: require('./locales/fr_translations.json')
    },
    de: {
      translations: require('./locales/de_translations.json')
    },
    ja: {
      translations: require('./locales/ja_translations.json')
    },
    hi: {
      translations: require('./locales/hi_translations.json')
    }
  },
  ns: ['translations'],
  defaultNS: 'translations'
});

i18n.languages = ['en', 'es',"fr","de","hi"];

export default i18n;