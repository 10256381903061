import { Button, Result, Spin } from 'antd'
import FeedCard from 'components/feed/FeedCard'
import Utils from 'helper/Utils'
import React from 'react'
import { useState,useEffect } from 'react'
import { Link, useLocation, useNavigate,useParams } from 'react-router-dom'
import ApiService from 'services/ApiService'
import InfiniteScroll from 'react-infinite-scroller';
const FeedPage = (props) => {
    const navigate = useNavigate()
    
    const brParams = useParams();
    const user = Utils.getLocalInfo("userhash")
    const [loading, setloading] = useState(true);
    const [error, seterror] = useState(null);
    const [feedContent, setfeedContent] = useState([])
    const [baseQuery, setbaseQuery] = useState({})
    const [shwoFull, setshwoFull] = useState(false)
    const [queryParams, setqueryParams] = useState({limit:20,offset:0,sort:"desc",total:0})
    useEffect(() => {
      
        const params = brParams;
        setshwoFull(false);
        setfeedContent([])
        let filter = {};
        if(params["user"] && params["id"]){
            filter["type"] = "id";
            filter["contentID"] = params["id"]
            setshwoFull(true);

          }
          else if(params["tag"]){
            filter["type"] = "tag";
            filter["tag"] = params["tag"]
          }
          setbaseQuery(filter)
          getPosts(filter)
      return () => {
        
      }
    }, [props])
    const loadFunc = async(filter) =>{
      const queryString = new URLSearchParams(queryParams).toString();
        const res = await ApiService.PostApiCalls(
          `posts?${queryString}`,
          {... filter},
          false
        );
        
        if (res[0]) {
          
          if (res[1]["IsSuccessful"]) {
            setfeedContent([...feedContent,...res[1]["list"]])
            setqueryParams({...queryParams,offset:queryParams.offset+20})
           
          } else {
            
            setloading(false);
          }
        } else {
          
          
        }
    
      }

    
    const getPosts = async(filter) =>{
      const queryString = new URLSearchParams(queryParams).toString();
        const res = await ApiService.PostApiCalls(
          `posts?${queryString}`,
          {... filter},
          false
        );
        
        if (res[0]) {
          
          if (res[1]["IsSuccessful"]) {
            setfeedContent(res[1]["list"])
            setqueryParams({...queryParams,offset:20,total:res[1]["total"]})
            setloading(false);
            window.scrollTo(0, 0);
          } else {
            
            setloading(false);
          }
        } else {
          
          setloading(false);
        }
    
      }
    if(user["role"] === "creator" && parseInt(user["creatorProfileStep"]) < 6){
        return(navigate("/creator/edit/1"))
    }
   
  //   else  if(user["role"] === "creator" && parseInt(user["creatorProfileStep"]) === 6){
  //     return(<Redirect to="/creator/home" />)
  // }
    // console.log(user["creatorProfileStep"]);
    return loading ? (
        <div className="px-4 h-100 d-flex justify-center align-items-center">
          <Spin />
        </div>
      ) :(
        <>
        {feedContent && feedContent.length > 0 &&<div className="px-4">
        <InfiniteScroll
    pageStart={0}
    loadMore={loadFunc}
    hasMore={feedContent.length < queryParams.total?true:false}
    loader={<div className="loader flex items-center justify-center" key={0}><Spin /></div>}
>
            {
                feedContent.map((feed,key)=>(
                  
                    
                    <FeedCard key={key} post={feed} id={key} shwoFull={shwoFull} />
                    
                ))
            }
        
        </InfiniteScroll>
        </div>}
        {feedContent && feedContent.length === 0 &&<div className="px-4">
        <Result
    status="403"
    title={baseQuery["tag"]?`#${baseQuery["tag"]}`:""}
    subTitle={baseQuery["tag"]?"Sorry, no post found for searched tag.":"Sorry, no post found for searched id."}
    extra={<Link to={"/"}><Button type="primary">Back Home</Button></Link>}
  />
    
    
    </div>}</>
    )
}

export default FeedPage
