// import { configureStore } from "@reduxjs/toolkit";

// import storage from "redux-persist/lib/storage";
// import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import { encryptTransform } from "redux-persist-transform-encrypt";

import { hashsaga } from "../services/apis";
import getConstants from "../services/slices/constants";




// const persistConfig = {
// 	key: "root",
// 	storage,
// 	transforms: [
// 		encryptTransform({
// 			secretKey: "my-super-secret-keyForMeta@Prop@$S",
// 			onError: function(error) {
// 				// console.warn(error);
// 			},
// 		}),
// 	],
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// export const store = configureStore({
// 	reducer: persistedReducer,
// 	middleware: (getDefaultMiddleware) =>
// 		getDefaultMiddleware().concat(hashsaga.middleware),
// });

// export default store;
// import { PERSIST_STORE_NAME } from 'constants/app.constant'

import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import rootReducer from './rootReducer'

// const rootReducer = (state, action) => {
// 	if (action.type === "logout/logout") {
// 		state = undefined;
// 	}
// 	return reducers(state, action);
// };
const reducers = combineReducers({
	[hashsaga.reducerPath]: hashsaga.reducer,
	constants: getConstants,
});
const middlewares = []

const persistConfig = {
    key: "root",
    keyPrefix: '',
    storage,
    whitelist: ['auth','theme', 'locale'],
  
}

const store = configureStore({
    reducer: persistReducer(persistConfig, rootReducer()),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
        }).concat(hashsaga.middleware),
    devTools: process.env.NODE_ENV === 'development',
})

store.asyncReducers = {}

export const persistor = persistStore(store)

export const injectReducer = (key, reducer) => {
    if (store.asyncReducers[key]) {
        return false
    }
    store.asyncReducers[key] = reducer
    store.replaceReducer(
        persistReducer(persistConfig,rootReducer(store.asyncReducers))
    )
    persistor.persist()
    return store
}

export default store
