import React, { useState } from 'react'
import { Button, Col, Row,Checkbox, Form, Input,Radio, Select, InputNumber, Modal, Upload, DatePicker, message  } from 'antd'
import { LockOutlined, UploadOutlined } from '@ant-design/icons';
import jsonData from '../../../res/extra/country_dial_list.json';
import { useEffect } from 'react';
import Utils from 'helper/Utils';
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { updatePropertyListing } from '../store/dataSlice';
import axios from 'axios';
import _ from 'lodash';
const { Option } = Select;
const availablity = [
  "Immediate","1 Month","2 Months","2 Months +"
]
const ListingPricingDetails = ({next}) => {
  const [form] = Form.useForm()
  const [basicInfo, setbasicInfo] = useState({
      ListingType:"open",
      GuaranteeAmount:"",
      guaranteeType:"Open",
      deposit:"",price:"",AuctionTill:"",
      availableIn:"Immediate"
      
  })

    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);

    const dispatch = useDispatch()

    const listingBasicInfo = useSelector((state) => state.webStreet.data.listingBasicInfo)
    const listingPropertyDetails = useSelector((state) => state.webStreet.data.listingPropertyDetails);
   
    
   
    useEffect(() => {
      form.setFieldsValue(basicInfo)
     }, [form, basicInfo])
     
    
    const onFinish = async(values) => {
        
       console.log(values);
      //  return;
        const formData = new FormData();
        fileList.forEach((file) => {
          formData.append('images', file);
        });
        Object.entries(listingBasicInfo).forEach(([key, value]) => {
          formData.append(key, value);
        });
        Object.entries(listingPropertyDetails).forEach(([key, value]) => {
          if(key === "propertyAdress"){
            formData.append(key, JSON.stringify(value));

          }else{
            formData.append(key, value);

          }
        });
       
        const newObject = _.omit(values, ["listingimages","AuctionTill"]);
        
        Object.entries(newObject).forEach(([key, value]) => {
          formData.append(key, value);
        });
        formData.append("AuctionTill",values.AuctionTill?parseInt(values.AuctionTill.unix()):0)

        
        setUploading(true);
        
        const path =Utils.apiUrl("street/create/listing/images")
       
        try {
          let res = await axios.post(
            path,
            formData,
            Utils.apiHeaderApp(true)
          );
          if(res.data["IsSuccessful"]){
            message.success('Propery listed successfully.');
          }
          else{
            message.error('Something went wrong, try again.');
          }
        } catch (error) {
          message.error('Something went wrong, try again.');
        }
        
        
        setUploading(false);
        dispatch(updatePropertyListing({type:"listingBasicInfo",update:{PropertyType:"Residential",
        listingFor:"Sale",phone:"",
        fullname:"",city:"",prefix:"+91"}}))
        dispatch(updatePropertyListing({type:"listingPropertyDetails",update:{PropertySubType:"Apartment",
        BHK:"",FurnishType:"",
        address:"",Locality:"",
        propertyAdress:"",furnishingList:[],AmenitiesList:[]}}))
        next(3)
      };
      const props = {
        onRemove: (file) => {
          const index = fileList.indexOf(file);
          const newFileList = fileList.slice();
          newFileList.splice(index, 1);
          setFileList(newFileList);
        },
        beforeUpload: (file) => {
          setFileList([...fileList, file]);
          return false;
        },
        fileList,
      };
  return (
    <>
    <Row>
    <Col xs={24} sm={24} md={18} lg={18} xl={18} className='mx-auto '>
    <Form
     form={form}
      name="normal_login"
      className="login-form"
      initialValues={{
        
        layout: "vertical",
        ...basicInfo
      }}
      
      layout={"vertical"}
      onFinish={onFinish}
      
    >
      <Form.Item
        name="ListingType"
        label="Listing Type"
        rules={[
          {
            required: true,
            message: 'Please select one!',
          },
        ]}
      >
        <Radio.Group defaultValue={basicInfo.ListingType} onChange={(e)=>setbasicInfo({...basicInfo,ListingType:e.target.value})} buttonStyle="solid" size="large" className='custom-ant-r' >
            
        <Radio.Button className='rounded-md mx-2 '  value="open">Open</Radio.Button>
        <Radio.Button className='rounded-md mx-2 '  value="guaranteed">Guaranteed</Radio.Button>
            
        </Radio.Group>
      </Form.Item>
      {
          basicInfo.ListingType === "guaranteed" && 
              
          <Form.Item
          name="guaranteeType"
          label="Guarantee Type"
          rules={[
            {
              required: true,
              message: 'Please select one!',
            },
          ]}
        >
          <Radio.Group defaultValue={basicInfo.guaranteeType} onChange={(e)=>setbasicInfo({...basicInfo,guaranteeType:e.target.value})} buttonStyle="solid" size="large" className='custom-ant-r' >
              
          <Radio.Button className='rounded-md mx-2 '  value="Open">Open</Radio.Button>
          <Radio.Button className='rounded-md mx-2 '  value="Auction">Auction</Radio.Button>
              
          </Radio.Group>
        </Form.Item>
          
        }
         {
          basicInfo.guaranteeType === "Auction" && 
              
          <Form.Item
          name="AuctionTill"
          label="Auction End Date"
          rules={[
            {
              required: true,
              message: 'Please select end date!',
            },
          ]}
        >
          <DatePicker style={{width:250}} value={(value) => value.unix() }/>
        </Form.Item>
          
        }
        {
          basicInfo.ListingType === "guaranteed" && 

             <Form.Item
        name="GuaranteeAmount"
        label="Guarantee"
        style={{
          width: 560,
        }}
        extra={<span >You need to pay the guarantee amount now as deposit, if your property is goes unsold within said period it will be refunded to original payment source.</span>}
        className='mb-3'
        rules={[
          {
            required: true,
            message: 'Guarantee details is required',
            
          },
        ]}
      >
        {/* <InputNumber controls={false} max={1000000000} addonAfter="Hash Coins" placeholder='Guarantee amount' className='rounded-md p-1' style={{
            width: 560,
          }}/> */}
          <InputNumber controls={false} max={10000000} min={1000} prefix={"₹"} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}  placeholder='Guarantee amount' className='rounded-md p-1' style={{
            width: 560,
          }}/>
      </Form.Item>
          
        }
         <Form.Item
          name="price"
          label={listingBasicInfo.listingFor === "Sale"?"Price":"Rent"}
          className='mb-3'
          rules={[
            {
              required: true,
              message: 'Price is required',
              
            }
          ]}
        >
          <InputNumber controls={false} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      max={1000000000} prefix={"₹"} min={listingBasicInfo.listingFor === "Rent"?5000:100000} placeholder='Price amount' className='rounded-md p-1' style={{
              width: 560,
            }}/>
        </Form.Item>
        {
          listingBasicInfo.listingFor === "Rent" && 
          <Form.Item
          name="deposit"
          label="Deposit"
          className='mb-3'
          rules={[
            {
              required: true,
              message: 'Deposit is required',
              
            },
          
          ]}
        >
          <InputNumber controls={false}  max={100000000} prefix={"₹"} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} placeholder='Deposit amount' className='rounded-md p-1' style={{
              width: 560,
            }}/>
            
        </Form.Item>
        }
        <Form.Item
        name="availableIn"
        label="Avilable In"
        className='mb-3'
        rules={[
          {
            required: true,
            message: 'Please select the furnish type',
          },
        ]}
      >
        <Radio.Group defaultValue={basicInfo.availableIn} buttonStyle="solid" size="large" className='custom-ant-r' >
        {
              availablity.map((size,i) => <Radio.Button key={i} className='rounded-md mx-2 mb-2'  value={size}>{size}</Radio.Button>)
            }
            
        </Radio.Group>
      </Form.Item>
      <Form.Item
          name="listingimages"
          label="Property Pictures"
          className='mb-3 w-50'
          // extra="Add atleast one picture"
          rules={[
            {
              required: true,
              message: 'Add atleast one picture',
            },
          ]}
          
      >
        <Upload {...props} >
        <Button icon={<UploadOutlined />}>Select File</Button>
      </Upload>
      </Form.Item>
      <Form.Item className='w-50 flex flex-row space-x-4'>
        <Button size='large' className='rounded-lg' loading={uploading} onClick={()=>next(1)} >Back</Button>
        <Button type="primary" htmlType="submit"  loading={uploading} size='large'  className={`px-3 brd-4 fw-700 mx-2 rounded-lg text-white bg-hs-pink  hover:border-hs-pink `}>
          {uploading ? "Please wait...":
          
          basicInfo.ListingType === "guaranteed"?"Proceed to payment":"Post Property"}
        </Button>
       
      </Form.Item>
    </Form>
    </Col>
   
  </Row>
  
  </>
  )
}

export default ListingPricingDetails