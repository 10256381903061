import React,{useState} from 'react'
import {Select} from "antd";
import { Flag } from 'react-bootstrap-icons';
// import Flag from "res/icons/Flag"
const { Option } = Select;
const Timeline = (props) => {
    const [duration, setDuration] = useState(props.duration);
    const scheduleList = props.scheduleList;
    // console.log(scheduleList);
    const [schedule, setSchedule] = useState(scheduleList[duration]);
    const handleChange = (value) =>  {        
        setDuration(value)
        setSchedule(scheduleList[value])
        props.callback(scheduleList[value]["days"])
        props.update(value)
       
        
    }
    
    return (
        <div className="d-flex flex-grow-1 align-items-center flex-wrap" >
           {!props.hide && <div className="d-flex flex-row  flex-grow-1 justify-content-between mt-4">
          <Select defaultValue={duration}  placeholder="Select Plan" defaultActiveFirstOption={false}  style={{ width: '100%' }} onChange={handleChange}>
          {
              props.schedList.map((s,key)=>(
                <Option key={key} value={s["code"]}>{s["name"]}</Option>
              ))
            }
    </Select>
          </div>}
          <div className="mt-4 w-100 float-left"></div>
          <div className="campaign-timeline mt-4 w-100 float-left">
          <div className="endtime"></div>
          <div className="starttime"></div>
            <div className="track"><div className="flag-container">
            {
                schedule.slot.map((day,key) =>(
                    <Flag key={key} />
                    
                ))
            }
              
              
            </div></div>
          </div>
        </div>
    )
}

export default Timeline
