import React, { useEffect, useState } from 'react';
import classNames from 'classnames'; // Classnames library for conditional CSS classes
// import './styles.css'; // Your Tailwind CSS file

const ReadMoreComponent = ({ text }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [xpandNeed, setxpandNeed] = useState(true);
  useEffect(() => {
    
    if(text.length < 51){
        setxpandNeed(false)
    }
    else{
        setxpandNeed(true)
    }
    return () => {
      
    }
  }, [text])
  
  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    xpandNeed?<div className="max-w-md mx-auto w-full">
     {  !isExpanded ?<><p className="max-h-12 overflow-hidden ine-clamp-2">
    {text.substring(0, 50)}
    <span className="truncate">... {!isExpanded && (
        <button
          className="text-blue-500 hover:underline focus:outline-none"
          onClick={toggleReadMore}
        >
          Read more
        </button>
      )}</span>
  </p>
  </>:<p className="">
    {text}
    <span className=""> {isExpanded && (
        <button
          className="text-blue-500 hover:underline focus:outline-none"
          onClick={toggleReadMore}
        >
          Read less
        </button>
      )}</span>
  </p>}
      
    </div>:<div className="max-w-md mx-auto w-full"><p className="">{text}</p></div>
  );
};

export default ReadMoreComponent;
