import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import _ from 'lodash'


export const initialFilterData = {
    query: '',
    PropertyType: "",
    BHKType: "",
    Type: "",
}
export const initialTableData = {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    query: '',
    sort: {
        order: '',
        key: '',
    },
}
const dataSlice = createSlice({
    name: 'webStreet/data',
    initialState: {
       loading:false,
       webLocation:null,
       filterData:initialFilterData,
       tableData: initialTableData,
       brokerForm:{
        phone:"",
        fullname:"",city:"",prefix:"+91",
        idProofOption:"",
        idProofNumber:"",
        email:""
       },
       listingBasicInfo:{ PropertyType:"Residential",
       listingFor:"Sale",phone:"",
       fullname:"",city:"",prefix:"+91"
        
    },
       listingPropertyDetails:{
        PropertySubType:"Apartment",
        BHK:"",FurnishType:"",
        address:"",Locality:"",
        propertyAdress:"",furnishingList:[],AmenitiesList:[]
        
    },
       listingPricingDetails:null,
       listingPictures:null,
       city:"Vadodara"

    },
    reducers: {
        setTableData: (state, action) => {
            state.tableData = action.payload
        },
        updateSelectedProductId: (state, action) => {
            state.city = action.payload
        },
        updateWebLocation:(state,action)=>{
            state.webLocation = action.payload
        },
        updatePropertyListing:(state,action) =>{
            state[action.payload.type] = action.payload.update
        },
        setFilterData: (state, action) => {
            state.filterData = action.payload
        },

        
    },
    extraReducers: {
        
    },
})

export const { 
    updateSelectedProductId,
    updateWebLocation,updatePropertyListing,setFilterData,setTableData
 } = dataSlice.actions

export default dataSlice.reducer
