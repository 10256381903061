import React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { Upload, message, Button, Spin } from "antd";
import _ from "lodash";
import {
  CameraVideoFill,
  FileMusic,
  ImageAlt,
  ImageFill,
  Images,
  Plus,
  PlusCircle,
  X,
  XCircleFill,
} from "react-bootstrap-icons";
import FileUploader from "container/common/FileUploader";
import Utils from "helper/Utils";

import AssetValidationView from "./AssetValidationView";
import { useEffect } from "react";
const { Dragger } = Upload;
const UploadModal = ({ visible, callback,addedImages, saveUploads,type,prefix = "extra" }) => {
  const [show, setshow] = useState(visible);
  const [activeImages, setactiveImages] = useState(addedImages);
  const [disableSave, setdisableSave] = useState(false)
  useEffect(() => {
    
    return () => {
      
    }
  }, [activeImages])
  const cancelUpload = () =>{
    for (let i = 0; i < activeImages.length; i++) {
      const token = activeImages[i]["cancelToken"]
      
        token.cancel();
    }
    setactiveImages([])
    saveUploads([])
    callback(false)
  }
  const ProgressCheck = (images,p,cancelToken) => {
    
    const image = Utils.filterArray(images, [
      "name",
      "uid",
      "type",
      "url",
      "size",
      "status","cancelToken"
    ]);
    // console.log(p);  
    setdisableSave(true)
    setactiveImages(images);
  };
  const ImageUploadCallback = (images) => {
    const image = Utils.filterArray(images, [
      "name",
      "uid",
      "type",
      "url",
      "size",
      "status",
    ]);
    // console.log(image);
    setdisableSave(false)
    setactiveImages(images);
  };
  const removeFile = (id) =>{
    
    setactiveImages(_.filter(activeImages, function(o) { 
      return o.uid !== id; 
   }));
    
  }
  const updateDetails = (id,data) =>{
    const updatedState = _.map(activeImages, single => {
      if (single.uid === id) {
        single["image"] = data;
      }
      return single;
  });
    setactiveImages(updatedState);
    
  }
  return (
    <div>
      <Modal
        show={show}
        onHide={() => {
          setshow(false);
          callback(false);
        }}
        centered
        size="lg"
        contentClassName="campaign-new-modal rounded-2xl p-0"
        backdrop={false}
      >
        <Modal.Header className="p-0">
          <div className="p-3 font-semibold text-lg border-b w-full relative">
            {" "}
            {type === "music"? "Upload music":"Upload images and videos"}
            <span
              onClick={() => cancelUpload()}
              className="absolute top-4 cursor-pointer right-4"
            >
              <XCircleFill />
            </span>
          </div>
        </Modal.Header>
        <Modal.Body className="p-3 upload-modal h-96 overflow-y-auto">
          {/* {activeImages.length === 0 && <FileUploader prefix={prefix} showUploadList={false} multipleImages={false} processCallback={ProgressCheck} callback={ImageUploadCallback} list={activeImages} limit={20} listType="none"
        view={} 
      hideLoader={true}
        viewUploading={<div className="upload-imagebt">
        <Spin size="small"  spinning={true} />
        <span className="ml-2 pt-1 fw-700">Uploading...</span> </div>} />} */}

          {/* {activeImages.length > 0 &&  */}
          <div
            className={
              activeImages.length === 0 ? "" : "images-gallery-list col-four"
            }
          >
            <div className="item">
              <FileUploader
                prefix={prefix}
                showUploadList={false}
                multipleImages={false}
                processCallback={ProgressCheck}
                callback={ImageUploadCallback}
                list={activeImages}
                acceptedList={type === "music"?"audio/mpeg,audio/aac,audio/wav":"image/*,video/mp4"}
                limit={20}
                listType="none"
                view={
                  activeImages.length === 0 ? (
                    type === "music"?<div className="ant-upload ant-upload-drag w-100 flex items-center">
                    <div className="ant-upload-drag-container w-100">
                      <p className="ant-upload-drag-icon w-100 text-center flex items-center justify-center py-4 h-60">
                        <FileMusic size={130} />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag music to this area to upload
                      </p>
                      <p className="ant-upload-hint pb-2">
                        File Requirements: MP3, WAV, AAC format{" "}
                        <br></br>
                        Max file size 100 MB <br></br>
                        
                      </p>
                    </div>
                  </div>:<div className="ant-upload ant-upload-drag w-100 flex items-center">
                      <div className="ant-upload-drag-container w-100">
                        <p className="ant-upload-drag-icon w-100 text-center flex items-center justify-center py-4 h-60">
                          <Images size={150} />
                        </p>
                        <p className="ant-upload-text">
                          Click or drag images/videos to this area to upload
                        </p>
                        <p className="ant-upload-hint pb-2">
                          File Requirements: JPG, PNG, GIF, MOV, MP4 format{" "}
                          <br></br>
                          Max file size 1 GB <br></br>
                          For best results, use size 1920px by 1080px
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="ant-upload ant-upload-drag w-full h-full flex items-center justify-center">
                      <Plus size={32} />
                    </div>
                  )
                }
                hideLoader={true}
                btnCss="w-full h-full flex"
                viewUploading={null}
              />
            </div>
            {activeImages.length > 0 && activeImages.map((img, key) => (
              <AssetValidationView img={img} key={key} removeFile={removeFile} updateDetails={updateDetails}/>
            ))}
          </div>

          {/* // } */}
        </Modal.Body>
        <Modal.Footer className="p-3 border-t">
          <Button onClick={() => cancelUpload()} type="text" className="hover:bg-gray-200 rounded-xl">Cancel</Button>
          <Button disabled={disableSave} onClick={() => saveUploads(activeImages)} type="primary" className="rounded-xl">Upload</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UploadModal;
