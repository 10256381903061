
import { Col, List } from 'antd'
import React from 'react'

const AccountSettings = ({user}) => {
  return (
    <Col span={24} className="px-0">
      
        
        <List
          header={<div className="font-semibold px-3">Notifications</div>}
          className="bg-white mb-2 pb-3"
        >
<List.Item className="cursor-pointer p-1 px-1"> <span
                className={
                  "flex justify-between items-center w-100 p-2 hover:bg-blue-50 hover:text-blue-400"
                }
              >
               <span className="flex flex-col space-y-1">
                  <span>Push Notifications</span>
                  
                </span>
                {/* <ChevronRight size={20} /> */}
              </span></List.Item>
<List.Item className="cursor-pointer p-1 px-1"><span
                className={
                  "flex justify-between items-center w-100 p-2 hover:bg-blue-50 hover:text-blue-400"
                }
              >
                <span className="flex flex-col space-y-1">
                  <span>Email</span>
                  
                </span>
                {/* <ChevronRight size={20} /> */}
              </span></List.Item>
              <List.Item className="cursor-pointer p-1 px-1"><span
                className={
                  "flex justify-between items-center w-100 p-2 hover:bg-blue-50 hover:text-blue-400"
                }
              >
                <span className="flex flex-col space-y-1">
                  <span>SMS</span>
                  
                </span>
                {/* <ChevronRight size={20} /> */}
              </span></List.Item>
        </List>
        <div className='bg-gray-100 w-100 py-1'></div>
        <List
          header={<div className="font-semibold px-3">Account management</div>}
          className="bg-white  pb-3"
        >
          <List.Item className="cursor-pointer p-1">
            
          <a
              className="text-hs-dark px-1 flex w-100"
              href={`mailto:delete-account@hashsaga.com`}
            ><span
                className={
                  "flex justify-between items-center w-100 p-2 hover:bg-blue-50 hover:text-blue-400"
                }
              >
                <span className="flex flex-col space-y-1">
                  <span>Delete account</span>
                  
                </span>
                
              </span></a>
            
          </List.Item>
         
        </List>
    </Col>
  )
}

export default AccountSettings