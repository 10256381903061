import { Button, List, Spin, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { Envelope, Phone } from 'react-bootstrap-icons'
import ApiService from 'services/ApiService'
import Utils from 'helper/Utils'

const ListingRequets = ({id,callback}) => {
    const [loading, setloading] = useState(true)
    const [requests, setrequests] = useState([])
    const [sending, setsending] = useState(false)
    useEffect(() => {
      
        getRequests()
      return () => {
        
      }
    }, [id])
    const getRequests = async()=>{
        const result = await ApiService.PostApiCalls("street/listing/list",{id:id,pageIndex:1,pageSize:20},true)
        if(result[0]){
            setrequests(result[1]["data"])
            setloading(false)
          }
    }
    const acceptOffer = async(item)=>{
      setsending(true)
      const payload = {id:item._id,offerBy:item.userId,listedBy:item.listedBy,listingId:item.listingId,title:item.title,bidAmount:item.bidAmount?item.bidAmount:null}
      
      const offerState = await ApiService.PostApiCalls("steert/listing-request/accept",{...payload},true)
      if(offerState[0] && offerState[1]["IsSuccessful"]){
        message.success("Offer accepted and intimated to broker");
        callback();
      }

      setsending(false)

    }
    if(loading){
        return (<div className='flex items-center justify-center h-20'><Spin /></div>)
    }
    
  return (
    <>
    {requests.length === 0 && <div className='flex items-center justify-center h-20 text-xl '>No request yet!</div> }
    {requests.length > 0 &&<>
    
        <List
    itemLayout="vertical"
    size="large"
    pagination={{
      onChange: (page) => {
        
      },
      pageSize: 20,
    }}
    dataSource={requests}
    footer={
      <div>
        More available
      </div>
    }
    renderItem={(item) => (
      <List.Item
        key={item.title}
        className='bg-white rounded-md mb-3 p-3'
       
      >
       <div className='flex space-x-3 flex-row w-100'>
      
          <div className='flex flex-col space-y-2 flex-1'>
          <span className='font-semibold text-lg'>{item.fullname} </span>
          <span className='flex items-center space-x-3 text-base'><Phone /><span>{` ${item.prefix} - ${item.phone}`}</span></span>
          <span className='flex items-center space-x-3 text-base'><Envelope /><span>{` ${item.email}`}</span></span>
          
          <div className='flex items-center justify-between pt-2'>
             <span className='text-danger'>{item.bidAmount?<span className='flex items-center space-x-3 text-base'><span className='font-semibold'>Guarantee Bid</span><span>{`- ₹ ${Utils.getAmountInFormat(item.bidAmount)}`}</span></span>:""}{!item.bidAmount && item.paymentInfo?<span className='flex items-center space-x-3 text-base'><span className='font-semibold'>Guarantee Paid</span><span>{`- ₹ ${Utils.getAmountInFormat(item.paymentInfo.amount)}`}</span></span>:""}</span>
             <span className='text-danger'></span>
            {item.status === 1?
            <Button loading={sending} onClick={()=>acceptOffer(item)} className='rounded-md' type='primary' >{item.bidAmount?"Accept Bid":"Accept Request"}</Button>
              :
              item.status === 2?"Payment requested":""
          }
            </div>
          </div>
       </div>
         
       
      </List.Item>
       )}
       />
    
    </>}
    </>
    
  )
}

export default ListingRequets