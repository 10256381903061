import { Col, Row, Tabs } from "antd";
import React, { useContext, useState, useEffect } from "react";
import { VideoContext } from "app/VideoContext";
import Info from "../../views/Info";
import Timeline from "../../views/Timeline";
import InfoTimestamp from "../../views/InfoTimestamp";
import SocialProfileInfo from "../../views/SocialProfileInfo";
import { AuthContext } from "app/AuthProvider";
import _ from "lodash";
import ApiService from "services/ApiService";
import Utils from "helper/Utils";
import Quotes from "../../views/Quotes";
import Mentions from "../../views/Mentions";
import Tags from "../../views/Tags";

const { TabPane } = Tabs;
const StoryCampaignInfo = () => {
  const {
    showPreview,
    setactiveLefttab,
    activeLefttab,
    videoContent,
    setvideoContent,
  } = useContext(VideoContext);
  const { authUser, activeWorkSpace } = useContext(AuthContext);
  const [activeImages, setactiveImages] = useState(
    videoContent["posts"]["images"]
  );
  const [activeTabe, setactiveTabe] = useState("1");
  const [loading, setLoading] = useState(true);
  const [campaign, setData] = useState(null);
  const [campaignType, setCampaignType] = useState(null);
  const [minQuotesNo, setMinQuotesNo] = useState(0);
  const [duration, setDuration] = useState(null);
  const [selectedProfiles, setselectedProfiles] = useState(
    _.map(
      Utils.getWorkSpaceSocialProfiles(
        authUser["socialchannels"],
        activeWorkSpace["wid"]
      ),
      "id"
    )
  );
  const [scheduleList, setscheduleList] = useState({});
  const [schedList, setschedList] = useState([]);
  const [activeTags, setActiveTags] = useState();
  const [activeMentions, setactiveMentions] = useState();
  const [selectedQuotes, setselectedQuotes] = useState();
  const [activeQuotes, setactiveQuotes] = useState();
  const [posts, setPosts] = useState()
  useEffect(() => {
    setData(videoContent);
    setCampaignType(videoContent["details"]["campaign-post"]);
    setActiveTags(videoContent["posts"]["tags"]);

    setactiveMentions(videoContent["posts"]["mentions"]);
    setactiveQuotes(videoContent["posts"]["quotes"]);
    setDuration(videoContent["posts"]["duration"]);
    setPosts(videoContent["posts"])
    let scheduleList = {};
    videoContent["defaultSchedules"].map((s, k) => {
      scheduleList[[s["code"]]] = { slot: s["days"], days: s["postCount"] };
    });
    setscheduleList(scheduleList);
    setschedList(videoContent["defaultSchedules"]);
    setMinQuotesNo(scheduleList[videoContent["posts"]["duration"]]["days"]);
    setselectedQuotes(
      videoContent["posts"]["selectedQoutes"]
        ? videoContent["posts"]["selectedQoutes"]
        : _.map(
            _.chunk(
              _.shuffle(videoContent["posts"]["quotes"]),
              scheduleList[videoContent["posts"]["duration"]]["days"] + 2
            )[0],
            function(k, v) {
              return v;
            }
          )
    );

    if (videoContent["selectedProfiles"]) {
      setselectedProfiles(videoContent["selectedProfiles"]);
    } else {
      const spd = _.map(
        Utils.getWorkSpaceSocialProfiles(
          authUser["socialchannels"],
          activeWorkSpace["wid"]
        ),
        "id"
      );
      setselectedProfiles(spd);
      const data = {
        cid: videoContent["cid"],
        updates: { selectedProfiles: spd },
      };
      const updated = ApiService.PostApiCalls(
        "campaign/update/" + videoContent["cid"],
        { ...data },
        true
      );
    }

    setLoading(false);
    return () => {
      setLoading(false);
    };
  }, [videoContent.cid]);
  const callback = (key) => {
    setactiveTabe(key);
  };
  const updateDuration = async (dur) => {
    setDuration(dur);
    const data = {
      cid: campaign["cid"],
      updates: {
        "posts.duration": dur,
        "details.campaign-duration": dur,
        selectedProfiles: selectedProfiles,
      },
    };
    await ApiService.PostApiCalls(
      "campaign/update/" + campaign["cid"],
      { ...data },
      true
    );
  };
  const setProfiles = async (key) => {
    let newProfiles = [];
    if (selectedProfiles.includes(key)) {
      var newList = _.remove(selectedProfiles, function(n) {
        return n !== key;
      });
      setselectedProfiles(newList);
      newProfiles = newList;
    } else {
      setselectedProfiles([...selectedProfiles, key]);
      newProfiles = [...selectedProfiles, key];
    }
    const data = {
      cid: campaign["cid"],
      updates: { selectedProfiles: newProfiles },
    };
    await ApiService.PostApiCalls(
      "campaign/update/" + campaign["cid"],
      { ...data },
      true
    );
  };
  const updateCampaignPost = async (res = {}) => {
    let postContent = {};
    postContent["quotes"] = res["q"] ? res["q"] : activeQuotes;
    postContent["selectedQoutes"] = selectedQuotes;
    postContent["title"] = posts["title"];
    postContent["tags"] = res["t"] ? res["t"] : activeTags;
    postContent["mentions"] = res["m"] ? res["m"] : activeMentions;
    postContent["images"] = res["i"] ? res["i"] : activeImages;
    postContent["duration"] = duration;
    postContent["type"] = posts["type"];
    postContent["drip"] = posts["drip"];
    postContent["url"] = posts["url"];
    const data = {
      cid: campaign["cid"],
      updates: { posts: postContent, selectedProfiles: selectedProfiles },
    };
    await ApiService.PostApiCalls(
      "campaign/update/" + campaign["cid"],
      { ...data },
      true
    );
  };
  const activateHashes = async () => {};
  return (
    !loading && (
      <Row className="h-full w-100">
        <Col className="flex flex-col h-full story-article" lg={24}>
          <div className=" w-100">
            <Tabs
              defaultActiveKey="1"
              className="h-full w-full"
              centered
              onChange={callback}
            >
              <TabPane className="w-full" tab={"Campaign"} key="1"></TabPane>
              <TabPane className="w-full" tab={"Quotes"} key="2"></TabPane>
              <TabPane className="w-full" tab={"Hashtags"} key="3"></TabPane>
              <TabPane className="w-full" tab={"Mentions"} key="4"></TabPane>
            </Tabs>
          </div>
          {activeTabe === "1" && (
            <div className="story-editor overflow-y-auto p-4">
              <Info campaign={videoContent} />
              <Timeline
                duration={duration}
                update={updateDuration}
                callback={setMinQuotesNo}
                scheduleList={scheduleList}
                schedList={schedList}
              />
              <InfoTimestamp campaign={campaign} />
              <SocialProfileInfo
                selectedProfiles={selectedProfiles}
                profile={Utils.getWorkSpaceSocialProfiles(
                  authUser["socialchannels"],
                  activeWorkSpace["wid"]
                )}
                callbackSelected={setProfiles}
              />
            </div>
          )}
          {activeTabe === "2" && (
            <div className="story-editor overflow-y-auto p-4">
              <Quotes
                update={updateCampaignPost}
                setMinQuotesNo={setMinQuotesNo}
                activeQuotes={activeQuotes}
                minQuotesNo={minQuotesNo}
                callback={setactiveQuotes}
                callbackSelected={setselectedQuotes}
                selectedQuotes={selectedQuotes}
              />
            </div>
          )}
          {activeTabe === "3" && (
            <div className="story-editor overflow-y-auto p-4">
              <Tags
                activateHashes={activateHashes}
                update={updateCampaignPost}
                tags={activeTags}
                callback={setActiveTags}
              ></Tags>
            </div>
          )}
          {activeTabe === "4" && (
            <div className="story-editor overflow-y-auto p-4">
              <Mentions
                update={updateCampaignPost}
                tags={activeMentions}
                callback={setactiveMentions}
              />
            </div>
          )}
          {/* <div>3</div> */}
        </Col>
      </Row>
    )
  );
};

export default StoryCampaignInfo;
