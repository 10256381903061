import { Col } from "antd";
import React from "react";

const UserSecurity = () => {
  return (
    <Col span={24} className="py-0 px-0 mb-2">
      <div className="bg-white p-3 px-3 flex space-x-3 items-center">
        <span className="font-bold">PRIVACY AND SAFETY</span>
      </div>
    </Col>
  );
};

export default UserSecurity;
