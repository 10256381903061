// Tinder.js
import { Button } from 'antd';
import React, { useState ,useEffect,useRef,useMemo} from 'react';
import { Heart, HeartPulse, X } from 'react-bootstrap-icons';
import { BsX, BsXCircle } from 'react-icons/bs';
import TinderCard from 'react-tinder-card'

const Tinder = ({ data }) => {
  const [cards, setCards] = useState(data);
  const [animals, setAnimals] = useState([])
  const [userLike, setUserLike] = useState([])
  const [userDisLike, setUserDisLike] = useState([])
  const [currentIndex, setCurrentIndex] = useState(data.length - 1)
  const [lastDirection, setLastDirection] = useState()
  // used for outOfFrame closure
  const currentIndexRef = useRef(currentIndex)

  const childRefs = useMemo(
    () =>
      Array(data.length)
        .fill(0)
        .map((i) => React.createRef()),
    []
  )
  
  useEffect(() => {
    setAnimals(data)
    return data
  },[])
  const updateCurrentIndex = (val) => {
    console.log(val);
    setCurrentIndex(val)
    currentIndexRef.current = val
  }

  function swiped(direction,category,index){
    setLastDirection(direction)
    updateCurrentIndex(index - 1)
    if(direction === 'right')
      setUserLike(userLike => [...userLike,category])
    else
    setUserDisLike(userDisLike => [...userDisLike,category])
  }
  function leftScreen(name){
    console.log(name)
  }
  const outOfFrame = (name, idx) => {
    console.log(`${name} (${idx}) left the screen!`, currentIndexRef.current,currentIndexRef.current >= idx)
    // handle the case in which go back is pressed before card goes outOfFrame
    currentIndexRef.current >= idx && childRefs[idx].current.restoreCard()
    // TODO: when quickly swipe and restore multiple times the same card,
    // it happens multiple outOfFrame events are queued and the card disappear
    // during latest swipes. Only the last outOfFrame event should be considered valid
    if(currentIndexRef.current === -1){
      window.location.reload()
    }
  }
  const canGoBack = currentIndex < data.length - 1

  const canSwipe = currentIndex >= 0
  // console.log(userLike)
  // console.log(userDisLike)
  const swipe = async (dir) => {
    
    if (canSwipe && currentIndex < data.length) {
      await childRefs[currentIndex].current.swipe(dir) // Swipe the card!
    }
  }
  const goBack = async () => {
    
    if (!canGoBack) return
    // const newIndex = currentIndex + 1
    // updateCurrentIndex(newIndex)
    // console.log(data.length - 1);
    // await childRefs[newIndex].current.restoreCard()
  }
  return (
    <div className="tinder-container">
      <div className="cardContainer">
      
      {data.map((animal,index) => (
          <TinderCard
          ref={childRefs[index]}
          key ={animal.name}
          className="swipe"
          preventSwipe = {['up','down']}
          onSwipe = {(direction) => swiped(direction,animal.category,index)}
          onCardLeftScreen = {() => outOfFrame(animal.name,index)}
        >
            <div className="tindercard"
              style ={{backgroundImage: `url(${animal.pictures[0]})`}}>
              <h3>{animal.name}</h3>
            </div>
            
        </TinderCard>
      ))}
      </div>
      <div className='buttons flex justify-between'>
        <Button shape="circle" className='flex items-center justify-center' size='large' icon={<BsX color='blue' size={28} />} style={{ backgroundColor: !canSwipe && '#c3c4d3' }} onClick={() => swipe('left')} />
        {/* <Button style={{ backgroundColor: !canGoBack && '#c3c4d3' }} onClick={() => goBack()}>Undo swipe!</Button> */}
        <Button shape="circle" className='flex items-center justify-center' size='large' icon={<Heart color='red' size={24} />} style={{ backgroundColor: !canSwipe && '#c3c4d3' }} onClick={() => swipe('right')} />
      </div>
      {/* {lastDirection ? (
        <h2 key={lastDirection} className='infoText'>
          You swiped {lastDirection}
        </h2>
      ) : (
        <h2 className='infoText'>
          Swipe a card or press a button to get Restore Card button visible!
        </h2>
      )} */}
    </div>
  );
};

export default Tinder;
