import { Button, Result, Spin, Row } from "antd";
import Utils from "helper/Utils";
import React from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ApiService from "services/ApiService";



const UserSubscriptions = (props) => {
  const navigate = useNavigate()
  const user = Utils.getLocalInfo("userhash");
  const [loading, setloading] = useState(true);
  const [error, seterror] = useState(null);

  const [groups, setgroups] = useState([]);
  const [baseQuery, setbaseQuery] = useState({});
  const [queryParams, setqueryParams] = useState({
    limit: 20,
    offset: 0,
    sort: "desc",
  });
  
  useEffect(() => {
    const params = props["match"]["params"];
    
    setloading(false);
    
    return () => {};
  }, [props]);

  
  if (
    user["role"] === "creator" &&
    parseInt(user["creatorProfileStep"]) !== 5
  ) {
    return navigate("/creator/edit/1") ;//<Redirect to="/creator/edit/1" />;
  }

  return loading ? (
    <div className=" h-100 d-flex justify-center align-items-center bg-white py-3 px-4 rounded-xl ">
      <Spin />
    </div>
  ) : (
    <>
      <div className=" d-flex justify-center flex-col bg-white py-3 rounded-xl ">
        <div className="px-4 d-flex justify-between mb-2 pb-2 border-b">
          <span className="text-hp text-lg font-semibold">Your Subscriptions</span>
          
        </div>
        
        {groups && groups.length === 0 && (
          <div className="px-4 bg-white">
            <Result
              status="500"
              title={baseQuery["tag"] ? `#${baseQuery["tag"]}` : ""}
              subTitle={"No subscriptions yet"}
              extra={
                <Link to={"/"}>
                  <Button type="primary">Back Home</Button>
                </Link>
              }
            />
          </div>
        )}
      </div>
    </>
  );
};

export default UserSubscriptions;
