import React, { useEffect, useState } from "react";
import Utils from "helper/Utils";
import moment from "moment";
import { Button,Modal,notification } from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import ApiService from "services/ApiService";
const { confirm } = Modal;
const HashtagsOffer = (props) => {
  const [processOn, setprocessOn] = useState(false);
  const [userTokens, setuserTokens] = useState(0);
  const [userTokensOg, setuserTokensOg] = useState(0);
  const [showModal, setshowModal] = useState(false);
  const [modalEvent, setmodalEvent] = useState({ event: "mint", item: null });
  const [type, settype] = useState(props.type);
  const [item, setitem] = useState(props.hash);

  const cancelRequest = ()=>{
    confirm({
        title: 'Cancel request - '+item["hashdata"][0].title,
        icon: <ExclamationCircleOutlined />,
        content: `Your ${item["listingType"]==="sale"?"buy":item["listingType"]} offer for this keyword will be cancelled.`,
        async onOk() {
          const datapayload = {hashId:item["hashId"]}
    
          const data = await ApiService.PostApiCalls("keyhash/listing/cancelrequest",{payload:datapayload},true)
          
          if(data[0] && data[1]["IsSuccessful"]){
            setitem({...item,offerStatus:"cancelled"})
            notification["success"]({
              message: 'Your request has been processed.',
            });
          }
          else{
            notification["error"]({
              message: 'Request failed!',
            });
          }
          
        },
        onCancel() {},
      });
  }
  return (
    <div className="flex flex-1 justify-between">
      <div>
        <span className="flex space-x-3 items-center pn-2">
          <h6 className="mb-0">
            {`#${props.hash["hashdata"][0].title.replace(/ /g, "")}`}
          </h6>
        </span>
        <span className="text-xs text-muted px-1">
          {`Added on ${moment
            .unix(props.hash.createdAt)
            .format("MMM DD, YYYY ")}`}
        </span>
        <div className="py-2"></div>

        {item["offerStatus"] === "open" ? (
          <>
            {" "}
            <span className="text-md py-2 mb-2 font-semibold px-2">
              Your request is in process.
            </span>
            <div className="py-2"></div>
            <div className="flex flex-row space-x-3 py-2">
              <Button
                loading={processOn}
                onClick={cancelRequest}
                className="px-3 brd-4 fw-700  rounded-lg text-white bg-hs-pink  hover:border-hs-pink"
              >
                Cancel Request
              </Button>
            </div>
          </>
        ):item["offerStatus"] === "cancelled" ? (
            <>
              {" "}
              <span className="text-md py-2 mb-2 font-semibold px-2">
                Your request is cancelled.
              </span>
              <div className="py-2"></div>
              
            </>
          ) : (
          <>
            {" "}
            <span className="text-md py-2 mb-2 font-semibold px-2">
              Your request has been accepted.
            </span>
            <div className="py-2"></div>
            
          </>
        )}
      </div>
    </div>
  );
};

export default HashtagsOffer;
