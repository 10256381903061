import { Card, List, Avatar } from "antd";
import React from "react";

const { Meta } = Card;

const FeedsCard = ({ posts }) => {
  return (
    <List
      itemLayout="horizontal"
      dataSource={posts}
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
            title={<a href="feeds">{item.name}</a>}
            description={item.members}
          />
        </List.Item>
      )}
    />
  );
};

export default FeedsCard;
