import { Col, Row } from 'antd'
import { AuthContext } from 'app/AuthProvider'
import Web3Sreets from 'container/web3street'
import ListingDetails from 'container/web3street/components/ListingDetails'
import NewBrokerForm from 'container/web3street/components/NewBrokerForm'
import NewListingForm from 'container/web3street/components/NewListingForm'
import { StreetListings } from 'container/web3street/components/StreetListings'
import React,{useContext,useState} from 'react'
import { Container } from 'react-bootstrap'
import { useParams } from 'react-router-dom'

const RealEstate = () => {
    const brParams = useParams()
    const {authUser} = useContext(AuthContext);
    const [alreadyBroker, setalreadyBroker] = useState(authUser)
    const [checking, setchecking] = useState(true)
    console.log(brParams);
  return (
    <>
    
   <Container fluid className='p-0 w-100 mx-0 '>
    <Row gutter={16} className="mx-0">
    <Col span={24} xs={24} sm={24} md={16} lg={18} xl={24} className="mb-3  mx-auto">
        <Web3Sreets params={brParams}  authUser={authUser}  alreadyBroker={alreadyBroker} reView={true} />
        
    </Col>
    </Row>
    </Container>
    </>
  )
}

export default RealEstate