import { Tag, message } from "antd";
import _ from "lodash";
import React from "react";
import HashTag from "./HashTag";
import Linkify from "linkify-react";
import CardTags from "./CardTags";

import ApiService from "services/ApiService";
import { useState } from "react";
import { Link } from "react-router-dom";

const CardText = ({ content,author,postId,shwoFull ,docId,postLocale,fractions,minted}) => {
  const appLocale = localStorage.getItem("appLocale")?localStorage.getItem("appLocale"):"en";
  const [translation, settranslation] = useState(null)
  const [showTranslation, setshowTranslation] = useState(false)
  let tags = _.map(_.filter(content.keywords,function(obj) {return obj.value}), 'value')
  if(tags.length === 0){
    tags = content.keywords 
  }
  
  const translate = async() =>{
    if(translation){
      setshowTranslation(true);
      return false;
    }
    const state = await ApiService.PostApiCalls("translate/post",{id:docId,text:content.caption,locale:appLocale},true)
    if(state[0] && state[1]["IsSuccessful"]){
      settranslation(state[1]["translation"])
      setshowTranslation(true)
    }
    else{
      message.error(state[1]["ErrorMessage"]);
    }
    
  }
  return (
    <>
      <div className="flex flex-col"><div feedtext className="font-normal text-h text-sm mb-2" >
      {shwoFull?<Linkify options={{target: '_blank', style: {color: 'red', fontWeight: 'bold'}}}><p className="whitespace-pre-line">{content.caption}</p></Linkify>
    :<Link to={`/${author.userName}/posts/${postId}`  } class="text-hs-dark" ><Linkify options={{target: '_blank', style: {color: 'red', fontWeight: 'bold'}}}>
      {
        content.caption.length > 280 ?
        <p className="whitespace-pre-line">{`${content.caption.slice(0, 270)}...`} <Link to={`/${author.userName}/posts/${postId}` } class="text-hs-blue">Show More</Link>
      
        </p>:<><p className="whitespace-pre-line">{content.caption}
        
        </p>
        
        </>

      }
      </Linkify></Link>  
    }
      </div>
       
        {tags.length > 0 && <div feedtags className="flex flex-wrap pb-2"><CardTags tags={tags} fractions={fractions} minted={minted} /></div>}
      
      {
        showTranslation && <><p className="pt-0"><span  class="text-hs-blue cursor-pointer text-sm hover:underline  w-auto" onClick={() =>setshowTranslation(false)}>Translated by</span> <a href="https://translate.google.com" target="_blank" className="text-red-500">Google.</a></p><p className="py-0 whitespace-pre-wrap">{translation}</p></>
      }
      {appLocale !== postLocale["lang"] && <p className="py-0">{shwoFull && !showTranslation && <span  class="text-hs-blue cursor-pointer text-sm hover:underline  w-auto" onClick={() =>translate()}>Translate Post</span>}</p>}
      </div></>
  );
};

export default CardText;
