import { Row, Col, Input, Button } from "antd";
import { AuthContext } from "app/AuthProvider";
import React, { useContext, useEffect } from "react";
import { Container } from "react-bootstrap";
import { ToastProvider } from "react-toast-notifications";
import LeftMenu from "./LeftMenu";
import ContentGallery from "./options/ContentGallery";
import AddNewFeed from "./options/AddNewFeed";
import ManageFeed from "./options/ManageFeed";
import ProductsCatalog from "./options/ProductsCatalog";
import DisplaySettings from "./options/DisplaySettings";
import VeridfyShopfiy from "./options/VeridfyShopfiy";
import { useParams } from "react-router-dom";

const SocialCommerce = (props) => {
  const brPrams = useParams();
  const { authUser, activeWorkSpace,setactiveWorkSpace,setAuthUser } = useContext(AuthContext);
  useEffect(() => {
    return () => {};
  }, []);
  return (
    <>
      <ToastProvider>
        <Container className=" bg-white flex-1 h-100  px-2 py-1" fluid>
          <Row gutter={16} className="h-100">
            <Col span={4} className="mb-0 px-0 bg-white   border-l-2">
              <Col span={24} className="py-0 px-0 mb-0">
                <div className="bg-white p-3 px-3 flex space-x-3 items-center">
                  <span className="font-bold">Social Commerce</span>
                  <Button></Button>
                </div>
              </Col>
              <Col span={24} className="px-0  bg-white">
                <LeftMenu
                  user={authUser}
                  activeWorkSpace={activeWorkSpace}
                  setactiveWorkSpace={setactiveWorkSpace}
                  active={brPrams.option}
                  setAuthUser={setAuthUser}
                />
              </Col>
            </Col>
            <Col span={20} className="mb-0 px-0 bg-white border-l-2">
            {brPrams.option === "content-gallery" && <ContentGallery user={authUser} activeWorkSpace={activeWorkSpace} />}
            {brPrams.option === "add-feed" && <AddNewFeed user={authUser} activeWorkSpace={activeWorkSpace} />}
            {brPrams.option === "manage-feed" && <ManageFeed user={authUser} activeWorkSpace={activeWorkSpace} />}
            {brPrams.option === "products-catalog" && <ProductsCatalog user={authUser} activeWorkSpace={activeWorkSpace} />}
            {brPrams.option === "display-settings" && <DisplaySettings user={authUser} activeWorkSpace={activeWorkSpace} />}
            {brPrams.option === "shopify-token" && <VeridfyShopfiy user={authUser} activeWorkSpace={activeWorkSpace} />}
            </Col>
          </Row>
        </Container>
      </ToastProvider>
    </>
  );
};
export default SocialCommerce;
