
import React, { useState } from 'react'
import { Col,Button ,Table,Modal, Switch, message, Checkbox, Input} from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons';

import DisplayFeeds from './views/DisplayFeeds';
import ApiService from 'services/ApiService';

const { confirm } = Modal;
const DisplaySettings = () => {
  const [products, setproducts] = useState([])
  const [loading, setloading] = useState(true);
  const [showPreview, setshowPreview] = useState(false)
  const [displayOptions, setdisplayOptions] = useState({btnLable:"Buy Now",random:true,onlyProducts:false})
  
  const publishFeed = async()=>{
    confirm({
      title: 'Publish Feed?',
      icon: <ExclamationCircleOutlined />,
      content: 'Your feed will be available on HashSaga Web3 Marketplace for searched keywords.',
      async onOk() {
          const added = await ApiService.PostApiCalls("/commerce/feed/publish",{...displayOptions},true)
          if (added[0] && !added[1]["IsSuccessful"]) {
            message.success("Feed published successfully")
            
            
        }
        else {
         
          
        }
      },
      onCancel() {},
    });
  }
  return (
    <>
     <Col span={24} className="py-0 px-0 mb-0">
        <div className="bg-white p-3 px-3 flex space-x-3 items-center justify-between">
          <span className="font-bold">{"Display Settings"}</span>
          {!showPreview && <Button
             
            type="primary"
            className=" rounded  float-right mr-3"
            onClick={()=>setshowPreview(true)}
          >
            Show Preview
          </Button>}
          {showPreview && <div><Button
             
             type="primary"
             className=" rounded  float-right mr-3"
             onClick={()=>setshowPreview(false)}
           >
             Edit Settings
           </Button><Button
             
             type="primary"
             className=" rounded  float-right mr-3"
            onClick={publishFeed}
           >
             Publish
           </Button></div>}
        </div>
      </Col>
      <div className="pb-2 bg-gray-100"></div>
      {!showPreview && <Col span={24} className="p-3 mb-0 ">
        <div className='flex flex-col w-full space-y-4 p-5'>
          <Checkbox checked={displayOptions.random} onChange={(e)=>setdisplayOptions({...displayOptions,random:e.target.checked})} className='ml-0'>Show Random Posts</Checkbox>
          <Checkbox checked={displayOptions.onlyProducts} onChange={(e)=>setdisplayOptions({...displayOptions,onlyProducts:e.target.checked})}  className='ml-0'>Only with Products</Checkbox>
          <span>Button Lable</span>
          <Input value={displayOptions.btnLable} className='p-3 rounded-md w-1/4' onChange={(e)=>setdisplayOptions({...displayOptions,btnLable:e.target.value})}/>

        </div>
      </Col>}
      {showPreview && <Col span={24} className="p-3 mb-0 ">
        <DisplayFeeds displayOptions={displayOptions}/>
      </Col>}
    </>
  )
}

export default DisplaySettings