
import axios from "axios";
import Utils from "../helper/Utils";

const ApiService = {
  
  
  
  GetApiCalls: async (endPoint = "", data = "",authorized=false) => {
    try {
      const path = Utils.apiUrl(endPoint) + "?" + data;
      let res = await axios.get(path, Utils.apiHeaderApp(authorized));
      return [true, res.data];
    } catch (e) {
      return [false, e];
    }
  },
  PostApiCalls: async (endPoint = "", data = {},authorized=false) => {
    try {
      const path = Utils.apiUrl(endPoint);
      
      let res = await axios.post(
        path,
        data,
        Utils.apiHeaderApp(authorized)
      );
      return [true, res.data];
    } catch (e) {
      return [false, e];
    }
  },
  PostCogentApiCalls: async (endPoint = "", data = {},authorized=false) => {
    try {
      const path = Utils.apiCogent(endPoint);
      
      let res = await axios.post(
        path,
        data,
        Utils.apiHeaderApp(authorized)
      );
      return [true, res.data];
    } catch (e) {
      return [false, e];
    }
  },
  
};

export default ApiService;
