import React,{useState} from 'react'
import {Linkedin} from "react-bootstrap-icons"
import { LinkedIn } from 'react-linkedin-login-oauth2';
import ApiService from "services/ApiService"
import { message, Button, Space,Spin,Select } from 'antd';
import {Modal,Row,Col} from "react-bootstrap";
import _ from "lodash";
const { Option } = Select;
const LinkedinAuth = (props) => {
    const [accesstoken,setAccessToken] = useState(null)
    const [accessTokenData,setaccessTokenData] = useState(null)
    const [userinfo,setUserInfo] = useState(null)
    const [userPages,setUserPages] = useState(null)
    const [processing,setprocessing] = useState(true)
    const [show,setShow] = useState(false)
    const [loading,setLoading] = useState(false)
    const [selectedKey,setSelectedKey] = useState(null)
    const handleSuccess = async (data) => {
        
        //getLinkedinToken
        const g = await ApiService.PostApiCalls("linkedin/getLinkedinToken",{data:{code:data.code}})
       
        if(g[0] && g[1]["success"]){
            const Pages = g[1]["pages"];
            const profile = g[1]["userInfo"];
            setUserPages(Pages)
            
            setAccessToken(g[1]["access_token"])
            setaccessTokenData(g[1]["accessTokenData"])
            const details = {
                vanityName: _.get(profile,'vanityName',''),
                id: _.get(profile,'id',''),
                firstName: _.get(profile,'localizedFirstName',''),
                lastName: _.get(profile,'localizedLastName',''),
                profileURL: `https://www.linkedin.com/in/${_.get(profile,'id','')}`,
                pictureURL: _.get(_.last(_.get(profile,'profilePicture.displayImage~.elements','')),'identifiers[0].identifier','')
            }
            setUserInfo({...details,name:details["firstName"]+" "+details["lastName"]})
            setprocessing(false)
            setSelectedKey(0)
            
        }
        else{
            message.error('This is an error while processing request.');
        }
        
      }
    
      const handleFailure = (error) => {
        message.error('This is an error while processing request.');
      }
      const handleChange = (e) =>{
        setSelectedKey(e)
        
        
      }
      const onSaveinfo = async () =>{
        
        if( selectedKey === 0){ 
          let profileSelected = userinfo;
          profileSelected["source"] = "Linkedin";
          profileSelected["access_token"] = accesstoken;
          profileSelected["accessTokenData"] = accessTokenData;
          profileSelected["user_id"] = userinfo["id"];
          profileSelected["type"] = "person";
          profileSelected["fb-picture"] = userinfo["pictureURL"] === ""?"https://hs-container.s3.ap-south-1.amazonaws.com/saga/accounts/profileimages/el5AzILd4s_aK3.jpeg":userinfo["pictureURL"];
          profileSelected["pictureURL"] = userinfo["pictureURL"] === ""?"https://hs-container.s3.ap-south-1.amazonaws.com/saga/accounts/profileimages/el5AzILd4s_aK3.jpeg":userinfo["pictureURL"];
          // profileOwner:{name:response.name,id:response.id,accessToken:token}
          profileSelected["profileOwner"] = {name:userinfo["name"],id:userinfo["id"],accessToken:accesstoken};
          props.callback(profileSelected,[],"Linkedin");
          setShow(false)
      }
      else{
        setLoading(true)
        
        const actualKey = selectedKey - 1;
        //GetProfilePictureforPage getLinkedinPageInfo
        const currentPageid = userPages[actualKey]["id"];
        const g = await ApiService.PostApiCalls("linkedin/getLinkedinPageInfo",{data:{pageId:currentPageid,access_token:accesstoken}})
        const profile = g[1]["data"]
        const  p = _.get(_.last(_.get(profile,'logoV2.cropped~.elements','')),'identifiers[0].identifier','')
        let profileSelected = userPages[actualKey];
        profileSelected["source"] = "Linkedin";
        profileSelected["type"] = "organization";
        profileSelected["access_token"] = accesstoken;
        profileSelected["accessTokenData"] = accessTokenData;
        profileSelected["user_id"] = userPages[actualKey]["id"];
        profileSelected["pictureURL"] = p === ""?"https://hs-container.s3.ap-south-1.amazonaws.com/saga/accounts/profileimages/el5AzILd4s_aK3.jpeg":p;
        profileSelected["fb-picture"] = p === ""?"https://hs-container.s3.ap-south-1.amazonaws.com/saga/accounts/profileimages/el5AzILd4s_aK3.jpeg":p;
        profileSelected["profileOwner"] = {name:userinfo["name"],id:userinfo["id"],accessToken:accesstoken};
        setLoading(false)
        props.callback(profileSelected,[],"Linkedin");
        setShow(false)
      }
      }
    return (
      <>
        <LinkedIn
          clientId="78fy5bicv5ktau"
          onFailure={handleFailure}
          onSuccess={handleSuccess}
          onClick={()=>setShow(true)}
          redirectUri="https://hashsaga.com/linkedin"
          // redirectUri="http://localhost:3001/linkedin"
          // scope={["r_liteprofile","r_emailaddress","w_member_social"]}
          scope={["r_basicprofile","r_emailaddress","w_member_social","r_organization_social","w_organization_social","r_1st_connections_size","rw_organization_admin"]}
          className="twitter-btn"
        >
          <Linkedin color={"#0077b5"} size={45} />
        </LinkedIn>
        <Modal show={show} onHide={() => setShow(false)} centered contentClassName="campaign-new-modal">
            <Modal.Header >
            <span className="modal-title">Select Profile/Page</span>
            </Modal.Header>
            <Modal.Body>
            <Row className="mt-3">
            <Col lg={12} md={12} sm={12} className="campaign-new-info fz-14 fw-600 text-center">
          {processing && <Spin />}
          </Col>
          {!processing && <Col lg={12} md={12} sm={12} className="campaign-new-info fz-14 fw-600 my-4">
          <Select defaultValue={0} defaultActiveFirstOption={true} onChange={handleChange} style={{ width: '100%' }} >
          <Option value={0}>{userinfo.name}</Option>
          {userPages.map((data,key)=>(
            <Option key={key+1} value={key+1}>{data.name}</Option>
          ))}
            
            
         </Select>
          </Col>}
          </Row>
            </Modal.Body>
            {!processing && <Modal.Footer className="mt-4">
           
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button  type="primary" onClick={() => onSaveinfo()} disabled={false} loading={loading}>  
            Save
          </Button>
        </Modal.Footer>}
            </Modal>
        </>
    )
}

export default LinkedinAuth
