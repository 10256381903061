import axios from 'axios';

const baseUrl = "https://hashsaga.com:3002/S3SignRequest"
const baseUrlMPart = "https://hashsaga.com:3002/S3SignRequest"
const MinFileSize = 1024 * 1024 * 5;

// let buffer = 0;
// let numPartsLeft = 0;
let FileBolbsParts = {};
function startUpload(fileSize){
    
    if(fileSize < MinFileSize){
        return "single";
    }
    else {
        return "single";
    }
}
function randomString(length) {
    var chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOP1234567890";
    var pass = "";
    for (var x = 0; x < length; x++) {
        var i = Math.floor(Math.random() * chars.length);
        pass += chars.charAt(i);
    }
    return pass;
}
function GetSingedRequest(filetype,fileName,prifix="images"){
    return new Promise((resolve, reject) => {
        axios.get(baseUrl+"?command=geturl&filename="+prifix+"/"+fileName+"&filetype="+filetype)
        .then(response => {
           resolve(response);
        })
        .catch(error => {
            reject(error);
        });
        });
   

}

function createMultiPart(e){
    const fileExt = e.file.name.split(".").pop();
    const fileName = randomString(10)+"_"+randomString(3)+"."+fileExt;
    console.log(fileName);
    
    const fileInfo = {
        name: 'pyn/'+fileName,
        type: e.file.type,
        size: e.file.size,
        lastModifiedDate: e.file.lastModifiedDate
    };
    return new Promise((resolve, reject) => {
        axios.get(baseUrlMPart,{
            params: {
                command: 'createmultipartupload',
                filename: "pyn/"+fileName,
                filetype:e.file.type,
                fileInfo_name: fileInfo.name,
                fileInfo_type: fileInfo.type,
                fileInfo_size: fileInfo.size,
                fileInfo_sizee: fileInfo.lastModifiedDate,
            }
        }
           )
        .then(response => {
           resolve(response);
        })
        .catch(error => {
            reject(error);
        });
        });
}

function ListPartsAndGetRquest(e,backData){
    let partNum = 0;
    const FileSizeAdded = e.file.size;
    const FileAdded = e.file;
    const fileType = e.file.type;
    const fileName = backData.key;
    const uploadId = backData.uploadId;
    console.log(fileName);
    let promises = [];
    let partBlobobj = {};
    for (var start = 0; start < FileSizeAdded; start += MinFileSize) {
        partNum++;  
        var end = Math.min(start + MinFileSize, FileSizeAdded);       
        const blobs = FileAdded.slice(start, end);
        partBlobobj[partNum]= blobs;
        FileBolbsParts[fileName] = partBlobobj;
           
        promises.push(        
            axios.get(baseUrlMPart+"?command=signuploadpart&filename="+fileName+"&filetype="+fileType+"&blobSize="+blobs.size+"&partNumber="+partNum+"&uploadId="+uploadId+"&filenam="+fileName)
            )
           
        
    }
    Promise.all(promises).then(response => {
        UploadPartsToS3(response);
        
    });
    
}

function UploadPartsToS3(response){
    console.log(response.length);
    for (var i = 0; i < response.length; i++) {
       
        uploadSinglePart(response[i],  i+1);
    }
    
    
}

function uploadSinglePart(res,partNum){
    
    const partBlob = FileBolbsParts[res.data.filename][partNum];
    var options = {
        headers: {
            'Content-Type': res.data.type,
           
        },
    onUploadProgress: progressEvent => console.log(Math.round( (progressEvent.loaded * 100) / progressEvent.total ))  
    };
    axios.put(res.data.url, partBlob, options)
    .then(response => {
        
        console.log(response);
        // CompleteMultiPartUpload(res.data.filename,res.data.uid)
     })
     .catch(error => {
        // alert("sd");
        console.log(error);
     });
}
// function CompleteMultiPartUpload(name,ud){
//     return new Promise((resolve, reject) => {
//         axios.get(baseUrlMPart,{
//             params: {
//                 command: 'completemultipartupload',
//                 key: "pyn/"+name,
//                 uploadId:ud,
                
//             }
//         }
//            )
//         .then(response => {
//            resolve(response);
//         })
//         .catch(error => {
//             reject(error);
//         });
//         });
// }
function UploadSingle(e,uploadres,UploadFinish,UploadFail,id,cropped="",prifix="hs"){
    let ef,fileName;
    if(cropped === ""){
        ef = e.file
        const fileExt = e.file.name.split(".").pop();
        fileName = randomString(10)+"_"+randomString(3)+"."+fileExt.toLowerCase();
    }
    else{
        
        ef = e;
        fileName = randomString(10)+"_"+randomString(3)+".jpg";
    }
    
    
   
    return new Promise((resolve, reject) => {
    GetSingedRequest(ef.type,fileName,prifix)
    .then(response => {
        // return new Promise((resolve, reject) => {
            const cancelTokenSource = axios.CancelToken.source();
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();
        var options = {
            cancelToken: source.token,
            headers: {
                'Content-Type': ef.type
            },
        onUploadProgress: progressEvent => uploadres(Math.round( (progressEvent.loaded * 100) / progressEvent.total ),id,ef.uid,source)  
        };
        
        axios.put(response.data, ef, options)
        .then(response => {
            UploadFinish(response,prifix+"/"+fileName,ef.type,id,ef.uid,fileName);
         })
         .catch(error => {
            UploadFail(error,prifix+"/"+fileName,ef.type,id,ef.uid,fileName);
         });
        // });
     })
     .catch(error => {
         return error;
     });    
     });    
}

function UploadParts(){


}

function CompleteUpload(){

}

export { startUpload,UploadSingle,UploadParts,CompleteUpload,GetSingedRequest,createMultiPart,ListPartsAndGetRquest}