import React,{useEffect, useState} from 'react'
import { Tag, Input,message } from 'antd';
import {XCircle} from "react-bootstrap-icons";
import _ from 'lodash';
import Utils from 'helper/Utils';
import ApiService from 'services/ApiService';
const Mentions = (props) => {
    const [inputValue, setinputValue] = useState("")
    const [defaultTags, setdefaultTags] = useState(props.tags)
    const [hide, sethide] = useState(true)
    useEffect(() => {
      getMenionedForhash()
    
      return () => {
        
      }
    }, [props.tags])
    const getMenionedForhash = async() =>{
        const listed = _.map(props.hashTags,"title")
        
        let hashKeys = [];
        listed.map((k) => {
        hashKeys.push(Utils.getHashId(k, true, true));
        });

        const addedKeyHashes = await ApiService.PostApiCalls(
        "keyhashes/linkedmention",
        { hashKeys: hashKeys },
        true
        );
        
        if (addedKeyHashes[0] && addedKeyHashes[1]["IsSuccessful"]) {
            let listedmention = [];
            for(var i = 0; i < addedKeyHashes[1]["mentions"].length;i++){
                const m = addedKeyHashes[1]["mentions"][i];
                const mm = m["offerOptions"]["mentionHandle"];
                listedmention.push(mm)
            }
            
            setdefaultTags([...defaultTags,...listedmention])
        }
        sethide(false)
    }
    const removeTag = (e,k) => {
        const tags = _.remove(defaultTags, function (time,i) {
            return i !== k;
          });
          props.callback(tags)
          setdefaultTags(tags)
          setTimeout(
            () =>  props.update({m:tags}), 
            500
        );
    }
    const handleInputConfirm = () => {
        if(inputValue.trim() !== ""){
            const tag = inputValue[0] === "@" ?inputValue:inputValue; 
            const isEmpty = _.filter(defaultTags, function (t,i) {                
                return t.toLowerCase() === tag.toLowerCase();
              });
              if(isEmpty.length === 0){  
            const newtgs = [...defaultTags,tag.replace(/\s/g,'')]
            setdefaultTags(newtgs)
            props.callback(newtgs)
            setTimeout(
                () =>  props.update({m:newtgs}), 
                500
            );           
            setinputValue("")
            }else{
                setinputValue("")
                message.error(
                {
                    content: 'Mention is already added',
                    className: 'custom-class',
                    style: {
                      right: '10px',
                      position:"absolute"

                    },duration:2
                  });
            }
        }
        
    }
    return (
        !hide && <><div className="d-flex flex-grow-1 align-items-center flex-wrap" >
            {_.uniq(defaultTags).map((tag,key) =>(
                <Tag closable onClose={(e) =>removeTag(e,key)} className="tags-added mention mb-3 d-flex align-items-center" closeIcon={<XCircle color={"#ef4d74"} size={15} /> } >
                <span>{tag}</span>
        </Tag>
            ))}
            <Input           
            type="text"
            className="tag-input mb-3"
            placeholder="Add profile to be mentioned"
            value={inputValue}
            onChange={(e) => setinputValue(e.target.value)}
            onBlur={handleInputConfirm}
            onPressEnter={handleInputConfirm}
          />
          
        </div>
        <span className='muted text-xs text-muted mt-2'>* Only for X.com, add valid X.com handles.</span>
        </>
    )
}

export default Mentions
