import React,{useState} from 'react'
import { Tag, Input,message,Button } from 'antd';
import {XCircle} from "react-bootstrap-icons";
import _ from 'lodash';
import Utils from 'helper/Utils';
const Tags = (props) => {
    const [inputValue, setinputValue] = useState("")
    const [defaultTags, setdefaultTags] = useState(props.tags)
    const removeTag = (e,k) => {
        const tags = _.remove(defaultTags, function (time,i) {
            return i !== k;
          });
          props.callback(tags)
          setdefaultTags(tags)
          setTimeout(
            () =>  props.update({t:tags}), 
            500
        );
    }
    const handleInputConfirm = () => {
        if(inputValue.trim() !== ""){
            const tag = inputValue[0] === "#" ?inputValue.substring(1):inputValue; 
            const isEmpty = _.filter(defaultTags, function (t,i) {                
                return t.toLowerCase() === tag.toLowerCase();
              });           
            if(isEmpty.length === 0){
            const newtgs = [...defaultTags,tag.replace(/\s/g,'')]
            setdefaultTags(newtgs)
            props.callback(newtgs)
            setTimeout(
                () =>  props.update({t:newtgs}), 
                500
            );
            setinputValue("")
            }else{
                setinputValue("")
                message.error(
                {
                    content: 'Tag is already added',
                    className: 'custom-class',
                    style: {
                      right: '10px',
                      position:"absolute"

                    },duration:2
                  });
            }
        }
        
    }
    const renderTags = (tag,key) =>{
        const tagText = tag.replace(/\s/g,'');
        const id = Utils.getHashId(tagText,true,true);
        const fid = _.filter(props.mintVerfiyList, function(d){
          return d["hashId"] === id
        })
        
        let tagClass = "tags-added mb-3 d-flex align-items-center rounded"
        if(fid[0]){
          
          if(fid[0]["mintedBy"] === props.uid){
            tagClass = "tags-added mb-3 d-flex align-items-center rounded border-hs-pink text-hs-pink"
          }else{
          tagClass = "tags-added mb-3 d-flex align-items-center rounded border-hs-blue text-hs-blue"
        }
        }
        if(tagText.length > 1){
            return <Tag key={key} closable onClose={(e) =>removeTag(e,key)} className={tagClass} closeIcon={<XCircle color={"#ef4d74"} size={14} /> } ><span>{tag[0] === "#" ?tagText:"#"+tagText}</span></Tag>
        }
        
    }
    return (
        <div>
        <div className="d-flex flex-grow-1 align-items-center flex-wrap" >
            {defaultTags.map((tag,key) =>
                
                renderTags(tag,key)
        
            )}
            <Input           
            type="text"
            className="tag-input mb-3"
            placeholder="#write hashtags and hit enter"
            value={inputValue}
            onChange={(e) => setinputValue(e.target.value)}
            onBlur={handleInputConfirm}
            onPressEnter={handleInputConfirm}
          />

          
        </div>
        <span className="py-2"></span>
         {props.isAdmin && <span className="py-2"><Button
                 
                 type="primary"
                //  size="large"
                 onClick={()=> props.activateHashes()}
                 className="px-3 brd-4 fw-700 mx-2 rounded-lg border-0 flex items-center"
               >
                 # Hash
               </Button></span>}
        </div>
    )
}

export default Tags
