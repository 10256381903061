import React from 'react'
import Tinder from './Tinder';


  
const SwipeCards = ({data}) => {
  console.log(data);
  return (
    <Tinder data={data} />
  )
}

export default SwipeCards