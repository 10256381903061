import { Button } from 'antd';
import moment from 'moment';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import ApiService from 'services/ApiService';

const CommentCard = ({content}) => {
    const [comment, setcomment] = useState(content)
    const [liked, setliked] = useState(content["userLiked"] && content["userLiked"][0]?true:false)
    useEffect(() => {
        // setcomment(content)
        // setliked(content["userLiked"] && content["userLiked"][0]?true:false)
      return () => {
        
      }
    }, [content,liked])
    const addLike =async()=>{
        const reaction = liked?0:1;
        const likeAdded = await ApiService.PostApiCalls(`posts/${comment["contentID"]}/reaction`,{reaction:reaction,isComment:true,commentId:comment["_id"]},true);
        if(likeAdded[0] && likeAdded[1]["IsSuccessful"]){
            setliked(!liked)
            setcomment(likeAdded[1]["comment"])
            
        }
    }
  return (

    <div className='flex items-center  px-4 py-1 '>
        <div className='action-profile rounded-full bg-red-400 overflow-hidden mr-1.5'>
            {/* <div className='w-100 h-100 bg-center bg-cover' style={{backgroundImage: `url(${comment["author"][0]["profilePicture"]})`,}}></div> */}
            {comment["author"][0]["profilePicture"] !==""? <div
          className="w-100 h-100 bg-center bg-cover"
          style={{
            backgroundImage:
              `url("${comment["author"][0]["appMode"] && comment["author"][0]["appMode"] === "privacy"?'https://static.thenounproject.com/png/2910477-200.png':comment["author"][0]["profilePicture"]}")`,
          }}
        ></div>:<span className="head-notificationsBox flex items-center w-100 h-100 justify-center text-white text-2xl ">{comment["author"][0]["profileDisplayName"][0]}</span>}
        </div>
        <div className='flex-1 '>
        <div className='text-comment flex flex-1 flex-column box-radius-large bg-gray-100 py-2 px-3'>
        <Link  to={`/${comment["author"][0]["userName"]}`}>
          <span className='text-decoration-none text-xs text-ht font-semibold text-current'>
            {comment["author"][0]["appMode"] && comment["author"][0]["appMode"] === "privacy"?comment["author"][0]["uniqueId"]:comment["author"][0]["profileDisplayName"]}
            </span></Link>
        <span className='whitespace-pre-line' >{comment["comment"]}</span>
        </div>
        <div className='px-2'>
        <span  className='text-xs text-hlt font-semibold mr-1'>{comment["likes"]}</span> 
         <span><Button onClick={addLike} type="link" className={liked?'text-xs text-hb font-semibold px-0':'text-xs text-hlt font-semibold px-0'} >Like</Button></span> 
         <span className='text-xs text-hlt font-semibold ml-2'>{moment.unix(comment["createdAt"]).fromNow()}</span>

        </div>
        </div>
    </div>
  )
}

export default CommentCard