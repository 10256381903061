import React,{useState} from 'react'
import {Circle,CheckCircleFill} from "react-bootstrap-icons"
import _ from 'lodash';
import { Input,Button } from 'antd'
import EditableQuote from "./EditableQuote"
const { TextArea } = Input;
const Quotes = (props) => {
    
    const [activeQuotes, setactiveQuotes] = useState(props.activeQuotes);
    const [selected,setSelected] = useState(props.selectedQuotes);
    const [newQuote,setnewQuote] = useState("");

    const submitQuote = () => {
        const tmp = [...selected,activeQuotes.length];        
        const newQuotes = [...activeQuotes,newQuote];
        setactiveQuotes(newQuotes)
        // props.setMinQuotesNo(newQuotes.length)
        props.callback(newQuotes);
        setTimeout(
            () =>  props.update({q:newQuotes}), 
            500
        );
        setnewQuote("")
    }
    const checkQuote = (key) =>{
        if (!selected.includes(key)){
           const tmp = [...selected,key];
           setSelected(tmp);
           props.callbackSelected(tmp);
           setTimeout(
            () =>  props.update(), 
            500
        );
        }
        else{
            const remainingimages = _.remove(selected, function (img,i) {
                return img !== key;
              });
              
              setSelected(remainingimages)
              props.callbackSelected(remainingimages);
              setTimeout(
                () =>  props.update(), 
                500
            );
        }
        
    }
    const updateText = (key,newtext) =>{
        let old = activeQuotes;
        let newitems = [];
        for(let i=0;i<old.length; i++){
            newitems.push(i===key?newtext:old[i])
        }
        setactiveQuotes(newitems);
        props.callback(newitems);
        setTimeout(
            () =>  props.update({q:newitems}), 
            500
        );
    }
    return (
        <div className="d-flex flex-grow-1 align-items-center flex-wrap">
        <div className="d-flex flex-grow-1 align-items-start flex-column mb-4 px-2">
        <TextArea
          placeholder="Write quote..."
          autoSize={{ minRows: 2, maxRows: 3 }}
          className="mb-3"
          value={newQuote}
          onChange={(e) => setnewQuote(e.target.value)}
        />
        <Button type="primary" onClick={submitQuote} disabled={newQuote.trim().length === 0?true:false}>Add</Button>
        </div>
        {
            activeQuotes.map((activeQuote,key) =>(
                <div className="post-quote" key={key} >
                   <div onClick={() => checkQuote(key)}>{ selected.includes(key) ? <CheckCircleFill color="#30B67C" size={24} /> :<Circle color="##A6A5A7" size={24} />}</div>
                    <span className={ selected.includes(key) ?"ml-2 w-full selected":"ml-2 w-full"}><EditableQuote updateText={updateText} k={key} text={activeQuote}/></span>
                    </div>
            ))

        }
        
         
        </div>
    )
}

export default Quotes
