import React,{useState,useEffect} from 'react'
import { Clock,Trash } from 'react-bootstrap-icons';
import { TimePicker } from 'antd';
import moment from 'moment';

export const TimePickerView = (props) => {
    const [selectedTime,setSelectedTime] = useState(props.time)
    
    useEffect(() => {
        
       
        setSelectedTime(props.time)
      return () => {
        // effect
      };
    }, [props.time])
    const format = 'HH:mm';
    const onChange = (time, timeString) =>{
        setSelectedTime(timeString)       
        props.changeTime(props.kindex,timeString);
    }
    return (
        <>
        
        <div id={`timeo-${selectedTime}`} className="in input in d-flex flex-row justify-content-between align-items-center">
        <span><TimePicker suffixIcon={null} minuteStep={1} allowClear={false} inputReadOnly={true} onChange={onChange} value={moment(selectedTime, format)} format={format} /></span>
        <div className={props.len === 1 ?"bt-icon ": "bt-icon clock"}> <Clock color={"#55acee"} size={14} /></div>
        {props.len > 1 && <div onClick={() => props.removeItem(props.kindex)} className="bt-icon bin"> <Trash color={"red"} size={14} /></div>}
        </div>
        </>
    )
}
