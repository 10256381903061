import React, { useState, useEffect } from "react";
import { Container, Row, Col, Accordion, Card, Modal } from "react-bootstrap";
import { Button, Alert, Checkbox, Spin, Select } from "antd";
import CardHeader from "./views/CardHeader";
import Tags from "./views/Tags";
import Mentions from "./views/Mentions";
import Images from "./views/Images";
import Quotes from "./views/Quotes";

import { Link, useNavigate } from "react-router-dom";
import _ from "lodash";
import {
  ImageIcon,
  HashtagIcon,
  MentionIcon,
  QuoteIcon,
} from "res/icons/ReviewIcons";
import ApiService from "services/ApiService";
import Utils from "helper/Utils";
import { Coin } from "react-bootstrap-icons";
import MineHashList from "components/Reusable/MineHashList";
import { useTranslation } from 'react-i18next';
import { HASH_DEFAULT_CHARGE, HASH_DEFAULT_GASFEE } from "constants/hashkeys.constant";
import BuyHashCard from "container/common/BuyHashCard";

const { Option } = Select;
const StaticPost = ({
  campaign,
  duration,
  minQuotes,
  url,
  scheduleList,
  slug,
  profilesSelected,
  uid,authUser
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation();
  
  const [activeKey, setCurrentKey] = useState(0);
  const [activeTags, setActiveTags] = useState(campaign["posts"]["tags"]);
  const [activeMentions, setactiveMentions] = useState(
    campaign["posts"]["mentions"]
  );
  const [activeImages, setactiveImages] = useState(campaign["posts"]["images"]);
  const [activeQuotes, setactiveQuotes] = useState(campaign["posts"]["quotes"]);
  const [selectedQuotes, setselectedQuotes] = useState(
    campaign["posts"]["selectedQoutes"]
      ? campaign["posts"]["selectedQoutes"]
      : _.map(
          _.chunk(_.shuffle(campaign["posts"]["quotes"]), minQuotes + 2)[0],
          function(k, v) {
            return v;
          }
        )
  );
  const [minQuotesNo, setMinQuotesNo] = useState(minQuotes);
  const [building, setbuilding] = useState(false);
  const [posts, setPosts] = useState(campaign["posts"]);
  const [apiError, setApiError] = useState(null);
  const [showHash, setshowHash] = useState(false);
  const [loadingHashes, setloadingHashes] = useState(true);
  const [mintedKeyhash, setmintedKeyhash] = useState([]);
  const [userTokens, setuserTokens] = useState(0);
  const [userTokensOg, setuserTokensOg] = useState(0);
  const [ActiveImages, setActiveImages] = useState([]);
  const [checkedHash, setcheckedHash] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [processOn, setprocessOn] = useState(false);
  const [mintKeywords, setmintKeywords] = useState([]);
  const [mintVerfiyList, setmintVerfiyList] = useState([]);
  const [needMoreTokens, setneedMoreTokens] = useState(false)
  const [showBuyView, setshowBuyView] = useState(false)
  const [prceedBuy, setprceedBuy] = useState(true)
  const [buyValue, setbuyValue] = useState(0);
  useEffect(() => {
    setMinQuotesNo(minQuotes);
    getMintedList();
    
    return () => {};
  }, [minQuotes]);

  const getMintedList = async () => {
    let hashKeys = [];
    activeTags.map((k) => {
      hashKeys.push(Utils.getHashId(k, true, true));
    });

    const addedKeyHashes = await ApiService.PostApiCalls(
      "keyhashes/list",
      { hashKeys: hashKeys },
      true
    );
    if (addedKeyHashes[0] && addedKeyHashes[1]["IsSuccessful"]) {
      setmintVerfiyList(addedKeyHashes[1]["hashDetails"]);
    }
  };
  
  const updateCampaignPost = async (res = {}) => {
    
    let postContent = {};
    postContent["quotes"] = res["q"] ? res["q"] : activeQuotes;
    postContent["selectedQoutes"] = selectedQuotes;
    postContent["title"] = posts["title"];
    postContent["tags"] = res["t"] ? res["t"] : activeTags;
    postContent["mentions"] = res["m"] ? res["m"] : activeMentions;
    postContent["images"] = res["i"] ? res["i"] : activeImages;
    postContent["duration"] = duration;
    postContent["type"] = posts["type"];
    postContent["drip"] = posts["drip"];
    postContent["url"] = posts["url"];
   
    let hashKeys = [];
    postContent["tags"].map((k) => {
      hashKeys.push(Utils.getHashId(k, true, true));
    });

    const addedKeyHashes = await ApiService.PostApiCalls(
      "keyhashes/list",
      { hashKeys: hashKeys },
      true
    );
    if (addedKeyHashes[0] && addedKeyHashes[1]["IsSuccessful"]) {
      setmintVerfiyList(addedKeyHashes[1]["hashDetails"]);
      const data = {
        cid: campaign["cid"],
        updates: { posts: postContent, selectedProfiles: profilesSelected,mintedHashs:addedKeyHashes[1]["hashDetails"] },
      };
      await ApiService.PostApiCalls(
        "campaign/update/" + campaign["cid"],
        { ...data },
        true
      );
    }
    else{
      const data = {
        cid: campaign["cid"],
        updates: { posts: postContent, selectedProfiles: profilesSelected,mintedHashs:[] },
      };
      await ApiService.PostApiCalls(
        "campaign/update/" + campaign["cid"],
        { ...data },
        true
      );
    }
    
   
  };
  const buildCampaignPost = () => {
    setbuilding(true);
    setTimeout(() => start(), 1000);
  };
  const start = async () => {
    setApiError(null);
    let postContent = {};
    postContent["quotes"] = activeQuotes;
    postContent["selectedQoutes"] = selectedQuotes;
    postContent["title"] = posts["title"];
    postContent["tags"] = activeTags;
    postContent["mentions"] = activeMentions;
    postContent["images"] = activeImages;
    postContent["duration"] = duration;
    postContent["type"] = posts["type"];
    postContent["drip"] = posts["drip"];
    postContent["url"] = posts["url"];
    postContent["postcount"] = minQuotes;
    postContent["socialProfiles"] = profilesSelected;
    const data = {
      cid: campaign["cid"],
      posts: postContent,
      campaign: campaign,
    };

    const updated = await ApiService.PostApiCalls(
      "campaign/build/" + campaign["cid"],
      { ...data },
      true
    );

    if (updated[0] && updated[1]["IsSuccessful"]) {
      navigate(url + "posts");
    } else {
      setApiError("Error while processing request");
    }
    setbuilding(false);
  };
  const AccordionList = [
    {
      label: t("accordian1"),
      icon: <HashtagIcon className="mr-3" />,
      secondaryLabel: t("accordian1s"),
    },
    {
      label: t("accordian2"),
      icon: <MentionIcon className="mr-3" />,
      secondaryLabel: t("accordian2s"),
    },
    {
      label: t("accordian3"),
      icon: <ImageIcon className="mr-3" />,
      secondaryLabel:t("accordian3s"),
    },
    {
      label:t("accordian4"),
      icon: <QuoteIcon className="mr-3" />,
      secondaryLabel: t("accordian4s"),
    },
  ];
  const activateHashes = async () => {
    setmintedKeyhash([]);
    setmintKeywords([]);
    const hashKeys = [];
    let tmpmintKeywords = [];
    activeTags.map((k) => {
      hashKeys.push(Utils.getHashId(k, true, true));
      const tmp = {
        value: k.replace(/\s/g, ""),
        code: "721",
        copies: null,
      };
      tmpmintKeywords.push(tmp);
    });
    setmintKeywords(tmpmintKeywords);
    const addedKeyHashes = await ApiService.PostApiCalls(
      "keyhashes/list",
      { hashKeys: hashKeys },
      true
    );
    if (addedKeyHashes[0] && addedKeyHashes[1]["IsSuccessful"]) {
      setmintedKeyhash(addedKeyHashes[1]["hash"]);
      setuserTokens(addedKeyHashes[1]["user"]["hashTokens"]);
      setuserTokensOg(addedKeyHashes[1]["user"]["hashTokens"]);
      setcheckedHash([])
      setCheckAll(false)
      setloadingHashes(false);
    } else {
      setloadingHashes(false);
    }

    setshowHash(true);
  };
  const checkedAll = (e, id, t) => {
    let hashes = checkedHash;
    let leftToken = 0;
    if (!e) {
      _.remove(hashes, function(n) {
        return n == id;
      });
      setuserTokens(userTokens + t);
      leftToken = userTokens + t;
    } else {
      setuserTokens(userTokens - t);
      leftToken = userTokens - t;
      hashes.push(id);
    }
    if(leftToken < 0){
      setneedMoreTokens(true)
      setbuyValue(Math.abs(leftToken))
    }
    setcheckedHash(hashes);
  };
  const selectAllTokenCode = (e) => {
    if (e === "1155") {
      const newArr = _.map(mintKeywords, function(a, k) {
        return { ...a, code: "1155", copies: 10 };
      });
      setmintKeywords(newArr);
    } else {
      const newArr = _.map(mintKeywords, function(a, k) {
        return { ...a, code: "721", copies: null };
      });
      console.log(newArr);
      setmintKeywords(newArr);
    }
  };

  const onCheckAllChange = (e) => {
    let tokens = userTokensOg;
    if (e.target.checked) {
      let hashUpdates = [];
      activeTags.map((k) => {
        const id = Utils.getHashId(k, true, true);
        if (!mintedKeyhash.includes(Utils.getHashId(k, true, true))) {
          hashUpdates.push(id);
          tokens = tokens - (HASH_DEFAULT_CHARGE+HASH_DEFAULT_GASFEE);
        }
      });
      if(tokens < 0){
        setneedMoreTokens(true)
        
        setbuyValue(Math.abs(tokens));

      }
      setcheckedHash(hashUpdates);
      setuserTokens(tokens);
    } else {
      setcheckedHash([]);
      setuserTokens(userTokensOg);
    }

    setCheckAll(e.target.checked);
  };
  const mintHashes = async (now) => {
    if(userTokens < 0){
      Utils.showNotification(
        "warning",
        "Oops!",
        "You do not have required hash tokens to process."
      );
    return;

    }
    let hashUpdates = [];
    mintKeywords.map((k) => {
      const id = Utils.getHashId(k["value"], true, true);
      if (checkedHash.includes(id)) {
        const hash = {
          title: k["value"],
          code: k["code"],
          copies: k["copies"],
          fraction:k["fractions"]?true:false,
          fractionValues:k["fractions"]?k["fractions"]:0,
          hashId: id,
          minted: now,
          tokensCharged:(HASH_DEFAULT_CHARGE+HASH_DEFAULT_GASFEE)
        };
        hashUpdates.push(hash);
      }
    });
    setprocessOn(true);
    const minted = await ApiService.PostApiCalls(
      "keyhashes/mint",
      { hashKeys: hashUpdates, userToken: now ? userTokens : userTokensOg,hashVisual:activeImages[0]?[{...activeImages[0],visualType:"image"}]:[] },
      true
    );
    if (minted[0] && minted[1]["IsSuccessful"]) {
      Utils.showNotification(
        "success",
        "Done",
        "Hash have been sent for approval"
      );
      // setshowUpload(false);
      setshowHash(false);
      setCheckAll(false);
      setuserTokensOg(now ? userTokens : userTokensOg);
      setcheckedHash([]);
    } else {
      Utils.showNotification(
        "success",
        "Done",
        "Hash have been sent for approval"
      );
    }
    setprocessOn(false);
    setshowHash(false);
  };
  const updateTagsAdded = (i, tag) => {
    const newArr = _.map(mintKeywords, function(a, k) {
      return k === i ? tag : a;
    });
    setmintKeywords(newArr);
  };
  const updateBuyBack = (val)=>{
  
    setuserTokens(userTokens+val)
    setuserTokensOg(userTokensOg+val)
    setneedMoreTokens(false);
    setshowBuyView(false)
  }
  return (
    <>
      <Container fluid="true" className="px-0">
        <Row className="mx-0 px-4">
          <Col className="px-0" lg={12} md={12} sm={12}>
            <Row className="mx-0">
              <Col className="px-0 fz-18 fw-700" lg={12} md={12} sm={12}>
                {t("addcontent")}
              </Col>
              <Col className="px-0 fz-14 fw-600" lg={12} md={12} sm={12}>
                {t("addcontentp1")} 
                <span className="color-blue font-weight-bold text-hs-dark"> {t("addcontentp3")} </span>{" "}
                {t("addcontentp3")} 
              </Col>
            </Row>
          </Col>
          <Col className="px-0 py-3" lg={12} md={12} sm={12}>
            <Accordion>
              {AccordionList.map((a, key) => (
                <Card className="my-3" key={key + 1}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey={key + 1}
                    onClick={() => setCurrentKey(key + 1)}
                  >
                    <CardHeader
                      cp={campaign}
                      activeTags={
                        key === 0
                          ? (activeTags?activeTags.length:0)
                          : key === 1
                          ? (activeMentions?_.uniq(activeMentions).length:0)
                          : key === 2
                          ? activeImages.length
                          : selectedQuotes.length
                      }
                      activeKey={activeKey}
                      icon={a.icon}
                      text={a.label}
                      eventKey={key + 1}
                      secondaryLabel={a.secondaryLabel}
                      minQuotesNo={minQuotesNo}
                    />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={key + 1}>
                    <Card.Body>
                      {key === 0 && (
                        <Tags
                          uid={uid}
                          activateHashes={activateHashes}
                          mintVerfiyList={mintVerfiyList}
                          update={updateCampaignPost}
                          tags={activeTags}
                          callback={setActiveTags}
                          isAdmin={authUser.isAdmin}
                        ></Tags>
                      )}
                      {key === 1 && (
                        <Mentions
                          update={updateCampaignPost}
                          tags={activeMentions}
                          callback={setactiveMentions}
                          hashTags={mintVerfiyList}
                        />
                      )}
                      {key === 2 && (
                        <Images
                          campaign={campaign}
                          update={updateCampaignPost}
                          activeImages={activeImages}
                          callback={setactiveImages}
                          tags={mintVerfiyList}
                        />
                      )}
                      {key === 3 && (
                        <Quotes
                          update={updateCampaignPost}
                          setMinQuotesNo={setMinQuotesNo}
                          activeQuotes={activeQuotes}
                          minQuotesNo={minQuotesNo}
                          callback={setactiveQuotes}
                          callbackSelected={setselectedQuotes}
                          selectedQuotes={selectedQuotes}
                        />
                      )}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
            </Accordion>
          </Col>

          <Col className="px-0 fz-14 my-4 fw-600" lg={12} md={12} sm={12}>
            <Row className="mb-4">
              <Col lg={6}>
                {profilesSelected.length === 0 && (
                  <span>
                    <Alert
                      message="No social profile has been selected"
                      type="error"
                    />
                  </span>
                )}
                {apiError && (
                  <span>
                    <Alert message={apiError} type="error" />
                  </span>
                )}
              </Col>
              <Col lg={6}>
                <Button
                  type="primary"
                  onClick={buildCampaignPost}
                  className="float-right"
                  loading={building}
                  disabled={
                    selectedQuotes.length >= minQuotesNo
                      ? profilesSelected.length === 0
                        ? true
                        : false
                      : true
                  }
                >
                  {t("addcontentp2")}
                </Button>
                <Link to={`/creator/${slug}/campaigns`}>
                  <Button type="link" className="float-right">
                    
                  {t("addcontentp4")}

                  </Button>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {showHash && (
        <Modal
          show={showHash}
          onHide={() => {
            setshowHash(false);
            // activateHashes(false);
          }}
          centered size="lg"
          contentClassName="campaign-new-modal rounded-2xl p-0"
          backdrop={"static"}
        >
          <Modal.Header className="mp-2 p-2 border-bottom ">
            <div className="flex flex-row w-full justify-between space-x-4 p-2 font-semibold items-center">
              <span>Manage Keywords</span>
              <span className>
                {" "}
                Your Tokens -{" "}
                <span className="text-hs-pink"> {userTokens}</span>
              </span>
            </div>
          </Modal.Header>
          <Modal.Body className={`ct-image overflow-scroll p-3 ${showBuyView?"max-h-full":"max-h-96"}`}>
            {/* <div className="py-2"></div> */}
            {loadingHashes && (
              <div className="h-32 flex items-center justify-center">
                <Spin />
              </div>
            )}
            {!loadingHashes && <>{showBuyView ?<div className="flex flex-col space-y-3">
            
            
            <BuyHashCard backCallback={()=>{setshowBuyView(!showBuyView);setprceedBuy(true)}}   defaultValue={buyValue} tokens={buyValue} successCallback={updateBuyBack} />
           </div>:<><span className="font-semibold text-hs-blue">Minted Hash</span>
            {!loadingHashes && (
              <div className="py-2">
                <div className="flex flex-col">
                  {mintKeywords.map(
                    (k, i) =>
                      mintedKeyhash.includes(
                        Utils.getHashId(k["value"], true, true)
                      ) && (
                        <div
                          key={i}
                          className="flex flex-col p-2 border rounded-md text-hs-pink "
                        >
                          {`#${k["value"].replace(/ /g, "")}`}
                        </div>
                      )
                  )}
                </div>
              </div>
            )}
            <div className="py-1"></div>
            <span className="font-semibold text-hs-blue">Avilable Hash</span>
            <Checkbox
              className="ml-3"
              indeterminate={false}
              onChange={onCheckAllChange}
              checked={checkAll}
            >
              Check all
            </Checkbox>
            <Select
              defaultValue="721"
              style={{ width: 120 }}
              size={"small"}
              onChange={selectAllTokenCode}
            >
              <Option value="721">721</Option>
              <Option value="1155">1155</Option>
            </Select>
            {!loadingHashes && (
              <div className="py-2">
                <div className="flex flex-col">
                  {mintKeywords.map(
                    (k, i) =>
                      !mintedKeyhash.includes(
                        Utils.getHashId(k["value"], true, true)
                      ) && (
                        <MineHashList
                          callback={updateTagsAdded}
                          checkedHash={checkedHash}
                          checkedAll={checkedAll}
                          key={i}
                          id={i}
                          k={k}
                        />
                      )
                  )}
                </div>
              </div>
            )}</>}</>}
            <div className="py-2"></div>
          </Modal.Body>
          {!showBuyView && <Modal.Footer className=" justify-end space-x-4 border-top py-2 px-3">
            <>
              <Button
                onClick={() => {
                  setshowHash(false);
                }}
                className="px-3 brd-4 fw-700 mx-2 rounded-lg hover:text-hs-pink hover:border-hs-pink"
              >
                Cancel
              </Button>
              {needMoreTokens && <Button
              type="primary"
              onClick={()=>{setshowBuyView(!showBuyView);}}
              className="px-3 brd-4 fw-700 mx-2 rounded-lg border-0 flex items-center"
            >
              Buy Hash
            </Button>}
              {checkedHash.length > 0 && (
                <Button
                  loading={processOn}
                  disabled={needMoreTokens}
                  onClick={() => {
                    mintHashes(true);
                  }}
                  className={`px-3 brd-4 fw-700 mx-2 rounded-lg text-white bg-hs-pink  hover:border-hs-pink ${needMoreTokens? "opacity-50":"" }`}
                >
                  Mint Now
                </Button>
              )}
              {/* {checkedHash.length > 0 && <Button
                  loading={processOn}
                  onClick={() => {
                    mintHashes(false)
                  }}
                  className="px-3 brd-4 fw-700 mx-2 rounded-lg text-hs-pink-dark border-hs-pink hover:text-hs-pink hover:border-hs-pink"
                >
                  Mint Later
                </Button>} */}
            </>
          </Modal.Footer>}
        </Modal>
      )}
    </>
  );
};

export default StaticPost;
