import React,{useState,useEffect} from 'react'
import {Row,Col} from "react-bootstrap";
import {Input,Slider,Button,InputNumber,message } from 'antd';
import {CalendarCheck,ChevronDown}  from "react-bootstrap-icons"
import moment from 'moment';
const ScheduleComp = ({data,newpost,callBack,akey,saving}) => {
    const [name,SetName] = useState(data ? data.name:"");
    const [duration,Setduration] = useState(data ? data.duration:30);
    const [postCount,SetpostCount] = useState(data ? data["postCount"]:0);
    const [distribution,Setdistribution] = useState(data ? data.distribution:30);
    const [dayList,setDaylist] = useState(data ? data.days:[]);
    // const [saving, setsaving] = useState(false)
    useEffect(() => {
        if(data){
        SetName(data.name)
        Setduration(data.duration)
        SetpostCount(data["postCount"])
        Setdistribution(data.distribution)
        setDaylist(data.days)}
        return () => {
            
        }
    }, [data])
    
    const changeDaysList = (e,postCountnew,durationnew) =>{
        if(postCountnew === 0){
            return false;
        }
        const daysSlot = durationnew / 3;
        let postPerslot = {};
        if(e <= 33){
            postPerslot[0] = parseInt(postCountnew*.60);
            postPerslot[1] = parseInt((postCountnew-postPerslot[0])/2);
            postPerslot[2] = parseInt(postCountnew-(postPerslot[0]+postPerslot[1]));
        }
        else if(e > 33 && e <= 66){
            postPerslot[0] = parseInt(postCountnew*.33);
            postPerslot[1] = parseInt((postCountnew)*.33);
            postPerslot[2] = parseInt(postCountnew-(postPerslot[0]+postPerslot[1]));
        }
        else if(e >  66){
            postPerslot[2] = parseInt(postCountnew*.60);
            postPerslot[1] = parseInt((postCountnew-postPerslot[2])/2);
            postPerslot[0] = parseInt(postCountnew-(postPerslot[2]+postPerslot[1]));
        }
        let newDays = [];
        let startDay = 0;
        for(let i = 0; i < 3; i++){
            const vs = parseInt(daysSlot/postPerslot[i]);
            for(let j = 0;j<postPerslot[i];j++){
                newDays.push(startDay);
                startDay = startDay+parseInt(daysSlot/postPerslot[i])
            }
            
            
        }
        newDays[postCountnew-1] = duration;
        setDaylist(newDays)
    }
    const onChangeSlider = (e) =>{
        changeDaysList(e,postCount,duration)
        Setdistribution(e)
    }
    const handlePostCountChengae = (e) =>{
       
        if(!newpost){
            
        if(e > parseInt(data["duration"])){
            return false;
        }
        else if(e < parseInt(data["postCount"])){
            return false;
        }
        else{
            changeDaysList(distribution,e,duration)
            SetpostCount(e)
        }
        }
        else{
            
            if(e > parseInt(duration)){
                return false;
            }
            changeDaysList(distribution,e,duration)
            SetpostCount(e)
        }
    }
    const saveSchdule = ()=>{
        if(!newpost){
        const newdata = {
            ...data,
            days:dayList,
            duration:duration,
            postCount:parseInt(postCount),
            distribution:distribution,
            name:name
        }
        callBack(newdata,akey);
    }
    else{
        if(name.trim().length === 0){
            message.error(
                {
                    content: 'Enter name',
                    className: 'custom-class',
                    duration:2,top:100
                  });
            return false;
        }
        if(postCount === 0){
            message.error(
                {
                    content: 'Post count must be more than zero',
                    className: 'custom-class',
                    duration:2,top:100
                  });
                  return false;
        }
        const newdata = {

            name:name,
            id:moment().unix(),
            code:moment().unix().toString(),
            days:dayList,
            duration:duration,
            postCount:parseInt(postCount),
            distribution:distribution,
            createdAt:moment().unix()
        }
        callBack(newdata,0);
    }
        
       
    }
    const onUpdateDuration = (days)=>{
        Setduration(days)
        changeDaysList(distribution,postCount,days)
    }
    return (
        <Col className="px-0 "
             lg={12}      
             md={12}
             sm={12} >
             <Row className="mt-3 mx-0">
          <Col lg={6} md={12} sm={12} className="campaign-new-info fz-14 fw-600 mb-md-3 mb-sm-3">
          <span className="mb-2 float-left">Schedule Name</span>          
          <Input disabled={!newpost} placeholder="Add name" value={name} onChange={(e) =>SetName( e.target.value)}  />
          </Col><Col lg={6} md={12} sm={12} className="campaign-new-info fz-14 fw-600 mb-md-3 mb-sm-3"></Col>
          <Col lg={4} md={12} sm={12} className="campaign-new-info fz-14 fw-600 mb-md-3 mb-sm-3">
          <span className="mb-2 float-left">Campaign duration (days)</span>          
          <Input type="number" disabled={!newpost}  placeholder="Duration (days)"  onChange={(e) => onUpdateDuration( e.target.value)}  value={duration}   />
          </Col>
          <Col lg={3} md={12} sm={12} className="campaign-new-info fz-14 fw-600 mb-md-3 mb-sm-3">
          <span className="mb-2 float-left">No. Of Posts</span>          
          <Input type="number" placeholder="Posts"  onChange={(e) =>handlePostCountChengae( e.target.value)} value={postCount} min   />
          </Col>
          <Col lg={5} md={12} sm={12} className="campaign-new-info fz-14 fw-600 mb-md-3 mb-sm-3 px-0">
          <span className="mb-2 float-left">Post Distribution</span> 
          <span className="mb-2 float-left w-100 d-flex justify-content-between">
          <p className="post-distribution-info fz-12 mr-2">More at the start</p>
          <Slider value={distribution} className="col-lg-6"  onChange={(e) =>onChangeSlider(e)} />
          <p className="post-distribution-info fz-12 ml-2">More at the end</p>      
          </span> 
          </Col>
          <Col lg={12} md={12} sm={12} className="campaign-new-info fz-14 fw-600 mb-md-3 mb-sm-3">
          <span className="mb-2 float-left">Posts schedule</span></Col>   
          <Col lg={12} md={12} sm={12} className="campaign-new-info fz-14 fw-600 mb-md-3 mb-sm-3">
          {
            dayList.map((v,i) =>(
                <span className="post-days p-2 mr-2 mb-3 d-flex align-items-center float-left" key={i}>
                <CalendarCheck size={14} className="mr-1"/>
                <span className="mx-1">{v}</span>
                {/* <ChevronDown color="#1694E3" size={12} className="ml-3"/> */}
                </span>
            ))
          }
          
          </Col>
          {/* <Col lg={12} md={12} sm={12} className="campaign-new-info fz-14 fw-600 mb-md-3 mb-sm-3">
              <Button type="primary" ghost>Add Day</Button>
          </Col> */}
          <Col lg={12} md={12} sm={12} className="campaign-new-info fz-14 fw-600 mb-md-3 mb-sm-3">
             
          </Col>
          <Col lg={12} md={12} sm={12} className="campaign-new-info fz-14 fw-600 mb-md-3 mb-sm-3">
              <Button className="float-right fz-14 fw-600 mx-3" type="primary" loading={saving} onClick={()=>saveSchdule()} >Save</Button>
              <a href="/"><Button className="float-right fz-14 fw-600" >Back to Campaigns</Button></a>
          </Col>
          </Row>
             </Col>
    )
}

export default ScheduleComp
