import { Button, Divider, Slider, Upload,notification } from 'antd';
import React from 'react'
import { useRef } from 'react';
import { useState } from 'react';
import ReactAvatarEditor from 'react-avatar-editor'
import { Modal } from 'react-bootstrap';
import { Dash, DashCircle, Plus, PlusCircle } from 'react-bootstrap-icons';
import ApiService from 'services/ApiService';
import { UploadSingle } from 'services/FileUpload';
const AvatarEditor = ({pic,gid,callback,modalCallback,action}) => {
    
    const imgEditor = useRef(null)
    const [currentScale, setcurrentScale] = useState(1)
    const [uploading, setuploading] = useState(false)
    const [settings, setsettings] = useState({
        image: pic+"?x=100",
        allowZoomOut: false,
        position: { x: 0.5, y: 0.5 },
        scale: 1,
        rotate: 0,
        borderRadius: 240/2,
        preview: null,
        width: 240,
        height: 240,
  
    })
    const handleNewImage = e => {
        setsettings({ ...settings,image: e.target.files[0] })
      }
    
      const handleScale = e => {
        setcurrentScale(e)
        const scale = parseFloat(e)
        setsettings({ ...settings,scale:scale })
      }
    
      const handlePositionChange = position => {
        
        setsettings({ ...settings,position:position })
      }
    const updateScale = (scale) =>{
            if(scale && currentScale < 3){
                setsettings({ ...settings,scale:currentScale+0.1 })
                setcurrentScale(currentScale+0.1)
            }
            else if(!scale && currentScale > 1){
                setsettings({ ...settings,scale:currentScale-0.1 })

                setcurrentScale(currentScale-0.1)
            }
    }
    const dataURItoBlob = (dataURI) =>{
        var binary = atob(dataURI.split(',')[1]);
        var array = [];
        for(var i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
        }
        return new Blob([new Uint8Array(array)], {type: 'image/jpeg',name:"unamed.jpg"});
    }
    const uploadFinish = async (value,name,type,cid,uid) =>{
        let imagepath = "https://hs-container.s3.ap-south-1.amazonaws.com/" + name
        
        //group/update creator/setup/update
        const update = await ApiService.PostApiCalls(action,{id:gid,update:{profilePicture:imagepath}},true)
        if(update[0] && update[1]["IsSuccessful"]){
            setuploading(false)
            callback("profilePicture",imagepath)
            modalCallback();
            notification["success"]({
                message: 'Profile updated',
                description:
                  'Profile image has been updated.',
              });

        }
        else{
            setuploading(false)
            notification["error"]({
                message: 'Oopss!!',
                description:
                  'Something went wrong, please try again.',
              });
        }
    }
    const uploadFail = (res) =>{
        
    }
    const uploadProgress = (res) =>{
        
    }
    const saveImage = () =>{
        if (imgEditor) {
            // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
            // drawn on another canvas, or added to the DOM.
            const canvasImge = imgEditor.current.getImageScaledToCanvas().toDataURL("image/png");
            var blobData = dataURItoBlob(canvasImge);
            setuploading(true);
            UploadSingle(blobData,uploadProgress,uploadFinish,uploadFail,1,"cropped","hashapp/profile-images/")
            .then(res => {          
           
            })
            .catch(error => {            
            });
          }
    }
    const customUploadRequest =(e) => {
        
        console.log(e.file.size);
        setsettings({ ...settings,image: e.file })
    }
  return (
    <>
    <div className='flex flex-col'>
        <div className='flex justify-center'>
        <Upload  customRequest={customUploadRequest} accept="image/*"
          showUploadList={false}>
    <Button icon={<Plus size={22} className="mr-2" />} className="rounded-md border-0 px-5 flex justify-between items-center font-normal bg-hs-pink text-white">Upload Photo</Button>
  </Upload>
        </div>
        <Divider />
        <div className='bg-gray-400 w-100 flex justify-center'>
    <ReactAvatarEditor
              ref={imgEditor}
              scale={parseFloat(settings.scale)}
              width={settings.width}
              height={settings.height}
              position={settings.position}
              onPositionChange={handlePositionChange}
              rotate={parseFloat(settings.rotate)}
              borderRadius={settings.width / (100 / settings.borderRadius)}
              image={settings.image}
              className="bg-gray-400 scale-50"
              crossOrigin="anonymous"
            />
            </div>
            
        <div className='p-4 flex items-center'>
            <Button  onClick={() =>updateScale(false)} className='rounded-full flex justify-around items-center ' icon={<Dash size={20} />} />
            <Slider  defaultValue={1} onChange={handleScale} className="flex-grow mx-3"
          min={settings.allowZoomOut ? 0.1 : 1}
          max={3}
          value={currentScale}
          step={0.01}/>
          
          <Button onClick={() =>updateScale(true)} className='rounded-full flex justify-around items-center ' icon={<Plus size={20} />} />
          </div>
        </div>
        <Modal.Footer>
        <Button onClick={modalCallback} className='rounded-md'type='text' >Cancel</Button>
        <Button  className='rounded-md px-6' loading={uploading}  onClick={() =>saveImage()} type="primary" >Save</Button>
        </Modal.Footer>
        
     </>
  )
}

export default AvatarEditor