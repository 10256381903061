import { Tabs } from "antd";
import React from "react";
import {
  BlockquoteLeft,
  Film,
  Image,
  InfoCircle,
  MusicNote,
  Palette,
  Textarea,
} from "react-bootstrap-icons";
import TabView from "components/Reusable/TabView";
const { TabPane } = Tabs;
const LeftTabs = ({callback}) => {
  return (
    <Tabs
      defaultActiveKey="1"
      type="card"
      className="h-full w-full"
      tabPosition="left"
      centered
      onChange={callback}
    >
      <TabPane
        className="w-full"
        tab={<TabView lable="Quotes" icon={<BlockquoteLeft />} />}
        key="1"
      ></TabPane>
      <TabPane
        tab={<TabView lable="Media" icon={<Image />} />}
        key="2"
      ></TabPane>
      <TabPane
        tab={<TabView lable="Music" icon={<MusicNote />} />}
        key="3"
      ></TabPane>
      <TabPane
        tab={<TabView lable="Style" icon={<Palette />} />}
        key="4"
      ></TabPane>
      <TabPane
        tab={<TabView lable="Format" icon={<Film />} />}
        key="5"
      ></TabPane>
      <TabPane
        tab={<TabView lable="Info" icon={<InfoCircle />} />}
        key="6"
      ></TabPane>
    </Tabs>
  );
};

export default LeftTabs;
