import React from 'react'
import { PatchCheckFill } from 'react-bootstrap-icons'

const ProfileName = ({profileName,verfied,classnames,privacyMode,appMode}) => {
  return (
    appMode === "privacy"?<div className="flex flex-row items-center space-x-2">
    <span className={classnames}>
     Private User
    </span>
    
  </div>:<div className="flex flex-row items-center space-x-2">
          <span className={classnames}>
            {profileName}
          </span>
          {!verfied && <PatchCheckFill color={"#0079FF"} />}
        </div>
  )
}

export default ProfileName