import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import hashlogo from "assets/images/hash-logo.png";
import { Switch,Menu, Dropdown, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { Search, Bell, ChevronCompactDown, Translate } from "react-bootstrap-icons";
import BluLogo from 'assets/BleLogo';
import { useEffect } from 'react';

// import Logo from "assets/images/Logo@2x.png"
const NavBarHome = () => {
  const [privacyMode, setprivacyMode] = useState(false)
  const { t } = useTranslation();
  const changeLocale = (locale) =>{
    localStorage.setItem('appLocale',locale);

    window.location.reload();


}
useEffect(() => {
  const modein = localStorage.getItem("appUsageMode")
  if(modein){
    setprivacyMode(modein === "privacy"?true:false)
  }

  return () => {
    
  }
}, [])

const updatePrivacyMode = (mode) =>{
  
  localStorage.setItem('appUsageMode',mode?"privacy":"earning");
  setprivacyMode(mode)
}
  const usermenu =  (
    <Menu className="mt-0">
         <Menu.Item className="p-2 pr-4" onClick={()=>changeLocale("en")} >
      <span className="ml-3" >English</span>  
      </Menu.Item>
      <Menu.Item className="p-2 pr-4" onClick={()=>changeLocale("ja")} >
      <span className="ml-3" >Japanese</span>  
      </Menu.Item>
      <Menu.Item className="p-2 pr-4" onClick={()=>changeLocale("de")} >
      <span className="ml-3" >Deutsch</span>  
      </Menu.Item> <Menu.Item className="p-2 pr-4" onClick={()=>changeLocale("fr")} >
      <span className="ml-3" >Français</span>  
      </Menu.Item> 
       <Menu.Item className="p-2 pr-4" onClick={()=>changeLocale("hi")} >
      <span className="ml-3" >हिन्दी</span>  
      </Menu.Item>
    </Menu>
  );
    return (
        <nav className="flex fixed w-full items-center justify-between flex-wrap px-2 pt-2 home-bg z-20">
          
  <Col className="flex items-center text-center  flex-shrink-0 text-white" xs={12} sm={12} md={12} lg={12} xl={12}>
  
  <Link to={"/"}><span className="text-h text-2xl p-0 font-semibold flex items-center space-x-2">
            {/* <BluLogo /> */}
            <img src={hashlogo} className="w-8" />
            <span className="text-white">HashSaga</span>
          </span></Link> 
  </Col>
  
  <Col className="w-full flex justify-end items-end"  xs={12} sm={12} md={12} lg={12} xl={12}>
    <div className="flex items-center text-sm lg:flex-grow pr-4 justify-end">
    <Switch className='bg-hs-pink ' checkedChildren={t("Earning Mode")} onChange={(checked)=>updatePrivacyMode(!checked)} checked={!privacyMode} unCheckedChildren={t("Privacy Mode")} />
      {/* <a href="#responsive-header" className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4">
        Docs
      </a>
      <a href="#responsive-header" className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4">
        Examples
      </a>*/}
      <a href="#responsive-header" className="block  lg:inline-block lg:mt-0 text-teal-200 ml-2">
      
      <Dropdown overlay={usermenu} placement="bottomRight" trigger={['click']}>
        {/* <User color="#ffffff" /> */}
        <div className='flex bg-light rounded-full items-center justify-center w-8 h-8' ><Translate /></div>
        </Dropdown> 
      </a> 
      
    </div>
    <div>
      
    </div>
  </Col>
</nav>
    )
}

export default NavBarHome
