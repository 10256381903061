import React from 'react';
import ReactDOM from 'react-dom/client'
import i18n from './i18n/config';
import './index.css';
import './iconsmixed.css';
import './newstyle.css';
import AppProivder from 'app/index';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";



const root = ReactDOM.createRoot(document.getElementById('root'))

// const root = createRoot(container);
// ReactDOM.render(
//   // <React.StrictMode>
//     <AppProivder />,
//   // </React.StrictMode>,
//   document.getElementById('root')
// );
root.render(
  // <React.StrictMode>
    
        <AppProivder />
     
  // </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
