import React, { useState } from 'react'
import { Button, Col, Divider, Row, notification } from 'antd';
import { Camera, CameraFill } from 'react-bootstrap-icons';
import { Modal } from "react-bootstrap";
import AvatarEditor from 'container/common/AvatarEditor';
import CoverEditor from 'container/common/CoverEditor';
import DatingPicEditor from 'container/common/DatingPicEditor';
import ApiService from 'services/ApiService';

const style = {
    background: '#0092ff',
    padding: '8px 0',
  };
const DatingProfilePictures = ({profile}) => {
    const [pictures, setpictures] = useState(profile.pictures?profile.pictures:[])
  const [editType, seteditType] = useState(false)
  const [picModalVisible, setpicModalVisible] = useState(false);
  const [currentIndex, setcurrentIndex] = useState(0)
  const handleOk = (state,p) => {
    seteditType(state)
    setcurrentIndex(p)
    setpicModalVisible(true);
  };
  const handleClose = () => {
    
    setpicModalVisible(false);
  };
    const pics = [0,1,2,3,4]
    const updateProfile = async (type,img) =>{
        let picUpdate = [...pictures,img];
        if(editType){
            let addedPics = pictures;
            addedPics[currentIndex] = img;
            picUpdate = addedPics
        }
       
            setpictures(picUpdate)
            const saved  = await ApiService.PostApiCalls(
                "dating-profiles/update",
                {pictures:picUpdate},
                true
              );
              if (saved[0] && !saved[1]["IsSuccessful"]) {
                handleClose()
                notification["success"]({
                    message: 'Something went wrong, please try again.',
                    description:""
                  });
              }
              else{
                
                
                  handleClose()
                
                //   
              }
    }
    
  return (
    <>
    <div className='px-4'>
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
        {
            pics.map((pic) =>
                pictures[pic]?
                <Col key={pic} className="gutter-row mb-3" span={6}>
                    <div  className='h-48 rounded border-dashed border-2 border-light-blue-500 relative'>
                    <div
        className="relative inset-0 h-48  w-full rounded bg-center bg-cover flex flex-col items-start justify-end z-20"
        style={{
          backgroundImage: `url("${pictures[pic]}")`,
        }}
      ></div>
      <Button onClick={()=>handleOk(true,pic)} className="rounded-full bg-gray-200 flex justify-around items-center font-normal absolute -right-2 -bottom-2 z-20" icon={<CameraFill className="text-hs-dark" size={18} />} ></Button>
                    </div></Col>
                :
                
                <Col key={pic} className="gutter-row mb-3" span={6} onClick={()=>handleOk(false,pic)}>
                    <div  className='h-48 rounded border-dashed cursor-pointer border-2 border-light-blue-500 flex items-center justify-center'>
                        <Camera size={38} />
                    </div></Col>
            )
        }
      
    </Row>
</div>
<Modal show={picModalVisible} onHide={handleClose} dialogClassName={"max-w-screen-md "}>
    <Modal.Header closeButton className="text-center justify-center" >
    <Modal.Title className="text-center h5 text-hs-dark flex-grow">Update profile picture</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-0">
          
          <DatingPicEditor pic={pictures[currentIndex]?pictures[currentIndex]:""} action="user/update" callback={updateProfile} gid={profile.uniqueId} modalCallback={handleClose} />
         
          
          </Modal.Body>
        
      </Modal></>
  )
}

export default DatingProfilePictures