


import React, { useState } from 'react'
import { Button, Breadcrumb, Spin, Row, Col, Card ,Tabs} from "antd";

import { Link } from 'react-router-dom';
import DisplayWall from './DisplayWall';
const { TabPane } = Tabs;
const SocialWidgets = () => {
    const [loading, setloading] = useState(false)
    const [activeTab, setactiveTab] = useState("1")
    const [view, setview] = useState("1")
    return loading ? (
        <div className=" h-100 d-flex justify-center align-items-center bg-white py-3 px-4 rounded-xl ">
          <Spin />
        </div>
      ) : (
        <>
          <Row gutter={16}>
            <Col span={24} className="mb-3">
              <div className=" d-flex justify-center flex-col bg-white py-3 mb-3 rounded-xl ">
                <div className="px-4 d-flex flex-col justify-between mb-2 pb-2 border-b">
                  <span className="text-hp text-lg font-semibold"><Breadcrumb>
    
    <Breadcrumb.Item>
      <Link to={"/social-shop"}>Social Shop</Link>
    </Breadcrumb.Item>
   
    <Breadcrumb.Item>Widget</Breadcrumb.Item>
  </Breadcrumb></span>
                  <span className="text-hp text-xs">
                  Embed Social Feeds, UGC, & Reviews On Webpages
                  </span>
                </div>
    
               {view === "1" && <div className='flex items-start justify-between py-2 px-0'>
               <Col span={14} className="mb-3"><div className='w-100 flex flex-col space-y-2'>
               <span className="text-hp text-lg font-semibold py-2 border-b">Getting started in 3 steps</span>
                <div className='py-2'>
                <Tabs onChange={(key)=>setactiveTab(key)} defaultActiveKey={activeTab} activeKey={activeTab} activeTab={activeTab} tabPosition={"left"} >
                <TabPane tab={<span>Add Feed</span>} key="1">
                <div className='w-100 flex flex-col space-y-2'>
                <h4>Add Feeds In Your Social Wall</h4>
                <span className="text-hp text-xs">
                After authentication, you will get your social feeds successfully</span>
                <div>
                <ul style={{listStyle:"disc"}} className="px-2 space-y-2">
                    <li >Click On Start Now Below</li>
                    <li>Choose Social Platform To Collect Feeds</li>
                    <li>Fill The Details & Complete Authentication</li>
                    
                </ul>
                <span className="text-hp text-xs">
                After authentication, you will get your social feeds successfully</span>
                </div>
                <div className='mt-3'>
                <Button onClick={()=>setactiveTab("2")} className=' fw-700 my-3 rounded-lg text-white bg-hs-pink  hover:border-hs-pink'>Start Now</Button>
                </div>
                    </div>
                 </TabPane>
                 <TabPane tab={<span>Personalize</span>} key="2">
                 <div className='w-100 flex flex-col space-y-2'>
                <h4>Customize Your Social Wall</h4>
                <span className="text-hp text-xs">Make your social wall more exciting, engaging, and appealing with diverse designing & filter options.</span>
                <div>
                <ul style={{listStyle:"disc"}} className="px-2 space-y-2">
                    <li >Moderate & Filter Content of Your Wall</li>
                    <li>Choose Wall Theme, Layout, & Style</li>
                    <li>Show Banner, Announcements, & More</li>
                    
                </ul>
                    
                </div>
                <div>
                <Button onClick={()=>setactiveTab("3")} className=' fw-700 my-3 rounded-lg text-white bg-hs-pink  hover:border-hs-pink'>Customize</Button>
                </div>
                    </div>
                 </TabPane>
                 <TabPane tab={<span>Display Wall</span>} key="3">
                 <div className='w-100 flex flex-col space-y-2'>
                <h4>Display Your Social Wall</h4>
                <span className="text-hp text-xs">
                Now it’s time to display your social wall on digital screens be it in events, signages, virtual events, or anywhere else</span>
                <div>
                <ul style={{listStyle:"disc"}} className="px-2 space-y-2">
                    <li >Click On Display Button</li>
                    <li>Choose The Preferred Display Option</li>
                    <li>Follow The Steps As Per Your Display Type</li>
                    
                </ul>
                    
                </div>
                <div>
                <Button onClick={()=>setactiveTab("3")} className=' fw-700 mx-2 rounded-lg  border-hs-pink hover:text-white hover:bg-hs-pink hover:border-hs-pink'>Display Wall</Button>
                <Button onClick={()=>setview("2")} className=' fw-700 mx-2 rounded-lg text-white bg-hs-pink  hover:border-hs-pink'>Preview Display</Button>
                </div>
                    </div>
                 </TabPane>
                 
        </Tabs>
                </div>
                </div></Col>
                <Col span={10} className="mb-3"> <div>
                <div className="px-4 d-flex flex-col justify-between mb-2 pb-2 border-b">
                <span className="text-hp text-lg font-semibold pb-2">What is social shop?</span>
                  <span className="text-hp text-xs">
                  <iframe className='w-100' height={"255"} src="https://www.youtube.com/embed/H_VznoiLSdM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </span>
                </div>
               
                </div></Col>
               </div>}
              
               {view === "2" && <div className='flex items-start justify-between py-2 px-0'>
               <Col span={24} className="mb-3"><div className='w-100 flex flex-col space-y-2'>
               <span className="text-hp text-lg font-semibold py-2 border-b">Shoppable Gallery Preview</span>
                <div className='p-1 bg-gray-100 rounded overflow-hidden'>
                   <DisplayWall />
                </div>
                <div className='py-2'>
                <Button  onClick={()=>setview("1")} className=' fw-700 mx-2 rounded-lg text-white bg-hs-pink  hover:border-hs-pink'>Back</Button>
                </div>
                </div></Col>
                
               </div>}
              </div>
             
            </Col>
            <Col span={24} className="mb-3">
            <div className=" d-flex justify-center flex-col bg-white p-3 py-4 mb-3 rounded-xl ">
            <div className='flex items-center'>
            <span className='px-2 font-medium'>If you have any questions then you can schedule a demo with our experts or explore the support center to get the step by step guide for Social Shop.</span>
            <span className='flex space-x-2'>
            <Button className='rounded-md' size='large'>Example Gallery</Button>
            <Button size='large' className=' fw-700 mx-2 rounded-lg text-white bg-hs-pink  hover:border-hs-pink'>Book A Demo</Button>
            </span>
            </div>
            </div>
            </Col>
          </Row>
        </>
      );
}

export default SocialWidgets