import { Button, Result } from 'antd'
import React, { useEffect ,useState} from 'react'


import { StreetListings } from './components/StreetListings';
import NewListingForm from './components/NewListingForm';
import NewBrokerForm from './components/NewBrokerForm';
import StreetHeader from './components/StreetHeader';
import { ArrowLeft } from 'react-bootstrap-icons';
import {
  setDefaults
} from "react-geocode";

import { AuthContext } from "../../app/AuthProvider";
import { injectReducer } from 'store/index'
import reducer from './store'
import { useContext } from 'react';
import ApiService from 'services/ApiService';
import { Link } from 'react-router-dom';
import { toggleEditProfile } from './store/stateSlice';
import ListingDetails from './components/ListingDetails';
injectReducer('webStreet', reducer)

setDefaults({
  key: "AIzaSyCCzuq4fr8br2VOXY2-90VAYm9HmKCPqLo", // Your API key here.
  language: "en", // Default language for responses.
  region: "es", // Default region for responses.
});
const allLablesIcons = {
  "null":{"title":"Properties near you","subtitle":"",back:false},
  "new-listing":{"title":"Property Listing","subtitle":"View or add new property",back:<ArrowLeft />},
  "broker-registration":{"title":"Broker","subtitle":"Create or manage your profile",back:<ArrowLeft />},
  "property":{"title":null,back:<ArrowLeft />},
}



const Web3Sreets = ({params,reView}) => {

    const {authUser} = useContext(AuthContext);
    const [alreadyBroker, setalreadyBroker] = useState(null)
    const [checking, setchecking] = useState(true)
    
    const [position, setPosition] = useState({ latitude: null, longitude: null });
    
    useEffect(() => {
        if(authUser){
          checkBrokerProfile()
        }
        else{
          setchecking(false)
        }
    }, [authUser]);
    const checkBrokerProfile = async()=>{
      const result = await ApiService.PostApiCalls("street/broker/verify",{},true)
      
      if(result[0] && result[1]["IsSuccessful"]){
        setalreadyBroker(result[1]["d"])
      }
      setchecking(false)
    }
    if(checking){
      return(<></>)
    }
    
  return (
    reView?<>
    <StreetHeader authUser={authUser} alreadyBroker={alreadyBroker}  data={!params.action?allLablesIcons["null"]:alreadyBroker?{title:alreadyBroker.fullname,subtitle:""}:allLablesIcons[params.action]} showExtra={!params.action?"home":authUser?params.action:false} />
    {params.state === "listings" && <StreetListings back={"/re/view/"} authUser={authUser}  alreadyBroker={alreadyBroker} />}
        {params.state === "new-listing" && <NewListingForm authUser={authUser} alreadyBroker={alreadyBroker} />}
        {params.state === "view" && <div className="p-4 pb-4 mt-2 "><ListingDetails authUser={authUser} alreadyBroker={alreadyBroker} /></div>}
        {params.state === "activities" && <NewBrokerForm authUser={authUser}  alreadyBroker={alreadyBroker} />} </>
    :<>
    {params.action !== "property" && <StreetHeader authUser={authUser} alreadyBroker={alreadyBroker}  data={!params.action?allLablesIcons["null"]:alreadyBroker?{title:alreadyBroker.fullname,subtitle:""}:allLablesIcons[params.action]} showExtra={!params.action?"home":authUser?params.action:false} />}
   {!params.action && <StreetListings authUser={authUser} alreadyBroker={alreadyBroker}/>}
   {params.action && params.action === "new-listing" && <NewListingForm authUser={authUser} alreadyBroker={alreadyBroker} />}
   {params.action && params.action === "property" && <ListingDetails authUser={authUser} alreadyBroker={alreadyBroker} />}
   {params.action && params.action === "activities" && <NewBrokerForm authUser={authUser}  alreadyBroker={alreadyBroker} />}
   
   </>
  )
}

export default Web3Sreets