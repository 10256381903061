import React,{useState,useEffect,useContext} from 'react'
import {Row,Col} from "react-bootstrap";
import { Select } from 'antd';
import TextEditorView from "./TextEditorView"
import ApiService from "services/ApiService"
import { VideoContext } from 'app/VideoProvider';

const { Option } = Select;
const TextView = () => {
    const {videoId,setVideoClipType,videoClips, setVideoClips,videoActiveClip} = useContext(VideoContext);
    
    const [currentTextOption,SetTextOption] = useState(videoClips[videoActiveClip] && videoClips[videoActiveClip]["cliptype"]?videoClips[videoActiveClip]["cliptype"]:"text")
    
    useEffect(() => {
        SetTextOption(videoClips[videoActiveClip]&& videoClips[videoActiveClip]["cliptype"]?videoClips[videoActiveClip]["cliptype"]:"text")
        // SetTextOption(videoClips[videoActiveClip]?videoClips[videoActiveClip]["cliptype"]:"text")
    },[videoActiveClip,videoClips])
    const handleChange = (value) => {
        let existingClips = videoClips;
        let newClips = []
            for(let i = 0; i < 5; i++){
                let tmp = existingClips[i] ? existingClips[i] :{}
                if(videoActiveClip === i ){
                    
                    tmp["cliptype"] = value;  
                                
                }
                newClips.push(tmp);
            }
            
        updateServer({clips:newClips})
        setVideoClips(newClips);    
        SetTextOption(value)
        setVideoClipType(value)
      }
      const updateServer = async(input) =>{
        const data = {
            vid:videoId,
            update:input
        }
        // await ApiService.updateVideoData(data);
        await ApiService.PostApiCalls("campaign/videoUpdate",{...data},true)
    }
    return (
        <Row className="mx-0">
         <Col lg={12}  md={12} sm={12} className="py-1 text-left" >
         <Select size={"small"} defaultValue="text" style={{ width: 120 }} value={currentTextOption} onChange={handleChange}>
            <Option value="text">Text</Option>
            <Option value="title">Title Clip</Option>
            {/* <Option value="quote" disabled>Quote</Option>
            <Option value="tweet" disabled>Tweet</Option> */}
        </Select>{currentTextOption === "title" &&<span className="ml-3 fz-10 fw-600">Video opening with bigger font.</span>}</Col>
        <Col lg={12}  md={12} sm={12} className="py-1 text-left" >
            {currentTextOption === "text" && <TextEditorView show={"text"} type={currentTextOption} />}
            {currentTextOption === "title" && <TextEditorView show={"title"} type={currentTextOption}/>}
        </Col>
        </Row>
    )
}

export default TextView
