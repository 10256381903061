import { Alert } from 'antd'
import React, { useEffect } from 'react'

const AutoCloseAlert = ({duration,type,message,callback}) => {
    useEffect(() => {
        setTimeout(
            () =>  {console.log("close");callback();}, 
            duration
        );
    
      return () => {
        
      }
    }, [duration,type,message])
    const handleClose = () => {
        // callback();
      };
  return (
    <Alert message={message}  afterClose={handleClose} className="py-2" type={type}/>
  )
}

export default AutoCloseAlert