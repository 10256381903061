import React, { useEffect ,useState} from 'react'
import ApiService from 'services/ApiService'
import CustomPostCard from './CustomPostCard'

const RecentPosts = ({np,activeWorkSpace}) => {
    const [recentPosts, setrecentPosts] = useState([])
    useEffect(() => {
        getPosts()
        return () => {
            setrecentPosts([])
        }
    }, [])
    const getPosts = async()=>{
        setrecentPosts([])
        const list = await ApiService.PostApiCalls("campaign/list-posts",{limit:2,wid:activeWorkSpace["wid"]},true)
        setrecentPosts(list[1]["post"])
    }
    return (
        <div className="d-block h-full p-4 bg-white border-1 shadow rounded-xl ">
            {recentPosts.length === 0 && <div className="flex h-full items-center justify-center">
                <span className="text-gray-400 font-normal text-xl">{np}</span></div>}
                {recentPosts.length > 0 && <div className="grid grid-cols-1 gap-5 ">
              {recentPosts.map((feed, key) => (
                  <div
                    key={key}
                    className="p-2 bg-white border-1 shadow rounded-xl"
                  >
                    <CustomPostCard  content={feed} id={key} type={1} rv={"Review"} hideVisual={true} />
                  </div>
                ))}
              </div>}</div>
    )
}

export default RecentPosts
