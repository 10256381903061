import React from 'react'
import {Facebook,Linkedin,Twitter,Instagram} from "react-bootstrap-icons"

const CalendarEvent = (props) => {
    
    const checkEvent = (d) =>{
       
        // props.callback(p);
    }
    const renderIcon = (s) =>{
        if(s === "Facebook"){
            return  <Facebook className="sicon "  color="#4267B2" />
        }
        else if(s === "Linkedin"){
            return  <Linkedin className="sicon" color="#0e76a8" />
        }
        else if(s === "Twitter"){
            return  <Twitter className="sicon" color="#00acee"/>
        }
        else if(s === "Instagram"){
            return  <Instagram className="sicon" color={"#fbad50"}/>
        }
    }

    return (

        <div onClick={() => checkEvent(props)} className="calendar-event-content">
        {
            props.event["profilesource"].length === 1 && <div className="calendar-event-image single flex items-center justify-center">
            {
                props.event["profilesource"].map((s) => 
                    renderIcon(s))
            }
        </div>
        }
        {props.event["profilesource"].length > 1 &&<div className="calendar-event-image multipule">
            {
                props.event["profilesource"].map((s) => 
                    renderIcon(s))
            }
        </div>}
         <div className="calendar-event-text">{props.title}</div>   
        </div>
    )
}

export default CalendarEvent
