import React,{useState,useContext} from 'react'
import {Modal,Row,Col,OverlayTrigger,Tooltip} from "react-bootstrap";
import { Button,Spin ,Input,Select , Radio  } from 'antd';
import { FileEaselFill,InfoCircle } from 'react-bootstrap-icons';
import md5 from 'md5';
import { useTranslation } from 'react-i18next';

import _ from "lodash"
import Utils from 'helper/Utils';
import ApiService from 'services/ApiService';
import { AuthContext } from 'app/AuthProvider';

const { Option } = Select;
const CreateNewCampaign = (props) => {
  
  const [show, setShow] = useState(props.showModalFirst);
  const [campaignDetails, setCampaignDetails] = useState({"campaign-post":"static-post"})
  const [campaignSchedule, setCampaignSchedule] = useState(null)
  const [buttonOff, setButton] = useState(false)
  const [isProgressing,setProgressing] = useState(false)
  const [isError,setError] = useState(false)
  const {userDetails,activeWorkSpace} = useContext(AuthContext);
  const user = props.user;
  const { t } = useTranslation();

    const [name, setName] = useState("");
    const [URL, setURL] = useState("");
    const [drip, setDrip] = useState("");
    const [postType, setpostType] = useState("static-post");
    const [duration, setDuration] = useState("");
    const [isLinkValid, setLinkValid] = useState(true);
    const [urlAdded, seturlAdded] = useState(false)
    const [linkMetaInfo, setlinkMetaInfo] = useState(null);
    const [isYouTubeVideo, setisYouTubeVideo] = useState(false)
    const [videoAddedInProcess, setvideoAddedInProcess] = useState(false);
    const [TranslationLanguage, setsetTranslationLanguage] = useState({value:"na",lable:"No Tranlation"})
    const [contentLanguage, setcontentLanguage] = useState("")
    const [spotifyUrl, setspotifyUrl] = useState("")
    const wid = user["workspace"][0]["wid"];
    // console.log(user);
    const schedList = [
        {
          "name": "18 Month Horizon",
          "code": "horizon",
          "duration": 544,
          "postCount": 17,
          "days": [
            0,
            3,
            7,
            14,
            30,
            70,
            100,
            150,
            210,
            270,
            325,
            370,
            400,
            430,
            485,
            507,
            544
          ],
          "distribution": 30,
          "active": 1,
          "createdAt": 1611270128,
          "id": 1
        },
        {
          "id": 2,
          "name": "12 Month Success",
          "code": "success",
          "duration": 365,
          "postCount": 10,
          "days": [
            0,
            3,
            7,
            14,
            30,
            90,
            180,
            225,
            270,
            365
          ],
          "distribution": 30,
          "active": 1,
          "createdAt": 1611270128
        },
        {
          "id": 3,
          "name": "6 Months Motivate",
          "code": "motivate",
          "duration": 180,
          "postCount": 8,
          "days": [
            0,
            1,
            7,
            10,
            32,
            73,
            124,
            180
          ],
          "distribution": 40,
          "active": 1,
          "createdAt": 1611270128
        },
        {
          "id": 4,
          "name": "3 Months Steady",
          "code": "3-month-steady",
          "duration": 90,
          "postCount": 6,
          "days": [
            0,
            10,
            24,
            48,
            69,
            90
          ],
          "distribution": 50,
          "active": 1,
          "createdAt": 1611270128
        },
        {
          "id": 5,
          "name": "1 Month Blast",
          "code": "1-month-blast",
          "duration": 30,
          "postCount": 5,
          "days": [
            0,
            5,
            14,
            23,
            30
          ],
          "distribution": 50,
          "active": 1,
          "createdAt": 1611270128
        },
        {
          "id": 6,
          "name": "3 Week Explosion",
          "code": "explosion",
          "duration": 21,
          "postCount": 4,
          "days": [
            0,
            9,
            15,
            21
          ],
          "distribution": 50,
          "active": 1,
          "createdAt": 1611270128
        },
        {
          "id": 7,
          "name": "1 Week Sprint",
          "code": "sprint",
          "duration": 7,
          "postCount": 3,
          "days": [
            0,
            3,
            7
          ],
          "distribution": 100,
          "active": 1,
          "createdAt": 1611270128
        }
    ]
    
    // Utils.renderScheduleList(user["defaulSchedules"],user["customSchedules"],wid)
    const langList = [
      {value:"na",lable:t("No Translation")},
      {value:"en",lable:"English"},
      {value:"es",lable:"Spanish"},
      {value:"de",lable:"German"},
      {value:"fr",lable:"French"},
      {value:"hi",lable:"Hindi"},
      {value:"ja",lable:"Japanese"},
    ]
    const handleNameChange =(e) => {        
        setName(e.target.value)
        setDetails("campaign-name",e.target.value)
    }
    const handlePostUrlChange =(e) => {        
      setspotifyUrl(e.target.value)
     
  }
    const handleURLChange =(e) => {
        setURL(e.target.value)
        setDetails("campaign-url",e.target.value)
    }
    const onChange = (e) => {
        setDrip(e.target.value)
        setDetails("campaign-drip",e.target.value)
    };
    const onChangeType = (e) => {
        setpostType(e.target.value)
        setDetails("campaign-post",e.target.value)
    };
    const handleChange = (value) =>  {
      const selectedSchedule = _.pick(_.filter(schedList,function(s) { return s.code === value})[0],["name","code","days","id","duration","postCount"]);

        setDuration(value)
        setDetails("campaign-duration",selectedSchedule)
    }
    const handleChangelanguages = (language)=>{
      setsetTranslationLanguage(langList[language])
    }
    const validateUrl = () => {
        setLinkValid(URL === ""?true:Utils.ValidateURL(URL))
        if(!Utils.ValidateURL(URL)){
          setDetails("campaign-url","")
        }
    }
    
  const handleClose = () => {
      setShow(false)
      props.callback(false)
  };
  const validateDetails = () => {
    const keys = ['campaign-name', 'campaign-url','campaign-drip','campaign-post','campaign-duration'];
    const allThere = keys.every(prop => prop in campaignDetails);
    
    if(allThere){
        if(campaignDetails["campaign-name"] === ""){
            return false;
        }
        if(campaignDetails["campaign-url"] === ""){
            return false;
        }

        return true;
    }
    else{
        return false;
    }
  }
  const setDetails =(name, value) =>{
    
    let details = campaignDetails;
    if(name === "campaign-duration"){
      details[name] = value["code"];
      setCampaignSchedule(value)
      setButton(validateDetails())
    }
    else{
      details[name] = value;
    setCampaignDetails(details => (details))   
    setButton(validateDetails())
    }
    
  }
  const handelMetaInfo = async()=>{
    setLinkValid(true)
    setProgressing(true)
    seturlAdded(false)
    setisYouTubeVideo(false)
    
    if(!Utils.ValidateURL(URL)){
      setLinkValid(false)
      setProgressing(false)
      return false;
    }
    const metaInfo = await ApiService.PostApiCalls("verifyLink",{url:URL},true)
    
    if(metaInfo[0] && metaInfo[1]["IsSuccessful"]){
      setlinkMetaInfo(metaInfo[1]["metadata"])
      console.log(metaInfo[1]["metadata"]["source"]);
      const metaInfoDetails = metaInfo[1]["metadata"]
      setcontentLanguage(metaInfo[1]["contentLanguage"])
      if((metaInfoDetails["source"]).toLowerCase() === "www.youtube.com"){
        if(metaInfoDetails["videoId"]){
          seturlAdded(true)
          setName(metaInfo[1]["data"]["article_title"])
          setDetails("campaign-name",metaInfo[1]["data"]["article_title"])
          setProgressing(false)
          setisYouTubeVideo(true)
        }
        else  if(metaInfoDetails["identifier"]){
          seturlAdded(true)
          setName(metaInfo[1]["data"]["article_title"])
          setDetails("campaign-name",metaInfo[1]["data"]["article_title"])
          setProgressing(false)
          setisYouTubeVideo(true)
        }
        else{
          setisYouTubeVideo(true)
          setError("Invalid YouTube video")
        }
      }
      else if((metaInfoDetails["source"]).toLowerCase() === "music.youtube.com"){
        // if(metaInfoDetails["videoId"]){
          seturlAdded(true)
          setName(metaInfo[1]["data"]["article_title"])
          setDetails("campaign-name",metaInfo[1]["data"]["article_title"])
          setProgressing(false)
          setisYouTubeVideo(true)
        // }
        // else{
        //   setisYouTubeVideo(true)
        //   setError("Invalid YouTube video")
        // }
      }
      else{
      seturlAdded(true)
      setName(metaInfo[1]["data"]["article_title"])
      setDetails("campaign-name",metaInfo[1]["data"]["article_title"])
      setProgressing(false)
      setisYouTubeVideo(false)
    }
    }
    if(metaInfo[0] && !metaInfo[1]["IsSuccessful"]){
      setError(metaInfo[1]["FriendlyMessage"])
    }
    else{
      setError("Error")
    }
  }
  const handleCreate = async () => {
    setProgressing(true);
    setError(false);
    setvideoAddedInProcess(false)
    if(isYouTubeVideo){
          const data = {url:URL,title:name,metaInfo:linkMetaInfo,wid:activeWorkSpace["wid"],promotionalLink:spotifyUrl,contentLanguage:contentLanguage,
          TranslationLanguage:TranslationLanguage}
          const addVideo = await ApiService.PostApiCalls("addYtVideo",data,true)
          if(addVideo[0] && addVideo[1]["IsSuccessful"]){
            setvideoAddedInProcess(true)

          }
          else{
            setError(addVideo[1]["FriendlyMessage"])
          }
          


    }
    else{
        const schedule = {
            "name": "12 Month Success",
            "code": "success",
            "days": [0, 3, 7,14,30,90,180,225,270,365],
            "id": 2,
            "duration": 365,
            "postCount": 10
        }
        const details = {"campaign-post": campaignDetails["campaign-post"],
        "campaign-name": campaignDetails["campaign-name"],
        "campaign-url": campaignDetails["campaign-url"],
        "campaign-drip": campaignDetails["campaign-drip"],
        "campaign-duration": "success"}
        const data = {
            title:campaignDetails["campaign-name"],
            url:campaignDetails["campaign-url"],    
            campaign:details,
            TranslationLanguage:TranslationLanguage,
            schedule,
            urlhash:md5(campaignDetails["campaign-url"]+Math.random().toString(36).substr(2, 5)),
            wid:activeWorkSpace["wid"] //TO-DO alter as per current workspace
          }
          const created = await ApiService.PostApiCalls("campaign/create",{...data},true)
          if(created[0] && created[1]["IsSuccessful"]){
            window.location.reload();
          }
          else{
            setProgressing(true);
            setError(true);
            // seterror(created[1]["ErrorMessage"])
          }
    //   const data = {
    //     campaign:campaignDetails,    
    //     campaignSchedule:campaignSchedule,    
    //     urlhash:md5(campaignDetails["campaign-url"]+Math.random().toString(36).substr(2, 5)),
    //     wid:user["workspace"][0]["wid"],
    //     contentLanguage:contentLanguage,
    //     TranslationLanguage:TranslationLanguage
    //   }
      console.log(data);
    //   const campaign = await ApiService.createCampaign(data);
    //   if(campaign[0]){
    //     if(campaign[1]["success"]){
    //       window.location.reload();
    //     }
    //     else{
    //       setProgressing(true);
    //       setError(true);
    //     }
    //   }
    //   else{
    //     setProgressing(true);
    //     setError(true);
    //   }
    }
    
      
  }
  const handleTry = ()=>{
    setProgressing(false);
    setError(false);
  }
  const spinIcon =  <div className="anticon anticon-loading anticon-spin ant-spin-dot"></div>;
    return (
        <Modal show={show} onHide={handleClose} centered size={!urlAdded?"":"lg"} contentClassName="campaign-new-modal bg-white">
        <Modal.Header >
          <span className="modal-title">{t("Create Campaign")}</span>
        </Modal.Header>
        <Modal.Body>
        {!isProgressing && <> <Row className="mt-3">
          <Col lg={12} md={12} sm={12} className="campaign-new-info fz-14 fw-600">
          {t("createdes")}
          </Col>
          <Col lg={12} md={12} sm={12}>
          {!urlAdded && <Row className="mt-3">
          
          <Col lg={12} md={12} sm={12} className={isLinkValid ? "campaign-new-info fz-14 fw-600 m-auto":"campaign-new-info fz-14 fw-600 link-failed"}>
          {/* <span className="mb-2 float-left">Enter URL</span> */}
          <Input placeholder={!isLinkValid? t("createinpute1"):t("createinpute2")} className={!isLinkValid?"border border-danger":""} onBlur={validateUrl} value={URL} onChange={handleURLChange}/>
          </Col>
          
          </Row>}
          {urlAdded && <Row className={isYouTubeVideo?"mt-3 flex-column px-3":"mt-3 "}>
          <Col lg={isYouTubeVideo?8:6} md={12} sm={12} className="campaign-new-info fz-14 fw-600 mb-md-3 mb-sm-3">
          <span className="mb-2 float-left">{isYouTubeVideo?t("Video Title"):t("Campaign Name")}</span>
          <Input placeholder="Add name" value={name} onChange={handleNameChange}/>
          </Col>
          <Col lg={isYouTubeVideo?8:6} md={12} sm={12} className={isLinkValid ? "campaign-new-info fz-14 fw-600":"campaign-new-info fz-14 fw-600 link-failed"}>
          <span className="mb-2 float-left">{isYouTubeVideo?t("YouTube Video Link"):t("Enter URL")}</span>
          <Input placeholder="Add article url" onBlur={validateUrl} value={URL} onChange={handleURLChange}/>
          </Col>
          
          </Row>}
          {urlAdded && isYouTubeVideo && <Row className={isYouTubeVideo?"mt-3 flex-column px-3":"mt-3 "}>
          <Col lg={isYouTubeVideo?8:6} md={12} sm={12} className="campaign-new-info fz-14 fw-600 mb-md-3 mb-sm-3">
          <span className="mb-2 float-left">{isYouTubeVideo?t("Spotify URL"):t("Campaign Name")}<OverlayTrigger
      key={0}
      placement={"top"}
      overlay={
        <Tooltip id={`tooltip-0`}>
        This URL will be shared with all your social posts, instead of YouTube video link.
        </Tooltip>
      }
    >
     <InfoCircle className="ml-2" size={14} color={"#646464"} />
    </OverlayTrigger></span>
          <Input placeholder="Add url (optional)" value={spotifyUrl} onChange={handlePostUrlChange}/>
          </Col>
          
          
          </Row>}
           {urlAdded && isYouTubeVideo && <Row className={isYouTubeVideo?"mt-3 flex-column px-3":"mt-3 "}>
         
          <Col lg={isYouTubeVideo?8:6} md={12} sm={12} className="campaign-new-info fz-14 fw-600 mb-md-3 mb-sm-3">
          <span className="mb-2 ">{t("Translate Content")}</span>
          <div className="d-flex flex-row justify-content-between mt-2">
          <Select defaultValue={0} placeholder="Select Language" defaultActiveFirstOption={true}  style={{ width: '100%' }} onChange={handleChangelanguages}>
            {
              langList.map((s,key)=>(
                <Option key={key} value={key}>{s["lable"]}</Option>
              ))
            }
      
    </Select>
          </div>
          </Col>
          </Row>}
          {urlAdded && !isYouTubeVideo && <Row className="mt-3">
          <Col lg={6} md={12} sm={12} className="campaign-new-info fz-14 fw-600">
          <span className="mb-2 ">{t("driptype")}</span>
          <div className="d-flex flex-row justify-content-between mt-2">
          <Radio.Group onChange={onChange} value={drip} className="d-flex w-100 flex-row justify-content-between drip-selection">
          <Radio value={"auto-drip"}>{t("drip1")}<OverlayTrigger
      key={0}
      placement={"top"}
      overlay={
        <Tooltip id={`tooltip-0`}>
        If you'd like the content to be created automatically based on the content we find on this page, select the Auto Drip option.
        </Tooltip>
      }
    >
     <InfoCircle className="ml-2" size={14} color={"#646464"} />
    </OverlayTrigger></Radio>
          <Radio value={"empty-drip"}  className="campaign-new-info fz-14 fw-600">{t("drip2")}<OverlayTrigger
      key={0}
      placement={"top"}
      overlay={
        <Tooltip id={`tooltip-0`}>
        Select an Empty Drip Campaign if you want to drive traffic to this URL but want to create the Drip Campaign content yourself.
        </Tooltip>
      }
    >
     <InfoCircle className="ml-2" size={14} color={"#646464"} /></OverlayTrigger></Radio>
     </Radio.Group>
          </div>
         </Col>
          <Col lg={6} md={12} sm={12} className="campaign-new-info fz-14 fw-600">
          <span className="mb-2 ">{t("posttype")}</span>
          <div className="d-flex flex-row justify-content-between mt-2">
          <Radio.Group onChange={onChangeType} value={postType} className="d-flex w-100 flex-row justify-content-between drip-selection">
          <Radio value={"video-post"} className="d-flex items-center"><span>{t("type1")}</span><OverlayTrigger
      key={0}
      placement={"top"}
      overlay={
        <Tooltip id={`tooltip-0`}>
        Select a Video Post if you want to have animated video in you post content.
        </Tooltip>
      }
    >
     <InfoCircle className="ml-2" size={14} color={"#646464"} />
    </OverlayTrigger></Radio>
          <Radio value={"static-post"}  className="campaign-new-info fz-14 fw-600">{t("type2")}<OverlayTrigger
      key={0}
      placement={"top"}
      overlay={
        <Tooltip id={`tooltip-0`}>
        Select a Static Post if you want to have text & image posts.
        </Tooltip>
      }
    >
     <InfoCircle className="ml-2" size={14} color={"#646464"} /></OverlayTrigger></Radio>
     </Radio.Group>
          </div>
          </Col>
          </Row>}
          {urlAdded && !isYouTubeVideo && <Row className="mt-3">
          <Col lg={6} md={12} sm={12} className="campaign-new-info fz-14 fw-600">
          <span className="mb-2 ">{t("Duration")}</span>
          <div className="d-flex flex-row justify-content-between mt-2">
          <Select  placeholder="Select Plan" defaultActiveFirstOption={false}  style={{ width: '100%' }} onChange={handleChange}>
            {
              schedList.map((s,key)=>(
                <Option key={key} value={s["code"]}>{s["name"]}</Option>
              ))
            }
      
    </Select>
          </div>
          </Col>
          <Col lg={6} md={12} sm={12} className="campaign-new-info fz-14 fw-600">
          <span className="mb-2 ">{t("Translate Content")}</span>
          <div className="d-flex flex-row justify-content-between mt-2">
          <Select defaultValue={0} placeholder="Select Language" defaultActiveFirstOption={true}  style={{ width: '100%' }} onChange={handleChangelanguages}>
            {
              langList.map((s,key)=>(
                <Option key={key} value={key}>{s["lable"]}</Option>
              ))
            }
      
    </Select>
          </div>
          </Col>
          </Row>}

          </Col>
        </Row></>}
        {isProgressing && !isError && !videoAddedInProcess &&
        <div className="d-flex align-items-center flex-column justify-content-center mt-4">
        <Spin size="small" indicator={spinIcon} spinning={true}  />
        <span className="fw-600 fz-16 mt-4">{t("moment")}</span>
        </div>
        }
        {isProgressing && videoAddedInProcess &&
        <div className="d-flex align-items-center flex-column justify-content-center mt-4">
        <span className="fw-600 fz-16 mt-4">Great, video added in processing.</span>
        <span className="fw-600 fz-14 mt-2">As it is a video, it will take little time to get added in <b>Draft Campaigns</b> list.</span>
        </div>
        }
        {isProgressing && isError &&
        <div className="d-flex align-items-center flex-column justify-content-center mt-4">
        <span className="fw-600 fz-16 mt-4">Oops there was a problem.</span>
        <span className="fw-600 fz-14 mt-2">{isYouTubeVideo?`${isError}. Did you enter a correct URL? `:"Did you enter a valid URL? If you did, your site might be blocking."}</span>
        </div>
        }
        </Modal.Body>
        <Modal.Footer className="mt-4">
          <Button variant={videoAddedInProcess?"primary":"secondary"} onClick={handleClose}>
            {videoAddedInProcess? t("Okay") : t("Cancel")}
          </Button>
          {
            !isProgressing && urlAdded && <Button type="primary" onClick={()=>{seturlAdded(false)}} >
            {t("Change Url")}
            </Button>
          }
          {!isProgressing && urlAdded && <Button type="primary" onClick={handleCreate} disabled={isYouTubeVideo?false:!buttonOff}>
          {!isYouTubeVideo?t("Create"):t("Process Video")}
          </Button>}
          {!isProgressing && !urlAdded && <Button type="primary" onClick={handelMetaInfo} >
          {t("Next")}
          </Button>}
          { isProgressing && isError &&
            <Button type="primary" onClick={handleTry} >
            {t("Try again")}
          </Button>
          }
        </Modal.Footer>
      </Modal>
    )
}

export default CreateNewCampaign
