import { List, Spin,Tabs } from "antd";
import FeedCard from "components/feed/FeedCard";
import GroupBanner from "components/ProfileCard/GroupBanner";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import ApiService from "services/ApiService";
import GroupFeed from "./GroupFeed";
import GroupMembers from "./GroupMembers";
import { useParams } from "react-router-dom";


const GroupProfile = (props) => {
  const brParams = useParams()
 const [loading, setloading] = useState(true);
  const [profile, setprofile] = useState(null);
  const [error, seterror] = useState(null);
  const [feedContent, setfeedContent] = useState([])
  const [tabKey, settabKey] = useState("1")
  

  useEffect(() => {
    alert("dd")
    getGroupInfo();

    return () => {};
  }, []);

  const getGroupInfo = async()=>{
    const id = brParams["id"];
    const info = await ApiService.PostApiCalls("group/info",{id:id},true);
    if(info[0] && info[1]["IsSuccessful"]){
        setprofile(info[1]["groups"][0]);
    }
    setloading(false);
    

  }
  const onChange = (key) => {
    
    settabKey(key);
  };
  
  if(error){
    return(<div className="px-4 h-100 d-flex justify-center p-4">
      <span className="font-weight-bold" >{error}</span>
    </div>)
  }
  return loading ? (
    <div className="px-4 h-100 d-flex justify-center align-items-center">
      <Spin />
    </div>
  ) : (
   <><div className="px-0">
      <GroupBanner post={profile} />
      <div className=" pb-0 bg-white">
      <Tabs defaultActiveKey={tabKey}  onChange={onChange} tabBarGutter={44} tabBarStyle={{paddingLeft:20,marginBottom:0}} >
    <Tabs.TabPane tab="Feed" key="1" >
     
    </Tabs.TabPane>
    <Tabs.TabPane tab="Members" key="2">
      
    </Tabs.TabPane>
   
  </Tabs>
      </div>
    {tabKey === "1" && <GroupFeed groupinfo={profile} />}
    {tabKey === "2" && <GroupMembers groupinfo={profile} />}
      {/* <List dataSource={feedContent}
      locale={{emptyText:"No Activities"}}
      renderItem={(item) => (
        <List.Item>
          <FeedCard  post={item} id={item} />
        </List.Item>
      )}></List> */}
    </div></> 
  );

}

export default GroupProfile