import { Card, Avatar, Col, Row, Button } from "antd";
import React from "react";

const { Meta } = Card;

const MarketCard = ({ post }) => {
  return (
    <Col span={8} className="mb-3">
      <Card
    hoverable
    style={{ width: 240 }}
    cover={<img alt="example" src={post.image} />}
  >
    <Meta title={post.name} description={post.members} />
  </Card>
    </Col>
  );
};

export default MarketCard;
