import { HomeContainer,DefaultLayout, CreatorLayout, CreatorSetupLayout, NoRightLayout, DisplayLayout, OpenLayout, CreatorProfileLayout, SearchLayout } from "layouts";
import HomePage from "container/home/HomePage";
import FeedPage from "container/feed/FeedPage";
import ProfileView from "container/profile/ProfileView";
import CreatorEdit from "container/creator/CreatorEdit";
import Dashboard from "container/creator/Dashboard";
import CampaignReview from "container/creator/creator/CampaignReview";
import CampaignPost from "container/creator/creator/CampaignPost";
import Campaigns from "container/creator/creator/Campaigns";
import HashMine from "container/creator/HashMine";
import CalendarView from "container/creator/CalendarView";
import VideoReview from "container/creator/creator/video/VideoReview";

import GroupHome from "container/group/GroupHome";

import MarketPlace from "container/marketplace/MarketPlace";
import FeedsPage from "container/feed/FeedsPage";
import NewsHome from "container/news/NewsHome";
import AnalyticsHome from "container/creator/analytics/AnalyticsHome";
import VideoCampaignReview from "container/creator/creator/VideoReview/VideoCampaignReview";
import UserBookmarks from "container/users/UserBookmarks";
import UserSubscriptions from "container/users/UserSubscriptions";
import SocialShop from "container/socialshop/SocialShop";
import HashShop from "container/socialshop/HashShop";
import SocialDisplay from "container/socialshop/SocialDisplay";
import SocialWidgets from "container/socialshop/SocialWidgets";
import DisplayWall from "container/socialshop/DisplayWall";
import DisplayWallFull from "container/socialshop/DisplayWallFull";
import AcceptCrypto from "container/home/AcceptCrypto";
import DonateCrypto from "container/home/DonateCrypto";
import Profile from "container/creator/Profile";
import AllSettings from "container/creator/workspacesettings/AllSettings";
import SearchResult from "helper/search/SearchResult";
import AboutUs from "components/pages/AboutUs";
import FAQs from "components/pages/FAQs";
import Whyus from "components/pages/Whyus";
import ContactUs from "components/pages/ContactUs";
import TermsServices from "components/pages/TermsServices";
import PricingPlans from "components/pages/PricingPlans";
import PrivacyPolicy from "pages/PrivacyPolicy";
import UserAccount from "container/users/UserAccount";
import HashResult from "helper/search/HashResult";
import CreateGroupe from "container/group/CreateGroup";
import MiddleLayout from "layouts/MiddleLayout";
import CreateGroup from "container/group/CreateGroup";
import GroupProfile from "container/group/GroupProfile";
import UserFollowers from "container/profile/UserFollowers";
import MobileAppLayout from "layouts/MobileAppLayout";
import SocialCommerce from "container/creator/socialcommerce/SocialCommerce";
import TempLayout from "layouts/TempLayout";
import ReLayout from "layouts/ReLayout";
import RealEstate from "views/RealEstate";



const CommenRoutes=[
    {
        path: "/pricing",
        exact: true,
        layout: OpenLayout,
        component: PricingPlans,
        isHome: true,
    },
    {
        path: "/about-us",
        exact: true,
        layout: OpenLayout,
        component: AboutUs,
        isHome: true,
    },
    {
        path: "/faqs",
        exact: true,
        layout: OpenLayout,
        component: FAQs,
        isHome: true,
    },
    {
        path: "/why-us",
        exact: true,
        layout: OpenLayout,
        component: Whyus,
        isHome: true,
    },
    {
        path: "/contact-us",
        exact: true,
        layout: OpenLayout,
        component: ContactUs,
        isHome: true,
    },
    {
        path: "/privacy",
        exact: true,
        layout: OpenLayout,
        component: PrivacyPolicy,
        isHome: true,
    },
    {
        path: "/terms-services",
        exact: true,
        layout: OpenLayout,
        component: TermsServices,
        isHome: true,
    },
    {
        path: "/privacy-app",
        exact: true,
        layout: MobileAppLayout,
        component: PrivacyPolicy,
        isHome: true,
    },
    {
        path: "/terms-services-app",
        exact: true,
        layout: MobileAppLayout,
        component: TermsServices,
        isHome: true,
    },
    
]

const AuthRoutes = [
    {
        path: "/",
        exact: true,
        layout: HomeContainer,
        component: HomePage,
        isHome: true,
    },
    {
        path: "/search/:state",
        exact: true,
        layout: TempLayout,
        component: SearchResult,
        isHome: true,
    },
    {
        path: "/search/:state/:action",
        exact: true,
        layout: TempLayout,
        component: SearchResult,
        isHome: true,
    },
    {
        path: "/search/:state/:action/:id",
        exact: true,
        layout: TempLayout,
        component: SearchResult,
        isHome: true,
    },
    {
        path: "/re/:state",
        exact: true,
        layout: ReLayout,
        component: RealEstate,
        isHome: true,
    },
    {
        path: "/re/:state/:id",
        exact: true,
        layout: ReLayout,
        component: RealEstate,
        isHome: true,
    },
    {
        path: "/:user/posts/:id",
        exact: true,
        layout: SearchLayout,
        component: FeedPage,
        isHome: true,
    },
    {
        path: "/hashtag/:tag",
        exact: true,
        layout: SearchLayout,
        component: HashResult,
        isHome: true,
    },
    {
        path: "/accept-crypto-demo",
        exact: true,
        layout: OpenLayout,
        component: AcceptCrypto,
        isHome: true,
    },
    {
        path: "/donate-crypto",
        exact: true,
        layout: OpenLayout,
        component: DonateCrypto,
        isHome: true,
    },
    {
        path: "/creator/edit/:step",
        exact: true,
        layout: OpenLayout,
        hideExtraNav:true,
        component: CreatorEdit,
        isHome: true,
    },
    
   
]
const AppRoutes = [
    {
        path: "/",
        exact: true,
        layout: DefaultLayout,
        component: FeedPage,
        isHome: true,
    },
    {
        path: "/search/:state",
        exact: true,
        layout: SearchLayout,
        component: SearchResult,
        isHome: true,
    },
    {
        path: "/search/:state/:action",
        exact: true,
        layout: TempLayout,
        component: SearchResult,
        isHome: true,
    },
    {
        path: "/search/:state/:action/:id",
        exact: true,
        layout: TempLayout,
        component: SearchResult,
        isHome: true,
    },
    {
        path: "/brokers/:state",
        exact: true,
        layout: SearchLayout,
        component: SearchResult,
        isHome: true,
    },
    {
        path: "/brokers/:state/:action",
        exact: true,
        layout: SearchLayout,
        component: SearchResult,
        isHome: true,
    },
    {
        path: "/brokers/:state/:action/:id",
        exact: true,
        layout: TempLayout,
        component: SearchResult,
        isHome: true,
    },
    {
        path: "/re/:state",
        exact: true,
        layout: ReLayout,
        component: RealEstate,
        isHome: true,
    },
    {
        path: "/re/:state/:id",
        exact: true,
        layout: ReLayout,
        component: RealEstate,
        isHome: true,
    },
    // {
    //     path: "/re/:state/:action",
    //     exact: true,
    //     layout: ReLayout,
    //     component: SearchResult,
    //     isHome: true,
    // },
    // {
    //     path: "/re/:state/:action/:id",
    //     exact: true,
    //     layout: ReLayout,
    //     component: SearchResult,
    //     isHome: true,
    // },
    {
        path: "/hashtag/:tag",
        exact: true,
        layout: SearchLayout,
        component: SearchResult,
        isHome: true,
    },
    {
        path: "/bookmarks/:action",
        exact: true,
        layout: NoRightLayout,
        component: UserBookmarks,
        isHome: true,
    },
    {
        path: "/hashmine",
        exact: true,
        layout: NoRightLayout,
        component: HashMine,
        isHome: true,
    },
    {
        path: "/account-settings/:action",
        exact: true,
        layout: NoRightLayout,
        component: UserAccount,
        isHome: true,
    },
    {
        path: "/account-settings/:action/:type",
        exact: true,
        layout: NoRightLayout,
        component: UserAccount,
    },
    {
        path: "/subscriptions",
        exact: true,
        layout: DefaultLayout,
        component: UserSubscriptions,
        isHome: true,
    },
    {
        path: "/groups/list",
        exact: true,
        layout: DefaultLayout,
        component: GroupHome,
        isHome: true,
    },
    {
        path: "/groups/create/",
        exact: true,
        layout: MiddleLayout,
        component: CreateGroup,
        isHome: true,
    },
    {
        path: "/groups/:id",
        exact: true,
        layout: DefaultLayout,
        component: GroupProfile,
        isHome: true,
    },
    {
        path: "/social-shop",
        exact: true,
        layout: NoRightLayout,
        component: SocialShop,
        isHome: true,
    },
    {
        path: "/social-shop/display",
        exact: true,
        layout: NoRightLayout,
        component: SocialDisplay,
        isHome: true,
    },
    {
        path: "/social-shop/widget",
        exact: true,
        layout: NoRightLayout,
        component: SocialWidgets,
        isHome: true,
    },
    {
        path: "/display",
        exact: true,
        layout: DisplayLayout,
        component: DisplayWallFull,
        isHome: true,
    },
    {
        path: "/social-shop/hashshop",
        exact: true,
        layout: NoRightLayout,
        component: HashShop,
        isHome: true,
    },
    {
        path: "/news",
        exact: true,
        layout: NoRightLayout,
        component: NewsHome,
        isHome: true,
    },
    {
        path: "/marketplace",
        exact: true,
        layout: DefaultLayout,
        component: MarketPlace,
        isHome: true,
    },
    {
        path: "/feeds",
        exact: true,
        layout: DefaultLayout,
        component: FeedsPage,
        isHome: true,
    },
   
    
    {
        path: "/feed",
        exact: true,
        layout: DefaultLayout,
        component: FeedPage,
        isHome: true,
    },
    // {
    //     path: "/hashtag/:tag",
    //     exact: true,
    //     layout: DefaultLayout,
    //     component: FeedPage,
    //     isHome: true,
    // },
    {
        path: "/:user/posts/:id",
        exact: true,
        layout: DefaultLayout,
        component: FeedPage,
        isHome: true,
    },
    {
        path: "/:user/videos/:id",
        exact: true,
        layout: DefaultLayout,
        component: FeedPage,
        isHome: true,
    },
    {
        path: "/feed/:id",
        exact: true,
        layout: DefaultLayout,
        component: FeedPage,
        isHome: true,
    },
    {
        path: "/creator/edit/:step",
        exact: true,
        layout: CreatorSetupLayout,
        hideExtraNav:true,
        component: CreatorEdit,
        isHome: true,
    },
    {
        path: "/creator/home",
        exact: true,
        layout: CreatorLayout,
        component: Dashboard,
        isHome: true,
        sidebarKey:"0"
    },
    {
        path: "/creator/social-commerce",
        exact: true,
        layout: CreatorProfileLayout,
        component: SocialCommerce,
        isHome: true,
        sidebarKey:"8",
        closedSidebar:true,
        props:{
            isHome: true,
            sidebarKey:"8",
            closedSidebar:true,
        }
    },
    {
        path: "/creator/social-commerce/:option",
        exact: true,
        layout: CreatorProfileLayout,
        component: SocialCommerce,
        isHome: true,
        sidebarKey:"8",
        closedSidebar:true,
        props:{
            isHome: true,
            sidebarKey:"8",
            closedSidebar:true,
        }
    },
    {
        path: "/creator/home/:name",
        exact: true,
        layout: CreatorLayout,
        component: Dashboard,
        isHome: true,
        sidebarKey:"0"
    },
    {
        path: "/creator/campaigns",
        exact: true,
        layout: CreatorLayout,
        component: Dashboard,
        isHome: true,
        sidebarKey:"1"
    },
    {
        path: "/creator/:name/campaigns",
        exact: true,
        layout: CreatorLayout,
        component: Campaigns,
        isHome: true,
        sidebarKey:"1"
    },
    {
        path: "/creator/:name/campaigns/:id",
        exact: true,
        layout: CreatorLayout,
        component: Dashboard,
        isHome: true,
        sidebarKey:"1"
    },
    {
        path: "/creator/:name/campaigns/:id/review",
        exact: true,
        layout: CreatorLayout,
        component: CampaignReview,
        isHome: true,
        sidebarKey:"1",
        
    },
    {
        path: "/creator/:name/campaigns/:id/video",
        exact: true,
        layout: CreatorLayout,
        component: VideoCampaignReview,
        isHome: true,
        sidebarKey:"1",
        hideSideBar:true,
        isVideo:true
    },
    
    {
        path: "/creator/:name/campaigns/:id/review/posts",
        exact: true,
        layout: CreatorLayout,
        component: CampaignPost,
        isHome: true,
        sidebarKey:"1"
    },
    {
        path: "/creator/:name/analytics",
        exact: true,
        layout: CreatorLayout,
        component: AnalyticsHome,
        isHome: true,
        sidebarKey:"2"
    },
    {
        
        path: "/creator/:name/calendar",
        exact: true,
        layout: CreatorLayout,
        component: CalendarView,
        isHome: true,
        sidebarKey:"3"
    },
    {
        path: "/creator/:name/settings",
        exact: true,
        layout: CreatorProfileLayout,
        component: AllSettings,
        isHome: true,
        sidebarKey:"4",
        closedSidebar:true,
        props:{
            isHome: true,
            sidebarKey:"4",
            closedSidebar:true,
        }
    },
    {
        path: "/creator/:name/settings/:option",
        exact: true,
        layout: CreatorProfileLayout,
        component: AllSettings,
        isHome: true,
        sidebarKey:"4",
        closedSidebar:true,
        props:{
            isHome: true,
            sidebarKey:"4",
            closedSidebar:true,
        }
    },
    {
        path: "/creator/profile",
        exact: true,
        layout: CreatorProfileLayout,
        component: Profile,
        
        isHome: true,
        sidebarKey:"40",
        isProfile:true
    },
    {
        path: "/creator/hashmine",
        exact: true,
        layout: CreatorLayout,
        component: HashMine,
        isHome: true,
        sidebarKey:"5"
    },
    {
        path: "/my/settings/:action",
        exact: true,
        layout: CreatorProfileLayout,
        component: Profile,
        isHome: true,
        sidebarKey:"40",
        isProfile:true
    },
    {
        path: "/my/settings/:action/:type",
        exact: true,
        layout: CreatorProfileLayout,
        component: Profile,
        isHome: true,
        sidebarKey:"40",
        isProfile:true
    },
    {
        path: "/:profile",
        exact: true,
        layout: DefaultLayout,
        component: ProfileView,
        isHome: true,
    },
    {
        path: "/:profile/:connection",
        exact: true,
        layout: DefaultLayout,
        component: UserFollowers,
        isHome: true,
    },
]
export {AuthRoutes,AppRoutes,CommenRoutes}