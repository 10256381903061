import { Button, Tooltip,Modal as AntModal } from "antd";
import AvatarEditor from "container/common/AvatarEditor";
import CoverEditor from "container/common/CoverEditor";
import Utils from "helper/Utils";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import {ShareSocial} from 'react-share-social'

import {
  CameraVideo,
  Heart,
  PatchCheckFill,
  Image,
  ClipboardX,
  Gear,
  Star,Pencil,
  CameraFill,
} from "react-bootstrap-icons";
import { BsReply } from "react-icons/bs";
import { Link } from "react-router-dom";
import ApiService from "services/ApiService";
import ProfileName from "components/ProfileName";
const { confirm } = AntModal;
const ProfileBanner = ({info,isEdit}) => {
  const user = Utils.getLocalInfo("userhash")
  const [profile, setprofile] = useState(info)
  const [privacyMode, setprivacyMode] = useState(false);

  const [bookmarked, setbookmarked] = useState(info["userMarked"] && info["userMarked"][0]?true:false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [picModalVisible, setpicModalVisible] = useState(false);
  const [following, setfollowing] = useState(info.connections[0]?true:false)
  const isSelfProfile = user && user.uniqueId === info.uniqueId ?true:false;

  const [editType, seteditType] = useState("p")
  const shareLink = "https://hashsaga.com/groups/"+info.userName;
  useEffect(() => {
    const modein = localStorage.getItem("appUsageMode")
    if(modein){
      setprivacyMode(modein === "privacy"?true:false)
    }
    setprofile(info);
    return () => {
      
    }
  }, [])
  
  const showModal = () => {
      
    setIsModalOpen(true);
  };
  const handleOk = (p) => {
    seteditType(p)
    setpicModalVisible(true);
  };
  const handleClose = () => {
    setIsModalOpen(false);
    setpicModalVisible(false);
  };
  
  const addBookmark = async()=>{
    const bookmarkType = "profile"
      const reacted = await ApiService.PostApiCalls(`bookmarks/create`,{reaction:bookmarked?0:1,isComment:false,commentId:"",type:bookmarkType,bookmarkId:profile["uniqueId"]},true);
      if(reacted[0] && reacted[1]["IsSuccessful"]){
        
      setbookmarked(!bookmarked)

      }
  }
  const getRndInteger = (min, max)=> {
    return Math.floor(Math.random() * (max - min)) + min;
  }
  const connectUser = async(value) =>{
    const res = await ApiService.PostApiCalls("connections/follow",{connectionId:profile.uniqueId,value:value},true)
        setfollowing(value===1?true:false);
  }
  const unfollowUser = async()=>{
    confirm({
      title: 'Unfollow user?',
      // icon: <ExclamationCircleOutlined />,
      content: "Their posts will no longer show up in your feed timeline. You can still view their profile.",
      async onOk() {
        await connectUser(0) 
      },
      onCancel() {
        console.log('Cancel');
      },
    });
    // Alert.alert(
    //     `Unfollow ${myname}?`,
    //     "Their posts will no longer show up in your feed timeline. You can still view their profile.",
    //     [
    //       {
    //         text: "Cancel",
    //         onPress: () => {},
    //         style: "cancel"
    //       },
    //       { text: "Yes", onPress: () =>   connectUser(0) }
    //     ]
    //   );
  
}
  const updateProfile = (type,image) =>{
    
    setprofile({...profile,[type]:image})
  }
  // const viewCount = getRndInteger(50,400);
  const img =
    "https://hs-container.s3.ap-south-1.amazonaws.com/saga/user-assets/images/APCv2PKKlB_PwH.jpg";
  return (
    <>
   {isEdit?<div className="bg-white rounded-t-xl mb-3 relative  pb-3 flex flex-col ">
      <div
        className="relative inset-0 h-48  w-full rounded-xl bg-red-1 bg-center bg-cover flex flex-col items-start justify-end z-20"
        style={{
          backgroundImage: `url("${profile["coverPicture"]===""?img:profile["coverPicture"]}")`,
        }}
      >
        <div
          className="w-20 h-20 bg-red-400 rounded-full transform translate-x-10 translate-y-10 bg-center bg-contain border-2 border-red-500"
          style={{
            backgroundImage: `url(${info["appMode"] && info["appMode"] === "privacy"?'https://static.thenounproject.com/png/2910477-200.png':profile["profilePicture"]})`,
          }}
        >
          {user["userName"] === profile.userName && <Button onClick={()=>handleOk("p")} className="rounded-full bg-gray-200 flex justify-around items-center font-normal absolute -right-2 -bottom-2 z-20" icon={<CameraFill className="text-hs-dark" size={18} />} ></Button>}
        </div>
        {user["userName"] === profile.userName  && <Button onClick={()=>handleOk("c")} className="rounded-md bg-gray-200 flex justify-around items-center font-normal absolute right-3 bottom-3" icon={<Pencil className="mr-2" />} >Edit</Button>}
      </div>
      <div className="py-2"></div>
      <div className="py-0 px-4 flex flex-row relative justify-start">
        <div className="w-24"></div>
        <ProfileName classnames={"pl-2 ml-2 text-ht text-base font-semibold flex-grow flex flex-col"} verfied={profile["verfied"]} profileName= {profile.name} appMode={info["appMode"] ? info["appMode"]:""} privacyMode={privacyMode} />
        
        <div className="flex-grow"></div>
       
      </div></div> :<div className="bg-white rounded-t-xl mb-3 relative  pb-3 flex flex-col ">
      <div
        className="relative inset-0 h-48  w-full rounded-xl bg-red-1 bg-center bg-cover flex flex-col items-start justify-end z-20"
        style={{
          backgroundImage: `url("${profile["coverPicture"]===""?img:profile["coverPicture"]}")`,
        }}
      >
        <div
          className="w-20 h-20 bg-red-400 rounded-full transform translate-x-10 translate-y-10 bg-center bg-contain border-2 border-red-500"
          style={{
            backgroundImage: `url(${info["appMode"] && info["appMode"] === "privacy"?'https://static.thenounproject.com/png/2910477-200.png':profile["profilePicture"]})`,
          }}
        >
          {user["userName"] === profile.userName && <Button onClick={()=>handleOk("p")} className="rounded-full bg-gray-200 flex justify-around items-center font-normal absolute -right-2 -bottom-2 z-20" icon={<CameraFill className="text-hs-dark" size={18} />} ></Button>}
        </div>
        {user["userName"] === profile.userName  && <Button onClick={()=>handleOk("c")} className="rounded-md bg-gray-200 flex justify-around items-center font-normal absolute right-3 bottom-3" icon={<Pencil className="mr-2" />} >Edit</Button>}
      </div>
      <div className="py-2"></div>
      <div className="py-0 px-4 flex flex-row relative justify-start">
        <div className="w-24"></div>
        <ProfileName classnames={"pl-2 ml-2 text-ht text-base font-semibold flex-grow flex flex-col"} verfied={profile["verfied"]} profileName= {profile.name} appMode={info["appMode"] ? info["appMode"]:""} privacyMode={privacyMode} />
        
        <div className="flex-grow"></div>
        {/* <div className="self-end flex flex-row items-center space-x-5">
          <div className="flex flex-row space-x-2 items-center">
            <Heart color="#F6327D" />
            <span>{getRndInteger(50,400)}</span>
          </div>
          <div className="flex flex-row space-x-2 items-center">
            <Image color="#F6327D" />
            <span>{getRndInteger(50,400)}</span>
          </div>
          <div className="flex flex-row space-x-2 items-center">
            <CameraVideo color="#F6327D" />
            <span>{getRndInteger(50,400)}</span>
          </div>
        </div> */}
      </div>
      <div className="py-3"></div>
      {user["userName"] !== profile.userName ?<div className="text-hlt px-4 flex flex-row items-center justify-start">
        <div className="w-5"></div>
        <div className="flex flex-row items-center space-x-4 cursor-pointer">
          <div className="flex items-center space-x-1">@{privacyMode?profile.uniqueId:profile.userName}</div>
          {/* <div className="flex items-center space-x-2">
            <BsReply size={20} />
            <span>Share Profile</span>
          </div> */}
          {/* <Tooltip title={bookmarked?"Remove from favorites":"Add to favorites"} overlayClassName="rounded" overlayInnerStyle={{borderRadius: "0.25rem",fontSize: "0.75rem"}}>
            <div className={bookmarked?"flex items-center space-x-2 text-blue-600":"flex items-center space-x-2 " }onClick={addBookmark}>
            <Star />
            <span>Favorites</span>
          </div></Tooltip> */}
        </div>
        <div className="flex-grow"></div>
        <div className="flex flex-row space-x-4 items-center">
          
        {!following && !isSelfProfile && <Button onClick={()=>connectUser(1)} className="rounded-2xl px-4 bg-red-1 border-0 font-semibold" type="primary">
            Follow
          </Button>}
          {following && !isSelfProfile && <Button onClick={unfollowUser} className="rounded-2xl px-4 text-hs-pink font-semibold" type="default">
            Following
          </Button>}
        </div>
      </div>:<div className="text-hlt px-4 flex flex-row items-center justify-start">
        <div className="w-5"></div>
        <div className="flex flex-row items-center space-x-4 cursor-pointer">
          <div className="flex items-center space-x-1">@{profile.userName}</div>
          
        </div>
        <div className="flex-grow"></div>
        <div className="flex flex-row space-x-4 items-center">
          
         <Link to={"/my/settings/profile"}> <Button className="rounded-2xl bg-red-1 border-0 flex items-center" type="primary">
            <Gear />
            <span className="ml-2">Edit Profile</span>
          </Button></Link>
        </div>
      </div>}
      <div className="px-5 pt-2 pb-0 flex flex-row items-center space-x-2">
      

      <div className="flex flex-row space-x-4 items-center">
        <Link to={`/${info.userName}/following`} ><Button type="text" className="px-0" >{ Utils.numberFormatter(profile["connectionsCount"][0]["following_count"],1)} Following</Button></Link>
        <Link to={`/${info.userName}/followers`}><Button type="text" className="px-0">{Utils.numberFormatter(profile["connectionsCount"][0]["followers_count"],1)} Followers</Button></Link>
        </div>
        
        </div>
      {/* <div className="py-3"></div> */}
    </div>}
    <Modal show={isModalOpen} onHide={handleClose} dialogClassName="modal-25w">
    <Modal.Header closeButton>
          
        </Modal.Header>
        <Modal.Body><ShareSocial 
     url ={shareLink}
     style={{copyContainer: {
      border: 'none',
      background: 'rgb(0,0,0,0.7)',
      fontFamily:"Poppins",
      alignItems:'center'
    },}}
     socialTypes={['facebook','twitter','whatsapp','linkedin','telegram','email']}
     onSocialButtonClicked={ data => handleClose} 
   /></Modal.Body>
        
      </Modal>
      {/* Profile and Coverpic Modal */}
      <Modal show={picModalVisible} onHide={handleClose} dialogClassName={editType === "p"?"max-w-lg":"max-w-screen-md"}>
    <Modal.Header closeButton className="text-center justify-center" >
    <Modal.Title className="text-center h5 text-hs-dark flex-grow">Update profile picture</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-0">
          {editType === "p"?
          <AvatarEditor pic={profile.profilePicture} action="user/update" callback={updateProfile} gid={profile.uniqueId} modalCallback={handleClose} />
          :
          <CoverEditor pic={profile.coverPicture} action="user/update" callback={updateProfile} gid={profile.uniqueId} modalCallback={handleClose} />
          }
          
          </Modal.Body>
        
      </Modal>
    </>
  );
};

export default ProfileBanner;
