import moment from "moment";
import React,{useEffect,useState} from "react";
import { PatchCheckFill, ThreeDots } from "react-bootstrap-icons";
import { Tooltip} from "antd";
import { Link } from "react-router-dom";
import CardDropMenu from "./CardDropMenu";
const CardProfileInfo = ({author,date,content}) => {
  const [privacyMode, setprivacyMode] = useState(false);
  useEffect(() => {
    const modein = localStorage.getItem("appUsageMode")
    if(modein){
      setprivacyMode(modein === "privacy"?true:false)
    }
  
    return () => {
      
    }
  }, [])
  return (
    <div profileinfo className="flex items-center">
     <Link to={content["profileType"] === "user"?`/${author.userName}`:`/groups/${author.userName}`} className="text-decoration-none text-ht text-base font-semibold "><div className="h-12 w-12 rounded-full overflow-hidden shadow ">
       {author.profilePicture !==""? <div
          className="h-12 w-12 bg-center bg-cover"
          style={{
            backgroundImage:
              `url("${author["appMode"] && author["appMode"] === "privacy"?'https://static.thenounproject.com/png/2910477-200.png':author.profilePicture}")`,
          }}
        ></div>:<span className="head-notificationsBox flex items-center w-100 h-100 justify-center text-white text-2xl bg-blue-500">{author.profileDisplayName[0]}</span>}
      </div></Link>
      <div className="flex flex-row items-center space-x-2 flex-grow">
      
          <span className="pl-2 ml-2 text-ht text-base items-center space-x-2 font-semibold flex-grow flex flex-row">
          {author["appMode"] && author["appMode"] === "privacy"?<>{author.uniqueId}</>:<Link to={content["profileType"] === "user"?`/${author.userName}`:`/groups/${author.userName}`} className="text-decoration-none text-ht text-base font-semibold ">{author.profileDisplayName?author.profileDisplayName:author.name}</Link>}
          {author["appMode"] && author["appMode"] === "privacy" ?<></> :<PatchCheckFill size={14} color={"#0079FF"} />}
          </span>
          
        </div>
      
      
      <div className="flex flex-row items-center">
      <Link to={`/${author.userName}/posts/${content["contentID"]}`} className="text-decoration-none text-ht text-base font-semibold sm-hide d-sm-block d-none ">
        <Tooltip placement="bottom" overlayClassName="text-xs rounded-xl" overlayInnerStyle={{borderRadius:"0.25rem",display:"flex", alignItems:"center"}} title={`${moment.unix(date).format("dddd, D MMMM YYYY")} at ${moment.unix(date).format("HH:mm")}`}><span className=" font-normal text-xs text-gray-400">{moment.unix(date).fromNow()}</span>
        </Tooltip></Link>
        <CardDropMenu content={content}/>
      </div>
    </div>
  );
};

export default CardProfileInfo;
