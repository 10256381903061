import { Button, Result } from 'antd'
import FeedsCard from 'container/feed/FeedsCard'
import React from 'react'
import { Link } from 'react-router-dom'
import NewsTrendingCard from './NewsTrendingCard'

const TrendingNews = ({groups,baseQuery}) => {

 
  return (
    <div className=" d-flex justify-center flex-col bg-white py-3 rounded-xl ">
        <div className="px-4 d-flex flex-col justify-between mb-2 pb-2 border-b">
          <span className="text-hp text-lg font-semibold">Trending Links on HashSaga</span>
          <span className="text-hp text-xs">Most popular links in last 15 minutes</span>
          
        </div>
        {groups && groups.length > 0 && (
          <div className="px-4 space-y-3">
             {groups.map((feed, key) => 
                {
                  if(key > 5){
                  return (<NewsTrendingCard key={key} post={feed} id={key} />)
                }
                }
                
              )}
            
            
          </div>
        )}
        {groups && groups.length === 0 && (
          <div className="px-4 bg-white">
            <Result
              status="403"
              title={baseQuery["tag"] ? `#${baseQuery["tag"]}` : ""}
              subTitle={
                baseQuery["tag"]
                  ? "Sorry, no post found for searched tag."
                  : "Sorry, no post found for searched id."
              }
              extra={
                <Link to={"/"}>
                  <Button type="primary">Back Home</Button>
                </Link>
              }
            />
          </div>
        )}
      </div>
  )
}

export default TrendingNews