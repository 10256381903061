

import { Button, Card, Input, Tabs ,Checkbox, Select, Avatar, AutoComplete, message} from 'antd'
import axios from 'axios'
import FacebookAuth from 'components/socialauths/FacebookAuth'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { Facebook, Flag } from 'react-bootstrap-icons'
import ApiService from 'services/ApiService'
const { Option } = Select;
const FacebookFeedOptions = ({closeFeed}) => {
  const [subheaderText, setsubheaderText] = useState("Page")
  const [accounts, setaccounts] = useState([])
  const [loading, setloading] = useState(true)
  const [selectedFeedProfile, setselectedFeedProfile] = useState(null)
  const [feedText, setfeedText] = useState("")
  const [showerror, setshowerror] = useState(false)
  const [channelInfo, setchannelInfo] = useState(null)
  const [activeTab, setactiveTab] = useState(0)
  const [sending, setsending] = useState(false)
  const [options, setOptions] = useState([]);
  useEffect(() => {
    getAccounts()
  
    return () => {
      
    }
  }, [])
  const getAccounts = async()=>{
    const listed = await ApiService.PostApiCalls("/commerce/accounts/list",{source:"facebook"},true)
    if(listed[0] && listed[1]["IsSuccessful"]){
      setaccounts(listed[1]["accounts"])

    }
    setloading(false)
  }
  const optionsTabs = [
    {
      label:"Page",
      key:"1",
      type:"Page Posts",
    },
    {
      label:"Profile Posts",
      type:"Profile Posts",
      key:"2",

    },
    // {
    //   label:"Albums",
    //   type:"Albums",
    //   key:"3",

    // },
    {
      label:"Page Mentions",
      type:"Page Mentions",
      key:"4",

    }
  ]
  const onChange = (key) => {
    const current = optionsTabs[parseInt(key)-1];
    setsubheaderText(current["label"].toLowerCase())
  };
  const getConnectionResponse = async(data)=>{
    console.log(data);
    const added = await ApiService.PostApiCalls("/commerce/accounts/add",{account:data,source:"facebook"},true)
    if(added[0] && added[1]["IsSuccessful"]){
      setaccounts([...accounts,added[1]["account"]])

    }
  }
  const onSelectChannel = async(value,data)=>{
    console.log(data);
    setselectedFeedProfile(data.pageInfo)
  }
  const handleSearch = (value="money") => {
    
    
    let res = [];
    const token = accounts[0]["accessToken"]
    var config = {
        method: 'get',
        url: 'https://graph.facebook.com/v9.0/me?fields=accounts%7Binstagram_business_account%7Bname%2Cid%2Cfollows_count%2Cig_id%2Cfollowers_count%2Cprofile_picture_url%7D%2Cpage_token%2Cname%2Cid%2Ccategory%2Caccess_token%2Cis_published%2Cpicture%7Burl%7D%7D&access_token='+token,
        headers: { }
      };
      
      axios(config)
      .then(function (results) {
        
        const response = results.data;
        const fbpages = _.filter(response.accounts.data,function(d,i){
            if(d.is_published){
                return d;
            }
        })
          
          
          const items = fbpages;
          items.map((it,k) => {
            
            res.push({label:(<div key={k} className='flex items-center space-x-3'>
              <Avatar src={it["picture"]["data"]["url"]} />
              <span className='flex flex-col space-y-1'>
              <span>{it["name"]}</span>
              
              </span>
              
             
            </div>
          ),value:it["name"],pageInfo:it})
          })
          // console.log(JSON.stringify(response.data));
        setOptions(res);
        
        
       
      })
      .catch(function (error) {
        console.log(error);
      });
    
  };
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  const createFeed = async() =>{
    setshowerror(false)
    
    if(!selectedFeedProfile){
      setshowerror(true)
      return false
    }
    setsending(true)
    const feedOptions = optionsTabs[activeTab];
   
    
    const saved = await ApiService.PostApiCalls('/commerce/feed/create',{feedsource:"facebook","feedtype":feedOptions.type,feedOptions:feedOptions.feedOptions,searchquery:feedText,selectedFeedProfile:selectedFeedProfile},true)
    if (saved[0] && !saved[1]["IsSuccessful"]) {
      const code = saved[1]["code"];
      if(code === 100){
        message
        .warning('Feed already created', 2.5)
        
        
      }
      else{
        message
        .error('Something went wrong, please try again', 2.5)
      }
    setsending(false)

    }
    else{
    setfeedText("");

    setsending(false)
      message
    .success('Feed has been created', 2.5)
    .then(() => message.info('Posts will be added in backend.', 2.5).then(()=>window.location.href = "/creator/social-commerce/manage-feed"));
    }
    
  }
  return (
    <Card className='bg-gray-50 rounded w-full'>
      <div className='flex flex-col space-y-1 p-2'>
    <span className='text-lg'>Facebook</span>
    <span className='text-muted text-sm'>{`Add your Facebook ${subheaderText}`}</span>
      </div>
     {!loading && <div>
        <Tabs onChange={onChange}>
        {
          optionsTabs.map(t => 
            <Tabs.TabPane tab={t["label"]} key={t["key"]}>
            <div className='flex flex-col space-y-3'>
            {/* <Input addonBefore=""  placeholder="Enter hashtag" className='mb-2' /> */}
            <div className='flex flex-col w-full'>
            {accounts.length === 0 && <div className='w-full flex items-center justify-center p-4'>
               <FacebookAuth accountOnly={true} callback={getConnectionResponse} btn={<div className='bg-white rounded-md border flex space-x-3 items-center justify-center p-2 px-3 cursor-pointer'><Facebook color={"#3b5999"}  size={20}/><span  className=''>Connect Facebook </span></div>} />

              </div>}
            </div>
             {
              t["key"] === "2"?
              <div className='flex flex-col space-y-3'><Select  placeholder="select one account"
              onChange={handleChange}
               optionLabelProp="label" >
                {
                  accounts.map((acc,key) => 
                    <Option key={key} value={key} label={acc.name}>
      <div className="demo-option-label-item space-x-3">
        <span role="img" >
          <Avatar src={acc["picture"]["data"]["url"]} />
        </span>
        <span>{acc.name}</span>
      </div>
    </Option>
                    )
                }
                </Select>
                <div className='w-full flex items-center justify-center p-4'><FacebookAuth accountOnly={true} callback={getConnectionResponse} btn={<div className='bg-white rounded-md border flex space-x-3 items-center justify-center p-2 px-3 cursor-pointer'><Facebook color={"#3b5999"}  size={20}/><span  className=''>Connect New Facebook </span></div>} /></div>
                </div>
              
              :
              <div className='w-full'>
                <AutoComplete onSearch={handleSearch}
              onSelect={onSelectChannel}
              onFocus={handleSearch}
              options={options} className='w-full'><Input addonBefore={<Flag />} value={feedText} onChange={(e)=>setfeedText(e.target.value)}   placeholder={"Select page"} className='mb-0' /></AutoComplete>
              </div>

             }
            </div>
          </Tabs.TabPane>
          )
        }
      </Tabs>
      </div>}
      <div className='flex flex-row items-center justify-end space-x-3 py-3'>
        <Button onClick={closeFeed} loading={sending} className='rounded-sm'>Back</Button>
        {accounts.length > 0 && <Button loading={sending} onClick={createFeed} className='bg-hs-pink text-white rounded-sm'>Create Feed</Button>}
        
        </div>
        
    </Card>
  )
}

export default FacebookFeedOptions