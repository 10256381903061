import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ApiService from "services/ApiService";
import CardFooter from "./CardFooter";
import Observer from '@researchgate/react-intersection-observer';


import CardProfileInfo from "./CardProfileInfo";
import CardText from "./CardText";
import CardVisual from "./CardVisual";
import Comments from "./comments/Comments";

import Utils from "helper/Utils";
import axios from "axios";
import { Link } from "react-router-dom";

const FeedCard = ({ post, id ,shwoFull}) => {
  const [content, setContent] = useState(post);
  const [showComments, setshowComments] = useState(false)
  const [cardBg, setcardBg] = useState("bg-white")
  const author = content["author"][0]?content["author"][0]:content["authorGroup"][0]
  useEffect(() => {
    
  
    return () => {
      // setshowComments(false)
    }
  }, [content])
  const addReaction = async(type,reaction,comment="") =>{
    if(type === "like"){
      const reacted = await ApiService.PostApiCalls(`posts/${content["contentID"]}/reaction`,{reaction:reaction,isComment:false,commentId:""},true);
      if(reacted[0] && reacted[1]["IsSuccessful"]){
        setContent(reacted[1]["post"])
      }
    }
    else  if(type === "bookmark"){
      console.log(content["asstes"][0]["type"]);
      const bookmarkType = content["asstes"] && content["asstes"][0] ?content["asstes"][0]["type"]:"post"
      const reacted = await ApiService.PostApiCalls(`bookmarks/create`,{reaction:reaction,isComment:false,commentId:"",type:bookmarkType,bookmarkId:content["contentID"]},true);
      if(reacted[0] && reacted[1]["IsSuccessful"]){
        // setContent(reacted[1]["post"])
      }
    }
    else if(type === "comment"){
      setshowComments(true)
    }
    else if(type === "commented"){
      setshowComments(true)
      setContent(reaction)
    }
   
  }
  const trackView = (event) =>{
    const trackerId = Utils.getCookie("personalization_id");
    if(event.isIntersecting){
      let data = JSON.stringify({
        "link": content["shortLink"],
        "pid": trackerId
      });
      let config = {
        method: 'post',
        
        url: 'https://hs-ga.com/track',
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer AQU5SPgizEExKHZAqdAv_j-5UhAHkvsysnYvpeXViw7uKx'
        },
        data : data
      };
      
      axios.request(config)
      .then( async (response) => {
        
        if(!response.data.value){
        await ApiService.PostApiCalls("/posts/updateView/"+content["contentID"],{},true)
      }
      })
      .catch((error) => {
        
      });
    }
    
  }
  return (
    <Observer  onChange={(e) =>trackView(e)} threshold={0.5} >
   <div className={`${cardBg} py-3 px-4 rounded-xl mb-3 hover:bg-gray-50 cursor-pointer`} id={`post-${content["_id"]}`}>
      <CardProfileInfo author={content["author"][0]?content["author"][0]:content["authorGroup"][0]} content={content} date={content.createdAt} />
      <div className="py-2"></div>
      <CardText content={content["content"]} minted={content["minted"]} fractions={content["fractions"]} shwoFull={shwoFull} author={content["author"][0]?content["author"][0]:content["authorGroup"][0]}  postId={content["contentID"]} postLocale={content["lan"]}  docId={content["_id"]} />
      <div className="py-2"></div>
      {content["asstes"][0]["content"][0] && 
      
      
        shwoFull ? <CardVisual visual={content["asstes"][0]["content"]} type={content["asstes"][0]["type"]} />
        :
        <Link to={`/${author.userName}/posts/${content["contentID"]}`  } class="text-hs-dark" ><CardVisual visual={content["asstes"][0]["content"]} type={content["asstes"][0]["type"]} /></Link>
      }
      
      <div className="py-2"></div>
      <CardFooter id={id} stats={content} callback={addReaction}  />
      <div className="py-1" ></div>
      {showComments && <Comments content={content} callback={addReaction}  />}
      <div className="py-1" ></div>


    </div></Observer>
  );
};

export default FeedCard;
