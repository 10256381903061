/*global FB*/
import React, {Component} from 'react';
import {Person, Instagram, ArrowRepeat } from 'react-bootstrap-icons';
import {Modal,Row,Col} from "react-bootstrap";
import { Button,Select   } from 'antd';
import ApiService from "services/ApiService";
import _ from "lodash"
var axios = require('axios');
const { Option } = Select;

class InstaAuth extends Component{
    constructor(props) {
        super(props)
    
        this.state = {
             isUserConected:false,
             socialProfilesList:null,
             profileOwner:null,
             show:false,
             error:null,
             selected:-1,
             isErrorNoConnected:null
        }
        this.testAPI = this.testAPI.bind(this)
    }
    
    componentDidMount() {
        const _self = this;
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));


        window.fbAsyncInit = ()=> {
            FB.init({
                appId: '1438247273203808',//Change with your Facebook app id
                autoLogAppEvents: true,
                xfbml: true,
                version: 'v3.0'
            });

            // FB.Event.subscribe('auth.statusChange', response => {
            //     if (response.authResponse) {
            //         this.checkLoginState();
            //     } else {
            //         // console.log('[FacebookLoginButton] User cancelled login or did not fully authorize.');
            //         _self.setState({show: true,error:"Please fully authorize our Facebook App to connect your social profile."})
            //     }
            // });
        };
    }
    getExtendedUserToken = async (code) =>{
        const _self = this;
        var config = {
            method: 'get',
            url: 'https://graph.facebook.com/v9.0/oauth/access_token?grant_type=fb_exchange_token&client_id=1438247273203808&client_secret=830c78b50cf65b918d5d38bad28dc8b7&fb_exchange_token='+code,
            headers: { }
          };
          
          axios(config)
          .then(function (response) {
            _self.getPages(response.data.access_token)
            
          })
          .catch(function (error) {
            console.log(error);
          });
    }
    getPages = async(token) => {
        const _self = this;
        this.setState({userToken:token})
        var config = {
            method: 'get',
            url: 'https://graph.facebook.com/v9.0/me?fields=name%2Cemail%2Cid%2Caccounts%7Binstagram_business_account%7Bname%2Cid%2Cfollows_count%2Cig_id%2Cfollowers_count%2Cprofile_picture_url%7D%2Cpage_token%2Cname%2Cid%2Ccategory%2Caccess_token%2Cis_published%2Cpicture%7Burl%7D%7D&access_token='+token,
            headers: { }
          };
          
          axios(config)
          .then(function (results) {
            
            const response = results.data;
            const instapages = _.filter(response.accounts.data,function(d,i){
                if(d.instagram_business_account){
                    return d;
                }
            })
            if(_self.props.reconnect){
               
                const connectedPage = _.filter(instapages,function(d,i){
                    
                    if(d["instagram_business_account"]["id"] === _self.props.pid){
                        return d;
                    }
                })
                
                _self.setState({selected:0,profileOwner:{name:response.name,id:response.id,accessToken:token},socialProfilesList:connectedPage});
                _self.saveSocialProfile();
            }else{
                _self.setState({show:true,error:null,isErrorNoConnected:null,selected:0,profileOwner:{name:response.name,id:response.id,accessToken:token},socialProfilesList:instapages});
            }
            
           
          })
          .catch(function (error) {
            console.log(error);
          });
          
    }
    checkLoginState(){
        FB.getLoginStatus(function(response) {
            this.statusChangeCallback(response);
        }.bind(this));
    }

    login(){
        const _self = this;
        this.setState({error:null,isErrorNoConnected:null})
        FB.login(function(response) {
            if (response.status === 'connected') {
                
                _self.statusChangeCallback(response);
              // Logged into your webpage and Facebook.
            } else {
                _self.setState({
                    isErrorNoConnected:"You did not fully authorized this Facebook Account",
                    show:true,
                })
              // The person is not logged into your webpage or we are unable to tell. 
            }}, {scope: 'pages_show_list,instagram_content_publish,pages_read_engagement,public_profile,email,instagram_basic'});
    }

    statusChangeCallback(response) {
        // console.log(response);
        // const code = "EAAMfkM4OHs4BAMmdNqMKVDBOSj6lLiVwmZCZCnKOb1bvhSMcwtCZBBpoWBM8xLkN2nRyIuHENU4F2XXrv8CxBFD9SZAuzZCqJJzZCJQhd3Y6kK5lqWjFblTZAoZBL2QpKNVcNOnsTs7SM0aPKZBXNKwFODbjAUZA30v7KIQL3sczMX9pqjFAV7ZBcE8OovZAFUTE9OLHJdglhzTIRGJOSSsnodTFzZAeoREaJJF8ZCzpJXDnkcdbEga3rLDZAz0"
        // this.getExtendedUserToken(code);
        if (response.status === 'connected') {
            const code = response.authResponse["accessToken"]
            this.getExtendedUserToken(code);
        } else if (response.status === 'not_authorized') {
            this.setState({
                isErrorNoConnected:"You did not fully authorized this Instagram Account",
                show:true,
            })
        } else {
            this.setState({
                isErrorNoConnected:"Login to Instagram and try again",
                show:true,
            })
        }
    }

    testAPI() {
        const _self = this;
        // FB.api('/me/accounts?fields=page_token,name,id,category,access_token,is_published,picture{url}', function(response) {
            FB.api('/me/?fields=name,email,id,accounts{instagram_business_account{name,id,follows_count,ig_id,followers_count,profile_picture_url,user,username},page_token,name,id,category,access_token,is_published,picture{url}}', function(response) {       
            _self.setState({show:true,error:null,profileOwner:{name:response.name,id:response.id},socialProfilesList:response.accounts.data});
        });
    }
    handleChange = (value) =>{        
        console.log(value);
        this.setState({selected:value})
    }
    saveSocialProfile = async ()=>{
        this.setState({error:null})
        const {socialProfilesList,selected,profileOwner} = this.state;
        if(selected === -1){
            this.setState({error:"Please select atleast one page."})
            return false;
        }
        let pf = socialProfilesList[selected];
        pf["source"] = "Instagram";
        pf["profileOwner"] = profileOwner;
        pf["fb-picture"] = pf["instagram_business_account"]["profile_picture_url"];
        pf["name"] = pf["instagram_business_account"]["name"];
        pf["id"] = pf["instagram_business_account"]["id"];
        if(this.props.change){
            pf["isChange"] = "yes";
            pf["spid"] = this.props.spid;
        }
        if(this.props.reconnect){
            pf["isChange"] = "yes";
            pf["isReconnect"] = "yes";
            pf["spid"] = this.props.spid;
        }
        this.props.callback(pf,socialProfilesList,"Instagram");
        this.setState({show:false})
    }
    render(){
        const {show,socialProfilesList,error,isErrorNoConnected} = this.state;
        return (
            <>
            {this.props.change ? <div className="twitter-btn flex items-center"  onClick={()=>this.login()}><Person size={12} className="mr-1 text-hs-blue mrt-m3" />
            <span>Change Page</span></div>:
            this.props.reconnect ? <div className="twitter-btn flex items-center"  onClick={()=>this.login()}><ArrowRepeat size={12} className="mr-1 text-hs-blue mrt-m3" />
            <span>Reconnect</span></div>:
            <div className="twitter-btn"  onClick={()=>this.login()}><Instagram color={"#fbad50"} size={45} /></div>
            }
            
            <Modal show={show} onHide={() => this.setState({show: false,isErrorNoConnected:null,error:null})} centered contentClassName="campaign-new-modal">
            <Modal.Header >
            <span className="modal-title">Social profile</span>
            </Modal.Header>
            <Modal.Body>
            {isErrorNoConnected && <Row className="mt-3">
          <Col lg={12} md={12} sm={12} className="campaign-new-info fz-14 fw-600">
          {isErrorNoConnected}
          </Col>
          </Row>}
            { !isErrorNoConnected && <Row className="mt-3">
          <Col lg={12} md={12} sm={12} className="campaign-new-info fz-14 fw-600">
          {error? <>{error}</>:`Select the Instagram Business Account you want to link with your account.`}
          </Col>
          <Col lg={12} md={12} sm={12} className="campaign-new-info fz-14 fw-600 my-4">
          {socialProfilesList && <Select defaultValue={0} defaultActiveFirstOption={true} onChange={this.handleChange} style={{ width: '100%' }} >
          {socialProfilesList.map((data,key)=>(
            data.is_published && <Option value={key}>{data["instagram_business_account"].name}</Option>
          ))}
            
            
         </Select>}
          </Col>
          </Row>}
            </Modal.Body>
            <Modal.Footer className="mt-4">
           
          <Button variant="secondary" onClick={() => this.setState({show: false})}>
            Close
          </Button>
          <Button  type="primary" onClick={() => this.saveSocialProfile()}> 
          Save
          </Button>
        </Modal.Footer>
            </Modal>
            </>
        )
    }                                                                                                                                                                                                                                     z
}

// export default FacebookLoginButton;
export default InstaAuth;
