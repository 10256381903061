import { Row, Col, Typography,Upload, Modal, Button, Spin,notification,message } from "antd";
import {Form,Tooltip,Spinner} from "react-bootstrap";
import React, { useEffect, useState } from "react";
import {startUpload,UploadSingle} from "services/FileUpload"

import _ from "lodash";
import ApiService from "services/ApiService";
import Loading from "res/icons/Loading";
import { Container } from "react-bootstrap";
import TemplateView from "components/TemplateView";
const { Paragraph } = Typography;
const { confirm } = Modal;

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}
const SettingsBranding = ({ user, activeWorkSpace }) => {
  const [viewloading, setViewLoading] = useState(true);
  const currentworkspace = activeWorkSpace;
  // const [loading, setLoading] = useState(false);
  const brandInfo = currentworkspace;
  const [loading,setLoading] = useState(true);
  const [usedlibrary,setusedLibrary] = useState([]);
  const [library,setLibrary] = useState([]);
  const [activeTab,setactiveTab] = useState(0);
  const [companyName,setCompanyName] = useState()
  const [authorName,setAuthorName] = useState()
  const [themeColor, setThemeColor] = useState()
  const [textColor, setTextColor] = useState()
  const [brandImage,setBrandImage] = useState()
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUploading, setisUploading] = useState(false)
  const [isSaving, setisSaving] = useState(false)
  const [progressCount, setProgressCount] = useState(0)
  const [uploadedCount, setuploadedCount] = useState(0)
  const [currentItem, setCurrentItem] = useState(null)
  const spinIcon =  <div className="anticon anticon-loading anticon-spin ant-spin-dot"><Loading  /></div>;
    const startEdit = (d) => {
        setBrandImage(d["brandImage"]?d["brandImage"]:brandInfo["brandImage"])
        setCompanyName(d["companyName"]?d["companyName"]:brandInfo["companyName"])
        setAuthorName(d["authorName"])
        setThemeColor(d["speech_bubble_color"])
        setTextColor(d["quote_font_color"])
        setCurrentItem(d)
        setIsModalVisible(true);
    };
    
  useEffect(() => {
    
    getDetails()
    return () => {
      
    }
  }, [])
  const getDetails = async()=>{
    
    setLoading(true)
    const wid = activeWorkSpace["wid"];
    const b = await ApiService.PostApiCalls("creator/branding/list",{wid:wid},true)
    if(b[1] && b[1]["IsSuccessful"]){
      setLibrary(b[1]["templates"])            
      setusedLibrary(b[1]["used"])     
  }        
    setViewLoading(false)
    setLoading(false)
  }
  const getColor = (e)=>{
    const rgb = hexToRgb(e);
    const brightness = Math.round(((parseInt(rgb.r) * 299) +
                    (parseInt(rgb.b) * 587) +
                    (parseInt(rgb.g) * 114)) / 1000);
    const clr = (brightness > 125) ? '#333333' : '#ffffff';
    setTextColor(clr)

}
  
  const confirmDeActivation = (d) => {
    confirm({
           title: 'Deactivate template?',
           icon: null,
           content: 'Are you sure you want to deactivate this template? If you have another branding template active in this Workspace we will link that to any social post linked to the one being deleted. If not, those social posts will have their quote bubble image removed permanently.',
           okText: 'Yes',
           cancelText: 'No',
           async onOk() {
             await addRemove(d,"remove");
           },
           onCancel() {
             console.log('Cancel');
           },
         });
       
 }
  const handleOk = async () => {
    setisSaving(true);
    const data = {
        wid:currentItem["wid"],
        id:currentItem["id"],
        updates:{companyName:companyName,authorName:authorName,"brandImage":brandImage,speech_bubble_color:themeColor,quote_font_color:textColor}
       }
    const update = await ApiService.PostApiCalls("creator/branding/update/"+currentItem["id"],data,true)
    if(update[1]["IsSuccessful"]){
        setIsModalVisible(false);
        setisSaving(false);
        getDetails()
    }
    else if(!update[1]["IsSuccessful"]){
        setisSaving(false)
        notification["error"]({
            message: update[1]["msg"],
            description:
              'There is an error while processing your request',
          });
    }
    
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const addRemove =  async (d,action) =>{
   
    let template = {
      ...d,
     
    }
    if(usedlibrary[0]){
      template = {
        ...d,
        companyName:usedlibrary[0]["companyName"],authorName:usedlibrary[0]["authorName"],"brandImage":usedlibrary[0]["brandImage"],speech_bubble_color:usedlibrary[0]["speech_bubble_color"],quote_font_color:usedlibrary[0]["quote_font_color"]
      }
    }
    const data = {
      template:template,
      wid:brandInfo["wid"],
      id:d["id"],
      action:action
    }

    const added = await ApiService.PostApiCalls("creator/branding/activate",data,true)
    if(added[0] && added[1]["IsSuccessful"]){
      setIsModalVisible(false);
      setisSaving(false);
      getDetails()
    }
    else{
      setIsModalVisible(false);
      setisSaving(false)
        notification["error"]({
            message: "There is an error while processing your request",
            description:
              '',
          });
    }
  }

  const updateProgressBar = () =>{

  }
  const UploadFinish = (value,name,type,cid,uid) =>{              
      let imagepath = "https://hs-container.s3.ap-south-1.amazonaws.com/" + name
      setBrandImage(imagepath)
      setisUploading(false)
  }
  const UploadFail = () =>{

  }
  const customUploadRequest = (e) =>{
    const UploadState = startUpload(e.file.size);
    if(UploadState === "single")    
    {        
      setisUploading(true)
      setProgressCount(uploadedCount+1)
      UploadSingle(e,updateProgressBar,UploadFinish,UploadFail,progressCount,"","hashsaga/accounts/authorimages")
      .then(res => {          
       
      })
      .catch(error => {            
      });
     
    }
}
  if(viewloading){
    return (
      <>
      <Col span={24} className="py-0 px-0 mb-0">
        <div className="bg-white p-3 px-3 flex space-x-3 items-center justify-between">
          <span className="font-bold">Post Quote Templates</span>
          <Button
            
            type="primary"
            className=" rounded  float-right mr-3"
            loading={loading}
          >
           
          </Button>
        </div>
      </Col>
      <div className="pb-2 bg-gray-100"></div>
      <Col span={24} className="py-0 px-0 mb-0 h-screen flex items-center justify-center">
      <Spin />
      </Col>
      </>
    )
  }
  return (
    <> <Col span={24} className="py-0 px-0 mb-0">
    <div className="bg-white p-3 px-3 flex space-x-3 items-center justify-between">
      <span className="font-bold">Post Quote Templates</span>
      <Button
         
        type="primary"
        className=" rounded  float-right mr-3"
        loading={loading}
      >
         
      </Button>
    </div>
  </Col>
  <div className="pb-2 bg-gray-100"></div>
  <Col span={24} className="">
  <Row className="p-2">
  <Col span={24} className="p-2">
            <Paragraph className="text-sm text-hs-dark">
            Set your theme.
            </Paragraph>
          </Col>
          <Col className="px-0 pr-4 mt-4 " span={24}>
          {loading && <Container fluid="true" className="px-0">
      <Row className="mx-0">

          <Col className="px-0 py-3 mt-4 d-flex align-items-center justify-content-center"
           span={24} >
          <Spin />
           </Col></Row>
             
             </Container>}
             {!loading && <Row className="mx-0 mt-4">
                <Col span={6} className="settings pl-0">
        <div onClick={() =>setactiveTab(0)} className={activeTab === 0 ? "px-3 py-2 my-2 cursor-pointer brand-menu active":"px-3 py-2 my-2 cursor-pointer brand-menu"}>Active Themes</div>
        <div onClick={() =>setactiveTab(1)} className={activeTab === 1 ? "px-3 py-2 my-2 cursor-pointer brand-menu active":"px-3 py-2 my-2 cursor-pointer brand-menu"}>My Theme Library</div>
        <div onClick={() =>setactiveTab(2)} className={activeTab === 2 ? "px-3 py-2 my-2 cursor-pointer brand-menu active":"px-3 py-2 my-2 cursor-pointer brand-menu"}>Browse Available Themes</div></Col>
        <Col span={18}>
           {activeTab === 0 && <Row className="">
                {usedlibrary.map((d,key)=>(
                    <Col span={12} key={key} className="px-3" >
             <TemplateView startEdit={startEdit} confirmDeActivation={confirmDeActivation} showEditor={true} d={d} brandName={d["companyName"]?d["companyName"]:brandInfo["companyName"]} brandAuthor={d["authorName"]} themeColor={d["speech_bubble_color"]} brandImage={d["brandImage"]}  />
             <div className="mb-3"></div>
             </Col>
                ))}</Row>}
                {activeTab === 1 && <Row className="overflow-hidden">
                {library.map((d,key)=>(
                    <Col span={12} key={key} className="px-3" >
             <TemplateView addRemove={addRemove} used={_.map(_.filter(usedlibrary,function(obj) {return obj.id}), 'id')} startEdit={startEdit} confirmDeActivation={confirmDeActivation}  showEditor={true} d={d} brandName={d["companyName"]?d["companyName"]:brandInfo["companyName"]} brandAuthor={brandInfo["authorName"]} themeColor={brandInfo["themeColor"]} brandImage={brandInfo["brandImage"]}  />
             <div className="mb-3"></div>
             </Col>
                ))}</Row>}
                {activeTab === 2 && <Row className="overflow-hidden">
                {library.map((d,key)=>(
                    <Col span={12} key={key} className="px-3">
             <TemplateView  addRemove={addRemove}  used={_.map(_.filter(usedlibrary,function(obj) {return obj.id}), 'id')} startEdit={startEdit} confirmDeActivation={confirmDeActivation}  showEditor={true} d={d} brandName={d["companyName"]?d["companyName"]:brandInfo["companyName"]} brandAuthor={brandInfo["authorName"]} themeColor={brandInfo["themeColor"]} brandImage={brandInfo["brandImage"]}  />
             <div className="mb-3"></div>
             </Col>
                ))}</Row>}
                </Col>
             </Row>}
          </Col>
  </Row>
  </Col>
  <Modal title="Edit basic details" visible={isModalVisible}
              okText="Save"
              okButtonProps={{ loading: isSaving }}
              onOk={handleOk} onCancel={handleCancel}>
        <p>Update basic brand and author details for this theme.</p>
        <Row>
        <Col  span={12} className="mb-3 px-2"  ><span className="fw-bold fz-14 mb-2 float-left w-100">Brand Name:</span>
             <Form.Control type="text" name="brandname"  value={companyName}  onChange={(e) => setCompanyName(e.target.value)}   autoComplete="new-brandname" />
             </Col>
             <Col  span={12}   className="mb-3 px-2"  >
             <span className="fw-bold fz-14 mb-2 float-left w-100">Brand Color:</span>
             <Form.Row className="colorpicker-row mx-0 float-left w-100">
    <div className="uploadContainer colorpicker d-flex justify-content-between">
    <div className="text text-uppercase">{themeColor}</div>
     </div><div className="colorpicker-container cursor-pointer"> <input value={themeColor} type="color" onChange={(e) => {setThemeColor(e.target.value);getColor(e.target.value)}}  /></div>
    </Form.Row> </Col>
             <Col  span={12}  className="mb-3 px-2"   ><span className="fw-bold fz-14 mb-2 float-left w-100">Author Name:</span>
             <Form.Control type="text" name="authorname" value={authorName}  onChange={(e) => setAuthorName(e.target.value)}  autoComplete="new-authorname" />
    </Col>
             <Col  span={12}  className="mb-3 px-2"  ><span className="fw-bold fz-14 mb-2 float-left w-100">Author Image:</span>
             <Form.Row className="mx-0  float-left w-100">
    <Upload className="flex-1 uploadContainer"
      multiple={false}          
      customRequest={customUploadRequest}
      accept="image/*"
      listType='none'
      disabled={isUploading?true:false}
      showUploadList={false}    

    
     >
    <div className="flex-1 d-flex flex-row justify-content-between">
    <div className="activeImage">
        <img src={brandImage} alt="" />
    </div>
    
    {!isUploading && <div className="text-upload">Browse</div>}
    {isUploading && <div className="upload-imagebt">
        <Spin size="small" indicator={spinIcon} spinning={true} />
        </div>}
    </div>
    </Upload>

    </Form.Row></Col>
        </Row>
        
      </Modal>
  </>
  )
}

export default SettingsBranding