import React, { useContext, useState, useEffect } from "react";
import { Row, Col, Spin, } from "antd";
import {Container} from "react-bootstrap";
// import {Spin,Button} from "antd";
import Info from "./views/Info"
import Timeline from "./views/Timeline"
import InfoTimestamp from "./views/InfoTimestamp"
import SocialProfileInfo from "./views/SocialProfileInfo"
import StaticPost from "./StaticPost"

import { AuthContext } from "app/AuthProvider";
import { PushSpinner,SpiralSpinner,GuardSpinner } from "react-spinners-kit";
import _ from "lodash";
import ApiService from "services/ApiService";
import Utils from "helper/Utils";
import "./style.css"
import VideoPost from "./VideoReview/VideoPost";
import { VideoProvider } from "app/VideoProvider";
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from "react-router-dom";

const CampaignReview = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  console.log(location);
  const brParams = useParams();

    const {
        authUser,
        activeWorkSpace,
       
      } = useContext(AuthContext);
    //   console.log(authUser);
    const [loading, setLoading] = useState(true)
    const [campaign, setData] = useState(null)
    const [campaignType, setCampaignType] = useState(null)
    const [minQuotesNo,setMinQuotesNo] = useState(0)
    const [duration, setDuration] = useState(null)
    const [selectedProfiles,setselectedProfiles] = useState(_.map(Utils.getWorkSpaceSocialProfiles(authUser,activeWorkSpace["wid"]), 'id'))
    const [scheduleList, setscheduleList] = useState({})
    const [schedList, setschedList] = useState([])
    const [showPreparing, setshowPreparing] = useState(false)
      useEffect(() => {
          getCampaign(brParams.id)
          return () => {
              
          }
      }, [brParams.id])
      const getCampaign =async(id)=>{
          const details = await ApiService.PostApiCalls("campaign/get/"+id,{},true)
          if(details[0]){
            
            if(details[1]["campaign"]["status"] === 0){
              setLoading(true)
              setTimeout(() => {
                getCampaign(brParams.id);
              }, 10000);
            }
            else{
            const activeCampaign =details[1]["campaign"];
            setData(activeCampaign)
            setCampaignType(activeCampaign["details"]["campaign-post"])
            
            setDuration(activeCampaign["posts"]["duration"])
            let scheduleList = {
      
            } 
           
            activeCampaign["defaultSchedules"].map((s,k)=>{
            scheduleList[[s["code"]]] = {slot:s["days"],days:s["postCount"]}
            }) 
            setscheduleList(scheduleList)
            setschedList(activeCampaign["defaultSchedules"])
            setMinQuotesNo(scheduleList[activeCampaign["posts"]["duration"]]["days"])
            
          
            if(activeCampaign["selectedProfiles"]){
                
                setselectedProfiles(activeCampaign["selectedProfiles"])
            }
            else{
              
                let spd = _.map(Utils.getWorkSpaceSocialProfiles(authUser,activeWorkSpace["wid"]),"id")
                
                setselectedProfiles(spd)
                const data = {
                cid:activeCampaign["cid"],
                updates:{"selectedProfiles":spd},
                }
                const updated = ApiService.PostApiCalls("campaign/update/"+activeCampaign["cid"],{...data},true)
            }
          
              setLoading(false)
              

          }
        }
      }
      if(loading){
        return (
          <Container fluid="true" className="px-0 position-absolute z-20 left-0 w-100 bg-white -mt-6 h-screen flex items-center justify-center">
         <Row className="mx-0">
            <Col className="p-0 m-0 d-flex flex-column space-y-4 align-items-center justify-content-center loader-height"
             lg={24}      
             md={24}
             sm={24} >
               <GuardSpinner
                size={50}
                frontColor="#0079ff"
                backColor="#f6327d"
                loading={loading}
            />
            <span className="text-lg">{t("crevprep1")}</span>
            <span className="text-xs">{t("crevprep2")}</span>
            </Col></Row></Container>
             )
      } 
      const updateDuration = async (dur) =>{
        setDuration(dur)        
        const data = {
          cid:campaign["cid"],
          updates:{"posts.duration":dur,"details.campaign-duration":dur,"selectedProfiles":selectedProfiles },
          }
          const updated =  ApiService.PostApiCalls("campaign/update/"+campaign["cid"],{...data},true)
      }
      const setProfiles = (key) =>{
        let newProfiles = [];
        if(selectedProfiles.includes(key)){            
          var newList = _.remove(selectedProfiles, function(n) { return n !== key; });            
          setselectedProfiles(newList)  
          newProfiles = newList;
      }
      else{
        setselectedProfiles([...selectedProfiles,key]);
        newProfiles = [...selectedProfiles,key];
      }
      const data = {
        cid:campaign["cid"],
        updates:{"selectedProfiles":newProfiles},
        }
        const updated = ApiService.PostApiCalls("campaign/update/"+campaign["cid"],{...data},true)
      }
    return (
        <>
        <Container fluid="true" className="px-0 relative">
        <div className="sidebar-secondary">
        <Info campaign={campaign} />
        <Timeline duration={duration} update={updateDuration}  callback={setMinQuotesNo} scheduleList={scheduleList} schedList={schedList} />
        <InfoTimestamp campaign={campaign} />
        <SocialProfileInfo selectedProfiles={selectedProfiles} profile={Utils.getWorkSpaceSocialProfiles(authUser,activeWorkSpace["wid"])} callbackSelected={setProfiles} />
       </div>
        <div className={campaignType === "video-post"?"main-content-review video":" "}>
        {campaignType === "video-post" && <VideoProvider><VideoPost profilesSelected={selectedProfiles} scheduleList={scheduleList} campaign={campaign} minQuotes={minQuotesNo} duration={duration} url={location.pathname}  /></VideoProvider>}
        {campaignType === "static-post" && <StaticPost authUser={authUser} slug={activeWorkSpace["slug"]} profilesSelected={selectedProfiles} scheduleList={scheduleList} campaign={campaign} minQuotes={minQuotesNo} duration={duration} url={location.pathname} uid={authUser["uniqueId"]} />}
        
       
        </div>
        </Container>
        </>
    )
}

export default CampaignReview
