import React, { useState } from 'react'
import { Button, Result, Spin, Row, Col, Card } from "antd";
import { Cart2, Display, WindowDock } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
const SocialShop = () => {
    const [loading, setloading] = useState(false)
    return loading ? (
        <div className=" h-100 d-flex justify-center align-items-center bg-white py-3 px-4 rounded-xl ">
          <Spin />
        </div>
      ) : (
        <>
          <Row gutter={16}>
            <Col span={24} className="mb-3">
              <div className=" d-flex justify-center flex-col bg-white py-3 mb-3 rounded-xl ">
                <div className="px-4 d-flex flex-col justify-between mb-2 pb-2 border-b">
                  <span className="text-hp text-lg font-semibold">Social Shop</span>
                  <span className="text-hp text-xs">
                    Shop created with social contents
                  </span>
                </div>
    
               <div className='flex items-start justify-between px-3 py-2'>
                <div className='w-100 p-4 flex flex-col space-y-2'>
                <Link to={"/social-shop/display"} style={{ textDecoration: 'none' }} className="text-hs-dark">  <div className='p-3 flex flex-col rounded w-100 bg-gray-50 cursor-pointer'>
                 <div className='flex flex-row space-x-3 items-center'><Display size={30} />
                 <span className='text-xl font-semibold'>Display</span>
                 </div>  
                 <span className='py-2'>Social Walls For Events, Signages & Digital Displays</span> 
                    </div></Link>
                    <Link to={"/social-shop/widget"} style={{ textDecoration: 'none' }} className="text-hs-dark">       <div className='p-3 flex flex-col rounded w-100 bg-gray-50 cursor-pointer'>
                 <div className='flex flex-row space-x-3 items-center'><WindowDock size={30} />
                 <span className='text-xl font-semibold'>Widget</span>
                 </div>  
                 <span className='py-2'>Embed Social Feeds, UGC, & Reviews On Webpages</span> 
                    </div></Link>
                <Link to={"/social-shop/hashshop"} style={{ textDecoration: 'none' }} className="text-hs-dark">   <div className='p-3 flex flex-col rounded w-100 bg-gray-50 cursor-pointer'>
                 <div className='flex flex-row space-x-3 items-center'><Cart2 size={30} />
                 <span className='text-xl font-semibold'>Hash Shop</span>
                 </div>  
                 <span className='py-2'>Shoppable Galleries & UGC Feeds For eCommerce</span> 
                    </div>
                    </Link> 
                </div><div>
                <div className="px-4 d-flex flex-col justify-between mb-2 pb-2 border-b">
                <span className="text-hp text-lg font-semibold">What is social shop?</span>
                  <span className="text-hp text-xs">
                    
                  </span>
                </div>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/H_VznoiLSdM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
               </div>
              </div>
             
            </Col>
            <Col span={24} className="mb-3">
            <div className=" d-flex justify-center flex-col bg-white p-3 py-4 mb-3 rounded-xl ">
            <div className='flex items-center'>
            <span className='px-2 font-medium'>If you have any questions then you can schedule a demo with our experts or explore the support center to get the step by step guide for Social Shop.</span>
            <span className='flex space-x-2'>
            <Button className='rounded-md' size='large'>Example Gallery</Button>
            <Button size='large' className=' fw-700 mx-2 rounded-lg text-white bg-hs-pink  hover:border-hs-pink'>Book A Demo</Button>
            </span>
            </div>
            </div>
            </Col>
          </Row>
        </>
      );
}

export default SocialShop