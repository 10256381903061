import { Button, Checkbox, Tooltip, Input, Menu, Spin } from "antd";
import VisualDisplay from "components/visuals/VisualDisplay";
import FileUploader from "container/common/FileUploader";
import SocialIcon from "container/common/SocialIcon";
import Utils from "helper/Utils";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  CaretDownFill,
  Upload as UploadIcon,
  Image,
  PlusCircle,
  Trash,
  Coin,
  InfoCircle,
  Check2Circle,
} from "react-bootstrap-icons";
import { BsSlashCircle } from "react-icons/bs";
import Tags from "container/common/Tags";
import md5 from "md5";
import ApiService from "services/ApiService";
import { Link } from "react-router-dom";
const keyword_extractor = require("keyword-extractor");
const { TextArea } = Input;

const AddNewCampaign = ({ showModalFirst, callback, profiles, user }) => {
  const [show, setshow] = useState(showModalFirst);
  const [url, seturl] = useState("");
  const [title, settitle] = useState("");
  const [error, seterror] = useState(null);
  const [addingUrl, setaddingUrl] = useState(false);
  const [fetchedSuccess, setfetchedSuccess] = useState(false);
  const [articelData, setarticelData] = useState(null);
  const [campignType, setcampignType] = useState(0)
  const [campigndripType, setcampigndripType] = useState(0)
  const [created, setcreated] = useState(null)
  const fetchInfo = async () => {
    seterror(null);
    setfetchedSuccess(false);
    if (url.trim() === "") {
      seterror("Enter the valid URL");
      return false;
    }
    setaddingUrl(true);
    const fetched = await ApiService.PostApiCalls(
      "campaign/verify",
      { url: url },
      true
    );
    setaddingUrl(false);

    if (fetched[0] && fetched[1]["IsSuccessful"]) {
      setarticelData(fetched[1]["data"]);
      setfetchedSuccess(true);
      settitle(fetched[1]["data"]["article_title"]);
    } else {
      seterror("Oops there was a problem. Did you enter a valid URL?");
    }
  };
  const changeType = (e,i) =>{
      if(e.target.checked){
        setcampignType(i)
      }
      else{
        setcampignType(i)
      }
  }
  const changeTypeDrip = (e,i) =>{
    if(e.target.checked){
      setcampigndripType(i)
    }
    else{
        setcampigndripType(i)
    }
}
const createCampaign = async() =>{
    setaddingUrl(true)
    setcreated(null)
    const schedule = {
        "name": "12 Month Success",
        "code": "success",
        "days": [0, 3, 7,14,30,90,180,225,270,365],
        "id": 2,
        "duration": 365,
        "postCount": 10
    }
    const details = {"campaign-post": campignType === 0?"static-post":"video-post",
    "campaign-name": title,
    "campaign-url": url,
    "campaign-drip": campigndripType ===0 ?"auto-drip":"empty-drip",
    "campaign-duration": "success"}
    const data = {
        title:title,
        url:url,    
        campaign:details,
        schedule,
        urlhash:md5(articelData["article_url"]+Math.random().toString(36).substr(2, 5)),
        wid:user["workspace"][0]["wid"] //TO-DO alter as per current workspace
      }
      const created = await ApiService.PostApiCalls("campaign/create",{...data},true)
      if(created[0] && created[1]["IsSuccessful"]){
        setcreated(created[1]["cid"]);
        setaddingUrl(false);
      }
      else{
        setaddingUrl(false);
        seterror(created[1]["ErrorMessage"])
      }
      
}
  return (
    <div>
      <Modal
        show={show}
        onHide={() => {
          setshow(false);
          callback(false);
        }}
        centered
        size="lg"
        contentClassName="campaign-new-modal rounded-2xl"
        backdrop={"static"}
      >
       {user.selectedAppPlan["code"] === "free"?<>
       <Modal.Body>
          <div className="py-2"></div>
          <div className="text-center text-lg text-hs-blue">Your plan doesn't have this feature enabled.</div>
          <div className="py-2"></div>
          <div className="py-2 text-center text-base">A campaign is a series of social media posts that we produce for you depending on the topic of your blog article.</div>
          <div className="py-2"></div>
          <div className="py-2"></div>

          </Modal.Body>
          <Modal.Footer className="border-0 justify-start space-x-4">
          <div className="flex justify-end w-100">
            <Link to="/pricing"><Button
              size="large"
              
              
              className="px-3 brd-4 fw-700 mx-2 rounded-lg text-white bg-hs-pink  hover:border-hs-pink"
            >
              View Upgrade Options
            </Button></Link>
            <Button
              size="large"
              onClick={() => {
                callback(false);
              }}
              
              className="px-3 brd-4 fw-700 mx-2 rounded-lg hover:text-hs-pink hover:border-hs-pink"
            >
              Cancel
            </Button>
          </div>

          </Modal.Footer>
       </>:<><Modal.Header>Add new campaign</Modal.Header>
        {!created ?<Modal.Body>
          <div className="py-2"></div>
          {fetchedSuccess ? (
            <span>
              That appears to be a genuine URL. Are we sure we got the title
              right? If not, you may change it in the box below.
            </span>
          ) : (
            <span>
              Enter the URL of a URL (such as a blog article) here and click the
              'Start' button to establish a Drip Campaign.
            </span>
          )}
          <div className="py-2"></div>
          {fetchedSuccess ? (
            <div className="py-2 pr-4">
              <Input
                placeholder="Enter title here..."
                value={title}
                onChange={(e) => settitle(e.target.value)}
                bordered={false}
                className="bg-light rounded-md py-3"
              />
            </div>
          ) : (
            <div className="py-2 pr-4">
              <Input
                placeholder="Enter url here..."
                value={url}
                onChange={(e) => seturl(e.target.value)}
                bordered={false}
                className="bg-light rounded-md py-3"
              />
            </div>
          )}
          {fetchedSuccess && (
            <div className="py-2 px-2 flex flex-col">
             <span className="text-hs-dark py-2">Select Dripping type</span>   
             <span className="flex space-x-4"><span className="flex items-center" ><Checkbox checked={campigndripType === 0?true:false} onChange={(e)=>changeTypeDrip(e,0)} ><span>Auto Drip</span></Checkbox>  <Tooltip title=" If you'd like the content to be created automatically based on the content we find on this page, select the Auto Drip option.">
    <span><InfoCircle/></span>
  </Tooltip></span>
  <span className="flex items-center" > <Checkbox onChange={(e)=>changeTypeDrip(e,1)}  checked={campigndripType === 1?true:false} >Empty Drip</Checkbox><Tooltip title=" Select an Empty Drip Campaign if you want to drive traffic to this URL but want to create the Drip Campaign content yourself.">
    <span><InfoCircle/></span>
  </Tooltip></span>   
  </span>    </div>
          )}
          {fetchedSuccess && (
            <div className="py-2 px-2 flex flex-col">
             <span className="text-hs-dark py-2">Select Post type</span>   
             <span><Checkbox checked={campignType === 0?true:false} onChange={(e)=>changeType(e,0)} >Static Post</Checkbox> <Checkbox onChange={(e)=>changeType(e,1)}  checked={campignType === 1?true:false} >Video Post</Checkbox></span>   
            </div>
          )}
          {error && (
            <div className="py-2 px-2 text-danger text-base ">{error}</div>
          )}
        </Modal.Body>
        :<Modal.Body>
        <div className="py-2"></div>
        <div className="py-2 flex items-center space-y-2 flex-col"><Check2Circle color="green" size={24}/>
        <span>
              Campaign has been created successfully.
            </span>
        </div>
        <div className="py-2"></div>
        </Modal.Body>
        }
        <Modal.Footer className="border-0 justify-start space-x-4">
          { fetchedSuccess?<div className="flex justify-around w-100">
            <>{
              created?  <Link to={`/creator/${user["workspace"][0]["slug"]}/campaigns/${created}/review/`}><Button
              size="large"
              block
             
              className="px-3 brd-4 fw-700 mx-2 rounded-lg text-white bg-hs-pink  hover:border-hs-pink"
            >
              View Campaign
            </Button></Link>:  <Button
              size="large"
              block
              loading={addingUrl}
              onClick={createCampaign}
              className="px-3 brd-4 fw-700 mx-2 rounded-lg text-white bg-hs-pink  hover:border-hs-pink"
            >
              Create Campaign
            </Button>}
            </>
            <Button
              size="large"
              onClick={() => {
                callback(false);
              }}
              block
              className="px-3 brd-4 fw-700 mx-2 rounded-lg hover:text-hs-pink hover:border-hs-pink"
            >
              Cancel
            </Button>
          </div>:<div className="flex justify-around w-100">
            <Button
              size="large"
              block
              loading={addingUrl}
              onClick={fetchInfo}
              className="px-3 brd-4 fw-700 mx-2 rounded-lg text-white bg-hs-pink  hover:border-hs-pink"
            >
              Start
            </Button>
            <Button
              size="large"
              onClick={() => {
                callback(false);
              }}
              block
              className="px-3 brd-4 fw-700 mx-2 rounded-lg hover:text-hs-pink hover:border-hs-pink"
            >
              Cancel
            </Button>
          </div>}
        </Modal.Footer></> }
      </Modal>
    </div>
  );
};

export default AddNewCampaign;
