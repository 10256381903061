import { Spin } from "antd";
import FeedCard from "components/feed/FeedCard";
import ProfileBanner from "components/ProfileCard/ProfileBanner";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import ApiService from "services/ApiService";

const ProfileView = (props) => {
  const [loading, setloading] = useState(true);
  const [profile, setprofile] = useState(null);
  const [error, seterror] = useState(null);
  const [feedContent, setfeedContent] = useState([])
  useEffect(() => {
    getProfile();

    return () => {};
  }, []);

  const getProfile = async () => {
    seterror(null);
    const profileId = props["match"] && props["match"]["params"]["profile"]
      ? props["match"]["params"]["profile"]
      : "me";
    const res = await ApiService.PostApiCalls(
      `users/${profileId}`,
      { hi: "his" },
      true
    );
    
    if (res[0]) {
      if (res[1]["IsSuccessful"]) {
        setprofile(res[1]["profile"]);
        getPosts(res[1]["profile"]["uniqueId"])
      } else {
        seterror(res[1]["ErrorMessage"]);
        setloading(false);
      }
    } else {
      seterror("Something went wrong try again!");
      setloading(false);
    }
    
  };
  const getPosts = async(id) =>{
    const res = await ApiService.PostApiCalls(
      `users/${id}/posts`,
      { hi: "his" },
      true
    );
    
    if (res[0]) {
      
      if (res[1]["IsSuccessful"]) {
        setfeedContent(res[1]["list"])
        setloading(false);
      } else {
        
        setloading(false);
      }
    } else {
      
      setloading(false);
    }

  }
  
  if(error){
    return(<div className="px-4 h-100 d-flex justify-center p-4">
      <span className="font-weight-bold" >{error}</span>
    </div>)
  }
  return loading ? (
    <div className="px-4 h-100 d-flex justify-center align-items-center">
      <Spin />
    </div>
  ) : (
    <div className="px-0">
      <ProfileBanner info={profile} />
      {feedContent.map((feed, key) => (
        <FeedCard key={key} post={feed} id={key} />
      ))}
    </div>
  );
};

export default ProfileView;
