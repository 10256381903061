import React from "react";
import PropTypes from "prop-types";

import { Layout } from "antd";

import HeaderCreator from "components/HeaderCreator";
import SidebarCreator from "components/SidebarCreator";
import { useState } from "react";
import HeaderVideo from "components/HeaderVideo";
import { VideoProvider } from "app/VideoContext";
import SidebarRight from "components/SidebarRight";
const { Sider, Content } = Layout;
const CreatorLayout = ({ children, noNavbar, noFooter }) => {
  const [collapsed, setcollapsed] = useState(false)
  
  return (
    <>
      {
        children.props.isVideo?
        <VideoProvider>
        <Layout className="container w-full max-w-full min-h-screen bg-hg p-0">
          <HeaderVideo  noPadd={true}/>
          
          {children.props.hideSideBar ?<Layout className="video-header py-0 ">
            <Content className="py-0 px-0 bg-hg video-creator" >{children}</Content>
          </Layout>:<Layout className="mt-14 py-3 ">
            <Sider  onCollapse={(e)=>setcollapsed(e)} collapsed={collapsed} collapsible className="fixed bg-transparent hidden lg:block h-screen" width="250" >
          <SidebarCreator sidebarKey={children.props.sidebarKey} hideSideBar={children.props.hideSideBar } />
        </Sider> 
            <Content className="py-3 px-6 bg-hg" style={collapsed?{marginLeft:80}:{marginLeft:250}} >{children}</Content>
          </Layout>}
          {/* <Footer>Footer</Footer> */}
        </Layout>
        </VideoProvider>
        :
        children.props.isProfile?
        <Layout className="container w-full max-w-full min-h-screen bg-hg p-0">
          <HeaderCreator  noPadd={true}/>
          
          {children.props.hideSideBar ?<Layout className="video-header py-0 ">
            <Content className="py-0 px-0 bg-hg video-creator" >{children}</Content>
          </Layout>:<Layout className="mt-14 py-3 ">
            <Sider  onCollapse={(e)=>setcollapsed(e)} collapsed={collapsed} collapsible className="fixed bg-transparent hidden lg:block h-screen" width="250" >
          <SidebarCreator sidebarKey={children.props.sidebarKey} hideSideBar={children.props.hideSideBar } />
        </Sider> 
            <Content className="py-3 px-6 bg-hg" style={collapsed?{marginLeft:80}:{marginLeft:250}} >{children}</Content>
            <Sider className="hidden lg:block bg-transparent p-3" width="280" >
          <SidebarRight />
        </Sider>
          </Layout>}
          {/* <Footer>Footer</Footer> */}
        </Layout>
        :
        <Layout className="container w-full max-w-full min-h-screen bg-hg p-0">
          <HeaderCreator  noPadd={true}/>
          
          {children.props.hideSideBar ?<Layout className="video-header py-0 ">
            <Content className="py-0 px-0 bg-hg video-creator" >{children}</Content>
          </Layout>:<Layout className="mt-14 py-3  ">
            <Sider  onCollapse={(e)=>setcollapsed(e)} collapsed={collapsed} collapsible className="fixed bg-transparent hidden lg:block h-screen" width="250" >
          <SidebarCreator sidebarKey={children.props.sidebarKey} hideSideBar={children.props.hideSideBar } />
        </Sider> 
            <Content className="py-3 px-6 bg-hg" style={collapsed?{marginLeft:80}:{marginLeft:250}} >{children}</Content>
          </Layout>}
          {/* <Footer>Footer</Footer> */}
        </Layout>
      }
    </>
  );
};

CreatorLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool,
};

CreatorLayout.defaultProps = {
  noNavbar: false,
  noFooter: false,
};

export default CreatorLayout;
