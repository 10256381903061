import React from 'react'
import { Link } from 'react-router-dom'

const HashTag = ({tagText,color,link}) => {
  return (
    link?
    <a href={link} target='_blank' ><div className={`mr-2 ${color} cursor-pointer`}><span>{tagText[0] === "#" ?tagText:"#"+tagText}</span></div></a>
    :
    <Link to={`/search/posts?q=${tagText}`}><div className={`mr-2 ${color}`}><span>{tagText[0] === "#" ?tagText:"#"+tagText}</span></div></Link>
  )
}

export default HashTag