import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Avatar, Divider, Empty, List, Skeleton } from 'antd';
import { useContext } from 'react';
import { AuthContext } from 'app/AuthProvider';
import { Dot } from 'react-bootstrap-icons';
import Utils from 'helper/Utils';
import ApiService from 'services/ApiService';
import ProfileName from 'components/ProfileName';
import { Link } from 'react-router-dom';
const GroupMembers = ({groupinfo}) => {
    const {authUser} = useContext(AuthContext)
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [pagination, setpagination] = useState({
        offset:0,limit:20,total:0
    })
    const loadMoreData = async() => {
        if (loading) {
          return;
        }
        setLoading(true);
        const members = await ApiService.PostApiCalls("group/memebrs",{...pagination,id:groupinfo.uniqueId},true);
        
        if(members[0] && members[1]["IsSuccessful"]){
            setData( [...data,...ApiService.members[1]["members"]])
            setpagination({...pagination,offset:pagination.offset+10})
        }
        setLoading(false);
      };
      useEffect(() => {
        getMembers();
      }, []);
      const getMembers = async()=>{
        const members = await ApiService.PostApiCalls("group/memebrs",{...pagination,id:groupinfo.uniqueId},true);
        
        if(members[0] && members[1]["IsSuccessful"]){
            setData( members[1]["members"])
            setpagination({...pagination,offset:pagination.offset+10,total: members[1]["total"]})
        }
            // setLoading(true);

      }
  return (
    <div className="bg-white py-3 px-4 rounded-xl my-3">
        <InfiniteScroll
        dataLength={data.length}
        next={loadMoreData}
        hasMore={data.length < pagination.total}
        loader={
          <Skeleton
            avatar
            paragraph={{
              rows: 1,
            }}
            active
          />
        }
        endMessage={<Divider plain>It is all, no more members</Divider>}
        scrollableTarget="scrollableDiv"
      >
        <List
        header={<div className='flex flex-col'>
            <div className='flex flex-row items-center'><span className='text-bold font-semibold'>{Utils.numberFormatter(groupinfo.membersInfo[0]["active_count"],1) === "1"?"Member":"Members"}</span><Dot /> {Utils.numberFormatter(groupinfo.membersInfo[0]["active_count"],1)}</div>
                
                </div>}
          dataSource={data}
          locale={{emptyText:<div className=' text-hs-pink py-3'><Empty description="This group has zero members." /></div>}}
          renderItem={(item) => (
            <List.Item key={item.member.id}>
              <List.Item.Meta
                avatar={<Avatar src={item.memberinfo[0].profilePicture?item.memberinfo[0].profilePicture:"https://hs-container.s3.ap-south-1.amazonaws.com/hashapp/campaign-images/images/images/dqdmIww7t3_JCP.png"} />}
                title={<Link to={`/${item.memberinfo[0].userName}`}><ProfileName classnames={""} verfied={item.memberinfo[0]["verfied"]} profileName= {item.member.name} /></Link>}
                description={item.memberinfo[0].userName}
              />
              
            </List.Item>
          )}
        />
      </InfiniteScroll>
    </div>
  )
}

export default GroupMembers