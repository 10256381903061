function GetTextGrops(Lines,Position,actulaStart,y,TextCanvasHeight,StartFromX,canvas,x,ctx,lineSpacing,fontSizeTitle,pad,ReverseText,baseWidth,lng,FullBoxText=false,Header=false){
   
    var LeftOut = 0;
    var padding = pad;
    if(Header){
        padding = pad*3;
        
    }
    
    y = parseInt(y);
    if (Position === "bottom") {
        y = parseInt((canvas.height - TextCanvasHeight) - actulaStart);     
        
        y = y - (padding*Lines.length);
       
       
        
    } else if (Position === "middle") {
        y = parseInt((canvas.height - TextCanvasHeight) / 2);
    }
    if(FullBoxText){
        if (Position === "bottom") {
            y = (y - ((padding*4)+10));
           
        }
        else{
            y = (y - (padding*4))+actulaStart;
        }
        // x = x+x;
    }
   
    if(FullBoxText){
        padding = 0;
        if(lng.toLowerCase() !== "english"){
            padding = 1*1.2;
        }
    }
    
    
    lineSpacing = parseInt(lineSpacing);
    var data = [];
    var counter = 0;    
    Lines.forEach(function(line) {
        var LineCounter = Lines.length-counter;
        var linesingle = {};
        linesingle["text"] = line;
        linesingle["x"] = x+padding;   
        let DefaultX = x;   
        if(StartFromX === "center"){
            linesingle["x"] = ((canvas.width - parseInt(ctx.measureText(line).width))/2)+padding
            DefaultX = ((canvas.width - parseInt(ctx.measureText(line).width))/2);
        }
        var yPos = parseInt((y + (fontSizeTitle*counter)+(lineSpacing*counter))-(LeftOut));
        if(lng === "english"){
            linesingle["y"] = yPos+(padding*(counter+1));
        }
        else{
            linesingle["y"] = yPos+((padding*2)*(counter+1));
            // console.log(linesingle["y"]);
            
        }
       
        linesingle["h"] = fontSizeTitle;
        linesingle["BoxWidth"] = parseInt(ctx.measureText(line).width)+(padding*2);
        if(ReverseText){
            DefaultX = baseWidth-x;
            DefaultX = DefaultX - (parseInt(ctx.measureText(line).width)+(padding*2))
           
        }
        if (Position === "bottom") {
            linesingle["TextYStartPoint"] = yPos + (fontSizeTitle + (padding * 2) + 10);
            linesingle["TextXStartPoint"] = x - (fontSizeTitle + (padding * 2) + (10 * Lines.length));
        } else {
            linesingle["TextYStartPoint"] = yPos - (fontSizeTitle + (padding * 2) + 10);
            linesingle["TextXStartPoint"] = x - (fontSizeTitle + (padding * 2) + (10 * Lines.length));
        }
        linesingle["BoxHeight"] = fontSizeTitle+(padding*2);
        if(lng !== "english"){
            // linesingle["BoxHeight"] = fontSizeTitle+((padding+padding)*2);
        }
        linesingle["BoxX"] = DefaultX;
        linesingle["BoxY"] = yPos+parseInt((padding*counter));
        if(lng !== "english"){
            // linesingle["BoxHeight"] = fontSizeTitle+((padding+padding)*2);
            linesingle["BoxY"] = (yPos+parseInt((padding*counter)))-(padding*2);
        }
        linesingle["TextWidth"] = parseInt(ctx.measureText(line).width);
        linesingle["AnimationFactor"] = 0;
        linesingle["BlankVar"] = 0;
        counter++;
        data.push(linesingle)
    })
    return data;
}
function GetFullBox(TextCanvasHeight,lineSpacing,GetTextData,padding,TextYStart,header=false){
    padding = padding*.8;
    let BoxData = {}
    BoxData["height"] = TextCanvasHeight+(lineSpacing*(GetTextData.length-1))+(padding*5);
    BoxData["width"] = Math.max.apply(Math,GetTextData.map(function(ques){return ques.BoxWidth;}))+(padding*8);
    BoxData["x"] = (Math.min.apply(Math,GetTextData.map(function(ques){return ques.BoxX;}))-(padding*4));
    BoxData["y"] = Math.min.apply(Math,GetTextData.map(function(ques){return ques.BoxY;}))-(padding*2.5);
    if(header){
        BoxData["y"] = Math.min.apply(Math,GetTextData.map(function(ques){return ques.BoxY;}))-(padding*5);
    }
    BoxData["t"] = 0;
    BoxData["LineT"] = 0;
    return BoxData;
}
function GetSizes(Lines,fontSizeTitle,lineSpacing,padding,FullBoxText=false){
    var baseHeight = Lines.length * (fontSizeTitle + (0 * 2));
    var counter = Lines.length - 1;
    if(FullBoxText){
        counter = 0;
    }
    // console.log(counter);
    
    var Spacesizes = (parseInt(lineSpacing) * counter);
    return Spacesizes + baseHeight;
}
//GetLines
function fragmentText(text, maxWidth,padding,ctx) {
    
    if(text === undefined){
        text = ""
    }
    maxWidth = maxWidth - padding*2;
    var words = text.split(' '),
        lines = [],
        line = "";
    if (ctx.measureText(text).width < maxWidth) {
        return [text];
    }
    while (words.length > 0) {
        while (ctx.measureText(words[0]).width >= maxWidth) {
            var tmp = words[0];
            words[0] = tmp.slice(0, -1);
            if (words.length > 1) {
                words[1] = tmp.slice(-1) + words[1];
            } else {
                words.push(tmp.slice(-1));
            }
        }
        if (ctx.measureText(line + words[0]).width < maxWidth) {
            line += words.shift() + " ";
        } else {
            lines.push(line);
            line = "";
        }
        if (words.length === 0) {
            lines.push(line);
        }
    }
    return lines;
}
function getTextTextGropups(lines,HLIndexs){
    let mainarr = [];
    let totlength = 0;
    let cnt = 0;
    let last = 1;
    let lastindex = 0;
    let stpcnt = 0;
    // console.log(lines);
    lines.forEach(function(lineentry) {      
    
    lastindex = 0;
    stpcnt = 0;
  var hl = HLIndexs;
  let totsteparr = [];

if(hl){
  hl.forEach(function(entry) {
    
    
    let steparr = [];
    // entry[1] = entry[1] - 1;
    if (totlength <= entry[0] && totlength <= entry[1] && totlength + lineentry.length - 1 >= entry[0] && totlength + lineentry.length - 1 <= entry[1]) {
    
      if (entry[0] - totlength !== lastindex) {
        steparr["NonHighlighted"] = lineentry.substring(lastindex, entry[0] - totlength);
        if(steparr["NonHighlighted"].trim() !== ""){
            totsteparr[stpcnt.toString()] = steparr;
        }
        stpcnt += 1;
        steparr = [];
      }
      if(lines.length === 1){
        steparr["Highlighted"] = lineentry.substring(entry[0] - totlength, lineentry.length + 1);
        }
        else{
            steparr["Highlighted"] = lineentry.substring(entry[0] - totlength, lineentry.length - 1);
        }
      if(steparr["Highlighted"].trim() !== ""){
        totsteparr[stpcnt.toString()] = steparr;
      }
      last = 0;
      stpcnt += 1;
      steparr = [];
      if(lines.length === 1){
        lastindex = lineentry.length + 1;
    }
    else{
        lastindex = lineentry.length - 1;
    }
    } else if (totlength > entry[0] && totlength <= entry[1] && totlength + lineentry.length - 1 >= entry[0] && totlength + lineentry.length - 1 >= entry[1]) {
      
      steparr["Highlighted"] = lineentry.substring(lastindex, entry[1] - totlength + 0);
      if(steparr["Highlighted"].trim() !== ""){
        totsteparr[stpcnt.toString()] = steparr;
      }
      last = 0;
      stpcnt += 1;
      steparr = [];
      lastindex = entry[1] - totlength + 0;
    
    } else if (totlength <= entry[0] && totlength <= entry[1] && totlength + lineentry.length - 1 >= entry[0] && totlength + lineentry.length - 1 >= entry[1]) {
      
      if (entry[0] !== totlength) {
        steparr["NonHighlighted"] = lineentry.substring(lastindex, entry[0] - totlength);
        if(steparr["NonHighlighted"].trim() !== ""){
            totsteparr[stpcnt.toString()] = steparr;
        }
        stpcnt += 1;
        steparr = [];
      }
      steparr["Highlighted"] = lineentry.substring(entry[0] - totlength, entry[1] - totlength);
      if(steparr["Highlighted"].trim() !== ""){
        totsteparr[stpcnt.toString()] = steparr;
      }
      stpcnt += 1;
      lastindex = entry[1] - totlength;
      last = 1;
    }
    
  });
}
  let steparr = [];
  if (totlength + lineentry.length - 1 > lastindex) {
    steparr["NonHighlighted"] = lineentry.substring(lastindex, lineentry.length);
    if(steparr["NonHighlighted"].trim() !== ""){
    totsteparr[stpcnt.toString()] = steparr;
    }
  }
  mainarr[cnt.toString()] = totsteparr;
  cnt += 1;
  totlength += lineentry.length;
  });
  if (totlength + lines[lines.length - 1].length - 1 > lastindex) {

    let steparr = [];
    // let totsteparr = [];
  steparr["NonHighlighted"] = lines[lines.length - 1].substring(lastindex, lines[lines.length - 1].length);
  //   totsteparr[stpcnt.toString()] = steparr["NonHighlighted"];
    if(steparr["NonHighlighted"].trim() !== ""){
    mainarr[mainarr.length - 1][stpcnt.toString()] = steparr;
    }
  }

  return mainarr;
  }

//DrawImageInCanvas 
function DrawImage(ctx,image,x,y,w,h){
  
    ctx.drawImage(image, x,y,w,h);
}
function durationPerVisual(count,totalDuration){
    return totalDuration / count;
}
//FragementedText
function fillMixedTextLines(ctx, args, x, y,TextObjectReverse,baseWidth,HLColor,alpha){   
     
    
    ctx.save();  
    let xt = x;      
    if(TextObjectReverse){
        xt = baseWidth-x;         
    }
    
    let defaultFillStyle = ctx.fillStyle;    
    // let defaultFont = ctx.font;    
    args.forEach(({ Highlighted,NonHighlighted }) => {
        let colorbrk = HLColor.split(",");
        colorbrk = colorbrk[0]+","+colorbrk[1]+","+colorbrk[2]+","+alpha
        // ctx.fillStyle = "rgba( " + colorbrk + ")";
        ctx.fillStyle = Highlighted ? 'rgba('+colorbrk+')' :defaultFillStyle;
        let text = Highlighted ? Highlighted : NonHighlighted;
        
        
        ctx.fillText(text, xt, y);
        if(TextObjectReverse){
                     
            xt -= ctx.measureText(text).width;
        }
        else{
            xt += ctx.measureText(text).width;
        }
       
        // //////console.log(x);
        
    });
    ctx.restore();
  }
function getQueryString ( field, url ) {
var href = url ? url : window.location.href;
var reg = new RegExp( '[?&]' + field + '=([^&#]*)', 'i' );
var string = reg.exec(href);
return string ? string[1] : null;
};
function DrawImageLogo(ctx,LogoImage,LogoDetails,w,h){
    var x = LogoDetails["pos"][2],y =LogoDetails["pos"][3];
    if(LogoDetails["pos"][0] === "right"){
        x = w - (LogoImage.width+LogoDetails["pos"][2]);
        if(LogoDetails["pos"][1] === "bottom"){
            y = h - (LogoImage.height+LogoDetails["pos"][3]);
        }
    }
    else if(LogoDetails["pos"][0] === "left"){        
        if(LogoDetails["pos"][1] === "bottom"){
            y = h - (LogoImage.height+LogoDetails["pos"][3]);
        }
    }   
    ctx.drawImage(LogoImage, x, y,LogoImage.width,LogoImage.height);
}
function DrawRect(ctx, xPos, yPos,canImageWidth,canImageHeight){
    ctx.fillRect(xPos, yPos,canImageWidth,canImageHeight);
}
function DrawSourceText(ctx,text,x,y,pos,w,h,sourceFontSize){
    text = text.replace("Copyright","©");
    text = text.replace("Custom","©");
    var bw = ctx.measureText(text).width+(10)
    ctx.fillStyle = 'rgba(0,0,0,0.6)';
    if(pos === "TL" || pos === "TC" || pos === "TR"){
        y = h - (sourceFontSize+5);
        console.log(y);
        ctx.fillRect(5, y,bw,sourceFontSize+5);
        ctx.fillStyle = 'rgba(255,255,255,1)';
        ctx.fillText(text, 10, y+3);
    }
    else{
    y = h - (sourceFontSize+5);
    ctx.fillRect(5, y,bw,sourceFontSize+5);
    ctx.fillStyle = 'rgba(255,255,255,1)';
    ctx.fillText(text, 10, y+3);
}
}
const EasingFunctions = {
    linear: function (t) {
        return t
    },
    easeInQuad: function (t) {
        return t * t
    },
    easeOutQuad: function (t) {
        return t * (2 - t)
    },
    easeInOutQuad: function (t) {
        return t < .5 ? 2 * t * t : -1 + (4 - 2 * t) * t
    },
    easeInCubic: function (t) {
        return t * t * t
    },
    easeOutCubic: function (t) {
        return (--t) * t * t + 1
    },
    easeInOutCubic: function (t) {
        return t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
    },
    easeInQuart: function (t) {
        return t * t * t * t
    },
    easeOutQuart: function (t) {
        return 1 - (--t) * t * t * t
    },
    easeInOutQuart: function (t) {
        return t < .5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t
    },
    easeInQuint: function (t) {
        return t * t * t * t * t
    },
    easeOutQuint: function (t) {
        return 1 + (--t) * t * t * t * t
    },
    easeInOutQuint: function (t) {
        return t < .5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t
    }
}
export {GetFullBox,DrawRect,DrawImageLogo,DrawSourceText,GetTextGrops,durationPerVisual,GetSizes,fragmentText,getTextTextGropups,fillMixedTextLines,getQueryString,EasingFunctions,DrawImage}