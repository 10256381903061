import React from "react";
import PropTypes from "prop-types";
import Header from "components/Header";
import { Layout } from "antd";

const {  Content } = Layout;
const CreatorSetupLayout = ({ children, noNavbar, noFooter }) => {
  
  return (
    <>
      {
        <Layout className="container w-full max-w-full min-h-screen bg-hg p-3">
          <Header hideExtraNav={true}></Header>
          <Layout className="mt-14 py-3">
            {/* <Sider className=" bg-transparent hidden lg:block p-3" width="280" >
          <SidebarLeft />
        </Sider> */}
            <Content className="py-3 px-6 bg-hg">{children}</Content>
            {/* <Sider className="hidden lg:block bg-transparent p-3" width="280" >
          <SidebarRight />
        </Sider> */}
          </Layout>
          {/* <Footer>Footer</Footer> */}
        </Layout>
      }
    </>
  );
};

CreatorSetupLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool,
};

CreatorSetupLayout.defaultProps = {
  noNavbar: false,
  noFooter: false,
};

export default CreatorSetupLayout;
