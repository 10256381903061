import { Button, Col } from 'antd';
import { AuthContext } from 'app/AuthProvider';
import React, { useContext, useEffect } from 'react'
import { CheckCircle, CircleFill, NodeMinus, XCircle } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const AppPlanCard = ({plan,showaction,activePlan,activeCallback,openWaitingForm,billingCycle="yearly"}) => {
  const { t } = useTranslation();
    
    useEffect(() => {
      
    
      return () => {
        
      }
    }, [null])
    
    // console.log(selectedPlan);
    const activeOne = [1,2,3,4,5]
    
  return (
    <Col className="p-2 " xs={24} sm={24} md={4} lg={4} xl={4}>
      <div className="p-3 shadow-md rounded-lg flex flex-col bg-white h-full">
        <div name className="text-b text-center">{t(plan.name)}</div>
        <div price className="text-center font-semibold text-2xl text-hr py-3">${plan.price[billingCycle]}</div>
        <div className="text-center text-sm opacity-40 pb-3">{billingCycle === "yearly"?t("per year"):t("per month")}</div>
        {!activePlan && activeOne.includes(plan["id"]) && <Link to={`/creator/edit/start?plan=${plan["code"]}-${billingCycle}-${plan["id"]}`} className="w-100" ><div className="text-center "><Button  className='bg-hs-pink rounded-md text-white'>{plan["trial"]["count"] === 0 ?t("Start Now"):t("Start Now1")}</Button></div></Link>}
        {!activePlan && !activeOne.includes(plan["id"]) && <div onClick={()=>openWaitingForm(plan["id"])} className="w-100" ><div className="text-center "><Button  className='bg-hs-pink rounded-md text-white'>{plan["trial"]["count"] === 0 ?t("Start Now"):t("Start Now1")}</Button></div></div>}
        {activePlan && <>
        {
            activePlan["id"] === plan["id"]?
            <><div className="text-center "><Button  className='bg-hs-blue rounded-md text-white'>Active Plan</Button></div></>
            :
            <div className="text-center "><Button onClick={()=>activeCallback(plan)}  className='bg-hs-pink rounded-md text-white'>Upgrade</Button></div>
        }
        </>}
        <span className="py-2 fz-12 w-100 space-hr"></span>
        
        <div className="px-2 flex flex-col text-h">
        {/* <span className="flex flex-row items-center mb-2">
            <CircleFill className="opacity-20" size={8} /> 
        <span className="ml-2 font-normal text-xs">Trial Period of 7 days</span>
        </span> */}
        <span className="flex flex-row items-center mb-2">
            <CircleFill className="opacity-20" size={8} /> 
        <span className="ml-2 font-normal text-xs">{`${plan["features"]["workspace"]["value"]} ${t(plan["features"]["workspace"]["label"])}`}</span>
        </span>
        <span className="flex flex-row items-center mb-2">
            <CircleFill className="opacity-20" size={8} /> 
        <span className="ml-2 font-normal text-xs">{`${plan["features"]["users"]["value"]} ${t(plan["features"]["users"]["label"])}`}</span>
        </span>
        <span className="flex flex-row items-center mb-2">
            <CircleFill className="opacity-20" size={8} /> 
        <span className="ml-2 font-normal text-xs">{`${plan["features"]["posts"]["value"]} ${t("posts a month")}`}</span>
        </span>
        <span className="flex flex-row items-center mb-2">
            <CircleFill className="opacity-20" size={8} /> 
        <span className="ml-2 font-normal text-xs">{t("Video included")}
        </span> 
        </span><span className="flex flex-row items-center mb-2">
            <CircleFill className="opacity-20" size={8} /> 
        <span className="ml-2 font-normal text-xs">{`${plan["features"]["social-profiles"]["value"]} ${t(plan["features"]["social-profiles"]["label"])}`}</span> 
        </span>
        <span className="flex flex-row items-center mb-2">
            <CircleFill className="opacity-20" size={8} /> 
        <span className="ml-2 font-normal text-xs">{`${plan["features"]["minted-keywords"]["value"]} ${t(plan["features"]["minted-keywords"]["label"])}`}</span> 
        </span>
        {
          !showaction && <>
          <span className="flex flex-row items-center mb-2">
            <CircleFill className="opacity-20" size={8} />
            <span className="ml-2 font-normal text-xs capitalize">
                {plan["features"]["video-templates"]["value"] === "0"?<XCircle size={16} color="red" />:<CheckCircle size={16} color="green" />} 
                </span>
        <span className="ml-2 font-normal text-xs">{`${t(plan["features"]["video-templates"]["label"])}`}</span> 
        </span>
        <span className="flex flex-row items-center mb-2">
            <CircleFill className="opacity-20" size={8} /> 
        <span className="ml-2 font-normal text-xs capitalize">{plan["features"]["groups"]["extra"]?t("Grps1"):t("Grps2")}</span> 
        </span>
        <span className="flex flex-row items-center mb-2">
            <CircleFill className="opacity-20" size={8} /> 
        <span className="ml-2 font-normal text-xs capitalize">{plan["features"]["social-commerce"]["extra"]?t("Social commerce integrationE"):`${t(plan["features"]["social-commerce"]["label"])}`}</span> 
        </span>
        <span className="flex flex-row items-center mb-2">
            <CircleFill className="opacity-20" size={8} /> 
        <span className="ml-2 font-normal text-xs capitalize">{plan["features"]["analytics"]["extra"]? `${t(plan["features"]["analytics"]["label"])} - ${t(plan["features"]["analytics"]["extra"])}`:`${t(plan["features"]["analytics"]["label"])}`}</span> 
        </span>
        <span className="flex flex-row items-center mb-2">
            <CircleFill className="opacity-20" size={8} /> 
        <span className="ml-2 font-normal text-xs capitalize">{plan["features"]["language-translation"]["extra"]? `${t(plan["features"]["language-translation"]["label"])} - ${t(plan["features"]["language-translation"]["extra"])}`:`${t(plan["features"]["language-translation"]["label"])}`}</span> 
        </span>
        <span className="flex flex-row items-center mb-2">
            <CircleFill className="opacity-20" size={8} /> 
        <span className="ml-2 font-normal text-xs">{t("csource")}</span> 
        </span>
        <span className="flex flex-row items-center mb-2">
            <CircleFill className="opacity-20" size={8} />
            <span className="ml-2 font-normal text-xs capitalize">
                {plan["features"]["nft-analytics"]["value"] === "no"?<XCircle size={16} color="red" />:<CheckCircle size={16} color="green" />} 
                </span>
        <span className="ml-2 font-normal text-xs capitalize">{`${t(plan["features"]["stock-images"]["label"])}`}</span> 
        </span>
        <span className="flex flex-row items-center mb-2">
            <CircleFill className="opacity-20" size={8} /> 
            <span className="ml-2 font-normal text-xs capitalize">
                {plan["features"]["nft-analytics"]["value"] === "no"?<XCircle size={16} color="red" />:<CheckCircle size={16} color="green" />} 
                </span>
        <span className="ml-2 font-normal text-xs capitalize">{`${t("YouTubeSpotify Content")}`}</span> 
        </span>
        <span className="flex flex-row items-center mb-2">
            <CircleFill className="opacity-20" size={8} />
            <span className="ml-2 font-normal text-xs capitalize">
                {plan["features"]["nft-analytics"]["value"] === "no"?<XCircle size={16} color="red" />:<CheckCircle size={16} color="green" />} 
                </span>
        <span className="ml-2 font-normal text-xs capitalize">
            {`${t(plan["features"]["nft-analytics"]["label"])}`}</span> 
        </span>
          </>
        }

        {showaction && <span className='pt-2 align-self-baseline'>
            <Link to ={"/pricing"}><Button className='border-0'>{t("viewall")}</Button></Link>
        </span>}
        </div>
        <div className="p-2"></div>
      </div>
  </Col>
  )
}

export default AppPlanCard