
import React from 'react'
import { useEffect,useContext } from 'react';
import { useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Avatar, Divider, Empty, List, Skeleton } from 'antd';
import { AuthContext } from 'app/AuthProvider';
import AddGroupPost from './AddGroupPost';
import ApiService from 'services/ApiService';
import FeedCard from 'components/feed/FeedCard';
const GroupFeed = ({groupinfo}) => {
  const {authUser} = useContext(AuthContext)
  const [profile, setprofile] = useState(groupinfo)
  const [addPost, setaddPost] = useState(false)
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [feedContent, setfeedContent] = useState([])
useEffect(() => {
  getPosts();

  return () => {
    
  }
}, [])
const getPosts = async(id) =>{
  const res = await ApiService.PostApiCalls(
    `users/${groupinfo.uniqueId}/posts`,
    { hi: "his" },
    true
  );
  
  if (res[0]) {
    
    if (res[1]["IsSuccessful"]) {
      setData(res[1]["list"])
      setLoading(false);
    } else {
      
      setLoading(false);
    }
  } else {
    
    setLoading(false);
  }

}
    const loadMoreData = () => {
        if (loading) {
          return;
        }
        setLoading(true);
        fetch('https://randomuser.me/api/?results=10&inc=name,gender,email,nat,picture&noinfo')
          .then((res) => res.json())
          .then((body) => {
            setData([...data, ...body.results]);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      };
      useEffect(() => {
        
      }, []);
  return (
    <>
    {authUser.uniqueId === profile.owner.id && <div className="bg-white py-3 px-4 rounded-xl my-3">
      <div onClick={()=>setaddPost(true)} className='bg-gray-100 py-3 px-3 rounded-xl cursor-pointer hover:bg-gray-200 text-base text-gray-500 ' >Write something...</div>
      </div>}
    <div className="bg-white py-3 px-4 rounded-xl my-3">
        <InfiniteScroll
        dataLength={data.length}
        next={loadMoreData}
        hasMore={data.length < 0}
        loader={
          <Skeleton
            avatar
            paragraph={{
              rows: 1,
            }}
            active
          />
        }
        // endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
        scrollableTarget="scrollableDiv"
      >
        <List
        // header={<div>Header</div>}
        locale={{emptyText:<div className=' text-hs-pink py-3'><Empty description="This group has not posted anything." /></div>}}
          dataSource={data}
          renderItem={(item,key) => (
            <FeedCard key={key} post={item} id={key} />
          )}
        />
      </InfiniteScroll>
    </div>
    {
      addPost && <AddGroupPost groupinfo={profile} profiles={[]} user={authUser} showModalFirst={true} callback={()=>setaddPost(false)}/>
    }
    </>
  )
}

export default GroupFeed