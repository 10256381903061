import { Col, Row,Spin,List, Tabs,Input,Menu, Dropdown, Button } from "antd";
import React, { useContext, useState, useEffect, useRef } from "react";
import { VideoContext } from "app/VideoContext";
import ApiService from "services/ApiService";
import MusicLibrary from "./MusicLibrary";
import MusicUpload from "./MusicUpload";
const { TabPane } = Tabs;

const StoryMusic = () => {
  const {
    showPreview,
    setactiveLefttab,
    activeLefttab,
    videoContent,
    setvideoContent,updateContent,
  } = useContext(VideoContext);

  
  const [activeTabe, setactiveTabe] = useState("1");
  
  useEffect(() => {
    
    return () => {};
  }, [activeTabe]);
  
  
  const callback = (key) => {
   
    setactiveTabe(key);
  };
  
  return (
    <Row className="h-full w-100">
      <Col className="flex flex-col h-full story-article" lg={24}>
        <div className=" w-100">
          <Tabs
            defaultActiveKey="1"
            className="h-full w-full"
            centered
            onChange={callback}
          >
            <TabPane className="w-full" tab={"Library"} key="1"></TabPane>
            <TabPane className="w-full" tab={"Uploads"} key="2"></TabPane>
          </Tabs>
        </div>
        {activeTabe === "1" && (
         <div className="story-editor h-full flex flex-col">
           <MusicLibrary />
          </div>
        )}
        {activeTabe === "2" && (
           <div className="story-editor h-full flex flex-col">
            <MusicUpload />
          </div>
        )}
        {/* <div>3</div> */}
      </Col>
    </Row>
  );
};

export default StoryMusic;