import { Select } from "antd";
import React from "react";
const { Option } = Select;
const HeaderDropdownSelection = ({
  options = [],
  callback,
  defaultSelected,
}) => {
  return (
    <Select
      defaultValue={defaultSelected}
      style={{ width: 240 }}
      onChange={callback}
      dropdownClassName="rounded-md"
    >
      {options.map((e, i) => (
        <Option key={i} value={e}>
          {e}
        </Option>
      ))}
    </Select>
  );
};

export default HeaderDropdownSelection;
