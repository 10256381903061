import { Row, Col, Typography, List, Button, Spin,notification,Modal,Input } from "antd";

import React, { useEffect, useState } from "react";

import _ from "lodash";
import ApiService from "services/ApiService";
import moment from "moment";
import { Calendar2Check } from "react-bootstrap-icons";
const { TextArea } = Input;
const { confirm } = Modal;
const { Paragraph } = Typography;
const SettingsContentTemplates =  ({ user, activeWorkSpace }) => {
  const [viewloading, setViewLoading] = useState(true);
  const [userTemplates,setUserTemplate] = useState([])
  const [loading, setLoading] = useState(false);
  const [addCardVisible,setAddCardVisible] = useState(false);
  const [btnOff,setBtnOff] = useState(true);
  const [templateText,settemplateText] = useState("");
  const [forEdit, setforEdit] = useState(null)
  const [isEdit, setisEdit] = useState(false)
  const [editId, seteditId] = useState(0)
  useEffect(() => {
    
    getDetails()
    return () => {
      
    }
  }, [])
  const handleCancel = ()=>{
    setAddCardVisible(false)
  }
  const handleOk = ()=>{
    setAddCardVisible(false)
  }
  const handleOnChange = (e)=>{
    settemplateText(e.target.value);
    setLoading(false);
    setBtnOff(false);
    if((e.target.value) === ""){
      setBtnOff(true)
        // setloading(loading);
    }
  }
  const addText = (e)=>{
    settemplateText(templateText+""+e);
    setLoading(false);
    setBtnOff(false);
    
  }
  const getDetails = async()=>{
    const templates = await ApiService.GetApiCalls(`/creator/contenttemplates/${activeWorkSpace["wid"]}`,"",true)
    setUserTemplate(templates[1]["templates"])
    setViewLoading(false)
  }
  const handleAction =(action,item)=>{
    if(action === "edit"){
      settemplateText(item.template)
      setforEdit(item)
      setBtnOff(false);
    }
    else if(action === "new"){
      settemplateText("")
      setforEdit(item)
      setBtnOff(true);
    }
    else{
      
    }
    

    setAddCardVisible(true)
  }
  if(viewloading){
    return (
      <>
      <Col span={24} className="py-0 px-0 mb-0">
        <div className="bg-white p-3 px-3 flex space-x-3 items-center justify-between">
          <span className="font-bold">Content Templates</span>
          <Button
            onClick={(e) => handleAction("new",null)}
            type="primary"
            className=" rounded  float-right mr-3"
            loading={loading}
          >
           Add Template
          </Button>
        </div>
      </Col>
      <div className="pb-2 bg-gray-100"></div>
      <Col span={24} className="py-0 px-0 mb-0 h-screen flex items-center justify-center">
      <Spin />
      </Col>
      </>
    )
  }
  return (
    <> <Col span={24} className="py-0 px-0 mb-0">
    <div className="bg-white p-3 px-3 flex space-x-3 items-center justify-between">
      <span className="font-bold">Content Templates</span>
      <Button
         onClick={(e) => handleAction("new",null)}
        type="primary"
        className=" rounded  float-right mr-3"
        loading={loading}
      >
         Add Template
      </Button>
    </div>
  </Col>
  <div className="pb-2 bg-gray-100"></div>
  <Col span={24} className="">
  <Row className="p-2">
  <Col span={24} className="p-2">
            <Paragraph className="text-sm text-hs-dark">
            The content templates listed below will be utilised in your campaigns. You may change the language to your own, add your own writing style, or just delete templates you don't wish to use.
            </Paragraph>
          </Col>
          <Col className="px-0 pr-4 mt-4 " span={24}>
            <Row>
            <Col className="px-0 fz-12 fw-700 pr-4 mt-2 content-templates"
             span={24} >
             <List
    itemLayout="vertical"
    bordered
    dataSource={userTemplates}
    renderItem={item => (
      <List.Item
      key={item.id}
      actions={[
        <div className="flex w-100 justify-between"> <span className="flex items-center"><Calendar2Check className="mr-3"/>Updated on {moment.unix(item["updated"]).format("DD, MMM YYYY")}</span>
          <div className="flex space-x-3"><Button key="submit" className="rounded-md border-hs-pink"   onClick={(e) => handleAction("edit",item)}>
            Edit
          </Button>,
            <Button key="submit" className="rounded-md border-hs-pink"   onClick={(e) => handleAction("delete",item)}>
            Delete
          </Button>
          </div></div>
      ]}
      ><List.Item.Meta          
          description={<div className="usertemplates-text" dangerouslySetInnerHTML={{__html: '<p>'+item.template+'</p>'}} />}
        />
      </List.Item>
    )}
  /> </Col>
            </Row>
          </Col>
  </Row>
  </Col>
  <Modal className="campaign-new-modal" title="Add Content Template" visible={addCardVisible}
               closable={false}            
              footer={[
            //     <Button key="help" className="brd-4 bluelink-btn" >
            //   Need Help
            // </Button>,   
            <Button key="back" className="brd-4 blueline-btn" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button key="submit" className="brd-4 bluebg-btn" disabled={btnOff} type="primary" loading={loading} onClick={handleOk}>
              Save
            </Button>,
          ]}
              >
              <Col className="px-0 fz-12 text-sm"
             lg={24}  >At the very least, the title of the blog article or a quote from it should be included in the template. Press the relevant button beneath the text box to add one or both of these to the template.</Col>
                <Col className="px-0 fz-12  mt-2 text-sm "
             lg={24}  >
             You’re creating a content template that can be used on <span className="text-hs-blue fw-600">ALL DAY SLOTS.</span>
             </Col>
             <Col className="px-0 fz-12 pr-4 mt-2 fw-600"
             lg={24}      
             md={24}
              >Write your template here</Col>
             <Col className="px-0 fz-12 pr-4 mt-2 "
             lg={24} >
             <div className="relative">
             
             <TextArea className="pb-10 bg-hs-lighter text-hs-dark rounded-md focus:border-hs-pink hover:border-hs-pink" value={templateText} onChange={(e) =>handleOnChange(e)} placeholder="Write here" autoSize={{ minRows: 5 }} />
             <div className="absolute bottom-2 left-3 flex space-x-3">
               <Button className="rounded-md hover:border-hs-pink hover:text-hs-pink" onClick={()=>addText("{{quote}}")} >+ Quote</Button>
               <Button className="rounded-md hover:border-hs-pink hover:text-hs-pink" onClick={()=>addText("{{title}}")} >+ Title</Button>
               <Button className="rounded-md hover:border-hs-pink hover:text-hs-pink" onClick={()=>addText("{{url}}")} >+ URL</Button>
               <Button className="rounded-md hover:border-hs-pink hover:text-hs-pink" onClick={()=>addText("{{author_twitter}}")} >+ Author Twitter</Button>
               
               
               </div>
             </div></Col>
             <Col className="px-0 fz-12 pr-4 mt-2 "
             lg={12}      
             md={12}
             sm={12} ></Col>
              </Modal>
  </>
  )
}

export default SettingsContentTemplates