
import { Row, Col,Button, Result, Spin } from "antd";
import SettingsLeftList from "container/creator/profilesettings/SettingsLeftList";
import { Container, Modal } from "react-bootstrap";

import Utils from "helper/Utils";
import React from "react";
import { useState, useEffect } from "react";
import { ArrowBarLeft, ArrowLeft } from "react-bootstrap-icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import ApiService from "services/ApiService";
import { useContext } from "react";
import { AuthContext } from "app/AuthProvider";

import _ from "lodash";

import UserAccountList from "./UserAccountList";
import AccountProfile from "./UserAccounts/AccountProfile";
import AccountRewards from "./UserAccounts/AccountRewards";
import AccountSecurity from "./UserAccounts/AccountSecurity";
import AccountSettings from "./UserAccounts/AccountSettings";
import AccountActivities from "./UserAccounts/AccountActivities";
import AccountWallet from "./UserAccounts/AccountWallet";
import BuyHashCard from "container/common/BuyHashCard";
import { ToastProvider } from "react-toast-notifications";
import DatingProfile from "container/creator/profilesettings/DatingProfile";



const UserAccount = (props) => {
  const navigate = useNavigate()
  const brParams = useParams();
  const user = Utils.getLocalInfo("userhash");
  const {
    authUser
  } = useContext(AuthContext);
  const [loading, setloading] = useState(true);
  const [error, seterror] = useState(null);

  const [groups, setgroups] = useState([]);
  const [baseQuery, setbaseQuery] = useState({});
  const [showHash, setshowHash] = useState(false)
  const [queryParams, setqueryParams] = useState({
    limit: 20,
    offset: 0,
    sort: "desc",
  });
  const data = [
    {slug: 'profile',lable:"Profile"},
    {slug: 'dating-profile',lable:"Dating Profile"},

  {slug: 'wallet',lable:"Wallet"},
  {slug: 'rewards',lable:"Rewards"},
  {slug: 'security',lable:"Security"},
  {slug: 'settings',lable:"Settings"},
  {slug: 'activities',lable:"Activities"},
    
  ];
  const [current, setcurrent] = useState([{slug: 'profile',lable:"Profile"}])
  useEffect(() => {
    const params = brParams;
    const c = _.filter(data,function(d,i){
      return d.slug === brParams.action;

    })
    setcurrent(c[0]);
    setloading(false);
   
    return () => {};
  }, [brParams]);
const updateBuyBack = () =>{
  window.location.reload();
}
  const getPosts = async (filter) => {
    const queryString = new URLSearchParams(queryParams).toString();
    const res = await ApiService.PostApiCalls(
      `posts?${queryString}`,
      { ...filter },
      true
    );

    if (res[0]) {
      if (res[1]["IsSuccessful"]) {
        setgroups(res[1]["list"]);
        setloading(false);
        window.scrollTo(0, 0);
      } else {
        setloading(false);
      }
    } else {
      setloading(false);
    }
  };
  if (
    user["role"] === "creator" &&
    parseInt(user["creatorProfileStep"]) < 6
  ) {
    return navigate("/creator/edit/1");
  }

  return loading ? (
    <div className=" h-100 d-flex justify-center align-items-center bg-white py-3 px-4 rounded-xl ">
      <Spin />
    </div>
  ) : (
    <>
    <ToastProvider>
      <Container className=" bg-white flex-1 h-100  px-2 py-0 rounded-xl overflow-hidden" fluid>
    <Row gutter={16} className="h-100">
      <Col span={8} className="mb-0 px-0 bg-white   ">
      <Col span={24} className="py-0 px-0 mb-0 border-b-2">
          <div className="bg-white p-3 px-3 flex space-x-3 items-center">
            <span className="font-bold uppercase">Account</span>
          </div>
        </Col>
        <Col span={24} className="px-0  bg-white"><UserAccountList user={authUser} active={brParams.action} /></Col>
        
      </Col>
      <Col span={16} className="mb-0 px-0 bg-whitw border-l-2">
      <Col span={24} className="py-0 px-0 mb-2 border-b-2">
      <div className="bg-white p-3 px-3 flex space-x-3 items-center">
        {brParams.type && <Link className="text-hs-dark" to={"../profile"}> <ArrowLeft size={22} /></Link>}
        <span className="font-bold uppercase opacity-30">{current["lable"]}{brParams.type?` - ${brParams.type}`:``}</span>
        {current["lable"] === "Wallet" && <div className="flex-grow justify-end flex px-4 space-x-3"><Button type="primary" className="rounded-md" onClick={()=>setshowHash(true)} >Buy Hashs</Button><Button type="primary" className="rounded-md" >Withdraw</Button></div>}
      </div>
     
    </Col>
      {brParams.action === "profile" && <AccountProfile user={authUser} params={brParams.type} />}
      {brParams.action === "dating-profile" && <DatingProfile user={authUser} />}
      {brParams.action === "wallet" && <AccountWallet user={authUser} />}
      {brParams.action === "rewards" && <AccountRewards user={authUser} />}
      {brParams.action === "rewards" && <AccountRewards user={authUser} />}
      {brParams.action === "activities" && <AccountActivities user={authUser} />}
      {brParams.action === "settings" && <AccountSettings user={authUser} />}
      
      </Col>
    </Row>
    </Container></ToastProvider>
    <Modal show={showHash}
          onHide={() => {
            setshowHash(false);
            // activateHashes(false);
          }}
          centered size="lg"
          contentClassName="campaign-new-modal rounded-2xl p-0"
          backdrop={"static"}>
            <Modal.Header className="mp-2 p-2 border-bottom ">
            <div className="flex flex-row w-full justify-between space-x-4 p-2 font-semibold items-center">
              <span>Buy Hashs</span>
              <span className>
                {" "}
                Current Balance -{" "}
                <span className="text-hs-pink"> {authUser["hashTokens"]}</span>
              </span>
            </div>
          </Modal.Header>
          <Modal.Body className={`ct-image overflow-scroll p-3 max-h-96`}>
          <BuyHashCard backCallback={()=>{setshowHash(false)}} backTitle={"Cancel"}  defaultValue={authUser["hashTokens"]} tokens={authUser["hashTokens"]} changeText={true} successCallback={updateBuyBack} />
          </Modal.Body>

    </Modal>
    </>
  );
};

export default UserAccount;
