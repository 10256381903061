
import { Card } from "antd";
import React from "react";

const { Meta } = Card;

const NewsTrendingCard = ({ post }) => {
  return (
    <div className="flex flex-row border-b p-2 space-x-3 ">
      <div
        className="bg-center w-1/3 bg-cover rounded-md flex-1"
        style={{ backgroundImage: `url("${post.image}")` }}
      ></div>
      <div className="flex w-75  flex-col py-2">
       <a href={post.link} target={"_blank"}><span className="font-semibold text-base">{post.title}</span></a> 
        <span className="text-sm">{post.description}</span>
      </div>
    </div>
  );
};

export default NewsTrendingCard;
