import { Dropdown, Menu } from 'antd';
import Utils from 'helper/Utils';
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Navbar,Nav, Button } from 'react-bootstrap';
import hashlogo from "../assets/images/hash-logo.png";
import { Search, Bell, ChevronCompactDown,Translate } from "react-bootstrap-icons";
import Notifications from './Notifications';

const ReHeader = ({noPadd,hideExtraNav,user}) => {
    const navigate = useNavigate()
    const { t } = useTranslation();

    const changeLocale = (locale) =>{
        localStorage.setItem('appLocale',locale);
    
        window.location.reload();
    }
    const logout = ()=>{
        Utils.processAuthOut()
        navigate("/")
      }
      const usermenu =  (
        <Menu className="mt-0">
             <Menu.Item className="p-2 pr-4" onClick={()=>changeLocale("en")} >
          <span className="ml-3" >English</span>  
          </Menu.Item>
          <Menu.Item className="p-2 pr-4" onClick={()=>changeLocale("ja")} >
          <span className="ml-3" >Japanese</span>  
          </Menu.Item>
           <Menu.Item className="p-2 pr-4" onClick={()=>changeLocale("de")} >
          <span className="ml-3" >Deutsch</span>  
          </Menu.Item> <Menu.Item className="p-2 pr-4" onClick={()=>changeLocale("fr")} >
          <span className="ml-3" >Français</span>  
          </Menu.Item> 
           <Menu.Item className="p-2 pr-4" onClick={()=>changeLocale("hi")} >
          <span className="ml-3" >हिन्दी</span>  
          </Menu.Item>
        </Menu>
      );
      const profileMenu = (
        <Menu className="rounded-l">
          <Link to={"re/account-settings"}><Menu.Item key="1">Account Settings</Menu.Item></Link>
          
          <Menu.Item onClick={logout} key="3">Logout</Menu.Item>
        </Menu>
      );
      console.log(user,"user");
  return (
    <>
     <div className={noPadd?"fixed w-full top-0 left-0  z-40 bg-hg shadow-sm":"fixed w-full top-0 left-0 p-3 z-40 bg-hg"}>
     <Navbar
        id="headerfixed"
        expand="lg"
        className={noPadd?"header-bar auth  w-full flex justify-around bg-white":"header-bar auth  w-full flex justify-around bg-white rounded-xl"}
      >
        <Navbar.Brand href={"/"}>
        <Link to={"/"}><span className="text-h text-2xl font-semibold flex items-center space-x-2">
           {/* <BluLogo /> */}
           <img src={hashlogo} className="w-8" />
            <span className="text-h">HashSaga</span>
          </span></Link> 
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="justify-content-between"
        >
           <Navbar className="text-center flex justify-center flex-1 justify-content-center">
            
          </Navbar>
          {user && user[0] && <Nav className="mr-right">
            
            <Nav.Item className="px-4 cursor-pointer">
              <Notifications user={user} />
            </Nav.Item>
            <Nav.Item className="pr-4 visible">
              <Dropdown
                overlay={profileMenu}
                placement="bottomRight"
                trigger="click"
                className="cursor-pointer"
              >
                <div className="flex items-center space-x-2">
                  <span className="head-notificationsBox flex items-center justify-center text-white text-xl bg-blue-500">
                    {user.picture ? 
                    <img alt={user.fullname} src={user.picture } />
                      :user.fullname[0]
                  }
                  </span>
                  <span className="text-h font-semibold ">{user.fullname}</span>
                  <span className="text-h">
                    <ChevronCompactDown />
                  </span>
                </div>
              </Dropdown>
            </Nav.Item>
            <Nav.Item><Dropdown overlay={usermenu} placement="bottomRight" trigger={['click']}>
        {/* <User color="#ffffff" /> */}
        <div className='flex bg-light rounded-full items-center justify-center w-8 h-8' ><Translate /></div>
        </Dropdown> </Nav.Item>
          </Nav>}
        </Navbar.Collapse>
        
      </Navbar>
    </div>
    </>
  )
}

export default ReHeader