import { Menu } from "antd";
import Utils from "helper/Utils";
import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "app/AuthProvider";
import {
  BookmarkHeart,
  CalendarWeek,
  CardChecklist,
  ChatRightText,
  Gear,
  GraphUp,
  Hash,
  Heart,
  House,
  ListStars,
  People,
  Share,
  Sliders,
} from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import WorkspacesDropdown from "./WorkspacesDropdown";
import { LiaUserTagSolid } from "react-icons/lia";
import { VscVmConnect } from "react-icons/vsc";
const SidebarCreator = (props) => {
  const { t } = useTranslation();
  
  const { activeWorkSpace, authUser} = useContext(AuthContext);
  
  const [activeSlug, setactiveSlug] = useState(activeWorkSpace["slug"])
  useEffect(() => {
    setactiveSlug(activeWorkSpace["slug"])
    return () => {};
  }, [activeWorkSpace]);
  return (
    <div className="">
      <div section-1 className="h-screen bg-white p-2">
        <div className="mb-2">
          <WorkspacesDropdown authUser={authUser} activeWorkSpace={activeWorkSpace} hide={props.collapsed} /></div>
        <Menu className="border-0 active " selectedKeys={props.sidebarKey}>
          <Menu.Item
            key={"0"}
            className="text-hl flex flex-row items-center border-l-2 "
            icon={<House size={18} />}
          >
            <Link to={"/creator/home/"+activeSlug} className="text-hl">
              {t("Dashboard")}
            </Link>
          </Menu.Item>

          <Menu.Item
            key={"1"}
            className="text-hl flex flex-row items-center border-l-2 "
            icon={<BookmarkHeart size={18} />}
          >
            <Link to={`/creator/${activeSlug}/campaigns`} className="text-hl">
              {" "}
              {t("Campaigns")}
              
            </Link>
          </Menu.Item>
          {/* {authUser.isAdmin && <Menu.Item
            key={"5"}
            className="text-hl flex flex-row items-center border-l-2 "
            icon={<Hash size={18} />}
          ><Link to={`/creator/hashmine`} className="text-hl">
              {t("Hash Mine")}
            </Link>
          </Menu.Item>} */}
         
          <Menu.Item
            key={"5"}
            className="text-hl flex flex-row items-center border-l-2 "
            icon={<Hash size={18} />}
          ><Link to={`/creator/hashmine`} className="text-hl">
              {t("Hash Mine")}
            </Link>
          </Menu.Item>
          <Menu.Item
            key={"2"}
            className="text-hl flex flex-row items-center border-l-2 "
            icon={<GraphUp size={18} />}
          >
            <Link to={`/creator/${activeSlug}/analytics`} className="text-hl">
            {t("Analytics")}
              
            </Link>
          </Menu.Item>
          <Menu.Item
            key={"3"}
            className="text-hl flex flex-row items-center border-l-2 "
            icon={<CalendarWeek size={18} />}
          >
            <Link to={`/creator/${activeSlug}/calendar`} className="text-hl">
              {" "}
              {t("Calendar")}
              
            </Link>
          </Menu.Item>
          {authUser.selectedAppPlan.id === 5 && <Menu.Item
            key={"8"}
            className="text-hl flex flex-row items-center border-l-2 "
            icon={<LiaUserTagSolid size={18} />}
          ><Link to={`/creator/social-commerce/content-gallery`} className="text-hl">
              Social Commerce
            </Link>
          </Menu.Item>}
          <Menu.Item
            key={"4"}
            className="text-hl flex flex-row items-center border-l-2 "
            icon={<Gear size={18} />}
          >
            <Link to={`/creator/${activeSlug}/settings/campaigns`} className="text-hl">
              {" "}
              {t("Settings")}
              
            </Link>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
};

export default SidebarCreator;
