import React, { useEffect, useState } from "react";
import { Facebook, Instagram, Linkedin, Twitter } from "react-bootstrap-icons";

const SocialIcon = (props) => {
  const [color, setcolor] = useState("#ccc");
  const [icon, seticon] = useState(<Instagram size={14} />);
  const [added, setadded] = useState(false);
  const iconList ={
      "instagram":<Instagram size={14} />,
      "facebook":<Facebook size={14} />,
      "twitter":<Twitter size={14} />,
      "linkedin":<Linkedin size={14} />,
      "feed":<img src={"https://hs-container.s3.ap-south-1.amazonaws.com/blume.png"} />
  }
  const colorList ={
    "feed":"#000",
    "instagram":"#8a3ab9",
    "facebook":"#4267B2",
    "twitter":"#1DA1F2",
    "linkedin":"#0077b5"
}
  useEffect(() => {
        setcolor(colorList[props.icon])
        seticon(iconList[props.icon])
        setadded(true)
    return () => {setadded(false)};
  }, [props]);
  return (
    props.extraClass?<>
      {added && <div className={props.round?"source rounded-full p-1 w-5 h-5 flex items-center justify-center  overflow-hidden text-white":"source rounded-lg p-0 w-6 h-6 flex items-center justify-center overflow-hidden text-white"} style={{backgroundColor:color}}>
        {icon}
      </div>}
    </>:<>
    {added && <div className={props.round?"rounded-full p-1 w-5 h-5 flex items-center justify-center  overflow-hidden text-white":"rounded-lg p-0 w-6 h-6 flex items-center justify-center overflow-hidden text-white"} style={{backgroundColor:color}}>
      {icon}
    </div>}
  </>
  );
};

export default SocialIcon;
