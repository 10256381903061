import React, { useEffect, useState } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { Button, Checkbox, notification, Input, DatePicker, Spin ,Select, Switch, InputNumber, Tooltip, message} from "antd";
import Tags from "container/common/Tags";
import Utils from "helper/Utils";
import _ from "lodash";

import ApiService from "services/ApiService";
import MineHashList from "components/Reusable/MineHashList";
import moment from "moment";
import { InfoCircle, XCircle } from "react-bootstrap-icons";
import HashIcon from "components/HashIcon";
import { HASH_DEFAULT_CHARGE, HASH_DEFAULT_GASFEE } from "constants/hashkeys.constant";
import FileUploader from "container/common/FileUploader";
import BuyHashCard from "container/common/BuyHashCard";
const { Option } = Select;
export const AddHash = ({ showModalFirst, callback,cancelCallBack, profiles, user ,event,mintedCallBack,showExtra}) => {
  const [show, setshow] = useState(showModalFirst);
  const [keywords, setkeywords] = useState([]);
  const [loadingHashes, setloadingHashes] = useState(true);
  const [mintedKeyhash, setmintedKeyhash] = useState([]);
  const [userTokens, setuserTokens] = useState(0);
  const [userTokensOg, setuserTokensOg] = useState(0);
  const [showKeywords, setshowKeywords] = useState(false);
  const [checkedHash, setcheckedHash] = useState([]);
  const [checkAll, setCheckAll] = React.useState(false);
  const [processOn, setprocessOn] = useState(false);
  const [saleType, setsaleType] = useState({type:"1",price:10,endDate:0})
  const [minimumAmount, setminimumAmount] = useState(10)
  const [rentDetails, setrentDetails] = useState({type:"1",price:10,endDate:0,rentOption:"post",deposit:100})
  const [maxMentions, setmaxMentions] = useState(1)
  const [lastBid, setlastBid] = useState(null)
  const [ActiveImages, setActiveImages] = useState([]);
  const [needMoreTokens, setneedMoreTokens] = useState(false)
  const [showBuyView, setshowBuyView] = useState(false)
  const [prceedBuy, setprceedBuy] = useState(true)
  const [buyValue, setbuyValue] = useState(0);
  const [paymentSecret, setpaymentSecret] = useState(null)
  //OffersForFeedCreation
  const [avilableMintedOffer, setavilableMintedOffer] = useState(null)

  const optionsRent = ['Per Post', 'Per Day', 'Per Week','Per Month']
  useEffect(() => {
    
    if(event["event"] === "auction"){
      getLastMaxBid()
    }
    else if(event["event"] === "sale"){
      setsaleType({type:"1",price:10,endDate:0,fraction:event?event.item["fraction"]:false,fractionValues:event?event.item["fractionValues"]:0})
    }
    else if(event["event"] === "mint" && event["keywords"]){
      setkeywords(event["keywords"])
      
      activateHashes(event["keywords"])
    }
    const min = getRndInteger(112,176);
    setminimumAmount(min)
    setsaleType({...saleType,price:min})
    setrentDetails({...rentDetails,price:min})
    return () => {
      
    }
  }, [event])
  
  const getRndInteger = (min, max)=> {
    return Math.floor(Math.random() * (max - min)) + min;
  }
  const getLastMaxBid = async()=>{
    setloadingHashes(true)
    const lastMaxBid = await ApiService.PostApiCalls("keyhash/sales/lastBid",{...(_.pick(event.item,["title","mintedBy","hashId"]))},true)
    if(lastMaxBid[0] && lastMaxBid[1]["IsSuccessful"]){
      setlastBid(lastMaxBid[1]["lastBid"])
      setsaleType({...saleType,type:"2",price:lastMaxBid[1]["lastBid"]?lastMaxBid[1]["lastBid"]["options"]["price"]+1:event.item["options"]["price"]+1})
    }
    setloadingHashes(false)

  }
  const activateHashes = async (kees=null) => {
    const hashKeys = [];
    
    if(kees){
      kees.map((k) => {
        hashKeys.push(Utils.getHashId(k["value"], true, true));
      });
    }else{
      keywords.map((k) => {
        hashKeys.push(Utils.getHashId(k["value"], true, true));
      });
    }
    
    const addedKeyHashes = await ApiService.PostApiCalls(
      "keyhashes/list",
      { hashKeys: hashKeys },
      true
    );
    if (addedKeyHashes[0] && addedKeyHashes[1]["IsSuccessful"]) {
      setmintedKeyhash(addedKeyHashes[1]["hash"]);
      setuserTokens(addedKeyHashes[1]["user"]["hashTokens"]);
      setuserTokensOg(addedKeyHashes[1]["user"]["hashTokens"]);
      setcheckedHash([])
      setCheckAll(false)
      setloadingHashes(false);
    } else {
      setloadingHashes(false);
    }

    setshowKeywords(true);
  };
  const checkedAll = (e, id, t) => {
    setneedMoreTokens(false)
    let hashes = checkedHash;
    let leftToken = 0
    if (!e) {
      _.remove(hashes, function(n) {
        return n == id;
      });
      setuserTokens(userTokens + t);
      leftToken = userTokens + t;
    } else {
      setuserTokens(userTokens - t);
      leftToken = userTokens - t;

      hashes.push(id);
    }
    if(leftToken < 0){
      setneedMoreTokens(true)
      setbuyValue(Math.abs(leftToken))
    }
    setcheckedHash(hashes);
  };
  const onCheckAllChange = (e) => {
    let tokens = userTokensOg;
    setneedMoreTokens(false)

    if (e.target.checked) {
      let hashUpdates = [];
      keywords.map((k) => {
        const id = Utils.getHashId(k["value"], true, true);
        if (!mintedKeyhash.includes(Utils.getHashId(k["value"], true, true))) {
          hashUpdates.push(id);
          tokens = tokens - (HASH_DEFAULT_CHARGE+HASH_DEFAULT_GASFEE);
        }
      });
      if(tokens < 0){
        setneedMoreTokens(true)
        
        setbuyValue(Math.abs(tokens));

      }
      setcheckedHash(hashUpdates);
      setuserTokens(tokens);
    } else {
      setcheckedHash([]);
      setuserTokens(userTokensOg);
      
    }

    setCheckAll(e.target.checked);
  };
  const selectAllTokenCode = (e) =>{
    if(e === "1155"){
      const newArr = _.map(keywords, function(a,k) {
        return {...a,code:"1155",copies:10};
      });
      setkeywords(newArr);
    }
    else{
      const newArr = _.map(keywords, function(a,k) {
        return {...a,code:"721",copies:null};
      });
      setkeywords(newArr);
    }
   
  }
  const setNewTags = (n) => {
   
    
    setkeywords(n);
  };
  const updateTagsAdded =(i,tag)=>{

    const newArr = _.map(keywords, function(a,k) {
      return k === i ? tag : a;
    });
    setkeywords(newArr);
  }
  const mintHashes = async(now)=>{
    if(userTokens < 0){
      Utils.showNotification(
        "warning",
        "Oops!",
        "You do not have required hash tokens to process."
      );
    return;

    }
    let hashUpdates = []
    keywords.map(k =>{
      const id = Utils.getHashId(k["value"],true,true)
      if(checkedHash.includes(id)){
        const hash = {
          title:k["value"],
          code:k["code"],
          copies:k["copies"],
          fraction:k["fractions"]?true:false,
          fractionValues:k["fractions"]?k["fractions"]:0,
          hashId:id,
          minted:now,
          tokensCharged:(HASH_DEFAULT_CHARGE+HASH_DEFAULT_GASFEE)
        }
        hashUpdates.push(hash)
      }
    })
   
    setprocessOn(true)
    const minted = await ApiService.PostApiCalls("keyhashes/mint",{hashKeys:hashUpdates,hashVisual:ActiveImages,userToken:now?userTokens:userTokensOg},true)
    if(minted[0] && minted[1]["IsSuccessful"]){
      Utils.showNotification(
        "success",
        "Done",
        "Hash have been sent for approval"
      );
      
      setshowKeywords(false);
      setCheckAll(false)
      setuserTokensOg(now?userTokens:userTokensOg)
      setcheckedHash([])
      callback(false,event["keywords"]);
    }
    else{
      Utils.showNotification(
        "success",
        "Done",
        "Hash have been sent for approval"
      );
    }
    setprocessOn(false)
    
}
const changeSaleBidValue = (e) =>{
  setsaleType({...saleType,price:e})
  setrentDetails({...rentDetails,price:e})

}
const changeFractionValue = (f) =>{
  const fraction = f === 0?false:true;
  const fractionValues = f;
  setsaleType({...saleType,fraction:fraction,fractionValues:fractionValues})
}
const changeRentDepositValue = (e) =>{
  
  setrentDetails({...rentDetails,deposit:e})

}

const onChangeRentOptions = (e)=>{
  setrentDetails({...rentDetails,rentOption:e})
  // setrentDetails({...rentDetails,rentOptions:e})
}
const onChangeDate = (date, dateString)=>{
  if(date){
    setsaleType({...saleType,endDate:date.unix()});
    setrentDetails({...rentDetails,endDate:date.unix()})
  }
  else{
    setsaleType({...saleType,endDate:0});
    setrentDetails({...rentDetails,endDate:date.unix()})
  }
  
}
const disabledDate = (current)=> {
  // Can not select days before today and today
  return current && current < moment().endOf('day');
}
const confirmSale = async()=>{
  setprocessOn(true)
  const payload = {
    options:saleType,
    type:saleType["type"] === "1"?"sale":"auction",
    endDate:saleType["endDate"]
  }
  // console.log(payload);
  // return;
  const sale = await ApiService.PostApiCalls("keyhash/sales/new",{...payload,...(_.pick(event.item,["title","mintedBy","hashId"]))},true)

  setprocessOn(false)
  notification["success"]({
    message: 'Sale has been started.',
  });
  cancelCallBack();

}
const confirmMention = async()=>{
  const payload = {
    options:{...saleType,mentionCount:maxMentions},
    type:"mention",
    endDate:saleType["endDate"]
  }

  const sale = await ApiService.PostApiCalls("keyhash/sales/new",{...payload,...(_.pick(event.item,["title","mintedBy","hashId"]))},true)

  setprocessOn(false)
  notification["success"]({
    message: 'Mentions have been allowed.',
  });
  cancelCallBack(sale[1]["hash"]);

}
const confirmBid = async()=>{
  // setprocessOn(true)
  
  const data = {offer:{...saleType},mintedBy:event.item["mintedBy"], hashId:event.item["hashId"],listingType:event.item["listingType"],listingEnddate:event.item["listingEnddate"]}
  
  const sale = await ApiService.PostApiCalls("keyhash/listing/request",{payload:data},true)
  // const sale = await ApiService.PostApiCalls("keyhash/sales/process",{...saleType,...(_.pick(event.item,["title","mintedBy","hashId"]))},true)

  setprocessOn(false)
  notification["success"]({
    message: 'Bid has been submitted.',
  });
  cancelCallBack();

}
const confirmRent = async()=>{
  setprocessOn(true)
  const payload = {
    options:rentDetails,
    type:"rent",
    endDate:rentDetails["endDate"]
  }
  const sale = await ApiService.PostApiCalls("keyhash/sales/new",{...payload,...(_.pick(event.item,["title","mintedBy","hashId"]))},true)

  setprocessOn(false)
  notification["success"]({
    message: 'Keyword has been listed for rent out.',
  });
  cancelCallBack();
}
const ImageUploadCallback =  (images) => {
    
  const image = Utils.filterArray(images, [
    "name",
    "uid",
    "type",
    "url",
    "size",
    "taskId",
    'visualType',"extension","assetData"
  ]);

setActiveImages(images)
}
const createPyament = async() =>{
    const data = { "amount":buyValue*100,
    "currency":"INR"}
    const res = await ApiService.PostApiCalls("/payment/create-payment-intent",data,true)
    if(res[0] && res[1]["IsSuccessful"]){
      setpaymentSecret(res[1]["clientSecret"])
      setprceedBuy(false);
    }
    else{
      Utils.showNotification("error", "Oopps", "Something went wrong",3);
    }

}
const checkAvailabilty = async(k)=>{
  setavilableMintedOffer(null)
  setloadingHashes(true)
  const hashId = Utils.getHashId(k["value"], true, true)
  const Availability = await ApiService.PostApiCalls("/keyhashes/sales/list",{id:hashId},true)
  if(Availability[0] && Availability[1]["IsSuccessful"]){
    const details = Availability[1]["hashKeys"];
    if(details[0]){
      setavilableMintedOffer(details[0])
      let costforuser = details[0]["options"]["price"]+(details[0]["options"]["deposit"]?details[0]["options"]["deposit"]:0)+HASH_DEFAULT_GASFEE;
      
      let leftToken = userTokens - costforuser;
      if(leftToken < 0){
        setneedMoreTokens(true)
        setbuyValue(Math.abs(leftToken))
      }
    }
    else{
      message.success("Sorry, no offer is available.")
    }
  setloadingHashes(false)
    
  }

}
const updateBuyBack = (val)=>{
  
  setuserTokens(userTokens+val)
  setuserTokensOg(userTokensOg+val)
  setneedMoreTokens(false);
  setshowBuyView(false)
}
const confirmAvailableOffer = async()=>{
  setprocessOn(true)
  const response = await ApiService.PostApiCalls("keyhashes/confirm-offer",{offerDetails:avilableMintedOffer},true)
  if(response[0] && response[1]["IsSuccessful"]){
    message.success("Transaction successful, you can use this keyword to create")
    callback(false,event["keywords"]);

  }
  else if(response[0] && !response[1]["IsSuccessful"]){
    message.error(response[1]["message"]?response[1]["message"]:"Something went wrong, pls try again.")

  }
  else{
    message.error("Something went wrong, pls try again.")
  }
  
  setprocessOn(false)

  
}
  return (
    <>
    {event["event"] === "mint" &&
      <Modal
        show={show}
        onHide={() => {
          setshow(false);
          callback(false);
        }}
        centered size="lg"
        contentClassName="campaign-new-modal rounded-2xl p-0 "
        backdrop={"static"}
      >
        <Modal.Header className="border-bottom p-2">
          <div className="flex flex-row w-full justify-between space-x-4 font-semibold items-center p-2 ">
            <span> {!showKeywords ? "Add Keywords" : showBuyView?"Buy Hashs": "Manage Keywords"}</span>
            {showKeywords && (
              <span className>
                {" "}
                Your Tokens -{" "}
                <span className="text-hs-pink"> {userTokens}</span>
              </span>
            )}
          </div>
        </Modal.Header>
        {!showKeywords ? (
          <Modal.Body className="d-flex flex-column  p-3">
            <div className="py-2"></div>
            <Tags callback={setNewTags} tags={keywords} />
            <div className="py-2"></div>
          </Modal.Body>
        ) : showBuyView?(<Modal.Body className="ct-image overflow-scroll max-h-100  p-3">
          <div className="flex flex-col space-y-3">
            
            
           <BuyHashCard backCallback={()=>{setshowBuyView(!showBuyView);setprceedBuy(true)}}   defaultValue={buyValue} tokens={buyValue} successCallback={updateBuyBack} />
          </div>


        </Modal.Body>):(
          <Modal.Body className="ct-image overflow-scroll max-h-80  p-3">
            {/* <div className="py-2"></div> */}
            {loadingHashes && (
              <div className="h-32 flex items-center justify-center">
                <Spin />
              </div>
            )}
           {!avilableMintedOffer ?<><span className="font-semibold text-hs-blue">Minted Hash</span>
            {!loadingHashes && (
              <div className="py-2">
                <div className="flex flex-col">
                  {keywords.map(
                    (k, i) =>
                      mintedKeyhash.includes(
                        Utils.getHashId(k["value"], true, true)
                      ) && (
                        <div
                          key={i}
                          className="flex flex-row p-2 border rounded-md text-hs-pink justify-between"
                        >
                         <span>{`#${k["value"].replace(/ /g, "")}`}</span> 
                         {showExtra && <Button onClick={()=>checkAvailabilty(k)}>Check Availability</Button>}
                        </div>
                      )
                  )}
                </div>
              </div>
            )}
            <div className="py-1"></div>
            <span className="font-semibold text-hs-blue">Avilable Hash</span>
            <Checkbox
              className="ml-3"
              indeterminate={false}
              onChange={onCheckAllChange}
              checked={checkAll}
            >
              Check all
            </Checkbox>
            <Select defaultValue="721" style={{ width: 120 }} size={"small"} onChange={selectAllTokenCode} >
            <Option value="721">721</Option>
            <Option value="1155">1155</Option>
            </Select>
            {!loadingHashes && (
              <div className="py-2">
                <div className="flex flex-col">
                  {keywords.map(
                    (k, i) =>
                      !mintedKeyhash.includes(
                        Utils.getHashId(k["value"], true, true)
                      ) && (
                        <MineHashList callback={updateTagsAdded} checkedHash={checkedHash} checkedAll={checkedAll}  key={i} id={i} k={k} />
                      )
                  )}
                </div>
              </div>
            )}
            </>:<>
            <div className="flex flex-col space-y-3">
            <span className="font-semibold capitalize  bg-gray-100">{`${avilableMintedOffer["listingType"]} offer for - ${avilableMintedOffer.title}`}</span>
            {avilableMintedOffer["listingType"] === "rent"?<div className="flex flex-col  py-2 items-start">
                 <span className="flex flex-row mb-2">
                  <span className="font-semibold  bg-gray-100">Deposit - </span>
                  
              <span className="flex flex-row items-center ml-1">
              {/* <Coin className="text-yellow-600" /> */}
              <HashIcon />
              <span className="flex-grow ml-1">{avilableMintedOffer["options"]["deposit"]?avilableMintedOffer["options"]["deposit"]:500}</span>
             
            </span></span>
                  <span className="flex flex-row">
                  <span className="font-semibold  bg-gray-100">Expected rent - </span>
                  
              <span className="flex flex-row items-center ml-1">
              <HashIcon />
              <span className="flex-grow ml-1">{avilableMintedOffer["options"]["price"]}</span>
              <span className="flex-grow ml-1"> per {avilableMintedOffer["options"]["rentOption"]}</span>
            </span></span>
            <span className="flex flex-row text-xs mx-0 py-2">Gas Fees - <HashIcon /> {` ${HASH_DEFAULT_GASFEE}`}</span>
            <span className="text-xs text-muted py-2">
              {`Avialable till ${moment
                    .unix(avilableMintedOffer.listingEnddate)
                    .format("MMM DD, YYYY ")}`}
            </span>
            <span className="font-semibold my-2">{`Your account will be dedcucted for ${avilableMintedOffer["options"]["price"]+(avilableMintedOffer["options"]["deposit"]?avilableMintedOffer["options"]["deposit"]:0)+HASH_DEFAULT_GASFEE} Hashs tokens.`}</span>
                  </div>:<div className="flex flex-col  py-2 items-start">
                  <span className="flex flex-row">
                  <span className="font-semibold  bg-gray-100">{avilableMintedOffer["options"]["type"] === "1"?"Expected price -":"Starting bid -"}</span>
                  
              <span className="flex flex-row items-center ml-1">
              <HashIcon />
              <span className="flex-grow ml-1">{avilableMintedOffer["options"]["price"]}</span>
            </span></span>
            <span className="flex flex-row text-xs mx-0 py-2">Gas Fees - <HashIcon /> {` ${HASH_DEFAULT_GASFEE}`}</span>
            <span className="text-xs text-muted py-2">
              {`Ends on ${moment
                    .unix(avilableMintedOffer.listingEnddate)
                    .format("MMM DD, YYYY ")}`}
            </span>
            <span className="font-semibold my-2">{`Your account will be dedcucted for ${avilableMintedOffer["options"]["price"]+(avilableMintedOffer["options"]["deposit"]?avilableMintedOffer["options"]["deposit"]:0)+HASH_DEFAULT_GASFEE} Hashs tokens.`}</span>
                  </div>}
            </div>
            
            </>}
            <div className="py-2"></div>
           {ActiveImages.length > 0 &&  <div className="w-50 p-1 rounded-sm bg-red-100 relative">
              <div onClick={() => setActiveImages([])} className="absolute right-1 bg-gray-100 bg-opacity-60 rounded-md cursor-pointer p-1 h-6 w-6 flex items-center justify-center"><XCircle color="red" /></div>
              <img src={ActiveImages[0]["url"]} className="w-100 rounded-sm" />
            </div>}
            <div className="py-2"></div>

          </Modal.Body>
        )}
        {!showBuyView && <Modal.Footer className=" justify-end space-x-4 py-2 px-3 border-top">
          {!showKeywords && (
            <Button
              type="primary"
              //  size="large"
              onClick={() => activateHashes()}
              disabled={keywords.length === 0?true:false}
              className="px-3 brd-4 fw-700 mx-2 rounded-lg border-0 flex items-center"
            >
              # Hash
            </Button>
          )}
          {showKeywords && showBuyView? (<>
           <Button
              
              onClick={()=>{setshowBuyView(!showBuyView);setprceedBuy(true)}}
              className="px-3 brd-4 fw-700 mx-2 rounded-lg border-0 flex items-center"
            >
             Go Back
            </Button>
            {
              prceedBuy && <Button
              type="primary"
              onClick={()=>createPyament()}
              className="px-3 brd-4 fw-700 mx-2 rounded-lg border-0 flex items-center"
            >
              Proceed to Buy
            </Button>
            }
            
            </>):showKeywords && (<>
           {needMoreTokens && <Button
              type="primary"
              onClick={()=>{setshowBuyView(!showBuyView);}}
              className="px-3 brd-4 fw-700 mx-2 rounded-lg border-0 flex items-center"
            >
              Buy Hash
            </Button>}
          {checkedHash.length > 0 && ActiveImages.length === 0 && <FileUploader 
            prefix={"images"}
            showUploadList={false}
            multipleImages={false}
            callback={ImageUploadCallback}
            list={ActiveImages}
            limit={2}
            listType="none"
            view={
              <Button
              type="primary"
              //  size="large"
              
              className="px-3 brd-4 fw-700 mx-2 rounded-lg border-0 flex items-center"
            >
              Add Image
            </Button>
            }
            viewUploading={
              <div className="upload-imagebt">
                <Spin size="small" spinning={true} />
                <span className="ml-2 pt-1 fw-700">{"Uploading..."}</span>{" "}
              </div>
            } />}
           
            {!event.hideAddHash && <Button
              type="primary"
              //  size="large"
              onClick={() => setshowKeywords(false)}
              className="px-3 brd-4 fw-700 mx-2 rounded-lg border-0 flex items-center"
            >
              Add Hash
            </Button>}
            
            </>
          )}
          {showKeywords && checkedHash.length > 0 && !showBuyView && (
            <Button
              loading={processOn}
              disabled={needMoreTokens}
              onClick={() => {
                mintHashes(true);
              }}
              className={`px-3 brd-4 fw-700 mx-2 rounded-lg text-white bg-hs-pink  hover:border-hs-pink ${needMoreTokens? "opacity-50":"" }`}
            >
              Mint Now
            </Button>
          )}
          {showKeywords && avilableMintedOffer && !showBuyView && (
            <Button
              loading={processOn}
              disabled={needMoreTokens}
              onClick={() => {
                confirmAvailableOffer(true);
              }}
              className={`px-5 brd-4 fw-700 mx-2 rounded-lg text-white bg-hs-pink  hover:border-hs-pink ${needMoreTokens? "opacity-50":"" }`}
            >
              {avilableMintedOffer["listingType"] === "rent"?"Rent Now":"Buy Now"}
            </Button>
          )}
          {!showBuyView && <Button
                          loading={processOn}

            onClick={() => {
              setshow(false);
              cancelCallBack();
            }}
            className="px-3 brd-4 fw-700 mx-2 rounded-lg hover:text-hs-pink hover:border-hs-pink"
          >
            Cancel
          </Button>}
        </Modal.Footer>}
      </Modal>
    }
    {event["event"] === "mention" &&
      <Modal
        show={show}
        onHide={() => {
          setshow(false);
          callback(false);
        }}
        centered
        contentClassName="campaign-new-modal rounded-2xl"
        backdrop={"static"}
      >
        <Modal.Header>
          <div className="flex flex-row w-full justify-between space-x-4 font-semibold items-center">
            <span>{`Allow Mentions for - ${event.item["title"]}`}</span>
            
            {showKeywords && (
              <span className>
                {" "}
                Your Tokens -{" "}
                <span className="text-hs-pink"> {userTokens}</span>
              </span>
            )}
          </div>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column pt-0">
            <div className="py-3"></div>
            <div className="flex flex-col space-y-4 px-3">
             <span className="flex flex-row space-x-3 items-center"><span>Max Mentions:</span>
            <InputNumber value={maxMentions} min={1} max={5} onChange={(e)=>setmaxMentions(e)}/></span>

            <span className="flex flex-row space-x-3 items-center"><span>End Date:</span>
            <DatePicker onChange={onChangeDate} disabledDate={disabledDate} />
            <Tooltip title="If end date is not set, mention will be available till you cancel it."><InfoCircle/></Tooltip>
            </span>
            </div>
            <div className="py-3"></div>
          </Modal.Body>
        <Modal.Footer className="border-0 justify-end space-x-4">
          {!showKeywords && (
            <Button
              type="primary"
              loading={processOn}
              onClick={() => confirmMention()}
              
              className="px-3 brd-4 fw-700 mx-2 rounded-lg border-0 flex items-center"
            >
              Confirm Mentions
            </Button>
          )}
          
         
          <Button
            // size="large"
            onClick={() => {
              setshow(false);
              cancelCallBack();
            }}
            className="px-3 brd-4 fw-700 mx-2 rounded-lg hover:text-hs-pink hover:border-hs-pink"
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    }
    {event["event"] === "sale" &&
      <Modal
        show={show}
        onHide={() => {
          setshow(false);
          callback(false);
        }}
        centered
        contentClassName="campaign-new-modal rounded-2xl"
        backdrop={"static"}
      >
        <Modal.Header>
          <div className="flex flex-row w-full justify-between space-x-4 font-semibold items-center">
            <span>{`Sale Hash - ${event.item["title"]}`}</span>
            
            {showKeywords && (
              <span className>
                {" "}
                Your Tokens -{" "}
                <span className="text-hs-pink"> {userTokens}</span>
              </span>
            )}
          </div>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column pt-0">
            <div className="py-3"></div>
            <div className="flex flex-col space-y-4 px-3">
            <span className="flex flex-row space-x-3">
              <span>Sale Type
                :</span>
              <Switch  checkedChildren="Sale" unCheckedChildren="Auction" defaultChecked onChange={(e)=>setsaleType({...saleType,type:e?"1":"2"})} /></span>
            <span className="flex flex-row space-x-3 items-center"><span>{saleType["type"] === "1"?"Price":"Minimum Bid"}:</span>
            <InputNumber prefix={<HashIcon />} value={saleType["price"]} min={minimumAmount} onChange={changeSaleBidValue}/>
            <Tooltip title="Minimum price set is based on performance of this keyword. You can increase price as per your expectation."><InfoCircle/></Tooltip>
            </span>
           { event.item["fraction"] && saleType["type"] === "1" && <span className="flex flex-row space-x-3 items-center"><span>Fractions :</span>
            <InputNumber  defaultValue={event.item["fractionValues"]} max={event.item["fractionValues"]} min={1} onChange={changeFractionValue}/>
            </span>
            }
            <span className="flex flex-row space-x-3 items-center"><span>End Date:</span>
            <DatePicker onChange={onChangeDate} disabledDate={disabledDate} />
            </span>
            
            </div>
            <div className="py-3"></div>
          </Modal.Body>
        <Modal.Footer className="border-0 justify-end space-x-4">
          {!showKeywords && (
            <Button
              type="primary"
              loading={processOn}
              onClick={() => confirmSale()}
              disabled={saleType["endDate"] === 0 ?true:false}
              className="px-3 brd-4 fw-700 mx-2 rounded-lg border-0 flex items-center"
            >
              Confirm Sale
            </Button>
          )}
          
         
          <Button
            // size="large"
            onClick={() => {
              setshow(false);
              cancelCallBack();
            }}
            className="px-3 brd-4 fw-700 mx-2 rounded-lg hover:text-hs-pink hover:border-hs-pink"
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    }
     {event["event"] === "rent" &&
      <Modal
        show={show}
        onHide={() => {
          setshow(false);
          callback(false);
        }}
        centered
        contentClassName="campaign-new-modal rounded-2xl"
        backdrop={"static"}
      >
        <Modal.Header>
          <div className="flex flex-row w-full justify-between space-x-4 font-semibold items-center">
            <span>{`Rent Hash - ${event.item["title"]}`}</span>
            
            {showKeywords && (
              <span className>
                {" "}
                Your Tokens -{" "}
                <span className="text-hs-pink"> {userTokens}</span>
              </span>
            )}
          </div>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column pt-0">
            <div className="py-3"></div>
            <div className="flex flex-col space-y-4 px-3">
            <span className="flex flex-row space-x-3 items-center"><span>Deposit:</span>
            <InputNumber prefix={<HashIcon />} value={rentDetails["deposit"]} min={100} onChange={changeRentDepositValue}/>
            <Tooltip title="This is refundable amount."><InfoCircle/></Tooltip>
            </span>
            <span className="flex flex-row space-x-3 items-center"><span>Rent:</span>
            <InputNumber prefix={<HashIcon />} value={rentDetails["price"]} min={minimumAmount} onChange={changeSaleBidValue}/>
            <Tooltip title="Minimum rent set is based on performance of this keyword. You can increase price as per your expectation."><InfoCircle/></Tooltip>
            </span>
            <span className="flex flex-row space-x-3 items-center"><span>Rent Duration:</span>
            {/* <Checkbox.Group options={optionsRent} defaultValue={rentDetails["rentOptions"]} onChange={onChangeRentOptions}  /> */}
    
            <Select defaultValue="post" style={{ width: 120 }} onChange={onChangeRentOptions} >
            
            <Option value="post">Per Post</Option>
            <Option value="day">Per Day</Option>
            <Option value="week">Per Week</Option>
            <Option value="month">Per Month</Option>
            </Select>
            </span>

            <span className="flex flex-row space-x-3 items-center"><span>End Date:</span>
            <DatePicker onChange={onChangeDate} disabledDate={disabledDate} />
            </span>
            </div>
            <div className="py-3"></div>
          </Modal.Body>
        <Modal.Footer className="border-0 justify-end space-x-4">
          {!showKeywords && (
            <Button
              type="primary"
              loading={processOn}
              onClick={() => confirmRent()}
              disabled={saleType["endDate"] === 0 ?true:false}
              // disabled={saleType["endDate"] === 0 ?true:rentDetails["rentOptions"].length === 0?true:false}
              className="px-3 brd-4 fw-700 mx-2 rounded-lg border-0 flex items-center"
            >
              Confirm Rent out
            </Button>
          )}
          
         
          <Button
            // size="large"
            onClick={() => {
              setshow(false);
              cancelCallBack();
            }}
            className="px-3 brd-4 fw-700 mx-2 rounded-lg hover:text-hs-pink hover:border-hs-pink"
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    }
    {event["event"] === "auction" &&
      <Modal
        show={show}
        onHide={() => {
          setshow(false);
          callback(false);
        }}
        centered
        contentClassName="campaign-new-modal rounded-2xl"
        backdrop={"static"}
      >
        <Modal.Header>
          <div className="flex flex-row w-full justify-between space-x-4 font-semibold items-center">
            <span>{`Bid Hash - ${event.item["title"]}`}</span>
            
            {showKeywords && (
              <span className>
                {" "}
                Your Tokens -{" "}
                <span className="text-hs-pink"> {userTokens}</span>
              </span>
            )}
          </div>
        </Modal.Header>
        {loadingHashes && (
              <div className="h-32 flex items-center justify-center">
                <Spin />
              </div>
            )}
        {!loadingHashes && <Modal.Body className="d-flex flex-column pt-0">
            <div className="py-3"></div>
            <div className="flex flex-col space-y-4 px-3">
            <span className="flex flex-row space-x-3">
              <span>Minimum Ask
                :</span>
                <HashIcon/><span>{event.item["options"]["price"]}</span></span>
              <span className="flex flex-row space-x-3">
              <span>Last Offer Price
                :</span>
              <span>{lastBid?<><HashIcon/>{lastBid["options"]["price"]}</>:"NA"}</span></span>
            <span className="flex flex-row space-x-3 items-center"><span>{"Your Bid Price"}:</span>
            <InputNumber prefix={<HashIcon />} value={saleType["price"]} min={lastBid?lastBid["options"]["price"]+1:event.item["options"]["price"]+1} onChange={changeSaleBidValue}/></span>

            </div>
            <div className="py-3"></div>
          </Modal.Body>}
        <Modal.Footer className="border-0 justify-end space-x-4">
          {!showKeywords && (
            <Button
              type="primary"
              loading={processOn}
              onClick={() => confirmBid()}
              
              className="px-3 brd-4 fw-700 mx-2 rounded-lg border-0 flex items-center"
            >
              Confirm Bid
            </Button>
          )}
          
         
          <Button
            // size="large"
            onClick={() => {
              setshow(false);
              cancelCallBack();
            }}
            className="px-3 brd-4 fw-700 mx-2 rounded-lg hover:text-hs-pink hover:border-hs-pink"
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    }
    </>
  );
};
