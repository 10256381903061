import React,{useState} from 'react'
import {EditIcon,DeleteIcon} from "res/icons/ReviewIcons"
import Loading from "res/icons/Loading"
import {Button, Spin,message} from "antd"
import {PlayCircleFill, Upload as UploadIcon} from "react-bootstrap-icons";
import ImageUpload from "components/Reusable/ImageUpload"
import _ from 'lodash';
import FilerobotImageEditor from 'filerobot-image-editor'; 
import ApiService from 'services/ApiService';
const Images = (props) => {
    const config = {
        filerobotUploadKey: '7cc1f659309c480cbc8a608dc6ba5f03',
        filerobotContainer: 'scaleflex-tests-v5a',
        // elementId: '',
        uploadParams: {
            dir: '/'
          }
};
    const [ActiveImages, setActiveImages] = useState(props.activeImages)
    const [ActiveAIImages, setActiveAiImages] = useState([])
    const [ActiveFilterImages, setActiveFilterImages] = useState([])
    const [imageView, setimageView] = useState("1")
    const [activeFilter, setactiveFilter] = useState("0")
    const [isShow, setisShow] = useState(false)
    const [imgSrc, setimgSrc] = useState("https://blue-container.s3.eu-west-2.amazonaws.com/blueapp/campaign/post-images/kFn4c0jNnJ_HBa.jpg")
    const [loadingImages, setloadingImages] = useState(true)
    const spinIcon =  <div className="anticon anticon-loading anticon-spin ant-spin-dot"><Loading  /></div>;
    
    const changeImageView = async() =>{
        if(imageView === "1"){
        setimageView("2")
        
        const listed = _.map(props.tags,"title")
        setloadingImages(true)
        const imagesAi = await ApiService.PostApiCalls("campaign/hashImages",{tags:listed},true)
        let imagesAiAdded = [];
        console.log(imagesAi["1"]["images"]);
        for(var i = 0;i < imagesAi["1"]["images"].length;i++){
            if(imagesAi["1"]["images"][i]){
            imagesAiAdded.push(imagesAi["1"]["images"][i][0])
        }
        }
        setActiveAiImages(imagesAiAdded);
        setloadingImages(false)
    }
    }
    
    const removeImage = (e,k) => {
       
        const remainingimages = _.filter(ActiveImages, function (img,i) {
            return img["uid"] !== k;
          });
         
          props.callback(remainingimages)
          setActiveImages(remainingimages);
          setTimeout(
            () =>  props.update({i:remainingimages}), 
            500
        );
    }
    const AddImageToCampaign =(img) =>{
        
        const isAdded = _.filter(ActiveImages, function (imgAll,i) {
            return imgAll["uid"] === img["uid"];
          });
        if(isAdded.length === 0){
            message.success('Added to campaign');
            setActiveImages([...ActiveImages,img])
            props.callback([...ActiveImages,img])
            setTimeout(
                () =>  props.update({i:[...ActiveImages,img]}), 
                500
            );
        }
    }
    const editImage = (e,k) =>{        
        setimgSrc(k["url"]?k["url"]:k["src"])
        setisShow(true)
    }
    
    const getImgUrl = (img) =>{
       
        if(img.visualType === "video"){
            
            const imgv =  img.url?img.url:img.src
            const imgpath =" https://hs-container.s3.ap-south-1.amazonaws.com/"+img["assetData"]["bucketpath"]+"/"+img["assetData"]["name"]+"-thumb.jpg"
            return imgpath
        }
        else{
            return img.url?img.url:img.src
        }
        
       }
    
    
    const ImageUploadCallback = (images) =>{       
        setActiveImages(images)
        props.callback(images)
        setTimeout(
            () =>  props.update({i:images}), 
            500
        );
    }
   const setFilteredVisuals = (state)=>{
        if(state === "0"){
            setactiveFilter(state);
            setActiveFilterImages(ActiveAIImages)
        }
        else{
            const listv = ["all","image","video","gif"];
            const isAdded = _.filter(ActiveAIImages, function (imgAll,i) {
                return imgAll["visualType"] === listv[parseInt(state)];
              });
            setactiveFilter(state);
            setActiveFilterImages(isAdded)
        }
        
   }
    const onComplete = (newUrl) => {
         
         }
    return (
        <>
        <div className="d-flex flex-grow-1 align-items-center flex-wrap">
        <div className="d-flex flex-grow-1 mb-4 justify-between items-center w-100">
        <ImageUpload campaign={props.campaign} cid={props.campaign.cid} showUploadList={false} callback={ImageUploadCallback} list={ActiveImages} limit={30} listType="none"
        view={<div className="upload-imagebt"><UploadIcon color="#30B67C" /><span className="ml-2 pt-1 fw-700">Upload images</span></div>} 
        viewUploading={<div className="upload-imagebt">
        <Spin size="small" indicator={spinIcon} spinning={true} />
        <span className="ml-2 pt-1 fw-700">Uploading...</span> </div>}

        />
        <div className='flex space-x-2'
        ><Button type="primary" onClick={()=>setimageView("1")} >Article Images</Button><Button type="primary"  onClick={()=>changeImageView("2")}  >Hashtags Images</Button></div>
        </div>
        {
            imageView === "2" &&   <div className="d-flex flex-grow-1 mb-4 justify-between items-center w-100">
           
            <div className='flex space-x-2'>
            <Button  onClick={()=>setFilteredVisuals("0")} danger={activeFilter === "0"?true:false} >All</Button>
            <Button  onClick={()=>setFilteredVisuals("1")} danger={activeFilter === "1"?true:false} >Images</Button>
            <Button  onClick={()=>setFilteredVisuals("2")} danger={activeFilter === "2"?true:false}  >Videos</Button>
            <Button  onClick={()=>setFilteredVisuals("3")} danger={activeFilter === "3"?true:false}  >GIFs</Button>
            
            </div>
            </div>
        }
        {imageView === "1" && <div className="images-gallery">
        {
            ActiveImages.map((img,key) =>(
                <div className="item" key={key}>
        <img src={getImgUrl(img)} alt=""></img>
       
        
        {img["visualType"] === "video" && <div className='absolute bg text-white z-10 inset-0 flex items-center justify-center cursor-pointer'>
        <PlayCircleFill size={26} /></div>}
        <div className="image-action z-10">
        <div className="abtn" onClick={(e) => removeImage(e,img["uid"])}><DeleteIcon /></div>
        <div className="abtn" onClick={(e) => editImage(e,img)}><EditIcon /></div>   
        </div>
        </div>
            ))
        }
       
        </div>}
        {imageView === "2" && loadingImages && <div className="images-gallery">
        <div className="item" key={"1"}><Spin size="small"  spinning={true} /></div>
       
        </div>}
        {imageView === "2" && activeFilter === "0" && <div className="images-gallery">
        {
            ActiveAIImages.map((img,key) =>(
                <div className="item" key={key} onClick={()=>AddImageToCampaign(img)}>
        <img src={getImgUrl(img)} alt=""></img>    
        {img["visualType"] === "video" && <div className='absolute bg text-white z-10 inset-0 flex items-center justify-center cursor-pointer'>
            <PlayCircleFill size={26} />
            </div>}
        {/* <div className="image-action">
        <div className="abtn" onClick={(e) => removeImage(e,img["uid"])}><DeleteIcon /></div>
        <div className="abtn" onClick={(e) => editImage(e,img)}><EditIcon /></div>   
        </div> */}
        </div>
            ))
        }
       
        </div>}
        {imageView === "2" && activeFilter !== "0" && <div className="images-gallery">
        {
            ActiveFilterImages.map((img,key) =>(
                <div className="item" key={key} onClick={()=>AddImageToCampaign(img)}>
        <img src={getImgUrl(img)} alt=""></img>    
        {img["visualType"] === "video" && <div className='absolute bg text-white z-10 inset-0 flex items-center justify-center cursor-pointer'>
            <PlayCircleFill size={26} />
            </div>}
        {/* <div className="image-action">
        <div className="abtn" onClick={(e) => removeImage(e,img["uid"])}><DeleteIcon /></div>
        <div className="abtn" onClick={(e) => editImage(e,img)}><EditIcon /></div>   
        </div> */}
        </div>
            ))
        }
       
        </div>}
        </div>
       
        <FilerobotImageEditor
           show={isShow}
           src={imgSrc}
           onClose={() => setisShow(false)}
        //    onComplete={(props) => { console.log(props) }}
            onComplete={onComplete}
           config={config}
        />
        </>
    )
}

export default Images
