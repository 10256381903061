import React, {createContext, useState} from 'react';

export const VideoContext = createContext();

export const VideoProvider = ({children}) => {
    const [video, setVideo] = useState(null);
    const [videoId, setVideoId] = useState("");
    const [videoClips, setVideoClips] = useState([]);
    const [videoActiveClip, setVideoActiveClip] = useState(0);
    const [videoActiveClipType, setVideoClipType] = useState("text");
    const [showPreview, setshowPreview] = useState(false);
    const [activeLefttab, setactiveLefttab] = useState("1")
    const [videoContent, setvideoContent] = useState(null)
    const [videoResolution, setvideoResolution] = useState("720")
    return (
        <VideoContext.Provider
           value={{               
            video, setVideo,videoId, setVideoId,videoClips, setVideoClips,videoActiveClip, setVideoActiveClip,videoActiveClipType, setVideoClipType,
            showPreview, setshowPreview,activeLefttab, setactiveLefttab,videoContent, setvideoContent,
            videoResolution, setvideoResolution,
            updateContent (data){
                setvideoContent({...videoContent,...data})
            }
           }}
           >
               {children}
    </VideoContext.Provider>
    )
}