import React, { useContext } from "react";
import PropTypes from "prop-types";
import Header from "components/Header";
import { Layout } from "antd";
import OpenHeader from "components/OpenHeader";
import { AuthContext } from "app/AuthProvider";
import SearchLeft from "components/SearchLeft";
import SidebarRight from "components/SidebarRight";
import ReHeader from "components/ReHeader";
import ReLeftMenu from "components/ReLeftMenu";
const { Sider, Content } = Layout;
const ReLayout = ({ children, noNavbar, noFooter }) => {
  const { authUser } = useContext(AuthContext);
  // console.log(authUser);
  return (
    <>
      {
        <Layout hasSider className="container w-full max-w-full min-h-screen bg-hg p-0">
          <ReHeader noPadd={true} user={authUser} ></ReHeader>
          <Sider
          className="bg-white h-screen hidden lg:block p-0 fixed mt-16 shadow-xl"
          width="300"
        >
          <ReLeftMenu {...children.props} />
        </Sider>
          <Layout className="mt-16 p-0" style={{marginLeft:"300px"}}>
           
            <Content className="p-0 bg-hg">{children}</Content>
          </Layout>
         
        </Layout>
      }
    </>
  );
};

ReLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool,
};

ReLayout.defaultProps = {
  noNavbar: false,
  noFooter: false,
};

export default ReLayout;
