import { Button, Statistic,Modal,notification, InputNumber } from "antd";
import Utils from "helper/Utils";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { ArrowDown, ArrowDownShort, ArrowUp, Clock, ClockFill, Coin } from "react-bootstrap-icons";
import ApiService from "services/ApiService";
import { Link } from "react-router-dom";
import { AddHash } from "container/creator/creator/AddHash";
import HashIcon from "components/HashIcon";
import { HASH_DEFAULT_GASFEE } from "constants/hashkeys.constant";
const { confirm } = Modal;
const Hashtags = (props) => {
  const [processOn, setprocessOn] = useState(false);
  const [userTokens, setuserTokens] = useState(0);
  const [userTokensOg, setuserTokensOg] = useState(0);
  const [showModal, setshowModal] = useState(false)
  const [modalEvent, setmodalEvent] = useState({event:"mint",item:null})
  const [type, settype] = useState(props.type)
  const [item, setitem] = useState(props.hash);
  const [fractionInSale, setfractionInSale] = useState(0)
  
  useEffect(() => {
    
  
    return () => {
      
    }
  }, [item,type])
  const getRndInteger = (min, max)=> {
    return Math.floor(Math.random() * (max - min)) + min;
  }
  const randomNumber = getRndInteger(-15,20)
  const mintHashes = async (now, title, requestedCoins = 2) => {
    setprocessOn(true);
    const hashKeys = [];
    hashKeys.push(Utils.getHashId(title, true, true));
    const addedKeyHashes = await ApiService.PostApiCalls(
      "keyhashes/list",
      { hashKeys: hashKeys },
      true
    );
    if (addedKeyHashes[0] && addedKeyHashes[1]["IsSuccessful"]) {
      setprocessOn(false);
      setuserTokens(addedKeyHashes[1]["user"]["hashTokens"]);
      setuserTokensOg(addedKeyHashes[1]["user"]["hashTokens"]);
      const availableCoins = addedKeyHashes[1]["user"]["hashTokens"];
      if (availableCoins < requestedCoins) {
        Utils.showNotification(
          "error",
          "Oopps",
          "You do not have enought coins!"
        );
      } else {
        //Mint
        let hashUpdates = [];
        const hash = {
          title: title,
          hashId: Utils.getHashId(title, true, true),
          minted: now,
        };
        hashUpdates.push(hash);
        const minted = await ApiService.PostApiCalls(
          "keyhashes/mint",
          { hashKeys: hashUpdates, userToken: now ? userTokens : userTokensOg },
          true
        );
        if (minted[0] && minted[1]["IsSuccessful"]) {
          Utils.showNotification(
            "success",
            "Done",
            "Hash have added in your account"
          );
        } else {
          Utils.showNotification("error", "Oopps", "Something went wrong");
        }
      }
    } else {
      setprocessOn(false);
      Utils.showNotification("error", "Oopps", "Something went wrong");
    }
  };
  const cancelSale = async (id)=>{
   
    confirm({
      title: 'Do you want to cancel this hash sale?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      async onOk() {
        const data = await ApiService.PostApiCalls("keyhash/sales/process",{hashId:props.hash.hashId},true)
        if(data[0] && data[1]["IsSuccessful"]){
          setitem(data[1]["hash"])
          notification["success"]({
            message: 'Sale has been cancelled.',
          });
        }
        else{
          notification["error"]({
            message: 'Request failed!',
          });
        }
        
      },
      onCancel() {},
    });
  }
  const cancelMentions = async (id)=>{
   
    confirm({
      title: 'Do you want to cancel this hash mentions?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      async onOk() {
        const data = await ApiService.PostApiCalls("keyhash/mention/cancel",{hashId:props.hash.hashId},true)
        if(data[0] && data[1]["IsSuccessful"]){
          setitem(data[1]["hash"])
          notification["success"]({
            message: 'Mentions has been cancelled.',
          });
        }
        else{
          notification["error"]({
            message: 'Request failed!',
          });
        }
        
      },
      onCancel() {},
    });
  }
  const buyHash =()=>{
    const userBalance = props.user["hashTokens"];
    
    if(item["listingType"] === "rent"){
      const requiredBalance = item["options"]["price"]+HASH_DEFAULT_GASFEE+(item["options"]["deposit"]?item["options"]["deposit"]:0);
      if(userBalance < requiredBalance){
        Utils.showNotification("error", "Oopps!", "You do not have required Hashs to make this transaction.",3);
        return false;
      }
      confirm({
        title: 'Rent Hash -'+item["title"],
        icon: <ExclamationCircleOutlined />,
        content: `${item["options"]["deposit"]?item["options"]["deposit"]:500} tokens will deducted from your account for  deposit(refundable). Your account will be charged for ${item["options"]["price"]+HASH_DEFAULT_GASFEE} tokens as per defined usage.`,
        async onOk() {
          const datapayload = {offer:{price:item["options"]["price"]+HASH_DEFAULT_GASFEE,type:item["options"]["type"],rentOption:item["options"]["rentOption"],deposit:item["options"]["deposit"]?item["options"]["deposit"]:0},mintedBy:item["mintedBy"], hashId:item["hashId"],listingType:item["listingType"],listingEnddate:item["listingEnddate"]}
          const offerDetails = {mintedBy:item["mintedBy"],hashId:item["hashId"],listingType:item["listingType"],listingEnddate:item["listingEnddate"],options:item["options"]}
          
          
          
          const data = await ApiService.PostApiCalls("keyhashes/confirm-offer",{offerDetails:offerDetails},true)
          
          if(data[0] && data[1]["IsSuccessful"]){
            setitem(data[1]["hash"])
            notification["success"]({
              message: 'Transaction successful, you can now use '+item["title"]+" as per rent agreement.",
            });
          }
          else{
            notification["error"]({
              message: 'Request failed!',
            });
          }
          
        },
        onCancel() {},
      });
    }
    else{
    if(item["options"]["type"] === "2"){
      setmodalEvent({event:"auction",item:item})
      setshowModal(true)

      
    }else{
      let finalPrice = item["options"]["price"];
      if(fractionInSale > 0){
        const pricePerFraction = item["options"]["price"]/item["options"]["fractionValues"]
        finalPrice = parseInt(pricePerFraction * fractionInSale);
        
      }
      const requiredBalance = finalPrice+HASH_DEFAULT_GASFEE;
      if(userBalance < requiredBalance){
        Utils.showNotification("error", "Oopps!", "You do not have required Hashs to make this transaction.",3);
        return false;
      }
      
    confirm({
      title: 'Buy Hash - '+item["title"],
      icon: <ExclamationCircleOutlined />,
      content: `Your account will be dedcucted for ${finalPrice+HASH_DEFAULT_GASFEE} Hashs tokens.`,
      okText: "Confirm",
      async onOk() {
        const datapayload = {offer:{price:finalPrice+HASH_DEFAULT_GASFEE,type:item["options"]["type"],fractionInSale:fractionInSale},mintedBy:item["mintedBy"], hashId:item["hashId"],listingType:item["listingType"],listingEnddate:item["listingEnddate"]}
        const offerDetails = {mintedBy:item["mintedBy"],hashId:item["hashId"],listingType:item["listingType"],listingEnddate:item["listingEnddate"],options:item["options"]}
        console.log(offerDetails);
        
        // return;
        const data = await ApiService.PostApiCalls("keyhashes/confirm-offer",{offerDetails:offerDetails},true)
        
        if(data[0] && data[1]["IsSuccessful"]){
          setitem(data[1]["hash"])
          notification["success"]({
            message: 'Transaction successful, you now own '+item["title"]+".",
          });
        }
        else{
          notification["error"]({
            message: 'Request failed!',
          });
        }
        
      },
      onCancel() {},
    });
  }
}
  }
  const cancelCalled = (hash=null)=>{
    setshowModal(false);
    if(hash){
      setitem(hash)
    }
  }
  const updateSaleFractions = (f) =>{
    
    setfractionInSale(f)
  }
  return (
    <>
      {item && type === "sale"?(
        <div className="flex flex-1 justify-between">
          <div >
            <span className="flex space-x-3 items-center pn-2"><h6 className="mb-0">{`#${props.hash.title.replace(/ /g, "")}`}</h6><span className="text-xs">{` - `}<Link to={"/"+item.author[0]["userName"]}>{item.author[0]["name"]}</Link></span></span>
            <span className="text-xs text-muted px-1">
              {item.minted
                ? `Minted on ${moment
                    .unix(props.hash.updatedAt / 1000)
                    .format("MMM DD, YYYY ")}`
                : `Added on ${moment
                    .unix(props.hash.updatedAt)
                    .format("MMM DD, YYYY ")}`}
            </span>
            <div className="py-2"></div>
            {item.listingStatus === "processing" && (
              <div className="flex flex-row space-x-1 py-2 items-center">
                  <Clock /> 
                  <span className="font-semibold p-2 bg-gray-100">Sales request is in process</span></div>
            )}
            {item.listingStatus === "open" && !item.onSale?(
              
              item["options"]["type"] === "1"? <div className="flex flex-col  py-2 items-start">
                  <span className="flex flex-row">
                  <span className="font-semibold  bg-gray-100">Expected price - </span>
                  
              <span className="flex flex-row items-center ml-1">
              <HashIcon />
              <span className="flex-grow ml-1">{item["options"]["price"]}</span>
            </span></span>
            { item["options"]["fraction"] &&
              <span className="flex flex-row">
              <span className="font-semibold  bg-gray-100">Available Fractions - </span>
              
          <span className="flex flex-row items-center ml-1">
          
          <span className="flex-grow ml-1">{item["options"]["fractionValues"]}</span>
        </span></span>
            }
            <span className="flex flex-row text-xs mx-0 py-2">Gas Fees - <HashIcon /> {` ${HASH_DEFAULT_GASFEE}`}</span>
            <span className="text-xs text-muted py-2">
              {`Ends on ${moment
                    .unix(item.listingEnddate)
                    .format("MMM DD, YYYY ")}`}
            </span>
                  </div>
                  :
                  <div className="flex flex-col  py-2 items-start">
                  <span className="flex flex-row">
                  <span className="font-semibold  bg-gray-100">{item["options"]["type"] === "1"?"Expected price -":"Starting bid -"}</span>
                  
              <span className="flex flex-row items-center ml-1">
              <HashIcon />
              <span className="flex-grow ml-1">{item["options"]["price"]}</span>
            </span></span>
            <span className="flex flex-row text-xs mx-0 py-2">Gas Fees - <HashIcon /> {` ${HASH_DEFAULT_GASFEE}`}</span>
            <span className="text-xs text-muted py-2">
              {`Ends on ${moment
                    .unix(item.listingEnddate)
                    .format("MMM DD, YYYY ")}`}
            </span>
                  </div>
              
            ):<> <div className="flex flex-row space-x-1 py-2 items-center">
            <Clock /> 
            <span className="font-semibold p-2 bg-gray-100">Sale request is in process</span>
            {/* <Button
                  onClick={cancelSale}
                  className="px-3 brd-4 fw-700  rounded-lg hover:text-hs-pink hover:border-hs-pink"
                >
                  Cancel Sale
                </Button> */}
            </div></>}
            { item["options"]["fraction"] &&
              <span className="flex flex-row items-center mb-4">
              <span className="font-semibold  bg-gray-100">Select Fractions - </span>
              
          <span className="flex flex-row items-center ml-1">
          <InputNumber  defaultValue={item["options"]["fractionValues"]} max={item["options"]["fractionValues"]} min={1} onChange={updateSaleFractions} />
          
        </span></span>
            }
            {item.listingStatus === "open" && !item.onSale && (
              <div className="flex flex-row space-x-3 py-2">
                <Button
                  loading={processOn}
                  onClick={buyHash}
                  className="px-3 brd-4 fw-700  rounded-lg text-white bg-hs-pink  hover:border-hs-pink"
                >
                 { item["options"]["type"] === "1"?"Buy Now":"Bid Now"}
                </Button>
                {/* <Button className="px-3 brd-4 fw-700  rounded-lg hover:text-hs-pink hover:border-hs-pink">One</Button> */}
              </div>
            )}
          </div>
          {item.minted && (
            <Statistic
            // title="Idle"
            value={randomNumber < 0?-(randomNumber):randomNumber}
            precision={2}
            valueStyle={randomNumber < 0?{ color: "#cf1322" }:{ color: "#3f8600" }}
            prefix={randomNumber < 0?<ArrowDown />: <ArrowUp />}
            suffix="%"
          />
          )}
         
        </div>
      ):item && type === "rent"?(
        <div className="flex flex-1 justify-between">
          <div >
            <span className="flex space-x-3 items-center pn-2"><h6 className="mb-0">{`#${props.hash.title.replace(/ /g, "")}`}</h6><span className="text-xs">{` - `}<Link to={"/"+item.author[0]["userName"]}>{item.author[0]["name"]}</Link></span></span>
            <span className="text-xs text-muted px-1">
              {item.minted
                ? `Minted on ${moment
                    .unix(props.hash.updatedAt / 1000)
                    .format("MMM DD, YYYY ")}`
                : `Added on ${moment
                    .unix(props.hash.createdAt)
                    .format("MMM DD, YYYY ")}`}
            </span>
            <div className="py-2"></div>
           
            {item.listingStatus === "open" && (
              
              item["options"]["type"] === "1"? <div className="flex flex-col  py-2 items-start">
                 <span className="flex flex-row mb-2">
                  <span className="font-semibold  bg-gray-100">Deposit - </span>
                  
              <span className="flex flex-row items-center ml-1">
              {/* <Coin className="text-yellow-600" /> */}
              <HashIcon />
              <span className="flex-grow ml-1">{item["options"]["deposit"]?item["options"]["deposit"]:500}</span>
             
            </span></span>
                  <span className="flex flex-row">
                  <span className="font-semibold  bg-gray-100">Expected rent - </span>
                  
              <span className="flex flex-row items-center ml-1">
              <HashIcon />
              <span className="flex-grow ml-1">{item["options"]["price"]}</span>
              <span className="flex-grow ml-1"> per {item["options"]["rentOption"]}</span>
            </span></span>
            <span className="flex flex-row text-xs mx-0 py-2">Gas Fees - <HashIcon /> {` ${HASH_DEFAULT_GASFEE}`}</span>
            <span className="text-xs text-muted py-2">
              {`Avialable till ${moment
                    .unix(item.listingEnddate)
                    .format("MMM DD, YYYY ")}`}
            </span>
                  </div>
                  :
                  <div className="flex flex-col space-x-1 py-2 items-start">
                  <span className="flex flex-row">
                  <span className="font-semibold  bg-gray-100">{item["options"]["type"] === "1"?"Expected price -":"Starting bid -"}</span>
                  
              <span className="flex flex-row items-center ml-1">
              {/* <Coin className="text-yellow-600 " /> */}
              <HashIcon />
              <span className="flex-grow ml-1">{item["options"]["price"]}</span>
            </span></span>
            <span className="text-xs text-muted py-2">
              {`Avialable till ${moment
                    .unix(item.listingEnddate)
                    .format("MMM DD, YYYY ")}`}
            </span>
                  </div>
              
            )}
            {item.listingStatus === "open" && !item.onSale && (
              <div className="flex flex-row space-x-3 py-2">
                <Button
                  loading={processOn}
                  onClick={buyHash}
                  className="px-3 brd-4 fw-700  rounded-lg text-white bg-hs-pink  hover:border-hs-pink"
                >
                 Rent Now
                </Button>
                {/* <Button className="px-3 brd-4 fw-700  rounded-lg hover:text-hs-pink hover:border-hs-pink">One</Button> */}
              </div>
            )}
          </div>
          
         
        </div>
      ):item && (
        <div className="flex flex-1 justify-between">
          <div className="flex-grow">
            <h6>{`#${props.hash.title.replace(/ /g, "")}`}</h6>
            <span className="text-xs text-muted px-1">
              {item.minted && (["draft","processing","waiting"].includes(item.status))
                ? `Added on ${moment
                    .unix(props.hash.createdAt/1000)
                    .format("MMM DD, YYYY ")}`
                : `Minted on ${moment
                    .unix(props.hash.updatedAt)
                    .format("MMM DD, YYYY ")}`}
            </span>
            <div className="py-2"></div>
            {item.minted && (item.status === "processing" || item.status === "waiting"  || item.status === "draft") ?(
              <div className="flex flex-row space-x-1 py-2 items-center">
                  <Clock /> 
                  <span className="font-semibold p-2 bg-gray-100">{item.status === "draft"?"Awaiting approval":"In minting que"}</span></div>
            ):item.minted && item.status === "active" && !item.onSale?(
              <div className="flex flex-row space-x-3 py-2">
                <Button
                  onClick={() => props.callback("sale",item)}
                  className="px-3 brd-4 fw-700  rounded-lg hover:text-hs-pink hover:border-hs-pink"
                >
                  Sell
                </Button>
                <Button
                  onClick={() => props.callback("rent",item)}
                  className="px-3 brd-4 fw-700  rounded-lg hover:text-hs-pink hover:border-hs-pink"
                >
                  Rent Out
                </Button>
                {/* <Button  className="px-3 brd-4 fw-700  rounded-lg hover:text-hs-pink hover:border-hs-pink">Auction</Button> */}
                {!item.mentionAllowed?<Button
                  onClick={() => {setmodalEvent({event:"mention",item:item})
                  setshowModal(true)}}
                  className="px-3 brd-4 fw-700  rounded-lg hover:text-hs-pink hover:border-hs-pink"
                >
                  Allow Mentions
                </Button>:<Button type="danger"
                  onClick={cancelMentions}
                  className="px-3 brd-4 fw-700  rounded-lg hover:text-hs-pink hover:border-hs-pink"
                >
                  Cancel Mentions
                </Button>}
                {/* <Button className="px-3 brd-4 fw-700  rounded-lg hover:text-hs-pink hover:border-hs-pink">One</Button> */}
              </div>
            ):<> <div className="flex flex-row space-x-1 py-2 items-center">
            <Clock /> 
            <span className="font-semibold p-2 bg-gray-100">Sale request is live</span>
            <Button
                  onClick={cancelSale}
                  className="px-3 brd-4 fw-700  rounded-lg hover:text-hs-pink hover:border-hs-pink"
                >
                  Cancel Sale
                </Button>
            </div></>}
            {!item.minted && (
              <div className="flex flex-row space-x-3 py-2">
                <Button
                  loading={processOn}
                  onClick={() => {
                    mintHashes(true, item.title, 2);
                  }}
                  className="px-3 brd-4 fw-700  rounded-lg text-white bg-hs-pink  hover:border-hs-pink"
                >
                  Mint Now
                </Button>
                {/* <Button className="px-3 brd-4 fw-700  rounded-lg hover:text-hs-pink hover:border-hs-pink">One</Button> */}
              </div>
            )}
          </div>
          {item.minted && item.status === "active"  && (
         <span className="flex flex-row items-center"><Statistic
              // title="Idle"
              value={randomNumber < 0?-(randomNumber):randomNumber}
              precision={2}
              valueStyle={randomNumber < 0?{ color: "#cf1322" }:{ color: "#3f8600" }}
              prefix={randomNumber < 0?<ArrowDown />: <ArrowUp />}
              suffix="%"
            /></span>   
          )}
          {!item.minted && (
            <span className="flex flex-row items-center">
              <HashIcon />{" "}
              <span className="flex-grow ml-2">2</span>
             
            </span>
          )}
          <span className="rounded-md overflow-hidden mx-2">
            <img src={item["hashFrame"]} className="h-28" />
            </span>
        </div>
      )}
      {!item && <div></div>}
      {showModal && (
        <AddHash
          event={modalEvent}
          user={props.user}
          showModalFirst={showModal}
          callback={setshowModal}
          cancelCallBack={cancelCalled}
        />
      )}
    </>
  );
};

export default Hashtags;
