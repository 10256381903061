import React, { useEffect } from 'react';

const YouTubePlayer = ({ videoId }) => {
  useEffect(() => {
    // Load the YouTube IFrame Player API code asynchronously
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    let player;
    window.onYouTubeIframeAPIReady = () => {
      player = new window.YT.Player('player', {
        events: {
          'onReady': onPlayerReady,
          'onStateChange': onPlayerStateChange
        }
      });
    };

    const onPlayerReady = (event) => {
      // Event listener when the player is ready
      // You can perform actions here if needed
      console.log(event);
    };

    const onPlayerStateChange = (event) => {
      // Event listener for player state changes
      console.log(event.data , window.YT.PlayerState.PLAYING);
      if (event.data === window.YT.PlayerState.PLAYING) {
        player.setOption('controls', 1);
        // Show controls when the video starts playing
      }
    };

    // Clean up on unmount
    return () => {
      if (player) {
        player.destroy();
        delete window.onYouTubeIframeAPIReady;
      }
    };
  }, [videoId]);

  return (
    <div>
      <iframe
        id="player"
        type="text/html"
        width="100%"
        height="210"
        src={`https://www.youtube.com/embed/${videoId}?enablejsapi=1&autoplay=1&rel=0`}
        frameBorder="0"
        allowFullScreen
        title="Embedded YouTube Video"
      ></iframe>
    </div>
  );
};

export default YouTubePlayer;
