import React, { useEffect } from 'react'
import HashTag from './HashTag';
import { useState } from 'react';
import Utils from 'helper/Utils';
import ApiService from 'services/ApiService';
import _ from 'lodash';

const CardTags = ({tags,fractions,minted}) => {
    const [mitedIds, setmitedIds] = useState([])
    const [loading, setloading] = useState(false)
    useEffect(() => {
      
        // mapMitedTags()
    
      return () => {
        
      }
    }, [])
    const mapMitedTags = async() =>{
        let hashUpdates = [];
        tags.map((k) => {
         const id = Utils.getHashId(k, true, true);
            hashUpdates.push(id)
        })
       
        const addedKeyHashes = await ApiService.PostApiCalls("keyhashes/list",
        { hashKeys: hashUpdates },true);
        if (addedKeyHashes[0] && addedKeyHashes[1]["IsSuccessful"]) {
            setmitedIds(addedKeyHashes[1]["hash"]);
            setloading(false)
            
          } else {
            setloading(false)
            
          }
    }
  
    const renderTags = (tag, key) => {
        const tagText = tag.replace(/\s/g, "");
    
        if (tagText.length > 1) {
            const id = Utils.getHashId(tagText,true,true);
            const fid = _.filter(fractions, function(d){
              return d["hashId"] === id;
          })
          
            const mid = _.filter(_.map(minted,"hashId"), function(d){
                return d === id;
            })
            let color = "text-hs-blue";
            let link = null;
            if(fid[0]){
                link = fid[0]["affiliateLink"]
                color = "text-hs-pink";
            }
            else if(mid[0]){
              link = null
              color = "text-hs-pink";
          }
          
          return <HashTag key={key} link={link} tagText={tagText} color={color} />;
        }
      };

  return (
   !loading && <> {tags.map((tag, k) => renderTags(tag, k))} </>
  )
}

export default CardTags