import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const baseQuery = fetchBaseQuery({
	baseUrl: "/",
	prepareHeaders: (headers) => {
		const token = localStorage.getItem("token");
		headers.set("Authorization", `Bearer ${token}`);
	},
});
const baseQueryWithReauth = async (args, api, extraOptions) => {
	let result = await baseQuery(args, api, extraOptions);

	if (result && result?.error?.status === 404) {
		window.location.replace(window.location.origin);
		localStorage.clear();
	}
	return result;
};
export const hashsaga = createApi({
	reducerPath: "hashsaga",
	baseQuery: baseQueryWithReauth,
	endpoints: (builder) => ({
		login: builder.mutation({
			query: (data) => ({
				url: "user/login",
				method: "POST",
				body: data,
			}),
		}),
	}),
});

export const { useLoginMutation } = hashsaga;
