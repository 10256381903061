import { Row, Col, Typography, Modal, Button, Spin,notification,message, Input, Checkbox } from "antd";

import React, { useEffect, useState } from "react";

import _ from "lodash";
import ApiService from "services/ApiService";
import { Trash } from "react-bootstrap-icons";
const { Paragraph } = Typography;
const SettingsBlacklistPhrases = ({ user, activeWorkSpace }) => {
  const [viewloading, setViewLoading] = useState(true);
  const [list,setList] = useState([])
  const [loading, setLoading] = useState(false);
  const [newPhrase, setnewPhrase] = useState("")
  useEffect(() => {
    
    getDetails()
    return () => {
      
    }
  }, [])
  const getDetails = async()=>{
    const wid = activeWorkSpace["wid"]
    const listed = await ApiService.PostApiCalls("creator/phrases/list/"+wid,{})
    if(listed[0] && listed[1]["IsSuccessful"]){
      setList(listed[1]["phrases"])
    }

    setViewLoading(false)
  }
  const enterSubmit = async (e) =>{
    if(e.which === 13){
        
        //submitnew phrase
        const phrase = newPhrase.trim();
        if(phrase === ""){
            setnewPhrase("")
            notification["error"]({
              message: "Please enter valid phrase",
              description:""
            });
            return false;
        }
        else{
            
            const data = {
                wid:activeWorkSpace["wid"],
                match:true,
                phrase:phrase
            }
            const added = await ApiService.PostApiCalls("creator/phrases/add",data)
            if(added[0] && added[1]["IsSuccessful"]){
              setnewPhrase("")
              notification["success"]({
                message: added[1]["FriendlyMessage"],
                description:""
              });
              getDetails();
            }
            else{
              notification["error"]({
                message: "Try again!",
                description:"Error while adding phrase."
              });
            }

        }
    }
    
}
const updatePhrase = async(action,value,id,current) =>{
  const data = {
      wid:activeWorkSpace["wid"],
      status:action === "remove" ? "deleted":"active",
      match:action === "match" ? value:current,
      pid:id
  }
  const res = await ApiService.PostApiCalls('creator/phrases/update/'+id,{update:data});
  if(res[0] && res[1]["success"]){                 
      setList(res[1]["phrases"])
  }
}
  if(viewloading){
    return (
      <>
      <Col span={24} className="py-0 px-0 mb-0">
        <div className="bg-white p-3 px-3 flex space-x-3 items-center justify-between">
          <span className="font-bold">Blacklist Phrases</span>
          <Button
            
            type="primary"
            className=" rounded  float-right mr-3"
            loading={loading}
          >
           
          </Button>
        </div>
      </Col>
      <div className="pb-2 bg-gray-100"></div>
      <Col span={24} className="py-0 px-0 mb-0 h-screen flex items-center justify-center">
      <Spin />
      </Col>
      </>
    )
  }
  return (
    <> <Col span={24} className="py-0 px-0 mb-0">
    <div className="bg-white p-3 px-3 flex space-x-3 items-center justify-between">
      <span className="font-bold">Blacklist Phrases</span>
      <Button
         
        type="primary"
        className=" rounded  float-right mr-3"
        loading={loading}
      >
         
      </Button>
    </div>
  </Col>
  <div className="pb-2 bg-gray-100"></div>
  <Col span={24} className="">
  <Row className="p-2">
  <Col span={24} className="p-2">
            <Paragraph className="text-sm text-hs-dark">
            Despite how intelligent our algorithms are, we may occasionally draw in stuff that isn't part of your blog's article. Adding terms to your blacklist will assist us in determining what we should overlook. When your next post is processed, any modifications you make will be taken into consideration.
            </Paragraph>
          </Col>
          <Col className="px-0 pr-4 mt-2 " span={24}>
          <Row className="p-2">
          <Col className="px-0 pr-4 mt-4 " span={16}>
          <Input className="p-3 rounded-md" value={newPhrase} onChange={(e) => setnewPhrase(e.target.value)} placeholder="Enter a word or phrase and then press enter" onKeyPress={(e) => enterSubmit(e)}/>
          </Col>
          <Col className="px-0 pr-4 mt-4 " span={24}>
          {
                list.length > 0 && 
                <Col className="px-0 fz-12 p-0 mt-2 overflow-hidden border rounded-lg bg-gray-100"
             span={20} >
             <Row className="mx-0 p-3 bg-blue-500 text-white font-semibold">
                 <Col className="px-0 fz-16  fw-700" span={12} >
                    Word or phrase
                 </Col>
                 <Col className="px-0 fz-16  fw-700"  span={6} >
                Exact match?
                 </Col>
                 <Col className="px-0 fz-16  fw-700"  span={6} >

                 </Col>
             </Row>
                {
                    list.map((p,k) =>(
                        <Row className="mx-0 p-2 border-bottom bg-gray-100" key={k}>
                 <Col className="px-0 fz-14"  span={12} >
                        <span  className="w-100 float-left fw-600">{p["phrase"]}</span></Col>
                        <Col className="px-0 fz-14 text-center pr-2"  span={6} >
                <Checkbox defaultChecked={p["match"]} onChange={(e) => updatePhrase("match",e.target.checked,p["pid"],p["match"])}/>
                 </Col>
                 <Col className="px-0 fz-14  text-right pr-2"  span={6} >
    <Trash color={"#ff5280"} className="cursor-pointer" onClick={(e) => updatePhrase("remove",e.target.checked,p["pid"],p["match"])}/>
                 </Col></Row>
                    ))
                }
                <Row className="mx-0 p-3 "></Row>
             </Col>
             }
          </Col>
          <Col className="px-0 pr-4 mt-4 " span={24}></Col>

          </Row>
          
          </Col>
  </Row>
  </Col>
  </>
  )
}

export default SettingsBlacklistPhrases