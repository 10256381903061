import React from 'react'
import SwipeCards from './SwipeCards'
import { Col, Spin,notification } from 'antd'
import { useState } from 'react'
import { useEffect } from 'react'
import ApiService from 'services/ApiService'

const Web3Datings = () => {
  const [loading, setloading] = useState(true)
  const [profilesDatings, setprofilesDatings] = useState([])
  useEffect(() => {
    fetchList()
  
    return () => {
      
    }
  }, [])
  const fetchList = async()=>{
    const profileslist = await ApiService.PostApiCalls( "dating-profiles/list",{},false)
    if (profileslist[0] && !profileslist[1]["IsSuccessful"]) {
      notification["error"]({
        message: "Try again",
        description:""
      });
    }
    else{
      console.log(profileslist[1]["list"]);
      setprofilesDatings(profileslist[1]["list"])
    }
    setloading(false)
  }
  
  return (
    <Col lg={24} className='flex px-0 overflow-hidden justify-center h-full'>
    {loading? <><Spin /></>:<SwipeCards data={profilesDatings}/>}
    </Col>
  )
}

export default Web3Datings