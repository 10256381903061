
import { Row, Col, Typography, Modal, Button, Spin,notification,message } from "antd";

import React, { useEffect, useState } from "react";

import _ from "lodash";
import ApiService from "services/ApiService";
import ScheduleComp from "./comps/ScheduleComp";
const { Paragraph } = Typography;
const SettingsScheduleTemplates = ({ user, activeWorkSpace }) => {
  const [viewloading, setViewLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [defaultData,setData] = useState(null)
  const [activeTab,setactiveTab] = useState(0);
  const [createNew,setCreateNew] = useState(false);
  const [isSaving,setSaving] = useState(false)

  useEffect(() => {
    
    getDetails()
    return () => {
      
    }
  }, [])
  const getDetails = async()=>{
    //creator/scheduletemplates/custom/list
    const wid = activeWorkSpace["wid"];
    const listed = await ApiService.PostApiCalls("creator/scheduletemplates/custom/list",{wid:wid},true)
    if(listed[0] && listed[1]["IsSuccessful"]){
      if(listed[1]["templates"].length === 0){
        const listedAll = await ApiService.PostApiCalls("creator/scheduletemplates/list",{},true)
        if(listedAll[0] && listedAll[1]["IsSuccessful"]){
          setData(listedAll[1]["templates"])

        }
      }
      else{
        setData(listed[1]["templates"])
      }
     
    }
    setViewLoading(false)
  }
  const SaveNew = async(newdata,ikey) =>{
    setSaving(true)
    const wid = activeWorkSpace["wid"];
    let olddata = defaultData;
    olddata[defaultData.length] = newdata
    const data = {
        userId:user["uniqueId"],
        wid:wid,
        timeData:olddata
    }
    const listed = await ApiService.PostApiCalls("creator/scheduletemplates/update",data)
      if(listed[0] && listed[1]["IsSuccessful"]){
        notification["success"]({
          message: "Details have been updated successfully",
          description:""
        });
      }
      else{

      }
      setSaving(false)
  }
  const SaveOld = async (newdata,key) =>{
      const wid = activeWorkSpace["wid"];
      setSaving(true)
      let olddata = defaultData;
      olddata[key] = newdata;
      const data = {
        userId:user["uniqueId"],
        wid:wid,
        timeData:olddata
      }
      const listed = await ApiService.PostApiCalls("creator/scheduletemplates/update",data)
      if(listed[0] && listed[1]["IsSuccessful"]){
        notification["success"]({
          message: "Details have been updated successfully",
          description:""
        });
      }
      else{

      }
      setSaving(false)

    }
  if(viewloading){
    return (
      <>
      <Col span={24} className="py-0 px-0 mb-0">
        <div className="bg-white p-3 px-3 flex space-x-3 items-center justify-between">
          <span className="font-bold">Schedule Templates</span>
          <Button
            
            type="primary"
            className=" rounded  float-right mr-3"
            loading={loading}
          ></Button>
        </div>
      </Col>
      <div className="pb-2 bg-gray-100"></div>
      <Col span={24} className="py-0 px-0 mb-0 h-screen flex items-center justify-center">
      <Spin />
      </Col>
      </>
    )
  }
  return (
    <> <Col span={24} className="py-0 px-0 mb-0">
    <div className="bg-white p-3 px-3 flex space-x-3 items-center justify-between">
      <span className="font-bold">Schedule Templates</span>
      <Button
         
        type="primary"
        className=" rounded  float-right mr-3"
        loading={loading}
      ></Button>
    </div>
  </Col>
  <div className="pb-2 bg-gray-100"></div>
  <Col span={24} className="">
  <Row className="p-2">
  <Col span={24} className="p-2">
            <Paragraph className="text-sm text-hs-dark">
            A Hash Saga campaign, by default, lasts 12 months and consists of 9 social posts. You may adjust campaign lengths, post frequency, and auto repeat rules here, as well as change and create new templates.
            </Paragraph>
          </Col>
          <Col className="px-0 pr-4 mt-4 " span={24}>
          <Row className="mx-0 mt-4">
                <Col span={6} className="settings pl-0">
        <div onClick={() => setCreateNew(true)} className={"px-3 py-2 my-2 mb-4 cursor-pointer brand-menu active"}>Create new Schedule</div>
        {
            defaultData && defaultData.map((sc,key)=>(
                <div key={key} onClick={() => {setCreateNew(false); setactiveTab(key)}} className={!createNew && activeTab === key ? "px-3 py-2 my-2 cursor-pointer brand-menu active":"px-3 py-2 my-2 cursor-pointer brand-menu"}>{sc["name"]}</div>
            ))
        }
        
        
        </Col>
        <Col span={18} className="px-2 pr-4 pt-3">
           <div className="ScheduleTemplatesBox p-4">
           {createNew && <ScheduleComp saving={isSaving} newpost={true} callBack={SaveNew}  data={null}/>}
           
           {!createNew && defaultData && <ScheduleComp saving={isSaving}  callBack={SaveOld} data={defaultData[activeTab]} akey={activeTab} newpost={false}/>}
           
           </div>
           
        </Col>
             </Row>
          </Col>
  </Row>
  </Col>
  </>
  )
}

export default SettingsScheduleTemplates