
import { Button, Divider, Form, Input, notification,Radio,DatePicker, Tag } from 'antd';
import moment from 'moment';
import React from 'react'
import { useState } from 'react';
import { XCircle } from 'react-bootstrap-icons';
import ApiService from 'services/ApiService';

const DatingProfileForm = ({info}) => {
    const [passtoken, setpasstoken] = useState("second")
    const [hasError, sethasError] = useState(null)
    const [apiError, setapiError] = useState(null)
    const [isSaving, setisSaving] = useState(false);
    const [keyentered, setkeyentered] = useState("");
    const [keywordsList, setkeywordsList] = useState(info.keywords?info.keywords:[])
    const [donstr, setdonstr] = useState()
    
    const [form] = Form.useForm();
    const enterSubmit = (e) =>{
        if(e.which === 13){
        e.preventDefault();
        const phrase = e.target.value.trim();
        if(phrase === ""){
            setkeyentered("")
            notification["error"]({
              message: "Please enter valid keyword",
              description:""
            });
            return false;
        }
        else{
            const phrase = e.target.value.trim();
            let added = keywordsList;
            
            if(added.includes(phrase)){
                notification["error"]({
                    message: "Keyword already in list",
                    description:""
                  });
            }
            else{
                setkeyentered("")
                added.push(phrase)
                setkeywordsList(added)
            }
        }

            
            return false;
        }
    }
    const onChange = (date, dateString) => {
        setdonstr( dateString);
      };
    const onFinish = async(values) => {
        console.log(values);
        
        if(keywordsList.length === 0){
            notification["error"]({
                message: "Please add atleast one keyword",
                description:""
              });
              return false
            }
        setisSaving(true)
        const saved  = await ApiService.PostApiCalls(
            "dating-profiles/update",
            {bio:values.bioinfo,location:values.location,name:values.name,dob:donstr,gender:values.gender,keywords:keywordsList},
            true
          );
          if (saved[0] && !saved[1]["IsSuccessful"]) {
            notification["error"]({
                message: saved[1]["ErrorMessage"],
                description:""
              });
          }
          else{
            
            notification["success"]({
                message: "Details saved",
                description:""
              });
            
            //   
          }
          setisSaving(false)
      };
      const onFinishFailed = (errorInfo) => {
        
      };
      const removeTag = ()=>{

      }
      const renderTags = (tag,key) =>{
        
        const tagText = tag.replace(/\s/g,'');
        
        if(tagText.length > 1){
            return <Tag key={key} closable onClose={(e) =>removeTag(e,key)} className="tags-added mb-3 d-flex align-items-center" closeIcon={<XCircle color={"#ef4d74"} size={13} /> } ><span>{tagText}</span></Tag>
        }
        
    }
    const disabledDate = (current) => {
        // Can not select days before today and today new Date('2021-01-01')
        return current && current > moment(new Date('01-01-2005'), 'DD/MM/YYYY');//moment().startOf(new Date('2021-01-01'));
      };
  return (
    <Form name="basic" form={form}
    labelCol={{
      span: 4,
    }}
    wrapperCol={{
      span: 16,
    }}
    initialValues={{
      remember: true,
      name:info.name,
      bioinfo:info.bio?info.bio:"",
      website:info.website?info.website:"",
      location:info.location?info.location:"",
    }}
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    autoComplete="off">
      {passtoken &&  <> <div className="p-4 w-100">
        <div className=" w-100">
           
        <Form.Item
      label="Display Name"
      name="name"
      rules={[
        {
          required: true,
          message: 'Name field is required!',
        }
      ]}
    ><Input  name="name" maxLength={50} placeholder="Display Name" className="p-2 rounded-md mb-1" />
        </Form.Item>
        <div className="py-2"></div>
        <Form.Item
      label="Bio"
      name="bioinfo"
      
    ><Input.TextArea  name="bioinfo"  placeholder="Bio" className="p-2 rounded-md mb-1" />
        </Form.Item>
        <div className="py-2"></div>
        <Form.Item
      label="Date of Birth"
      name="dob"
      rules={[
        {
            required: true,
            message: 'Birth date field is required!',
          },
      ]}
    ><DatePicker
    onChange={onChange}
    defaultValue={moment(new Date(info.dob?info.dob:'01-01-2005'), 'DD/MM/YYYY')}
    disabledDate={disabledDate}
    format={'DD/MM/YYYY'}
    style={{
      width: '100%',
    }}
  />
        </Form.Item>
        <div className="py-2"></div>
        <Form.Item
        name="gender"
        label="Gender"
        rules={[
          {
            required: true,
            message: 'Gender field is required!',
          },
        ]}
      >
        <Radio.Group name='gender' defaultValue={info.gender} value={info.gender}>
          <Radio.Button value="male">Male</Radio.Button>
          <Radio.Button value="female">Female</Radio.Button>
          <Radio.Button value="transgender">Transgender</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <div className="py-2"></div>

        <Form.Item
      label="Location"
      name="location"
      rules={[
       
      ]}
    ><Input  name="location"  placeholder="Location" className="p-2 rounded-md mb-1" />
        </Form.Item>
        <div className="py-2"></div>

<Form.Item
label="Keywords"
name="keywords"
rules={[

]}
>
<div className="d-flex w-100 flex-grow-1 align-items-center flex-wrap" >
            {keywordsList.length > 0 && keywordsList.map((tag,key) =>
                
                renderTags(tag,key)
        
            )}
            {keywordsList.length < 5 && <Input type="text"  value={keyentered} onChange={(e)=>setkeyentered(e.target.value)}  placeholder="Enter keyword and space enter" onKeyDown={(e) => enterSubmit(e)} className="p-2 rounded-md mb-1" />}</div>
</Form.Item>
        </div>
    </div>
    
    </>}
    
   
    
    <Divider className="my-2 px-0" />
    <div className="px-6 w-100 flex justify-between items-center">
       <span className="text-danger font-semibold" >{apiError}</span>
        <Button type="primary" htmlType="submit" size="large"  loading={isSaving}  className={"rounded-md bg-hs-pink border-white text-white"} >Save</Button>
        </div>
        </Form>
  )
}

export default DatingProfileForm