import { Steps, Row, Col, Form, Input, Button, Select,Upload, Spin, Checkbox, Divider } from "antd";
import FacebookAuth from "components/socialauths/FacebookAuth";
import InstaAuth from "components/socialauths/InstaAuth";
import LinkedinAuth from "components/socialauths/LinkedinAuth";
import TwitterAuth from "components/socialauths/TwitterAuth";
import TemplateView from "components/TemplateView";
import { Modal } from "react-bootstrap";
import Utils from "helper/Utils";
import _ from "lodash";
import { loadStripe } from "@stripe/stripe-js";
import {useStripe, useElements, Elements,CardElement} from '@stripe/react-stripe-js';
import React, { useState, useEffect,useContext } from "react";
import { CheckCircle, Gift, TrashFill } from "react-bootstrap-icons";
import ApiService from "services/ApiService";
import { startUpload, UploadSingle } from "services/FileUpload";
import OverLayIcon from "assets/images/overlay-icon.png";
import { Link,  useLocation,  useNavigate,useSearchParams } from "react-router-dom";
import CardInput from "container/common/CardInput";
import PaymentCard from "container/common/PaymentCard";
// import { t } from "i18next";
import { useTranslation } from 'react-i18next';
import { AuthContext } from "app/AuthProvider";

const { Step } = Steps;
const useDebounce = (value, delay) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount) 
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
};
const CreatorEdit = (props) => {
  const navigate = useNavigate();
  const brLocation = useLocation();
  const brSearch = useSearchParams();
  const {setAuthUser,setactiveWorkSpace} = useContext(AuthContext)

  let [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const appLocale = localStorage.getItem("appLocale")
  let setDefault = "en";
  if(appLocale){
    setDefault = appLocale
  }
  //pk_test_51LDMFTSCziW5a8WnhKCt1U2sphsESJduhEOM9uU8xyjBjnRw0NGOtfkpztKQ3w5JAQhQQOlOFGROKmgUCrazjQ6a00cGdGsLwr
  const stripePromise = loadStripe("pk_live_51LDMFTSCziW5a8WnrdxtE3x0EZ4Ls0AWMSG81PiHJEpekq14XMP4kA0LIlriDJYXy2e1JjubKETbDgfSTcuEnA1Z00SL3BpanT",{locale: setDefault});
  // const stripePromise = loadStripe("pk_test_51LDMFTSCziW5a8WnahZL7oy2kpBCIdxFQVcwC1U0yDU7Ak7J0aclUaVHAlqsMncC5fDl6WyLmFiuq02j5pW9Gwv800V30n2tKD",{locale: setDefault});
  const user = Utils.getLocalInfo("userhash");
  const [form] = Form.useForm();
  const [currentStep, setcurrentStep] = useState(user?user["creatorProfileStep"]:"login");
  // const [userURL, setuserURL] = useState(user.userName);
  const [savingForm, setsavingForm] = useState(false);
  const [urlValidateStatus, seturlValidateStatus] = useState("success");
  const [redrictRef, setredrictRef] = useState(null)
  const [urlHelpTips, seturlHelpTips] = useState("");
  const [nextDisabled, setnextDisabled] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState([]);
  const [selectedProfileIds, setSelectedProfileIds] = useState([]);
  const [socialConnectionType, setSocialConnectionType] = useState(0);
  const [profileError, setprofileError] = useState(null);
  const [isUploading, setisUploading] = useState(false);
  const [progressCount, setProgressCount] = useState(0);
  const [uploadedCount, setuploadedCount] = useState(0);
  const [workspaceInfo, setWorkspaceInfo] = useState(null);
  const [brandImage, setBrandImage] = useState(
    selectedProfile[0] ? selectedProfile[0]["fb-picture"] : null
  );
  const [themeColor, setThemeColor] = useState("#CA44FF");
  const [loading, setloading] = useState(true);
  const [show, setShow] = useState(false);
  const [activeWp, SetActiveWP] = useState("");
  const [category, setcategory] = useState(null)
  const [subcategory, setsubcategory] = useState(null)
  const [selectedTemplate, setSelectedTemplate] = useState(2);
  const [termsChecked, settermsChecked] = useState(false)
  const [library, setLibrary] = useState([]);
  const [selectedPlan,setPlan] = useState({code:"starter",id:1,duration:"yearly"});
  const [onboardUser, setonboardUser] = useState(null)
  const planPrice = {
    "monthly":{2:20,3:45,4:125,5:200,1:10,0:10},
    "yearly":{2:204,3:459,4:1275,5:2040,1:102,0:102},
  }
  
  useEffect(() => {
    setredrictRef(searchParams.get("redirect"))
    if(user){
      
      checkOnBoarding();
      if(brLocation.search !== ""){     
       
        if(brLocation.search.split("?")[1].indexOf("plan=") !== -1){
          const details = brLocation.search.split("?")[1].replace("plan=", "").split("-");
          setPlan({
            code:details[0],
            id:parseInt(details[2]),
            duration:details[1]
          })
        }
      }
    }
    else{
      
     if(brLocation.search !== ""){     
      
     
      
      if(brLocation.search.split("?")[1].indexOf("plan=") !== -1){
        const details = brLocation.search.split("?")[1].replace("plan=", "").split("-");
        setPlan({
          code:details[0],
          id:parseInt(details[2]),
          duration:details[1]
        })
        setcurrentStep("start")
      }
    }
      setloading(false)
    }
    
    return () => {};
  }, []);
  const handleClose = () => {
    setShow(false);
    // props.callback(false)
  };
  const checkOnBoarding = async () => {
    const obData = await ApiService.PostApiCalls(
      "creator/setup/verifySetup",
      {},
      true
    );
    
    if (obData[0] && obData[1]["IsSuccessful"]) {
      const userInfo = obData[1]["user"];
      setonboardUser(userInfo)
      setcurrentStep(userInfo["creatorProfileStep"]);
      if (userInfo["creatorProfileStep"] === 0) {
        setcurrentStep(userInfo["creatorProfileStep"]);
        if(userInfo["selectedAppPlan"]){
          setPlan(userInfo["selectedAppPlan"])
        }
        setloading(false);
      } else if (userInfo["creatorProfileStep"] === 1) {
        setcurrentStep(userInfo["creatorProfileStep"]);
        if(userInfo["selectedAppPlan"]){
          setPlan(userInfo["selectedAppPlan"])
        }
        setloading(false);
      } else if (userInfo["creatorProfileStep"] >= 2) {
        setWorkspaceInfo(userInfo["workspaces"][0]);
        setSelectedProfile(userInfo["socialchannels"]);
        if(userInfo["selectedAppPlan"]){
          setPlan(userInfo["selectedAppPlan"])
        }
        setBrandImage(
          userInfo["socialchannels"][0]
            ? userInfo["socialchannels"][0]["fb-picture"]
            : null
        );
        setcurrentStep(userInfo["creatorProfileStep"]);
        if (userInfo["creatorProfileStep"] === 3) {
          getImageTemplates();
        } else {
          setloading(false);
        }
      } else {
      }
    } else {
      

      setloading(false);
      setuploadedCount(0);
    }
  };
  const getImageTemplates = async () => {
    const templates = await ApiService.PostApiCalls(
      "creator/setup/getImageTemplates",
      { filter: {} },
      true
    );
    setLibrary(templates[1]["templates"]);
    setloading(false);
  };
  const onFinish = async (values) => {
    setsavingForm(true);
    const modein = localStorage.getItem("appUsageMode")
    if(currentStep === "login"){
      
      const formContent = {
        role: "creator",
        name: values.name,
        email: values.loginemail.toLowerCase(),
        rememberSession: values.remember,
        password: values.loginpassword,
        accountSource: "Email",
        appMode:modein?modein:"earning"
      };
      const response = await ApiService.PostApiCalls("account/login", formContent);
      if (response[0]) {
        const serverRespons = response[1];
        if (serverRespons["IsSuccessful"]) {
          Utils.processAuthIn( serverRespons["user"]);
          Utils.showNotification(
            "success",
            "Done",
            serverRespons["FriendlyMessage"]
          );
          // setcurrentStep(0)
          if(redrictRef){
            const v = await ApiService.PostApiCalls("account/veriyAuth",{token:serverRespons["user"].token},true);
            if(v[0] && v[1]["IsSuccessful"]){
              const currentUser = v[1]["user"];
              setAuthUser(v[1]["user"])
              
              const activeWorkSpace = v[1]["user"]["workspace"][0]?v[1]["user"]["workspace"][0]:null
              setactiveWorkSpace(v[1]["user"]["workspace"][0]?v[1]["user"]["workspace"][0]:null)
              navigate("/"+redrictRef)

            }
            else{
              Utils.showNotification(
                "error",
                "Oooops",
                serverRespons["ErrorMessage"]
              );
            }
          }
          else{
            window.location.reload();
          }
          
          
        } else {
          Utils.showNotification(
            "error",
            "Oooops",
            serverRespons["ErrorMessage"]
          );
        }
      } else {
        Utils.showNotification(
          "error",
          "Oooops",
          "Error while processing request"
        );
      }
    }
    else if(currentStep === "start"){
      const formContent = {
        role: "creator",
        name: values.firstname+" "+values.lasttname,
        firstName: values.firstname,
        lastName: values.lasttname,
        email: values.loginemail.toLowerCase(),
        rememberSession: values.remember,
        password: values.loginpassword,
        accountSource: "Email",
        selectedAppPlan:selectedPlan,
        appMode:modein?modein:"earning"
      };
      
      const response = await ApiService.PostApiCalls("account/register", formContent);
      if (response[0]) {
        const serverRespons = response[1];
        if (serverRespons["IsSuccessful"]) {
          Utils.processAuthIn( serverRespons["user"]);
          Utils.showNotification(
            "success",
            "Done",
            serverRespons["FriendlyMessage"]
          );
          // setcurrentStep(0)
          window.location.reload();
          
        } else {
          Utils.showNotification(
            "error",
            "Oooops",
            serverRespons["ErrorMessage"]
          );
        }
      } else {
        Utils.showNotification(
          "error",
          "Oooops",
          "Error while processing request"
        );
      }
    }
    else if (currentStep === 0) {
      const data = {
        userName: values.usernameurl,
        profileDisplayName: values.profilePagename,
        creatorCategoryText: values.topicson === "Other"?values.topicsonOther:values.topicson,
        creatorSubcategoryText: values.subtopicson === "Other"?values.subtopicsonOther:values.subtopicson,
        selectedAppPlan:selectedPlan,
        role:"creator",
        creatorProfileStep: currentStep + 1,
      };
      const formsaved = await ApiService.PostApiCalls(
        "creator/setup",
        data,
        true
      );
      if (formsaved[0] && formsaved[1]["IsSuccessful"]) {
        Utils.updateLocalInfo("userhash", { ...user, creatorProfileStep: 1 });
        setcurrentStep(1);
      } else if (formsaved[0] && !formsaved[1]["IsSuccessful"]) {
        Utils.showNotification("error", "Oops", formsaved[1]["ErrorMessage"]);
      } else {
        Utils.showNotification(
          "error",
          "Oops",
          "Something went wrong. Try again"
        );
      }
    } else if (currentStep === 1) {
      setprofileError(null);

      if (selectedProfile.length === 0) {
        setprofileError(t("step2SocialValidation"));
        setsavingForm(false);
        return false;
      }
      //AddHashChannel
      const selected = {
        "name": user["name"],
        "id": "feed-"+user["uniqueId"],
        is_published:true,
        source:"Feed",
        uid:user["uniqueId"],
        status:1,
        "fb-picture":"https://yorktonrentals.com/wp-content/uploads/2017/06/usericon.png"
      }
      const addFeedProfile = await ApiService.PostApiCalls(
        "creator/setup/addSocialProfile",
        { profile: selected },
        true
      );
      if(addFeedProfile[0]){
      const data = {
        name: values.wsname,
        link: values.wslink,
        selectedProfile,
        selectedProfileIds,
      };
      const formsaved = await ApiService.PostApiCalls(
        "creator/setup/createWorkSpace",
        data,
        true
      );
      if (formsaved[0] && formsaved[1]["IsSuccessful"]) {
        Utils.updateLocalInfo("userhash", { ...user, creatorProfileStep: 2 });
        setWorkspaceInfo(formsaved[1]["workspace"]);
        setBrandImage(
          selectedProfile[0] ? selectedProfile[0]["fb-picture"] : null
        );
        setcurrentStep(2);
      } else if (formsaved[0] && !formsaved[1]["IsSuccessful"]) {
        Utils.showNotification("error", "Oops", formsaved[1]["ErrorMessage"]);
      } else {
        Utils.showNotification(
          "error",
          "Oops",
          "Something went wrong. Try again"
        );
      }
    }
    } else if (currentStep === 2) {
      const data = {
        update: {
          authorName: values.brandauthor,
          companyName: values.brandname,
          themeColor: themeColor,
          brandImage: brandImage,
        },
        nextStep: 3,
        wid: workspaceInfo["wid"],
      };
      const formsaved = await ApiService.PostApiCalls(
        "creator/setup/updateWorkSpace",
        data,
        true
      );
      if (formsaved[0] && formsaved[1]["IsSuccessful"]) {
        Utils.updateLocalInfo("userhash", { ...user, creatorProfileStep: 3 });
        setWorkspaceInfo({
          ...workspaceInfo,
          authorName: values.brandauthor,
          companyName: values.brandname,
          themeColor: themeColor,
          brandImage: brandImage,
        });
        setloading(true);
        getImageTemplates();
        setcurrentStep(3);
      } else if (formsaved[0] && !formsaved[1]["IsSuccessful"]) {
        Utils.showNotification("error", "Oops", formsaved[1]["ErrorMessage"]);
      } else {
        Utils.showNotification(
          "error",
          "Oops",
          "Something went wrong. Try again"
        );
      }
    } else if (currentStep === 3) {
      setprofileError(null);

      if (!selectedTemplate) {
        setprofileError(t("step2SocialValidation"));
        setsavingForm(false);
        return false;
      }
      const data = {
        update: { brandDefaultTemplate: selectedTemplate },
        wid: workspaceInfo["wid"],
        nextStep: selectedPlan["code"] === "starter"?6:5,
      };
      
      const formsaved = await ApiService.PostApiCalls(
        "creator/setup/updateWorkSpace",
        data,
        true
      );
      if (formsaved[0] && formsaved[1]["IsSuccessful"]) {
        Utils.updateLocalInfo("userhash", { ...user, creatorProfileStep: selectedPlan["code"] === "starter"?6:5 });
        if(selectedPlan["code"] === "starter"){
          setShow(true);
        }
        else{
          setloading(true);
          getImageTemplates();
          setcurrentStep(5);
        }
       
      } else if (formsaved[0] && !formsaved[1]["IsSuccessful"]) {
        Utils.showNotification("error", "Oops", formsaved[1]["ErrorMessage"]);
      } else {
        Utils.showNotification(
          "error",
          "Oops",
          "Something went wrong. Try again"
        );
      }
    }
    setsavingForm(false);
  };

  const onFinishFailed = (errorInfo) => {
    if (selectedProfile.length === 0) {
      setprofileError(t("step2SocialValidation"));
    }
  };
  const verifyURL = async (e) => {
    setnextDisabled(false);
    const data = {
      url: e.target.value,
      email: user.email,
    };
    if (data.url.trim() !== "") {
      const verfied = await ApiService.PostApiCalls(
        "creator/setup/verifyURL",
        data,
        true
      );
      if (verfied[0] && !verfied[1]["IsSuccessful"]) {
        seturlValidateStatus("error");
        seturlHelpTips(verfied[1]["ErrorMessage"]);
        setnextDisabled(true);
      } else {
        seturlValidateStatus("success");
        seturlHelpTips("");
        setnextDisabled(false);
      }
    }
  };
  const getConnectionResponse = async (selected, all, channel) => {
    if (channel === "Facebook") {
      const isThere = _.filter(selectedProfile, function(time, i) {
        return time["id"] === selected["id"];
      });

      if (isThere.length === 0) {
        let profiles = [...selectedProfile, selected];
        let profilesId = [...selectedProfileIds, selected["id"]];
        setSelectedProfile(profiles);
        setSelectedProfileIds(profilesId);
        setSocialConnectionType(1);
      }
    } else if (channel === "Twitter") {
      let profiles = [...selectedProfile, selected];
      let profilesId = [...selectedProfileIds, selected["id"]];
      setSelectedProfile(profiles);
      setSelectedProfileIds(profilesId);
      setSocialConnectionType(2);
    } else {
      let profiles = [...selectedProfile, selected];
      let profilesId = [...selectedProfileIds, selected["id"]];
      setSelectedProfile(profiles);
      setSelectedProfileIds(profilesId);
      setSocialConnectionType(3);
    }
    await ApiService.PostApiCalls(
      "creator/setup/addSocialProfile",
      { profile: selected },
      true
    );
  };
  const removeLink = async (k, id) => {
    const profiles = _.remove(selectedProfile, function(time, i) {
      return i !== k;
    });

    const profilesId = _.remove(selectedProfileIds, function(time, i) {
      return time !== id;
    });
    setSelectedProfile(profiles);
    setSelectedProfileIds(profilesId);
  };
  const updateProgressBar = () => {};
  const UploadFinish = (value, name, type, cid, uid) => {
    let imagepath =
      "https://hs-container.s3.ap-south-1.amazonaws.com/" + name;
    setBrandImage(imagepath);
    setisUploading(false);
  };
  const UploadFail = () => {};
  const customUploadRequest = (e) => {
    const UploadState = startUpload(e.file.size);
    if (UploadState === "single") {
      setisUploading(true);
      setProgressCount(uploadedCount + 1);
      UploadSingle(
        e,
        updateProgressBar,
        UploadFinish,
        UploadFail,
        progressCount,
        "",
        "hash/accounts/authorimages"
      )
        .then((res) => {})
        .catch((error) => {});
    }
  };
  const validation = (rule,value,callback)=>{
    if(termsChecked){
      return callback()
    }
    else{
      return callback(t("TACAccepted"))
    }
  }
  
  const paymentCallback = async(card,paymentMethodId,cardName,cpData=null,skip=false)=>{
    if(skip){

      Utils.updateLocalInfo("userhash", { ...user, creatorProfileStep: 6 });
      setShow(true);
      return false;
    }
    const subduration = onboardUser["selectedAppPlan"]["duration"]
    const plantoSuscribe = subduration ==="yearly"?onboardUser["subdetails"][0]["price"]["stripe_product_id_y"]:onboardUser["subdetails"][0]["price"]["stripe_product_id_m"];
    const datain ={'payment_method': paymentMethodId, cardName:cardName,'email':onboardUser["email"],planid:plantoSuscribe,discountApplied:cpData?true:false,couponCode:cpData?cpData["id"]:null}
    const saved  = await ApiService.PostApiCalls(
      "payment/addSubscription",
      datain,
      true
    );
    if(saved[0] && saved[1]["IsSuccessful"]){
      const {subscription,client_secret, status,success,error} = saved[1];
   
      const subscriptionDetails = {
          update: {planId:selectedPlan["planId"],id:selectedPlan["planId"],
          code:selectedPlan["code"],
          duration:selectedPlan["duration"],
          email:onboardUser["email"],
          paymentInfo:card,
          paymentMethodId:paymentMethodId,
          subId:subscription["id"],
          customer:subscription["customer"],
          plan:subscription["plan"],
          collection_method:subscription["collection_method"],
          current_period_start:subscription["current_period_start"],
          current_period_end:subscription["current_period_end"],
          latest_invoice:subscription["latest_invoice"],
          status:subscription["status"],
          created:subscription["created"],}
      }
      const UpdateSub = await ApiService.PostApiCalls("payment/saveSubscription",subscriptionDetails,true);
      if(UpdateSub[0] && UpdateSub[1]["IsSuccessful"]){
          Utils.updateLocalInfo("userhash", { ...user, creatorProfileStep: 6 });
          setShow(true);
      }
      else{
        Utils.showNotification(
          "error",
          "Oops",
          "Something went wrong. Try again"
        );
      }
  }else{
    Utils.showNotification(
      "error",
      "Oops",
      "Something went wrong. Try again"
    );
  }
    
  }
  if (loading) {
    return null;
  }
  if (currentStep === 6) {
    return navigate("/creator/home");// <Redirect to="/creator/home" />;
  }
  
  return (
    <>
      <Row>
        {!_.includes(["login","start"], currentStep) && <Col span={18} offset={3}>
          <div className="py-3"></div>
          <div className="w-full">
            <Steps size="small" current={currentStep === 5?currentStep-1:currentStep}>
              <Step title={t("Basic")} />
              <Step title={t("Social Channels")} />
              <Step title={t("Branding")} />
              <Step title={t("Templates")} />
              {selectedPlan.code !== "starter" && <Step title={t("Payment" )}/>}
            </Steps>
          </div>
          <div className="py-3"></div>
        </Col>}
        {currentStep === "start" && <Col span={14} offset={5}>
          <div className="py-3"></div>
          <div className="w-full px-2">
          <h4 className="fw-bold color-blue text-center">
          {t("authheaderSignup")}
        </h4>
          </div>
          <div className="py-3"></div>
    
          <div className="w-full flex justify-between px-2">
          <div className="plan-selctiontext float-left fw-700 flex items-center justify-center text-capitalize text-bold">
          <Gift className="mr-2 " />
          {selectedPlan.code !== "starter"?<span>{t(selectedPlan.code +" Membership")} ({selectedPlan.duration})</span>:<span>{t(selectedPlan.code +" Membership")}</span>}
          </div>
          {selectedPlan.code !== "starter" && <div className="plan-selctiontext float-left info flex items-center justify-left text-capitalize text-bold flex-1 pl-3">
          {/* <CheckCircle className="mr-2" /> */}
          <span>${planPrice[selectedPlan.duration][selectedPlan.id]}/{selectedPlan.duration ==="monthly"?t("month"):t("year")}</span></div>}
          <Link to={"/pricing"}><div className="plan-selctiontext link">{t("Change Plan")}</div></Link>
         </div>
          <div className="py-3"></div>
        </Col>}
        {user && currentStep === 0 && <Col span={14} offset={5}>
          
          {selectedPlan.code !== "starter" && <><div className="py-1"></div><div className="w-full px-2">
          <h4 className="fw-bold color-blue">
          Every single plan has the assurance that you may cancel at any time.
        </h4>
          </div> <div className="py-0"></div></>}
         

          <div className="w-full flex justify-between px-2">
          <div className="plan-selctiontext float-left fw-700 flex items-center justify-center text-capitalize text-bold">
          <Gift className="mr-2 " />
          {selectedPlan.code !== "starter"?<span>{t(selectedPlan.code +" Membership")} ({selectedPlan.duration})</span>:<span>{t(selectedPlan.code +" Membership")}</span>}
          </div>
          {selectedPlan.code !== "starter" && <div className="plan-selctiontext float-left info flex items-center justify-left text-capitalize text-bold flex-1 pl-3">
          {/* <CheckCircle className="mr-2" /> */}
          <span>${planPrice[selectedPlan.duration][selectedPlan.id]}/{selectedPlan.duration ==="monthly"?t("month"):t("year")}</span></div>}
          <Link to={"/pricing"}><div className="plan-selctiontext link">{t("Change Plan")}</div></Link>
         </div>
          <div className="py-3"></div>
        </Col>}
        {currentStep === "login" && <Col span={14} offset={5}>
          <div className="py-3"></div>
         
          <div className="py-3"></div>
        </Col>}
        {currentStep === "login" && (
          <Col span={14} offset={5} className="rounded-xl p-5 bg-white">
            <div className="text-center bold text-base tracking-wide text-white">
            <Form
                name="basic"
                layout="vertical"
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                className="text-white"
              >
                <Form.Item
                wrapperCol={{ offset: 0, span: 16 }}
                  label={t("Email")}
                  className="py-2"
                  name="loginemail"
                  rules={[
                    { required: true, message: t("EmailValidation") },
                  ]}
                >
                  <Input
                    className="p-2 rounded-xl"
                    placeholder="your@email.com"
                  />
                </Form.Item>

                <Form.Item
                wrapperCol={{ offset: 0, span: 16 }}

                  label={t("Password")}
                  name="loginpassword"
                  rules={[
                    { required: true, message: t("PasswrodValidation")},
                  ]}
                >
                  <Input.Password
                    autoComplete="false-none"
                    className="p-2 rounded-xl"
                    placeholder="*********"
                  />
                </Form.Item>

                <Form.Item
                  name="remember"
                  valuePropName="checked"
                  className="text-left text-white justify-between"
                >
                  <Checkbox className={" text-xs"}>
                    {t("Remember me")}
                  </Checkbox>
                  <Button
                    type="link"
                    htmlType="button"
                    className="text-xs self-end"
                    // onClick={() => setisResetPassword(true)}
                  >
                    {t("PasswordReset")}
                  </Button>
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 0, span: 22 }}
                className="py-1 float-left w-100"
                >
                  <Button
                    type="primary"
                    
                    htmlType="submit"
                    className="px-3 rounded-xl float-right"
                  >
                    {t("Submit")}
                  </Button>
                </Form.Item>
              </Form>
            </div>  
            <div className="text-center font-light text-xs tracking-wide text-white pt-2"></div>
          <div className="text-center font-light text-xs tracking-wide text-white">
            <Divider className="text-white font-light text-xs cursor-pointer">
              <Button
                onClick={() => setcurrentStep(currentStep ==="login"?"start":"login")}
                type="link"
                block
                className="px-2 rounded-xl "
              >
                {currentStep ==="login" ? t("acount2") : t("acount1")}
              </Button>
            </Divider>
          </div>
          </Col>

        )}
        {currentStep === "start" && (
          <Col span={14} offset={5} className="rounded-xl p-5 bg-white">
            <div className="text-center bold text-base tracking-wide text-white">
              <Form
                name="basic"
                layout="vertical"
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                className="text-white"
              >
                
                <Form.Item
                  wrapperCol={{ offset: 0, span: 22 }}
                  label={t("First Name")}
                  className="py-1 float-left w-50"
                  name="firstname"
                  rules={[{ required: true, message: t("FnameValidation") }]}
                >
                  <Input className="p-2 rounded-xl" placeholder={t("First Name")} />
                </Form.Item>
                <Form.Item
                  wrapperCol={{ offset: 0, span: 22 }}
                  label={t("Last Name")}
                  className="py-1  float-left w-50"
                  name="lasttname"
                  rules={[{ required: true, message: t("LnameValidation") }]}
                >
                  <Input className="p-2 rounded-xl" placeholder={t("Last Name")} />
                </Form.Item>
                <Form.Item
                 wrapperCol={{ offset: 0, span: 22 }}
                  label={t("Email")}
                  className="py-1 float-left w-50"
                  name="loginemail"
                  rules={[
                    { required: true, message: t("EmailValidation") },
                  ]}
                >
                  <Input
                    className="p-2 rounded-xl"
                    placeholder="your@email.com"
                  />
                </Form.Item>

                <Form.Item
                wrapperCol={{ offset: 0, span: 22 }}
                  label={t("Password")}
                  name="loginpassword"
                  className="py-1 float-left w-50"
                  rules={[
                    { required: true, message: t("PasswrodValidation")  },
                  ]}
                >
                  <Input.Password
                    autoComplete="false-none"
                    className="p-2 rounded-xl"
                    placeholder="*********"
                  />
                </Form.Item>
                <Form.Item
                wrapperCol={{ offset: 0, span: 24 }}
                  label=""
                  name="termscheck"
                  className="py-1 float-left w-50 text-left"
                  // initialValue={termsChecked}
                  // rules={[]}>
                  rules={[
                    {validator: validation},
                    // { required: true, message: "Please accept Terms & Privacy Policy!" },
                  ]}
                >
                  <Checkbox checked={termsChecked} onChange={()=>settermsChecked(!termsChecked)} >
                  {t("TNC1")}<Link to={"/terms-services"}>{t("TNC2")}</Link> {t("TNC3")} <Link to={"/privacy"}>{t("TNC4")}</Link>
                </Checkbox>
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 0, span: 22 }}
                className="py-1 float-left w-100"
                >
                  <Button
                    type="primary"
                    
                    htmlType="submit"
                    className="px-3 rounded-xl float-right"
                  >
                    {t("Submit")}
                  </Button>
                </Form.Item>
              </Form>
            </div>  
            <div className="text-center font-light text-xs tracking-wide text-white pt-2"></div>
          <div className="text-center font-light text-xs tracking-wide text-white">
            <Divider className="text-white font-light text-xs cursor-pointer">
              <Button
                onClick={() => setcurrentStep(currentStep ==="login"?"start":"login")}
                type="link"
                block
                className="px-2 rounded-xl "
              >
                {currentStep ==="login" ? t("acount2") : t("acount1")}
              </Button>
            </Divider>
          </div>
          </Col>

        )}
        {currentStep === 0 && (
          <Col span={14} offset={5} className="rounded-xl p-5 bg-white">
            <Form
              name="basic"
              requiredMark={false}
              layout="vertical"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label={
                  <span className="font-medium text-base pb-2">
                    {t("step1Name")}
                  </span>
                }
                initialValue={user["name"]}
                wrapperCol={{
                  span: 15,
                }}
                name="profilePagename"
                rules={[
                  {
                    required: true,
                    message: "How would people know you!",
                  },
                ]}
              >
                <Input placeholder="personal name, brand name, whatever" />
              </Form.Item>
              
              <Form.Item
                label={
                  <span className="font-medium text-base pb-2">
                                        {t("step1SocialCause")}

                  </span>
                }
                wrapperCol={{
                  span: 15,
                }}
                name="topicson"
                rules={[
                  {
                    required: true,
                    message:t("step1CatValid"),
                  },
                ]}
              >
                {/* <Input placeholder="blog, music, videos, art & craft" /> */}
                <Select  placeholder={t("step1Cat")} onChange={(e)=>setcategory(e)}>
          <Select.Option value="Poverty & Hunger">Poverty & Hunger</Select.Option>
          <Select.Option value="Health & Well Being">Health & Well Being</Select.Option>
          <Select.Option value="Quality Education">Quality Education</Select.Option>
          <Select.Option value="Gender Equality">Gender Equality</Select.Option>
          <Select.Option value="Clean Water & Sanitation">Clean Water & Sanitation</Select.Option>
          <Select.Option value="Affordable & Clean Energy">Affordable & Clean Energy</Select.Option>
          <Select.Option value="Decent work and economic growth">Decent work and economic growth</Select.Option>
          <Select.Option value="Industry, Innovation and Infrastructure">Industry, Innovation and Infrastructure</Select.Option>
          <Select.Option value="Traditional Industry revamping">Traditional Industry revamping</Select.Option>
          <Select.Option value="Sustainable Cities and Communities">Sustainable Cities and Communities</Select.Option>
          <Select.Option value="Responsible Consumption & Production">Responsible Consumption & Production</Select.Option>
          <Select.Option value="Climate Action">Climate Action</Select.Option>
          <Select.Option value="Life on Land">Life on Land</Select.Option>
          <Select.Option value="Peace, Justice  & Strong Institutions">Climate Action</Select.Option>
          <Select.Option value="journalism">Journalism</Select.Option>
          <Select.Option value="Other">Other</Select.Option>
          
        </Select>
              </Form.Item>
             {
               category === "Other" && <Form.Item
               initialValue={user["creatorCategoryText"]}
               wrapperCol={{
                 span: 15,
               }}
               name="topicsonOther"
               rules={[
                 {
                   required: true,
                   message: "Missing!",
                 },
               ]}
             >
               <Input placeholder="Spacify category" />
             </Form.Item>
             } 
              <Form.Item
                className="py-2"
                wrapperCol={{
                  span: 15,
                }}
                name="subtopicson"
                rules={[
                  {
                    required: true,
                    message: t("step1SubCat")
                  },
                ]}
              >
                {/* <Input placeholder="blog, music, videos, art & craft" /> */}
                <Select  placeholder={t("step1SubCat")} onChange={(e)=>setsubcategory(e)}>
          <Select.Option value="Art">Art</Select.Option>
          <Select.Option value="Business Professional-Consultant">Business Professional/Consultant</Select.Option>
          <Select.Option value="Digital">Digital(Blog, Instagram, TikTok, YouTube, etc.)</Select.Option>
          <Select.Option value="Film-TV">Film/TV</Select.Option>
          <Select.Option value="Gaming">Gaming</Select.Option>
          <Select.Option value="Media-Editorial">Media/Editorial</Select.Option>
          <Select.Option value="Music">Music</Select.Option>
          <Select.Option value="Podcast-Social Audio">Podcast/Social Audio</Select.Option>
          <Select.Option value="Sports">Sports</Select.Option>
          <Select.Option value="News-Articles">News & Articles</Select.Option>
          <Select.Option value="Indian-Astrology">Indian Astrology</Select.Option>
          <Select.Option value="Other">Other</Select.Option>
         
          
        </Select>
              </Form.Item>
              {
               subcategory === "Other" && <Form.Item
               initialValue={user["creatorCategoryText"]}
               wrapperCol={{
                 span: 15,
               }}
               name="subtopicsonOther"
               rules={[
                 {
                   required: true,
                   message: "Missing!",
                 },
               ]}
             >
               <Input placeholder="Spacify Sub-category" />
             </Form.Item>
             } 
              <Form.Item
                label={
                  <span className="font-medium text-base pb-2">
                    {t("step1Link")}
                  </span>
                }
                wrapperCol={{
                  span: 15,
                }}
                initialValue={user["userName"]}
                name="usernameurl"
                validateStatus={urlValidateStatus}
                help={urlHelpTips}
                rules={[
                  {
                    required: true,
                    message: "Your URL is missing!",
                  },
                ]}
              >
                <Input
                  prefix={
                    <span className="opacity-70">https://hashsaga.com/</span>
                  }
                  onChange={(e) => verifyURL(e)}
                />
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  offset: 0,
                  span: 24,
                }}
              >
                <div className="py-3"></div>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  className="float-right rounded-xl bg-red-1 px-4 border-0"
                  loading={savingForm}
                  disabled={nextDisabled}
                >
                  Next
                </Button>
              </Form.Item>
            </Form>
          </Col>
        )}

        {currentStep === 1 && (
          <Col
            span={14}
            offset={5}
            className="rounded-xl p-5 bg-white flex flex-col"
          >
            <span className="-mt-3 text-center mb-3 w-100 text-xl font-semibold w-full self-center">
              {t("step2Header")}
            </span>
            <Form
              name="basic"
              requiredMark={false}
              layout="vertical"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label={
                  <span className="font-medium text-base pb-2">
                    {t("step2InputBrandName")}
                  </span>
                }
                wrapperCol={{
                  span: 15,
                }}
                name="wsname"
                rules={[
                  {
                    required: true,
                    message: t("step2InputValidation"),
                  },
                ]}
              >
                <Input placeholder={t("step2InputBrandName")}
 />
              </Form.Item>

              <Form.Item
                label={
                  <span className="font-medium text-base pb-2">
                    {t("step2InputBrandLink")}

                  </span>
                }
                wrapperCol={{
                  span: 15,
                }}
                name="wslink"
                rules={[
                  {
                    required: false,
                    message: t("step2InputValidation"),
                  },
                ]}
              >
                <Input placeholder={t("step2InputBrandLinkPlace")}
 />
              </Form.Item>
              <Form.Item
                validateStatus={!profileError ? "success" : "error"}
                help={
                  profileError ? t("step2SocialValidation") : null
                }
              >
                <div className="flex-col flex mt-2 mb-3">
                  <span className="font-medium text-base pb-2">
                    {t("step2SocialHeader")}
                  </span>
                  <span className="font-normal text-xs pb-2 opacity-70">
                  {t("step2SocialSubHeader")}

                  </span>
                </div>
                <div className="py-3 pt-3 flex justify-around">
                  <div
                    className={
                      socialConnectionType === 1
                        ? "social-connection connect box--shadow  active"
                        : "social-connection connect box-shadow"
                    }
                  >
                    <FacebookAuth callback={getConnectionResponse} />
                  </div>
                  <div
                    className={
                      socialConnectionType === 2
                        ? "social-connection connect box--shadow  active"
                        : "social-connection connect box-shadow"
                    }
                  >
                    <TwitterAuth callback={getConnectionResponse} />
                  </div>
                  <div
                    className={
                      socialConnectionType === 3
                        ? "social-connection connect box--shadow  active"
                        : "social-connection connect box-shadow"
                    }
                  >
                    <LinkedinAuth callback={getConnectionResponse} />
                  </div>
                  <div
                    className={
                      socialConnectionType === 4
                        ? "social-connection connect box--shadow  active"
                        : "social-connection connect box-shadow"
                    }
                  >
                    <InstaAuth callback={getConnectionResponse} />
                  </div>
                </div>
              </Form.Item>
              <Form.Item>
                <span className="pl-2 fw-600 pt-3">
                {t("step2SocialInsta2")}{" "}
                  <a
                    target="_blank"
                    href="https://help.instagram.com/502981923235522?fbclid=IwAR216hIf5N_9nLD2kIVZpoEZ-kr_SuCbJ1MF6liJSr-iE_o3op0X9xO4JtM"
                  >
                    {t("step2SocialInsta2")}
                  </a>
                </span>
              </Form.Item>
              <Form.Item>
                {selectedProfile.length > 0 && (
                  <div>
                    {selectedProfile.map((p, key) => (
                      <div
                        key={key}
                        className="social-connection box-shadow selected w-100 d-flex justify-content-between align-items-center"
                      >
                        <span className="fz-18 fw-600 px-4 flex items-center flex-row">
                          <img
                            src={
                              p["source"] === "Facebook"
                                ? p["picture"]["data"]["url"]
                                : p["fb-picture"]
                            }
                            className="mr-3 h-8"
                            height="30"
                            alt={p["name"]}
                          />
                          <span>
                            {p["name"]} ({p["source"]})
                          </span>
                        </span>
                        <span
                          className="fz-18 fw-700 px-4 cursor-pointer"
                          onClick={() => removeLink(key, p["id"])}
                        >
                          <TrashFill
                            className="cursor-pointer"
                            color={"red"}
                            size={14}
                          />
                        </span>
                      </div>
                    ))}
                  </div>
                )}
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  offset: 0,
                  span: 24,
                }}
              >
                <div className="py-3"></div>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  className="float-right rounded-xl bg-red-1 px-4 border-0"
                  loading={savingForm}
                  disabled={nextDisabled}
                >
                  {t("Next")}
                </Button>
              </Form.Item>
            </Form>
          </Col>
        )}
        {currentStep === 2 && (
          <Col
            span={14}
            offset={5}
            className="rounded-xl p-5 bg-white flex flex-col"
          >
            <span className="-mt-3 text-center mb-3 w-100 text-xl font-semibold w-full self-center">
              {t("step3Header")}
            </span>
            <Form
              name="basic"
              requiredMark={false}
              layout="vertical"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label={
                  <span className="font-medium text-base pb-2">
                    {t("step3Input1")}
                  </span>
                }
                wrapperCol={{
                  span: 15,
                }}
                name="brandauthor"
                initialValue={workspaceInfo ? workspaceInfo["name"] : ""}
                rules={[
                  {
                    required: true,
                    message: t("Missing"),
                  },
                ]}
              >
                <Input placeholder="Creator name" />
              </Form.Item>

              <Form.Item
                label={
                  <span className="font-medium text-base pb-2">
                    {t("step3Input2")}
                  </span>
                }
                wrapperCol={{
                  span: 15,
                }}
                name="brandname"
                initialValue={workspaceInfo ? workspaceInfo["name"] : ""}
                rules={[
                  {
                    required: true,
                    message: t("Missing"),
                  },
                ]}
              >
                <Input placeholder="Influencer/Brand/Company Name" />
              </Form.Item>
              <Form.Item
                label={
                  <span className="font-medium text-base pb-2">
                    {t("step3Input3")}
                  </span>
                }
                wrapperCol={{
                  span: 15,
                }}
              >
                <Upload
                  className="flex-1 uploadContainer"
                  multiple={false}
                  customRequest={customUploadRequest}
                  accept="image/*"
                  listType="none"
                  disabled={isUploading ? true : false}
                  showUploadList={false}
                >
                  <div className="flex-1 d-flex flex-row justify-content-between">
                    <div className="activeImage">
                      {<img src={brandImage} alt="" />}
                    </div>

                    {!isUploading && <div className="text-upload">Browse</div>}
                    {isUploading && (
                      <div className="upload-imagebt">
                        <Spin size="small" spinning={true} />
                      </div>
                    )}
                  </div>
                </Upload>
              </Form.Item>
              <Form.Item
                label={
                  <span className="font-medium text-base pb-2">
                    {t("step3Input4")}
                  </span>
                }
                wrapperCol={{
                  span: 15,
                }}
                className="colorpicker-row"
              >
                {" "}
                <div className="uploadContainer colorpicker d-flex justify-content-between">
                  <div className="text text-uppercase">{themeColor}</div>
                </div>
                <div className="colorpicker-container cursor-pointer">
                  {" "}
                  <input
                    value={themeColor}
                    type="color"
                    onChange={(e) => setThemeColor(e.target.value)}
                  />
                </div>
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  offset: 0,
                  span: 24,
                }}
              >
                <div className="py-3"></div>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  className="float-right rounded-xl bg-red-1 px-4 border-0"
                  loading={savingForm}
                  disabled={nextDisabled}
                >
                  {t("Next")}
                </Button>
              </Form.Item>
            </Form>
          </Col>
        )}
        {currentStep === 3 && (
          <Col
            span={18}
            offset={3}
            className="rounded-xl p-5 bg-white flex flex-col"
          >
            <span className="-mt-3 text-center mb-3 w-100 text-xl font-semibold w-full self-center">
              {t("step4Header")}
            </span>
            <Form
              name="basic"
              requiredMark={false}
              layout="vertical"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Row className="mx-0 justify-around py-2  ">
                {library.map((d, key) => (
                  <Col
                    span={10}
                    key={key}
                    className="signup mb-3"
                    onClick={() => {
                      setSelectedTemplate(d.id);
                      setprofileError(null);
                    }}
                  >
                    <TemplateView
                      selectedTemplate={selectedTemplate}
                      d={d}
                      brandName={workspaceInfo?workspaceInfo["companyName"]:""}
                      brandAuthor={workspaceInfo?workspaceInfo["authorName"]:""}
                      themeColor={workspaceInfo?workspaceInfo["themeColor"]:""}
                      brandImage={workspaceInfo?workspaceInfo["brandImage"]:""}
                      showEditor={false}
                    />
                    <div className="mb-3"></div>
                  </Col>
                ))}
              </Row>
              <Form.Item
                validateStatus={!profileError ? "success" : "error"}
                help={profileError ? "Select atleast one template" : null}
              ></Form.Item>
              <Form.Item
                wrapperCol={{
                  offset: 0,
                  span: 24,
                }}
              >
                <div className="py-3"></div>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  className="float-right rounded-xl bg-red-1 px-4 border-0"
                  loading={savingForm}
                  disabled={nextDisabled}
                >
                  {savingForm ? t("Finishing") : t("Finish")}
                </Button>
              </Form.Item>
            </Form>
          </Col>
        )}
        {currentStep === 5 && (
         <Col
            span={18}
            offset={3}
            className="rounded-xl p-5 bg-white flex flex-col"
          >
            <span className="-mt-3 text-center mb-3 w-100 text-xl font-semibold w-full self-center">
              {t("step5Header")}
            </span>
            <div>
            <div className="flex items-start space-x-4 py-4 flex-row justify-start">
              <span className="-mt-3 text-left mb-3  self-center">
              {`${t("step5Charge1")} $${planPrice[selectedPlan.duration][selectedPlan["id"]]}/${selectedPlan.duration ==="monthly"?t("month"):t("year")}.`}
            </span>
            {selectedPlan["id"] === 1 && <span className="-mt-3 text-left mb-3  self-center">Free trial is available for 2 months.</span>}
            </div><div className="py-2"></div>
            <Col
             span={12}
             offset={6}
            className="rounded-xl p-5 bg-white flex flex-col"
          >
            <Elements  stripe={stripePromise} ><PaymentCard user={onboardUser} selectedPlan={onboardUser["subdetails"][0]?onboardUser["subdetails"][0]:onboardUser["selectedAppPlan"]} paymentCallback={paymentCallback}/></Elements>
            </Col>
            </div>
          </Col>
        )
        }
      </Row>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="lg"
        className=""
        contentClassName="campaign-new-modal flex items-center bg-white "
        backdrop={"static"}
      >
        <Modal.Body className="px-4">
          <Col className="text-center flex flex-col w-full self-center items-center">
            <img src={OverLayIcon} style={{ width: "50%" }} />
          </Col>
          <Col className="text-center py-3"></Col>
          <Col className="fz-16 fw-600 mb-3">
            Which area would you like to checkout first ?
          </Col>
          <Col className="text-center py-2"></Col>
          <Col className="grid grid-flow-col gap-4">
            <div className="bg-gray-50 shadow-md rounded-md p-3 flex flex-col items-center">
              <span className="text-center text-sm font-medium">
                Have a look what other creators are doing.
              </span>
              <span className="text-center text-sm py-3"></span>
              <a href={"/"}>
                <Button
                  type="primary"
                  className="float-right rounded-xl bg-red-1 px-4 border-0"
                >
                  Jump to Feed
                </Button>
              </a>
            </div>
            <div className="bg-gray-50 shadow-md rounded-md p-3 flex flex-col items-center">
              <span className="text-center text-sm font-medium">
                Don't wait, create your first post.
              </span>
              <span className="text-center text-sm py-3"></span>
              <a href={"/creator/home"}>
                <Button
                  type="primary"
                  className="float-right rounded-xl bg-red-1 px-4 border-0"
                >
                  Create Post
                </Button>
              </a>
            </div>
            <div className="bg-gray-50 shadow-md rounded-md p-3 flex flex-col items-center">
              <span className="text-center text-sm font-medium">
                Choose what to offer your followers
              </span>
              <span className="text-center text-sm py-3"></span>
              <a href={"/creator/home"}>
                <Button
                  type="primary"
                  className="float-right rounded-xl bg-red-1 px-4 border-0"
                >
                  Manage Tiers
                </Button>
              </a>
            </div>

            {/* <a href={`/workspace/${activeWp}/campaigns`}>
              <Button
                type="primary"
                className=" fw-bold  mr-2"
                
              >
                Campaign
              </Button>
            </a> */}
            {/* <a href={`/workspace/${activeWp}/settings/workspace`}>
              {" "}
              <Button
                type="primary"
                className=" fw-bold mx-2"
                
              >
                Add RSS Feed
              </Button>
            </a> */}
            {/* <a href={`/workspace/${activeWp}/calendar`}>
              {" "}
              <Button
                type="primary"
                className=" fw-bold  mx-2"
                
              >
                Calendar
              </Button>
            </a> */}
          </Col>
          <Col className="text-center py-2"></Col>
          <Col className="text-left ">
            If you have any questions for the next couple of weeks, you can
            reach out to the support team by clicking on the Support details in
            the menu portal.
          </Col>
          <Col className="text-center py-3"></Col>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreatorEdit;
