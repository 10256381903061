
import VideoPlayer from "container/common/VideoPlayer";
import React, { useState,useRef } from "react";
import { PauseCircle, Play, PlayCircle, PlayCircleFill } from "react-bootstrap-icons";

const CardVisualSingle = ({ visual, type,CampaignType,text,assets=null }) => {

  const [visualImage, setvisualImage] = useState(typeof visual === "string"? visual:visual?visual[0]["url"]:null)
  const [isPlaying, setisPlaying] = useState(false);
  const [isPaused, setisPaused] = useState(false);
  const [showPlayPause, setshowPlayPause] = useState(false)
    const videoPlayer = useRef();
    const videoPlayerSource = useRef();
    let img ={}
    if(type === "video" && assets && assets[0]["assetData"]){
    
      
      if(assets[0]["assetData"]["bucketpath"]){
        img = {
          "thumbnail_url":`https://hs-container.s3.ap-south-1.amazonaws.com/${assets[0]["assetData"]["bucketpath"]}/${assets[0]["assetData"]["name"]}-preview.mp4`,
          "preview_url":visualImage,
          "poster_url":`https://hs-container.s3.ap-south-1.amazonaws.com/${assets[0]["assetData"]["bucketpath"]}/${assets[0]["assetData"]["name"]}-thumb.jpg`,
        }
      }
      else{
        img = {
          "thumbnail_url":`https://hs-container.s3.ap-south-1.amazonaws.com/${assets[0]["assetData"]["bucketpath"]}/${assets[0]["assetData"]["name"]}-preview.mp4`,
          "preview_url":visualImage,
          "poster_url":`https://hs-container.s3.ap-south-1.amazonaws.com/${assets[0]["assetData"]["bucketpath"]}/${assets[0]["assetData"]["name"]}-thumb.jpg`,
        }
      }
    }
    // console.log(visual,"type");
  return (
    visualImage && <div
      feedimage="true"
      className={type === "video"?"rounded-xl overflow-hidden bg-blue-900 w-full relative flex justify-center":"rounded-xl overflow-hidden bg-blue-900 w-full feed-visual-56 relative"}
    >
      {type === "video" && (
       
        <>
        <VideoPlayer img={img} isThumbnail={true} />
       </>
      )}
       {CampaignType === "video-post" && (
        <div className="absolute z-30 inset-0 flex items-center justify-center bg-gray-100 bg-opacity-30">
          <div className="w-8 h-8 bg-blue-1 rounded-full text-white cursor-pointer flex items-center justify-center ">
            <Play size={24} />
          </div>
        </div>
      )}
     {type === "image" && <div image className="w-full h-full absolute  inset-0 ">
     <div
          className="absolute w-full h-full inset-0 bg-center bg-cover blur-mdx bg-no-repeat"
          style={{ backgroundImage: `url("${visualImage}")` }}
        ></div>
        <div
          className="absolute w-full h-full inset-0 bg-center bg-contain bg-no-repeat"
          style={{ backgroundImage: `url("${visualImage}")` }}
        ></div>
      </div>}
      {type === null && <div image className="w-full h-full absolute  inset-0 ">
     <div
          className="absolute w-full h-full inset-0 bg-center bg-cover blur-mdx bg-no-repeat"
          style={{ backgroundImage: `url("${visualImage}")` }}
        ></div>
        <div
          className="absolute w-full h-full inset-0 bg-center bg-contain bg-no-repeat"
          style={{ backgroundImage: `url("${visualImage}")` }}
        ></div>
      </div>}
      {type === "noimage" && <div image className="w-full h-full absolute  inset-0 ">
        <div
          className="absolute w-full h-full inset-0 bg-center bg-cover flex align-items-center text-center text-white "
          
        ><span className="px-2 line-clamp-2">{text}</span></div>
      </div>}
    </div>
  );
};

export default CardVisualSingle;
