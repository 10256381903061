
import {  message, Divider, Button,List, Spin,Row as ANTRow, Col as ANTCol } from "antd";
import { AuthContext } from "app/AuthProvider";
import _ from "lodash";

import React, { useContext, useState, useEffect } from "react";
import {Container,Row,Card,Col} from "react-bootstrap";
import ApiService from "services/ApiService";
import { Doughnut, Pie ,Line} from "react-chartjs-2"; 

import AnalyticsView from "../creator/AnalyticsView";
import CampaignPostCard from "../creator/CampaignPostCard";
import CreateNewCampaign from "../creator/CreateNewCampaign";
import DashboardListView from "../creator/DashboardListView";
import NavHeader from "../creator/NavHeader";
import RecentPosts from "../creator/RecentPosts";
import SetupWorkspace from "../creator/SetupWorkspace";
import TopKeywords from "./TopKeywords";
import moment from "moment";
import { chartColors } from "./colors";
import ListView from "./ListView";
import Utils from "helper/Utils";
import SocialProfileBox from "../workspacesettings/comps/SocialProfileBox";
import AnlyticsHeader from "./AnlyticsHeader";
const arrToInstanceCountObj = arr => arr.reduce((obj, e) => {
  obj[e] = (obj[e] || 0) + 1;
  return obj;
}, {});
const options = {
  legend: {
    display: true,
    position: "bottom",
    rtl:false,
    labels:{
      boxWidth:9,
      fontColor:"#485465",
      fontFamily:"Muli"
    }
  },
  maintainAspectRatio: false,
  elements: {
    arc: {
      borderWidth: 0
    }
  },
  plugins: {
    datalabels: {
      anchor: 'end',
      align: 'top',
      formatter: Math.round,
      font: {
        weight: 'bold'
      }
    }
  }
};
const optionsline = {
  legend: {
    display: false,
    position: "right"
  },
  scales: {
    xAxes: [{
      display: true,
      scaleLabel: {
        display: false,
        labelString: 'Month'
      }
    }],
    yAxes: [{
      display: true,
      scaleLabel: {
        display: false,
        labelString: 'Click'
      },
      ticks: {
        stepSize:10 
      }
    }]
  }
};

const AnalyticsHome = () => {
  const {
    authUser,
    activeWorkSpace,
    setupCompleted,
    setsetupCompleted,
  } = useContext(AuthContext);

  const [showsetup, setshowsetup] = useState(true);
  const [selectedProfile, setselectedProfile] = useState([])
  const [filteredProfiles, setfilteredProfiles] = useState([])
  const [loading, setloading] = useState(true)
  const [clicksData, setclicksData] = useState(null)
  const [analyticsData,setAnalyticsData] = useState(null)
    const [dataOS,setdataOS] = useState(null)
    const [dataBrws,setdataBrws] = useState(null)
    const [dataline, setdataline] = useState(null)
    const [dataCities, setdataCities] = useState(null)
    const [datareferer, setdatareferer] = useState(null)
 
  useEffect(() => {
    getAnalytics([moment().add(-30,"day").unix(),moment().add(-1,"day").unix()],null)
    const checkOne = _.filter(setupCompleted, function(item) {
      return item === true;
    });
    setshowsetup(checkOne.length === 0 ? false : true);
    return () => {};
  }, [setupCompleted]);

  const getAnalytics = async(dates=null,source=null,id=null)=>{
        const wid = activeWorkSpace['wid'];
        const currentProfiles = Utils.getWorkSpaceSocialProfiles(authUser,wid)
      
        const spd = _.map(currentProfiles,"id")
        setfilteredProfiles(spd)
        setselectedProfile(currentProfiles)

        let data = {wid: wid,userId:authUser.uniqueId}
        if(source){
          data["referer"] = source;
          data["pid"] = id;
        }
        if(dates){
          data["dates"] = dates;
        }
        else{
          data["dates"] = [moment().add(-8,"day").unix(),moment().add(-1,"day").unix()]
        }
        const dataAna = await ApiService.PostCogentApiCalls('/cogent/clicks',{...data})
        if(dataAna[0] && dataAna[1]['success']){
          setclicksData(dataAna[1]['data'])
          processData(dataAna[1]['data'])
        }
        else{
          setloading(false)
        }
        
        
  }
  const setActionFilter = (action,value,id="all",dates=null,pid) =>   {
    if(action === "profile"){
      // console.log(filteredProfiles);
      // console.log(typeof id);
      if(typeof id === "number"){
        setfilteredProfiles(value === "all"?_.map(selectedProfile,"id"):[parseInt(id)])
      }
      else{
        setfilteredProfiles(value === "all"?_.map(selectedProfile,"id"):[id])
      }
      
      getAnalytics(dates,value === "all"?null:value,id)

    }
    else if(action === "date"){
      
      getAnalytics(value,id === "all"?null:id,id === "all"?null:pid)  
    }
    
  }
  const convertTime = (t)=>{
    const timeSplits = t.split("pm");
    if(t === "12pm"){
      return "12pm"
    }
    else if(t === "0am"){
      return "12am"
    }
    else if(timeSplits.length > 1){
      return parseInt(timeSplits[0]-12)+"pm"
    }
    else{
      return t;
    }
  }
  const getPer = (all,val)=>{
    if(!all){
        return 0;
    }
    let sum = 0;
    all.map((d,l)=>{
        if(l < 6){sum = sum+d["Total"]}
    })
    return sum === 0? 0:parseInt((100*val)/sum);
}
const profileClick = (s) =>{
  if(datareferer){
    const d = _.filter(datareferer,function(d){
      return s === d["referer"]
    })
    return d[0] ? d[0]["Total"]:0
  }
  return 0;
}
  const processData = (dataIn)=>{
    let allTimes = [];
    if(dataIn){
      dataIn.map((s)=>{
        allTimes.push(moment.unix(s["clickdate"]).format("Ha"))
      })
    }
    const tts = arrToInstanceCountObj(_.sortBy(allTimes, (obj) => parseInt(obj, 10)));
    let dtsLable = [],dtsValue = [];
    Object.keys(tts).map((d,k)=>{
      dtsLable.push(convertTime(d))
      dtsValue.push(tts[d])
    })
    
    setdataline({
      labels: dtsLable,
      datasets: [
        {
          fill: true,
          lineTension: 0.1,
          backgroundColor: 'rgba(219,236,248,0.6)',
          borderColor: 'rgba(22,148,227,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: dtsValue
        }
      ]
    });
    const dataAll = dataIn;
    const OsData = _.chain(dataAll)
    .groupBy("os")
    .map((value, key) => ({ os: key, Total: value.length }))
    .value();
    let osLable = [],osValue = [];
    OsData.map((d,k)=>{
      if(k<5){
      osLable.push(d["os"])
      osValue.push(d["Total"])
      }
    })
    setdataOS({
      maintainAspectRatio: false,
      responsive: false,
      labels: osLable,
      datasets: [
        {
          data: osValue,
          backgroundColor: chartColors,
          hoverBackgroundColor: chartColors
        }
      ]
    });
    const BsData = _.chain(dataAll)
    .groupBy("browser")
    .map((value, key) => ({ browser: key, Total: value.length }))
    .value();
    let bsLable = [],bsValue = [];
    BsData.map((d,k)=>{
      if(k<5){
      bsLable.push(d["browser"])
      bsValue.push(d["Total"])}
    })
    setdataBrws({
      maintainAspectRatio: false,
      responsive: false,
      labels: bsLable,
      datasets: [
        {
          data: bsValue,
          backgroundColor: chartColors,
          hoverBackgroundColor: chartColors
        }
      ]
    });
    const CitiesData = _.chain(dataAll)
    .groupBy("city")
    .map((value, key) => ({ city: key, Total: value.length }))
    .value();
   
    setdataCities(_.reverse(_.sortBy(CitiesData, (obj) => parseInt(obj.Total, 10))))
    const RefData = _.chain(dataAll)
    .groupBy("referer")
    .map((value, key) => ({ referer: key, Total: value.length }))
    .value();
    
    setdatareferer(RefData)
    // console.log(
    //   _.chain(dataAll)
    //     // Group the elements of Array based on `color` property
    //     .groupBy("os")
    //     // `key` is group's name (color), `value` is the array of objects
    //     .map((value, key) => ({ os: key, Total: value.length }))
    //     .value()
    // );
    
    setAnalyticsData(dataAll);
    setloading(false)
  }
  return (
    <>
      <div className="p-0">
        <Container className="p-0">
          <ANTRow className="flex flex-col">
            <Col className="text-left px-6">
             <span className="text-lg font-medium">Analytics</span>
            </Col>
            {loading && <ANTCol className="flex items-center justify-center pt-4 py-5" style={{height:"500px"}}><Spin /></ANTCol>}
            {/* {!loading && clicksData && <><Col className="grid grid-flow-row grid-cols-2 space-x-3 pt-4" style={{height:"500px"}}>
            <TopKeywords  title={"Top Keywords"} type={"1"} />
            <TopKeywords title={"Social Platforms"}  type={"2"} />
            

            </Col>
          
           </>} */}
            
          </ANTRow>
          
        </Container>
       {!loading && <Container fluid="true" className="p-0 px-2">
        <Row className="mx-0 ">
            <Col className="px-0 pr-4"
             lg={12}      
             md={12}
             sm={12} >
             <Row className="mx-0 all-header-dark">
             <Col className="px-0 fz-18 fw-700"
             lg={12}      
             md={12}
             sm={12} >
            
            <Col className="px-0 fz-18 fw-700 float-right text-right"
             lg={9}      
             md={9}
             sm={12} >
            <AnlyticsHeader userDetails={authUser} callback={setActionFilter} wid={activeWorkSpace['wid']}/>
            </Col>
             </Col>
             <Col className="px-0 fz-14 fw-600"
             lg={12}      
             md={12}
             sm={12} ></Col>
             </Row>
             </Col>
             {
               loading ? 
                     <Col className="px-0 py-3 d-flex align-items-center justify-content-center loader-height"
                      lg={12}      
                      md={12}
                      sm={12} ></Col>
             :<>
             
             <Col className="px-0 fz-14 fw-600 text-center mt-4 border-end border-right"
             lg={3}      
             md={4}
             sm={12} >
             <Row className="mx-0">
                  <Col className="pl-0 fz-14 fw-700 text-left mt-4"
             lg={12}      
             md={12}
             sm={12} >Social Accounts</Col>
             <Col className="px-0 pr-4 mt-4 d-flex flex-wrap"
             lg={12}      
             md={12}
             sm={12} >
                  {
                    selectedProfile.map((p,key)=>(
                      filteredProfiles.includes(p["id"]) && <SocialProfileBox analytics={true} key={key} alls={datareferer} filteredProfiles={filteredProfiles} count={profileClick(p.source)} getConnectionResponse={null} removeProfile={null} className="" p={p} name={p.name} />
                    ))
                 }                  
             </Col> 
             <Col className="px-0 fz-14 fw-600 text-center mt-4"
             lg={12}      
             md={12}
             sm={12} >
               {
                 !analyticsData && <Card className="shadow-sm p-0"><Card.Body className="p-0 py-4">
                 <span className="fz-16 py-4 fw-700 ">No data available yet</span>
                
                </Card.Body></Card>
               }
                {/* {analyticsData &&<Card className="shadow-sm p-0"><Card.Body className="p-0">
                 <ListView name="Magical Maderai" value={213} width={randomXToY(23,78)}/>
                 <ListView name="Family Travel to Algeria" value={96} width={randomXToY(23,78)}/>
                 <ListView name="Budapest - When Buda met pest" value={36} width={randomXToY(23,78)}/>
                 <ListView name="Escape to cantabria" value={56} width={randomXToY(23,78)}/>
                 <ListView name="Island to hvar & brac" value={11} width={randomXToY(23,78)}/>
                
                </Card.Body></Card>} */}
                 </Col>
             </Row>    
                 
        
             </Col>
             <Col className="px-0 fz-14 fw-600 text-center mt-4"
             lg={9}      
             md={8}
             sm={12} >
             <Row className="mx-0">
                  <Col className=" fz-14 fw-700 text-left  mt-4"
             lg={4}      
             md={4}
             sm={12} >Clicks Overview</Col></Row>
             <Row className="mx-0">
                  <Col className=" fz-14 fw-600 text-center mt-4"
             lg={4}      
             md={4}
             sm={12} >
               {
                 !analyticsData && <Card className="shadow-sm p-0"><Card.Body className="p-0 py-4">
                 <span className="fz-16 py-4 fw-700 ">No data available yet</span>
                
                </Card.Body></Card>
               }
               { analyticsData && <Card className="shadow-sm p-0">
                 <Card.Header className="text-left bg-white border-0 fz-14 fw-700 ">Top Cities</Card.Header>
                 <Card.Body className="p-0 pb-3">{
                 dataCities && dataCities.map((d ,key)=>(
                  key < 6 && <ListView key={key} name={d["city"] === "null" ? "India":d["city"] ===""?"Others":d["city"]} value={d.Total} width={getPer(dataCities,d.Total)}/>
                 ))
               }
                 
                
                </Card.Body></Card>}
                 </Col>
                 <Col className=" fz-14 fw-600 text-center mt-4"
             lg={4}      
             md={4}
             sm={12} >
                <Card className="shadow-sm">
                <Card.Header className="text-left bg-white border-0 fz-14 fw-700 ">Browser</Card.Header>
                {
                 !analyticsData && <Card.Body className="p-0 py-4">
                 <span className="fz-16 py-4 fw-700 ">No data available yet</span>
                
                </Card.Body>
               }
                 {
                 dataBrws &&<Card.Body  style={{height:"260px"}}>
                 <Doughnut data={dataBrws} options={options} /></Card.Body>}
                </Card>
                 </Col>
                 <Col className=" fz-14 fw-600 text-center mt-4"
             lg={4}      
             md={4}
             sm={12} >
                 <Card className="shadow-sm"><Card.Header className="text-left bg-white border-0 fz-14 fw-700 ">Operating System</Card.Header>
                 {
                 !analyticsData && <Card.Body className="p-0 py-4">
                 <span className="fz-16 py-4 fw-700 ">No data available yet</span>
                
                </Card.Body>
               }
                 {
                 dataOS &&<Card.Body  style={{height:"260px"}}>
                 <Doughnut data={dataOS} options={options} /></Card.Body>}
                 
                 </Card>
                 </Col></Row>
                 <Row className="mx-0">
                 <Col className="px-4 fz-14 fw-600 text-center mt-4"
             lg={12}      
             md={12}
             sm={12} >
             <Card>
             <Card.Header className="text-left bg-white border-0 fz-14 fw-700">When you got more clicks</Card.Header>
             {
                 !analyticsData && <Card.Body className="p-0 py-4">
                 <span className="fz-16 py-4 fw-700 ">No data available yet</span>
                
                </Card.Body>
               }
             {dataline && <Card.Body >

                <Line data={dataline} options={optionsline} height={150} />
                </Card.Body>}
                </Card>
             </Col></Row>
             </Col>
             </>
            }
             </Row></Container>}
      </div>
     
     
    </>
  );
};

export default AnalyticsHome;
