import React,{useState} from 'react'
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import { Button, Spin } from 'antd';
import Utils from 'helper/Utils';


const ListingPaymentForm = ({tokens,clientSecret,callback,setprocessingCall}) => {
    const stripe = useStripe();
    const elements = useElements();
    const [processing, setprocessing] = useState(false)
    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        setprocessing(true)
        setprocessingCall(true)
        const result = await stripe.confirmPayment({
          //`Elements` instance that was used to create the Payment Element
          elements,
          redirect:"if_required",
          confirmParams: {
            return_url: "http://localhost:3000/creator/hashmine",
          },
        });
        console.log(result.error);
        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            setprocessing(false);
            setprocessingCall(false);
            Utils.showNotification("error", result.error.message, "Please try again.",3);
        }
        else{
            const data = {
                tokens:tokens,clientSecret,
                payInfo:{id:result.paymentIntent.id,status:result.paymentIntent.status,currency:result.paymentIntent.currency,created:result.paymentIntent.created,}
            }
            setprocessing(false);
            setprocessingCall(false);
            // console.log(data);
            callback(data)
        }

    }
  return (
    <div className='w-100 mx-auto relative'>
    {processing && <div className="h-100 w-100 flex flex-col items-center justify-center absolute bg-opacity-50 bg-gray-100 z-20">
                <Spin />
                <span className='p-3'>Please wait while we process your payment...</span>
              </div>}
    <form onSubmit={handleSubmit} className='flex flex-col'>
        <div className='p-4'>
        <span className='font-semibold text-base mb-3 float-left'>You are making payment of ₹{Utils.getAmountInFormat(tokens)}</span>
      <PaymentElement />
      </div>
      <div className='flex p-3 pb-0 border-t-2 justify-end space-x-3'><Button size='large'  disabled={!stripe} htmlType="submit" type='primary'  className={`px-3 brd-4 fw-700 mx-2 rounded-lg text-white bg-hs-pink  hover:border-hs-pink `} >Pay & Confirm</Button></div>
    </form>
    </div>
  )
}

export default ListingPaymentForm