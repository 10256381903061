
import React from 'react'
import { List, Modal } from 'antd';
import { ChevronBarRight, ChevronRight, Trash } from 'react-bootstrap-icons';
import { Link ,useNavigate} from 'react-router-dom';
import ApiService from 'services/ApiService';
import _ from 'lodash';
// import { Redirect } from 'react-router-dom/cjs/react-router-dom';

const data = [
  {slug: 'workspace-details',lable:"Workspace Details"},
  {slug: 'campaigns',lable:"Campaigns"},
  {slug: 'social-profiles',lable:"Social Profiles"},
  {slug: 'dates-times',lable:"Dates & Times"},
  {slug: 'schedule-templates',lable:"Schedule Templates"},
  {slug: 'content-templates',lable:"Content Templates"},
  {slug: 'branding',lable:"Branding"},
  {slug: 'blacklist-phrases',lable:"Blacklist Phrases"},
  
  
];
const dataGeneral = [
    
    {slug: 'delete',lable:"Delete Workspace"}
    
  ];
const LeftMenu = ({user,active,activeWorkSpace,setactiveWorkSpace,setAuthUser}) => {
  // const history = useHistory();
  const navigate = useNavigate()
  const VerifyAndDelete = async()=>{
    // console.log(user["workspace"].length);
    if(user["workspace"].length === 1){
      Modal.warning({
        title: 'Hold on!',
        content: (
          <div>
            <p>You have only one workspace, you can delete this workspace.</p>
            
          </div>
        ),
        onOk() {},
      });
    }
    else{
      const data = {
        wid:activeWorkSpace["wid"],
        action:"remove",           
      }
      Modal.confirm({
        title: 'Are you sure?',               
        content: 'Are you sure you want to delete this Workspace? This will remove all of its campaigns and social content. Data cannot be retrieved once it has been deleted!.',
        okText: 'Yes',
        cancelText: 'No',
        okButtonProps:{ type: "primary",className:"brd-4" },
        okType: 'danger',
        cancelButtonProps:{ className:"brd-4 mr-3" },
        async onOk() {
            // setTimeout(
            //     () => setError(null), 
            //     3000
            //   );
            const res = await ApiService.PostApiCalls('/creator/workspace/delete',data);
            if(res[0] && res[1]["success"]){   
              let currentWp = _.filter(user["workspace"] ,cw => cw.wid !== activeWorkSpace["wid"])
                
                setAuthUser({...user,workspace:currentWp})
                setactiveWorkSpace(currentWp[0]); 
                
                navigate("/creator/home")
            } 
            else{
                // setError(res[1]["Network error"])
            }   
                           
        },
        onCancel() {
          
        },
      });
     
    }
  }
  return (
      <>
       <div className='pb-2 bg-gray-100'></div>
    <List
     
      className="bg-white pb-3"

      dataSource={data}
      renderItem={item => (
        <List.Item className='cursor-pointer p-2'>
          <Link className='text-hs-dark px-1 flex w-100' to={`/creator/${activeWorkSpace["slug"]}/settings/${item["slug"].toLowerCase()}`}>
              <span className={item["slug"].toLowerCase() === active ?'flex justify-between items-center w-100 p-2 bg-blue-50 text-blue-400 font-semibold':'flex justify-between items-center w-100 p-2 hover:bg-blue-50 hover:text-blue-400'}>
              <span>{item["lable"]}</span>
              <ChevronRight size={20} />
              </span></Link>
        </List.Item>
      )}
    />
    <div className='pb-2 bg-gray-100'></div>
    <List
    className="bg-white pb-3"
      
      dataSource={dataGeneral}
      renderItem={item => (
        <List.Item className='cursor-pointer p-2' onClick={()=>VerifyAndDelete()}>
        <Link className='text-hs-dark px-1 flex w-100' >
              <span className={item["slug"].toLowerCase() === active ?'flex justify-between items-center w-100 p-2 bg-blue-50 text-blue-400':'flex justify-between font-semibold text-red-400 items-center w-100 p-2 hover:bg-blue-50 hover:text-red-800'}>
              <span>{item["lable"]}</span>
              <Trash size={20} />
              </span></Link>
      </List.Item>
      )}
    />
    </>
  )
}

export default LeftMenu