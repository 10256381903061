import React, { Component } from "react";
import { startUpload, UploadSingle } from "services/FileUpload";
import { Upload as AntUpload } from "antd";
import _ from "lodash";
import ApiService from "services/ApiService";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
class FileUploader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      previewVisible: false,
      save: false,
      previewImage: "",
      previewTitle: "",
      fileList: this.props.list,
      imageList: this.props.list,
      uploadedCount: 0,
      isUploading: false,
      progressCount: 0,
      imgPrifix: this.props.cid ? this.props.campaign.cid : this.props.prefix,
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.list.length !== this.props.list.length) {
      this.setState({
        fileList: this.props.list,
        imageList: this.props.list,
      });
    }
  }
  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };
  updateProgressBar = (val, id, uid,cancelToken) => {
    
    const { fileList } = this.state;
    let newList = [];
    
    for (let i = 0; i < fileList.length; i++) {
      let tmp = fileList[i];
      if (tmp.uid === uid) {
        
        tmp["uploaded"] = false;
        
      }
      tmp["cancelToken"] = cancelToken;
      newList.push(tmp);

    }
    
    if(this.props.processCallback){
      this.props.processCallback(newList,val,cancelToken);
    }
    this.setState({ fileList: newList });
  };
  UploadFinish = async(value, name, type, cid, uid,fileName="") => {
    const { uploadedCount } = this.state;
    this.setState({ uploadedCount: uploadedCount + 1 });
    let visualType = "image";
    let imagepath = "https://hs-container.s3.ap-south-1.amazonaws.com/" + name;
    let assetObject = {}
    if (type === "video/mp4" || type === "video/quicktime" || type === "video/avi"  || type === "video/mpeg"  || type === "video/ogg" || type === "video/webm"  || type === "video/3gpp2"  || type === "video/x-ms-wmv"  || type === "video/3gpp" ) {
      assetObject = {
        "imagePath": imagepath,
        "name": fileName.split(".")[0],
        "extension": fileName.split(".")[1],
        "type":"video",
        "fileType":type,
      }
      visualType = "video";

    }
    else if (type === "audio/mpeg" || type === "audio/aac" || type === "audio/wav") {
      assetObject = {
        "imagePath": imagepath,
        "name": fileName.split(".")[0],
        "extension": fileName.split(".")[1],
        "type":"audio",
        "fileType":type,
      }
      visualType = "audio";

    }
    else{
      assetObject = {
        "imagePath": imagepath,
        "name": fileName.split(".")[0],
        "extension": fileName.split(".")[1],
        "type":"image",
        "fileType":type,
      }
      visualType = "image";
    }
    const validation  = await ApiService.PostApiCalls("asset-validation",assetObject,true)
    const taskId = validation[1]["taskId"]
    const assetData = validation[1]["assetData"]
    // const validation  = await ApiService.GetApiCalls("que-status/"+id,"",true)

    const { fileList } = this.state;
   
    
    let newListFinish = [];
    for (let i = 0; i < fileList.length; i++) {
      let tmpFinish = fileList[i];
      if (tmpFinish.uid === uid) {
        tmpFinish["status"] = "done";
        tmpFinish["uploaded"] = true;
        tmpFinish["url"] = imagepath;
        tmpFinish["visualType"] = visualType;
        tmpFinish["taskId"] = taskId;
        tmpFinish["assetData"] = assetData;
        
      }
      newListFinish.push(tmpFinish);
    }
    this.setState({ fileList: newListFinish });
    this.VerifyAll();
  };
  VerifyAll =  () => {
    const { progressCount, uploadedCount, fileList } = this.state;
   
    if (progressCount === uploadedCount) {
      this.setState({
        uploadedCount: 0,
        progressCount: 0,
        save: false,
        isUploading: false,
      });
      if (this.props.reset) {
        this.setState({ fileList: [] });
      }
    }
    this.props.callback(fileList);
  };
  UploadFail = (error) => {
    console.log(error);

  };
  customUploadRequest = (e) => {
    const UploadState = startUpload(e.file.size);
    const { progressCount, imgPrifix } = this.state;
    if (UploadState === "single") {
      this.setState({
        isUploading: true,
        save: true,
        progressCount: progressCount + 1,
      });
     
      UploadSingle(
        e,
        this.updateProgressBar,
        this.UploadFinish,
        this.UploadFail,
        progressCount,
        "",
        "saga/user-assets/" + imgPrifix + ""
      )
        .then((res) => {})
        .catch((error) => {});
    }
  };
  handleCancel = () => this.setState({ previewVisible: false });

  handleChange = ({ file, fileList }) =>{
    
    if (file.status === 'uploading') {
      // console.log(file, fileList);
    }
    this.setState({ fileList })
  }
  // ({ fileList }) => this.setState({ fileList });

  saveImages = () => {
    const { fileList } = this.state;
    this.props.callbackImages(fileList);
    this.setState({ show: false, imageList: fileList });
  };

  render() {
    const {
      previewVisible,
      save,
      imageList,
      isUploading,
      previewImage,
      fileList,
      previewTitle,
      show,
    } = this.state;
    return (
      <>
        <AntUpload
          multiple={
            this.props.multipleImages ? this.props.multipleImages : true
          }
          listType={this.props.listType}
          fileList={fileList}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
          customRequest={this.customUploadRequest}
          disabled={this.props.hideLoader?false:isUploading ? true : false}
          accept={this.props.acceptedList}
          showUploadList={this.props.showUploadList}
        >
          {this.props.limit && fileList.length < this.props.limit && (
           this.props.hideLoader?<div className={this.props.btnCss?this.props.btnCss:"w-full"}>
              {this.props.view}
              
            </div>:<div className="w-full">
              {isUploading && this.props.viewUploading}
              {!isUploading && this.props.view}
            </div>
          )}
        </AntUpload>
      </>
    );
  }
}

export default FileUploader;
