import React from 'react'
import {ChevronDown,ChevronUp} from "react-bootstrap-icons";
import SucessChecked from "res/icons/SucessChecked"

const CardHeader = (props) => {
    // console.log();

    const succesIcon = () =>{
        if(props.text === "Add Quotes"){            
            if(props.activeTags >= props.minQuotesNo){               
                return  <span className="ml-3"><SucessChecked />{props.cp.isYTVideo && <span className="ml-3 font-weight-bold">Quotes are generated using Video Script(speech)</span>}</span>
            }
            else{
                return <span className="ml-3 fw-700 fz-16 color-sidebar-menu">{`Select atleast ${props.minQuotesNo} quotes`}{props.cp.isYTVideo && <span className="ml-3 font-weight-bold text-dark fz-14">Quotes are generated using Video Script(speech)</span>}</span>
            }
        }
        
    }
    return (
        <div className="d-flex flex-grow-1 align-items-center justify-content-between">
        <div className='d-flex'>{props.icon}{props.text} {succesIcon()}
        {}
        {props.showNumber && <span> ({props.activeTags})</span>}</div>
        {!props.isTab && <div className="d-flex flex-1 align-items-center justify-content-between px-2">
        <div className="selected-counts mr-4">{props.activeTags} {props.secondaryLabel}</div>
        {props.activeKey === props.eventKey ? <ChevronUp />:<ChevronDown/>}
        </div>}
        </div>
    )
}

export default CardHeader
