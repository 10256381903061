import { Input } from 'antd';
import Utils from 'helper/Utils'
import { add } from 'lodash';
import React from 'react'
import { useState } from 'react';
import ApiService from 'services/ApiService';
const { TextArea } = Input;
const NewComment = ({content,callback,appendNewComment}) => {
    const user = Utils.getLocalInfo("userhash")
    const [commentText, setcommentText] = useState("")
    const submitComment =async(e)=>{
        
        if (e.keyCode == 13 && !e.shiftKey) {
            e.preventDefault()
            const comment = commentText.trim();
            if(comment !== ""){
                const commented = await ApiService.PostApiCalls(`posts/${content["contentID"]}/comments`,{commentText},true)
                if(commented[0] && commented[1]["IsSuccessful"]){
                    setcommentText("")
                    callback("commented",commented[1]["post"],"")
                    appendNewComment(commented[1]["comments"])
                }
            }
        }
       
    }
    const chaneText =(e)=>{
        setcommentText(e.target.value)
    }
  return (
    <div className='flex items-center  px-4 py-1 '>
        <div className='action-profile rounded-full bg-red-400 overflow-hidden mr-1.5'>
            {/* <div className='w-100 h-100 bg-center bg-cover' style={{backgroundImage: `url(${user["profilePicture"]})`,}}></div> */}
            {user["profilePicture"] !==""? <div
          className="w-100 h-100 bg-center bg-cover"
          style={{
            backgroundImage:
              `url("${user["profilePicture"]}")`,
          }}
        ></div>:<span className="head-notificationsBox flex items-center w-100 h-100 justify-center text-white text-lg ">{user["name"][0]}</span>}
        </div>
        <div className='text-comment flex-1 box-radius-large bg-gray-100 py-1.5 px-1'>
        <TextArea bordered={false}
          placeholder="Write a comment..." maxLength={250}
          autoSize={{ minRows: 1, maxRows: 4}}
          className="leading-relaxed"
          onPressEnter={(e)=>submitComment(e)}
          onChange={(e)=>chaneText(e)}
          value={commentText}
        />
        </div>
        
    </div>
  )
}

export default NewComment