import { Button, Col, Row, Spin, Tabs } from "antd";
import React, { useContext, useState,useEffect } from "react";
import { VideoContext } from "app/VideoContext";
import Images from "../../views/Images";
import _ from "lodash";
import { EditIcon, DeleteIcon } from "res/icons/ReviewIcons";
import Utils from "helper/Utils";
import ApiService from "services/ApiService";
import UploadModal from "./UploadModal";
import AsserListView from "./AsserListView";
const { TabPane } = Tabs;
const StoryStyle = () => {
  const {
    showPreview,
    setactiveLefttab,
    activeLefttab,
    videoContent,
    setvideoContent,
  } = useContext(VideoContext);

  const [activeImages, setactiveImages] = useState(
    videoContent["posts"]["images"]
  );
  const [newUploads, setnewUploads] = useState([]);
  const [activeTabe, setactiveTabe] = useState("1");
  const [userUploads, setuserUploads] = useState(null);
  const [openModal, setopenModal] = useState(false);
  useEffect(() => {
    
    return () => {
      
    }
  }, [userUploads,newUploads])
  const callback = (key) => {
    setactiveTabe(key);
    // if (!userUploads && key === "2") {
      setuserUploads([]);
      getUserUploads()
    // }
  };
  const getUserUploads = async()=>{
    const userAssets = await ApiService.PostApiCalls("get-assets",{assetType:"background"},true)
    setuserUploads(userAssets[1]["list"])

  }
  const updateCampaignPost = async (res = {}) => {};
  const removeImage = (e, k) => {};

  const editImage = (e, k) => {};
  const ProgressCheck = (images) => {
    const image = Utils.filterArray(images, [
      "name",
      "uid",
      "type",
      "url",
      "size",
    ]);
    // console.log(images);
  };
  const ImageUploadCallback = async (images) => {
    const image = Utils.filterArray(images, ["image"]);
    let onlyImages = [];
    _.map(image, (single) => {
      onlyImages.push({...single["image"],assetType:"background"});
    });
    const saveAssets = await ApiService.PostApiCalls(
      "save-assets",
      { assets: onlyImages },
      true
    );

    setuserUploads([...onlyImages,...userUploads]);
    setnewUploads([])
    setopenModal(false);
  };
  return (
    <Row className="h-full w-100">
      <Col className="flex flex-col h-full story-article" lg={24}>
        <div className=" w-100">
          <Tabs
            defaultActiveKey="1"
            className="h-full w-full"
            centered
            onChange={callback}
          >
            <TabPane className="w-full" tab={"Story Images"} key="1"></TabPane>
            <TabPane className="w-full" tab={"Uploads"} key="2"></TabPane>
          </Tabs>
        </div>
        {activeTabe === "1" && (
          <div className="story-editor overflow-y-auto p-4">
            <div className="images-gallery-list">
              {activeImages.map((img, key) => (
                <div className="item" key={key}>
                  <img src={img.url ? img.url : img.src} alt=""></img>
                  <div className="image-action">
                    <div
                      className="abtn"
                      onClick={(e) => removeImage(e, img["uid"])}
                    >
                      <DeleteIcon />
                    </div>
                    <div className="abtn" onClick={(e) => editImage(e, img)}>
                      <EditIcon />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {activeTabe === "2" && (
          <div className="story-editor h-full flex flex-col px-4">
            <div className="flex items-center justify-center w-100 py-3">
              <Button
                onClick={() => setopenModal(true)}
                className="rounded-xl text-hs-pink border-hs-pink  hover:border-hs-pink"
              >
                Upload images and videos
              </Button>
              {openModal && (
                <UploadModal
                  prefix={videoContent["createdBy"]}
                  visible={openModal}
                  callback={setopenModal}
                  addedImages={newUploads}
                  type="background"
                  saveUploads={ImageUploadCallback}
                />
              )}
              {/* <FileUploader prefix={"images"} showUploadList={false} multipleImages={false} processCallback={ProgressCheck} callback={ImageUploadCallback} list={activeImages} limit={20} listType="none"
        view={<Button className="rounded-xl text-hs-pink border-hs-pink  hover:border-hs-pink" >Upload images and videos</Button>} 
        viewUploading={<div className="upload-imagebt">
        <Spin size="small"  spinning={true} />
        <span className="ml-2 pt-1 fw-700">Uploading...</span> </div>} /> */}
            </div>
            <div className="overflow-y-auto pb-20">
              {!userUploads && (
                <div className="py-4 text-center w-full">
                  <Spin />
                </div>
              )}
              {userUploads && userUploads.length === 0 && (
                <div className="py-4 text-center w-full">
                  <span>You have not uploaded anything yet!</span>
                </div>
              )}
              {userUploads && userUploads.length > 0 && (
                <div className="images-gallery-list">
                  {userUploads.map((img, key) => (
                    <AsserListView key={key} img={img} />
                    
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
        {/* <div>3</div> */}
      </Col>
    </Row>
  );
};

export default StoryStyle;
