import React,{useState} from 'react'
import {OverlayTrigger,Tooltip} from "react-bootstrap";
import {GeoAltFill,Facebook,Twitter,Instagram,Linkedin,InfoCircle,XCircle,CheckCircle} from "react-bootstrap-icons";
import { Checkbox,DatePicker,Input, } from 'antd';
import moment from 'moment';
import Utils from "helper/Utils"
import SocialIcon from 'container/common/SocialIcon';

import { useTranslation } from 'react-i18next';

const SocialProfileInfo = ({profile,showExtra,callback,callbackSelected,selectedProfiles}) => {
    const [startDate, setDateStart] = useState(0);
  const { t } = useTranslation();

    const [endDate, setDateEnd] = useState(0);
    
    const socialProfiles = profile;  
    const disabledDate=(current) =>{        
      return current && current < moment().subtract(1, 'day');
    }
  
    const verifyEmail =  (e) =>{
      if(!Utils.ValidateEmail(e.target.value)){
        callback("","email")
      }
    }
    const setStartDate =(e) =>{
      if(e){
      setDateStart(e.unix())
      callback([e.unix(),endDate],"date")}
      else{
        setDateStart(0)
        callback([0,endDate],"date")
      }
    }
    const setEndDate =(e) =>{   
      if(e)   {
      setDateEnd(e.unix())
      callback([startDate,e.unix()],"date")}
      else{
        setDateEnd(0)
        callback([startDate,0],"date")
      }
    }
    const AddRemoveProfile = (e) =>{
      callbackSelected(e)
    }
    return (
        <>
        <div className="d-flex flex-grow-1 align-items-center flex-wrap flex-direction-row" >
           

        <div className="d-flex flex-grow-1 align-items-center flex-wrap justify-content-center">
        <GeoAltFill/ >
        <span className="ml-2 pt-0 fw-700">{t("tt2")}</span></div>   
        <div className="w-100  d-flex flex-grow-1 align-items-center flex-wrap justify-content-center mt-3">
        {!showExtra && <> {
            socialProfiles.map((p,key) =>(
        <div className="profile-list review" key={key}>
       
        {!selectedProfiles.includes(p.id) && <div className="AddRemoveAction shown" onClick={() => AddRemoveProfile(p.id)}>
        <XCircle color={"#ff3d67"} size={20} className="hide" /><CheckCircle color={"#30B67C"} size={20} className="show" /></div>}
        {selectedProfiles.includes(p.id) && <div className="AddRemoveAction" onClick={() => AddRemoveProfile(p.id)}><XCircle color={"#ff3d67"} size={20} /></div>}

        <div className="image">
        <img src={p["fb-picture"]} alt={p.name}></img>
        </div>
        <SocialIcon icon={p.source.toLowerCase()} extraClass="source" />
        {/* {p.source === "Facebook" && <div  className="source facebook" ><Facebook color="#4267B2" size={24}/> </div>}
        {p.source === "Twitter" && <div  className="source twitter" ><Twitter color="#00acee" size={22}/> </div>}
        {p.source === "Linkedin" && <div  className="source linkedin" ><Linkedin color="#0e76a8" size={24}/> </div>}
        {p.source === "Instagram" && <div  className="source instagram" ><Instagram color="#fff" size={20}/> </div>} */}
       
        
        </div>
            ))
        }</>}
        {showExtra && <> {
            socialProfiles.map((p,key) =>(
        <div className="profile-list review" key={key}>
       
        {/* {!selectedProfiles.includes(p.id) && <div className="AddRemoveAction shown" onClick={() => AddRemoveProfile(p.id)}>
        <XCircle color={"#ff3d67"} size={20} className="hide" /><CheckCircle color={"#30B67C"} size={20} className="show" /></div>}
        {selectedProfiles.includes(p.id) && <div className="AddRemoveAction" onClick={() => AddRemoveProfile(p.id)}><XCircle color={"#ff3d67"} size={20} /></div>} */}
        {selectedProfiles.includes(p.id) && <>
        <div className="image">
        <img src={p["fb-picture"]} alt={p.name}></img>
        </div>
        {p.source === "Facebook" && <div  className="source facebook" ><Facebook color="#4267B2" size={24}/> </div>}
        {p.source === "Twitter" && <div  className="source twitter" ><Twitter color="#00acee" size={22}/> </div>}
        {p.source === "Linkedin" && <div  className="source linkedin" ><Linkedin color="#0e76a8" size={24}/> </div>}
       </>}
        
        </div>
            ))
        }</>}
        
        </div> 
        
        </div>
        <hr/>
        {showExtra && <div className="d-flex flex-grow-1 align-items-center flex-wrap flex-direction-row" >
        <div className="d-flex flex-grow-1 align-items-center flex-wrap justify-content-center mb-2">
        <GeoAltFill/ >
        <span className="ml-2 pt-0 fw-700">Campaign Boosters</span></div>
        <div className="d-flex flex-grow-1 align-items-start flex-wrap justify-content-center">
        <Checkbox onChange={(e) =>callback(e.target.checked,"reminder")} >Send me reminder updates</Checkbox></div> 
        <div className="d-flex flex-grow-1 w-100 align-items-start flex-wrap justify-content-center my-4 border-spacer"></div>
        <div className="d-flex flex-grow-1 align-items-center flex-wrap justify-content-center mb-2">
        <GeoAltFill/ >
        <span className="ml-2 pt-0 fw-700">Drip Campaign Settings</span></div>
        <div className="w-100 mb-3 d-flex align-items-center">
        <DatePicker className="w-100" format="DD-MM-YYYY" placeholder="Set Optional start Date"  disabledDate={disabledDate} onChange={setStartDate} />
        <OverlayTrigger
      key={0}
      placement={"top"}
      overlay={
        <Tooltip id={`tooltip-0`}>
          Do you want this campaign to start on a specific date? If selected date is full, we will start sending on the next available date.
        
        </Tooltip>
      }
    >
     <InfoCircle className="ml-2" size={14} color={"#1694E3"} />
    </OverlayTrigger></div> 
        <div className="w-100 mb-3 d-flex align-items-center ">
        <DatePicker className="w-100" format="DD-MM-YYYY"  placeholder="Set Optional stop Date" disabledDate={disabledDate} onChange={setEndDate} />
        <OverlayTrigger
      key={0}
      placement={"top"}
      overlay={
        <Tooltip id={`tooltip-0`}>
        Setting a stop date for this campaign will stop any content from being sent past this date.
        </Tooltip>
      }
    >
     <InfoCircle className="ml-2" size={14} color={"#1694E3"} />
    </OverlayTrigger></div> 
        <div className="w-100 mb-3 d-flex align-items-center">
        <Input placeholder="optional reviewer mail id" onBlur={(e) =>verifyEmail(e)}  onChange={(e) => callback(e.target.value,"email")}/>
        <OverlayTrigger
      key={0}
      placement={"top"}
      overlay={
        <Tooltip id={`tooltip-0`}>
        If you'd like to receive update reminder, please provide a valid email.
        </Tooltip>
      }
    >
     <InfoCircle className="ml-2" size={14} color={"#1694E3"} />
    </OverlayTrigger></div> 
        
        </div>  }
        </>
    )
}

export default SocialProfileInfo
