import React,{useEffect,useState,useContext} from 'react';
import {Container,Row,Col} from "react-bootstrap";
import {Spin,Checkbox,Button,Modal } from "antd";
import Utils from "helper/Utils" 
import ApiService from "services/ApiService"
import Slider from "react-slick-adaptive-height-fixed";
// import {X} from "react-bootstrap-icons";
import PostCard from "./views/PostCard"
import Info from "./views/Info"
import Timeline from "./views/Timeline"
import SocialProfileInfo from "./views/SocialProfileInfo"
import { Link, useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "app/AuthProvider";
import _ from 'lodash';
import "./style.css"
const { confirm } = Modal;
const CampaignPost = (props) => {
  const navigate = useNavigate()
    const {
        authUser,
        activeWorkSpace,
       
      } = useContext(AuthContext);
    const brPrams = useParams();
    const [loading, setLoading] = useState(true)
    const [campaign, setData] = useState(null)
    const [duration, setDuration] = useState(null)
    const [startenddate, setStartEndDate] = useState([0,0])
    const [reminder, setReminder] = useState(false)
    const [userConsent, setuserConsent] = useState(false)
    const [ApprovalEmail, setApprovalEmail] = useState(false)
    const [launching,setLaunchig] = useState(false)
    const [selectedProfiles,setselectedProfiles] = useState([])
    const [customTemplates,setCustomTemplates] = useState([]);
    const [schedulePost,setschedulePost] = useState([]);
    const [videoClips,setVideoClips] = useState(null);
    const [videoDetails,setvideoDetails] = useState(null);
    const [preview,setPreview] = useState(false)
    const [availableQuotes, setavailableQuotes] = useState([])
    const [availableImages, setavailableImages] = useState([])
    const [metaInfo, setMetaInfo] = useState(null)
    const [postLink, setpostLink] = useState(null)
    const [invaliddays, setInvalidDays] = useState([])
    const [currentCWProfiles, setAvailableProfiles] = useState([])
    const [scheduleList, setscheduleList] = useState({})
    const [schedList, setschedList] = useState([])
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        adaptiveHeight:true,
        draggable:false
        
      };
    const updateSettings = (data,action) =>{
      if(action === "date"){
        setStartEndDate(data)
      }
      else if(action === "reminder"){
       
        setReminder(data)
      }
      else if(action === "email"){
       
        setApprovalEmail(data)
      }
    }

    useEffect(() => {
        getPosts(brPrams.id);
        return () => {
            
        }
    }, [brPrams.id])
    const getPosts = async(id)=>{
        const posts = await ApiService.PostApiCalls("campaign/posts/list/"+id,{},true)
        const campaign = posts[1]["posts"][0]
        // console.log(campaign);
        setData(campaign)
        setMetaInfo(campaign["metaInfo"])
        setschedulePost(campaign["postlist"])
        setavailableQuotes(campaign["posts"]["quotes"])
        setavailableImages(campaign["posts"]["images"])
        setDuration(campaign["details"]["campaign-duration"])
        setVideoClips(campaign["VideoDetails"][0]?campaign["VideoDetails"][0]["clips"]:null)
        setvideoDetails(campaign["VideoDetails"][0]?campaign["VideoDetails"][0]:null)
        setpostLink(campaign["shortlink"]?campaign["shortlink"]:campaign["link"])
        setselectedProfiles(campaign["selectedProfiles"])
        const availablaeProfileForCw = Utils.getWorkSpaceSocialProfiles(authUser,activeWorkSpace["wid"])
        setAvailableProfiles(availablaeProfileForCw)
        const workspaceTemplates = _.filter(posts[1]["posts"][0]["customtemplates"], function (t,i) {
            return t["wid"] === posts[1]["posts"][0]["wid"];
          })
          let scheduleList = {
      
        } 
       
        campaign["defaultSchedules"].map((s,k)=>{
        scheduleList[[s["code"]]] = {slot:s["days"],days:s["postCount"]}
        }) 
        setscheduleList(scheduleList)
        setschedList(campaign["defaultSchedules"])
          setCustomTemplates(workspaceTemplates)
          setLoading(false)
    }
    const getTweetText = (text,link,tags) =>{
        let textOnly = text+" "+link;
      
      for(let i = 0; i <tags.length; i++){
        const old  = textOnly;
        if(tags[i].replace(/ /g,'').length > 0){
        textOnly = textOnly+" "+"#"+tags[i].replace(/ /g,'');
        
      } 
        
        
    }
    return textOnly;
    }
    const launchCampaign = () =>{
        setLaunchig(true)
          setTimeout(
            () =>  start(), 
            1000
        );
    }
    const verifyContentLength = () =>{
      const selction = _.filter(authUser["socialchannels"], function (time,i) {
        return selectedProfiles.includes(time["id"]);
      });
        const istwitter = _.filter(selction,function(e){
          return e.source === "Twitter"
        })
       let invalidDays = []
        if(istwitter.length > 0){
          schedulePost.map(p =>{
            const textin = getTweetText(p["qoute"],"Read full article "+postLink,p["tags"])
           
            if(p[istwitter[0]["id"]]){
              // console.log(istwitter[0]["id"]);
              const textin = getTweetText(p[istwitter[0]["id"]]["text"],p[istwitter[0]["id"]]["linktext"],p[istwitter[0]["id"]]["tags"])
              // console.log(textin);
              console.log(p);
              console.log(textin);
              if(textin.length > 280){
                invalidDays.push(p["day"])
              }
            }
            else if(p["all"]){
              // console.log(p);
              const textin = getTweetText(p["all"]["text"],p["all"]["linktext"],p["all"]["tags"])
              // console.log(textin);
              if(textin.length > 280){
                invalidDays.push(p["day"])
              }
            }
            else{
              

              if(textin.length > 280){
                invalidDays.push(p["day"])
              }
            }
          })
          setInvalidDays(invalidDays)
          
          if(invalidDays.length > 0){
            return [false,invalidDays];
          }
          return [true,null];
        }
        else{

          return [true,null];
        }
    }
    const start = async() =>{
      const val = verifyContentLength();
    
      if(!val[0]){
        confirm({
          title: 'Character limit exceeded',               
          content: 'Character limit has been exceeded, check days '+val[1].join(", ")+'. Check Twitter content for sure, by switching profiles.',
          okText: 'Got it',
          cancelText: "Cancel",
          
          okButtonProps:{ type: "primary",className:"brd-4" },
          okType: 'danger',
          cancelButtonProps:{ className:"brd-4 mr-3 d-none" },
          async onOk() {
               
                             
          },
          onCancel() {
            
          },
        });
        setLaunchig(false)
        return false;
      }
      
      const data = {
        cid:campaign["cid"],
        StartEndDate:startenddate,
        reminder:reminder,
        ApprovalEmail: ApprovalEmail
       }
      const launched = await ApiService.PostApiCalls("campaign/posts/launch/"+campaign["cid"],{...data},true)
     
      navigate("/creator/"+activeWorkSpace["slug"]+"/campaigns/");
      
      setLaunchig(false)
    }
    const playVideo = () =>{
    }
    const updatePostContent = async (data,index) => {
        let tempContent = schedulePost;
          
          tempContent[index] = data;
          const postdata = {
            cid:campaign["cid"],
            updates:{scheduledPosts:tempContent}
           }
          
          const updatePost = await ApiService.PostApiCalls("campaign/posts/update/"+campaign["cid"],{...postdata},true)
          if(updatePost[1]){
            setschedulePost(tempContent)
          }
    }
    // console.log(schedulePost);
    if(loading){
        return (
          <Container fluid="true" className="px-0">
        <Row className="mx-0">

            <Col className="px-0 py-5 mt-4 d-flex align-items-center justify-content-center"
             lg={12}      
             md={12}
             sm={12} >
            <Spin />
             </Col></Row>
               
               </Container>
             )
      } 
    return (
        <>
        <div className="sidebar-secondary with-scroll">
        <Info campaign={campaign} />
        <Timeline duration={duration} hide={true} scheduleList={scheduleList} schedList={schedList} />
        <hr/>
        <SocialProfileInfo selectedProfiles={selectedProfiles} profile={currentCWProfiles}  showExtra={true} callback={updateSettings} />
        
        </div>
        <div className="">
        <Container fluid="true" className="px-0">
        <Row className="mx-0 px-4">
        <Col className="px-0"
             lg={12}      
             md={12}
             sm={12} >
             <Row className="mx-0">
             <Col className="px-0 fz-18 fw-700"
             lg={12}      
             md={12}
             sm={12} >Social Posts</Col>
             <Col className="px-0 fz-14 fw-600"
             lg={12}      
             md={12}
             sm={12} >Now that you've chosen your content and media, it's time to review the social posts that will be sent to promote this blog post.</Col>
            
             </Row>
             </Col>
            
             <Col className="px-0 py-3 mx-auto"
             lg={6}      
             md={12}
             sm={12} >
            <Slider {...settings}>
            
            {schedulePost.map((post,key)=>(
              <PostCard currentCWProfiles={currentCWProfiles} postLink={postLink} availableImages={availableImages} selectedProfiles={selectedProfiles} updatePostContent={updatePostContent} playvideo={(e) => playVideo()} videoClips={videoClips}  text="3" key={key} id={key} post={post} content={campaign.posts} availableQuotes={availableQuotes} mintedHashs={campaign["mintedHashs"]?campaign["mintedHashs"]:[]} customTemplates={customTemplates} metaInfo={campaign}/>
            ))}
            
        </Slider>
             </Col>
             
             <Col className="px-0 fz-14 my-4 fw-600"
             lg={12}      
             md={12}
             sm={12} > 
             <Row className="mb-4">
             <Col lg={6}><Checkbox onChange={(e) =>setuserConsent(e.target.checked)}>I agree this is my content</Checkbox></Col>
             <Col lg={6}>
             <Button type="primary" loading={launching} onClick={launchCampaign} className="float-right launch-btn " disabled={!userConsent}>Launch Campaign</Button>
             <Link to={`/creator/${activeWorkSpace["slug"]}/campaigns`}>
             <Button type="link" className="float-right">Back to board</Button></Link>
          </Col>
        </Row>
             
             </Col>
            </Row>
               
            </Container></div>
           
            </>
    )
}

export default CampaignPost
