import React, { useState } from 'react'
import {useStripe, useElements, Elements,CardElement} from '@stripe/react-stripe-js';
import CardInput from './CardInput';
import { Button, Col, Input,Modal } from 'antd';
import Utils from 'helper/Utils';
import { useTranslation } from 'react-i18next';
import ApiService from 'services/ApiService';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

const { confirm } = Modal;

const PaymentCard = ({user,paymentCallback,plan=null,selectedPlan=null}) => {
  const { t } = useTranslation();

    const [cardName,setCardName] = useState("");  
    const [isSaving,setSaving] = useState(false);
    const [added,setAdded] = useState(null);
    const [cpCode, setcpCode] = useState("")
    const [verifyingCpCode, setverifyingCpCode] = useState(false)
    const [cpApplied, setcpApplied] = useState(false)
    const [cpData, setcpData] = useState(null)
    const [error,setError] = useState(null);

  const stripe = useStripe();
  const elements = useElements();  
  const addNewCard = async()=>{
    if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
    }
        setSaving(true)
        
        setAdded(false);
        if(cardName === ""){
            Utils.showNotification("error","Missing details","Enter name for card holder")
            setSaving(false)
            return ;
        }
        const result = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
            billing_details: {
              email: user.email,
              name:cardName
            },
          });
          
          if (result.error) {
            //   setError(result.error.message);
            Utils.showNotification("error","Card Error",result.error.message)

              setSaving(false)
          } else {
            
            const cardDetails = {brand:result["paymentMethod"]["card"]["brand"],"last_four":result["paymentMethod"]["card"]["last4"]}
            const paymentMethodId = result.paymentMethod.id;
          
            setSaving(false)
            paymentCallback(cardDetails,paymentMethodId,cardName,cpData);
            
          }
  }
  const decodeCPMessage = ()=>{
    if(cpData === null){
      return "";
    }
      if(cpData["duration"] === "once"){
        return cpData["percent_off"] ? `${cpData["percent_off"]}% off on first bill`:`$${cpData["amount_off"]} off on first bill`
      }
      else if(cpData["duration"] === "forever"){
        return cpData["percent_off"] ? `${cpData["percent_off"]}% off on each bill`:`$${cpData["amount_off"]} off on each bill`
      }
      else if(cpData["duration"] === "repeating"){
        return cpData["percent_off"] ? `${cpData["percent_off"]}% off on first ${cpData["duration_in_months"]} bills`:`$${cpData["amount_off"]} off on first ${cpData["duration_in_months"]} bills`
      }
        
      
  }
  const verifyCPCode = async(cc=null)=>{
      setverifyingCpCode(true);
      setError(false)
     
      const cpDetails = {
        cpCode:cc?cc:cpCode,
        plantoSuscribe:plan?"as":"price_1LVcPhSCziW5a8WnVS2UxBQx",
      }
      const cd = await ApiService.PostApiCalls("payment/verifyCpn",cpDetails,true);
      if(cd[0] && cd[1]["IsSuccessful"]){
        setcpData(cd[1]["d"])
        setcpApplied(true);
        setverifyingCpCode(false)

      }
      else if(cd[0] && !cd[1]["IsSuccessful"]){
        setverifyingCpCode(false)
        setError(cd[1]["ErrorMessage"])
      }
      else{
        setverifyingCpCode(false)
        setError("Something went wrong, try again")
      }
      
     
  }
  const removeCpCode = async() =>{
    setcpApplied(false);
    setcpData(null);
    setcpCode("")
    setverifyingCpCode(false)
  }
  const SkipCardAndConfirm = () =>{
    confirm({
      title: 'Do you want to procced without adding card?',
      icon: <ExclamationCircleOutlined />,
      content: 'Your account will be active for 2 months. You need to add card later to continue using app services.',
      okText: "Yes",
      async onOk() {
        const subscriptionDetails = {
          update: {planId:selectedPlan["id"],id:selectedPlan["id"],
          code:"monthly",
          duration:selectedPlan["duration"],
          email:user["email"],
          paymentInfo:null,
          status:'active',
          FreeTrailStartedAt:moment().unix(),
          FreeTrailEndAt:moment().unix()+5184000,
          }
      }
      
      setSaving(true);
        const data = await ApiService.PostApiCalls("payment/createFreeTrial",subscriptionDetails,true)
        if(data[0] && data[1]["IsSuccessful"]){
          paymentCallback(null,null,null,null,true);
          
        }
        else{
          Utils.showNotification(
            "error",
            "Oops",
            "Something went wrong. Try again"
          );
        }
        setSaving(false);
      },
      onCancel() {},
    });
  }
  return (
    <><Col className="px-0 fz-16 py-1 fw-700"
    lg={12}      
    md={12}
    sm={12} >{t("step5Input1")}</Col>
     <Col className="px-0 fz-14 py-1 mb-3 fw-600"
    ><Input value={cardName} onChange={(e) => setCardName(e.target.value)} placeholder="Enter your name" /></Col>
    <Col className="px-0 fz-16 py-1 fw-700"
    lg={12}      
    md={12}
    sm={12} >{t("step5Input2")}</Col>
    <Col className="px-0 fz-14 py-1 mb-3 fw-600"
    ><CardInput /></Col>
    <Col className="px-0 fz-14 py-1 mb-3 fw-600"
    >{cpApplied? "":"Have coupon?"}</Col>
    {cpApplied? <Col className="px-0 fz-12  py-2 fw-600 w-100 mb-2 d-flex flex-row "
     span={24} >
       <div className="cpApplied p-2 d-flex align-items-center"><div className="d-flex flex-column mr-4">
         <span className="fz-14 fw-600">{cpCode}</span><span>{decodeCPMessage()}
</span></div>
        <Button key="apl"  type="text"  className="brd-0 mx-0" onClick={()=>removeCpCode()} >Remove</Button> </div>
        <div className=" px-5 py-2 "></div>
     </Col>:<Col className="px-0 fz-12  py-2 fw-600 w-100 mb-2"
     span={24} >
      <Input placeholder="Enter coupon code" value={cpCode} onChange={(e)=>setcpCode(e.target.value)} className="brd-0 mx-0  w-auto" />
      <Button key="apl"  type="primary" loading={verifyingCpCode} disabled={cpCode.trim() === ""?true:false} className="brd-0 mx-0" onClick={()=>verifyCPCode()} >Apply</Button>   
     </Col>}
     <Col className="px-0 fz-12 py-1 mb-3 text-xs text-gray-400"
     span={24}>Card details and payments securely stored and handled by <a target="_blank" rel="no-ref" href="https://stripe.com/">Stripe</a>.</Col>
    {
         error && <Col className="px-0 fz-12 py-1 pb-3 text-sm text-danger"
         span={24}>
             <span className="text-center w-100 fw-600 pt-1 fz-14 align-self-center color-red">{error}</span> 
         </Col>
     }
    <Col className="px-0 fz-14 py-1 mb-3 fw-600 flex space-x-4 justify-end "
    >
      
      {selectedPlan && selectedPlan["id"] === 1 && <Button key="next" loading={isSaving}   type="default" className="brd-4 mx-3 float-right" onClick={SkipCardAndConfirm}>Add Later</Button>}
      {!added && <Button key="next" loading={isSaving}   type="primary" className="brd-4 mx-3 float-right" onClick={addNewCard}>
    {isSaving ?"Please wait":"Pay & Proceed"}
  </Button>}
      </Col>
    </>
  )
}

export default PaymentCard