import React from "react";
import { Navbar, Nav, Button } from "react-bootstrap";
import { Search, Bell, ChevronCompactDown } from "react-bootstrap-icons";
import { Input, Badge, Menu, Dropdown } from "antd";
import Utils from "helper/Utils";
import { useContext } from "react";
import { VideoContext } from "app/VideoContext";
import BluLogo from "assets/BleLogo";
const HeaderVideo = ({noPadd}) => {
  const {setshowPreview} = useContext(VideoContext)
  const logout = ()=>{
    Utils.processAuthOut()
    window.location.reload();
  }
  const user = Utils.getLocalInfo("userhash")

  const profileMenu = (
    <Menu className="rounded-l p-2">
      <Menu.Item key="1" className="rounded-l py-2">Account Settings</Menu.Item>
      <Menu.Item key="2" className="rounded-l py-2">Notifications</Menu.Item>
      <Menu.Item onClick={logout} key="3" className="rounded-l py-2">Logout</Menu.Item>
    </Menu>
  );
  return (
    <div className={noPadd?"fixed w-full top-0 left-0 border-b z-40 bg-hg":"fixed w-full top-0 left-0 p-3 z-40 bg-hg"}>
      <Navbar
        id="headerfixed"
        expand="lg"
        className="header-bar auth  w-full flex justify-around bg-white py-0"
      >
        <Navbar.Brand href={"/beta"} className="py-2">
          <span className="text-h text-base font-semibold">
          {/* <BluLogo /> */}
          <span className="text-hr">#</span>HashSaga
          </span>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse
          id="basic-navbar-nav"
          className="justify-content-between"
        >
          <Navbar className="text-center flex justify-center flex-1 justify-content-center">
            
          </Navbar>
          <Nav className="mr-right">
            <Nav.Item className="space-x-2 border-r pr-2  py-2">
              <Button
                className="rounded-xl focus:shadow-none"
                variant="primary" onClick={()=>setshowPreview(true)}
              >
                Preview
              </Button>
              {/* <Button
                className="rounded-xl focus:shadow-none"
                variant="primary"
              >
                Publish
              </Button> */}
            </Nav.Item>
            <Nav.Item className="px-4 cursor-pointer flex items-center">
              <Badge count={0} showZero={true}>
                <div className="head-notificationsBox d-flex items-center justify-center rounded-xl">
                  <Bell size={20} color={"#ffffff"}></Bell>
                </div>
              </Badge>
            </Nav.Item>
            <Nav.Item className="pr-1 visible  flex items-center">
              <Dropdown
                overlay={profileMenu}
                placement="bottomRight"
                trigger="click"
                className="cursor-pointer"
              >
                <div className="flex items-center space-x-2">
                  <span className="head-notificationsBox rounded-full overflow-hidden flex items-center justify-center text-white text-xl bg-blue-500">
                    {user.profilePicture ? 
                    <img alt={user.name} src={user.profilePicture } />
                      :user.name[0]
                  }
                  </span>
                  {/* <span className="text-h font-semibold ">{user.name}</span> */}
                  <span className="text-h">
                    <ChevronCompactDown />
                  </span>
                </div>
              </Dropdown>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default HeaderVideo;
