
import { Button } from "antd";
import CardVisual from "components/feed/CardVisual";
import CardVisualSingle from "components/feed/CardVisualSingle";
import SocialIcon from "container/common/SocialIcon";
import moment from "moment";
import React from "react";
import { CalendarEvent, Check2Circle, CheckCircle } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

const CustomPostCard = ({ content, id,type ,slug,rv,hideVisual}) => {
  
  return (
    <div className="cursor-pointer">
      {!hideVisual && <CardVisualSingle text={content.content} assets={content["image"][0]?content["image"]:null} visual={content["image"][0] && content["image"][0]?content["image"][0]["url"]:"https://hs-container.s3.ap-south-1.amazonaws.com/hashapp/campaign-images/campaign-53606/Group-of-People-in-City-1024x683.jpg"} type={content["image"] && content["image"][0] && content["image"][0]["visualType"]?content["image"][0]["visualType"]:"noimage"} CampaignType={""}  />}
      <div className="py-2"></div>
      <div className="px-1 line-clamp-2 font-normal text-h text-sm">
        {content.qoute}
      </div>
      <div className="py-1"></div>
      <div className="px-2 line-clamp-2 font-normal text-xs text-blue-500">
       {/* <a href={content["link"]} target="_blank" >{content["link"]}</a>  */}
      </div>
      <div className="py-1"></div>
      <div className="px-2 font-normal text-xs text-h flex flex-row items-center">
      {content.posted && content.posted.length > 0 && content.posted.map((d,i)=>(
       <span className="mr-2"><SocialIcon icon={d.socialchannel.toLowerCase()} /></span> 
      ))}
      
    
      </div>
      <div className="py-2"><span className="text-bold text-xs text-hs-pink px-2">{content["posted"] ?" - Sent to profile":" - Scheduled"}</span></div>
      {/* {content["posted"] ?<div className="px-2 flex flex-row space-x-2 items-center">
      <CheckCircle size={18} color={"green"}/>
        <span className="ml-2">
          Sent on {content["time"]?moment.unix(content["time"]).format("MMM Do, hh:mm a"):moment.unix(content["UpdatedAt"]).format("MMM Do, hh:mm a")}
        </span>
        
      </div>:
      <div className="px-2 flex flex-row space-x-2 items-center">
       <CalendarEvent />
        <span className="ml-2">
        {content["time"]?`Will be sent on ${moment.unix(content["time"]).format("MMM Do, hh:mm a")}`:"Post will be sent soon"}
        </span>
      </div>} */}
      <div className="py-1"></div>
    </div>
  );
};

export default CustomPostCard;
