import React from 'react'
import { List, Typography, Divider } from 'antd';
import { ChevronBarRight, ChevronRight } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

const data = [
  {slug: 'profile',lable:"Profile"},
  {slug: 'account',lable:"Account"},
  {slug: 'dating-profile',lable:"Dating Profile"},
  // {slug: 'security',lable:"Privacy and Safety"},
  {slug: 'notifications',lable:"Notifications"},
  {slug: 'subscription',lable:"Subscription & Billing"},
  
  
];
const dataGeneral = [
    
    {slug: 'display',lable:"Display"}
    
  ];
const SettingsLeftList = ({user,active}) => {
    
  return (
      <>
       <div className='pb-2 bg-gray-100'></div>
    <List
      header={<div className='font-semibold px-2'>
          <Link className='text-hs-dark px-1' to={`/${user["userName"]}`}>
          @{user["userName"]}</Link></div>}
      className="bg-white pb-3"

      dataSource={data}
      renderItem={item => (
        <List.Item className='cursor-pointer p-2'>
          <Link className='text-hs-dark px-1 flex w-100' to={`/my/settings/${item["slug"].toLowerCase()}`}>
              <span className={item["slug"].toLowerCase() === active ?'flex justify-between items-center w-100 p-2 bg-blue-50 text-blue-400':'flex justify-between items-center w-100 p-2 hover:bg-blue-50 hover:text-blue-400'}>
              <span>{item["lable"]}</span>
              <ChevronRight size={20} />
              </span></Link>
        </List.Item>
      )}
    />
    <div className='pb-2 bg-gray-100'></div>
    <List
    className="bg-white pb-3"
      header={<div className='font-semibold px-2'>General</div>}
      dataSource={dataGeneral}
      renderItem={item => (
        <List.Item className='cursor-pointer p-2'>
        <Link className='text-hs-dark px-1 flex w-100' to={`/my/settings/${item["slug"].toLowerCase()}`}>
              <span className={item["slug"].toLowerCase() === active ?'flex justify-between items-center w-100 p-2 bg-blue-50 text-blue-400':'flex justify-between items-center w-100 p-2 hover:bg-blue-50 hover:text-blue-400'}>
              <span>{item["lable"]}</span>
              <ChevronRight size={20} />
              </span></Link>
      </List.Item>
      )}
    />
    </>
  )
}

export default SettingsLeftList