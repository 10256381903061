import { Badge, Drawer,Avatar, List, notification } from 'antd'
import React, { useEffect,useState } from 'react'
import { Bell } from 'react-bootstrap-icons'
import { FaProductHunt } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';

import ApiService from 'services/ApiService';
import io from 'socket.io-client';

const socket = io('https://hashsaga.com:3007');
const Notifications = ({user}) => {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false);
    const [count, setcount] = useState(0)
    const [list, setlist] = useState([])

  const showDrawer = () => {
    setcount(0)
    setOpen(true);
    getNotofications(false)
  };

  const onClose = () => {
    setOpen(false);
  };
 
  
  useEffect(() => {
    
    socket.on('notification', (data) => {
      const oc = count;
      
      setcount(oc+1)
        openNotification(data)
       
        // Handle the notification in your app
      });
      socket.emit('user_connected', {username:user.uniqueId});
  return () => {
    // socket.disconnect();
    socket.off('notification');
  }
}, [])
    useEffect(() => {
      
          
      return () => {
        // socket.disconnect();
      }
    }, [user])
    useEffect(() => {
      getNotofications(true)
    
      return () => {
        
      }
    }, [])
    const openNotification = (data) => {
      notification.open({
        message: data.title,
        description:
          data.message,
        onClick: () => {
          navigate(data.link)
        },
      });
    };
    const getNotofications = async(lenin=false)=>{
        const listin = await ApiService.PostApiCalls("users/notifications/list",{},true)
        
        if(listin[0] && listin[1]["IsSuccessful"]){
            setlist(listin[1]["list"])
            setcount(lenin?listin[1]["list"].length:0)
        }
    }
    const descirptionsList = {
        "brand-promotion":"Your product promotion has been approved."
    }
    const showDetails = async(id)=>{
        onClose()
        const listin = await ApiService.PostApiCalls("users/notifications/update",{id:id},true)
        
    }
  return (
    <>
     <Drawer title="Notifications" bodyStyle={{padding:0}} onClose={onClose} open={open} visible={open}>
     <List
    itemLayout="horizontal" className='cursor-pointer'
    
    dataSource={list}
    renderItem={(item) => (
      <Link to={item.source.link?item.source.link:"/creator/social-commerce/promotions"}><List.Item onClick={()=>showDetails(item._id)} className={item.read?'px-3 border-blue-100 ':'px-3 bg-gray-50 border-blue-100'}>
        <List.Item.Meta
        //   avatar={<Avatar icon={<BreadcrumbItem />}/>}
          title={item.source.title}
          description={item.source.message?item.source.message:descirptionsList[item.source.type]}
        />
      </List.Item></Link>
    )}
  />
      </Drawer>
    <Badge count={count} showZero={true} onClick={showDrawer}>
									<div className="head-notificationsBox d-flex items-center justify-center rounded-xl">
										<Bell size={20} color={"#ffffff"}></Bell>
									</div>
    </Badge>
    </>
  )
}

export default Notifications