import { Button, Checkbox, Form, Input, InputNumber, Modal,Select,message } from 'antd'
import React,{useState,useEffect,useContext} from 'react'
import {Elements,PaymentElement} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import ApiService from 'services/ApiService';
import { AuthContext } from 'app/AuthProvider';
import jsonData from '../../../res/extra/country_dial_list.json';
import ListingPaymentForm from './ListingPaymentForm'
import Utils from 'helper/Utils'
import { Link } from 'react-router-dom';

const { Option } = Select;
const RequestModal = ({alreadyBroker,isopen,details,callback}) => {
    const {setAuthUser,setactiveWorkSpace} = useContext(AuthContext)
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(isopen);
    const [modalDetails, setmodalDetails] = useState(details)
    const [paymentSecret, setpaymentSecret] = useState(null)
    const [prceedBuy, setprceedBuy] = useState(false);
    const [formValues, setformValues] = useState(null)
    const [sending, setsending] = useState(false)
    const [isBroker, setisBroker] = useState(alreadyBroker)
    const [basicInfo, setbasicInfo] = useState({fullname:alreadyBroker?alreadyBroker.fullname:"",
  phone:alreadyBroker?alreadyBroker.phone:"",email:alreadyBroker?alreadyBroker.email:"",prefix:"+91"})
    const stripePromise = loadStripe('pk_test_51LDMFTSCziW5a8WnahZL7oy2kpBCIdxFQVcwC1U0yDU7Ak7J0aclUaVHAlqsMncC5fDl6WyLmFiuq02j5pW9Gwv800V30n2tKD');

    useEffect(() => {
      
        setbasicInfo(({fullname:isBroker?isBroker.fullname:"",
        phone:isBroker?isBroker.phone:"",email:isBroker?isBroker.email:"",prefix:"+91"}))
        
        return () => {
          
        }
      }, [isBroker])
      const handleOk = () => {
        setprceedBuy(false)
        setIsModalOpen(false);
        setformValues(null)
      };
      const handleCancel = () => {
        setIsModalOpen(false);
        setprceedBuy(false)
        setformValues(null)
        callback()
      };
      const handleChangeCode = (v)=>{
    
      }
      const prefixSelector = (
        <Form.Item name="prefix" noStyle>
          <Select
            style={{
              width: 70,
            }}
            
            name="prefix-code"
            showSearch
            defaultValue={['+91']}
            // searchValue='+91'
    onChange={handleChangeCode}
    optionLabelProp="label"
    showArrow={false}
            dropdownMatchSelectWidth={false}
            // filterSort={(optionA, optionB) =>
            //     (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            //   }
          >
            {
                jsonData.map((cnt,i) => <Option key={i} value={`${cnt.value}(${cnt.label})`} label={cnt.value} >{`${cnt.value}(${cnt.label})`}</Option>)
            }
            
            
          </Select>
        </Form.Item>
      );
      const onFinishLogin = async(values)=>{
        // setsending(true)
        const modein = localStorage.getItem("appUsageMode")
        const formContent = {
          role: "creator",
          
          email: values.email.toLowerCase(),
          rememberSession: true,
          password: values.passwordin,
          accountSource: "Email",
          appMode:modein?modein:"earning",
          brokerCheck:true
        };
        
        const response = await ApiService.PostApiCalls("account/login", formContent);
        if (response[0]) {
          const serverRespons = response[1];
          if (serverRespons["IsSuccessful"]) {
            Utils.processAuthIn( serverRespons["user"]);
            const v = await ApiService.PostApiCalls("account/veriyAuth",{token:serverRespons["user"].token},true);
              if(v[0] && v[1]["IsSuccessful"]){
                const currentUser = v[1]["user"];
                setAuthUser(v[1]["user"])
                
                const activeWorkSpace = v[1]["user"]["workspace"][0]?v[1]["user"]["workspace"][0]:null
                setactiveWorkSpace(v[1]["user"]["workspace"][0]?v[1]["user"]["workspace"][0]:null)
                const result = await ApiService.PostApiCalls("street/broker/verify",{},true)
        
                if(result[0] && result[1]["IsSuccessful"]){
                  setisBroker(result[1]["d"])
                }
  
              }
              else{
                Utils.showNotification(
                  "error",
                  "Oooops",
                  serverRespons["ErrorMessage"]
                );
              }
          }
        }
      }
    const onFinish = async(values) => {
        if(modalDetails.ListingType === "guaranteed" && modalDetails.guaranteeType === "Open"){
          
          const dataIn = { "amount":parseInt(values.bidAmount?values.bidAmount:modalDetails.GuaranteeAmount)*100,
          "currency":"INR"}
          setformValues(values)
          const res = await ApiService.PostApiCalls("/payment/create-payment-intent",dataIn,true)
          if(res[0] && res[1]["IsSuccessful"]){
            setpaymentSecret(res[1]["clientSecret"])
            setprceedBuy(true)
            
          }
          else{
            Utils.showNotification("error", "Oopps", "Something went wrong",3);
          }
        }
        else{
          
          
          //
          const sendreq = await ApiService.PostApiCalls("street/listing/request",{...values,listingId:modalDetails._id,listedBy:modalDetails.listedBy,title:`${modalDetails.BHK}, ${modalDetails.Locality}`},true)
          if(sendreq[1]["IsSuccessful"]){
            message.success('Request sent successfully.');
            handleCancel()
          }
          else{
            message.error('Something went wrong, try again.');
          }
        }
       
      }
      const confirmPayment = async(payinfo)=>{
       
        const paydata = {
          ...payinfo.payInfo,
          amount:payinfo.tokens
        }
        
        const sendreq = await ApiService.PostApiCalls("street/listing/request",{...formValues,listingId:modalDetails._id,paymentInfo:paydata,listedBy:modalDetails.listedBy,title:`${modalDetails.BHK}, ${modalDetails.Locality}`},true)
        if(sendreq[1]["IsSuccessful"]){
          message.success('Payment received & request sent successfully.');
          handleCancel()
        }
        else{
          message.error('Something went wrong, try again.');
        }
      }
  return (
    <>
    <Modal bodyStyle={{padding:0}} footer={null} title="Connect with seller" visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <div>
       {
        !isBroker ?
        
        <Form
        name="normal_login"
        className="login"
        initialValues={{
          
          layout: "vertical",
          email:"",passwordin:""
          
        }}
        layout={"vertical"}
        onFinish={onFinishLogin}
        >
        <div  className='p-4'>
          <Form.Item className='text-center font-semibold' >Login to Broker Account</Form.Item>
           <Form.Item  name="email"
        label="Email"
        
        rules={[
          {
            type: 'email',
            message: 'The input is not valid E-mail!',
          },
          {
            required: true,
            message: 'Please input your email!',
            whitespace: true,
          },
        ]} >
          <Input className='rounded'/>
           </Form.Item>
           <Form.Item  name="passwordin"
        label="Password"
        
        rules={[
          {
            required:true,
            message: 'Password is required!',
          },
         
        ]} >
          <Input.Password className='rounded' />
           </Form.Item>
           <Form.Item className='mb-0' >
        
        <Button type="primary" loading={sending}  htmlType="submit" size='large'  className={`px-3 brd-4 fw-700 mx-2 rounded-lg text-white bg-hs-pink  hover:border-hs-pink `}>
          Submit
        </Button>
       <Link className='' to={"/creator/edit/start"}><Button type='link'>New User? Register here</Button></Link>
      </Form.Item>
         </div></Form>
        :
        !prceedBuy?<Form
        name="normal_login"
        className="login-form"
        initialValues={{
          
          layout: "vertical",
          ...basicInfo
        }}
        layout={"vertical"}
        onFinish={onFinish}
        form={form}
        >
        <div  className='p-4'>
        <Form.Item><span><span className='font-semibold'>{`${modalDetails.BHK}`}</span>{` for ${(modalDetails.listingFor).toLowerCase()} in ${modalDetails.Locality}`}</span>
        </Form.Item>
        <Form.Item
        name="phone"
        
        rules={[
          {
            required: true,
            message: 'Please input your phone number!',
          },
        ]}
      >
        <InputNumber
          addonBefore={prefixSelector}
          placeholder='Enter Phone Number'
          className='rounded'
          controls={false}
          maxLength={10}
          name="phone"
          style={{
            width: 340,
          }}
         size='large'
         autoComplete='off'
        />
      </Form.Item>
      <Form.Item
        name="fullname"
        
        
        rules={[
          {
            required: true,
            message: 'Please input your name!',
            whitespace: true,
          },
        ]}
      >
        <Input placeholder='Name' size='large' className='rounded' style={{
            width: 340,
          }}/>
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
        
        rules={[
          {
            type: 'email',
            message: 'The input is not valid E-mail!',
          },
          {
            required: true,
            message: 'Please input your email!',
            whitespace: true,
          },
        ]}
      >
        <Input placeholder='Email' size='large' className='rounded' style={{
            width: 340,
          }}/>
      </Form.Item>
      {
        modalDetails.ListingType === "guaranteed" && <>
          <Form.Item><span>{`This property is listed as ${(modalDetails.guaranteeType).toLowerCase()} guarantee transaction with guarantee amount of `}<span className='font-semibold'>{`₹${Utils.getAmountInFormat(parseInt(modalDetails.GuaranteeAmount))}.`}</span></span></Form.Item>
          {
            modalDetails.guaranteeType === "Auction" &&
            <Form.Item
        name="bidAmount"
        label={`Your Bid - Min bid ₹${Utils.getAmountInFormat(parseInt(modalDetails.GuaranteeAmount))}`}
        className='mb-3'
        rules={[
          {
            required: true,
            message: 'Bid amount is required',
            
          },
        ]}
      >
        
          <InputNumber controls={false} max={10000000} min={parseInt(modalDetails.GuaranteeAmount)} prefix={"₹"} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}  placeholder='Your bid' className='rounded-md p-1' style={{
           width: 340,
          }}/>
      </Form.Item>
          }
          <span className='text-xs text-muted '>
            {modalDetails.guaranteeType === "Open"?"You need to pay the guarantee now, if your offer is not accepted it will refunded to original payment source.":"You need to make payment of bid amount once your bid is accepted."}</span>
          
          <Form.Item
        name="agreement"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value ? Promise.resolve() : Promise.reject(new Error('You should accept agreement')),
          },
        ]}
        
      >
        <Checkbox>
          I have read the <a href="">Guarantee Agreement</a>
        </Checkbox>
      </Form.Item>
        </>
      }
      </div>
      <div className='flex p-3 pb-0 border-t-2 justify-end space-x-3'>
      <Form.Item className='mb-0' >
        
       
        <Button type="primary" loading={sending}  htmlType="submit" size='large'  className={`px-3 brd-4 fw-700 mx-2 rounded-lg text-white bg-hs-pink  hover:border-hs-pink `}>
          {modalDetails.ListingType === "guaranteed" && modalDetails.guaranteeType === "Open"?"Proceed to payment":"Send Request"}
        </Button>
       
      </Form.Item>
      </div>
        </Form>
        :
        <>
        <Elements stripe={stripePromise} options={{
        
        clientSecret: paymentSecret
      }} >
        <ListingPaymentForm tokens={parseInt(formValues.bidAmount?formValues.bidAmount:modalDetails.GuaranteeAmount)} callback={confirmPayment} clientSecret={paymentSecret} setprocessingCall={setsending} />
      </Elements>
        </>
       }
       
       
        </div>
      </Modal></>
  )
}

export default RequestModal