import React from 'react'
import Marquee from "react-easy-marquee";
import WallCard from './WallCard';
import WallCardMusic from './WallCardMusic';
const DisplayWall = ({filter}) => {
   console.log(filter);
  return (
    <div className='w-100 overflow-hidden'>
        
        <Marquee className='displya-wall' duration={200000} background="#fafafa" height="650px" reverse={true}
        axis="y"

        >
        {filter === "music"?
        <WallCardMusic size={4}/>:
        <WallCard size={4} filter={filter}/>
        }
        
      </Marquee>
    </div>
  )
}

export default DisplayWall