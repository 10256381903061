import React,{useState,useContext,useEffect} from 'react'
import {Col} from "react-bootstrap";
import { Input } from 'antd';
import ApiService from "services/ApiService"
import { VideoContext } from 'app/VideoProvider';
const { TextArea } = Input;

const TextEditorView = ({show,type}) => {
    const {videoId, videoClips, setVideoClips, videoActiveClip,} = useContext(VideoContext);
    const [headerText, setheaderText] = useState("")
    const [clipText, setclipText] = useState("")
   
    useEffect(() => {
        setclipText(videoClips[videoActiveClip]?videoClips[videoActiveClip]["cliptext"]:"")
        // setclipText(videoClips[videoActiveClip]?videoClips[videoActiveClip]["cliptext"][0]["text"]:"")
        setheaderText(videoClips[videoActiveClip]?videoClips[videoActiveClip]["headertext"]:"")
    },[videoActiveClip,videoClips])
    const updateText = () => {
            let existingClips = videoClips;        
            let newClips = []
            for(let i = 0; i < 5; i++){
                let tmp = existingClips[i] ? existingClips[i] :{}
                if(videoActiveClip === i ){
                    // let textBloks = {};
                    // let textBloksList = {};
                    // textBloks["text"] =   clipText;                
                    // textBloks["highlight_indices"] =   []; 
                    // textBloksList[0] = textBloks
                                  
                    tmp["cliptext"] = clipText;
                    tmp["cliptype"] = type;  
                                
                }
                newClips.push(tmp);
            }
            
        updateServer({clips:newClips})
        setVideoClips(newClips);
    }
    const updateHeaderText = () => {
        let existingClips = videoClips;        
        let newClips = []
        for(let i = 0; i < 5; i++){
            let tmp = existingClips[i] ? existingClips[i] :{}
            if(videoActiveClip === i ){
                tmp["headertext"] = headerText; 
                tmp["cliptype"] = type;  
            }
            newClips.push(tmp);
        }
        
    updateServer({clips:newClips})
    setVideoClips(newClips);
}
    const updateServer = async(input) =>{
        const data = {
            vid:videoId,
            update:input
        }
        // await ApiService.updateVideoData(data);
        await ApiService.PostApiCalls("campaign/videoUpdate",{...data},true)
    }
    return (
        <>
        {show === "title" && <Col lg={12}  md={12} sm={12} className="py-1 px-0 text-left" >
           <TextArea value={headerText}
            autoSize={{ minRows:1, maxRows:1 }}
            onBlur={() => updateHeaderText()} 
            showCount maxLength={60} onChange={(e) =>setheaderText(e.target.value)} 
            placeholder={"Add title"} />
        </Col>}
        {show === "text" &&  <Col lg={12}  md={12} sm={12} className="py-1 px-0 text-left" >
        <TextArea
          value={clipText} onChange={(e) =>setclipText(e.target.value)} onBlur={() => updateText()}  placeholder="Clip text"
          autoSize={{ minRows: 2, maxRows: 3 }}
          showCount maxLength={90}
        />
           
        </Col>}
        </>
    )
}

export default TextEditorView
