import { Button, Input,Select } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce'
import cloneDeep from 'lodash/cloneDeep'
import { useRef } from 'react';
import { setFilterData } from '../store/dataSlice';
const ListingTools = () => {
    const dispatch = useDispatch();
    const searchInput = useRef()
    const filterData = useSelector(
        (state) => state.webStreet.data.filterData
    )
    
    const debounceFn = debounce(handleDebounceFn, 500)
        
    function handleDebounceFn(val) {
        
        const newTableData = cloneDeep(filterData)
        newTableData.query = val
        
        if (typeof val === 'string' && val.length > 1) {
            dispatch(setFilterData(newTableData))
        }

        if (typeof val === 'string' && val.length === 0) {
            dispatch(setFilterData(newTableData))
        }
    }
    const OnEdit = (e)=>{
        debounceFn(e.target.value)
        
    }
    const clearAll = ()=>{
        dispatch(setFilterData({
            query: '',
            PropertyType: "",
            BHKType: "",
            Type: "",
        }))
    }
    const handleChange = (target,value) => {
        const newTableData = cloneDeep(filterData)
        newTableData[target] = value === "All"?"":value;
        dispatch(setFilterData(newTableData))
      };
  return (
    <div className='flex space-x-2 mb-3 shadow p-2'>
        <Input  ref={searchInput} onChange={OnEdit} placeholder="Search property" defaultValue={filterData.query} allowClear style={{
        width: 280,
      }} />
        <Select
      defaultValue={filterData["PropertyType"]}
      style={{
        width: 200,
      }}
      onChange={(e)=>handleChange("PropertyType",e)}
      options={[
        {
          value: '',
          label: 'Property Type',
        },
        {
            value: 'All',
            label: 'All',
          },
        {
            value: 'Apartment',
            label: 'Apartment',
        },
        {
            value: 'Independent Floor',
            label: 'Independent Floor',
        },
        {
            value: 'Independent House',
            label: 'Independent House',
        },
        {
            value: 'Villa',
            label: 'Villa',
        },
        
      ]}
    />
    <Select
      
      defaultValue={filterData["BHKType"]}
      style={{
        width: 120,
      }}
      onChange={(e)=>handleChange("BHKType",e)}
      options={[
        {
          value: '',
          label: 'BHK Type',
        },
        {
            value: '1 RK',
            label: '1 RK',
        },
        {
            value: '1 BHK',
            label: '1 BHK',
        },
        {
            value: '2 BHK',
            label: '2 BHK',
        },
        {
            value: 'Others',
            label: '2 BHK+',
        },
        
      ]}
    />
    <Select
      defaultValue={filterData["Type"]}
      style={{
        width: 120,
      }}
      onChange={(e)=>handleChange("Type",e)}
      options={[
        {
          value: '',
          label: 'All',
        },
        {
            value: 'Sale',
            label: 'Sale',
        },
        {
            value: 'Rent',
            label: 'Rent',
        },
        
        
      ]}
    />
    <div className='flex flex-1 justify-end'>
        <Button onClick={clearAll}>Clear All</Button>
    </div>
    </div>
  )
}

export default ListingTools