import { Row, Col, message, Divider, Button,Tabs,Spin } from "antd";
import { AuthContext } from "app/AuthProvider";
import _ from "lodash";

import React, { useContext, useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import ApiService from "services/ApiService";
import AddNewCampaign from "./AddNewCampaign";
import AddNewPost from "./AddNewPost";
import { useTranslation } from 'react-i18next';

import CampaignCard from "./CampaignCard";
import CreateNewCampaign from "./CreateNewCampaign";
import NavHeader from "./NavHeader";
import NewPost from "./NewPost";
import CustomPostCard from "./CustomPostCard";
const { TabPane } = Tabs;
const Campaigns = () => {
  const {
    authUser,
    activeWorkSpace
  } = useContext(AuthContext);
  const { t } = useTranslation();

  
  const [addNewPost, setaddNewPost] = useState(false);
  const [showpost, setShowPost] = useState(false);

  const [addCampaign, setaddCampaign] = useState(false);
  const [activeTab, setactiveTab] = useState("inprocess")
  const [loadingData, setloadingData] = useState(true)
  const [campaignsList, setcampaignsList] = useState([])
  const [customPosts, setcustomPosts] = useState([])
  const [allCounts, setallCounts] = useState([{
    "_id": "active",
    "count": 0
},
{
    "_id": "pending",
    "count": 0
},
{
  "_id": "finished",
  "count": 0
},
{
    "_id": "inprocess",
    "count": 0
}])
  const callback = (action, data) => {
    
    if (action === "create-post") {
      // setShowPost(true);
      setaddNewPost(true);
    }
    else{
      setaddCampaign(true)
    }
  };

  useEffect(() => {
    
    
    getList(activeTab);
   
    const interval = setInterval(() => {
      refreshList(activeTab);
    }, 20000);
    getCustomPosts();
    return () => { clearInterval(interval) };
  }, [activeWorkSpace["wid"],activeTab]);

  const getList = async(state)=>{
    setcampaignsList([])
    const data= {
      wid:activeWorkSpace["wid"],
      state : state
    }
    const list = await ApiService.PostApiCalls("campaign/list",data,true)
    setcampaignsList(list[1]["campaigns"])
    setallCounts(list[1]["counts"])
    
    setloadingData(false)
  }
  const refreshList = async(state)=>{
    
    const data= {
      wid:activeWorkSpace["wid"],
      state : state
    }
    const list = await ApiService.PostApiCalls("campaign/list",data,true)
    setcampaignsList(list[1]["campaigns"])
    setallCounts(list[1]["counts"])
    
    
  }
  const getCustomPosts = async()=>{
    setcustomPosts([])
    // const list = await ApiService.PostApiCalls("campaign/customposts",{limit:10},true)
    const list = await ApiService.PostApiCalls("campaign/list-posts",{limit:10,wid:activeWorkSpace["wid"]},true)
    
    setcustomPosts(list[1]["post"])
    setloadingData(false)

  }
  const changeTabs=(t)=>{
    if(t === "customposts"){
      setactiveTab(t);
      setloadingData(true)
      getCustomPosts()
    }else{
      setactiveTab(t);
      setloadingData(true)
      // getList(t);
    }
   
  }
  
  return (
    <>
      <div className="p-0">
        <Container className="p-0">
          <Row className="flex flex-col">
            <Col className="text-right px-6">
              <NavHeader callback={callback} activePlan={authUser.selectedAppPlan}/>
            </Col>
            
            <Col className="py-1"></Col>
            <Col>
              <div className="text-gray-70 font-semibold text-base pl-3 opacity-60 pb-6">
              <Tabs defaultActiveKey={activeTab} onChange={(e)=>changeTabs(e)}>
              <TabPane
      tab={
        <span>
          Posts ({customPosts.length})
        </span>
      }
      key="customposts"
    >
    </TabPane>
    <TabPane
      tab={
        <span>
          {t("Drafts")} ({_.get(allCounts.filter(r => r._id==="inprocess"),'0.count',0)})
        </span>
      }
      key="inprocess"
    >
    </TabPane>
    <TabPane
      tab={
        <span>
          {t("Active")} ({_.get(allCounts.filter(r => r._id==="active"),'0.count',0)})
        </span>
      }
      key="active"
    >
    </TabPane> <TabPane
      tab={
        <span>
          {t("Finished")} ({_.get(allCounts.filter(r => r._id==="finished"),'0.count',0)})
        </span>
      }
      key="finished"
    >
    </TabPane>
  </Tabs>
              </div>
              {
                loadingData && <div className="py-5 flex flex-col items-center justify-center"><Spin />
                <span className="py-2 text-base font-semibold">Tips</span>
                <span className="py-2 text-sm">Content with at least 1,500 words generates 30% more clicks on average.</span>
                </div>
              }
              {activeTab !== "customposts" && campaignsList.length > 0 && <div className="grid grid-cols-4 gap-5 ">
              {campaignsList.map((feed, key) => (
                  <div
                    key={key}
                    className="p-2 bg-white border-1 shadow rounded-xl"
                  >
                    <CampaignCard slug={activeWorkSpace["slug"]} content={feed} id={key} type={1} rv={t("Review")} />
                  </div>
                ))}
              </div>}
              {activeTab === "customposts" && customPosts.length > 0 && <div className="grid grid-cols-4 gap-5 ">
              {customPosts.map((feed, key) => (
                  <div
                    key={key}
                    className="p-2 bg-white border-1 shadow rounded-xl"
                  >
                    <CustomPostCard slug={activeWorkSpace["slug"]} content={feed} id={key} type={1} rv={t("Review")} />
                  </div>
                ))}
              </div>}
            </Col>
            <Col className="py-3"></Col>
            
          </Row>
        </Container>
      </div>
      {addNewPost && (
        <AddNewPost
          profiles={authUser["socialchannels"]}
          user={authUser}
          showModalFirst={addNewPost}
          callback={setaddNewPost}
        />
      )}
      {addCampaign && (
        <CreateNewCampaign
          profiles={authUser["socialchannels"]}
          user={authUser}
          showModalFirst={addCampaign}
          callback={setaddCampaign}
        />
      )}
       {
          showpost && <NewPost activeWorkSpace={activeWorkSpace} show={showpost} callback={setShowPost} profiles={authUser["socialchannels"]}
          authuser={authUser} />
        }
    </>
  );
};

export default Campaigns;
