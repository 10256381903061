import React from 'react'
import { Button, Result, Spin, Row, Col, Card } from "antd";
import { Container } from 'react-bootstrap';
import CrtyptoForm from './components/CrtyptoForm';
const AcceptCrypto = () => {
  return (
      <Container className='py-5 w-75 '>
    <Row gutter={16}>
        <Col span={15} className="mb-3 pr-6">
          <div className=" d-flex justify-center flex-col  py-3 mb-3 rounded-xl ">
            <h1 className='text-3xl'>Get Started</h1>
            <span className='font-semibold mb-3 text-lg'>Every new client receives a personal crypto donation match pool for a limited time. Speak with us to learn more.</span>
            <div className='py-2'></div>
            <h2>Product Demo</h2>
            <h3>Walkthrough of the #1 Crypto Donation Solution in 30 Minutes</h3>
            <span className='text-base '>Learn how accepting <b>HASHS Token</b> donation, can help your nonprofit diversify its revenue streams and expand its donor base. Nonprofit organizations must be registered in the country in which they operate.</span>
            {/* <span className='text-base '>Learn how accepting cryptocurrency donations, such as Bitcoin (BTC), Ethereum (ETH), USD Coin (USDC), Dai (DAI), Dogecoin (DOGE), Solana (SOL), and others, can help your nonprofit diversify its revenue streams and expand its donor base.</span> */}
            <span className='text-base italic'>Nonprofit organizations must be registered in the country in which they operate.</span>
            <div className='py-3'></div>
            <h3>What we'll talk about in the demo -</h3>
            <ul className='list-ul pl-3'>
                <li className='mb-1'><b>Crypto Donation Widget:</b> An overview of how your unique widget allows contributors to contribute secure crypto donations.</li>
                <li className='mb-1'><b>Our Website and Crypto Donors:</b> Your organization's profile will be seen by crypto donors looking for causes to help if you join our network.</li>
                <li className='mb-1'><b>Crypto Fundraising:</b> Learn about the tools, campaigns, and other activities we help NGOs use to raise money with cryptocurrency.</li>
            </ul>
            
         </div>
            </Col>
            <Col span={9} className="mb-3">
          <div className=" d-flex justify-start flex-col  py-3 mb-3 rounded-xl h-100 ">
            <div className="p-3 d-flex flex-col bg-white rounded-md shadow-md h-100 justify-between mb-2 pb-2 border-b">
              <CrtyptoForm />
            </div>
            </div>
            </Col>
            </Row>
            {/* <Row gutter={24}>
            <Col span={24} className="mb-3 pr-6">
                <div className=" d-flex justify-center flex-col  py-3 mb-3 rounded-xl ">
                <h3>Cryptocurrency Accepted</h3>
                <div>
                    <img src='https://hs-container.s3.ap-south-1.amazonaws.com/saga/user-assets/images/1z1FJe7AC4_a3x.jpeg' />
                </div>
                </div>
            </Col></Row> */}
            </Container>
  )
}

export default AcceptCrypto