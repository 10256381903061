
import React from 'react'
import { List, Modal } from 'antd';
import { ChevronBarRight, ChevronRight, Trash } from 'react-bootstrap-icons';
import { Link ,useNavigate} from 'react-router-dom';
import ApiService from 'services/ApiService';
import _ from 'lodash';
// import { Redirect } from 'react-router-dom/cjs/react-router-dom';

const data = [
  {slug: 'content-gallery',lable:"Content Gallery"},
  {slug: 'add-feed',lable:"Add Feed"},
  {slug: 'manage-feed',lable:"Manage Feed"},
  {slug: 'products-catalog',lable:"Products Catalog"},
  {slug: 'display-settings',lable:"Display Settings"},
  // {slug: 'content-templates',lable:"Content Templates"},
  // {slug: 'branding',lable:"Branding"},
  // {slug: 'blacklist-phrases',lable:"Blacklist Phrases"},
  
  
];

const LeftMenu = ({user,active,activeWorkSpace,setactiveWorkSpace,setAuthUser}) => {
  const navigate = useNavigate()
  
  return (
      <>
       <div className='pb-2 bg-gray-100'></div>
    <List
     
      className="bg-white pb-3"

      dataSource={data}
      renderItem={item => (
        <List.Item className='cursor-pointer p-2'>
          <Link className='text-hs-dark px-1 flex w-100' to={`/creator/social-commerce/${item["slug"].toLowerCase()}`}>
              <span className={item["slug"].toLowerCase() === active ?'flex justify-between items-center w-100 p-2 bg-blue-50 text-blue-400 font-semibold':'flex justify-between items-center w-100 p-2 hover:bg-blue-50 hover:text-blue-400'}>
              <span>{item["lable"]}</span>
              {/* <ChevronRight size={20} /> */}
              </span></Link>
        </List.Item>
      )}
    />
   
    </>
  )
}

export default LeftMenu