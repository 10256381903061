
import React from 'react'
import Marquee from "react-easy-marquee";
import WallCard from './WallCard';
import WallCardMusic from './WallCardMusic';
const DisplayFeeds = ({filter,displayOptions}) => {
   
  return (
    <div className='w-100 overflow-hidden'>
        
        <Marquee pauseOnHover={true}  className='displya-wall' duration={200000} background="#fafafa" height="650px" reverse={true}
        axis="y"

        >
        {filter === "music"?
        <WallCardMusic size={4}/>:
        <WallCard size={4} displayOptions={displayOptions}/>
        }
        
      </Marquee>
    </div>
  )
}

export default DisplayFeeds