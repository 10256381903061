import { Button, Checkbox, Dropdown,Menu } from 'antd'
import React from 'react'
import { Pencil, PinAngle, PinFill, SlashCircle, StarFill, ThreeDotsVertical, Trash } from 'react-bootstrap-icons'

const ContentHeader = () => {
    const menu = (
        <Menu
          
          items={[
            {
              key: '1',
              label: (
                <span className='font-medium py-1'>
                  Edit post
                </span>
              ),
              
              icon: <Pencil size={16} />,
            },
            {
                key: '1',
                label: (
                  <span className='font-medium py-1'>
                    Delete post
                  </span>
                ),
                
                icon: <Trash size={16} />,
              },
            {
              key: '2',
              label: (
                <span className='font-medium py-1' >
                  Block User
                </span>
              ),
              
              icon: <SlashCircle size={16} />,
              disabled: false,
            }
            
          ]}
        />
      );
  return (
    <div className='flex items-center w-100 justify-between'>
     <div className='flex items-center justify-center space-x-3'>
        <Checkbox />
        <PinAngle />
        <StarFill />
        </div>   
     <div>
     <Dropdown overlay={menu} trigger={"click"}>
    
    <Button shape="circle" type="text" className='text-center flex items-center justify-center ml-2' icon={<ThreeDotsVertical />} />
  </Dropdown></div>   
        </div>
  )
}

export default ContentHeader