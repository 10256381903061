import React from "react";
import { ArrowRepeat, Twitter } from "react-bootstrap-icons";
import TwitterLogin from "react-twitter-auth";
const TwitterAuth = (props) => {
  const callbackerror = (e) => {};
  const callbackSuccess = (e) => {
    e.json().then((user) => {
      
      let profileSelected = user["user"]["_json"];
      profileSelected["source"] = "Twitter";
      profileSelected["oauth_token"] = user["data"]["oauth_token"];
      profileSelected["oauth_token_secret"] =
        user["data"]["oauth_token_secret"];
      profileSelected["user_id"] = user["data"]["user_id"];
      profileSelected["fb-picture"] =
      user["user"]["_json"]["profile_image_url_https"];
      profileSelected["profileOwner"] = {name:user["user"]["_json"]["name"],id:user["data"]["user_id"],accessToken:user["data"]["oauth_token"]};
      // pf["fb-picture"] = pf["picture"]["data"]["url"];
      if(props.reconnect){
        profileSelected["isChange"] = "yes";
        profileSelected["isReconnect"] = "yes";
        profileSelected["spid"] = props.spid;
    }
      // const id = user["id"]
      props.callback(profileSelected, [], "Twitter");
    });
  };
  return (
    <TwitterLogin
      // loginUrl="http://localhost:3003/api/v1/twitter/auth"
      loginUrl="https://hashsaga.com:3011/api/v1/twitter/auth"
      onFailure={callbackerror}
      onSuccess={callbackSuccess}
      // requestTokenUrl="http://localhost:3003/api/v1/twitter/login?twitter=true"
      requestTokenUrl="https://hashsaga.com:3011/api/v1/twitter/login?twitter=true"
      showIcon={false}
      tag="div"
      className="twitter-btn"
    >
      {props.reconnect ?<div className="twitter-btn flex items-center text-xs"  ><ArrowRepeat size={12} className="mr-1 text-hs-blue mrt-m3" />
            <span>Reconnect</span></div>:<Twitter color={"#55acee"} size={45} />}
    </TwitterLogin>
  );
};

export default TwitterAuth;
