import React from 'react'
import {Divider } from "antd";
import {Clock} from "react-bootstrap-icons"
import { useTranslation } from 'react-i18next';


import moment from 'moment'
const InfoTimestamp = ({campaign}) => {
  const { t } = useTranslation();

    return (
        <div className="d-flex flex-grow-1 align-items-center flex-wrap flex-direction-column" >
            <Divider />

        <div className="d-flex w-full align-items-center flex-wrap justify-content-center">
        <Clock/ >
        <span className="ml-2 pt-0 fw-700">{t("tt1")}</span></div>   
        <div className="d-flex w-full align-items-center flex-wrap justify-content-center mt-3">
        
        <span className="ml-0 pt-0 fw-700">{moment.unix(campaign.createdAt).format("DD MMM YY, h:mm a")}</span></div> 
        <Divider />
        </div>
    )
}

export default InfoTimestamp
