import React, { useEffect, useState } from "react";
import { Col, List, Spin } from "antd";
import _ from "lodash";

import ApiService from "services/ApiService";
import HeaderDropdownSelection from "./HeaderDropdownSelection";
import HashtagsMention from "container/creator/HashMine/mention/HashtagsMention";
import HashtagsOffer from "container/common/HashtagsOffer";
const KeywordsMention = ({
  setaddHash,
  activeTab,
  setmodalEvent,
  authUser,
  openModel,
}) => {
  const [loadingData, setloadingData] = useState(true);
  const [mintedHash, setmintedHash] = useState([]);
  const [activeSelection, setactiveSelection] = useState("Available");
  const [pagination, setpagination] = useState({
    limit: 10,
    skip: 0,
    count: 0,
  });

  const [draftHash, setdraftHash] = useState([]);
  useEffect(() => {
    getMinedData(activeTab);
    return () => {};
  }, []);
  const getMinedData = async (activeTab) => {
    let filter = {};
    setmintedHash([]);
    if (activeSelection === "Available") {
      filter = { mentionStatus: "open" };
      getAvilableList(filter);
    }
  };
  const getAvilableList = async () => {
    const data = await ApiService.PostApiCalls(
      "keyhashes/sales/list",
      { type: "mention", status: "open" },
      true
    );
    if (data[0] && data[1]["IsSuccessful"]) {
      setmintedHash(data[1]["hashKeys"]);
    }
    setloadingData(false);
  };
  const getMentionRequested = async () => {
    setloadingData(true);
    setmintedHash([]);
    const salesData = await ApiService.PostApiCalls(
      "keyhash/listing/get/mention",
      { type: "mention", status: "open" },
      true
    );
    if (salesData[0] && salesData[1]["IsSuccessful"]) {
      setmintedHash(salesData[1]["offers"]);
    }

    setloadingData(false);
  };
  const handleChange = (e) => {
    setmintedHash([]);
    setactiveSelection(e);
    if (e === "Available") {
      getAvilableList(activeTab);
    } else {
      getMentionRequested(activeTab);
    }
  };
  return (
    <>
      {loadingData && (
        <div className="py-5 flex flex-col items-center justify-center">
          <Spin />
          <span className="py-2 text-base font-semibold">Tips</span>
          <span className="py-2 text-sm">
            Content with at least 1,500 words generates 30% more clicks on
            average.
          </span>
        </div>
      )}

      {!loadingData && (
        <>
          <Col className="px-3 pb-2 ml-2">
            <HeaderDropdownSelection
              options={["Available", "Your Request"]}
              defaultSelected={activeSelection}
              callback={handleChange}
            />
          </Col>
          <Col className="px-3">
            <List
              bordered
              itemLayout="horizontal"
              dataSource={mintedHash}
              renderItem={(item) => (
                <List.Item>
                  {activeSelection === "Available" ? (
                    <HashtagsMention
                      hash={item}
                      type={activeSelection}
                      user={authUser}
                      callback={openModel}
                    />
                  ) : (
                    <HashtagsOffer
                      hash={item}
                      type={activeTab === "auction" ? "sale" : activeTab}
                      user={authUser}
                      callback={openModel}
                    />
                  )}
                </List.Item>
              )}
            />
          </Col>
        </>
      )}
    </>
    // <div>UserKeywords</div>
  );
};

export default KeywordsMention;
