import { Button, Col } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Masonry from 'react-masonry-css'
import ApiService from 'services/ApiService'
import ContentMedia from '../../content/ContentMedia'
const WallCard = ({size,displayOptions}) => {
    const [galleryPosts, setgalleryPosts] = useState([])
  const [loading, setloading] = useState(true)

    useEffect(() => {
        getPosts()
    
      return () => {
        
      }
    }, [])
    const getPosts = async()=>{
        //commerce/feed/posts
        const posts = await ApiService.PostApiCalls("/commerce/feed/posts",{...displayOptions},true)
        if (posts[0] && !posts[1]["IsSuccessful"]) {
          setgalleryPosts(posts[1]["posts"])
          
          
      }
      else {
        setgalleryPosts(posts[1]["posts"])
        
      }
      setloading(false)
      }
   
  return (
    loading?<></>:
    <Masonry
    breakpointCols={size}
    className="my-masonry-grid ml-0 mw-100 w-100"
    columnClassName="my-masonry-grid_column">
    { galleryPosts && galleryPosts.map((d,idx) =>
      d.contentId &&  <div key={idx} >
      <Col key={idx}   style={{order:(idx)}} className="poke-item p-0 w-100">
      <div className={"poke-view mb-1 bg-white overflow-hidden relative"}>
        <div className='postContent cursor-pointer rounded-lg overflow-hidden bg-white'>

            <div className='postContentCard p-0 text-center relative'>
             {d.taggedProducts && d.taggedProducts[0] && <div className=' z-50 absolute w-full top-5 flex items-center justify-center p-3'>
                
                <a href={d.taggedProducts[0]["shoplink"]} target='_blank' ><Button>{displayOptions.btnLable}</Button></a>
                </div>}
               
            {
                d["mediaFile"] !== "" &&
                <div className='image mb-2'><ContentMedia post={d} /></div>
            }
            <h4 className='mt-0 bg-white'>
                <div className='authorDetails'>
                {/* <div className='authorIcoBg text-center flex items-center justify-center'>
                    <img className='text-center' src={d["picture"]}></img>
                </div> */}
                <div className='postAuthorName mt-2'>{d["name"]}</div>
                <div className="authrHandleTime text-xs mt-1">
                    <span className="authrHandle" target="_blank"> @{d["username"]} </span>
                    <span className="sepratedot"> - </span>
                    <span className="timePost" >{ moment.unix(d["createdAt"]).fromNow()}</span></div>
                </div>
            </h4>
           
            <div className='postedTxt w-100 '>
            <p className="line-clamp-6 trimcont text-left" dangerouslySetInnerHTML={{__html: d["postcontent"]}} />
                </div>
            </div>
        </div>

      </div>
      
      </Col></div>  ) 
    }
    </Masonry>
    
  )
}

export default WallCard