import { Breadcrumb, Spin,Row,Col, Empty, Button,Tabs,  Form,  Divider,Alert, Modal, message  } from 'antd'
import Utils from 'helper/Utils'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import ApiService from 'services/ApiService'
import RequestModal from './RequestModal'
import {Elements,PaymentElement} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import ListingPaymentForm from './ListingPaymentForm'
import SiteVistiModal from './SiteVistiModal'
import ListingRequets from './ListingRequets'

const furnishingList = [
  {label:"Dining Table",icon:<i className="icon-dining-table"></i>},
  {label:"Washing Machine",icon:<i className="icon-washing-machine"></i>},
  {label:"Sofa",icon:<i className="icon-sofa"></i>},
  {label:"Microwave",icon:<i className="icon-microwave"></i>},
  {label:"Stove",icon:<i className="icon-stove"></i>},
  {label:"Fridge",icon:<i className="icon-fridge"></i>},
  {label:"Water Purifier",icon:<i className="icon-water-purifier"></i>},
  {label:"Gas Pipeline",icon:<i className="icon-gas-pipeline"></i>},
  {label:"AC",icon:<i className="icon-ac"></i>},
  {label:"Bed",icon:<i className="icon-bed"></i>},
  {label:"TV",icon:<i className="icon-tv"></i>},
  {label:"Cupboard",icon:<i className="icon-cupboard"></i>},
  {label:"Geyser",icon:<i className="icon-geyser"></i>},
]
const amenitiesList = [
  {label:"Lift",icon:<i className="icon-elevator"></i>},
  {label:"CCTV",icon:<i className="icon-CCTV"></i>},
  {label:"Gym",icon:<i className="icon-dumbel"></i>},
  {label:"Garden",icon:<i className="icon-landscaped-garden"></i>},
  {label:"Kids Area",icon:<i className="icon-playground"></i>},
  {label:"Sports",icon:<i className="icon-sports"></i>},
  {label:"Swimming Pool",icon:<i className="icon-pool"></i>},
  {label:"Intercom",icon:<i className="icon-intercom"></i>},
  {label:"Gated Community",icon:<i className="icon-gated-society"></i>},
  {label:"Club House",icon:<i className="icon-club-house"></i>},
  {label:"Community Hall",icon:<i className="icon-community-hall"></i>},
  {label:"Regular Water Supply",icon:<i className="icon-water-supply"></i>},
  {label:"Power Backup",icon:<i className="icon-power-backup"></i>},
  {label:"Pet Allowed",icon:<i className="icon-pet"></i>},
  
]
const ListingDetails = ({authUser,alreadyBroker}) => {
  const stripePromise = loadStripe('pk_test_51LDMFTSCziW5a8WnahZL7oy2kpBCIdxFQVcwC1U0yDU7Ak7J0aclUaVHAlqsMncC5fDl6WyLmFiuq02j5pW9Gwv800V30n2tKD');
    const location = useLocation();
    
    const [paymentSecret, setpaymentSecret] = useState(null)
    const [prceedBuy, setprceedBuy] = useState(false);
    const [form] = Form.useForm();
    const bwParams = useParams()
    const navigate = useNavigate()
    const [loading, setloading] = useState(true)
    const [data, setData] = useState(null)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenRq, setIsModalOpenRq] = useState(false);
    const [isActionModalOpen, setisActionModalOpen] = useState(false)
    const [modalDetails, setmodalDetails] = useState(null)
    const [ownListing, setownListing] = useState(false)
    const homeState = location.pathname.split("/")[1]
    const [sending, setsending] = useState(false)
    const [siteModal, setsiteModal] = useState(false)
 
    useEffect(() => {
      
      
      
      
        getDetails()
      return () => {
        
      }
    }, [bwParams.id])
   const confirmPayment =  async(payinfo)=>{
    const paydata = {
      paymentInfo:{ ...payinfo.payInfo,
      amount:payinfo.tokens},
      offerBy:data.offerBy,
      offerId:data.offerId,
      listedBy:data.listedBy,
      listingId:data._id,
    }
    
    const updated = await ApiService.PostApiCalls("steert/listing-request/payment",{...paydata},true)
    if(updated[1]["IsSuccessful"]){
      message.success('Payment received successfully.');
      handleCancel();
      getDetails();
    }
    else{
      message.error('Something went wrong, try again.');
    }
   }
   const showSiteModal = async(item)=>{
    setmodalDetails(item)

      setsiteModal(true)
   }
   const handleOk = () => {
    setIsModalOpenRq(false);
  };
    const showModal = (item) => {
      

      setmodalDetails(item);
      if(ownListing){
        setIsModalOpenRq(true);
      }
      else{
        setIsModalOpen(true);
      }
      
    };
    const handleCancel = () => {
      setmodalDetails(null)
      setIsModalOpen(false);
      setisActionModalOpen(false)
      setsending(false)
      setsiteModal(false)
      setIsModalOpenRq(false)
      
    };
    const getDetails = async()=>{
        const result = await ApiService.PostApiCalls("/street/list/"+bwParams.id,{filters:{},pageIndex:1,pageSize:20,userid:authUser?authUser.uniqueId:""},false)
    if(result[0]){
      setData(result[1]["data"][0]?result[1]["data"][0]:null)
      setownListing(authUser && result[1]["data"][0]["listedBy"] === authUser["uniqueId"]?true:false)
      setloading(false)
      
      if(location.state && location.state.modal){
        showModal(result[1]["data"][0])
      }
    }
    
    }
    const initiatePayment = async(amount)=>{
      const dataIn = { "amount":parseInt(amount)*100,
      "currency":"INR"}
      
      const res = await ApiService.PostApiCalls("/payment/create-payment-intent",dataIn,true)
      if(res[0] && res[1]["IsSuccessful"]){
        setpaymentSecret(res[1]["clientSecret"])
        setprceedBuy(true)
        setsending(true);
        setisActionModalOpen(true)
      }
      else{
        Utils.showNotification("error", "Oopps", "Something went wrong",3);
      }
    }
    
   
   
    if(loading){
        return ( 
            
                loading && <Row className='h-screen'>
                    <Col span={24} className="flex h-48 items-center justify-center">
                    <Spin size='large' /></Col>
                </Row>
            )
    }
  return (
    <>
   <Row className='min-h-screen'>
    {
        !data && <Col span={24} className='flex items-center h-screen justify-center'>
         <Empty
         className='flex items-center flex-col'
    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
    imageStyle={{
      height: 60,
    }}
    description={
      <span className='font-normal text-xl'>
        No property found
      </span>
    }
  >
    <Button onClick={()=>navigate(-1)} type="primary">View Listings</Button>
  </Empty>
        </Col>
    }
    {
        data && <Col span={24} className='flex flex-col space-y-2'>
         <div>
        <Breadcrumb> <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
    <Breadcrumb.Item>
      <Link to={homeState==="brokers"?`/${homeState}/streets/broker-registration`:ownListing?`/${homeState}/streets/new-listing`:`/${homeState}/streets`}  >{homeState==="brokers"?"Your Activities":ownListing?"Your Listings":"Property Listings"}</Link>
    </Breadcrumb.Item>
    <Breadcrumb.Item>
      <span>{`${data.BHK}`}</span>
    </Breadcrumb.Item>
   </Breadcrumb>
    </div>
    <div className='py-1'></div>
    {<>{data.status === 1?<>{}</>:ownListing?<>
    {
      data.status === 4 ?<Alert
      message={`Property Assigned`}
      type="warning"
      description={<div className='flex flex-col space-y-1'><span>{data.assignedTo["fullname"]}</span><span>{`${data.assignedTo["prefix"]} - ${data.assignedTo["phone"]}`}</span></div>}
      action={
        <Button onClick={()=>showSiteModal(data)} type="primary" className='rounded'>
        Manage Site Visits
      </Button>
      }
    />:data.status === 2 ?
    <Alert
      message={`Payment Requested  - ₹ ${Utils.getAmountInFormat(parseInt(data.paymentPending))}`}
      type="warning"
      description={<div className='flex flex-col space-y-1'><span>{data.assignedTo["fullname"]}</span><span>{`${data.assignedTo["prefix"]} - ${data.assignedTo["phone"]}`}</span></div>}
      
    />:<></>
    }
    
    
    </>:data.paymentPending && !data.paymentInfo?<Alert
      message="Payment Request"
      type="warning"
      description={`You need to make payment of ₹ ${Utils.getAmountInFormat(parseInt(data.paymentPending))}`}
      action={
        <Button  type="primary" loading={sending} className='rounded' onClick={()=>initiatePayment(data.paymentPending)}>
          Make Payment
        </Button>
      }
      
    />:<Alert
    message="Offer Accepted"
    type="info"
    description={`Your offer is live and you can schedule site visits.`}
    action={
      <Button onClick={()=>showSiteModal(data)} type="primary" className='rounded'>
        Manage Site Visits
      </Button>
    }
    
  />}</>}
    <div className='py-1'></div>
    <div className='flex justify-between mt-5'>
      <div className='flex flex-col space-y-1'>
      <span className='font-semibold text-xl'>{`${data.BHK} on ${data.listingFor}`}</span>
      <span>By - {data["Owner"][0].name}</span>
      <span>{data["Locality"]}, {data["city"]}</span>

      </div>
      <div className='flex flex-col justify-between pr-3'>
      <span className='font-semibold text-xl'>₹{Utils.getAmountInFormat(parseInt(data.price))}</span>
      {data.status === 1 && <Button onClick={(e)=>{e.stopPropagation();showModal(data)}}  className={`px-3 brd-4 fw-700 rounded-lg text-white bg-hs-pink  hover:border-hs-pink `}>{ownListing?"View Requests":"Contact Seller"}</Button>}
      </div>

    </div>
    <div className='py-1'></div>
    <div className='img'>
    <div className="images-gallery-listings">
        {
            data.listingImages.map((img,key) =>(
                <><div className="item" key={key}>
        <img src={img} alt=""></img>    
        <div className="image-action">
         
        </div>
        </div></>
            ))
        }
       
        </div>
    </div>
    <div className='py-1'></div>
      <div>
      <Tabs defaultActiveKey="1">
    <Tabs.TabPane tab="Overview" key="1">
    <div className='flex flex-col space-y-2'>
        <span>Address - {data.address}</span>
        <span>Price - ₹{Utils.getAmountInFormat(parseInt(data.price))}</span>
        <span>Area - {parseInt(data.BuiltArea)}</span>
        <span>Possession in - {data.availableIn}</span>
    </div>
    </Tabs.TabPane>
    <Tabs.TabPane tab="Furnishings" key="2">
    <div className="rf-group checkbox-group amenities propertyAmenities">
    {
            furnishingList.map((furniture,i)=> data.furnishingList.split(",").includes(furniture["label"]) && <div key={i} className={"rf-toggle checkbox checkbox-0 toggle-tag pills"}>
            <div className="toggle-label"><div>
              {furniture["icon"]} <span>{furniture["label"]}</span></div></div></div>)
          }
          </div>
    </Tabs.TabPane>
    <Tabs.TabPane tab="Amenities" key="3">
    <div className="rf-group checkbox-group amenities propertyAmenities">
    {
            amenitiesList.map((furniture,i)=> data.AmenitiesList.split(",").includes(furniture["label"]) && <div key={i} className={"rf-toggle checkbox checkbox-0 toggle-tag pills"}>
            <div className="toggle-label"><div>
              {furniture["icon"]} <span>{furniture["label"]}</span></div></div></div>)
          }
          </div>
    </Tabs.TabPane>
  </Tabs>
      </div>
      <div className='py-1'></div>
      <Divider />
      <div className='py-1'></div>

    {data.status === 1 &&   <div className='flex justify-between mt-5'>
      <div className='flex flex-col space-y-1'>
      <span className='font-semibold text-xl'>{`${data.BHK}`}</span>
      <span>By - {data["Owner"][0].name}</span>
      <span>{data["Locality"]}, {data["city"]}</span>

      </div>
      <div className='flex flex-col justify-between pr-3'>
      <span className='font-semibold text-xl'>₹{Utils.getAmountInFormat(parseInt(data.price))}</span>
      <Button   onClick={(e)=>{e.stopPropagation();showModal(data)}}  className={`px-3 brd-4 fw-700 rounded-lg text-white bg-hs-pink  hover:border-hs-pink `}>{ownListing?"View Requests":"Contact Seller"}</Button>
      </div>

    </div>}
      <div className='py-3'></div>
        </Col>
    }
   </Row>
   {modalDetails && <Modal   width={800} bodyStyle={{padding:0}} footer={null} title="Requests for property" visible={isModalOpenRq} onOk={handleOk} onCancel={handleCancel}>
        <div>
        <div className='p-3 max-h-96 overflow-y-auto'>
        <ListingRequets id={modalDetails._id} callback={handleCancel} />
        </div>
        <div className='flex p-3 pb-0 border-t-2 items-center justify-between space-x-3'>

        <span className='text-purple-700'>{modalDetails.ListingType === "guaranteed" && modalDetails.guaranteeType === "Open"?"":"Once you accept highest bid offered, a payment request will be sent to broker."}</span>
        <Button onClick={handleCancel} type="primary"  size='large'  className={`px-3 brd-4 fw-700 mx-2 rounded-lg text-white bg-hs-pink  hover:border-hs-pink `}>
          Okay
        </Button>
       
      
      </div>
        </div>
      </Modal>}
   {modalDetails && 
   <RequestModal isopen={isModalOpen} alreadyBroker={alreadyBroker} callback={handleCancel} details={modalDetails} />
   }
   {data &&
    <Modal title="Connect with seller" bodyStyle={{padding:0}} footer={null}  visible={isActionModalOpen}  onCancel={handleCancel}>
       <Elements stripe={stripePromise} options={{
        
        clientSecret: paymentSecret
      }} >
        <ListingPaymentForm tokens={parseInt(data.paymentPending)} callback={confirmPayment} clientSecret={paymentSecret} setprocessingCall={setsending} />
      </Elements>
    </Modal>
   }
   {
    modalDetails && <><SiteVistiModal isopen={siteModal} ownListing={ownListing} alreadyBroker={alreadyBroker} callback={handleCancel}  details={modalDetails}/></>
   }
    </>
  )
}

export default ListingDetails