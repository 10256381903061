import React, { useEffect, useRef, useState } from 'react'
import { PauseCircle, Play, PlayCircle, PlayCircleFill } from "react-bootstrap-icons";
const VideoPlayer = ({img,isThumbnail=false}) => {
    const [isPlaying, setisPlaying] = useState(false);
    const [isPaused, setisPaused] = useState(false);
    const [showPlayPause, setshowPlayPause] = useState(false)
    const videoPlayer = useRef(null);
    const videoPlayerSource = useRef();
    const [viewclass, setviewclass] = useState("square-img portrait-background")
    useEffect(() => {
      if(!img["width"]){
        const imgIN = new Image()
        imgIN.onload =  function () {
          if(isThumbnail){
            setviewclass("square-img thumbe-background")
           }
           else{
         const ratio = imgIN["width"]/imgIN["height"];
         

         if(ratio < 1){
           console.log("here");
           setviewclass("square-img portrait-background")
         }
         else{
          setviewclass("square-img")
         }
        }
      }
      imgIN.src = img.poster_url;
         
     }
     else{

         const ratio = img["width"]/img["height"];
         if(ratio < 1){
          setviewclass("square-img portrait-background")
         }
         else{
          setviewclass("square-img")
         }
     }
    
        let options = {
            rootMargin: "0px",
            threshold: [0.25, 0.75]
          };
          let handlePlay = (entries, observer) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting && isPlaying) {
                videoPlayer.current.play();
                videoPlayer.current.muted = true;
              } else {
                  if(videoPlayer.current){
                videoPlayer.current.pause();
            }
              }
            });
          };
      
          let observer = new IntersectionObserver(handlePlay, options);
      
          observer.observe(videoPlayer.current);
      return () => {
        
      }
    }, [img,isPlaying])
    
    const mouseOverVideo = (e,state)=>{
      
        if(e.currentTarget !== e.target){
          return false
        }
        e.stopPropagation();
        setshowPlayPause(state)
      }
      const toggleOnEnd =()=>{
        setisPlaying(false);
        setisPaused(false)
      }
      const togglePlay = ()=>{
        if(!isPaused){
          videoPlayer.current.pause();
          setisPaused(true)
        }
        else{
          videoPlayer.current.play();
          setisPaused(false)
         
        }
      }
      const mouseClickVideo = (e,play)=>{
          
          if(!isPlaying){
          videoPlayerSource.current.src = e;
          if (videoPlayer.current !== null) {
              videoPlayer.current.pause();
              videoPlayer.current.load();
              videoPlayer.current.play();
              setisPlaying(true)
            }
          }
          else{
            if(!isPaused){
              videoPlayer.current.pause();
              setisPaused(true)
            }
            else{
              videoPlayer.current.play();
              setisPaused(false)
             
            }
              // videoPlayerSource.current.src = "";
              // if (videoPlayer.current !== null) {
              //     videoPlayer.current.load();
              //     videoPlayer.current.pause();
              //     setisPlaying(false)
              //   }
          }
      }
    const getClass = (asset) =>{
      
      
  }
  return (
    <>
    {!isPlaying && <div onMouseOut={(e)=>mouseOverVideo(e,false)} onMouseOver={(e)=>mouseOverVideo(e,true)} onClick={()=>mouseClickVideo(img.preview_url,true)} className="absolute bg text-white z-10 inset-0 flex items-center justify-center cursor-pointer">
          {!isPlaying && !isPaused && <PlayCircleFill  size={46} color={"#0079FF"} colorInterpolation="red" />}
          {isPlaying && showPlayPause && !isPaused && <PauseCircle  size={46} />}
          {isPaused && showPlayPause && <PlayCircle  size={46} />}
          
          </div>}
        <div className={viewclass} data-d="ss" style={{backgroundImage:`url("${img.poster_url}")`}}></div>
        <video onEnded={()=>toggleOnEnd()} onClick={()=>togglePlay()} ref={videoPlayer}  className={viewclass} poster={img.poster_url} style={{backgroundImage:`url("${img.poster_url}")`}}  preload="none" draggable="false" controls={!isPlaying?false:true} disablepictureinpicture="" controlslist="nodownload ">
          <source ref={videoPlayerSource} src="" />
        </video>
        </>
        
  )
}

export default VideoPlayer