import { Button, Result, Spin, Row } from "antd";
import FeedCard from "components/feed/FeedCard";
import Utils from "helper/Utils";
import React from "react";
import { useState, useEffect } from "react";
import { Plus, PlusCircle } from "react-bootstrap-icons";
import { Link,  useNavigate,useParams } from "react-router-dom";
import ApiService from "services/ApiService";
import GroupCard from "./GroupCard";
import { PoweroffOutlined } from '@ant-design/icons';

const GroupHome = (props) => {
  const navigate = useNavigate()
  const brParams = useParams();

  const user = Utils.getLocalInfo("userhash");
  const [loading, setloading] = useState(true);
  const [error, seterror] = useState(null);

  const [groups, setgroups] = useState([]);
  const [baseQuery, setbaseQuery] = useState({});
  const [queryParams, setqueryParams] = useState({
    limit: 20,
    offset: 0,
    sort: "desc",
    total:0
  });
  
  useEffect(() => {
    const params =brParams;
    
    setloading(true);
    listGroups()
    
    return () => {};
  }, [props]);

  const listGroups = async() =>{

    const listed = await ApiService.PostApiCalls("group/list",{...queryParams},true);
    if(listed[0] && listed[1]["IsSuccessful"]){
      setgroups(listed[1]["groups"])
    }
    else{

    }
    setloading(false);

  }
  if (
    user["role"] === "creator" &&
    parseInt(user["creatorProfileStep"]) !== 6
  ) {
    return navigate("/creator/edit/1");// <Redirect to="/creator/edit/1" />;
  }
console.log(groups);
  return loading ? (
    <div className=" h-100 d-flex justify-center align-items-center bg-white py-3 px-4 rounded-xl ">
      <Spin />
    </div>
  ) : (
    <>
      <div className=" d-flex justify-center flex-col bg-white py-3 rounded-xl ">
        <div className="px-4 d-flex justify-between mb-2 pb-2 border-b">
          <span className="text-hp text-lg font-semibold">Featured</span>
          {user["role"] === "creator" &&
    parseInt(user["creatorProfileStep"]) === 6 && <Link to={"/groups/create/"} ><Button className="rounded-md flex justify-around items-center font-semibold" type="primary" icon={<Plus size={24} className="font-semibold mr-1" />}>Create New Group</Button></Link>}
        </div>
        {groups && groups.length > 0 && (
          <div className="px-4 space-y-3">
            
              {groups.map((feed, key) => (
                <GroupCard key={key} post={feed} id={key} />
              ))}
            
          </div>
        )}
        {groups && groups.length === 0 && (
          <div className="px-4 bg-white">
            <Result
              status="403"
              title={baseQuery["tag"] ? `#${baseQuery["tag"]}` : ""}
              subTitle={
                baseQuery["tag"]
                  ? "Sorry, no post found for searched tag."
                  : "Sorry, no group found."
              }
              extra={
                <Link to={"/"}>
                  <Button type="primary">Back Home</Button>
                </Link>
              }
            />
          </div>
        )}
      </div>
    </>
  );
};

export default GroupHome;
