import { Button, Card,Checkbox,Row,Col,message } from 'antd'
import React, { useState } from 'react'
import ApiService from 'services/ApiService'

const MintedKeywordFeed = ({keywords,closeFeed}) => {
    const [sending, setsending] = useState(false)
  const [showerror, setshowerror] = useState(false)
  const [selectedOptions, setselectedOptions] = useState([])
  const onChange = (checkedValues) => {
    
    setselectedOptions(checkedValues)
  };
    const createFeed = async() =>{
        setshowerror(false)
        
        if(selectedOptions.length === 0){
          setshowerror(true)
          return false
        }
        setsending(true)
        const saved = await ApiService.PostApiCalls('/commerce/feed/create',{feedsource:"minted",feedtype:"keyword","feedoptions":selectedOptions,searchquery:keywords},true)
    if (saved[0] && !saved[1]["IsSuccessful"]) {
      const code = saved[1]["code"];
      if(code === 100){
        message
        .warning('Feed already created', 2.5)
        
        
      }
      else{
        message
        .error('Something went wrong, please try again', 2.5)
      }
    setsending(false)

    }
    else{
    

    setsending(false)
      message
    .success('Feed has been created', 2.5)
    .then(() => message.info('Posts will be added in backend.', 2.5).then(()=>window.location.href = "/creator/social-commerce/content-gallery"));
    }
    }
  return (
    <Card className='bg-gray-50 rounded w-full'>
    <div className='flex flex-col space-y-1 p-2'>
    <span className='text-lg'>Minted Keyword</span>
    <span className='text-muted text-sm'>{`Create ${keywords} feed`}</span>
    </div>
    <div className='p-3'>
        <span className='font-bold py-2'>Select Feed Options</span>
    <Checkbox.Group
    style={{
      width: '100%',
    }}
    className='py-3'
    onChange={onChange}
  >
    <Row className='space-y-4'>
      <Col span={24}>
        <Checkbox value="blueme">BlueMe</Checkbox>
      </Col>
      <Col span={24}>
        <Checkbox value="facebook">Facebook</Checkbox>
      </Col>
      <Col span={24}>
        <Checkbox value="instagram">Instagram</Checkbox>
      </Col>
      <Col span={24}>
        <Checkbox value="twitter">Twitter</Checkbox>
      </Col>
      
    </Row>
  </Checkbox.Group>
  {showerror && <span className='text-xs text-danger -mt-3'>Select atleast one option.</span>}
    </div>
    <div className='flex flex-row items-center justify-end space-x-3 py-3'>
      <Button onClick={closeFeed} loading={sending}className='rounded-sm'>Back</Button>
        <Button onClick={createFeed} loading={sending} className='bg-hs-pink text-white rounded-sm'>Create Feed</Button>
        
        </div>
    </Card>
  )
}

export default MintedKeywordFeed