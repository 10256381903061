import React, { useRef, useState,useEffect } from 'react';
import {Container,Row,Col} from "react-bootstrap";
import {Trash,Pencil,Flower3,Close, XCircle} from "react-bootstrap-icons"
import {Spin,Modal, Button} from "antd";
import _ from 'lodash';

const TemplateView = ({addRemove,used,startEdit,confirmDeActivation,showEditor=true,d,brandName,brandAuthor,themeColor,brandImage,selectedTemplate,text}) => {
    const itemRef = useRef(null);
    const [itemHeight, setItemHeight] = useState(-1);
    const [scale, setScale] = useState(-1);
    const [loading,setLoading] = useState(true);
    const img = brandImage;
    
    useEffect(() => {
        if (itemRef.current) {
            const width = parseInt(window.getComputedStyle(itemRef.current).width)
            const height = (width*350)/700;
            setScale(height/350);
            setItemHeight(height);
            console.log(width, height);
            setLoading(false)
         }
    },[])
   
  
    return (
             <>
             {loading && <Container fluid="true" className="px-0 w-100">
      <Row className="mx-0"  >

          <Col className="px-0 py-3 mt-4 d-flex align-items-center justify-content-center"
           lg={12}      
           md={12}
           sm={12} >
          <Spin />
          <div ref={itemRef} className="branding-render "></div>
           </Col></Row>
             
             </Container>}
             {!loading &&
             <div className={selectedTemplate === d.id?"template-container active  pt-2":"template-container  pt-2"}>
             <div className="px-2 w-100"><div ref={itemRef} className="branding-render" style={{height:itemHeight+"px"}}>
             <div className="template-layout" style={{transform:`scale(${scale})`}}>
             <div className="tl-item tl-background"></div>
             <div className="tl-item tl-container" style={{top:d.speech_bubble_position_y+"px",left:d.speech_bubble_position_x+"px",width:d.speech_bubble_width+"px",height:d.speech_bubble_height+"px"}}>
             <div className="tl-svg" style={{fill:themeColor}} dangerouslySetInnerHTML={{__html: d.speech_bubble_obj["svg_code"]}} >
             
             </div>
             
             </div>
             <div className="tl-item tl-profile-image btl-profile-image-circle"
             style={{top:d.profile_image_position_y+"px",left:d.profile_image_position_x+"px",width:d.profile_image_width+"px",height:d.profile_image_height+"px",backgroundImage:`url(${img})`}}
             ></div>
             <div className="tl-item tl-author" id={d.author_text_alignment}
             style={{
                    color:d.author_font_color,
                    fontSize: d.author_font_size+"px",
                    fontWeight: d.author_font_weight,
                    fontStyle: "normal",
                    textDecoration: "none",
                    textAlign: d.author_text_alignment === 1?"left":"right",
                    top: d.author_position_y+"px",
                    left: d.author_position_x+"px",
                    transform: d.author_text_alignment === 1?`translate(0px, -50%)`:`translate(-100%, -50%)`
             }}
             >{brandAuthor}</div>
             <div className="tl-item tl-brand" id={d.id}
             style={{
                    color:d.brand_font_color,
                    fontSize: d.brand_font_size+"px",
                    fontWeight: d.brand_font_weight,
                    fontStyle: "normal",
                    textDecoration: "none",
                    textAlign: d.brand_text_alignment === 1?"left":d.brand_text_alignment === 2?"center":"right",
                    top: d.brand_position_y+"px",
                    left: d.brand_position_x+"px",
                    transform: d.brand_text_alignment === 1?`translate(0px, -50%)`:d.brand_text_alignment === 2?`translate(-50%, -50%)`:`translate(-100%, -50%)`
             }}
             >{brandName}</div>
             <div className="tl-item tl-quote"
             style={{
                    color:d.quote_font_color,
                    fontWeight: d.quote_font_weight,
                    fontStyle: "normal",
                    textDecoration: "none",
                    
                    top: d.quote_position_y+"px",
                    left: d.quote_position_x+"px",
                   
                    width: d.quote_width+"px",
                    height: d.quote_height+"px"
             }}
             >
             <div className="text" 
             style={{
                textAlign: d.quote_text_alignment === 1?"left":"right", fontSize: "33px",}}
             >
             {text ? text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor"}</div>
             </div>
             </div>
             </div>
             </div> <div className="mb-3"></div> <div className="mb-3"></div>
             {showEditor && <>{!used && <div className="template-actions">
               <div className="template-info"></div> 
               <div className="template-edit cursor-pointer flex-2">
               {/* <div className="icons "><Flower3 className="" /></div> */}
               <div className="icons " onClick={()=>startEdit(d)}><Pencil /></div>
               <div className="icons " onClick={()=>confirmDeActivation(d)}><XCircle  color={"#EF476F"} /></div>
               </div>     

             </div>}
             {used && <div className="template-actions p-3" style={{flexDirection:"column",alignItems:"flex-start",justifyContent:"flex-start",height:"auto"}}>
               <div className="template-info fz-14 fw-700">
                      {used.includes(d.id)?"1 Workspace using this template":"0 Workspaces using this template"}</div> 
               <div className="template-edit cursor-pointer flex-2 mt-3">
               <Button onClick={(e) => addRemove(d,used.includes(d.id)?"remove":"add")} className="brd-4" type="primary"> {used.includes(d.id)?"Remove from workspace":"Add to current workspace"}</Button>
              
               </div>     

             </div>}
             </>}
            </div>
            }
            </>
        
    )
}

export default TemplateView
