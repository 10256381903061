import React, { Component } from 'react'
import {ImageIcon,HashtagIcon,MentionIcon,QuoteIcon} from "res/icons/ReviewIcons"
import{ChevronLeft,ChevronRight} from "react-bootstrap-icons"
export default class PostCardActions extends Component {
         constructor(props) {
           super(props)
         
           this.state = {
              day:this.props.day
           }
         }
   componentDidUpdate(prevProps)    {
     
      if(prevProps.day !== this.props.day) {
         this.setState({day: this.props.day})
      }
   }
   changeTags = (s) => {
      this.props.updateContent("tags")
   }
   changeMentions = () => {this.props.updateContent("mentions")}
   changeImage = () => {this.props.updateContent("image")}
   changeQuote = (d) => {
      this.props.updateContent("text")
   }
  render() {
     const {day} = this.state;
    return (
      <>
        <div className="d-flex mt-3 px-4 flex-grow-1 align-items-center flex-wrap justify-content-between">
        <div className="action-tab d-flex align-items-center justify-content-between">
           <span onClick={() =>this.changeTags(day)} ><ChevronLeft /></span> 
           <span> <HashtagIcon size={[15,13]} /></span> 
           <span onClick={() =>this.changeTags(day)}> <ChevronRight /></span>          
           
        </div>
        <div className="action-tab d-flex align-items-center justify-content-between">
           <span onClick={() =>this.changeMentions()}><ChevronLeft /></span> 
           <span> <MentionIcon size={[15,15]}/></span> 
           <span onClick={() =>this.changeMentions()}> <ChevronRight /></span>          
           
        </div>
        <div className="action-tab d-flex align-items-center justify-content-between">
           <span onClick={() =>this.changeImage()}><ChevronLeft /></span> 
           <span > <ImageIcon size={[14,14]}/></span> 
           <span onClick={() =>this.changeImage()}> <ChevronRight /></span>          
           
        </div>
        <div className="action-tab d-flex align-items-center justify-content-between">
           <span onClick={() =>this.changeQuote(day)}><ChevronLeft /></span> 
           <span> <QuoteIcon size={[12,11]}/></span> 
           <span onClick={() =>this.changeQuote(day)}> <ChevronRight /></span>          
           
        </div>
        
        
       
       
        </div>
      </>
    )
  }
}



// import React,{useEffect} from 'react'

// const PostCardActions = ({updateContent,day}) => {
//     useEffect(() => {
       
//       return () => {
        
//       };
//     }, [day])
   
   
//     return (
//         <div className="d-flex mt-3 px-4 flex-grow-1 align-items-center flex-wrap justify-content-between">
//         <div className="action-tab d-flex align-items-center justify-content-between">
//            <span onClick={() =>changeTags()} ><ChevronLeft /></span> 
//            <span> <HashtagIcon size={[15,13]} /></span> 
//            <span onClick={() =>changeTags()}> <ChevronRight /></span>          
           
//         </div>
//         <div className="action-tab d-flex align-items-center justify-content-between">
//            <span onClick={() =>changeMentions()}><ChevronLeft /></span> 
//            <span> <MentionIcon size={[15,15]}/></span> 
//            <span onClick={() =>changeMentions()}> <ChevronRight /></span>          
           
//         </div>
//         <div className="action-tab d-flex align-items-center justify-content-between">
//            <span onClick={() =>changeImage()}><ChevronLeft /></span> 
//            <span > <ImageIcon size={[14,14]}/></span> 
//            <span onClick={() =>changeImage()}> <ChevronRight /></span>          
           
//         </div>
//         <div className="action-tab d-flex align-items-center justify-content-between">
//            <span onClick={() =>changeQuote(day)}><ChevronLeft /></span> 
//            <span> <QuoteIcon size={[12,11]}/>{day}</span> 
//            <span onClick={() =>changeQuote(day)}> <ChevronRight /></span>          
           
//         </div>
        
        
       
       
//         </div>
//     )
// }

// export default PostCardActions
