import { Button, Col, Layout } from 'antd'
import React from 'react'
import { Container } from 'react-bootstrap'

const DonateCrypto = () => {
    const catList = [
        "Poverty & Hunger","Health & Well Being","Quality Education",
        "Gender Equality","Clean Water & Sanitation","Affordable & Clean Energy"
        ,"Decent work and economic growth","Industry, Innovation and Infrastructure",
        "Traditional Industry revamping","Sustainable Cities and Communities"
        ,"Responsible Consumption & Production","Climate Action","Life on Land"
    ]
    
  return (
    <Container fluid className='px-0' >
     <div className='bg-hs-dark flex flex-col p-3 items-center text-white justify-center' >
         <Col span={12} className='flex flex-col p-3 items-center text-white justify-center'>
     <h1 className='text-white py-2 mb-0'>Donate Crypto</h1>
     <span className='text-white py-2 font-semibold text-center'>Reduce your tax burden by donating Bitcoin, Ethereum, and other cryptocurrencies to nonprofits, charities, schools, faith-based groups, and more.</span>
     <div className='flex flex-row space-x-6 mt-3 w-100 px-3 pb-2'>
        <div className='w-50 min-h-32 py-2 bg-white rounded-lg flex flex-col items-center cursor-pointer'>
        <img className='w-32' src='https://hs-container.s3.ap-south-1.amazonaws.com/saga/user-assets/images/p3qhPhfnMO_D7b.jpeg' />
        <span className='text-hs-dark text-lg'>Sustainable Impact Fund</span>
        </div>
        <div className='w-50 min-h-32 py-2 bg-white rounded-lg flex flex-col items-center cursor-pointer'>
        <img className='w-32' src='https://hs-container.s3.ap-south-1.amazonaws.com/saga/user-assets/images/p3qhPhfnMO_D7b.jpeg' />
        <span className='text-hs-dark text-lg'>Search Non-Profits</span>
        </div>
     </div>
     
     </Col> </div>
     <Col span={16} className='flex mx-auto flex-col p-3 items-center justify-center'>
         <div className='flex flex-col text-hs-dark space-y-3 items-center'>
        <h2>Donate to our Funds</h2>
        <span className='text-center text-base'>Choose the Impact Index Fund you want to contribute to. Use the widget to send a coin donation. If you include your email address, you will receive a receipt once the gift has cleared.</span>
         </div>
         <div className='py-1'></div>
        
         </Col>
         <Col span={22} className='flex mx-auto flex-col p-3 items-center justify-center'>
         <div className='flex flex-row flex-wrap text-hs-dark items-center justify-center w-100'>
             {
                 catList.map((cat,k)=>
                 <div key={k} className='col-md-2 h-64 col-sm-6 col-xs-6 py-3 mx-2 my-3 bg-white rounded-lg flex flex-col items-center cursor-pointer  justify-between shadow-md'>
        <img className='w-32' src='https://hs-container.s3.ap-south-1.amazonaws.com/saga/user-assets/images/p3qhPhfnMO_D7b.jpeg' />
        <span className='flex items-center justify-center text-center'>
        <span className='text-hs-dark  '>{cat}</span></span>
        <Button className='mt-2 bg-hs-pink text-white rounded-md'>Donate Crypto</Button>
        </div>
                 )
             }
         
       
         </div>
         </Col>
         <Col span={18} className="mb-3 mt-2 pr-6 mx-auto">
                <div className=" d-flex justify-center flex-col  py-3 mb-3 rounded-xl ">
                <h3>Cryptocurrency Accepted</h3>
                <div>
                    <img src='https://hs-container.s3.ap-south-1.amazonaws.com/saga/user-assets/images/1z1FJe7AC4_a3x.jpeg' />
                </div>
                </div>
            </Col>
    </Container> 
  )
}

export default DonateCrypto