import React, { useEffect, useState } from 'react'
import { InboxOutlined } from '@ant-design/icons';
import Dragger from 'antd/lib/upload/Dragger';
import { Button, Divider, Upload ,message} from 'antd';
import Utils from 'helper/Utils';
const FileUploader = ({callback, cancelCallback}) => {
    const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  
    const handleUpload = () => {
        const formData = new FormData();
        formData.append('productCSV', fileList[0]);
        setUploading(true);
        // You can use any AJAX library you like
        fetch(Utils.apiUrl("commerce/products/csv"), {
          method: 'POST',
          body: formData, 
         headers:{"Authorization": `Bearer ${Utils.getCookie("sessionhash")}`}
        })
          .then((res) => res.json())
          .then((data) => {
            if(data["IsSuccessful"]){
              message.success(data["message"],3);
              setFileList([])
              callback()
            }
            else{
              message.error(data["message"],3);
            }
          })
          .catch(() => {
            message.error('upload failed.');
          })
          .finally(() => {
            setUploading(false);
          });
      };
      const props = {
        onRemove: (file) => {
          const index = fileList.indexOf(file);
          const newFileList = fileList.slice();
          newFileList.splice(index, 1);
          setFileList(newFileList);
        },
        beforeUpload: (file) => {
          const isPNG = file.type === "text/csv";
          if (!isPNG) {
            message.error(`${file.name} is not a csv file`);
          }
          if(isPNG){
            setFileList([ file]);
          }
          return false;
        },
        fileList,
        onDrop(e) {
          console.log('Dropped files', e.dataTransfer.files);
        },
      };
  return (
    <div>
      <div className='px-5'>
      <Dragger {...props}>
    <p className="ant-upload-drag-icon">
      <InboxOutlined />
    </p>
    <p className="ant-upload-text">Click or drag file to this area to upload</p>
    <p className="ant-upload-hint">
      Please select csv file same as downloaded sample file.
    </p>
  </Dragger>
  </div>
  <Divider />
  <div className='flex items-center justify-end space-x-3 px-4 pb-4'>
    <Button loading={uploading}  className='rounded-lg px-3' onClick={cancelCallback}>Cancel</Button>
    <Button loading={uploading} disabled={fileList.length === 0} type='primary' onClick={handleUpload} className='rounded-lg px-3'>Upload File</Button>

  </div>
  </div>
  )
}

export default FileUploader