import React,{useState,useEffect,useContext} from 'react'
import { Calendar,Views, momentLocalizer,Navigate } from 'react-big-calendar'
import {Container,Row,Col} from "react-bootstrap";
import {Spin,Modal} from "antd";
import moment from 'moment'
import { AuthContext } from "app/AuthProvider";
import {X} from "react-bootstrap-icons";
import * as dates from 'date-arithmetic'
import CalendarToolbar from "./creator/CalendarToolbar";
import CalendarEvent from "./creator/CalendarEvent";
import CalendarDateView from "./creator/CalendarDateView";
import TimeGrid from 'react-big-calendar/lib/TimeGrid'
import Utils from "helper/Utils" 
import ApiService from "services/ApiService"
import _ from 'lodash';
import "./creator/calendar.css";
import PostCard from './creator/views/PostCard';
import PreviewContainer from './creator/VideoReview/VideoComponents/Animations/PreviewContainer';
import { useLocation } from 'react-router-dom';
const CalendarView = (props) => {
    const {userDetails,activeWorkSpace} = useContext(AuthContext);
    const [showEvent, setShowEvent] = useState(false)
    const [postLoading, setPostLoading] = useState(true)
    const [loading, setLoading] = useState(true)
    const [events, setEvents] = useState([]);
    const [currentMonth, setcurrentMonth] = useState(null);
    const [campaign, setData] = useState(null)
    const [duration, setDuration] = useState(null)
    const [selectedProfiles,setselectedProfiles] = useState([])
    const [customTemplates,setCustomTemplates] = useState([]);
    const [schedulePost,setschedulePost] = useState([]);
    const [videoClips,setVideoClips] = useState(null);
    const [videoDetails,setvideoDetails] = useState(null);
    const [preview,setPreview] = useState(false)
    const [availableQuotes, setavailableQuotes] = useState([])
    const [availableImages, setavailableImages] = useState([])
    const [metaInfo, setMetaInfo] = useState(null)
    const [postLink, setpostLink] = useState(null)
    const [modalTitle,setModalTitle] = useState("")
    const localizer = momentLocalizer(moment) // or globalizeLocalizer
    const brLocation = useLocation();
    
    useEffect(() => {
        getList(currentMonth);
      },[currentMonth])
      const getList = async(m) =>{
        const wid = activeWorkSpace["wid"];
        const month = moment.unix(moment(m).format("X")).format("YYYY-DD-MM");
        const urlQuery = brLocation.search !== "" ? brLocation.search.substring(1):"";
        const q = "wid="+wid+"&calendar=yes&month="+month+"&"+urlQuery;

        const events = await ApiService.PostApiCalls("campaign/calendar/list?"+q,{},true)
        setEvents(events[1]["post"].length === 0 ? []:Utils.setEvents(events[1]["post"],wid))
        setLoading(false)
        // setLoading(false)
      }
      const loadEvent = async(e)=>{
        
      
        const wid = activeWorkSpace["wid"];
        setModalTitle(moment(e["start"]).format('MMMM Do YYYY'));
        const eventPostid = e["postId"]
        setShowEvent(true)
        setPostLoading(true)
        const q = "wid="+wid+"&id="+e["cid"];
        // const q = "wid="+wid+"&calendar=yes&month="+month+"&"+urlQuery;

        const c = await ApiService.PostApiCalls("campaign/calendar/list?"+q,{},true)
          
        const campaign = c[1]["post"][0]
        setData(campaign)
        setMetaInfo(campaign["metaInfo"]?campaign["metaInfo"]:{"title":campaign.title})
        setschedulePost(_.filter(campaign["postlist"],function(p,j){return p["postid"] === eventPostid;}))
        setavailableQuotes(campaign["posts"]["quotes"])
        setavailableImages(campaign["posts"]["images"])
        setDuration(campaign["details"]["campaign-duration"])
        setVideoClips(campaign["VideoDetails"][0]?campaign["VideoDetails"][0]["clips"]:null)
        setvideoDetails(campaign["VideoDetails"][0]?campaign["VideoDetails"][0]:null)
        setpostLink(campaign["shortlink"]?campaign["shortlink"]:campaign["link"])
        setselectedProfiles(campaign["selectedProfiles"])
        const worspaceTemplates = _.filter(c[1]["post"][0]["customtemplates"], function (t,i) {
          return t["wid"] === c[1]["post"][0]["wid"];
        })
       
        setCustomTemplates(worspaceTemplates)
        setPostLoading(false)
      }
      const playVideo = () =>{     
        setPreview(true)
      }
      if(loading){
        return (
          <Container fluid="true" className="px-0">
        <Row className="mx-0">
  
            <Col className="px-0 py-5 mt-4 d-flex align-items-center justify-content-center"
             lg={12}      
             md={12}
             sm={12} >
            <Spin />
             </Col></Row>
               
               </Container>
             )
      }
      
      return (
        <>
        <Container className="px-3">
        <Row className="mx-0">
         
            <Col className="px-0 py-3 app-calendar" style={{height:"100vh"}}
             lg={12}      
             md={12}
             sm={12} >
           <Calendar
    events={events}
    defaultView={Views.MONTH}
    views={{ month: true}}
    step={60}
    showMultiDayTimes
    defaultDate={new Date()}
    components={{      
      event:CalendarEvent,
      toolbar:CalendarToolbar
    }}
    onNavigate={date => setcurrentMonth(date)}
    localizer={localizer}
    onSelectEvent={e=> loadEvent(e)}
    popup
    popupOffset={30}
  />
        </Col>
            
            </Row>
               
            </Container>
            <Modal onCancel={()=>setShowEvent(false)} maskClosable={true} className="campaign-new-modal rounded-2xl" contentClassName="campaign-new-modal rounded-2xl" title={modalTitle} closable={true}  visible={showEvent} footer={null} >
            
            {
              postLoading && <div className="d-flex align-items-center justify-content-center py-4"><Spin /></div>
            }
            <div className="d-flex align-items-center justify-content-start  fw-600 pb-2">{
              !postLoading && <>{metaInfo["title"]}</>
            }</div>
            {
              !postLoading && schedulePost.map((post,key)=>(
                <PostCard hideTools={true} postLink={postLink} availableImages={availableImages} selectedProfiles={selectedProfiles} updatePostContent={null} playvideo={(e) => playVideo()} videoClips={videoClips}  text="3" key={key} id={key} post={post} content={campaign.posts} availableQuotes={availableQuotes} customTemplates={customTemplates} metaInfo={metaInfo}/>
              ))
            }
            </Modal>
            {preview &&  <Modal visible={preview} closeIcon={<X color="#EF476F" size={24} />} onCancel= {()=>setPreview(false)} closable={true} title="" footer={null}>
          <PreviewContainer video={videoDetails} clips={videoClips} preview={preview} />
         
        </Modal>}
            </>
    )
}

export default CalendarView
