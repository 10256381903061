
import React, { useState } from 'react'
import { Button, Breadcrumb, Spin, Row, Col, Card ,Tabs} from "antd";

import { Link } from 'react-router-dom';
const { TabPane } = Tabs;
const HashShop = () => {
    const [loading, setloading] = useState(false)
    const [activeTab, setactiveTab] = useState("1")
    const [view, setview] = useState("1")
    return loading ? (
        <div className=" h-100 d-flex justify-center align-items-center bg-white py-3 px-4 rounded-xl ">
          <Spin />
        </div>
      ) : (
        <>
          <Row gutter={16}>
            <Col span={24} className="mb-3">
              <div className=" d-flex justify-center flex-col bg-white py-3 mb-3 rounded-xl ">
                <div className="px-4 d-flex flex-col justify-between mb-2 pb-2 border-b">
                  <span className="text-hp text-lg font-semibold"><Breadcrumb>
    
    <Breadcrumb.Item>
      <Link to={"/social-shop"}>Social Shop</Link>
    </Breadcrumb.Item>
   
    <Breadcrumb.Item>Hash Shop</Breadcrumb.Item>
  </Breadcrumb></span>
                  <span className="text-hp text-xs">
                  Shoppable Galleries & UGC Feeds For eCommerce
                  </span>
                </div>
    
               {view === "1" && <div className='flex items-start justify-between py-2 px-0'>
               <Col span={14} className="mb-3"><div className='w-100 flex flex-col space-y-2'>
               <span className="text-hp text-lg font-semibold py-2 border-b">Getting Started With First Shoppable Gallery</span>
                <div className='py-2'>
                <Tabs onChange={(key)=>setactiveTab(key)} defaultActiveKey={activeTab} activeKey={activeTab} activeTab={activeTab} tabPosition={"left"} >
                <TabPane tab={<span>Content Gallery</span>} key="1">
                <div className='w-100 flex flex-col space-y-2'>
                <h4>Select Content</h4>
                <span className="text-hp text-xs">Start with collecting content from social media platforms for your content gallery.</span>
                <div>
                <ul style={{listStyle:"disc"}} className="px-2 space-y-2">
                    <li >Click on Add Feed to get started</li>
                    <li>Select social media platform</li>
                    <li>Enter the feed type like Hashtag, Handle, etc.</li>
                    <li>Connect social platform to collect content</li>
                </ul>
                    
                </div>
                <div>
                <Button onClick={()=>setactiveTab("2")} className=' fw-700 my-3 rounded-lg text-white bg-hs-pink  hover:border-hs-pink'>Add Feed</Button>
                </div>
                    </div>
                 </TabPane>
                 <TabPane tab={<span>Products Catalog</span>} key="2">
                 <div className='w-100 flex flex-col space-y-2'>
                <h4>Adding & Tagging Your Products</h4>
                <span className="text-hp text-xs">To make your content Shoppable, you need to add/upload your products & tag products to the collected content.</span>
                <div>
                <ul style={{listStyle:"disc"}} className="px-2 space-y-2">
                    <li >Direct Sync From Shopify</li>
                    <li>Add Product Manually</li>
                    <li>Now click on Tag Products option on posts</li>
                    <li>Search the product and click to tag it</li>
                </ul>
                    
                </div>
                <div>
                <Button onClick={()=>setactiveTab("3")} className=' fw-700 my-3 rounded-lg text-white bg-hs-pink  hover:border-hs-pink'>Add & Tag Products</Button>
                </div>
                    </div>
                 </TabPane>
                 <TabPane tab={<span>Design & Filter</span>} key="3">
                 <div className='w-100 flex flex-col space-y-2'>
                <h4>Customize & Moderate Shoppable Gallery</h4>
                <span className="text-hp text-xs">
                This section helps you make your Shoppable Gallery more engaging, appealing, and exciting with customization options.</span>
                <div>
                <ul style={{listStyle:"disc"}} className="px-2 space-y-2">
                    <li >Moderate content of your gallery</li>
                    <li>Choose any theme, card style, or more</li>
                    <li>Customize the call-to-action button</li>
                    
                </ul>
                    
                </div>
                <div>
                <Button onClick={()=>setactiveTab("4")} className=' fw-700 my-3 rounded-lg text-white bg-hs-pink  hover:border-hs-pink'>Publish Gallery</Button>
                </div>
                    </div>
                 </TabPane>
                 <TabPane tab={<span>Publish Gallery</span>} key="4">
                 <div className='w-100 flex flex-col space-y-2'>
                <h4>Publish Your Shoppable Gallery</h4>
                <span className="text-hp text-xs">
                Now Publish your Shoppable Gallery on your eCommerce website or Online store’s homepage, product pages, or any other landing pages.</span>
                <div>
                <ul style={{listStyle:"disc"}} className="px-2 space-y-2">
                    <li >Click on the Publish button</li>
                    <li>Choose your website platform</li>
                    <li>Follow simple steps to publish gallery</li>
                    
                </ul>
                    
                </div>
                <div>
                <Button  onClick={()=>setview("2")} className=' fw-700 mx-2 rounded-lg text-white bg-hs-pink  hover:border-hs-pink'>Preview Gallery</Button>
                </div>
                    </div>
                 </TabPane>
        </Tabs>
                </div>
                </div></Col>
                <Col span={10} className="mb-3"> <div>
                <div className="px-4 d-flex flex-col justify-between mb-2 pb-2 border-b">
                <span className="text-hp text-lg font-semibold pb-2">What is social shop?</span>
                  <span className="text-hp text-xs">
                  <iframe className='w-100' height={"255"} src="https://www.youtube.com/embed/H_VznoiLSdM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </span>
                </div>
               
                </div></Col>
               </div>}
              
               {view === "2" && <div className='flex items-start justify-between py-2 px-0'>
               <Col span={24} className="mb-3"><div className='w-100 flex flex-col space-y-2'>
               <span className="text-hp text-lg font-semibold py-2 border-b">Shoppable Gallery Preview</span>
                <div className='p-1 bg-gray-100 rounded overflow-hidden'>
                    <img src='https://hs-container.s3.ap-south-1.amazonaws.com/saga/user-assets/images/p09qMMdrMh_a8a.png'></img>
                </div>
                <div className='py-2'>
                <Button  onClick={()=>setview("1")} className=' fw-700 mx-2 rounded-lg text-white bg-hs-pink  hover:border-hs-pink'>Back</Button>
                </div>
                </div></Col>
                
               </div>}
              </div>
             
            </Col>
            <Col span={24} className="mb-3">
            <div className=" d-flex justify-center flex-col bg-white p-3 py-4 mb-3 rounded-xl ">
            <div className='flex items-center'>
            <span className='px-2 font-medium'>If you have any questions then you can schedule a demo with our experts or explore the support center to get the step by step guide for Social Shop.</span>
            <span className='flex space-x-2'>
            <Button className='rounded-md' size='large'>Example Gallery</Button>
            <Button size='large' className=' fw-700 mx-2 rounded-lg text-white bg-hs-pink  hover:border-hs-pink'>Book A Demo</Button>
            </span>
            </div>
            </div>
            </Col>
          </Row>
        </>
      );
}

export default HashShop