import React from 'react'
import strawberry from "assets/images/hashsaga.png"
import Utils from 'helper/Utils'
const Info = ({campaign}) => {
    
    return (
        <div className="list-profile">
          <img className="campaign-image" src={Utils.getImageUrl(campaign)?Utils.getImageUrl(campaign):strawberry}  alt={campaign["title"]} />
          <span className="campaign-title text-full ml-2 fz-14">{campaign["title"]}</span>
          </div>
    )
}

export default Info
