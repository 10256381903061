import React from 'react'
import { Col, Row, Statistic, Tabs } from 'antd';
import { Container } from "react-bootstrap";
import { useNavigate, useParams} from 'react-router-dom';

import DataLoader from 'container/common/DataLoader'
import { useState } from 'react';
import { ArrowBarLeft, ArrowLeft } from 'react-bootstrap-icons';
import { useEffect } from 'react';
import ApiService from 'services/ApiService';
import UserConnections from './UserConnections';

const UserFollowers = (props) => {
  const [loading, setloading] = useState(true);
  const brPrams = useParams();
  const navigate = useNavigate()
  const [profile, setprofile] = useState(null)
  const [tabKey, settabKey] = useState(brPrams.connection === "following"?"2":"1")
  useEffect(() => {
    
    getUserInfo()
  
    return () => {
      
    }
  }, [brPrams.profile])
  const onChange = (key) => {
    
    settabKey(key);
    navigate({ pathname: `/${brPrams.profile}/${key === "1"?"followers":"following"}`})
    document.title = key === "1"?`People following ${profile["name"]} / HashSaga`:`People followed by ${profile["name"]} / HashSaga`;
  };
  const getUserInfo = async()=>{
    const user = await ApiService.PostApiCalls(`UserByScreenName/${brPrams.profile}`,{},true)
    if(user[0] && user[1]["IsSuccessful"]){
      setprofile(user[1]["profile"])
      document.title = tabKey === "1"?`People following ${user[1]["profile"]["name"]} / HashSaga`:`People followed by ${user[1]["profile"]["name"]} / HashSaga`;
    }
    
    setloading(false)
  }
    
    if(loading){
      return(
        <Container className=" bg-white flex-1 h-100  px-2 py-0 rounded-xl overflow-hidden" fluid>
        <DataLoader /></Container>
      )
    }
    if(!profile){
      return(
        <Container className=" bg-white flex-1 h-100  px-2 py-0 rounded-xl overflow-hidden" fluid>
        <DataLoader /></Container>
      )
    }
    
  return (
    <Container className=" bg-white flex-1 h-100  px-0 py-0 rounded-xl overflow-hidden" fluid>
      <Row gutter={24} className="mx-0">
      <Col span={24} className="mb-0 px-0 bg-white border-b-2">
      <Col span={24} className="py-1 px-4 flex items-center cursor-pointer my-1" onClick={navigate(-1)}>
        <div><ArrowLeft size={24} color={"#4c4c4c"} /></div>
          <div className="bg-white px-4 flex flex-col">
            <span className="font-bold ">{profile.name}</span>
            <span className="text-xs font-light muted">@{profile.userName}</span>
          </div>
        </Col>
       
        <Col className='px-0'>
        <Tabs defaultActiveKey={tabKey}  onChange={onChange} tabBarGutter={44} tabBarStyle={{paddingLeft:20,marginBottom:0}} >
    <Tabs.TabPane tab="Followers" key="1" >
     
    </Tabs.TabPane>
    <Tabs.TabPane tab="Following" key="2">
      
    </Tabs.TabPane>
   
  </Tabs></Col>
      </Col>
      <Col span={24}>
      {
        tabKey === "1" ?<UserConnections type={"followers"} userId={profile.uniqueId} />:<UserConnections type={"following"}  userId={profile.uniqueId}/>
      }
      </Col>
      </Row>
   
      </Container>
  )
}

export default UserFollowers