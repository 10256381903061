import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  Button,
  Checkbox,
  notification,
  Input,
  DatePicker,
  Spin,
  Select,
  Switch,
  InputNumber,
  Tooltip,
} from "antd";
import Tags from "container/common/Tags";
import Utils from "helper/Utils";
import _ from "lodash";

import ApiService from "services/ApiService";

import moment from "moment";
import { Coin, InfoCircle } from "react-bootstrap-icons";
import HashIcon from "components/HashIcon";
import { HASH_DEFAULT_GASFEE } from "constants/hashkeys.constant";
const { Option } = Select;
export const HashtagSaleModal = ({
  showModalFirst,
  callback,
  cancelCallBack,
  profiles,
  user,
  event,
}) => {
  const [show, setshow] = useState(showModalFirst);
  const [offers, setoffers] = useState([]);
  const [processOn, setprocessOn] = useState(false);
  const [hashItem, sethashItem] = useState(event.item)
  useEffect(() => {
    if (event.event === "sale") {
      
      getOffers();
    }

    return () => {};
  }, [event]);
  const getOffers = async () => {
    const saleOffers = await ApiService.PostApiCalls(
      "keyhashes/user/sales/offers/" + event["item"].hashId,
      {},
      true
    );
    if (saleOffers[0] && saleOffers[1]["IsSuccessful"]) {
      setoffers(saleOffers[1]["offers"]);
      // console.log(saleOffers);
    }
  };
  const acceptOffer = async()=>{
      
      // setprocessOn(true)
      const offerDetails = {
        offerBy:offers[0]["offeredBy"],
        options:offers[0]["offerOptions"],
        listingType:offers[0]["listingType"],
        listingEnddate:offers[0]["listingEnddate"],
      }

      console.log(offerDetails);
      // return
      const offer = await ApiService.PostApiCalls("keyhashes/user/sales/acceptoffer/"+event["item"].hashId,{mintedBy:event["item"].mintedBy,offerDetails:offerDetails},true)
     
      if(offer[0]){
        Utils.showNotification(
            "success",
            "Done",
            "Keyword sale process completed"
          );

          cancelCallBack({...hashItem,listingStatus:"completed"})
      }
      else{
        Utils.showNotification(
            "error",
            "Oopps",
            "You do not have enought coins!"
          );
      }
      
      setprocessOn(false)
  }
  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          setshow(false);
          callback(false);
        }}
        centered
        contentClassName="campaign-new-modal rounded-2xl"
        backdrop={"static"}
      >
        <Modal.Header>
          <div className="flex flex-row w-full justify-between space-x-4 font-semibold items-center">
            <span>{`Offer for - ${event.item["title"]}`}</span>
          </div>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column pt-0">
          <div className="py-3"></div>
          {offers.length > 0 && <>{offers[0]["listingType"] === "mention"?(
            <div className="flex flex-col space-y-4 px-3">
              <span className="flex flex-row space-x-3 items-center">
                <span className="font-semibold">Mention Handle: </span>
                <span className="flex items-center space-x-2">
                  
                  <span>{offers[0]["offerOptions"]["mentionHandle"]}</span>
                </span>
              </span>
              <span className="flex flex-row space-x-3 items-center">
                <span className="font-semibold">Offer By: </span>
                <span>
                  <a
                    target={"_blank"}
                    href={"/" + offers[0].author[0]["userName"]}
                  >
                    {offers[0].author[0]["name"]}
                  </a> <span className="text-xs">on {moment
                    .unix(offers[0].updatedAt)
                    .format("MMM DD, YYYY ")}</span>
                </span>
              </span>
              <span className="flex flex-row space-x-3 items-center">
                <span className="font-semibold">Requested till: </span>
                <span className="text-xs">{moment
                    .unix(offers[0]["offerOptions"]["endDate"])
                    .format("MMM DD, YYYY ")}</span>
              </span>
              <span className="text-xs text-danger">
                Once you accept this offer you will not able to rent/sale this keyword. You won't be
                able to undo this action.
              </span>
            </div>
          ):offers[0]["listingType"] === "rent"?(
            <div className="flex flex-col space-y-4 px-3">
              <span className="flex flex-row space-x-3 items-center">
                <span className="font-semibold">Rent: </span>
                <span className="flex items-center space-x-2">
                  <HashIcon />
                  <span>{offers[0]["offerOptions"]["price"]}</span>
                </span>
              </span>
              <span className="flex flex-row space-x-3 items-center">
                <span className="font-semibold">Offer By: </span>
                <span>
                  <a
                    target={"_blank"}
                    href={"/" + offers[0].author[0]["userName"]}
                  >
                    {offers[0].author[0]["name"]}
                  </a> <span className="text-xs">on {moment
                    .unix(offers[0].updatedAt)
                    .format("MMM DD, YYYY ")}</span>
                </span>
              </span>
              <span className="flex flex-row space-x-3 items-center">
                <span className="font-semibold">Rent type: </span>
                <span className="flex items-center space-x-2">
                  
                  <span>Per {offers[0]["offerOptions"]["rentOption"]}</span>
                </span>
              </span>
              <span className="flex flex-row space-x-3 items-center">
                <span className="font-semibold">Requested till: </span>
                <span className="text-xs">{moment
                    .unix(offers[0]["listingEnddate"])
                    .format("MMM DD, YYYY ")}</span>
              </span>
              <span className="text-xs text-danger">
                Once you accept this offer you will not able to allow mention/sale this keyword. You won't be
                able to undo this action.
              </span>
            </div>
          ):(
            <div className="flex flex-col space-y-4 px-3">
              <span className="flex flex-row space-x-3 items-center">
                <span className="font-semibold">Price: </span>
                <span className="flex items-center space-x-2">
                  <HashIcon />{" "}
                  <span>{offers[0]["offerOptions"]["price"]?offers[0]["offerOptions"]["price"]:0}</span>
                </span>
              </span>
              {offers[0]["offerOptions"]["fractionInSale"] && offers[0]["offerOptions"]["fractionInSale"]>0 &&<span className="flex flex-row space-x-3 items-center">
                <span className="font-semibold">Fractions: </span>
                <span className="flex items-center space-x-2">
                  
                  <span>{offers[0]["offerOptions"]["fractionInSale"]?offers[0]["offerOptions"]["fractionInSale"]:0}</span>
                </span>
              </span>}
              <span className="flex flex-row text-xs mx-0 ">Including gas fees - <HashIcon /> {` ${HASH_DEFAULT_GASFEE}`}</span>
              <span className="flex flex-row space-x-3 items-center">
                <span className="font-semibold">Offer By: </span>
                <span>
                  <a
                    target={"_blank"}
                    href={"/" + offers[0].author[0]["userName"]}
                  >
                    {offers[0].author[0]["name"]}
                  </a> <span className="text-xs">on {moment
                    .unix(offers[0].updatedAt)
                    .format("MMM DD, YYYY ")}</span>
                </span>
              </span>
              <span className="text-xs text-danger">
                Once you accept this offer all rights of the keyword will be
                transfer to <b>{offers[0].author[0]["name"]}</b>. You won't be
                able to undo this action.
              </span>
            </div>
          )}
          </>}
          <div className="py-3"></div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-end space-x-4">
          <Button
            // 
            onClick={() => {
              setshow(false);
              cancelCallBack();
            }}
            className="px-3 brd-4 fw-700 mx-2 rounded-lg hover:text-hs-pink hover:border-hs-pink"
          >
            Cancel
          </Button>
          <Button
            loading={processOn}
            onClick={() => acceptOffer()}
            type="primary"
            className="px-3 brd-4 fw-700 mx-2 rounded-lg border-0 flex items-center"
          >
            Accept Offer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
