import { Button, Dropdown, Menu, Modal } from 'antd';
import { AuthContext } from 'app/AuthProvider';
import React,{useContext,useState} from 'react'
import { useNavigate } from "react-router-dom";
import CreateWorkspace from './CreateWorkspace';
import { PersonLinesFill, PersonSquare } from 'react-bootstrap-icons';

const WorkspacesDropdown = ({authUser,activeWorkSpace,hide}) => {
    const {setactiveWorkSpace} = useContext(AuthContext);
    const [addworkspaceVisible,setAaddworkspaceVisible] = useState(false);
    const navigate = useNavigate()
    
    const changeWorkspace = async(selected) =>{
        setactiveWorkSpace(selected)
        navigate("/creator/home")

    }
    const handleCancel = () => setAaddworkspaceVisible(false);
    const usermenuAction =  (
        <Menu className="mt-0">
          {/* <Menu.Item className="p-2 pr-4"  >
          <span className="ml-3" >Follower</span>  
          </Menu.Item>
          <Link to={"/creator/edit/start"} ><Menu.Item className="p-2 pr-4"  >
          <span className="ml-3" >Creator</span>
          </Menu.Item></Link> */}
          {
            authUser.workspace.map((v,i)=>(
            <Menu.Item key={i} onClick={()=>changeWorkspace(v)}  className="p-2">
        
      <span className="ml-0" >{v.name}</span>
          
        </Menu.Item>
              ))
          }
          <Menu.Divider />
          <Menu.Item key={"new"} onClick={()=>setAaddworkspaceVisible(true)} className="p-2 pr-4"  >
            <Button className="ml-3 text-hs-pink rounded-sm" type='' >Create Workspace</Button>  
          </Menu.Item>
        </Menu>
      );
  return (
   <> 
    <Dropdown overlay={usermenuAction} placement="bottomRight" trigger={['click']}>
    {/* <User color="#ffffff" /> */}
    <div className='flex bg-light rounded items-center justify-center py-2' >
        {!hide?<Button size='large' className="text-hs-pink-dark w-full rounded-sm font-semibold">{activeWorkSpace['name']}</Button>:<><PersonLinesFill size={24} className='text-hs-pink-dark' /></>}
        </div>
    </Dropdown> 
    <Modal className="campaign-new-modal" title={"Adding Workspace"} onCancel={handleCancel} visible={addworkspaceVisible}  closable={true} footer={null} >
   <CreateWorkspace close={handleCancel}/>

</Modal></>
  )
}

export default WorkspacesDropdown