import React, { useState } from 'react'
import { Button, Col, Row,Checkbox, Form, Input,Radio, Select, InputNumber  } from 'antd'
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import jsonData from '../../../res/extra/country_dial_list.json';
import { useEffect } from 'react';
import Utils from 'helper/Utils';
import {
  setKey,
  setDefaults,
  setLanguage,
  setRegion,
  fromAddress,
  fromLatLng,
  fromPlaceId,
  setLocationType,
  geocode,
  RequestType,
} from "react-geocode";
import { useDispatch, useSelector } from 'react-redux';
import { updatePropertyListing } from '../store/dataSlice';
const { Option } = Select;

const toRad = (Value) =>
{
    return Value * Math.PI / 180;
}
 const calcCrow = (lat1, lon1, lat2, lon2) =>
{
  var R = 6371; // km
  var dLat = toRad(lat2-lat1);
  var dLon = toRad(lon2-lon1);
  var lat1 = toRad(lat1);
  var lat2 = toRad(lat2);

  var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  var d = R * c;
  return d;
}
const ListingBasicInfo = ({next}) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const listingBasicInfo = useSelector((state) => state.webStreet.data.listingBasicInfo)

  
    const [basicInfo, setbasicInfo] = useState(listingBasicInfo)
    const [locationUpadted, setlocationUpadted] = useState(false)
    useEffect(() => {
      if(!locationUpadted){
        if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition(function (position) {
            
            const localLocation = Utils.getLocalInfo("posCords");
            
            
           if(localLocation ){
            const dist = calcCrow(localLocation["lat"],localLocation["long"] ,position.coords.latitude ,position.coords.longitude)
            if(dist > 50){
              Utils.setLocalInfo("posCords",{lat:position.coords.latitude,long:position.coords.longitude})
              geocode(RequestType.LATLNG, `${position.coords.latitude}, ${position.coords.longitude}`)
            .then(({ results }) => {
              const address = results[0].formatted_address;
              const city = Utils.getLocationInfo(results[0].address_components);
              if (city) {
                Utils.setLocalInfo("addressInfo",city)
                setbasicInfo({...basicInfo,city:city?city.city:""})
              } else {
                console.log('City not found in address components.');
              }
            })
            .catch(console.error);
            }
            else{
              const addInfo = Utils.getLocalInfo("addressInfo");
              setbasicInfo({...basicInfo,city:addInfo?addInfo.city:""})
            }
           
           
           }
           else{
            
            geocode(RequestType.LATLNG, `${position.coords.latitude}, ${position.coords.longitude}`)
            .then(({ results }) => {
              const address = results[0].formatted_address;
              const city = Utils.getLocationInfo(results[0].address_components);
              if (city) {
                Utils.setLocalInfo("addressInfo",city)
                setbasicInfo({...basicInfo,city:city?city.city:""})
              } else {
                console.log('City not found in address components.');
              }
            })
            .catch(console.error);
           
            Utils.setLocalInfo("posCords",{lat:position.coords.latitude,long:position.coords.longitude})
            
           }
   
          });
          
        } else {
          console.log("Geolocation is not available in your browser.");
        }
    
      setlocationUpadted(true)
    }
    form.setFieldsValue(basicInfo)

      return () => {
        
      }
    }, [form, basicInfo])
    
    const handleChangeCode = (v)=>{
      console.log(v);
    }
    const onFinish = (values) => {
        console.log('Received values of form: ', values);
        dispatch(updatePropertyListing({type:"listingBasicInfo",update:{...values}}))
        next(1)
      };
      const prefixSelector = (
        <Form.Item name="prefix" noStyle>
          <Select
            style={{
              width: 70,
            }}
            
            name="prefix-code"
            showSearch
            defaultValue={['+91']}
            // searchValue='+91'
    onChange={handleChangeCode}
    optionLabelProp="label"
    showArrow={false}
            dropdownMatchSelectWidth={false}
            // filterSort={(optionA, optionB) =>
            //     (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            //   }
          >
            {
                jsonData.map((cnt,i) => <Option key={i} value={`${cnt.value}(${cnt.label})`} label={cnt.value} >{`${cnt.value}(${cnt.label})`}</Option>)
            }
            
            
          </Select>
        </Form.Item>
      );
  return (
    <Row>
    <Col xs={24} sm={24} md={18} lg={18} xl={18} className='mx-auto '>
    <Form
      form={form}
      name="normal_login"
      className="login-form"
      initialValues={{
        
        layout: "vertical",
        ...basicInfo
      }}
      layout={"vertical"}
      onFinish={onFinish}
      
    >
      <Form.Item
        name="PropertyType"
        label="Property Type"
        rules={[
          {
            required: true,
            message: 'Please input your Username!',
          },
        ]}
      >
      <Radio.Group defaultValue={basicInfo.type} buttonStyle="solid" size="large" className='custom-ant-r' >
            <Radio.Button className='rounded-md mx-2 '  value="Residential">Residential</Radio.Button>
            <Radio.Button className='rounded-md mx-2 '  value="Commercial">Commercial</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="listingFor"
        label="Looking to"
        rules={[
          {
            required: true,
            message: 'Please input your Username!',
          },
        ]}
      >
        <Radio.Group defaultValue={basicInfo.listingFor} buttonStyle="solid" className='custom-ant-r' size="large">
            <Radio.Button className=' rounded-md mx-2 '  value="Sale">Sale</Radio.Button>
            <Radio.Button className=' rounded-md mx-2 '  value="Rent">Rent</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="phone"
        
        rules={[
          {
            required: true,
            message: 'Please input your phone number!',
          },
        ]}
      >
        <InputNumber
          addonBefore={prefixSelector}
          placeholder='Enter Phone Number'
          className='rounded'
          controls={false}
          maxLength={10}
          name="phone"
          style={{
            width: 340,
          }}
         size='large'
         autoComplete='off'
        />
      </Form.Item>
      <Form.Item
        name="fullname"
        
        
        rules={[
          {
            required: true,
            message: 'Please input your name!',
            whitespace: true,
          },
        ]}
      >
        <Input placeholder='Name' size='large' className='rounded' style={{
            width: 340,
          }}/>
      </Form.Item>
     
      <Form.Item
        name="city"
        
        extra="Location auto-detected"
        rules={[
          {
            required: true,
            message: 'Please input your city!',
            whitespace: true,
          },
        ]}
      >
        <Input placeholder='City' size='large'   className='rounded' style={{
            width: 340,
          }}/>
      </Form.Item>
      <Form.Item className='w-50'>
        <Button type="primary" block htmlType="submit" size='large'  className={`px-3 brd-4 fw-700 mx-2 rounded-lg text-white bg-hs-pink  hover:border-hs-pink `}>
          Start Now
        </Button>
       
      </Form.Item>
    </Form>
    </Col>
    
  </Row>
  )
}

export default ListingBasicInfo