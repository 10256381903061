

import React from 'react'
import {Facebook,Twitter,Linkedin,Instagram} from "react-bootstrap-icons";
const SocialProfileImages = ({p,id}) => {
    return (
        <div className="profile-list" key={id}><div className="image">
        <img src={p["fb-picture"]} alt={p.name}></img>
        </div>
               
        {p.source === "Facebook" && <div  className="source facebook" ><Facebook color="#4267B2" size={24}/> </div>}
        {p.source === "Twitter" && <div  className="source twitter" ><Twitter color="#00acee" size={22}/> </div>}
        {p.source === "Linkedin" && <div  className="source linkedin" ><Linkedin color="#0e76a8" size={24}/> </div>}
        {p.source === "Instagram" && <div  className="source instagram" ><Instagram color="#fff" size={20}/> </div>}
       
       
        </div>
    )
}

export default SocialProfileImages
