import { Col } from "antd";
import React from "react";
import { List, Typography, Divider } from "antd";
import { ChevronBarRight, ChevronRight } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import UserAccountInfo from "./UserAccountInfo";
import _ from "lodash"

const UserAccount = ({ user,params,passtoken }) => {
    
    const acounntInfoList =["username","email","phone","password","2fa"] ;
    const socialList =["facebook","instagram","twitter","youtube","linkedin"] ;

  return (
    <>
      {!params.type && <><Col span={24} className="py-0 px-0 mb-2">
        <div className="bg-white p-3 px-3 flex space-x-3 items-center">
          <span className="font-bold">ACCOUNT</span>
        </div>
      </Col>
      <Col span={24} className="px-0">
        <List
          header={<div className="font-semibold px-3">Account Info</div>}
          className="bg-white mb-2 pb-3"
        >
          <List.Item className="cursor-pointer p-1 px-1">
            <Link
              className="text-hs-dark px-1 flex w-100"
              to={`/my/settings/account/username`}
            >
              <span
                className={
                  "flex justify-between items-center w-100 p-2 hover:bg-blue-50 hover:text-blue-400"
                }
              >
                <span className="flex flex-col space-y-1">
                  <span>Username</span>
                  <span className="text-xs text-gray-500">
                    @{user["userName"]}
                  </span>
                </span>
                <ChevronRight size={20} />
              </span>
            </Link>
          </List.Item>
          <List.Item className="cursor-pointer p-1">
            <Link
              className="text-hs-dark px-1 flex w-100"
              to={`/my/settings/account/email`}
            >
              <span
                className={
                  "flex justify-between items-center w-100 p-2 hover:bg-blue-50 hover:text-blue-400"
                }
              >
                <span className="flex flex-col space-y-1">
                  <span>Email</span>
                  <span className="text-xs text-gray-500">{user["email"]}</span>
                </span>
                <ChevronRight size={20} />
              </span>
            </Link>
          </List.Item>
          <List.Item className="cursor-pointer p-1">
            <Link
              className="text-hs-dark px-1 flex w-100"
              to={`/my/settings/account/phone`}
            >
              <span
                className={
                  "flex justify-between items-center w-100 p-2 hover:bg-blue-50 hover:text-blue-400"
                }
              >
                <span className="flex flex-col space-y-1">
                  <span>Phone number</span>
                  {user["phone"] && <span className="text-xs text-gray-500">{`${user["phone"]["flag"]}(${user["phone"]["dial_code"]})`}{user["phone"]["number"]}</span>}
                </span>
                <ChevronRight size={20} />
              </span>
            </Link>
          </List.Item>
        </List>
        {/* <List
          header={<div className="font-semibold px-3">Social Accounts</div>}
          className="bg-white mb-2 pb-3"
        >
          <List.Item className="cursor-pointer p-1">
            <Link
              className="text-hs-dark px-1 flex w-100"
              to={`/my/settings/account/facebook`}
            >
              <span
                className={
                  "flex justify-between items-center w-100 p-2 hover:bg-blue-50 hover:text-blue-400"
                }
              >
                <span className="flex flex-col space-y-1">
                  <span>Facebook</span>
                  
                </span>
                <ChevronRight size={20} />
              </span>
            </Link>
          </List.Item>
          <List.Item className="cursor-pointer p-1">
            <Link
              className="text-hs-dark px-1 flex w-100"
              to={`/my/settings/account/twitter`}
            >
              <span
                className={
                  "flex justify-between items-center w-100 p-2 hover:bg-blue-50 hover:text-blue-400"
                }
              >
                <span className="flex flex-col space-y-1">
                  <span>Twitter</span>
                  
                </span>
                <ChevronRight size={20} />
              </span>
            </Link>
          </List.Item>
          <List.Item className="cursor-pointer p-1">
            <Link
              className="text-hs-dark px-1 flex w-100"
              to={`/my/settings/account/instagram`}
            >
              <span
                className={
                  "flex justify-between items-center w-100 p-2 hover:bg-blue-50 hover:text-blue-400"
                }
              >
                <span className="flex flex-col space-y-1">
                  <span>Instagram</span>
                  
                </span>
                <ChevronRight size={20} />
              </span>
            </Link>
          </List.Item>
          <List.Item className="cursor-pointer p-1">
            <Link
              className="text-hs-dark px-1 flex w-100"
              to={`/my/settings/account/linkedin`}
            >
              <span
                className={
                  "flex justify-between items-center w-100 p-2 hover:bg-blue-50 hover:text-blue-400"
                }
              >
                <span className="flex flex-col space-y-1">
                  <span>Linkedin</span>
                  
                </span>
                <ChevronRight size={20} />
              </span>
            </Link>
          </List.Item>
          <List.Item className="cursor-pointer p-1">
            <Link
              className="text-hs-dark px-1 flex w-100"
              to={`/my/settings/account/youtube`}
            >
              <span
                className={
                  "flex justify-between items-center w-100 p-2 hover:bg-blue-50 hover:text-blue-400"
                }
              >
                <span className="flex flex-col space-y-1">
                  <span>YouTube</span>
                  
                </span>
                <ChevronRight size={20} />
              </span>
            </Link>
          </List.Item>
        </List> */}
        <List
          header={<div className="font-semibold px-3">Security</div>}
          className="bg-white mb-2 pb-3"
        >
          <List.Item className="cursor-pointer p-1">
            <Link
              className="text-hs-dark px-1 flex w-100"
              to={`/my/settings/account/password`}
            >
              <span
                className={
                  "flex justify-between items-center w-100 p-2 hover:bg-blue-50 hover:text-blue-400"
                }
              >
                <span className="flex flex-col space-y-1">
                  <span>Password</span>
                  
                </span>
                <ChevronRight size={20} />
              </span>
            </Link>
          </List.Item>
          <List.Item className="cursor-pointer p-1">
            <Link
              className="text-hs-dark px-1 flex w-100"
              to={`/my/settings/account/2fa`}
            >
              <span
                className={
                  "flex justify-between items-center w-100 p-2 hover:bg-blue-50 hover:text-blue-400"
                }
              >
                <span className="flex flex-col space-y-1">
                  <span>Two Step Authentication</span>
                  
                </span>
                <ChevronRight size={20} />
              </span>
            </Link>
          </List.Item>
          
        </List>
        <List
          header={<div className="font-semibold px-3">Account management</div>}
          className="bg-white  pb-3"
        >
          <List.Item className="cursor-pointer p-1">
            <a
              className="text-hs-dark px-1 flex w-100"
              href={`mailto:delete-account@hashsaga.com`}
            >
              <span
                className={
                  "flex justify-between items-center w-100 p-2 hover:bg-blue-50 hover:text-blue-400"
                }
              >
                <span className="flex flex-col space-y-1">
                  <span>Delete account</span>
                  
                </span>
                <ChevronRight size={20} />
              </span>
            </a>
          </List.Item>
         
        </List>
      </Col></>}

         {
             _.includes(acounntInfoList,params.type) && <UserAccountInfo passtoken={passtoken} user={user} header={params.type} />
         }  
          {
             _.includes(socialList,params.type) && <UserAccountInfo user={user} header={params.type} />
         }     
    </>
  );
};

export default UserAccount;
