import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import ApiService from 'services/ApiService'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Avatar, Divider, Empty, List, Skeleton } from 'antd';
import ProfileName from 'components/ProfileName'
import { Link } from 'react-router-dom'

const UserConnections = ({type,userId}) => {
    const [data, setdata] = useState([])
    const [loading, setLoading] = useState(false);

    const [pagination, setpagination] = useState({
      offset:0,limit:20,total:0
    })
    useEffect(() => {
      
      getList();
      
      return () => {
        
      }
    }, [type])
    const loadMoreData = async() => {
      if (loading) {
        return;
      }
      setLoading(true);
      const members = await ApiService.PostApiCalls(`user/${type}`,{...pagination,id:userId},true);
      
      if(members[0] && members[1]["IsSuccessful"]){
          setdata( [...data,...ApiService.members[1]["members"]])
          setpagination({...pagination,offset:pagination.offset+10})
      }
      setLoading(false);
    };
    const getList = async()=>{
      const members = await ApiService.PostApiCalls(`user/${type}`,{...pagination,offset:0,id:userId},true);
      
      if(members[0] && members[1]["IsSuccessful"]){
          setdata( members[1]["members"])
          setpagination({...pagination,offset:pagination.offset+20,total: members[1]["total"]})
      }
          // setLoading(true);

    }
  return (
    <div className="bg-white  col-24">
<InfiniteScroll
        dataLength={data.length}
        next={loadMoreData}
        hasMore={data.length < pagination.total}
        loader={
          <Skeleton
            avatar
            paragraph={{
              rows: 1,
            }}
            active
          />
        }
        endMessage={data.length !== 0 ?<Divider plain>It is all, no more connections</Divider>:<></>}
        scrollableTarget="scrollableDiv"
      >
        <List
       
          dataSource={data}
          locale={{emptyText:<div className=' text-hs-pink py-3'><Empty description={`User has no ${type}.`} /></div>}}
          renderItem={(item) => (
            <List.Item key={item.memberinfo[0].uniqueId}>
              <List.Item.Meta
                avatar={<Avatar size={46} src={item.memberinfo[0].profilePicture?item.memberinfo[0].profilePicture:"https://hs-container.s3.ap-south-1.amazonaws.com/hashapp/campaign-images/images/images/dqdmIww7t3_JCP.png"} />}
                title={<Link to={`/${item.memberinfo[0].userName}`}><ProfileName classnames={""} verfied={item.memberinfo[0]["verfied"]} profileName={item.memberinfo[0].name} /></Link>}
                description={item.memberinfo[0].userName}
              />
              
            </List.Item>
          )}
        />
      </InfiniteScroll>
    </div>
  )
}

export default UserConnections