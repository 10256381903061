import { Button, Dropdown, Menu,Modal,message } from 'antd';
import React from 'react'
import { Check2Circle, Check2Square, ExclamationCircleFill, Flag, HeartFill, Share, SlashCircle, ThreeDots, Trash } from 'react-bootstrap-icons'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Col,Row, ModalBody, ModalFooter } from 'react-bootstrap';
import {ShareSocial} from 'react-share-social' 

import { useState } from 'react';
import ApiService from 'services/ApiService';
import Utils from 'helper/Utils';

const { confirm } = Modal;
const CardDropMenu = ({content}) => {
  const user = Utils.getLocalInfo("userhash")
    const [selected, setselected] = useState(false)
    const [loading, setloading] = useState(false)
    const [index,setIndex] = useState(-1)
    const [completed,Setcompleted] = useState(false)
    const [showReport, setshowReport] = useState(false)
    const list = ["Incorrect information shared with this post","It’s suspicious or spam","It's abusive or harmful","It expresses intentions of self-harm or suicide"]
    const author = content["author"][0]?content["author"][0]:content["authorGroup"][0];
  

    const checkSelect = (action) =>{
      if(action === "block"){
        confirm({
          title: `Block @${author["userName"]}?`,
          icon: null,
          content: `They will not be able to follow you or view your post, and you will not see posts or notifications from @${author["userName"]}.`,
          okText: 'Block',
          okType: 'danger',
          async onOk() {
            
            const state = await ApiService.PostApiCalls("user/action/block",{id:content["profileID"],type:content["profileType"],userName:author["userName"]},true)
            if(state[0] && state[1]["IsSuccessful"]){
              message.success(state[1]["FriendlyMessage"]);
            }
            else{
              message.error(state[1]["FriendlyMessage"]);
            }
           
          },
          onCancel() {},
        });
      }
      else if(action === "delete"){
        confirm({
          title: `Delete Post?`,
          icon: null,
          content: `This can’t be undone and it will be removed from your profile, the feed of any accounts that follow you, and from HashSaga search results.`,
          okText: 'Delete',
          okType: 'danger',
          async onOk() {
            console.log(content);
            const state = await ApiService.PostApiCalls("post/action/delete/"+content["_id"],{},true)
            if(state[0] && state[1]["IsSuccessful"]){
              message.success(state[1]["FriendlyMessage"]);
              document.getElementById(`post-${content["_id"]}`).remove()
            }
            else{
              message.error(state[1]["FriendlyMessage"]);
            }
           
          },
          onCancel() {},
        });
      }
      else  if(action === "share"){
        
        setloading(false);
        setIndex(-2)
        setshowReport(true)
      }
      else  if(action === "report"){
        setloading(false);
        setIndex(-1)
        setshowReport(true)
      }
    }
    const SubmitReport = async() =>{
      setloading(true);
      const state = await ApiService.PostApiCalls("post/action/report",{userId:content["profileID"],postId:content["contentID"],reason:list[index],type:content["profileType"],userName:author["userName"]},true)
      if(state[0] && state[1]["IsSuccessful"]){
        setloading(false);
        setIndex(-1)
        setshowReport(false)
        message.success(state[1]["FriendlyMessage"]);
      }
      else{
        message.error(state[1]["FriendlyMessage"]);
      }
    }
    const select =(i) =>{
      setIndex(i);
      setselected(true)
      
  }
    const menu = (
        <Menu
          
          items={[
            {
              key: '1',
              label: (
                <span className='font-medium py-1'>
                  Share post
                </span>
              ),
              onClick : ()=>checkSelect("share"),
              icon: <Share size={16} />,
            },
            {
              key: '2',
              label: (
                <span className='font-medium py-1' >
                  Block @{author["userName"]}
                </span>
              ),
              onClick : ()=>checkSelect("block"),
              icon: <SlashCircle size={16} />,
              disabled: false,
            },
            {
                key: '3',
                label: (
                  <span className='font-medium py-1'>
                    Report post
                  </span>
                ),
                onClick : ()=>checkSelect("report"),
                icon: <Flag size={16} />,
                disabled: false,
              },
            
          ]}
        />
      );
      const menuUser = (
        <Menu
          
          items={[
            {
              key: '4',
              label: (
                <span className='font-medium py-1 text-red-500'>
                  Delete post
                </span>
              ),
              onClick : ()=>checkSelect("delete"),
              icon: <Trash size={16} className='text-red-500'/>,
              disabled: false,
            },
            {
              key: '1',
              label: (
                <span className='font-medium py-1'>
                  Share post
                </span>
              ),
              onClick : ()=>checkSelect("share"),
              icon: <Share size={16}  />,
            },
            {
              key: '2',
              label: (
                <span className='font-medium py-1' >
                  Block @{author["userName"]}
                </span>
              ),
              onClick : ()=>checkSelect("block"),
              icon: <SlashCircle size={16} />,
              disabled: false,
            },
            {
                key: '3',
                label: (
                  <span className='font-medium py-1'>
                    Report post
                  </span>
                ),
                onClick : ()=>checkSelect("report"),
                icon: <Flag size={16} />,
                disabled: false,
              },
             
            
          ]}
        />
      );
  return (
    <>
    <span className="cursor-pointer w-8 h-12 flex items-center justify-end">
          
          <Dropdown overlay={user["userName"] === author["userName"]?menuUser:menu} trigger={"click"}>
    
    <Button shape="circle" type="text" className='text-center flex items-center justify-center ml-2' icon={<ThreeDots />} />
  </Dropdown>
        </span>
      <Modal
    title={index === -2 ? "Share Post" :"Report Post"}
    visible={showReport}
    footer={null}
    onCancel={()=>setshowReport(false)}
    bodyStyle={{padding: "0px"}}
   
  >
  <ModalBody className="p-2">
  {index !== -2 && <Row className="ml-0 report-poke">
  <Col md={12} className="p-2" ><h5>Help us understand the problem. What is wrong with this post?</h5></Col>
  <Col  md={12} className="p-0 px-2 mb-3"><div className=" first flex items-center  text-base" onClick={(e) =>select(0)}>
  {index === 0 && <span className="mr-2"><Check2Circle size={20} color="#ff0000" className='font-semibold' /></span>}
   <span>Incorrect information shared with this post</span>
</div></Col>
<Col  md={12} className="p-0 px-2 mb-3"><div className=" first flex items-center  text-base" onClick={(e) =>select(1)}>
  {index === 1 && <span className="mr-2"><Check2Circle size={20} color="#ff0000" className='font-semibold' /></span>}
   <span>It’s suspicious or spam</span>
</div></Col>
<Col  md={12} className="p-0 px-2 mb-3"><div className=" first flex items-center  text-base" onClick={(e) =>select(2)}>
  {index === 2 && <span className="mr-2"><Check2Circle size={20} color="#ff0000" className='font-semibold' /></span>}
   <span>It's abusive or harmful </span>
</div></Col>
<Col  md={12} className="p-0 px-2 mb-3"><div className=" first flex items-center  text-base" onClick={(e) =>select(3)}>
  {index === 3 && <span className="mr-2"><Check2Circle size={20} color="#ff0000" className='font-semibold' /></span>}
   <span>It expresses intentions of self-harm or suicide</span>
</div></Col>

  </Row>}
  {
    index === -2 && <p><ShareSocial
    url ={`https://hashsaga.com/${author["userName"]}/posts/${content["contentID"]}`}
    style={{copyContainer: {
     border: 'none',
     background: 'rgb(0,0,0,0.7)',
     fontFamily:"Poppins",
     alignItems:'center'
   },}}
    socialTypes={['facebook','twitter','whatsapp','linkedin','telegram','email']}
    onSocialButtonClicked={ data => setshowReport(false)} 
  /></p>
  }
</ModalBody>
{index !== -2 && <ModalFooter>
  {
      completed ?
      <>
      <span className="float-left alert alert-success flex-1">Thanks for letting us know.</span>
      <Button disabled={!selected} onClick={()=>setshowReport(false)} >Done</Button>
      </>
      :
      <Button disabled={!selected} onClick={(e)=>SubmitReport()}>
      {
          loading ? 
          <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...</>
          :
          "Submit"
      }
  </Button>
  }
  
</ModalFooter>}
  </Modal>
  </>
  )
}

export default CardDropMenu