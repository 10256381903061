import React from 'react'
import {Elements,PaymentElement} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import { Modal } from 'react-bootstrap';
import { Button, InputNumber } from 'antd';
import { useState } from 'react';
import ApiService from 'services/ApiService';
import Utils from 'helper/Utils';
import HashIcon from 'components/HashIcon';
// const stripePromise = loadStripe('pk_test_51LDMFTSCziW5a8WnahZL7oy2kpBCIdxFQVcwC1U0yDU7Ak7J0aclUaVHAlqsMncC5fDl6WyLmFiuq02j5pW9Gwv800V30n2tKD');
const BuyHashCard = ({tokens,callback,backCallback,successCallback,changeText,backTitle="Go Back"}) => {
  const appLocale = localStorage.getItem("appLocale")
  let setDefault = "en";
  if(appLocale){
    setDefault = appLocale
  }
  const stripePromise = loadStripe("pk_live_51LDMFTSCziW5a8WnrdxtE3x0EZ4Ls0AWMSG81PiHJEpekq14XMP4kA0LIlriDJYXy2e1JjubKETbDgfSTcuEnA1Z00SL3BpanT",{locale: setDefault});
 
  const [prceedBuy, setprceedBuy] = useState(true);
  const [buyValue, setbuyValue] = useState(10)
  const [paymentSecret, setpaymentSecret] = useState(null)
  const [processing, setprocessing] = useState(false)

    const options = {
        // passing the client secret obtained from the server
        clientSecret: paymentSecret
      };
      const createPyament = async() =>{
        const data = { "amount":buyValue*100,
        "currency":"INR"}
        const res = await ApiService.PostApiCalls("/payment/create-payment-intent",data,true)
        if(res[0] && res[1]["IsSuccessful"]){
          setpaymentSecret(res[1]["clientSecret"])
          setprceedBuy(false);
        }
        else{
          Utils.showNotification("error", "Oopps", "Something went wrong",3);
        }
    
    }
    const backPay = (val) =>{
      
      setprocessing(false)
      successCallback(val)
    }
    return (
      <>
      {prceedBuy && <><div className='p-3'><span className="font-semibold" >{changeText?"":`You need more ${Math.abs(tokens)} hashs tokens to complete this transaction...`}</span><span className="flex flex-col">
              <span className="font-normal flex" ><HashIcon /> 1 = INR 1</span>
              <span className="py-4">Tokens - <InputNumber min={10} value={buyValue} onChange={e=>setbuyValue(e)} defaultValue={Math.abs(tokens)} /></span>
              </span></div></>}
        {!prceedBuy && <Elements stripe={stripePromise} options={{
        
        clientSecret: paymentSecret
      }} >
        <CheckoutForm tokens={buyValue} callback={backPay} clientSecret={paymentSecret} setprocessingCall={setprocessing} />
      </Elements>}
      
      <Modal.Footer className=" justify-end space-x-4 py-2 px-3 border-top">
      <Button
              disabled={processing}
              onClick={()=>backCallback()}
              className="px-3 brd-4 fw-700 mx-2 rounded-lg border-0 flex items-center"
            >
             {backTitle}
            </Button>
            {
              prceedBuy && <Button
              type="primary"
              onClick={()=>createPyament()}
              className="px-3 brd-4 fw-700 mx-2 rounded-lg border-0 flex items-center"
            >
              Proceed to Buy
            </Button>
            }
        </Modal.Footer></>
  )
}

export default BuyHashCard