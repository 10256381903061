import React from 'react'

export const HashtagIcon = (props) => {
    return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={props.size ? props.size[0] :20}
        height={props.size ? props.size[1] :18}
          viewBox="0 0 20.811 18.919"
          {...props}
        >
          <path
            d="M19.865 4.734h-2.874l.949-3.539a.946.946 0 00-1.828-.49l-1.079 4.029H8.315l.949-3.539A.946.946 0 107.437.701l-1.08 4.033H2.838a.946.946 0 000 1.892h3.011l-1.522 5.672H.946a.946.946 0 000 1.892H3.82l-.949 3.539a.945.945 0 101.826.49l1.081-4.029H12.5l-.949 3.539a.946.946 0 101.827.49l1.08-4.029h3.519a.946.946 0 100-1.892h-3.015l1.522-5.676h3.381a.946.946 0 000-1.892zM13 12.298H6.285l1.522-5.676h6.718zm0 0"
            fill="#1694e3"
          />
        </svg>
      )
}

export const MentionIcon = (props) => {
    return (
        <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ? props.size[0] :24}
        height={props.size ? props.size[1] :24}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M10.628.077a12 12 0 002.3 23.888 12.357 12.357 0 007.444-3.365l.176-.164a.5.5 0 00.025-.707L19.89 19a.5.5 0 00-.707-.025l-.366.341A10 10 0 1112.3 2 10.176 10.176 0 0122 12.268V13a2 2 0 01-2.2 1.99 2.06 2.06 0 01-1.8-2.078V8.5a.5.5 0 00-.5-.5h-1a.5.5 0 00-.451.291 5.5 5.5 0 10.532 6.746 3.984 3.984 0 003.693 1.954A4.1 4.1 0 0024 12.859V12A12.014 12.014 0 0010.628.077zM12 15.5a3.5 3.5 0 113.5-3.5 3.5 3.5 0 01-3.5 3.5z"
        fill="#df3d28"
      />
    </svg>
      )
}

export const QuoteIcon = (props) => {
    return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={props.size ? props.size[0] :20}
        height={props.size ? props.size[1] :17}
         
          viewBox="0 0 20.044 17.316"
          {...props}
        >
          <defs>
            <style>{".prefix__a{fill:#2bb885}"}</style>
          </defs>
          <path
            className="prefix__a"
            d="M20.036 4.778v-.007a4.784 4.784 0 10-3.226 4.511c-1.073 6.158-5.876 10.13-1.424 6.861 4.94-3.624 4.659-11.226 4.65-11.365zM4.771 9.543a4.754 4.754 0 001.545-.261C5.243 15.44.441 19.412 4.892 16.143c4.936-3.624 4.656-11.226 4.65-11.365v-.007a4.771 4.771 0 10-4.771 4.771z"
          />
        </svg>
      )
}

export const ImageIcon = (props) => {
    return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={props.size ? props.size[0] :25}
        height={props.size ? props.size[1] :25}
          viewBox="0 0 25.292 25.292"
          {...props}
        >
          <defs>
            <style>{".prefix__a{fill:#2d42c6}"}</style>
          </defs>
          <path
            className="prefix__a"
            d="M22.921 0H2.371A2.371 2.371 0 000 2.371v20.55a2.371 2.371 0 002.371 2.371h20.55a2.371 2.371 0 002.371-2.371V2.371A2.371 2.371 0 0022.921 0zm.79 22.921a.79.79 0 01-.79.79H2.371a.79.79 0 01-.79-.79V2.371a.79.79 0 01.79-.79h20.55a.79.79 0 01.79.79z"
          />
          <path
            className="prefix__a"
            d="M17.159 13.666a.79.79 0 00-1.114 0l-1.818 1.818-3.389-3.391a.79.79 0 00-1.114 0l-5.533 5.533a.79.79 0 00.547 1.343H20.55a.79.79 0 00.561-1.352z"
          />
          <circle
            className="prefix__a"
            cx={2.5}
            cy={2.5}
            r={2.5}
            transform="translate(12.292 6.292)"
          />
        </svg>
      )
}

export const EditIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15.536}
      height={15.536}
      viewBox="0 0 15.536 15.536"
      {...props}
    >
      <path
        d="M7.768 0a7.768 7.768 0 107.768 7.768A7.768 7.768 0 007.768 0zm3.693 5.59l-.738.738-1.5-1.5-.57.57 1.5 1.5-3.684 3.683-1.5-1.5-.57.57 1.5 1.5-.367.367-.007-.007a.291.291 0 01-.186.133l-1.4.312a.291.291 0 01-.347-.348l.312-1.4a.292.292 0 01.134-.186l-.007-.007 5.928-5.927a.223.223 0 01.315 0l1.187 1.187a.223.223 0 010 .315z"
        fill="#1694e3"
      />
    </svg>
  )
}

export const DeleteIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={12.339}
      height={15.193}
      viewBox="0 0 12.339 15.193"
      {...props}
    >
      <defs>
        <style>{".prefix__a{fill:#ef476f}"}</style>
      </defs>
      <path
        fill="#ef476f"
        d="M8.268 5.501a.356.356 0 00-.356.356v6.725a.356.356 0 00.712 0V5.857a.356.356 0 00-.356-.356zm0 0M4.07 5.501a.356.356 0 00-.356.356v6.725a.356.356 0 00.712 0V5.857a.356.356 0 00-.356-.356zm0 0"
      />
      <path
        fill="#ef476f"
        d="M1.01 4.523v8.766a1.963 1.963 0 00.522 1.354 1.752 1.752 0 001.271.55h6.732a1.752 1.752 0 001.271-.55 1.963 1.963 0 00.522-1.354V4.523a1.359 1.359 0 00-.349-2.673H9.158v-.449a1.4 1.4 0 00-1.409-1.4H4.59a1.4 1.4 0 00-1.409 1.4v.445H1.359a1.359 1.359 0 00-.349 2.673zm8.525 9.958H2.803a1.127 1.127 0 01-1.082-1.192V4.554h8.895v8.735a1.127 1.127 0 01-1.081 1.192zM3.892 1.401a.685.685 0 01.7-.694h3.157a.685.685 0 01.7.694v.445H3.892zm-2.533 1.16h9.621a.64.64 0 110 1.281H1.359a.64.64 0 110-1.281zm0 0"
      />
      <path
        fill="#ef476f"
        d="M6.169 5.501a.356.356 0 00-.356.356v6.725a.356.356 0 00.712 0V5.857a.356.356 0 00-.356-.356zm0 0"
      />
    </svg>
  )
}