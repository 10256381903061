import { Col } from "antd";
import ProfileBanner from "components/ProfileCard/ProfileBanner";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ApiService from "services/ApiService";
import ProfileForm from "./ProfileForm";

const UserProfile = (props) => {
  const [loading, setloading] = useState(true);
  const [profile, setprofile] = useState(null);
  const [error, seterror] = useState(null);
  useEffect(() => {
    getProfile();

    return () => {};
  }, []);
  const getProfile = async () => {
    seterror(null);
    const profileId = props["match"] && props["match"]["params"]["profile"]
      ? props["match"]["params"]["profile"]
      : "me";
    const res = await ApiService.PostApiCalls(
      `users/${profileId}`,
      { hi: "his" },
      true
    );
    
    if (res[0]) {
      if (res[1]["IsSuccessful"]) {
        setprofile(res[1]["profile"]);
        setloading(false);
        
      } else {
        seterror(res[1]["ErrorMessage"]);
        setloading(false);
      }
    } else {
      seterror("Something went wrong try again!");
      setloading(false);
    }
    
  };
  return (
    <>
   {props.hideHeader?'': <Col span={24} className="py-0 px-0 mb-2">
      <div className="bg-white p-3 px-3 flex space-x-3 items-center">
        <span className="font-bold">EDIT PROFILE</span>
      </div>
    </Col>}
    {!loading && <Col span={24} className="px-0 h-100 bg-white p-3">
    <ProfileBanner info={profile} isEdit={true} />
    <ProfileForm info={profile}/>
    </Col>
    }
    </>
  );
};

export default UserProfile;
