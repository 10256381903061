import { Col, Divider, List, Switch,message } from "antd";
import { AuthContext } from "app/AuthProvider";
import React from "react";
import { useState ,useContext} from "react";

import ApiService from "services/ApiService";


const UserNotifications = ({user,params}) => {
  const {setAuthUser,authUser} = useContext(AuthContext)

  const [notificationPreferences, setnotificationPreferences] = useState(user['notificationPreferences']?user['notificationPreferences']:{'push':false,'email':false,'toast':false,'sms':false})
  const listData = [
     {slug: 'push',lable:"Push notifications",desc:"Get push notifications to find out what’s going on when you’re not on HashSaga. You can turn them off anytime."},
     {slug: 'email',lable:"Email notifications",desc:'Get emails to find out what’s going on when you’re not on HashSaga. You can turn them off anytime.'},
     {slug: 'toast',lable:"Toast notifications",desc:"Toast notifications for activity on your post, when you are active on HashSaga."},
     {slug: 'sms',lable:"SMS notifications",desc:'Important update for your account Yayment, Subscriptions etc.'},
    
    ]
  
  const active = "as";
  
  const updateNoti = async(e,slug) =>{
    setnotificationPreferences({...notificationPreferences,[slug]:e})
    const data = {notificationPreferences:{...notificationPreferences,[slug]:e}}
    
    const saved  = await ApiService.PostApiCalls(
      "creator/setup/update",
      {...data},
      true
    );
    setAuthUser({...authUser,notificationPreferences:{...notificationPreferences,[slug]:e}})
    message.success({
      content: 'Notification preference updated.',
      duration:2,
      className: 'custommsg-class',
      style: {
        right:10
      },
    });
  }
  
  return (
    <>
    {!params.type && <><Col span={24} className="py-0 px-0 mb-2">
      <div className="bg-white p-3 px-3 flex space-x-3 items-center">
        
        <span className="font-bold">NOTIFICATIONS</span>
      </div>
    </Col>
    <Col span={24} className="h-100 bg-white py-0 px-0 flex flex-col">
    <List
      header={<div className='font-semibold px-3'>
          Preferences
          </div>}
      className="bg-white pb-3"
      dataSource={listData}
      renderItem={item => (
        <List.Item className='cursor-pointer p-2 pr-4'>
             
              <span className={item["slug"].toLowerCase() === active ?'flex justify-between items-center w-100 p-2 bg-blue-50 text-blue-400':'flex justify-between items-center rounded-sm w-100 p-2 px-3 hover:bg-blue-50 hover:text-blue-400'}>
              <List.Item.Meta
         
          title={item.lable}
          description={item.desc}
          
        />
              
              <Switch className="ml-4" checked={notificationPreferences[item.slug]} onChange={(e)=>updateNoti(e,item.slug)} />
              </span>
        </List.Item>
     )}
    />
    </Col></>}
   
    </>
  );
};

export default UserNotifications;

