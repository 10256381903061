import { Col, Row } from 'antd'
import React from 'react'
import { Container } from 'react-bootstrap'
import { GuardSpinner, SwapSpinner } from 'react-spinners-kit'

const DataLoader = () => {
  return (
    <Container className="h-full flex items-center justify-center">
              <Row className="m-0 p-0">      
          <Col
            className="main-content p-0 fz-18 fw-bold mx-auto d-flex align-items-center justify-content-center loader-height"
            lg={12}      
            md={12}
            sm={12}        
            tag="home-1"         
          >
        <SwapSpinner
                    size={30}
                    frontColor="#0079ff"
                    backColor="#f6327d"
                    loading={true}
                />
          </Col>
          </Row>
            </Container>
  )
}

export default DataLoader