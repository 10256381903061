import { Button,Tag as AntTag, Avatar, Divider, message,Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { Instagram, Tag ,Facebook,Twitter,Pinterest,Youtube, Plus} from 'react-bootstrap-icons'
import ReadMoreComponent from './ReadMoreComponent'
import ApiService from 'services/ApiService'

const ContentBody = ({post,callback,hidetools=false}) => {
  const [postState, setpostState] = useState(post.status);
  const [tagedProducts, settagedProducts] = useState(post.taggedProducts?post.taggedProducts:null)
  
  useEffect(() => {
    
    
    settagedProducts(post.taggedProducts?post.taggedProducts:null)
    return () => {
      
    }
  }, [post.taggedProducts])
  
  const tagProducts =(id)=>{
    callback(id,post,post.taggedProducts?post.taggedProducts:[])
  }
  const renderIcon = (source) => {
    if(source.toLowerCase() === "instagram"){
      return (<Instagram color={"#fa7e1e"} size={24} />)
    }
    else if(source.toLowerCase() === "facebook"){
      return (<Facebook color={"#3b5999"} size={24} />)
    }
    else if(source.toLowerCase() === "pinterest"){
      return (<Pinterest color={"#E60023"} size={24}/>)
    }
    else if(source.toLowerCase() === "twitter"){
      return (<Twitter color={"#55acee"} size={24}/>)
    }
    else if(source.toLowerCase() === "youtube"){
      return (<Youtube color={"#CD201F"} size={24}/>)
    }
    else if(source.toLowerCase() === "blume"){
      return (<Avatar src={"https://hashsaga.com/static/media/hash-logo.098fff50.png"} size={24}/>)
    }
  }
  const updatePostPrivacy = async(state) =>{
    
    const posted = await ApiService.PostApiCalls("/commerce/posts/update",{id:post.contentId,state:state})
    if (posted[0] && !posted[1]["IsSuccessful"]) {
    }
    else{
      message.success(`Post state is updated to ${state === "active"?"Public":"Private"}`)
    }
    setpostState(state)
  }
  return (
   <> <div className='flex flex-col items-center justify-center py-2'>
      {!hidetools &&  <>
        <div className='h-full flex space-x-2 flex-row flex-wrap'>
          {
            post.tags && post.tags.map((tg,i) => <AntTag key={i} className='mb-2'>{tg}</AntTag>)
          }
         </div>
        
        
        {!tagedProducts && <div><Button onClick={()=>tagProducts(post.contentId)} className="flex items-center justify-around rounded space-x-1 w-75"   icon={<Tag size={18} />} >Tag Products</Button></div>}
        {
          tagedProducts && 
          <div className='flex items-center justify-between w-full'><div className='flex flex-wrap flex-grow space-x-2'> {tagedProducts.map (p => <Avatar src={p.image} />)}
          
          </div>
          <Button type="" onClick={()=>tagProducts(post.contentId)} className='flex items-center justify-center' shape="circle" icon={<Plus className='' size={20} />} />
          </div>
        }
        
        </> }

        <div className='h-full flex mt-3 w-100 space-x-2 flex-row items-center px-1'>
          <div><Avatar className='flex items-center' src={post.picture} size={24} /></div>
          <div className='flex flex-col w-75 space-y-1'>
            <a href={post.link} target='_blank' className='text-hs-dark'><span className='truncate whitespace-nowrap text-xs'>{post.name}</span></a>
            <span className='truncate whitespace-nowrap text-muted text-xs'>{post.username}</span>
          </div>
          {renderIcon(post.netname)}
        </div>
        <div className='h-full flex mt-2 w-100 space-x-2 flex-row items-center px-1'>
          <ReadMoreComponent text={post.content}/>
        </div>
       {!hidetools && <Divider className='my-2' />}
        {!hidetools && <div className='flex mt-2 w-100 flex-row items-center justify-between px-3'>
          <Button onClick={()=>updatePostPrivacy("active")}  disabled={postState === "active"?true:false} className="px-4 rounded-lg  bg-hs-pink text-white hover:text-white hover:border-white focus:border-white focus:text-white">Public</Button>
          <Button onClick={()=>updatePostPrivacy("private")} disabled={postState === "private"?true:false} className="rounded-lg px-4 hover:text-hs-pink hover:border-hs-pink focus:border-hs-pink focus:text-hs-pink">Private</Button>
        </div>}
    </div>
   
    </>
  )
}

export default ContentBody