import React,{Component} from 'react'
import {Modal } from 'react-bootstrap'
import { Upload , Button,message } from 'antd';
import {Plus,Upload as UploadIcon} from "react-bootstrap-icons"
import {startUpload,UploadSingle} from "services/FileUpload"
import _ from "lodash"

function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
}
function beforeUploadStart(file){
  console.log(file.type);
  return file.type === "image/jpg" ? true:Upload.LIST_IGNORE;
}
class ImageUploadView extends Component {
            constructor(props) {
              super(props)
            
              this.state = {
                show:false,
                previewVisible: false,
                save: false,
                previewImage: '',
                previewTitle: '',
                fileList:this.props.list,
                imageList:this.props.list,
                uploadedCount:0,
                isUploading:false,
                progressCount: 0,
                imgPrifix:this.props.campaign.cid
              }
            }
            
            componentDidUpdate(prevProps){
              if(this.state.fileList.length !== this.props.list.length){
                // this.setState({
                //   fileList:this.props.list
                // })  
              }
              
            }
             handleClose = () => this.setState({show:false});
             handleShow = () => this.setState({show:true});
             handlePreview = async file => {
                if (!file.url && !file.preview) {
                  file.preview = await getBase64(file.originFileObj);
                }
            
                this.setState({
                  previewImage: file.url || file.preview,
                  previewVisible: true,
                  previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
                });
              };
             updateProgressBar = (val,id,uid) =>{
                const {fileList} = this.state;
                let newList = [];
                for(let i=0; i<fileList.length; i++){
                    let tmp = fileList[i];
                    if(tmp.uid === uid){
                        tmp["percent"] = val;
                    }
                    newList.push(tmp);
                }
                this.setState({fileList: newList})
            }
             UploadFinish = (value,name,type,cid,uid) =>{ 
               const _self = this;
                const {uploadedCount} = this.state;
                this.setState({uploadedCount:uploadedCount+1})
                let imagepath = "https://hs-container.s3.ap-south-1.amazonaws.com/" + name
                const {fileList} = this.state;
                let newList = [];
                for(let i=0; i<fileList.length; i++){
                    let tmp = fileList[i];
                    if(tmp.uid === uid){
                        tmp["status"] = "done";
                        tmp["url"] = imagepath;
                    }
                    newList.push(tmp);
                }
                
                this.setState({fileList: newList},()=>{
                  
                  _self.VerifyAll()
                })
            }
            VerifyAll = () =>{
                const {progressCount,uploadedCount} = this.state;
                if(progressCount === uploadedCount){
                    this.setState({uploadedCount:0,progressCount:0,save:false})
                }
            }
             UploadFail = () =>{

            }
             customUploadRequest = (e) =>{
                const imgesTypes = ["image/gif","image/jpeg", "image/png","image/bmp"]
               
                if(imgesTypes.indexOf(e.file.type) === -1){
                  const {fileList} = this.state;
             
                  const newlist = _.filter(fileList,function(f){
                    return f["uid"] !== e["uid"]
                  })
                  
                  this.setState({fileList:newlist});
                  return false;
                }
                const UploadState = startUpload(e.file.size);
                const {progressCount,imgPrifix} = this.state;
                if(UploadState === "single")    
                {        
                  this.setState({
                    isUploading:true,
                    save:true,
                    progressCount:progressCount+1,
                  })
                  UploadSingle(e,this.updateProgressBar,this.UploadFinish,this.UploadFail,progressCount,"","hashapp/videopost/"+imgPrifix+"/images")
                  .then(res => {          
                   
                  })
                  .catch(error => {            
                  });
                 
                }
            }
            handleCancel = () => this.setState({ previewVisible: false });

            handleChange = ({ fileList }) => {
              const oldList = this.state.fileList;
              
              const imgesTypes = ["image/gif","image/jpeg", "image/png","image/bmp"];
              const typeslist = _.map(fileList,"type")
              let filteredList = [];
              let accepted = true;
              for(let i =0;i < typeslist.length; i++){
                if(imgesTypes.indexOf(typeslist[i]) === -1){
                  this.setState({fileList: oldList},()=>{
                    return false;
                  })
                  accepted = false;
                }
                else{
                  filteredList.push(fileList[i])
                  accepted = true;
                }
              }
                
              this.setState({ fileList:filteredList})
              
            };

            saveImages = () =>{
                const {fileList} = this.state;
                this.props.callbackImages(fileList);
                this.setState({show:false,imageList:fileList});
            }
            updateCurrentImage = (i) =>{
                this.props.updateCurrentImage(i)
            }
            removeImages  = (e) =>{
              const {fileList} = this.state;
             
              const newlist = _.filter(fileList,function(f){
                return f["uid"] !== e["uid"]
              })
              
              this.setState({fileList:newlist});
             
             return false;
            }
            beforeUploadStarts = (e) =>{
             

              return Upload.LIST_IGNORE;
            }

  render() {
      const {previewVisible,save,imageList, previewImage, fileList, previewTitle,show} = this.state;
     const props = {
    beforeUpload: file => {
      const imgesTypes = ["image/gif","image/jpeg", "image/png","image/bmp"]
      
        let accepted = true;
        if(imgesTypes.indexOf(file.type) === -1){
          message.error(`You can only upload images`);
          accepted = false;
          return false;
        }
      
      return accepted ? true : Upload.LIST_IGNORE;
    },
    
  };
      return (
        <>
        <div className="d-flex align-items-start list px-2">
        <div onClick={this.handleShow} className="item d-flex flex-column align-items-center justify-content-center cursor-pointer">
            <Plus size={24} color={"#1694E3"} />
            <span>Add Media</span>
        </div>
        <div className="d-flex flex-row image-list">
        {imageList.map((img,key)=>(
            <div key={key} className="image-item" onClick={()=>this.updateCurrentImage(key)}>
            <span  className="clip-no">Clip - {key+1}</span>
            <img key={key} src={img["url"]} alt="" className="item" /></div>
        ))}
            
        </div>
        </div>
        <Modal show={show} onHide={this.handleClose} centered={true} contentClassName="image-modal">
        <Modal.Header closeButton className="p-2 px-3">
            <span className="text-center w-100">Only 5 images are allowed</span>
        </Modal.Header>
        <Modal.Body>

        <Upload
          {...props}
          multiple={true}
          listType="picture-card"
          fileList={fileList}
          
          onPreview={this.handlePreview}
          onChange={this.handleChange}
          customRequest={this.customUploadRequest}
          onRemove={this.removeImages}
          accept="image/*"
          maxCount={5}
          
        >
         {fileList.length < 5 && <div>
        <UploadIcon />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>}
        </Upload>

        </Modal.Body>
        <Modal.Footer  className="p-1 px-3">          
          <Button disabled={save} variant="primary" primary onClick={this.saveImages}>
            Save Images
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
          show={previewVisible}
          title={previewTitle}
          onHide={this.handleCancel} centered={true} contentClassName="image-modal"
        ><Modal.Header closeButton className="p-1 px-3"></Modal.Header>
        <Modal.Body>
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
          </Modal.Body>
        </Modal>
        </>
    )
  }
}

export default ImageUploadView



