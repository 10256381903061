import React from 'react'
import { Container } from 'react-bootstrap'
import { Button, Col, Layout,Row } from 'antd'

const AboutUs = () => {
    const appLocale = localStorage.getItem("appLocale")
    let setDefault = "en";
    if(appLocale){
      setDefault = appLocale
    }
  const textAbout = {
    "en":`<p><strong>About HashSaga (based on Blockchain Technology) &ndash;</strong></p>
  <p>Vision - We aim to encapsulate spirituality, humanity, and technology together to bring prosperity, equality, and sustainability to society, the environment, and every life form in the universe</p>
  <p></p>
  <p>HashSaga is a community-based platform for Creators, Brands, and Followers. It aims to bring these three groups together so that they can benefit each other mutually. Followers will get to enjoy exclusive offers &amp; discounts as well as a lot of other activities, while Creators will be able to grow their careers and Brand will be able to sell their products.&nbsp;</p>
  <p>Also, the creators or brands can take ownership of his/her content&rsquo;s minting and they can earn money through the sale or rent feature of that content.</p>
  <p></p>
  <p>And HashSaga content is linked with 17 UN Social Sustainability Causes, so it will work for our social well-being also.</p>
  <p></p>
  <p>Soon, we are going to launch our own crypto coin and Metaverse called Gamucha.</p>
  <p>Gamucha concept (Social Value Chain) &ndash; A person, corporates, institutions, etc can walk in our Gamucha metaverse and they can donate to any social causes. That&rsquo;s why we have integrated Donate Crypto and Accept Crypto features (our business model will be to earn a basic fee on each transaction and our own Coin as a medium of donation)</p>
  <p><br></p>
  
  <ol>
      <li>
          <p>Data privacy. A user can decide whether his activities will be private or not. A user can select Privacy mode or Earning mode. USP is that if the user selects earning mode, then he&rsquo;ll also get some percentage as per his/her activities.</p>
      </li>
      <li>
          <p>For the creators &ndash; We have an AI + blockchain-based tool to create the content, create the campaign, schedule the content, distribution, and monetization.</p>
      </li>
      <li>
          <p>A user can easily earn #coins on the basis of his/her activities and time spent on site.</p>
      </li>
      <li>
          <p>A user can mint the content and claim ownership of that content. On the basis of that ownership, that user earns money through rent or sale.</p>
      </li>
      <li>
          <p>We&rsquo;ll also positing as a &lsquo;Google of NFTs&rsquo;. We can easily create, buy, sell, auction, and analytics of NFTs.</p>
      </li>
      <li>
          <p>We are making partners to provide more services and make more money. Like now we are going to start NFTs services, NFT ticketing services, NFT Arts, NGOs, and social e-commerce platforms.</p>
      </li>
      <li>
          <p>Everything connected with 17 UN Sustainability causes.</p>
      </li>
      <li>
          <p>To all the activities and services, #coin will be used, and just imagine the value of #coin, once all the activities started.</p>
      </li>
      <li>
          <p>Earning mode &amp; Privacy mode &ndash; Take ownership of your activities.</p>
      </li>
      <li>
          <p>Earn coin on the time investment.</p>
      </li>
  </ol>
  <p><br></p>
  
  <p><strong>AI-based Creator tool&rsquo;s Features &ndash; </strong></p>
  <p>Social media scheduler tool that will make your life easier and provide you with everything you need to produce quick, quality content.</p>
  <p>Single Post - In one location, manage social media updates, if you want to post anything immediately or schedule a certain post on social media.</p>
  <p>Flexible Scheduling &ndash; You can schedule the post very easily for the next few months.</p>
  <p>Personalized&nbsp;Templates - You may customize your themes and social media campaign visuals with our social media scheduling software.</p>
  <p>A video maker - Utilizing our video maker, you can quickly and easily produce powerful video content from your post or page.&nbsp;</p>
  <p>Photo Posting - With the help of this social media software, you can control your social brand presence and modify your post graphics to make a statement.</p>
  <p>Multilingual support - Break down language barriers, this tool provides multilingual support.</p>
  <p>Posting using RSS Feed - As soon as you submit a blog entry, the tool immediately finds it using an RSS feed, at which point the tool quickly constructs a campaign for you to review.</p>
  <p><br></p>
  <p><strong>Services &ndash;</strong></p>
  <ol>
      <li>
          <p>AI Tool for the creators&rsquo; communities</p>
      </li>
      <li>
          <p>NFTs (buy, create, sell &amp; auction)</p>
      </li>
      <li>
          <p>Content Minting (claim ownership of Hash)</p>
      </li>
      <li>
          <p>Metaverse (Gamucha)</p>
      </li>
      <li>
          <p>NFT Ticketing</p>
      </li>
      <li>
          <p>Support Social Causes (17 UN Social Sustainability)</p>
      </li>
      <li>
          <p>Social Shop (UGC)</p>
      </li>
  </ol>
  <p><br></p>`,
"ja":`<p><strong>HashSaga について (ブロックチェーン技術に基づく) &ndash;</strong></p>
<p>ビジョン - 私たちは、社会、環境、この地球上のあらゆる生命体に繁栄、平等、持続可能性をもたらすために、精神性、人間性、テクノロジーを一体化することを目指しています</p>
<p>HashSaga は、クリエイター、ブランド、フォロワーのためのコミュニティ ベースのプラットフォームです。</p>
<p>この 3 つのグループを統合して、相互に利益をもたらすことを目指しています。</p>
<p>フォロワーは限定オファーや割引、その他多くのアクティビティを楽しみながら収益を得るむことができ、クリエイターはキャリアを伸ばしながら、ブランドは製品を販売することで収益を得ることができます。</p>
<p>また、クリエイターまたはブランドは、自分のコンテンツの所有権を取得し、そのコンテンツの販売またはレンタル機能を通じて収益を得ることができます.</p>
<p>また、HashSaga のコンテンツは、国連の 17 の社会的持続可能性の大義（SDGｓ）とリンクしているため、私たちの社会福祉の向上にも貢献します。</p>
<p>まもなく、独自の仮想通貨とガムチャと呼ばれるメタバースをローンチする予定です。</p>
<p>ガムチャの概念 (ソーシャル バリュー チェーン) &ndash; 個人、企業、機関などは、ガムチャのメタバースを歩き、あらゆる社会的目的に寄付することができます。そのため、私たちは Donate Crypto（寄付する）&nbsp;と Accept Crypto（寄付を受け取る）&nbsp;機能を統合しました。&nbsp;(私たちのビジネス モデルは、各トランザクションで基本料金を獲得し、寄付を媒体として独自のコインを獲得することです)。</p>
<p><br></p><ol>
<li>ユーザーはデータのプライバシー、アクティビティを非公開にするかどうかを決定できます。ユーザーは、プライバシー モードまたは収益モードを選択できます。、ユーザーが獲得モードを選択した場合、ユーザーのアクティビティに応じて一定のパーセンテージを獲得できます。</li>
<li>クリエイター向けには&nbsp;コンテンツの作成、キャンペーンの作成、コンテンツのスケジュール、配信、および収益化のための AI + ブロックチェーン ベースのツールがあります。</li>
<li>ユーザーはサイトでの活動や滞在時間に基づいて #coin を簡単に獲得できます。</li>
<li>ユーザーはコンテンツを作成し、そのコンテンツの所有権を主張でき、その所有権に基づいて、レンタルまたは販売を通じて収益を得ることができます。</li>
<li>我々はHashSagaを「NFT の Google」として位置付けでいます。&nbsp;NFT の作成、購入、販売、オークション、分析を簡単に行うことができます。</li>
<li>私たちは、より多くのサービスを提供し、皆様がより多くの収益を得るためにパートナーを構築します。NFTサービス、NFTチケット サービス、NFTアート、NGO、ソーシャル e コマース プラットフォームを開始します。</li>
<li>国連の持続可能性に関する 17 の大義（ＳＤＧｓ）に関連するすべてのものを取り扱います。</li>
<li>すべての活動とサービスには #coin が使用されます。すべての活動が開始された後の、#coin の価値を想像してみてください。</li>
<li>収益モード &amp; プライバシー モードが選択できます。</li>
<li>ユーザーはこのサイトで時間を費やすことでコインを獲得できます。</li>
</ol>
<p><br></p><p><strong>AIベースのクリエーターツールの特徴 &ndash;</strong></p>
<p></p>
<p><strong>ソーシャルメディアスケジューラー</strong></p>
<p>質の高いコンテンツを迅速に作成するために必要なすべてを提供するソーシャル メディア スケジューラ ツールです。</p>
<p></p>
<p><strong>1ヵ所で複数のSNS投稿を管理</strong></p>
<p>ＳＮＳで何かをすぐに投稿したり、特定の投稿をスケジューリングしたい場合など1ヵ所で管理できます。、、</p>
<p></p>
<p><strong>柔軟なスケジュール管理</strong></p>
<p>数か月先まで、投稿を簡単にスケジュール管理できます。</p>
<p></p>
<p><strong>パーソナライズされたテンプレート</strong></p>
<p>&nbsp;ソーシャル メディア スケジューリング ソフトウェアを使用し、、テーマやソーシャル メディア キャンペーンのビジュアルをカスタマイズできます。</p>
<p>&nbsp;</p>
<p><strong>ビデオ メーカー&nbsp;</strong></p>
<p>ビデオ メーカーを使用し、投稿やページから強力なビデオ コンテンツをすばやく簡単に作成できます。</p>
<p>&nbsp;</p>
<p><strong>写真の投稿</strong></p>
<p>ソーシャル メディア ソフトウェアにより、投稿者のソーシャル ブランドのプレゼンスを管理し、投稿のグラフィックを変更して知らせることができます。</p>
<p>&nbsp;</p>
<p><strong>多言語サポート </strong></p>
<p>&nbsp;言語の壁を取り払う為の多言語サポートを提供しています。</p>
<p>&nbsp;</p>
<p><strong>RSS フィードを使用した投稿 </strong></p>
<p>ブログ に投稿すると即座に RSS フィードを使用してすぐにそれを見つけ、その時点で、投稿者が見るべきキャンペーンを作成します。</p><p>&nbsp;</p><p><strong>サービス &ndash;</strong></p>
<ol>
    <li>クリエイターのコミュニティ向けの全てのAIツール</li>
    <li>NFT（購入、作成、販売、オークション）</li>
    <li>コンテンツ作成 (ハッシュの所有権を主張)</li>
    <li>メタバース（ガムチャ）</li>
    <li>NFTチケット</li>
    <li>社会的大義の支援 ( 国連の17の社会的持続性SDGｓ)</li>
    <li>ソーシャルショップ（UGC）</li>
</ol>`};
  return (
    <Container fluid className='p-7 w-100 mx-0 '>
    <Row gutter={16} className="mx-0 p-3 about-texts">
    <div dangerouslySetInnerHTML={{__html: textAbout[setDefault]}}></div>
    </Row></Container>
  )
}

export default AboutUs