import { Checkbox, InputNumber, Select } from "antd";
import HashIcon from "components/HashIcon";
import { HASH_DEFAULT_CHARGE, HASH_DEFAULT_GASFEE } from "constants/hashkeys.constant";
import Utils from "helper/Utils";
import React, { useEffect, useState } from "react";
import { Coin } from "react-bootstrap-icons";
const { Option } = Select;
const MineHashList = ({ id, k, checkedHash, checkedAll,callback }) => {
    const [tagAdded, settagAdded] = useState(k)
    const [code, setcode] = useState("721")
    const [copies, setcopies] = useState(null)
    const [addFraction, setaddFraction] = useState(0)
    useEffect(() => {
        settagAdded(k)
        
        setcode(k["code"])
        setcopies(k["copies"])
      return () => {
        
      }
    }, [k])
    const updateCopies =(e) =>{
        setcode(e)
        setcopies(e === "721"?null:10)
        settagAdded({...tagAdded,code:e,copies:e === "721"?null:10})
        callback(id,{...tagAdded,code:e,copies:e === "721"?null:10})
    }
    const updateCopiesCount =(e)=>{
      if(e === ""){
        return false;
      }
      setcopies(e)
      settagAdded({...tagAdded,copies:e})
      callback(id,{...tagAdded,copies:e})
    }
    const updateFractionCount =(e)=>{
      if(e === ""){
        return false;
      }
      setaddFraction(e)
      settagAdded({...tagAdded,fractions:e})
      callback(id,{...tagAdded,fractions:e})
    }
  return (
    <div className="flex flex-col p-2 mb-2 px-4 border rounded-md text-hs-dark cursor-pointer">
      <div className="flex flex-row w-100">
        <span>
          <Checkbox
            key={id}
            checked={
              checkedHash.includes(Utils.getHashId(tagAdded["value"], true, true))
                ? true
                : false
            }
            onChange={(e) =>
              checkedAll(e.target.checked, Utils.getHashId(tagAdded["value"], true, true), (HASH_DEFAULT_GASFEE+HASH_DEFAULT_CHARGE))
            }
          >{`#${tagAdded["value"].replace(/ /g, "")}`}</Checkbox>
        </span>
        <span className="flex-grow ml-2"></span>
        <span className="flex flex-row items-center">
          <HashIcon className="text-yellow-600" />{" "}
          <span className="flex-grow ml-2">{HASH_DEFAULT_CHARGE}</span>
        </span>
       
      </div>
      <div className="p-2 mb-0 space-x-2 mt-1">
        <Select value={code} style={{ width: 120 }} size={"small"} onChange={updateCopies} >
          <Option value="721">721</Option>
          <Option value="1155">1155</Option>
        </Select>
        {
            copies && copies !== "" && <InputNumber value={copies} onChange={updateCopiesCount} size="small" min={10} max={50} />
        }
        <div className="py-2">Fractions: <InputNumber value={addFraction} onChange={updateFractionCount} size="small" min={0} max={100} /></div>
      </div>
      <span className="text-xs flex py-2 justify-end">Gas Fees - <HashIcon  />{" "}{HASH_DEFAULT_GASFEE}</span>
    </div>
  );
};

export default MineHashList;
