
import { Col, Row, Statistic } from 'antd'
import DataLoader from 'container/common/DataLoader'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { BadgeAd, Bookmark, CameraVideo, ChatRight, HandThumbsUp, Linkedin, MusicNoteList, Share } from 'react-bootstrap-icons'
import ApiService from 'services/ApiService'

const AccountWallet = ({user}) => {
  const [loading, setloading] = useState(true);
  const [walletInfo, setwalletInfo] = useState(null)
  
  useEffect(() => {
    
    getWalletBalance();
    return () => {
      
    }
  }, [])
  
  const getWalletBalance = async()=>{
    const points = await ApiService.PostApiCalls("user/wallet",{},true);
    if(points[0] && points[1]["IsSuccessful"]){
      setwalletInfo(points[1]["data"])
    }
    setloading(false)
  }
  if(loading){
    return(
      <DataLoader />
    )
  }
  const WithdrawalLimit = () => {
    // walletInfo["hashPoints"]?walletInfo["hashPoints"]["balance"]:0
    if(walletInfo["hashPoints"] && walletInfo["hashPoints"]["balance"]){
      return parseInt(walletInfo["hashPoints"]["balance"] *0.50)
    }
    else{
      return parseInt(walletInfo["hashTokens"] *0.50)

    }
  }
  return (
    <Col span={24} className=" h-full pt-0 px-3 pb-3">
    <Row gutter={16} className="px-4">
    <Col span={24} className="mt-0 h-full mb-3 text-xs text-hs-light"></Col>
    {/* <Col span={24} className="mt-0 h-full mb-3 text-xs text-hs-light">1000 Points = Approx INR 1</Col> */}
    <Col span={8}>
      <Statistic title="Total HASHS Coins"  value={walletInfo["hashPoints"]?walletInfo["hashPoints"]["total"]:walletInfo["hashTokens"]}   />
    </Col>
    <Col span={8}>
      <Statistic title="Balance HASHS Coins" value={walletInfo["hashPoints"]?walletInfo["hashPoints"]["balance"]:walletInfo["hashTokens"]}  />
    </Col>
    
    <Col span={24} className="py-3">
      
    </Col>
    <Col span={8}>
      <Statistic title="HASHS Coins earn through activities" value={walletInfo["hashPoints"]?walletInfo["hashPoints"]["made"]:0}   />
    </Col>
    <Col span={16}>
      <Statistic title="HASHS Coins earn through the own Post Engagement"  value={walletInfo["hashPoints"]?walletInfo["hashPoints"]["earned"]:0}  />
    </Col>
{/*    
    <Col span={24} className="py-3">
      
    </Col> */}
    {/* <Col span={8}>
      <Statistic title="Balance Points" value={(user["hashTokens"]-33) < 0 ? 0:user["hashTokens"]-33}  />
    </Col>
    <Col span={8}>
      <Statistic title="Points earn through activities" value={(user["hashTokens"]-43) < 0 ? 0:user["hashTokens"]-43}  />
    </Col> */}
    <Col span={8}></Col>
    <Col span={24} className="py-3">
      
    </Col>
    <Col span={8}>
      <Statistic title="Airdrop HASHS Coins" value={walletInfo["hashPoints"]?walletInfo["hashPoints"]["airdrop"]:0}   />
    </Col>
    <Col span={8}>
      <Statistic title="Withdrawal Limit" value={WithdrawalLimit()}   />
    </Col>
    {/* <Col span={8}>
      <Statistic title="Video Views" value={65} prefix={<CameraVideo size={20}/>}  />
    </Col> */}
  </Row>
    </Col>
  )
}

export default AccountWallet