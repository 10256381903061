import { Avatar, Button, Table } from 'antd'

import React,{useState} from 'react'
import { Plus, Tag } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'

const ProductsTable = ({products,openUploadBox}) => {
    
    const [tableParams, setTableParams] = useState({
        pagination: {
          current: 1,
          pageSize: 10,
        },
      });
      const columns = [
      
        {
          title: 'Title',
          dataIndex: 'title',
          key: 'title',
          render: (_, record)  => <div className='flex space-x-2 items-center'>
        <div className='mr-1'><Avatar src={record.image} size={90} /></div>
        <div className='flex flex-col space-y-1 text-sm'>
          <span className='font-semibold'>{record.title}</span>
          

        </div>

      </div>,
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (_, record)  => <div className='flex  items-center'>
        <div className='mr-1 font-semibold'>{(record.money_format).replace("{{amount}}","")} </div>
        <span className='font-semibold'>{record.price}</span>

      </div>,
          },
          {
            title: 'Discount',
            dataIndex: 'discount',
            key: 'discount',
            render: (_, record)  => <div className='flex  items-center'>
        <div className='mr-1 font-normal'>{(record.money_format).replace("{{amount}}","")} </div>
        <span className='font-normal'>{record.discount}</span>

      </div>,
          },
          {
            title: 'Product Link',
            dataIndex: 'productLink',
            key: 'productLink',
            render: (_, record)  => <a href={record.productLink} target='_blank'><span>{record.productLink}</span></a>
          },
          {
            title: 'SKU',
            dataIndex: 'sku',
            key: 'sku',
          },
          {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
          },
          {
            title: 'Tags',
            dataIndex: 'tags',
            key: 'tags',
          },
        
      ];
      const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
          pagination,
          filters,
          ...sorter,
        });
      };
  return (
    <>
    <div
        style={{
          marginBottom: 16,
        }}
        className='flex justify-between items-center space-x-3'
      >
         <span className='flex-grow'>Feed List</span>
        <Link to="./content-gallery"><Button className="flex items-center justify-around rounded space-x-1" type='primary'  icon={<Tag size={24} />} >Tag Products</Button></Link>
        <Button onClick={()=>openUploadBox("all")} className="flex items-center justify-around rounded space-x-1" type='primary'  icon={<Plus size={24} />} >Add Products</Button>
      </div>
      <Table columns={columns} rowSelection={false} dataSource={products} pagination={tableParams.pagination} onChange={handleTableChange} />
      </>
  )
}

export default ProductsTable