import { Button, Statistic, Modal, notification } from "antd";
import Utils from "helper/Utils";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import {
  ArrowDown,
  ArrowDownShort,
  ArrowUp,
  Clock,
  ClockFill,
  Coin,
} from "react-bootstrap-icons";
import ApiService from "services/ApiService";
import { Link } from "react-router-dom";
import MentionRequestModal from "./MentionRequestModal";


const { confirm } = Modal;
const HashtagsMention = (props) => {
  const [processOn, setprocessOn] = useState(false);
  const [userTokens, setuserTokens] = useState(0);
  const [userTokensOg, setuserTokensOg] = useState(0);
  const [showModal, setshowModal] = useState(false);
  const [modalEvent, setmodalEvent] = useState({ event: "mint", item: null });
  const [type, settype] = useState(props.type);
  const [item, setitem] = useState(props.hash);
  
  useEffect(() => {
    return () => {};
  }, [item, type]);
  const getRndInteger = (min, max) => {
    return Math.floor(Math.random() * (max - min)) + min;
  };
  const randomNumber = getRndInteger(-15, 20);
  const mintHashes = async (now, title, requestedCoins = 2) => {
    setprocessOn(true);
    const hashKeys = [];
    hashKeys.push(Utils.getHashId(title, true, true));
    const addedKeyHashes = await ApiService.PostApiCalls(
      "keyhashes/list",
      { hashKeys: hashKeys },
      true
    );
    if (addedKeyHashes[0] && addedKeyHashes[1]["IsSuccessful"]) {
      setprocessOn(false);
      setuserTokens(addedKeyHashes[1]["user"]["hashTokens"]);
      setuserTokensOg(addedKeyHashes[1]["user"]["hashTokens"]);
      const availableCoins = addedKeyHashes[1]["user"]["hashTokens"];
      if (availableCoins < requestedCoins) {
        Utils.showNotification(
          "error",
          "Oopps",
          "You do not have enought coins!"
        );
      } else {
        //Mint
        let hashUpdates = [];
        const hash = {
          title: title,
          hashId: Utils.getHashId(title, true, true),
          minted: now,
        };
        hashUpdates.push(hash);
        const minted = await ApiService.PostApiCalls(
          "keyhashes/mint",
          { hashKeys: hashUpdates, userToken: now ? userTokens : userTokensOg },
          true
        );
        if (minted[0] && minted[1]["IsSuccessful"]) {
          Utils.showNotification(
            "success",
            "Done",
            "Hash have added in your account"
          );
        } else {
          Utils.showNotification("error", "Oopps", "Something went wrong");
        }
      }
    } else {
      setprocessOn(false);
      Utils.showNotification("error", "Oopps", "Something went wrong");
    }
  };

  const buyHash = () => {
    setmodalEvent({ event: "auction", item: item });
    setshowModal(true);
  };
  const cancelCalled = (hash = null) => {
    setshowModal(false);
    if (hash) {
      setitem(hash);
    }
  };
  
  return (
    <>
     {type === "Available" && props.hash.hashId && <div className="flex flex-1 justify-between">
        <div>
          <span className="flex space-x-3 items-center pn-2">
            <h6 className="mb-0">{`#${props.hash.title.replace(/ /g, "")}`}</h6>
            <span className="text-xs">
              {` - `}
              {item.author[0]?<Link to={"/" + item.author[0]["userName"]}>
                {item.author[0]["name"]}
              </Link>:"Admin"}
            </span>
          </span>
          <span className="text-xs text-muted px-1">
            {item.minted
              ? `Minted on ${moment
                  .unix(props.hash.updatedAt / 1000)
                  .format("MMM DD, YYYY ")}`
              : `Added on ${moment
                  .unix(props.hash.createdAt)
                  .format("MMM DD, YYYY ")}`}
          </span>
          <div className="py-2"></div>
          {item.listingStatus === "open" ?(<> <span className="text-md py-2 mb-2 font-semibold px-2">
            {item.listingEnddate === 0
              ? `Ends on never`
              : `Ends on ${moment.unix(item.listingEnddate).format("MMM DD, YYYY ")}`}
          </span>
          <div className="py-2"></div>
          
            <div className="flex flex-row space-x-3 py-2">
              <Button
                loading={processOn}
                onClick={buyHash}
                className="px-3 brd-4 fw-700  rounded-lg text-white bg-hs-pink  hover:border-hs-pink"
              >
                Send Request
              </Button>
            </div>
            </>
          ):(<> <span className="text-md py-2 mb-2 font-semibold px-2">Your request is in process.</span></>)}
        </div>
      </div>}
      {type === "Your Request" && <div className="flex flex-1 justify-between">
        <div>
          <span className="flex space-x-3 items-center pn-2">
            <h6 className="mb-0">{`#${props.hash.title.replace(/ /g, "")}`}</h6>
            <span className="text-xs">
              {` - `}
              <Link to={"/" + item.author[0]["userName"]}>
                {item.author[0]["name"]}
              </Link>
            </span>
          </span>
          <span className="text-xs text-muted px-1">
            {item.minted
              ? `Minted on ${moment
                  .unix(props.hash.updatedAt / 1000)
                  .format("MMM DD, YYYY ")}`
              : `Added on ${moment
                  .unix(props.hash.createdAt)
                  .format("MMM DD, YYYY ")}`}
          </span>
          <div className="py-2"></div>
          {item.listingStatus === "approved" ?(<> 
            <div className="flex flex-row w-full justify-between space-x-4 font-semibold items-center">
            <div className="flex flex-col space-y-4 px-3">
            <span className="flex flex-row space-x-3 items-center">
              <span>Your mention handle
                :</span>
              <span>{`@${item["requestedOptions"]["mentionHandle"]}`}</span>
              </span>
              <span className="flex flex-row space-x-3 items-center">
              <span>End date
                :</span>
              <span>
              {`${moment.unix(item["requestedOptions"].endDate).format("MMM DD, YYYY ")}`}</span>
              </span>
            </div>
            </div>
          
          <div className="py-2"></div>
          
            <div className="flex flex-row space-x-3 py-2">
              <Button
                loading={processOn}
               
                className="px-3 brd-4 fw-700  rounded-lg text-white bg-hs-pink  hover:border-hs-pink"
              >
                Cancel Request
              </Button>
            </div>
            </>
          ):(<> <span className="text-md py-2 mb-2 font-semibold px-2">Your request is in process.</span>
           <div className="py-2"></div>
          
          <div className="flex flex-row space-x-3 py-2">
            <Button
              loading={processOn}
             
              className="px-3 brd-4 fw-700  rounded-lg text-white bg-hs-pink  hover:border-hs-pink"
            >
              Cancel Request
            </Button>
          </div></>)}
        </div>
      </div>}
      {!item && <div></div>}
      {showModal && (
        <MentionRequestModal
          event={modalEvent}
          user={props.user}
          showModalFirst={showModal}
          callback={setshowModal}
          cancelCallBack={cancelCalled}
        />
      )}
    </>
  );
};

export default HashtagsMention;
