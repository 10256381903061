import React, {createContext, useState} from 'react';

export const VideoContext = createContext();

export const VideoProvider = ({children}) => {
    const [video, setVideo] = useState(null);
    const [videoId, setVideoId] = useState("");
    const [videoClips, setVideoClips] = useState([]);
    const [videoActiveClip, setVideoActiveClip] = useState(0);
    const [videoActiveClipType, setVideoClipType] = useState("text");
    return (
        <VideoContext.Provider
           value={{               
            video, setVideo,videoId, setVideoId,videoClips, setVideoClips,videoActiveClip, setVideoActiveClip,videoActiveClipType, setVideoClipType
           }}
           >
               {children}
    </VideoContext.Provider>
    )
}