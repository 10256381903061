import React from "react";
import PropTypes from "prop-types";


const HomeContainer = ({ children, noNavbar, noFooter }) => {
    return <>
    {
      <div className="container w-full max-w-full px-0 home-bg min-h-screen">
        <div className="absolute home-circle-2"></div>
        <div className="absolute home-circle-1"></div>
        {children}
      </div>
    }
    </>
 }
  
 HomeContainer.propTypes = {
    /**
     * Whether to display the navbar, or not.
     */
    noNavbar: PropTypes.bool,
    /**
     * Whether to display the footer, or not.
     */
    noFooter: PropTypes.bool
  };
  
  HomeContainer.defaultProps = {
    noNavbar: false,
    noFooter: false
  };
  
  export default HomeContainer;