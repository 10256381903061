

import { Button, Card, Input, Tabs ,Checkbox, message} from 'antd'
import React from 'react'
import { useState } from 'react'
import { Flag } from 'react-bootstrap-icons'
import ApiService from 'services/ApiService'

const PinterestFeedOptions = ({closeFeed}) => {
  const [subheaderText, setsubheaderText] = useState("Handle")
  const [feedText, setfeedText] = useState("")
  const [showerror, setshowerror] = useState(false)
  const [activeTab, setactiveTab] = useState(0)
  const [sending, setsending] = useState(false)
  const  [tabOptions, settabOptions] = useState([
    {
      label:"Handle",
      type:"Handle",
      feedOptions:[true,false,false]
    },
    {
      label:"User Board",
      type:"User Board",
      feedOptions:[false,false]
    }
  ])
  
  
  const onChange = (key) => {
    setshowerror(false)
    setfeedText("");
    const current = tabOptions[parseInt(key)-1];
    setactiveTab(parseInt(key)-1)
    setsubheaderText(current["label"].toLowerCase())
  };
  const onCheckOption = (e,tab,k) =>{
    
    let allTabs = tabOptions;
    let currentab = tabOptions[tab];
    currentab["feedOptions"][k] = e.target.checked;
    allTabs[tab] = currentab;
    settabOptions(allTabs)
    
  }
  const createFeed = async() =>{
    setshowerror(false)
    
    if(feedText.trim() === ""){
      setshowerror(true)
      return false
    }
    setsending(true)
    //CreateFeed
    const feedOptions = tabOptions[activeTab];
    
    const saved = await ApiService.PostApiCalls('/commerce/feed/create',{feedsource:"pinterest","feedtype":feedOptions.type,feedOptions:feedOptions.feedOptions,searchquery:feedText},true)
    if (saved[0] && !saved[1]["IsSuccessful"]) {
      const code = saved[1]["code"];
      if(code === 100){
        message
        .warning('Feed already created', 2.5)
        
        
      }
      else{
        message
        .error('Something went wrong, please try again', 2.5)
      }
    setsending(false)

    }
    else{
    setfeedText("");

    setsending(false)
      message
    .success('Feed has been created', 2.5)
    .then(() => message.info('Posts will be added in backend.', 2.5).then(()=>window.location.href = "/creator/social-commerce/content-gallery"));
    }
  }
  const optionsTabs = {
    "1":{
      label:"Handle",
      type:"Handle",
    },
    "2":{
      label:"User Board",
      type:"User Board",
    },
   
  }
  
  return (
    <Card className='bg-gray-50 rounded w-full'>
      <div className='flex flex-col space-y-1 p-2'>
    <span className='text-lg'>Pinterest</span>
    <span className='text-muted text-sm'>{`Add your Pinterest ${subheaderText}`}</span>
      </div>
      <div>
      <Tabs defaultActiveKey="1" onChange={onChange}>
    <Tabs.TabPane tab="Handle(@)" key="1">
      <div className='flex flex-col space-y-3'>
      <Input addonBefore="@" value={feedText} onChange={(e)=>setfeedText(e.target.value)}  placeholder="Enter handle" />
      {showerror && <span className='text-xs text-danger -mt-3'>This field required</span>}
      <div className='py-2'></div>
      
      </div>
    </Tabs.TabPane>
    <Tabs.TabPane tab="User Board" key="2">
    <div className='flex flex-col space-y-2'>
      <Input addonBefore={<Flag />} value={feedText} onChange={(e)=>setfeedText(e.target.value)}  placeholder="https://in.pinterest.com/username/board-name/"/>
      {showerror && <span className='text-xs text-danger -mt-3'>This field required</span>}
      <div className='py-2'></div>
      
    </div>
    </Tabs.TabPane>
    
  </Tabs>
      </div>
      <div className='flex flex-row items-center justify-end space-x-3 py-3'>
      <Button onClick={closeFeed} loading={sending}className='rounded-sm'>Back</Button>
        <Button onClick={createFeed} loading={sending} className='bg-hs-pink text-white rounded-sm'>Create Feed</Button>
        
        </div>
        
    </Card>
  )
}

export default PinterestFeedOptions