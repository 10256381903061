import { Button, Card, Input, Tabs ,Checkbox, message, AutoComplete, Avatar} from 'antd'
import axios from 'axios'
import React from 'react'
import { useState } from 'react'
import { Binoculars, ListCheck } from 'react-bootstrap-icons'
import ApiService from 'services/ApiService'

const YouTubeFeedOptions = ({closeFeed}) => {
  const [subheaderText, setsubheaderText] = useState("Channel Url")
  const [feedText, setfeedText] = useState("")
  const [showerror, setshowerror] = useState(false)
  const [channelInfo, setchannelInfo] = useState(null)
  const [activeTab, setactiveTab] = useState(0)
  const [sending, setsending] = useState(false)
  const [options, setOptions] = useState([]);
  const  [tabOptions, settabOptions] = useState([
    {
      label:"Channel Url",
      key:"1",
      type:"Channel Url",
      prefix:"@",
      placeholder:"Enter channel url"
    },
    {
      label:"Shorts",
      type:"Shorts",
      key:"2",
      prefix:"@",
      placeholder:"Enter channel url"
    },
    {
      label:"Play List",
      type:"Play List",
      key:"3",
      prefix:<ListCheck />,
      placeholder:"Enter Play List ID"


    },
    {
      label:"Keywords",
      type:"Keywords",
      key:"4",
      prefix:"A",
      placeholder:"Enter keyword"

    }
  ])
  const onSelectChannel = (data,options) => {
    setfeedText(data);
    setchannelInfo(options.channelInfo)

    console.log('onSelect', data,options);
  };
  const handleSearch = (value) => {
    
    let searchtype = "channel";
    if(activeTab === 2){
      searchtype = "playlist"
    }
    let res = [];
    if (!value || value.indexOf('@') >= 0) {
      res = [];
    } else {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `https://youtube.googleapis.com/youtube/v3/search?part=snippet&q=${value}&type=${searchtype}&key=AIzaSyDhTPWwcXYeRH_ljL9Z8eCJDy1syPwQqn8&maxResults=50`,
        headers: { 
        }
      };
      
      axios.request(config)
      .then((response) => {
        // console.log(response.items);
        const items = response.data.items;
        items.map((it,k) => {
          
          res.push({label:(<div key={k} className='flex items-center space-x-3'>
            <Avatar src={it["snippet"]["thumbnails"]["default"]["url"]} />
            <span className='flex flex-col space-y-1'>
            <span>{it["snippet"]["title"]}</span>
            <span className='text-muted text-sm'>{`https://www.youtube.com/${searchtype}/${it["id"]["channelId"]}`}</span>
            </span>
            
           
          </div>
        ),value:activeTab === 2?`https://www.youtube.com/playlist?list=${it["id"]["playlistId"]}`:`https://www.youtube.com/channel/${it["id"]["channelId"]}`,channelInfo:{title:it["snippet"]["title"],kind:searchtype,id:activeTab === 2?it["id"]["playlistId"]:it["id"]["channelId"],link:activeTab === 2?`https://www.youtube.com/playlist?list=${it["id"]["playlistId"]}`:`https://www.youtube.com/channel/${it["id"]["channelId"]}`,picture:it["snippet"]["thumbnails"]["default"]["url"]}})
        })
        // console.log(JSON.stringify(response.data));
      setOptions(res);

      })
      .catch((error) => {
        console.log(error);
      });
    //   res = ['gmail.com', '163.com', 'qq.com'].map((domain) => ({
    //     value,
    //     label: `${value}@${domain}`,
    //   }));
    }
  };
  
  const optionsTabs = [
    {
      label:"Channel Url",
      key:"1",
      type:"Channel Url",
      prefix:"@",
      placeholder:"Enter channel url"
    },
    {
      label:"Shorts",
      type:"Shorts",
      key:"2",
      prefix:"@",
      placeholder:"Enter channel url"
    },
    {
      label:"Play List",
      type:"Play List",
      key:"3",
      prefix:<ListCheck />,
      placeholder:"Enter Play List ID"


    },
    {
      label:"Keywords",
      type:"Keywords",
      key:"4",
      prefix:"A",
      placeholder:"Enter keyword"

    }
  ]
  const onChange = (key) => {
    setshowerror(false)
    setOptions([]);
    setfeedText("");
    const current = tabOptions[parseInt(key)-1];
    setactiveTab(parseInt(key)-1)
    setsubheaderText(current["label"].toLowerCase())
  };
  const onCheckOption = (e,tab,k) =>{
    
    let allTabs = tabOptions;
    let currentab = tabOptions[tab];
    currentab["feedOptions"][k] = e.target.checked;
    allTabs[tab] = currentab;
    settabOptions(allTabs)
    
  }
  const createFeed = async() =>{
    setshowerror(false)
    
    if(feedText.trim() === ""){
      setshowerror(true)
      return false
    }
    setsending(true)
    //CreateFeed
    const feedOptions = tabOptions[activeTab];
    const saved = await ApiService.PostApiCalls('/commerce/feed/create',{feedsource:"YouTube","feedtype":feedOptions.type,feedOptions:feedOptions.feedOptions,searchquery:feedText,channelInfo:channelInfo},true)
    if (saved[0] && !saved[1]["IsSuccessful"]) {
      const code = saved[1]["code"];
      if(code === 100){
        message
        .warning('Feed already created', 2.5)
        
        
      }
      else{
        message
        .error('Something went wrong, please try again', 2.5)
      }
    setsending(false)

    }
    else{
    setfeedText("");

    setsending(false)
      message
    .success('Feed has been created', 2.5)
    .then(() => message.info('Posts will be added in backend.', 2.5).then(()=>window.location.href = "/creator/social-commerce/content-gallery"));
    }
    
  }
  // const inputCH=hangeSerivc
  return (
    <Card className='bg-gray-50 rounded w-full'>
      <div className='flex flex-col space-y-1 p-2'>
    <span className='text-lg'>YouTube</span>
    <span className='text-muted text-sm'>{`Add your YouTube ${subheaderText}`}</span>
      </div>
      <div>
        <Tabs onChange={onChange}>
        {
          optionsTabs.map(t => 
            <Tabs.TabPane tab={t["label"]} key={t["key"]}>
            <div className='flex flex-col space-y-3'>
            {
              t["label"] === "Keywords"?
              <Input addonBefore={t["prefix"]} value={feedText} onChange={(e)=>setfeedText(e.target.value)}   placeholder={t["placeholder"]} className='mb-2' />
              :
              <AutoComplete onSearch={handleSearch}
              onSelect={onSelectChannel}
              options={options}><Input addonBefore={t["prefix"]} value={feedText} onChange={(e)=>setfeedText(e.target.value)}   placeholder={t["placeholder"]} className='mb-2' /></AutoComplete>
            }
            {showerror && <span className='text-xs text-danger -mt-3'>This field required</span>}
      <div className='py-2'></div>
      
            
            </div>
          </Tabs.TabPane>
          )
        }
      </Tabs>
      </div>
      <div className='flex flex-row items-center justify-end space-x-3 py-3'>
      <Button onClick={closeFeed} loading={sending}className='rounded-sm'>Back</Button>
        <Button onClick={createFeed} loading={sending} className='bg-hs-pink text-white rounded-sm'>Create Feed</Button>
        
        </div>
        
    </Card>
  )
}

export default YouTubeFeedOptions