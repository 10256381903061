import { Button, Result, Spin, Row } from "antd";
import Utils from "helper/Utils";
import React from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ApiService from "services/ApiService";
import FeedsCard from "./FeedsCard";


const FeedsPage = (props) => {
  const navigate = useNavigate()
  const user = Utils.getLocalInfo("userhash");
  const [loading, setloading] = useState(true);
  const [error, seterror] = useState(null);

  const [groups, setgroups] = useState([]);
  const [baseQuery, setbaseQuery] = useState({});
  const [queryParams, setqueryParams] = useState({
    limit: 20,
    offset: 0,
    sort: "desc",
  });
  const grps = [
    {
      name: "Meme Master and Humor",
      members: <span>Created by <Link to={"sunil-uttekar-53114"}>@djsoki</Link> &#8729;  86 subscribers &#8729; 67 members</span>,
      image:
        "https://scontent.fbom24-1.fna.fbcdn.net/v/t1.18169-9/10408684_10201675886370318_2530351622024656102_n.jpg?_nc_cat=107&ccb=1-5&_nc_sid=8631f5&_nc_ohc=mKTIRh-aayUAX-x9UUR&_nc_ht=scontent.fbom24-1.fna&oh=00_AT-IrQ9P-ZWeXZD8NoZPDYEtqMWgPMoWfV5HsbLns0lbJA&oe=62774DCC",
    },
    {
        name: "News",
        members: <span>Created by <Link to={"sunil-uttekar-53114"}>@djsoki</Link> &#8729;  86 subscribers &#8729; 67 members</span>,
        image:
          "https://scontent.fbom24-1.fna.fbcdn.net/v/t1.18169-9/10408684_10201675886370318_2530351622024656102_n.jpg?_nc_cat=107&ccb=1-5&_nc_sid=8631f5&_nc_ohc=mKTIRh-aayUAX-x9UUR&_nc_ht=scontent.fbom24-1.fna&oh=00_AT-IrQ9P-ZWeXZD8NoZPDYEtqMWgPMoWfV5HsbLns0lbJA&oe=62774DCC",
      },
      {
        name: "Alt media influencers",
        members: <span>Created by <Link to={"praphuljain"}>@praphuljain</Link> &#8729;  62 subscribers &#8729; 112 members</span>,
        image:
          "https://scontent.fbom24-1.fna.fbcdn.net/v/t1.18169-9/10408684_10201675886370318_2530351622024656102_n.jpg?_nc_cat=107&ccb=1-5&_nc_sid=8631f5&_nc_ohc=mKTIRh-aayUAX-x9UUR&_nc_ht=scontent.fbom24-1.fna&oh=00_AT-IrQ9P-ZWeXZD8NoZPDYEtqMWgPMoWfV5HsbLns0lbJA&oe=62774DCC",
      },
      {
        name: "Election 2022 Candidates",
        members: <span>Created by <Link to={"MumbaiMirror"}>@MumbaiMirror</Link> &#8729;  120 subscribers &#8729; 98 members</span>,
        image:
          "https://scontent.fbom24-1.fna.fbcdn.net/v/t1.18169-9/10408684_10201675886370318_2530351622024656102_n.jpg?_nc_cat=107&ccb=1-5&_nc_sid=8631f5&_nc_ohc=mKTIRh-aayUAX-x9UUR&_nc_ht=scontent.fbom24-1.fna&oh=00_AT-IrQ9P-ZWeXZD8NoZPDYEtqMWgPMoWfV5HsbLns0lbJA&oe=62774DCC",
      },
      {
        name: "Gab Business",
        members: <span>Created by <Link to={"sunil-uttekar-53114"}>@djsoki</Link> &#8729;  86 subscribers &#8729; 67 members</span>,
        image:
          "https://scontent.fbom24-1.fna.fbcdn.net/v/t1.18169-9/10408684_10201675886370318_2530351622024656102_n.jpg?_nc_cat=107&ccb=1-5&_nc_sid=8631f5&_nc_ohc=mKTIRh-aayUAX-x9UUR&_nc_ht=scontent.fbom24-1.fna&oh=00_AT-IrQ9P-ZWeXZD8NoZPDYEtqMWgPMoWfV5HsbLns0lbJA&oe=62774DCC",
      },
      {
        name: "Startups Funding",
        members: <span>Created by <Link to={"sunil-uttekar-53114"}>@djsoki</Link> &#8729;  86 subscribers &#8729; 67 members</span>,
        image:
          "https://scontent.fbom24-1.fna.fbcdn.net/v/t1.18169-9/10408684_10201675886370318_2530351622024656102_n.jpg?_nc_cat=107&ccb=1-5&_nc_sid=8631f5&_nc_ohc=mKTIRh-aayUAX-x9UUR&_nc_ht=scontent.fbom24-1.fna&oh=00_AT-IrQ9P-ZWeXZD8NoZPDYEtqMWgPMoWfV5HsbLns0lbJA&oe=62774DCC",
      },
      {
        name: "Orthodox Christianity",
        members: <span>Created by <Link to={"sunil-uttekar-53114"}>@djsoki</Link> &#8729;  86 subscribers &#8729; 67 members</span>,
        image:
          "https://scontent.fbom24-1.fna.fbcdn.net/v/t1.18169-9/10408684_10201675886370318_2530351622024656102_n.jpg?_nc_cat=107&ccb=1-5&_nc_sid=8631f5&_nc_ohc=mKTIRh-aayUAX-x9UUR&_nc_ht=scontent.fbom24-1.fna&oh=00_AT-IrQ9P-ZWeXZD8NoZPDYEtqMWgPMoWfV5HsbLns0lbJA&oe=62774DCC",
      },
      

  ];
  useEffect(() => {
    const params = props["match"]["params"];
    setgroups(grps);
    setloading(false);
    // let filter = {};
    // if (params["user"] && params["id"]) {
    //   filter["type"] = "id";
    //   filter["contentID"] = params["id"];
    // } else if (params["tag"]) {
    //   filter["type"] = "tag";
    //   filter["tag"] = params["tag"];
    // }
    // setbaseQuery(filter);
    // getPosts(filter);
    return () => {};
  }, [props]);

  const getPosts = async (filter) => {
    const queryString = new URLSearchParams(queryParams).toString();
    const res = await ApiService.PostApiCalls(
      `posts?${queryString}`,
      { ...filter },
      true
    );

    if (res[0]) {
      if (res[1]["IsSuccessful"]) {
        setgroups(res[1]["list"]);
        setloading(false);
        window.scrollTo(0, 0);
      } else {
        setloading(false);
      }
    } else {
      setloading(false);
    }
  };
  if (
    user["role"] === "creator" &&
    parseInt(user["creatorProfileStep"]) !== 5
  ) {
    return navigate("/creator/edit/1");//<Redirect to="/creator/edit/1" />;
  }

  return loading ? (
    <div className=" h-100 d-flex justify-center align-items-center bg-white py-3 px-4 rounded-xl ">
      <Spin />
    </div>
  ) : (
    <>
      <div className=" d-flex justify-center flex-col bg-white py-3 rounded-xl ">
        <div className="px-4 d-flex justify-between mb-2 pb-2 border-b">
          <span className="text-hp text-lg font-semibold">Featured Feeds</span>
          
        </div>
        {groups && groups.length > 0 && (
          <div className="px-4 space-y-3">
            
            <FeedsCard posts={groups} />
            
          </div>
        )}
        {groups && groups.length === 0 && (
          <div className="px-4 bg-white">
            <Result
              status="403"
              title={baseQuery["tag"] ? `#${baseQuery["tag"]}` : ""}
              subTitle={
                baseQuery["tag"]
                  ? "Sorry, no post found for searched tag."
                  : "Sorry, no post found for searched id."
              }
              extra={
                <Link to={"/"}>
                  <Button type="primary">Back Home</Button>
                </Link>
              }
            />
          </div>
        )}
      </div>
    </>
  );
};

export default FeedsPage;
