import React, { useEffect, useState } from 'react'
import { Col,Button,Row ,Table,Modal, Switch, message, Skeleton, Empty, AutoComplete, Avatar, Input, List} from 'antd'
import LoaderSkeleton from './LoaderSkeleton'
import ApiService from 'services/ApiService'
import { Link } from 'react-router-dom'
import { Plus, Trash } from 'react-bootstrap-icons'
import ContentCard from '../content/ContentCard'
import ContentMedia from '../content/ContentMedia'
import _ from 'lodash'

const ContentGallery = ({user}) => {
  const [galleryPosts, setgalleryPosts] = useState([])
  const [loading, setloading] = useState(true)
  const [selectedId, setselectedId] = useState(null)
  const [selectedPost, setselectedPost] = useState(null)
  const [options, setOptions] = useState([]);
  const [searchText, setsearchText] = useState("")
  const [selectedProducts, setselectedProducts] = useState([])
  const [sending, setsending] = useState(false)

  const [open, setOpen] = useState(false);
  useEffect(() => {
    
    getPosts()
    return () => {
      
    }
  }, [])
  const style = {
    background: '#0092ff',
    padding: '8px 0',
  };
  const startProductTag = async(id,post,tagged)=>{
    setselectedId(id)
    setselectedPost(post)
    setsearchText("")
    setselectedProducts(tagged)
    setOptions([])
    let res = []
    const response = await ApiService.PostApiCalls("/commerce/products/list",{searchquery:""},true);
    if (response[0] && response[1]["IsSuccessful"]) {
      
      const items = response[1].products;
      items.map((it,k) => {
        
        res.push({label:(<div key={k} className='flex items-center space-x-3'>
          <Avatar src={it["image"]} />
          <span className='flex flex-col space-y-1'>
          <span>{it["title"]}</span>
          <span className='text-muted text-sm'>{`${(it.money_format).replace("{{amount}}","")} ${it["price"]}`}</span>
          </span>
          
         
        </div>
      ),value:it["title"],productInfo:{pid:it["pid"],title:it["title"],shopname:it["shoptype"],shopid:it["product_id"],shoplink:it["productLink"],price:it["price"],image:it["image"],money_format:it["money_format"],priceformatted:`${(it.money_format).replace("{{amount}}","")} ${it["price"]}`}})
      })
      setOptions(res);
    }
    setOpen(true)
  }
  const getPosts = async()=>{
    //commerce/feed/posts
    const posts = await ApiService.PostApiCalls("/commerce/feed/posts",{},true)
    if (posts[0] && !posts[1]["IsSuccessful"]) {
      setgalleryPosts(posts[1]["posts"])
      
      
  }
  else {
    setgalleryPosts(posts[1]["posts"])
    
  }
  setloading(false)
  }
  const handleSearch = async(value) => {
    setOptions([])
    let res = []
    const response = await ApiService.PostApiCalls("/commerce/products/list",{searchquery:value},true);
    if (response[0] && response[1]["IsSuccessful"]) {
      
      const items = response[1].products;
      items.map((it,k) => {
        
        res.push({label:(<div key={k} className='flex items-center space-x-3'>
          <Avatar src={it["image"]} />
          <span className='flex flex-col space-y-1'>
          <span>{it["title"]}</span>
          <span className='text-muted text-sm'>{`${(it.money_format).replace("{{amount}}","")} ${it["price"]}`}</span>
          </span>
          
         
        </div>
      ),value:it["title"],productInfo:{pid:it["pid"],title:it["title"],shopname:it["shoptype"],shopid:it["product_id"],shoplink:it["productLink"],price:it["price"],image:it["image"],money_format:it["money_format"],priceformatted:`${(it.money_format).replace("{{amount}}","")} ${it["price"]}`}})
      })
      setOptions(res);
    }
  }
  
  const onSelectOption = (value,data)=>{
    
    const isEmpty = _.filter(selectedProducts, function (t,i) {                
      return t["pid"] === data["productInfo"]["pid"];
    });  
    if(isEmpty.length === 0){
      setselectedProducts([...selectedProducts,data["productInfo"]])
      setsearchText("")
    }
    else{
      message.error(
        {
            content: 'Product is already added',
            className: 'custom-class',
            style: {
              right: '10px',
              position:"absolute"

            },duration:2
          });
    }
    
  }
  const removeProduct = (pid)=>{
    const updatedlist = _.remove(selectedProducts, function (p,i) {
      return p.pid !== pid;
    });
    setselectedProducts(updatedlist)
  }
  const handleOk = async()=>{
    
    const added = await ApiService.PostApiCalls("/commerce/posts/add-products",{pid:selectedId,products:selectedProducts},true)
    let galleryPostsTmp = galleryPosts;
    const updatedData = galleryPostsTmp.map(item => {
      if (item.contentId === selectedId) {
        return {...item,taggedProducts:selectedProducts}; // Replace the item if IDs match
      }
      return item; // Keep the original item if IDs don't match
    });
    setgalleryPosts(updatedData)
    setOpen(false)

  }
  return (
    <>
    <Col span={24} className="py-0 px-0 mb-0">
        <div className="bg-white p-3 px-3 flex space-x-3 items-center justify-between">
          <span className="font-bold">{"Your Gallery"}</span>
          <Button
             
            type="primary"
            className=" rounded  float-right mr-3"
            
          >
            
          </Button>
        </div>
      </Col>
      <div className="pb-2 bg-gray-100"></div>
      <Col span={24} className="p-3 mb-0 ">
      {loading && <Row
      gutter={{
        xs: 8,
        sm: 16,
        md: 24,
        lg: 32,
      }}
    >
      {[1,2,3,4,5,6,7,8,9,0,12,13].map(d =><Col className="gutter-row" span={6}>
      <LoaderSkeleton />
      </Col>)}
     
    </Row>}
    {!loading && galleryPosts.length > 0 &&<Row
      gutter={[16, 16]}
    >
      {galleryPosts.map((d,key) =><Col className="gutter-row" span={6}>
      <ContentCard key={key} post={d} addProducts={startProductTag}/>
      </Col>)}
     
    </Row>}
    {!loading && galleryPosts.length === 0 && <Row
      gutter={{
        xs: 8,
        sm: 16,
        md: 24,
        lg: 32,
      }}
    >
      <Col className="gutter-row flex items-center justify-center mt-5" span={24}>
      <Empty
    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
    className='text-center flex items-center justify-center flex-col'
    imageStyle={{
      height: 160,
    }}
    description={
      <span>
        You have not added any feed yet!
      </span>
    }
  >
    <Link to="./add-feed"><Button className="flex items-center justify-around rounded space-x-1" type='primary'  icon={<Plus size={24} />} >Add Feed</Button></Link>
  </Empty>
      </Col>
     
    </Row>}
      </Col>
      <Modal
        title="Tag Products"
        centered
        open={open} visible={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
      
        style={{ top: 20 }}
        bodyStyle={{padding:"1px"}}
        footer={[
          <Button loading={sending} key="back" onClick={() => setOpen(false)}>
            Close
          </Button>,
           <Button disabled={selectedProducts.length === 0} key="submit" type="primary" loading={sending} onClick={handleOk}>
           Save Products
         </Button>
          
          
        ]}
        width={800}
      >
        <Row gutter={{
        xs: 8,
        sm: 16,
        md: 24,
        lg: 32,
      }}>
          <Col span={12}>
          <ContentCard post={selectedPost} hidetools={true} />
          </Col>
          <Col span={12} className='p-4 flex flex-col space-y-4'>
          <span>Select Products</span>
          <AutoComplete onSearch={handleSearch}
              onSelect={onSelectOption}
              
              value={searchText}
              options={options}  className='w-full' >
                <Input  value={searchText} onChange={(e)=>setsearchText(e.target.value)}   placeholder="Search by name, category, tags, SKU" className='' /></AutoComplete>
          <div className='w-full flex items-center justify-center'>
            <Link to={"/creator/social-commerce/products-catalog"}>
            <Button className='rounded-md'>Add New Product</Button></Link>
            </div>
           <div className='h-96 overflow-y-auto'>
            {selectedProducts.length > 0 && 
            <List dataSource={selectedProducts}
            renderItem={(item) => (
              <List.Item
                actions={[<Button type="" onClick={()=>removeProduct(item.pid)} className='flex items-center justify-center' shape="circle" icon={<Trash className='text-red' />} />]}
              >
                <List.Item.Meta
              avatar={<Avatar src={item.image} />}
              title={item.title}
              description={item.priceformatted}
            />
            
                </List.Item>
            )}
            />
            
            }
            </div> 
            
          </Col>
          
        </Row>
      </Modal>
    </>
  )
}

export default ContentGallery