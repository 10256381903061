import React,{useState} from 'react'
import { Input,Button } from 'antd'

const { TextArea } = Input;
const EditableQuote = ({text,updateText,k}) => {
    const [edit, setEdit] = useState(false);
    const [editText, setEditText] = useState(text);
    const Original = text;
    const inputRef = React.useRef();
    const setEditOn = () =>{
        setEdit(true);        
    }
    const setUpdate = () =>{
        updateText(k,editText)
        setEdit(false);
    }
    const setCancel = () =>{
        setEdit(false)
        setEditText(Original)
    }
    return (
        <div style={!edit?{width: '100%' }:{width: '110%' }}>
         {!edit && <span className="only-text" onClick={() => setEditOn()}>{editText}</span>}   
         {edit && <TextArea
          placeholder="Write quote..."
          autoSize={{ minRows: 2, maxRows: 3 }}
          className="mb-3 custom-scroll"
          value={editText} 
          ref={inputRef}
          autoFocus
          onChange={(e) => setEditText(e.target.value)}
        />} 
        {edit && <><Button type="primary" onClick={() =>setUpdate() } size={"small"} className="mr-2">Update</Button><Button onClick={() => setCancel()}  size={"small"}>Cancel</Button></>}  
        </div>
    )
}

export default EditableQuote
