import _ from "lodash";
import cookie from "react-cookies";
import moment from "moment";
import { notification } from "antd";

import md5 from "md5";
const charaterLimits = {
  "all":3000,
  "feed":3000,
  "facebook":1000,
  "twitter":280,
  "instagram":1000,
  "linkedin":1300
}

const Utils = {
  randomString:(len) =>{
    var chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOP1234567890";
    var pass = "";
    for (var x = 0; x < len; x++) {
        var i = Math.floor(Math.random() * chars.length);
        pass += chars.charAt(i);
    }
    return pass;
  },
  renderDate: (time) => {
    return moment.unix(time).fromNow(false);
  },
  apiUrl: (path, params = "") => {
    let url = process.env.REACT_APP_API + path;
    if (params && !_.isEmpty(params)) {
      url += "?" + encodeURI(params);
    }
    return url;
  },
  apiCogent: (path, params = "") => {
    let url = process.env.REACT_APP_COGENTAPI + path;
    if (params && !_.isEmpty(params)) {
      url += "?" + encodeURI(params);
    }
    return url;
  },
  ValidateEmail: (mail) => {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(mail) === false) {
      return false;
    }

    return true;
  },
  setCookie: (key, value, maxAge = 86400) => {
    cookie.save(key, value, { path: "/", maxAge: maxAge });
  },
  getCookie: (key) => {
    return cookie.load(key) || null;
  },
  removeCookie: (key) => {
    cookie.remove(key, { path: "/" });
  },
  ValidateURL: (str) => {
    var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return regexp.test(str);
  },
  apiHeaderApp: (auth = false) => {
    var ret = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        // "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "PUT,GET,POST,DELETE,OPTIONS,PATCH",
        Accept: "application/json",
      },
    };

    try {
      const token = auth
        ? Utils.getCookie("sessionhash")
        : "AQU5SPgizEExKHZAqdAv_j-5UhAHkvsysnYvpeXViw7uKx";

      if (token) {
        ret.headers["Authorization"] = `Bearer ${token}`;
      }
    } catch {
      // do nothing
    }

    return ret;
  },

  getLocalInfo: (key) => {
    var udata = localStorage.getItem(key);
    if (udata && udata !== "null") {
      return JSON.parse(udata);
    }
    return false;
  },
  updateLocalInfo:(key,data,callback=null)=>{
    var localInfo = Utils.getLocalInfo(key);
    
    if(localInfo){
      data = {
        ...localInfo,
        ...data
      }; //== overwrite received data
    }
    localStorage.setItem(key,JSON.stringify(data));
    if(callback){
      callback();
    }
    return null;
  },
  setLocalInfo: (key, info, callback = null) => {
    localStorage.setItem(key, JSON.stringify(info));
    if (callback) {
      callback();
    }
  },
  removeLocalInfo: (key, callback = null) => {
    localStorage.removeItem(key);
    if (callback) {
      callback();
    }
  },
  showNotification: (type, title, text,duration=2) => {
    notification[type]({
      message: title,
      description: text,
      duration: duration,
    });
  },
  processAuthIn: (data) => {
    const userInfo = _.omit(data, ["token"]);
    Utils.setLocalInfo("userhash", userInfo);
    Utils.setCookie("sessionhash", data.token);
  },
  processAuthOut: () => {
    Utils.removeLocalInfo("userhash");
    Utils.removeCookie("sessionhash");
  },
  getTextLimit : (type) =>{
    return charaterLimits[type]
  },
  filterArray:(array,keys=[])=>{
    return _.map(array, _.partialRight(_.pick,keys));
  },
  getHashId:(value,space=false,lower=false) =>{
    if(space && lower){
    return md5(value.replace(/ /g, '').toLowerCase())
    }
    else if(space){
      return md5(value.replace(/ /g, ''))
    }
    else if(lower){
      return md5(value.toLowerCase())
    }
    else{
      return md5(value)
    }
    
  }
  ,
  renderScheduleList:(defaultschedules,customschedules,wid) => {
    let forworkspace = [];
    if(customschedules && customschedules.length > 0){
         forworkspace = _.filter(customschedules,function(s){
            return s["wid"] === wid;
        }) 
    }
    if(forworkspace && forworkspace.length>0){
        return forworkspace;
    }
    return defaultschedules;
  },

  getWorkSpaceSocialProfiles:(authUser,wid) =>{
    let forworkspace = [];
    forworkspace = _.filter(authUser["socialchannels"],function(s){
      return s["wid"] === wid;
    })
    const feedprofile = {name:"HashSaga Feed","id":"hash-saga","source":"feed","fb-picture":authUser["profilePicture"]?authUser["profilePicture"]:"https://hs-container.s3.ap-south-1.amazonaws.com/blume.png"}
    // forworkspace.push(feedprofile)
    if(forworkspace.length === 0){
      return forworkspace
    }
    else{
      return forworkspace;
    }
  },
  getImageUrl:(campaign)=>{
    if(campaign["metaInfo"] && campaign["metaInfo"]["image"] !==""){
      

      return campaign["metaInfo"]["image"]
    }
    else if(campaign["fetchedImges"] && campaign["fetchedImges"][0]){
      
      return campaign["fetchedImges"][1]?campaign["fetchedImges"][1]:campaign["fetchedImges"][0]
    }
    else{
      return false;
    }
  },
  setEvents: (data,wid) =>{
    let list = [];
    let counter = 1;
    data.map((p,i) => {
      const spall = Utils.getWorkSpaceSocialProfiles(p["socialprofiles"],wid);
      const selected =p["selectedProfiles"];
      const sps = _.filter(spall,function(s){ return selected.includes(s["id"]) })
      const onlysource = _.map(_.filter(sps,function(obj) {return obj.source}), 'source')
      p.postlist.map((s,j) => {
      const ev = {
        id: counter,
        cid:p["cid"],
        title: s["qoute"],
        profilesource:onlysource,
        image:s["isVideoPost"]?s["videoCover"]:s["image"][0]["src"]?s["image"][0]["src"]:s["image"][0]["url"],
        allDay: true,
        postId:s["postid"],
        isVideo:s["isVideoPost"],
        start: moment.unix(s["scheduleDates"]["date"]+2400).format("MM/DD/YYYY"),
        end: moment.unix(s["scheduleDates"]["date"]+3000).format("MM/DD/YYYY"),
      };
      list.push(ev)
      counter++;
    })
    })
    return list;
  },
  toHHMMSS : (secs) => {
    var sec_num = parseInt(secs, 10)
    var hours   = Math.floor(sec_num / 3600)
    var minutes = Math.floor(sec_num / 60) % 60
    var seconds = sec_num % 60

    return [hours,minutes,seconds]
        .map(v => v < 10 ? "0" + v : v)
        .filter((v,i) => v !== "00" || i > 0)
        .join(":")
},
 disabledDate : (current)=> {
  // Can not select days before today and today
  return current && current < moment().endOf('day');
},
capitalizeFirstLetter:(str) =>{
  return str[0].toUpperCase() + str.slice(1);
},
numberFormatter:(num, digits) =>{
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function(item) {
    return num >= item.value;
  });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
},
getLocationInfo:(addressComponents)=>{
  
  let locationInfo = {
    city: null,
    state: null,
    country: null
  };
    for (const component of addressComponents) {
      for (const type of component.types) {
        if (type === 'locality') {
          locationInfo.city = component.long_name;
        } else if (type === 'administrative_area_level_1') {
          locationInfo.state = component.long_name;
        } else if (type === 'country') {
          locationInfo.country = component.long_name;
        }
      }
    }
    return locationInfo; // Return null if city name is not found
  
},
getAmountInFormat:(value)=>{
  const val = Math.abs(value)
  if (val >= 10000000) return `${(value / 10000000).toFixed(2)} Cr`
  if (val >= 100000) return `${(value / 100000).toFixed(2)} Lac`
  return value.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

},
requestFaileddMsg:()=>{
  return "Something went wrong on our end, please try again."
}

  //https://lh3.googleusercontent.com/a-/AOh14GjtoVTscoSIvTlEEyI-rb9VDvK_AZ6FKsNOM3IK=s96-c
};
export default Utils;
