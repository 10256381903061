import React, { useEffect, useState } from 'react'
import { Col,Button ,Table,Modal, Switch, message, Spin, Divider, Input, Upload} from 'antd'
import ApiService from 'services/ApiService';
import { Shop } from 'react-bootstrap-icons';
import ProductsTable from './ProductsTable';
import FileUploader from './views/FileUploader';

const ProductsCatalog = () => {
  const [products, setproducts] = useState([])
  const [loading, setloading] = useState(false);
  const [shopLink, setshopLink] = useState("")
  const [error, seterror] = useState(null)
  const [showUploadModal, setshowUploadModal] = useState(false)
  const [modalOption, setmodalOption] = useState("all")
  
  useEffect(() => {
    getProducts()
  
    return () => {
      
    }
  }, [])
  const openUploadBox = (opt)=>{
    setmodalOption(opt)
    setshowUploadModal(true)
  }
  const closeBox = ()=>{
    setshowUploadModal(false)
  }
  const connectShop = () =>{
    seterror(false);
    if(shopLink.trim() === ""){
      seterror(true)
      return false;
    }
    else{
      const scopes = "read_themes,write_themes,read_script_tags,write_script_tags,read_content,write_content,read_products,write_products,write_orders,write_fulfillments,read_inventory,write_inventory,write_assigned_fulfillment_orders,read_draft_orders,read_assigned_fulfillment_orders,write_merchant_managed_fulfillment_orders,read_merchant_managed_fulfillment_orders";
                        const forwardingAddress = "";
                        const redirectURL = forwardingAddress + "http://localhost:3000/creator/social-commerce/shopify-token";
                        const shopState = "shopState";
                        const cld = "274a3a6cfced3a0c49aef57e3199824b"
                        // install url for app install
                        const installUrl =
                        "https://" +
                        shopLink +
                        "/admin/oauth/authorize?client_id=" +
                        cld +
                        "&scope=" +
                        scopes +
                        "&state=" +
                        shopState +
                        "&redirect_uri=" +
                        redirectURL;
                    // console.log(installUrl);
                         window.location.href = installUrl;
    }
  }
  const getProducts = async()=>{
    const content = await ApiService.PostApiCalls("/commerce/products/list",{},true);
    if (content[0] && !content[1]["IsSuccessful"]) {
      setproducts(content[1]["products"])
    }
    else {
      setproducts(content[1]["products"])
      
    }
  setloading(false)

  }
 const finishCallBack = async()=>{
  setshowUploadModal(false)
  getProducts()

 }
  return (
    <>
     <Col span={24} className="py-0 px-0 mb-0">
        <div className="bg-white p-3 px-3 flex space-x-3 items-center justify-between">
          <span className="font-bold">{"Product List"}</span>
          <Button
             
            type="primary"
            className=" rounded  float-right mr-3"
            
          >
            
          </Button>
        </div>
      </Col>
      <div className="pb-2 bg-gray-100"></div>
      {loading && <Col span={24} className="p-3 mb-0 flex items-center justify-center h-screen ">
        <Spin />
        </Col>}
        {!loading && <Col span={24} className="p-3 mb-0 flex items-center justify-center ">
        { products.length === 0 && <Col span={12} className='flex flex-col'>
          <div className='py-5'></div>
        
        <div className='flex flex-col space-y-8 mt-4'>
          <div className='flex space-x-3'>
            <div className='w-100'>
          <Input className='w-100' onChange={(e)=>setshopLink(e.target.value)} placeholder="example.myshopify.com"/>
        {error && <span className="p-2 text-xs text-pink-700">This field is required.</span>}
          </div>
          <Button onClick={connectShop} className="flex items-center justify-around rounded space-x-1 shopify_btn " type='primary'  icon={<Shop />} >Connect Shop</Button>
          </div>

          <span><Divider >OR</Divider></span>
          <div className='flex ite justify-center'>
            <Button onClick={()=>openUploadBox("csv")}>Upload CSV</Button>
            </div> 

        </div>
        </Col>}
        { products.length > 0 && <Col span={24} className='flex flex-col'>
          <ProductsTable products={products} openUploadBox={openUploadBox} />
          </Col>}


        </Col>}
    <Modal bodyStyle={{padding:0}}  width={700} title="Add/Sync Products" visible={showUploadModal} onCancel={()=>setshowUploadModal(false)} onOk={()=>setshowUploadModal(false)} footer={null}>
    { modalOption === "all" ? <div className='flex flex-col space-y-8 mt-4 p-4'>
          <div className='flex space-x-3'>
            <div className='w-100'>
          <Input className='w-100' onChange={(e)=>setshopLink(e.target.value)} placeholder="example.myshopify.com"/>
        {error && <span className="p-2 text-xs text-pink-700">This field is required.</span>}
          </div>
          <Button onClick={connectShop} className="flex items-center justify-around rounded space-x-1 shopify_btn " type='primary'  icon={<Shop />} >Connect Shop</Button>
          </div>

          <span><Divider >OR</Divider></span>
          <div className='flex ite justify-center'>
            <Button onClick={()=>openUploadBox("csv")}>Upload CSV</Button>
            </div> 

        </div>:<>
        <div className='flex items-center flex-col space-y-4 '>
          <span className='p-4'>Download the <a href="https://hs-container.s3.ap-south-1.amazonaws.com/hashsaga-products-sample.csv" target="_blank" class="text-link" download="">Sample CSV template</a> to see an example of the format required</span>
         <div className='w-100'>
        <FileUploader callback={finishCallBack} cancelCallback={closeBox} />
        
         </div>
         
          </div></>}
    </Modal>
    </>
  )
}

export default ProductsCatalog