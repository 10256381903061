import { Button, Divider, Form, Input, notification } from 'antd';
import React from 'react'
import { useState } from 'react';
import ApiService from 'services/ApiService';

const ProfileForm = ({info}) => {
    const [passtoken, setpasstoken] = useState("second")
    const [hasError, sethasError] = useState(null)
    const [apiError, setapiError] = useState(null)
    const [isSaving, setisSaving] = useState(false);

    const [form] = Form.useForm();
    const onFinish = async(values) => {
        
        
        setisSaving(true)
        const saved  = await ApiService.PostApiCalls(
            "creator/setup/update",
            {bio:values.bioinfo,location:values.location,name:values.name,website:values.website},
            true
          );
          if (saved[0] && !saved[1]["IsSuccessful"]) {
            notification["error"]({
                message: saved[1]["ErrorMessage"],
                description:""
              });
          }
          else{
            
            notification["success"]({
                message: saved[1]["FriendlyMessage"],
                description:""
              });
            
            //   
          }
          setisSaving(false)
      };
      const onFinishFailed = (errorInfo) => {
        
      };
  return (
    <Form name="basic" form={form}
    labelCol={{
      span: 4,
    }}
    wrapperCol={{
      span: 16,
    }}
    initialValues={{
      remember: true,
      name:info.name,
      bioinfo:info.bio?info.bio:"",
      website:info.website?info.website:"",
      location:info.location?info.location:"",
    }}
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    autoComplete="off">
      {passtoken &&  <> <div className="p-4 w-100">
        <div className=" w-100">
           
        <Form.Item
      label="Display Name"
      name="name"
      rules={[
        {
          required: true,
          message: 'Name field is required!',
        }
      ]}
    ><Input  name="name" maxLength={50} placeholder="Display Name" className="p-2 rounded-md mb-1" />
        </Form.Item>
        <div className="py-2"></div>
        <Form.Item
      label="Bio"
      name="bioinfo"
      
    ><Input.TextArea  name="bioinfo"  placeholder="Bio" className="p-2 rounded-md mb-1" />
        </Form.Item>
        <div className="py-2"></div>
        <Form.Item
      label="Website"
      name="website"
      rules={[
       
      ]}
    ><Input  name="website"  placeholder="Website" className="p-2 rounded-md mb-1" />
        </Form.Item>
        <div className="py-2"></div>
        <Form.Item
      label="Location"
      name="location"
      rules={[
       
      ]}
    ><Input  name="location"  placeholder="Location" className="p-2 rounded-md mb-1" />
        </Form.Item>
        </div>
    </div>
    
    </>}
    
   
    
    <Divider className="my-2 px-0" />
    <div className="px-6 w-100 flex justify-between items-center">
       <span className="text-danger font-semibold" >{apiError}</span>
        <Button type="primary" htmlType="submit" size="large"  loading={isSaving}  className={"rounded-md bg-hs-pink border-white text-white"} >Save</Button>
        </div>
        </Form>
  )
}

export default ProfileForm