import React from "react";
import { Dropdown, Menu, message } from "antd";
import { CaretDownFill } from "react-bootstrap-icons";
import { BsLock, BsLockFill } from "react-icons/bs";
import { useTranslation } from 'react-i18next';

const NavHeader = ({ callback,activePlan }) => {
  const { t } = useTranslation();

  const handleButtonClick = (e) => {
    callback("create-post", { new: true, id: 0 });
  };

  const handleMenuClick = (e) => {
    if (e["key"] === "1") {
      callback("create-post", { new: true, id: 0 });
    } else {
      callback("create-campaign", { new: true, id: 0, e: e });
    }
  };
  const menu = (
    <Menu onClick={handleMenuClick} className="rounded-xl p-3">
      <Menu.Item key="1" className="mb-2 rounded-xl py-2 text-white bg-red-1 ">
        {t("Create Post")}
      </Menu.Item>
      <Menu.Item key="2" className="mb-2 rounded-xl py-2 " >
      <div className="items-center justify-center flex">{activePlan["code"] !== "free"?<></>:<BsLockFill size={20} color="#FFD700" className="mr-1" />}<span>
      {t("Create Campaign")}
        </span></div>
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown.Button
      overlayClassName="p-1"
      size="large"
      icon={<CaretDownFill size={20} />}
      type="Div"
      className="rounded-xl overflow-hidden bg-red-1 text-white border-0"
      trigger={["click"]}
      onClick={handleButtonClick}
      overlay={menu}
    >
              {t("Create Post")}

    </Dropdown.Button>
  );
};

export default NavHeader;
