import React from 'react'
import { Calendar,Views, momentLocalizer,Navigate } from 'react-big-calendar'
import * as dates from 'date-arithmetic'
import TimeGrid from 'react-big-calendar/lib/TimeGrid'
class CalendarDateView extends React.Component {
    render() {
        let { date } = this.props
        let range = CalendarDateView.range(date)
    
        return <TimeGrid {...this.props} range={range} eventOffset={15} />
    }
}
CalendarDateView.range = date => {
    let start = date
    let end = dates.add(start, 2, 'day')
  
    let current = start
    let range = []
  
    while (dates.lte(current, end, 'day')) {
      range.push(current)
      current = dates.add(current, 1, 'day')
    }
  
    return range
  }
  
  CalendarDateView.navigate = (date, action) => {
    switch (action) {
      case Navigate.PREVIOUS:
        return dates.add(date, -3, 'day')
  
      case Navigate.NEXT:
        return dates.add(date, 3, 'day')
  
      default:
        return date
    }
  }
  
CalendarDateView.title = date => {
    return `My awesome week: ${date.toLocaleDateString()}`
  }
export default CalendarDateView
