import React from 'react'
import home6  from 'assets/images/home-6/Illustration.png'
import {CircleFill} from "react-bootstrap-icons"
import AppSubPlans from './AppSubPlans'

const HomeSctionTwo = () => {
    const appLocale = localStorage.getItem("appLocale")
    let setDefault = "en";
    if(appLocale){
      setDefault = appLocale
    }

    const textAbout = {"en":`<p><strong>About HashSaga (based on Blockchain Technology) &ndash;</strong></p>
    <p>Vision - We aim to encapsulate spirituality, humanity, and technology together to bring prosperity, equality, and sustainability to society, the environment, and every life form in the universe</p>
    <p></p>
    <p>HashSaga is a community-based platform for Creators, Brands, and Followers. It aims to bring these three groups together so that they can benefit each other mutually. Followers will get to enjoy exclusive offers &amp; discounts as well as a lot of other activities, while Creators will be able to grow their careers and Brand will be able to sell their products.&nbsp;</p>
    <p>Also, the creators or brands can take ownership of his/her content&rsquo;s minting and they can earn money through the sale or rent feature of that content.</p>
    <p></p>
    <p>And HashSaga content is linked with 17 UN Social Sustainability Causes, so it will work for our social well-being also.</p>
    <p></p>
    <p>Soon, we are going to launch our own crypto coin and Metaverse called Gamucha.</p>
    <p>Gamucha concept (Social Value Chain) &ndash; A person, corporates, institutions, etc can walk in our Gamucha metaverse and they can donate to any social causes. That&rsquo;s why we have integrated Donate Crypto and Accept Crypto features (our business model will be to earn a basic fee on each transaction and our own Coin as a medium of donation)</p>
    <p></p>
    <p><a href="./about-us"><strong>Know more&hellip;</strong></a></p>`,
    "ja":`<p><strong>HashSaga について (ブロックチェーン技術に基づく) &ndash;</strong></p>
    <p>ビジョン - 私たちは、社会、環境、この地球上のあらゆる生命体に繁栄、平等、持続可能性をもたらすために、精神性、人間性、テクノロジーを一体化することを目指しています</p>
    <p>HashSaga は、クリエイター、ブランド、フォロワーのためのコミュニティ ベースのプラットフォームです。</p>
    <p>この 3 つのグループを統合して、相互に利益をもたらすことを目指しています。</p>
    <p>フォロワーは限定オファーや割引、その他多くのアクティビティを楽しみながら収益を得るむことができ、クリエイターはキャリアを伸ばしながら、ブランドは製品を販売することで収益を得ることができます。</p>
    <p>また、クリエイターまたはブランドは、自分のコンテンツの所有権を取得し、そのコンテンツの販売またはレンタル機能を通じて収益を得ることができます.</p>
    <p>また、HashSaga のコンテンツは、国連の 17 の社会的持続可能性の大義（SDGｓ）とリンクしているため、私たちの社会福祉の向上にも貢献します。</p>
    <p>まもなく、独自の仮想通貨とガムチャと呼ばれるメタバースをローンチする予定です。</p>
    <p>ガムチャの概念 (ソーシャル バリュー チェーン) &ndash; 個人、企業、機関などは、ガムチャのメタバースを歩き、あらゆる社会的目的に寄付することができます。そのため、私たちは Donate Crypto（寄付する）&nbsp;と Accept Crypto（寄付を受け取る）&nbsp;機能を統合しました。&nbsp;(私たちのビジネス モデルは、各トランザクションで基本料金を獲得し、寄付を媒体として独自のコインを獲得することです)。</p>
    <p><a href="./about-us"><strong>もっと知る&hellip;</strong></a></p>`};
    return (
        <div className="flex justify-around flex-col items-center min-h-screen px-0 bg-white">
            <div className="flex justify-around items-center py-4">
             {/* <div className="flex-1">
             <div dangerouslySetInnerHTML={{__html: textAbout[setDefault]}}></div>
            </div> */}
            {/* <div className="flex-1">
                <div className="text-h text-4xl tracking-wide leading-relaxed font-bold ">
                How it <span className="text-hr">works</span>?
                </div>
                <div className="flex w-auto justify-start mt-2 text-sm">
                Followers are working. Start-time: 0-3 hours. Delivery speed: 100-500 followers per hour. If you experience minor drops, don’t worry: we refill lost followers automatically, daily!
                    
                </div>
            </div> */}
            </div>
            <div className="flex-1 py-4"></div>
            <AppSubPlans />
            
        </div>
    )
}

export default HomeSctionTwo
