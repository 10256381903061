

import React from "react";
import PropTypes from "prop-types";
import Header from "components/Header";
import { Layout } from 'antd';
import OpenHeader from "components/OpenHeader";
const {   Sider, Content } = Layout;
const OpenLayout = ({ children, noNavbar, noFooter }) => {
    
    return <>
    {
      <Layout className="container w-full max-w-full min-h-screen bg-hg p-0">
      <OpenHeader noPadd={true}></OpenHeader>
      <Layout className="mt-14 p-0">
        
        <Content className="p-0 bg-hg">{children}</Content>
        
      </Layout>
      
    </Layout>
      
    }
    </>
 }
  
 OpenLayout.propTypes = {
    /**
     * Whether to display the navbar, or not.
     */
    noNavbar: PropTypes.bool,
    /**
     * Whether to display the footer, or not.
     */
    noFooter: PropTypes.bool
  };
  
  OpenLayout.defaultProps = {
    noNavbar: false,
    noFooter: false
  };
  
  export default OpenLayout;
