import React, { Component } from 'react'
import {GetDataFromJson,SlideDuration} from "./helpers"
import {GetTextGrops,GetSizes,fragmentText,getTextTextGropups,GetFullBox,fillMixedTextLines,DrawRect,DrawImage,EasingFunctions,DrawSourceText} from './common'
export default class BlackSea  extends Component {
    constructor(props) {
        super(props)    
        this.state = {
          clipId:"",
          playing:this.props.playings,resetDone:false,
          isMuted:this.props.isMuted
        }
        this.musicPlayer = React.createRef();
        this.startAnims = this.startAnims.bind(this);
        this.drawText = this.drawText.bind(this);
        this.GetImages = this.GetImages.bind(this);
      }
      componentDidUpdate(prevProps){ 
        if(prevProps.isMuted !== this.props.isMuted){
            this.setState({isMuted:this.props.isMuted})
            this.musicPlayer.current.volume = this.props.isMuted?0:0.7;
        }
                 
          if(prevProps.playings !== this.props.playings){
            if(this.state.resetDone){
               
                this.setState({resetDone: false}) 
                this.then = Date.now();
                this.interval = 1000/this.fps;
                this.delta = 45;
                this.VisalLastValue = 0;
                this.CurrentIndex = 0;
               this.currentIndex = 0;
               this.LineIndex = 0;//++;                
                this.ClipDuration = this.ClipDurations[this.currentIndex];
                this.exit = false;
                this.t = 0;
                this.y1 = 0;
                this.alpha = 0;
                this.HLIndexs = this.HLIndexsData[this.currentIndex];//
                this.Text = this.textsData[this.currentIndex];
                this.bgImage = this.AllImagesLoaded[this.currentIndex];
                this.musicPlayer.current.load();
                this.musicPlayer.current.pause();       
                this.musicPlayer.current.play();
               this.startAnims();
             }
             
                if(this.props.playings){
                    this.musicPlayer.current.play();   
                }
                else{
                    this.musicPlayer.current.pause();
                }
                this.setState({playing:this.props.playings})
              
              
          }
      }

      componentDidMount(){
        //   console.log('====================================');
        //   console.log(this.props.clips);
        //   console.log('====================================');
        this.canvas = document.getElementById('Preview-Canvas');
        this.ctx = this.canvas.getContext('2d');
        this.canvas.width = 576;
        this.canvas.height = 325;//
        this.x = (5*this.canvas.width)/100;
        this.y = parseInt((7*this.canvas.height)/100);
        this.TextYStart = this.y;
        this.TextXStart = this.y;
        this.ystart = -(this.canvas.width);
        this.xFactor = this.canvas.width;
        this.fontSizeTitle = parseInt((7*this.canvas.width)/100);
        this.textCanvas = 60;  //Percentage
        this.fontName = "Bitter, Bold"
        this.textColor = "255,255,255,1";
        this.textColorHl = "236,153,24,1";
        this.Text2 = "Your clip text is here!";
        this.Text1 = "Steven Meisel and making multiple appearances on the cover of Vogue Italia.";
        this.Text3 = "I have lived here all my life.";
        this.StartAplha = 0;
        this.padding = 0;
        this.EndAlpha = 1;
        this.alpha = 0;
        this.alpha1 = 0;
        this.alpha2 = 0;
        this.t = 0;
        this.t1 = 0;
        this.t2 = 0;
        this.now = null;;
        this.fps = 30;
        this.then = Date.now();
        this.interval = 1000/this.fps;
        this.delta = 45;
        this.From = "Left";
        this.CurremtPos = "CC";
        this.lineSpacing = (30*this.fontSizeTitle/100);
        this.HLIndexs = [];
        this.HLIndexsData = [];
        this.LineIndex = 0;
        this.PosList = {
            "BL":"left","TL":"left","CL":"left",
            "BR":"right","TR":"right","CR":"right",
            "BC":"left","TC":"left","CC":"left"
        };
        this.MaxCanvas = (this.textCanvas*this.canvas.width)/100;
        this.textAlign = this.PosList[this.CurremtPos];
        this.StartFrom = "top";
        this.StartFromX  = "left";
        this.AnimFactor = "X";
        this.AnimFactorSpeed = 0.04;
        this.y1 = 0 ;
        this.ReverseText = false;
        this.TemplateData = null;
        this.ClipDuration = 5;
        this.TottalClipDuration = 0;
        this.exit = false;
        this.TextSize = 3;
        this.currentIndex = 0;
        this.lastIndex = this.TextSize - 1;
        this.GetTextData = null;
        this.TempAllLines = null ;
        this.textsData = [];
        this.textsDataVisible = [];
        this.AllImagesLoaded = {};
        this.baseScales = [];
        this.baseScalesAdd = [];
        this.xPosPan = [];
        this.yPosPan = [];
        this.yPos= null;this.xPos= null;
        this.FullBox= null;
        this.BoxDefaultPos = "left";
        this.baseXposLeft = [];
        this.bgImage = new Image();
        this.baseWidth = 576;;//this.canvas.offsetWidth;
        this.baseHeight = 325;//this.canvas.offsetHeight;
        this.MainImages = [];
        this.VisalLastValue = 0;
        this.MainImagesAnimation = [];
        this.ClipTexttype = [];
        this.MainVisualtype = [];
        this.MainVisualIn= [];
        this.MainVisualOut = [];
        this.MainVisualMute = [];
        this.MainVisualCrop = [];
        this.MainVisualSource = [];
        this.ClipDurations = [];
        this.ClipVisualDurations = [];
        this.ClipTextPosition = null;
        this.TextVisible = true;
        this.AlltextData = null;
        this.ClipTitleState = false;
        this.ClipTitleText = "";
        this.ClipListNo = 0;
        this.HeaderBackgroundColor = "";
        this.HeaderTextColor = "";
        this.HeaderPos = "bottom";
        this.HeaderAdded = false;
        this.GetHeaderData = null;
        this.ClipTitleTextList = [];
        this.Language = "English";
        let durationsegments = SlideDuration(7,this.props.clips,false,true);
    
        for(let j = 0; j < this.props.clips.length;j++) {
            if(this.props.clips[j]["visual"] && this.props.clips[j]["visual"]["url"] !== ""){
               
                this.MainImages.push(this.props.clips[j]["visual"]["url"])
                this.ClipTexttype.push(this.props.clips[j]["cliptype"])
                if(this.props.clips[j]["cliptype"] === "text"){
                    if(this.props.clips[j]["cliptext"] && this.props.clips[j]["cliptext"].trim().length > 0){
                        this.textsDataVisible.push(true)
                        this.textsData.push(this.props.clips[j]["cliptext"].trim())
                        this.HLIndexsData.push(this.props.clips[j]["highlight_indices"]?this.props.clips[j]["highlight_indices"]:[])
                    }
                    else{
                        this.textsData.push("")
                        this.textsDataVisible.push(false)
                        this.HLIndexsData.push(this.props.clips[j]["highlight_indices"]?this.props.clips[j]["highlight_indices"]:[])
                    
                    }
                }
                else if(this.props.clips[j]["cliptype"] === "title"){
                 if(this.props.clips[j]["headertext"] && this.props.clips[j]["headertext"].trim().length > 0){
                    this.textsDataVisible.push(true)
                    this.textsData.push(this.props.clips[j]["headertext"].trim())
                    this.HLIndexsData.push(this.props.clips[j]["highlight_indices"]?this.props.clips[j]["highlight_indices"]:[])
                }else{
                    this.textsData.push("")
                    this.textsDataVisible.push(false)
                    this.HLIndexsData.push(this.props.clips[j]["highlight_indices"]?this.props.clips[j]["highlight_indices"]:[])
                
                }
             }
                const AnimSyle = "Zoom in";// this.props.clips[j]["visualsEffects"] && this.props.clips[j]["visualsEffects"] === "zoom" ? "Zoom in" :  this.props.clips[j]["visualsEffects"]
                this.MainImagesAnimation.push(AnimSyle)
                this.MainVisualtype.push("image");
                // const TempSource = this.props.clips.ClipVisualsData.visualsSource[j];
                // const SourceList = this.props.clips.ClipVisualsData.SourceDataList[j];
                // var sourceText = TempSource === "" ?  SourceList === undefined ? "" :SourceList : TempSource ;
                // if(sourceText === "undefined"){sourceText="";}
        
                this.MainVisualSource.push("");
                
                // this.MainVisualIn.push(this.props.clips.ClipVisualsData.visualsIn[j]);
                // this.MainVisualOut.push(this.props.clips.ClipVisualsData.visualsOut[j]);
                // this.MainVisualMute.push(this.props.clips.ClipVisualsData.visualsAudio[j]);
                // this.MainVisualCrop.push(this.props.clips.ClipVisualsData.VisualCropData[j]);
              }
            
        }
 
    this.ClipTitleState = false;
    this.ClipTitleText = "";
    
    if(this.ClipTitleText.trim().length === 0){
        this.ClipTitleState = false;
    }
    this.ClipListNo = 1;
    
    this.TottalClipDuration = durationsegments.finalDuration;
    
    this.ClipDurations = durationsegments.textsegmentsduration;
    this.ClipVisualDurations = durationsegments.visualsegmentsduration
    if(this.ClipDurations.length === 1 && this.ClipDurations[0] < 7){
        this.ClipDurations = [];
        this.ClipDurations.push(7);
        this.TottalClipDuration = 7;
    }
    
    this.TextSize = this.textsData.length;
    if(this.TextSize === 0){
        this.TextVisible = false;
    }
    this.lastVisualIndex = this.MainImages.length - 1;
   
    this.currentIndex = 0;
    this.lastIndex = this.TextSize - 1;
    this.clipHeader = "TextSize";//.
    this.HLIndexs = this.HLIndexsData[0];//this.props.clips.ClipText.length === 0 ? []:this.props.clips.ClipText[0]["highlight_indices"];//.
    // GetDataFromJson("https://gist-container.s3.ap-south-1.amazonaws.com/GistTemplates/Calm%20Pink/e1416feb8925e0e2bc4b4be3fd315b2f.json").then(data => {
        GetDataFromJson("https://hs-container.s3.ap-south-1.amazonaws.com/app-static/c32a6ca2081699e509799c8a203ce4c4.json").then(data => {
            this.TemplateData = data;            
            this.x = (data.BasePosition.x*this.canvas.width)/100;
            this.y = parseInt((data.BasePosition.y*this.canvas.height)/100);
            this.TextXStart = this.x;
            this.TextYStart = this.y;
            this.ystart = -(this.canvas.width);
            this.xFactor = this.canvas.width;
            this.fontSizeTitleInt = data.FontProperties.FontSize;
            this.fontSizeTitle = parseInt((data.FontProperties.FontSize*this.canvas.width)/100);
            this.textCanvas = data.FontProperties.Canvas;
            this.fontName = data.FontProperties.FontFamily; 
            if(this.Language.toLowerCase() !== "english"){
                this.fontName = data.LanguageFontProperties[this.Language.toLowerCase()].FontFamily;
            }
            this.textColor = data.FontProperties.Color;
            this.textColorHl =data.FontProperties.HighlightColor;
            this.padding = parseInt(data.FontProperties.Padding * this.fontSizeTitle / 100);
            this.CurremtPos = this.ClipTextPosition === null ? data.BasePosition.postion:this.ClipTextPosition;
            this.lineSpacing = (data.FontProperties.LineSpacing*this.fontSizeTitle/100);
            this.PosList = data.PositionList;
            this.MaxCanvas = (this.textCanvas*this.canvas.width)/100;
            this.textAlign = this.PosList[this.CurremtPos];
            this.StartFrom = data.AnimationProps.StartFromX;
            this.StartFromX  = data.AnimationProps.StartFromY;
            this.AnimFactor = data.AnimationProps.AnimationFactor;
            this.BoxColor = data.BoxProps.BoxColor;
            this.BoxLineColor = data.BoxProps.BoxLineColor;
            this.AnimFactorSpeed = data.AnimationProps.Speed;
            if(data.AnimationProps.Alpha.State === "On"){
                this.EndAlpha = data.AnimationProps.Alpha.Start;
                this.alpha = data.AnimationProps.Alpha.End;
            }
            else{
                this.EndAlpha = 1;
                this.alpha = 1;
            }
            if(this.TemplateData.FontProperties.Shadow === "yes"){
                this.ctx.shadowOffsetX = (this.TemplateData.FontProperties.ShadowX*this.fontSizeTitle)/100;
                this.ctx.shadowOffsetY = (this.TemplateData.FontProperties.ShadowY*this.fontSizeTitle)/100;        
                this.ctx.shadowBlur = (this.TemplateData.FontProperties.ShadowBlur*this.fontSizeTitle)/100;
                this.ctx.shadowColor = this.TemplateData.FontProperties.ShadowColor;
            }
            this.HeaderFontName = data.FontProperties.HeaderFontFamily;
            this.HeaderFontFile = data.FontProperties.HeaderFontFileName;
            this.HeaderFontSizeInt = data.FontProperties.HeaderFontSize;
            this.HeaderFontSizePix = parseInt((this.HeaderFontSizeInt * this.canvas.width) / 100);
            this.HeaderBackgroundColor = data.FontProperties.HeaderBackgroundColor;
            this.HeaderTextColor = data.FontProperties.HeaderFontColor;
    
            this.TextDuration = parseInt(this.Text1.length/data.ReadingSpeed);
            this.TottalClipDuration = this.TottalClipDuration < 5 ? 5:this.TottalClipDuration;
            this.ClipDuration = this.ClipDurations[this.currentIndex];
            this.Text = this.textsData[this.currentIndex];
            if(this.Language.toLowerCase() !== "english"){
                this.padding = this.padding*1.2;
            }
            const root = this;
            document.fonts.load('' + this.fontSizeTitle + 'px "' + this.fontName + '"').then(function(loaded_face) {
                document.fonts.load('14px "Bitter, Bold"').then(function(loaded_faces) {
                    if(root.ClipTitleState && root.fontName !== root.HeaderFontName){
                        document.fonts.load('' + root.HeaderFontSizePix + 'px "' + root.HeaderFontName + '"').then(function(Header) {
                            root.GetImages()
                        }).catch(function(error) {
                            console.log(error);
                            
                        });
                    }
                    else{
                        root.GetImages()
                    }
                        
                    }).catch(function(error) {
                        console.log(error);
                    });
                
                }).catch(function(error) {
                    console.log(error);
                });
        })
          .catch(error => {
            
          });
      }
      componentWillUnmount(){
        cancelAnimationFrame(this.drawText);
      }
      GetImages(){
       
        var loadedImages = 0;
        const root = this;
         var numImages = this.MainImages.length;
        
    for(var k = 0; k < this.MainImages.length; k++){
        this.AllImagesLoaded[k] = new Image();
          if(this.MainImagesAnimation[k] === "Zoom out"){
              this.baseScales.push(1.10);
              this.baseScalesAdd.push(0.10)
          }
          else if(this.MainImagesAnimation[k] === "Zoom in"){
              this.baseScales.push(1);
              this.baseScalesAdd.push(0.10);
          }
          else if(this.MainImagesAnimation[k] === "Pan left" || this.MainImagesAnimation[k] === "Pan up"){
            this.baseScales.push(1);
            this.xPosPan.push(30);
            this.baseXposLeft.push(30);
            this.yPosPan.push(-30);
            
        }
        else if(this.MainImagesAnimation[k] === "Pan right" || this.MainImagesAnimation[k] === "Pan down"){
          this.baseScales.push(1);
          this.xPosPan.push(-30);
          this.baseXposLeft.push(30);
          this.yPosPan.push(-30);
          
        }
        else{
            this.baseScales.push(1);
              this.baseScalesAdd.push(1);
        }
          //xPosPan
          
          // eslint-disable-next-line no-loop-func        
          this.AllImagesLoaded[k].onload = function() {
  
              if (++loadedImages >= numImages) {
                  // //////console.log(AllImagesLoaded);
                  root.CurrentIndex = 0;
  
                  root.bgImage = root.AllImagesLoaded[0];
                  root.musicPlayer.current.load();
                  root.musicPlayer.current.pause();       
                  root.musicPlayer.current.play();
                  root.musicPlayer.current.volume = 0.7;
                  root.startAnims();
              }
          };
         
          this.AllImagesLoaded[k].src = this.MainImages[k];
      }
    }
    startAnims(){
        // this.HLIndexs = [31,47]
        
        
        if(this.textAlign === "right"){
            this.ReverseText = true;
        }
        if(this.CurremtPos === "BL"|| this.CurremtPos === "BR"|| this.CurremtPos === "BC"){
            this.StartFrom = "bottom";
        }
        if(this.CurremtPos === "CC"|| this.CurremtPos === "TC"|| this.CurremtPos === "BC"){
            this.StartFromX = "center";
            this.AnimFactor = "Y";
            this.ystart = this.CurremtPos === "TC" ? -(this.canvas.height) : this.canvas.height;
            this.xFactor = this.CurremtPos === "TC" ? this.canvas.height : -(this.canvas.height);    
        }
        if(this.CurremtPos === "CL"|| this.CurremtPos === "CR"|| this.CurremtPos === "CC"){
            //ChangeY
            this.StartFrom = "middle";
        }
        if (this.CurremtPos === "BL" || this.CurremtPos === "CL" || this.CurremtPos === "TL") {        
            this.BoxDefaultPos = "left"
        }
        else if (this.CurremtPos === "TC" || this.CurremtPos === "CC" || this.CurremtPos === "BC") {        
            this.BoxDefaultPos = "center"
        }
        else if (this.CurremtPos === "BR" || this.CurremtPos === "TR" || this.CurremtPos === "CR") {        
            this.BoxDefaultPos = "right"
        }
        if(this.CurremtPos === "TL" || this.CurremtPos === "TC" || this.CurremtPos === "TR"){
            this.HeaderPos = "top"
        }
        if(this.ClipTexttype[this.currentIndex] === "text"){
            this.HeaderAdded = false;
            this.fontSizeTitle = parseInt((this.TemplateData.FontProperties.FontSize*this.canvas.width)/100);
            this.lineSpacing = (this.TemplateData.FontProperties.LineSpacing*this.fontSizeTitle/100);    
        }
        else{
            this.HeaderAdded = true;           
            this.fontSizeTitle = parseInt((this.TemplateData.FontProperties.FontSizeHeader*this.canvas.width)/100);
            this.lineSpacing = (this.TemplateData.FontProperties.LineSpacing*this.fontSizeTitle/100);
           
        }
        this.ctx.font = ''+this.fontSizeTitle+'px "'+this.fontName+'"';
        this.ctx.textAlign = this.textAlign;
        this.ctx.textBaseline = 'top'; 
        if(this.ClipTitleState){
            this.ctx.font = '' + this.HeaderFontSizePix + 'px "' + this.HeaderFontName + '"';            
            var HeaderLines = fragmentText(this.ClipTitleText, this.MaxCanvas,this.padding,this.ctx); 
            while (HeaderLines.length > 1) {
                this.HeaderFontSizeInt = this.HeaderFontSizeInt -1
                this.HeaderFontSizePix = parseInt((this.HeaderFontSizeInt * this.canvas.width) / 100);
                this.lineSpacing = (30 * this.HeaderFontSizePix / 100);
                this.ctx.font = '' + this.HeaderFontSizePix + 'px "' + this.HeaderFontName + '"';
                HeaderLines = fragmentText(this.ClipTitleText, this.MaxCanvas,this.padding,this.ctx);       
                
            }
            var FullBoxTextHeader = false;
            var HeaderTextCanvasHeight = GetSizes(HeaderLines,FullBoxTextHeader); 
            this.GetHeaderData = GetTextGrops(HeaderLines,this.StartFrom,this.TextYStart,this.TextYStart,HeaderTextCanvasHeight,this.StartFromX,this.canvas,this.x,this.ctx,this.lineSpacing,this.fontSizeTitle,this.padding,this.ReverseText,this.canvas.width,this.Language.toLowerCase(),FullBoxTextHeader,true); 
            
            this.ClipTitleTextList = getTextTextGropups(HeaderLines, [[]]);
            let TempRevrsedLines = [];
            if (this.ReverseText) {
                this.ClipTitleTextList.forEach(function(line) {
                    TempRevrsedLines.push(line.reverse());
                });
                this.ClipTitleTextList = TempRevrsedLines;
            }
           
            
        }
        if(this.TextVisible){        
       
        let Lines = fragmentText(this.Text,this.MaxCanvas,this.padding,this.ctx);
        while (Lines.length > 3) {
            this.fontSizeTitleInt = this.fontSizeTitleInt -1
            this.fontSizeTitle = parseInt((this.fontSizeTitleInt * this.canvas.width) / 100);
            this.lineSpacing = (30 * this.fontSizeTitle / 100);
            this.ctx.font = '' + this.fontSizeTitle + 'px "' + this.fontName + '"';
            Lines = fragmentText(this.Text,this.MaxCanvas,this.padding,this.ctx);       
            
        }
       
        
        var FullBoxText = true;
        let TextCanvasHeight = GetSizes(Lines,this.fontSizeTitle,this.lineSpacing,this.padding,FullBoxText); 
        
        this.GetTextData = GetTextGrops(Lines,this.StartFrom,this.TextYStart,this.TextYStart,TextCanvasHeight,this.StartFromX,this.canvas,this.x,this.ctx,this.lineSpacing,this.fontSizeTitle,this.padding,this.ReverseText,this.canvas.width,this.Language.toLowerCase(),FullBoxText,this.HeaderAdded); 
        this.FullBox = GetFullBox(TextCanvasHeight,this.lineSpacing,this.GetTextData,this.padding,this.TextYStart,this.HeaderAdded);
               
        this.TempAllLines = getTextTextGropups(Lines,this.HLIndexs);
        let TempRevrsedLines = [];
        if(this.ReverseText){
            this.TempAllLines.forEach(function(line) {
                TempRevrsedLines.push(line.reverse());
            });
            this.TempAllLines = TempRevrsedLines;
        }
    }
    
        requestAnimationFrame(this.drawText);
        
    }
    reset(){
       
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        var imgRatio = this.bgImage.width/this.bgImage.height;        
        var canImageWidth,canImageHeight,maskcanImageWidth,maskcanImageHeight;
        if(imgRatio > 1){
            canImageWidth = (this.baseWidth* this.baseScales[this.CurrentIndex])+(30*2);
            canImageHeight = parseInt((this.baseWidth*this.bgImage.height)/this.bgImage.width)*this.baseScales[this.CurrentIndex];
            canImageHeight = canImageHeight + (30*2);
        }
        else if (imgRatio === 1) {
            canImageHeight = (this.baseHeight* this.baseScales[this.CurrentIndex])+(30*2);
            canImageWidth = parseInt((this.baseHeight*this.bgImage.width)/this.bgImage.height)* this.baseScales[this.CurrentIndex];
            canImageHeight = canImageHeight + (30*2);
        }
        else if(imgRatio < 1){
          canImageHeight = (this.baseHeight* this.baseScales[this.CurrentIndex])+(30*2);
          canImageWidth = parseInt((this.baseHeight*this.bgImage.width)/this.bgImage.height)* this.baseScales[this.CurrentIndex];
          canImageHeight = canImageHeight + (30*2);
        }
        
       
        if(canImageHeight < this.baseHeight){
            canImageHeight = (this.baseHeight* this.baseScales[this.CurrentIndex])+(30*2);;
            canImageWidth = parseInt((this.baseHeight*this.bgImage.width)/this.bgImage.height)* this.baseScales[this.CurrentIndex];
            canImageWidth = canImageWidth + (30*2);
        }
        if(canImageHeight > (this.baseHeight*1.1)){            
            
            maskcanImageHeight = this.baseHeight;
            maskcanImageWidth = parseInt((this.baseHeight*this.bgImage.width)/this.bgImage.height);
            canImageHeight = this.baseHeight*this.baseScales[this.CurrentIndex]
            canImageWidth = parseInt((this.baseHeight*this.bgImage.width)/this.bgImage.height)* this.baseScales[this.CurrentIndex];
            // canImageWidth = canImageWidth + (30*2);
        }
        
        
        var yPos = (this.baseHeight - canImageHeight) / 2;
        var xPos = (this.baseWidth - canImageWidth) / 2;
        var myPos = (this.baseHeight - maskcanImageHeight) / 2;
        var mxPos = (this.baseWidth - maskcanImageWidth) / 2;
        
            
       
       
        // baseScales[CurrentIndex] += (scaleToAdd/duration)/30;
        if(this.MainImagesAnimation[this.CurrentIndex] === "Zoom in"){
          if(this.baseScales[this.CurrentIndex] > 1){
            // let dd = this.baseScales[this.CurrentIndex];                
            this.baseScales[this.CurrentIndex] -= (this.baseScalesAdd[this.CurrentIndex]/(this.ClipVisualDurations[this.CurrentIndex]*this.fps));
        }
        }
        else if(this.MainImagesAnimation[this.CurrentIndex] === "Zoom out"){        
            
          if(this.baseScales[this.CurrentIndex] < (1+this.baseScalesAdd[this.CurrentIndex])){
            this.baseScales[this.CurrentIndex] += (this.baseScalesAdd[this.CurrentIndex]/(this.ClipVisualDurations[this.CurrentIndex]*this.fps));
        }
        }
        else if(this.MainImagesAnimation[this.CurrentIndex] === "Pan left"){
          xPos = xPos + this.xPosPan[this.CurrentIndex];
          
          
          if(this.xPosPan[this.CurrentIndex] > -30){    
             this.xPosPan[this.CurrentIndex] -= (this.baseXposLeft[this.CurrentIndex]/(this.ClipVisualDurations[this.CurrentIndex]*this.fps));
        }
        }
        else if(this.MainImagesAnimation[this.CurrentIndex] === "Pan right"){
          xPos = xPos + this.xPosPan[this.CurrentIndex];
          if(this.xPosPan[this.CurrentIndex] < 0){    
             this.xPosPan[this.CurrentIndex] += (this.baseXposLeft[this.CurrentIndex]/(this.ClipVisualDurations[this.CurrentIndex]*this.fps));
        }
        }
        else if(this.MainImagesAnimation[this.CurrentIndex] === "Pan up"){
          yPos = yPos + this.xPosPan[this.CurrentIndex];
          if(this.xPosPan[this.CurrentIndex] > -30){    
             this.xPosPan[this.CurrentIndex] -= (this.baseXposLeft[this.CurrentIndex]/(this.ClipVisualDurations[this.CurrentIndex]*this.fps));
        }
        }
        else if(this.MainImagesAnimation[this.CurrentIndex] === "Pan down"){
          yPos = yPos + this.xPosPan[this.CurrentIndex];
          if(this.xPosPan[this.CurrentIndex] < 0){    
             this.xPosPan[this.CurrentIndex] += (this.baseXposLeft[this.CurrentIndex]/(this.ClipVisualDurations[this.CurrentIndex]*this.fps));
        }
        }
        
        
         
        this.ctx.fillStyle = 'rgba(0,0,0,1)';
        DrawRect(this.ctx,  0, 0, this.canvas.width, this.canvas.height);
        this.ctx.filter = 'blur(10px)';
        DrawImage(this.ctx, this.bgImage, -15, -15, this.canvas.width+30, this.canvas.height+30);
        this.ctx.save()
        this.ctx.fillStyle = "#FFFFFF";
        this.ctx.globalAlpha = 0.8;
        this.ctx.rect(mxPos, myPos, maskcanImageWidth, maskcanImageHeight);
        // c.stroke();
        this.ctx.clip();
        this.ctx.filter = 'blur(0px)';
        DrawImage(this.ctx,this.bgImage, xPos, yPos,canImageWidth,canImageHeight);
        this.ctx.restore();
        this.props.callback();
        this.setState({resetDone:true})
    }
    drawText(){  
        if(!this.state.playing){
            requestAnimationFrame(this.drawText);
            return false;
        }
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);      
        if(this.bgImage === undefined){
            return false;
        }
        var imgRatio = this.bgImage.width/this.bgImage.height;    
        
          
        var canImageWidth,canImageHeight,maskcanImageWidth,maskcanImageHeight;
        if(imgRatio > 1){
            canImageWidth = (this.baseWidth* this.baseScales[this.CurrentIndex])+(30*2);
            canImageHeight = parseInt((this.baseWidth*this.bgImage.height)/this.bgImage.width)*this.baseScales[this.CurrentIndex];
            canImageHeight = canImageHeight + (30*2);
        }
        else if (imgRatio === 1) {
            canImageHeight = (this.baseHeight* this.baseScales[this.CurrentIndex])+(30*2);
            canImageWidth = parseInt((this.baseHeight*this.bgImage.width)/this.bgImage.height)* this.baseScales[this.CurrentIndex];
            canImageHeight = canImageHeight + (30*2);
        }
        else if(imgRatio < 1){
          canImageHeight = (this.baseHeight* this.baseScales[this.CurrentIndex])+(30*2);
          canImageWidth = parseInt((this.baseHeight*this.bgImage.width)/this.bgImage.height)* this.baseScales[this.CurrentIndex];
          canImageHeight = canImageHeight + (30*2);
      }
     
        if(canImageHeight < this.baseHeight){         
            
            canImageHeight = (this.baseHeight* this.baseScales[this.CurrentIndex])+(30*2);;
            canImageWidth = parseInt((this.baseHeight*this.bgImage.width)/this.bgImage.height)* this.baseScales[this.CurrentIndex];
            canImageWidth = canImageWidth + (30*2);
        }
        if(canImageHeight > (this.baseHeight*1.1)){
            
            
            maskcanImageHeight = this.baseHeight;
            maskcanImageWidth = parseInt((this.baseHeight*this.bgImage.width)/this.bgImage.height);
            canImageHeight = this.baseHeight*this.baseScales[this.CurrentIndex]
            canImageWidth = parseInt((this.baseHeight*this.bgImage.width)/this.bgImage.height)* this.baseScales[this.CurrentIndex];
            // canImageWidth = canImageWidth + (30*2);
        }
        var yPos = (this.baseHeight - canImageHeight) / 2;
        var xPos = (this.baseWidth - canImageWidth) / 2;
        var myPos = (this.baseHeight - maskcanImageHeight) / 2;
        var mxPos = (this.baseWidth - maskcanImageWidth) / 2;
        if(this.MainImagesAnimation[this.CurrentIndex] === "Zoom out"){
        if(this.baseScales[this.CurrentIndex] > 1){
            this.baseScales[this.CurrentIndex] -= (this.baseScalesAdd[this.CurrentIndex]/(this.ClipVisualDurations[this.CurrentIndex]*this.fps));
        }
        }
        else if(this.MainImagesAnimation[this.CurrentIndex] === "Zoom in"){      
         if(this.baseScales[this.CurrentIndex] < (1+this.baseScalesAdd[this.CurrentIndex])){
            this.baseScales[this.CurrentIndex] += (this.baseScalesAdd[this.CurrentIndex]/(this.ClipVisualDurations[this.CurrentIndex]*this.fps));
        }
        }
        else if(this.MainImagesAnimation[this.CurrentIndex] === "Pan left"){
          xPos = xPos + this.xPosPan[this.CurrentIndex];
          if(this.xPosPan[this.CurrentIndex] > -30){    
             this.xPosPan[this.CurrentIndex] -= (this.baseXposLeft[this.CurrentIndex]/(this.ClipVisualDurations[this.CurrentIndex]*this.fps));
        }
        }
        else if(this.MainImagesAnimation[this.CurrentIndex] === "Pan right"){
          xPos = xPos + this.xPosPan[this.CurrentIndex];
          if(this.xPosPan[this.CurrentIndex] < 0){    
             this.xPosPan[this.CurrentIndex] += (this.baseXposLeft[this.CurrentIndex]/(this.ClipVisualDurations[this.CurrentIndex]*this.fps));
        }
        }
        else if(this.MainImagesAnimation[this.CurrentIndex] === "Pan up"){
          yPos = yPos + this.xPosPan[this.CurrentIndex];
          if(this.xPosPan[this.CurrentIndex] > -30){    
             this.xPosPan[this.CurrentIndex] -= (this.baseXposLeft[this.CurrentIndex]/(this.ClipVisualDurations[this.CurrentIndex]*this.fps));
        }
        }
        else if(this.MainImagesAnimation[this.CurrentIndex] === "Pan down"){
          yPos = yPos + this.xPosPan[this.CurrentIndex];
          if(this.xPosPan[this.CurrentIndex] < 0){    
             this.xPosPan[this.CurrentIndex] += (this.baseXposLeft[this.CurrentIndex]/(this.ClipVisualDurations[this.CurrentIndex]*this.fps));
        }
        }
        
        this.ctx.fillStyle = 'rgba(0,0,0,1)';
        DrawRect(this.ctx,  0, 0, this.canvas.width, this.canvas.height);
        this.ctx.filter = 'blur(10px)';
        DrawImage(this.ctx, this.bgImage, -15, -15, this.canvas.width+30, this.canvas.height+30);
        this.ctx.save()
        this.ctx.fillStyle = "#FFFFFF";
        this.ctx.globalAlpha = 0.8;
        this.ctx.rect(mxPos, myPos, maskcanImageWidth, maskcanImageHeight);
        // c.stroke();
        this.ctx.clip();
        this.ctx.filter = 'blur(0px)';
        DrawImage(this.ctx,this.bgImage, xPos, yPos,canImageWidth,canImageHeight);
        this.ctx.restore();
        let defaultFillStyle = this.ctx.fillStyle; 
        if(this.MainVisualSource[this.CurrentIndex] !== ""){
            this.ctx.filter = 'blur(0px)';
            this.ctx.textAlign = "left";
            var sourceFontSize = parseInt((this.canvas.height*1.95)/100)
            this.ctx.font = '' + sourceFontSize + 'px "Bitter, Bold"';
            // this.ctx.font = '14px "Bitter, Bold"';
            DrawSourceText(this.ctx,this.MainVisualSource[this.CurrentIndex],0,0,this.CurremtPos,this.canvas.width,this.canvas.height,sourceFontSize);
        }
        if(this.textsDataVisible[this.currentIndex]){
            this.alpha = this.alpha + EasingFunctions.easeInOutQuad(this.GetTextData[this.LineIndex]["AnimationFactor"]) * .1;
            this.ctx.filter = 'blur(0px)';
            if (this.TemplateData.FontProperties.Shadow === "yes") {
                this.ctx.shadowOffsetX = (this.TemplateData.FontProperties.ShadowX * this.fontSizeTitle) / 100;
                this.ctx.shadowOffsetY = (this.TemplateData.FontProperties.ShadowY * this.fontSizeTitle) / 100;
                this.ctx.shadowBlur = (this.TemplateData.FontProperties.ShadowBlur * this.fontSizeTitle) / 100;
                this.ctx.shadowColor = this.TemplateData.FontProperties.ShadowColor;
            }
            //Box
            this.ctx.textAlign = this.textAlign;           
            this.ctx.fillStyle = defaultFillStyle;
            this.ctx.fillStyle = 'rgba(' + this.BoxColor + ')';
            var tx = parseInt(EasingFunctions.easeInOutQuad(this.FullBox["t"]) * this.FullBox["height"]);
                if(tx > this.FullBox["height"]){
                    tx = this.FullBox["height"]
                }
    
    
    
    var bx = parseInt( (this.canvas.height - tx) / 2 );
    var BoxX = this.FullBox["x"];
    if(this.BoxDefaultPos === "center"){
        bx = (this.FullBox["height"] - tx)/2+this.FullBox["y"] ;//parseInt( (FullBox["y"] - tx) / 2 );
    }
    else if(this.BoxDefaultPos === "left"){
        bx = (this.FullBox["height"] - tx)/2+this.FullBox["y"] 
        BoxX  = BoxX + this.TextXStart;
    }
    else if(this.BoxDefaultPos === "right"){
        bx = (this.FullBox["height"] - tx)/2+this.FullBox["y"] 
        BoxX  = BoxX - this.TextXStart;
    }
    
    
    DrawRect(this.ctx, BoxX, bx,  this.FullBox["width"],tx);
    if(this.ClipTitleState){
        this.ctx.fillStyle = 'rgba(' + this.HeaderBackgroundColor + ')';
    var HboxY = this.FullBox["y"]-(this.GetHeaderData[0]["BoxHeight"]+this.padding);
    var HTextY = this.FullBox["y"]-(this.GetHeaderData[0]["BoxHeight"]-this.padding);

    if(this.HeaderPos === "top"){
        HboxY = this.FullBox["y"]+(this.FullBox["height"]+this.padding)
        HTextY = HboxY+this.GetHeaderData[0]["y"]-(this.GetHeaderData[0]["h"]+this.padding)
    }
    DrawRect(this.ctx, BoxX, HboxY,  this.GetHeaderData[0]["BoxWidth"],this.GetHeaderData[0]["BoxHeight"]);
    this.ctx.font = '' + this.HeaderFontSizePix + 'px "' + this.HeaderFontName + '"';
    this.ctx.fillStyle = 'rgba(' + this.HeaderTextColor + ')';
    var TextX = 0;
    if(this.textAlign === "right"){
        TextX = (this.GetHeaderData[0]["x"]+this.GetHeaderData[0]["TextWidth"])-(this.padding*4)
    }
    else{
        TextX = BoxX+(this.padding*2)
    }
    fillMixedTextLines(this.ctx,this.ClipTitleTextList[0], TextX, HTextY, this.ReverseText, this.canvas.width, this.textColorHl, this.alpha);
    }
    
    // DrawRect(ctx, GetHeaderData["x"], GetHeaderData["y"],  GetHeaderData["width"],GetHeaderData["height"]);
    for (var i = 0; i < this.GetTextData.length; i++) {
        
        var index = i === this.LineIndex ? this.LineIndex : i;   
             
        var TextXd = this.GetTextData[index]["x"];
        if(this.BoxDefaultPos === "left"){
            TextXd = TextXd + (this.TextXStart-this.padding);
        }
        else if(this.BoxDefaultPos === "right"){
            TextXd = TextXd + this.TextXStart;
        }
        let colorbrk = this.textColor.split(",");
        colorbrk = colorbrk[0] + "," + colorbrk[1] + "," + colorbrk[2] + "," + this.alpha
        this.ctx.fillStyle = 'rgba(' + colorbrk + ')';                
        this.ctx.font = '' + this.fontSizeTitle + 'px "' + this.fontName + '"';
        fillMixedTextLines(this.ctx, this.TempAllLines[index], TextXd, this.GetTextData[index]["y"], this.ReverseText, this.canvas.width, this.textColorHl, this.alpha);
    }
    
    if (this.GetTextData[this.LineIndex]["AnimationFactor"] < 1) {
        this.GetTextData[this.LineIndex]["AnimationFactor"] += 0.1;
    }
    
    if (this.FullBox["t"] < 1) {
        this.FullBox["t"] += 0.05; //determines speed
    }
    else{
        
        // else{
            this.ctx.fillStyle = 'rgba(' + this.BoxLineColor + ')'
        var lineWidth = parseInt(EasingFunctions.easeOutQuart(this.FullBox["LineT"]) * this.FullBox["width"]);
        if(lineWidth > this.FullBox["width"]){
            lineWidth = this.FullBox["width"]
        }
        var LineH = parseInt((this.canvas.height*.7)/100)
        var line2x = 0
        if(this.BoxDefaultPos === "center"){
            this.ctx.fillRect(this.FullBox["x"], this.FullBox["y"], lineWidth, LineH);
            line2x = (this.canvas.width - lineWidth) - this.FullBox["x"];
            
        }
        else if(this.BoxDefaultPos === "left"){
            this.ctx.fillRect(BoxX, this.FullBox["y"], lineWidth, LineH);
            line2x = ((this.FullBox["width"] - lineWidth) + BoxX);//+TextXStart;
            // line2x = line2x + TextXStart;           
            
        }
        else if(this.BoxDefaultPos === "right"){
            this.ctx.fillRect(this.FullBox["x"]- this.TextXStart, this.FullBox["y"], lineWidth, LineH);
            line2x = (this.FullBox["width"] - lineWidth) + this.FullBox["x"];
            line2x = line2x - this.TextXStart;
        }
        
        
        
        this.ctx.fillRect(line2x, (this.FullBox["height"]-LineH)+this.FullBox["y"], lineWidth, LineH);
        if (this.FullBox["LineT"] < 1) {
            if(this.FullBox["LineT"] > .2){
                this.FullBox["LineT"] += 0.01; //determines speed
            }
            else{
                this.FullBox["LineT"] += 0.05; //determines speed
            }
            
        }
    // }
        
    }
    }
        this.now = Date.now();
        this.delta = (this.now+45) - this.then;
        
        if (this.delta > this.interval) {
            let timePassed = this.delta/1000; 
           if(this.CurrentIndex !== this.lastVisualIndex){
               
                if((timePassed-this.VisalLastValue) > (this.ClipVisualDurations[this.CurrentIndex] - 0.5)){
                // this.VisalLastValue = this.ClipVisualDurations[this.CurrentIndex];
                // this.CurrentIndex++;
                   
                // this.bgImage = this.AllImagesLoaded[this.CurrentIndex];
                this.CurrentIndex = 0;  
                this.bgImage = this.AllImagesLoaded[0];
                this.reset()
                if(this.musicPlayer.current){

                    this.musicPlayer.current.pause();   
                }
                }
            }      
            if(timePassed < this.ClipDuration){
                if(timePassed > this.ClipDuration-(1/2) && this.lastIndex !== this.currentIndex && this.TemplateData.AnimationProps.Exit.State === "On"){
                    this.exit = true;
                }
                requestAnimationFrame(this.drawText);
            }
            else{
                // return false;
                // if(this.CurrentIndex !== this.lastVisualIndex){
                //     if(timePassed > this.ClipVisualDurations[this.CurrentIndex]-0.5){
                //     this.CurrentIndex++;
                //     this.bgImage = this.AllImagesLoaded[this.CurrentIndex];

                //     }
                // }
                if(this.LineIndex !== this.lastIndex){   
                if(this.ClipDuration < this.TottalClipDuration){
                    this.currentIndex++;
                    this.LineIndex = 0;//++;                
                    this.ClipDuration = this.ClipDuration+this.ClipDurations[this.currentIndex];
                    this.exit = false;
                    this.t = 0;
                    this.y1 = 0;
                    this.alpha = 0;
                    this.HLIndexs = this.HLIndexsData[this.currentIndex];//
                    this.Text = this.textsData[this.currentIndex];
                    this.bgImage = this.AllImagesLoaded[this.currentIndex];
                  
                    this.startAnims();
                   
            }
            }else{
                //
            }
            }
        }
    }
      render() {
        return (
            <div className="SlideContent-Container" id="SlideContent-Container">  
            
            <canvas className="Preview-Canvas" id="Preview-Canvas" >  
            </canvas>
            <video id="fakeVideoPlayer" src="" />
            <audio style={{display:"none"}} controls ref={this.musicPlayer} muted={this.state.isMuted}>
                   <source src={this.props.music["mp3_url"]}/>
                </audio>
            </div>
        )
      }
}