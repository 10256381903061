import React from 'react'

const ListView = ({value,name,width}) => {
    return (
        <div className="d-flex align-items-center my-2 px-2">
        <div className="cnt-list d-flex vtext "><span>{value}</span></div>
        <div className="ml-2 w-100 d-flex overflow-hidden cnt-list position-relative">
        <div className="progressbar" style={{width:"100%"}}></div>
        <div className="progressbar active" style={{width:width+"%"}}></div>
        <span className="px-2">{name}</span>
        
        </div>
        </div>
    )
}

export default ListView
