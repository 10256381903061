import React from "react";
import { Navbar, Nav, Button } from "react-bootstrap";
import { Search, Bell, ChevronCompactDown,Translate } from "react-bootstrap-icons";
import { Input, Badge, Menu, Dropdown } from "antd";
import Utils from "helper/Utils";
import { Link } from "react-router-dom";
import BluLogo from "assets/BleLogo";
import hashlogo from "../assets/images/hash-logo.png";
import Notifications from "./Notifications";

const HeaderCreator = ({noPadd,showBorder}) => {

  const logout = ()=>{
    Utils.processAuthOut()
    window.location.reload();
  }
  const changeLocale = (locale) =>{
    localStorage.setItem('appLocale',locale);

    window.location.reload();


}
  const usermenu =  (
    <Menu className="mt-0">
         <Menu.Item className="p-2 pr-4" onClick={()=>changeLocale("en")} >
      <span className="ml-3" >English</span>  
      </Menu.Item>
      <Menu.Item className="p-2 pr-4" onClick={()=>changeLocale("ja")} >
      <span className="ml-3" >Japanese</span>  
      </Menu.Item>
       <Menu.Item className="p-2 pr-4" onClick={()=>changeLocale("de")} >
      <span className="ml-3" >Deutsch</span>  
      </Menu.Item> <Menu.Item className="p-2 pr-4" onClick={()=>changeLocale("fr")} >
      <span className="ml-3" >Français</span>  
      </Menu.Item> 
       <Menu.Item className="p-2 pr-4" onClick={()=>changeLocale("hi")} >
      <span className="ml-3" >हिन्दी</span>  
      </Menu.Item>
    </Menu>
  );
  const user = Utils.getLocalInfo("userhash")

  const profileMenu = (
    <Menu className="rounded-l p-2">
      <Link to={`/${user["userName"]}`}><Menu.Item key="1" className="rounded-l py-2">My Profile</Menu.Item></Link>
      {/* <Menu.Item key="1" className="rounded-l py-2">Account Settings</Menu.Item> */}
      <Menu.Item key="2" className="rounded-l py-2">Notifications</Menu.Item>
      <Link to={`/account-settings/activities`}><Menu.Item key="4" className="rounded-l py-2">Activities</Menu.Item></Link>
      <Link to={`/account-settings/wallet`}><Menu.Item key="5" className="rounded-l py-2">Wallet</Menu.Item></Link>
      <Menu.Item onClick={logout} key="3" className="rounded-l py-2">Logout</Menu.Item>
    </Menu>
  );
  return (
    <div className={noPadd?showBorder?"fixed w-full top-0 left-0  z-40 bg-hg border-b-2 border-gray-200":"fixed w-full top-0 left-0  z-40 bg-hg border-b-0":"fixed w-full top-0 left-0 p-3 z-40 bg-hg"}>
      <Navbar
        id="headerfixed"
        expand="lg"
        className="header-bar auth  w-full flex justify-around bg-white "
      >
        <Navbar.Brand href={"/beta/creator/home"}>
        <Link to={"/creator/home"}><span className="text-h text-2xl font-semibold flex items-center space-x-2">
           {/* <BluLogo /> */}
           <img src={hashlogo} className="w-8" />
            <span className="text-h">HashSaga</span>
          </span></Link> 
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse
          id="basic-navbar-nav"
          className="justify-content-between"
        >
          <Navbar className="text-center flex justify-center flex-1 justify-content-center">
            {/* <span className="w-1/2 ">
              <Input
                type="text"
                placeholder="Search Post, Creator, video"
                className="rounded-xl focus:shadow-none"
                prefix={<Search color="#0079FF" className="mr-2" />}
              />
            </span> */}
          </Navbar>
          <Nav className="mr-right">
          {user["role"] === "creator" && user["creatorProfileStep"] === 6 &&
              <Link to="/"><Nav.Item className="pr-4"><Button
                className="rounded-xl focus:shadow-none"
                variant="outline-primary"
              >
                Follower View
              </Button></Nav.Item></Link>}
          <Link to={`/pricing`}><Nav.Item>
              <Button
                className="rounded-xl focus:shadow-none"
                variant="outline-primary"
              >
                Upgrade
              </Button>
            </Nav.Item></Link>
            <Nav.Item className="px-4 cursor-pointer">
              <Notifications user={user} />
            </Nav.Item>
            <Nav.Item className="pr-4 visible ">
              <Dropdown
                overlay={profileMenu}
                placement="bottomRight"
                trigger="click"
                className="cursor-pointer"
              >
                <div className="flex items-center space-x-2">
                  <span className="head-notificationsBox flex items-center justify-center text-white text-xl bg-blue-500">
                    {user.profilePicture ? 
                    <img alt={user.name} src={user.profilePicture } />
                      :user.name[0]
                  }
                  </span>
                  <span className="text-h font-semibold ">{user.name}</span>
                  <span className="text-h">
                    <ChevronCompactDown />
                  </span>
                </div>
              </Dropdown>
            </Nav.Item>
            <Nav.Item><Dropdown overlay={usermenu} placement="bottomRight" trigger={['click']}>
        {/* <User color="#ffffff" /> */}
        <div className='flex bg-light rounded-full items-center justify-center w-8 h-8' ><Translate /></div>
        </Dropdown> </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default HeaderCreator;
