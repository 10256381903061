import { Col, List } from 'antd'
import UserAccountInfo from 'container/creator/profilesettings/UserAccountInfo';
import UserProfile from 'container/creator/profilesettings/UserProfile';
import _ from 'lodash';
import React from 'react'
import { Link } from 'react-router-dom';

const AccountProfile = ({user,params}) => {
  const acounntInfoList =["username","email","phone","password","2fa"] ;

  return (<>
    {!params && <Col span={24} className="px-0">
      <List
          header={<div className="font-semibold px-3">Account Info</div>}
          className="bg-white mb-2 pb-0"
        >
<List.Item className="cursor-pointer p-1 px-1"> <span
                className={
                  "flex justify-between items-center w-100 p-2 hover:bg-blue-50 hover:text-blue-400"
                }
              >
             <Link className='text-hs-dark px-1 flex w-100' to={`/account-settings/profile/name`}>  <span className="flex flex-col space-y-1">
                  <span>Name</span>
                  <span className="text-xs text-gray-500">
                    {user["name"]}
                  </span>
                </span></Link> 
                {/* <ChevronRight size={20} /> */}
              </span></List.Item>
<List.Item className="cursor-pointer p-1 px-1"><span
                className={
                  "flex justify-between items-center w-100 p-2 hover:bg-blue-50 hover:text-blue-400"
                }
              >
                <Link className='text-hs-dark px-1 flex w-100' to={`/account-settings/profile/email`}><span className="flex flex-col space-y-1">
                  <span>Email</span>
                  <span className="text-xs text-gray-500">
                    {user["email"]}
                  </span>
                </span></Link>
                {/* <ChevronRight size={20} /> */}
              </span></List.Item>
<List.Item className="cursor-pointer p-1 px-1"><span
                className={
                  "flex justify-between items-center w-100 p-2 hover:bg-blue-50 hover:text-blue-400"
                }
              >
                <Link className='text-hs-dark px-1 flex w-100' to={`/account-settings/profile/phone`}><span className="flex flex-col space-y-1">
                  <span>Phone number</span>
                  <span className="text-xs text-gray-500">
                  {user["phone"] && <span className="text-xs text-gray-500">{`${user["phone"]["flag"]}(${user["phone"]["dial_code"]})`}{user["phone"]["number"]}</span>}
                  </span>
                </span></Link>
                {/* <ChevronRight size={20} /> */}
              </span></List.Item>

        </List>
        <div className='bg-gray-100 w-100 py-1'></div>
        <List
          header={<div className="font-semibold px-3">Security</div>}
          className="bg-white mb-2 pb-3"
        >
<List.Item className="cursor-pointer p-1 px-1"> <span
                className={
                  "flex justify-between items-center w-100 p-2 hover:bg-blue-50 hover:text-blue-400"
                }
              >
               <Link className='text-hs-dark px-1 flex w-100' to={`/account-settings/profile/password`}><span className="flex flex-col space-y-1">
                  <span>Password</span>
                  
                </span></Link>
                {/* <ChevronRight size={20} /> */}
              </span></List.Item>
<List.Item className="cursor-pointer p-1 px-1"><span
                className={
                  "flex justify-between items-center w-100 p-2 hover:bg-blue-50 hover:text-blue-400"
                }
              >
                <Link className='text-hs-dark px-1 flex w-100' to={`/account-settings/profile/2fa`}><span className="flex flex-col space-y-1">
                  <span>Two Step Authentication</span>
                  
                </span></Link>
                {/* <ChevronRight size={20} /> */}
              </span></List.Item>

        </List>
    </Col>}
        {
          params && params === "name" && <UserProfile hideHeader={true} />
        }
        { 
             _.includes(acounntInfoList,params) && <UserAccountInfo hideHeader={true} passtoken={null} user={user} header={params} />
         }  
    </>
  )
}

export default AccountProfile