import { Card } from 'antd'

import React from 'react'
import ContentHeader from './ContentHeader';
import ContentBody from './ContentBody';
import ContentMedia from './ContentMedia';
const { Meta } = Card;
const ContentCard = ({post,addProducts,hidetools=false}) => {
  return (
    <Card
  
    className='w-100 p-0'
    title={!hidetools ?<ContentHeader />:""}
    bodyStyle={{padding:"5px"}}
    cover={<ContentMedia post={post} />}
  >
    {/* <Meta title="Europe Street beat" description="www.instagram.com" /> */}
    <ContentBody hidetools={hidetools} post={post} callback={addProducts} />
  </Card>
  )
}

export default ContentCard