import React from "react";
import SuggestedUsers from "./feed/SuggestedUsers";


import SideTags from "./SideTags";
import { useState } from "react";
import { useEffect } from "react";
import ApiService from "services/ApiService";

const SidebarRight = ({hideusers=false,...props}) => {
  
  const [loading, setloading] = useState(false)
  const [hashTags, sethashTags] = useState([])
    const [pagination, setpagination] = useState({
        offset:0,limit:7,total:0
  })
  useEffect(() => {
    
    getList()
  
    return () => {
      
    }
  }, [])
  
 const getList = async()=>{
  setloading(true);
        const members = await ApiService.PostApiCalls("trending/hash",{...pagination,offset:0},true);
        if(members[0] && members[1]["IsSuccessful"]){
      
          sethashTags(members[1]["list"])
          }
          setloading(false);
 }
  
  return (
    
    <div className="overflow-scroll">
      {hideusers && <div className="py-10"></div>}
      <div section-1 className="rounded-xl bg-white p-2">
        <div className="text-l text-center font-bold mb-2">
        Suggested Trending #
        </div>
        <div className="py-2"></div>
        
        {hashTags.map((d, k) => (
          <SideTags key={k} tag={d} />
        ))}
      </div>
      <div className="py-3"></div>
     {!hideusers && <div section-1 className="rounded-xl bg-white p-2">
        <div className="text-l text-center font-bold mb-2">
          Suggested Creators for you
        </div>
        <div className="py-2"></div>
        
        <SuggestedUsers />
        
      </div>}
    </div>
  );
};

export default SidebarRight;
