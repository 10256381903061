import React from 'react'
import {Container,Col} from "react-bootstrap";
const TermsServices = () => {
  return (
        <Container className='mt-5'>
        
    <Col
      className="main-content mx-auto d-flex flex-column  justify-content-center align-items-center account-form"
      lg={10}      
      md={12}
      sm={12}        
              
    >
    <div>
     <p className="fz-18 fw-700"><strong>Terms and Conditions</strong></p>
<p>Terms and Conditions of&nbsp;<strong>Hash Saga</strong></p>
<p>These Terms govern</p>
<ul>
<li>the use of this Website, and,</li>
<li>any other related Agreement or legal relationship with the Owner</li>
</ul>
<p>in a legally binding way. Capitalized words are defined in the relevant dedicated section of this document.</p>
<p><br /> </p>
<p>The User must read this document carefully.</p>
{/* <p>This Website is provided by:</p> */}

<p><strong>Owner contact email:</strong>&nbsp;customer.service@hashsaga.com</p>
<p><strong>What the User should know at a glance</strong></p>
<p><strong>TERMS OF USE</strong></p>
<p>Unless otherwise specified, the terms of use detailed in this section apply generally when using this Website.</p>
<p>Single or additional conditions of use or access may apply in specific scenarios and in such cases are additionally indicated within this document.</p>
<p>By using this Website, Users confirm to meet the following requirements:</p>
<p><strong>Content on this Website</strong></p>
<p>Unless where otherwise specified or clearly recognizable, all content available on this Website is owned or provided by the Owner or its licensors.</p>
<p>The Owner undertakes its utmost effort to ensure that the content provided on this Website infringes no applicable legal provisions or third-party rights. However, it may not always be possible to achieve such a result.<br /> In such cases, without prejudice to any legal prerogatives of Users to enforce their rights, Users are kindly asked to preferably report related complaints using the contact details provided in this document.</p>
<p><strong>Access to external resources</strong></p>
<p>Through this Website Users may have access to external resources provided by third parties. Users acknowledge and accept that the Owner has no control over such resources and is therefore not responsible for their content and availability.</p>
<p>Conditions applicable to any resources provided by third parties, including those applicable to any possible grant of rights in content, result from each such third parties&rsquo; terms and conditions or, in the absence of those, applicable statutory law.</p>
<p><strong>Acceptable use</strong></p>
<p>This Website and the Service may only be used within the scope of what they are provided for, under these Terms and applicable law.</p>
<p>Users are solely responsible for making sure that their use of this Website and/or the Service violates no applicable law, regulations or third-party rights.</p>
<p><strong>Common provisions</strong></p>
<p><strong>No Waiver</strong></p>
<p>The Owner&rsquo;s failure to assert any right or provision under these Terms shall not constitute a waiver of any such right or provision. No waiver shall be considered a further or continuing waiver of such term or any other term.</p>
<p><strong>Service interruption</strong></p>
<p>To ensure the best possible service level, the Owner reserves the right to interrupt the Service for maintenance, system updates or any other changes, informing the Users appropriately.</p>
<p>Within the limits of law, the Owner may also decide to suspend or terminate the Service altogether. If the Service is terminated, the Owner will cooperate with Users to enable them to withdraw Personal Data or information in accordance with applicable law.</p>
<p>Additionally, the Service might not be available due to reasons outside the Owner&rsquo;s reasonable control, such as &ldquo;force majeure&rdquo; (eg. labor actions, infrastructural breakdowns or blackouts etc).</p>
<p><strong>Service reselling</strong></p>
<p>Users may not reproduce, duplicate, copy, sell, resell or exploit any portion of this Website and of its Service without the Owner&rsquo;s express prior written permission, granted either directly or through a legitimate reselling program.</p>
<p><strong>Intellectual property rights</strong></p>
<p>Without prejudice to any more specific provision of these Terms, any intellectual property rights, such as copyrights, trademark rights, patent rights and design rights related to this Website are the exclusive property of the Owner or its licensors and are subject to the protection granted by applicable laws or international treaties relating to intellectual property.</p>
<p>All trademarks &mdash; nominal or figurative &mdash; and all other marks, trade names, service marks, word marks, illustrations, images, or logos appearing in connection with this Website are, and remain, the exclusive property of the Owner or its licensors and are subject to the protection granted by applicable laws or international treaties related to intellectual property.</p>
<p><strong>Changes to these Terms</strong></p>
<p>The Owner reserves the right to amend or otherwise modify these Terms at any time. In such cases, the Owner will appropriately inform the User of these changes.</p>
<p>Such changes will only affect the relationship with the User for the future.</p>
<p>The continued use of the Service will signify the User&rsquo;s acceptance of the revised Terms. If Users do not wish to be bound by the changes, they must stop using the Service. Failure to accept the revised Terms, may entitle either party to terminate the Agreement.</p>
<p>The applicable previous version will govern the relationship prior to the User's acceptance. The User can obtain any previous version from the Owner.</p>
<p><strong>Assignment of contract</strong></p>
<p>The Owner reserves the right to transfer, assign, dispose of by novation, or subcontract any or all rights or obligations under these Terms, taking the User&rsquo;s legitimate interests into account. Provisions regarding changes of these Terms will apply accordingly.</p>
<p>Users may not assign or transfer their rights or obligations under these Terms in any way, without the written permission of the Owner.</p>
<p><strong>Contacts</strong></p>
<p>All communications relating to the use of this Website must be sent using the contact information stated in this document.</p>
<p><strong>Severability</strong></p>
<p>Should any provision of these Terms be deemed or become invalid or unenforceable under applicable law, the invalidity or unenforceability of such provision shall not affect the validity of the remaining provisions, which shall remain in full force and effect.</p>
<p><strong>Definitions and legal references</strong></p>
<p><strong>This Website (or this Application)</strong></p>
<p>The property that enables the provision of the Service.</p>
<p><strong>Agreement</strong></p>
<p>Any legally binding or contractual relationship between the Owner and the User, governed by these Terms.</p>
<p><strong>Owner (or We)</strong></p>
<p>Indicates the natural person(s) or legal entity that provides this Website and/or the Service to Users.</p>
<p><strong>Service</strong></p>
<p>The service provided by this Website as described in these Terms and on this Website.</p>
<p><strong>Terms</strong></p>
<p>All provisions applicable to the use of this Website and/or the Service as described in this document, including any other related documents or agreements, and as updated from time to time.</p>
<p><strong>User (or You)</strong></p>
<p>Indicates any natural person or legal entity using this Website.</p>
<p>&nbsp;</p>   
    </div></Col></Container>
)
}

export default TermsServices