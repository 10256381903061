import { Button, Col, Layout,Radio,Row, Switch } from 'antd'
import { AuthContext } from 'app/AuthProvider'
import AppPlanCard from 'container/common/AppPlanCard'
import ViewLoader from 'container/common/ViewLoader'
import AuthModal from 'container/home/components/AuthModal'
import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import { Container } from 'react-bootstrap'
import { Check } from 'react-bootstrap-icons'
import { GuardSpinner } from 'react-spinners-kit'
import ApiService from 'services/ApiService'
import { useTranslation } from 'react-i18next';


const PricingPlans = () => {
    const {authUser} = useContext(AuthContext)
    const [loading, setloading] = useState(true)
    const [plans, setplans] = useState([])
    const [activePlan, setactivePlan] = useState(null)
    const [isVisible, setisVisible] = useState(false);
    const [billingCycle, setBillingCycle] = useState('yearly');
    const [planId, setplanId] = useState(2);
    const [mtype, setmtype] = useState("wait")
    const [upgradedPlan, setupgradedPlan] = useState(null)
    const { t } = useTranslation();
    const optionsBilling = [
        {
          label: 'Monthly',
          value: 'monthly',
        },
        {
          label: 'Yearly',
          value: 'yearly',
        }
      ];
    useEffect(() => {
        getSubPlans()
    
      return () => {
        
      }
    }, [])
    const getSubPlans = async()=>{
        const planslist = await ApiService.GetApiCalls("subscriptions/list","",false)
        if(planslist[0] && planslist[1]["IsSuccessful"]){
            setplans(planslist[1]["plans"])
            if(authUser){
                setactivePlan(authUser["selectedAppPlan"])
            }
            setloading(false)
        }
    }
    const upgradePlan = (plan)=>{
        const activeOne = [1,2,3,4,5]
        if(!activeOne.includes(plan["id"])){
            setmtype("wait")
            setupgradedPlan(null)
            setplanId(plan.id)
            setisVisible(true)
        }
        else{
            setmtype("upgrade")
            setupgradedPlan(plan)
            setplanId(plan.id)
            setisVisible(true)
            
        }
        
    }
    const openWaitingForm = (id)=>{
        setplanId(id)
        setisVisible(true)
    }
    const setBillingPeriod = ({ target: { value } }) => {
        
        setBillingCycle(value);
      };
    if(loading){
        return (
          <ViewLoader />
        )
       }
      
  return (
    <>
    <Container fluid className='p-7 w-100 mx-0 '>
    <Row gutter={16} className="mx-0">

    <div className="flex-1 w-full">
            <div className="text-2xl font-semibold text-center space-x-4">{t("pricfingLine1")}</div>
            <div className="flex-1 py-4 text-center w-50 mx-auto">
                <span className='text-center text-lg w-50'>{t("pricfingLine2")}</span>
                <span className='flex flex-col items-center justify-center space-y-2 py-3 text-sm'>
                    <span className='flex items-center '><strong>Billing Cycle:</strong>&nbsp;<span class="text-hs-pink">Save 15% </span>&nbsp;<span>with yearly</span></span>
                    <span className='flex items-center space-x-2'>
                    <Radio.Group options={optionsBilling} onChange={setBillingPeriod} value={billingCycle} optionType="button"  buttonStyle="solid" className='bg-hs-pink' />
      </span>
                </span>
            </div>
            <div className="flex-1 ">
            <div className="grid grid-cols-5 gap-1 w-full ">
                {
                    plans.map((plan,key)=>
                    <AppPlanCard plan={plan} key={key} billingCycle={billingCycle} openWaitingForm={openWaitingForm} showaction={false} activePlan={activePlan} activeCallback={upgradePlan}  />
                    )
}
</div>

            </div>
            
             <div className="flex-1 py-10"></div>
            </div>
        </Row>
    </Container>
    <AuthModal
    isVisible={isVisible}
    type={mtype} planId={planId}
    isWhite={true} authUser={authUser}
    upgradedPlan={upgradedPlan}
    billingCycle={billingCycle}
    cancelCallback={() => setisVisible(false)}
    creatorCallback={() => {
      
      setisVisible(false);
    }}
  /></>
  )
}

export default PricingPlans