import React from 'react'

import "./preview.css"

const VisualStatic = ({image}) => {
    return (
        <div className="StagePreviewHolder Rectangle">
        <div className="Silde-Preview Rectangle">
            <div className=" VisualBox">
            <div className="HeroImageBox NoPadding">
            
            <div  className="BluredImage "  style={{"backgroundImage": "url("+image+")"}} ></div><div key={0} className="SlideVisuals imageType"  style={{"backgroundImage": "url("+image+")"}} ></div>
            </div>
            </div>
            </div>
        </div>
    )
}

export default VisualStatic
