
import { Col, Row, Statistic } from 'antd'
import React from 'react'
import { BadgeAd, Bookmark, CameraVideo, ChatRight, HandThumbsUp, Linkedin, MusicNoteList, Share } from 'react-bootstrap-icons'

const AccountRewards = () => {
  return (
    <Col span={24} className="p-3 h-full">
    <Row gutter={16} className="px-4">
    <Col span={24} className="text-center py-5">
     <span className='text-lg text-hs-light' >Not Applicable</span>
    </Col>
    
  </Row>
    </Col>
  )
}

export default AccountRewards