import { Button, Result, List,  Spin, Tag } from 'antd'
import Utils from 'helper/Utils'
import moment from 'moment'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ApiService from 'services/ApiService';

import ListingTools from './ListingTools'
import { useSelector } from 'react-redux'
import RequestModal from './RequestModal'



export const StreetListings = ({back,authUser,alreadyBroker}) => {
  const [data, setData] = useState([])
  const navigate = useNavigate()

  const [loading, setloading] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalDetails, setmodalDetails] = useState(null)
  
  const filterData = useSelector(
    (state) => state.webStreet.data.filterData
)

  const showModal = (item) => {
    setmodalDetails(item)
    setIsModalOpen(true);
  };
  
  const handleCancel = () => {
    setIsModalOpen(false);
    setmodalDetails(null)
  };
  useEffect(() => {
    
    getList()
    return () => {
      
    }
  }, [filterData])
  const getList = async()=>{
    setloading(true)
    
    const result = await ApiService.PostApiCalls("/street/list/i",{filters:filterData,pageIndex:1,pageSize:20,userid:authUser?authUser.uniqueId:""},false)
    if(result[0]){
      setData(result[1]["data"])
      setloading(false)
    }
    
  }
  
  const handleRequest = (e,item)=>{
    e.stopPropagation();
    if(authUser && authUser.uniqueId === item.listedBy){
      // navigate("/search/streets/property/"+item._id )
      showModal(item)
    }
    else{
      showModal(item)
    }
  }
 
  return (
    <>
    {
      loading ? 
      <div className="p-7 flex space-x-4 items-center justify-center">
        <Spin />
      </div>
      :data.length > 0?
      <div className="px-4 pb-4 mt-2 ">
        <div><ListingTools /></div>
      <List
    itemLayout="vertical"
    size="large"
    pagination={{
      onChange: (page) => {
        console.log(page);
      },
      pageSize: 20,
    }}
    
    dataSource={data}
    footer={
      <div>
        More properties available
      </div>
    }
    renderItem={(item) => (
      <List.Item
        key={item.title}
        className='bg-white rounded-md mb-3 p-3 cursor-pointer'
        onClick={(e)=>{e.preventDefault();navigate(back?back+item._id:"/search/streets/property/"+item._id)}}
      >
       <div className='flex space-x-3 flex-row w-100'>
       <img
            width={272}
            alt="logo" className='rounded-lg'
            src={item["listingImages"][0]}
          />
          <div className='flex flex-col space-y-2 flex-1'>
          <span className='font-semibold text-lg'>₹{Utils.getAmountInFormat(parseInt(item.price))} </span>
          <span><span className='font-semibold'>{`${item.BHK}`}</span>{` for ${(item.listingFor).toLowerCase()} in ${item.Locality}`}</span>
           <div className='flex flex-col space-y-2'>
            <span className='text-hs-pink-dark'>Property Highlights</span>
            <span>{item.furnishingList.split(",").map((f,i) => <Tag key={i} className='rounded' color="purple">{f}</Tag>)}</span>
            </div> 
            <div className='flex items-end flex-1 justify-between'>
              <span className='flex flex-row space-x-1'>
              <div className="h-8 w-8 rounded-full overflow-hidden shadow ">
       {item["Owner"][0].profilePicture !==""? <div
          className="h-8 w-8 bg-center bg-cover"
          style={{
            backgroundImage:
              `url("${item["Owner"][0]["appMode"] && item["Owner"][0]["appMode"] === "privacy"?'https://static.thenounproject.com/png/2910477-200.png':item["Owner"][0].profilePicture}")`,
          }}
        ></div>:<span className="head-notificationsBox flex items-center w-100 h-100 justify-center text-white text-2xl bg-blue-500">{item["Owner"][0].name[0]}</span>}
       
      </div><div className='flex flex-col'><span>{item["Owner"][0].name}</span>
      <span className=" font-normal text-xs text-gray-400">{moment.unix(item.createdAt).fromNow()}</span>
      </div>
              </span>
              
              <Button onClick={(e)=>handleRequest(e,item)} 
              className={`px-3 brd-4 fw-700 mx-2 rounded-lg text-white bg-hs-pink  hover:border-hs-pink `}>
                {authUser && item.Req && item.Req[0]?"Request Pending":"Contact Seller"}</Button>
            </div>
            </div>
       </div>
         
       
      </List.Item>
       )}
       />
       </div>
      :
      <div className="px-4 pb-4 mt-2">
        <div><ListingTools /></div>
      <Result
  status="404"
  title={"No result found!"}
  subTitle={""}
  extra={
  <div className='flex space-x-4 items-center justify-center'>
  <Link to={"/re/new-listing"}><Button type="primary">List Property</Button></Link>
  {/* <Link to={!authUser? "/creator/edit/start?redirect=streets/broker-registration":"./streets/broker-registration"}><Button type="primary">{alreadyBroker?"Broker Profile":"Broker Registration"}</Button></Link> */}
  </div>
  }
  />
   
   
   </div>
    }
   
   {/* Modal Contact */}
   {modalDetails && <RequestModal isopen={isModalOpen} alreadyBroker={alreadyBroker} callback={handleCancel} details={modalDetails} /> }
 </>
  )
}
