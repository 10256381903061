import { createSlice } from "@reduxjs/toolkit";

export const getConstants = createSlice({
	name: "constants",
	initialState: {
		walletInfo: {
			address: "",
			chainId: "",
		},
	},
	reducers: {
		setWalletInfo: (state, action) => {
			state.walletInfo = action.payload;
		},
	},
});

export const { setWalletInfo } = getConstants.actions;

export default getConstants.reducer;
