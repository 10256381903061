
import React,{useContext, useEffect, useState} from "react";
import { Navbar, Nav, Button } from "react-bootstrap";
import { Search, Bell, ChevronCompactDown, Translate } from "react-bootstrap-icons";
import { Input, Badge, Menu, Dropdown,Modal, Button as AntButton } from "antd";

import { Link, useNavigate } from "react-router-dom";
import AuthModal from "container/home/components/AuthModal";
import { useTranslation } from 'react-i18next';

import hashlogo from "../assets/images/hash-logo.png";
import { AuthContext } from "app/AuthProvider";
import BluLogo from "assets/BleLogo";
const OpenHeader = ({noPadd,hideExtraNav},props) => {
  const { t } = useTranslation();
  // const history = useHistory()
  const navigate = useNavigate()
    const {authUser} = useContext(AuthContext)
    const [isVisible, setisVisible] = useState(false);
    const [showSetup, setshowSetup] = useState(false)
    const [type, settype] = useState("follower");
    const [redirect, setfirst] = useState(false)
    const [searchTag, setsearchTag] = useState(null)
  useEffect(() => {
    
    
    return () => {
      
    }
  }, [])
  const searchon = (e) => {
    if (e.key === "Enter") {
      setsearchTag(e.target.value);
      // setredirect(true);
      
      navigate("/search/top?q=" + e.target.value);
    }
  };
 
  
  const initAuth = (type) => {
    if(authUser){
      setfirst(true);
    }else{
    settype(type);
    setisVisible(true);}
  };
  const changeLocale = (locale) =>{
    localStorage.setItem('appLocale',locale);

    window.location.reload();


}
  const usermenu =  (
    <Menu className="mt-0">
         <Menu.Item className="p-2 pr-4" onClick={()=>changeLocale("en")} >
      <span className="ml-3" >English</span>  
      </Menu.Item>
      <Menu.Item className="p-2 pr-4" onClick={()=>changeLocale("ja")} >
      <span className="ml-3" >Japanese</span>  
      </Menu.Item>
       <Menu.Item className="p-2 pr-4" onClick={()=>changeLocale("de")} >
      <span className="ml-3" >Deutsch</span>  
      </Menu.Item> <Menu.Item className="p-2 pr-4" onClick={()=>changeLocale("fr")} >
      <span className="ml-3" >Français</span>  
      </Menu.Item> 
       <Menu.Item className="p-2 pr-4" onClick={()=>changeLocale("hi")} >
      <span className="ml-3" >हिन्दी</span>  
      </Menu.Item>
    </Menu>
  );
  const usermenuAction =  (
    <Menu className="mt-0">
         <Menu.Item className="p-2 pr-4" onClick={()=>initAuth("Follower")} >
      <span className="ml-3" >Follower</span>  
      </Menu.Item>
      <Link to={"/creator/edit/start"} ><Menu.Item className="p-2 pr-4"  >
      <span className="ml-3" >Creator</span>
      </Menu.Item></Link>
       
    </Menu>
  );
  if(redirect){
    return navigate("/creator/home");//<Redirect to="/creator/home" />;
  }
  return (
    <>
    <div className={noPadd?"fixed w-full top-0 left-0  z-40 bg-hg shadow-sm":"fixed w-full top-0 left-0 p-3 z-40 bg-hg"}>
      <Navbar
        id="headerfixed"
        expand="lg"
        className="header-bar auth  w-full flex justify-around bg-white rounded-xl"
      >
        <Navbar.Brand >
         <Link to={"/"}><span className="text-h text-2xl font-semibold flex items-center space-x-2">
         {/* <BluLogo /> */}
         <img src={hashlogo} className="w-8" />
            <span className="text-h">HashSaga</span>
          </span></Link> 
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse
          id="basic-navbar-nav"
          className="justify-content-between"
        >
          <Navbar className="text-center flex justify-center flex-1 justify-content-center">
            {!hideExtraNav && <span className="w-1/2 ">
              <Input
                type="text"
                placeholder={t("home_search_placeholder")}
                className="rounded-xl focus:shadow-none"
                onKeyPress={(e) => searchon(e)}

                prefix={<Search color="#0079FF" className="mr-2" />}
              />
            </span>}
          </Navbar>
          <Nav className="mr-right space-x-3">
          <Link to={"/about-us"}><AntButton type="link" className="text-hs-dark font-semibold">{t("About Us")}</AntButton></Link>
          {/* <AntButton onClick={() => initAuth("Follower")} type="link" className="text-hs-dark font-semibold">{t("My Account")}</AntButton> */}
          <Dropdown overlay={usermenuAction} placement="bottomRight" trigger={['click']}>
        {/* <User color="#ffffff" /> */}
        <div className='flex bg-light rounded-full items-center justify-center' ><AntButton type="link" className="text-hs-dark font-semibold">{t("My Account")}</AntButton></div>
        </Dropdown> 
          {/* Disabled for now */}
          <Link to={"/accept-crypto-demo"}><AntButton  className="font-normal rounded-md text-hs-pink  bg-white border-hs-pink">{t("Accept Crypto")}</AntButton></Link>
          {/* <Link to={"/donate-crypto"}><AntButton className="font-normal text-white rounded-md bg-hs-pink border-hs-pink">{t("Donate Crypto")}</AntButton></Link> */}
          <Dropdown overlay={usermenu} placement="bottomRight" trigger={['click']}>
        {/* <User color="#ffffff" /> */}
        <div className='flex bg-light rounded-full items-center justify-center w-8 h-8' ><Translate /></div>
        </Dropdown> 
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
    <AuthModal
        isVisible={isVisible}
        type={type}
        isWhite={true}
        cancelCallback={() => setisVisible(false)}
        creatorCallback={()=> {setshowSetup(true); setisVisible(false)}}
      />
      <Modal title={""} footer={null} visible={showSetup}  closable={false} bodyStyle={{"paddingBottom":".5rem"}} maskStyle={{"backgroundColor":"#000000e3"}}>
    <p>It's fantastic to have you on board. Thank you for registering! </p>
    <p>Complete creator setup with minmum steps.</p>
    <p className="py-2 space-x-3">
    <Link  to="/creator/edit/1" >
    <Button type="primary" className="rounded-md bg-red-1 px-4 border-0">Complete Setup</Button></Link>
    <Link  to="/" ><Button className="rounded-md" >I will do later</Button></Link>
    </p>
  </Modal>
    </>
  );
};

export default OpenHeader;
