import React, { useEffect, useState, useContext } from "react";
import { Row, Col, message, List, Button, Tabs, Spin, Result,Statistic } from "antd";
import { Container } from "react-bootstrap";
import { AuthContext } from "app/AuthProvider";
import _ from "lodash";
import ApiService from "services/ApiService";
import moment from "moment";
import { ArrowBarUp, ArrowUp, Coin } from "react-bootstrap-icons";
import { AddHash } from "./creator/AddHash";
import Hashtags from "container/common/Hashtags";
import UserKeywords from "./HashMine/UserKeywords";
import KeywordsSale from "./HashMine/KeywordsSale";
import KeywordsAuction from "./HashMine/KeywordsAuction";
import KeywordsMention from "./HashMine/KeywordsMention";
import KeywordsRent from "./HashMine/KeywordsRent";
import KeywordsFractions from "./HashMine/KeywordsFractions";
const { TabPane } = Tabs;
const HashMine = () => {
  const {
    authUser
  } = useContext(AuthContext);
  const [viewLoading, setviewLoading] = useState(true);
  const [mintedHash, setmintedHash] = useState([]);
  const [draftHash, setdraftHash] = useState([]);
  
  const [loadingData, setloadingData] = useState(true);
  const [activeTab, setactiveTab] = useState(authUser.selectedAppPlan && authUser.selectedAppPlan.id === 5?"minted":"fractions");
  const [addHash, setaddHash] = useState(false)
  const [modalEvent, setmodalEvent] = useState({event:"mint",item:null})


  useEffect(() => {
    setviewLoading(false)
    return () => {};
  }, []);
  
  const changeTabs = (t) => {
    
    setactiveTab(t);
    
   
  };
  
  const setHashClose=(state=true)=>{
    // if(!state){
    //   getMinedData(activeTab);
    // }else{
    //   window.location.reload();
    // }
    setaddHash(false)
    // 
  }
  const openModel = (event,item=null)=>{
    setmodalEvent({event:event,item:item})
    setaddHash(true);
  }
  if (viewLoading) {
    return (
      <Container fluid="true" className="px-0">
        <Row className="mx-0">
          <Col
            className="px-0 py-5 mt-4 d-flex align-items-center justify-content-center loader-height"
            lg={24}
            md={24}
            sm={24}
          >
            <Spin />
          </Col>
        </Row>
      </Container>
    );
  }
  
  return (
    <>
      <Container fluid="true" className="px-0">
        {mintedHash.length === 0 && draftHash.length === 0 && (
          <Row className="mx-0">
            
          </Row>
        )}
        {/* {mintedHash.length > 0 && ( */}
          <Row className="mx-0 flex flex-col">
              <Col className="text-left px-6 flex justify-between">
                <div className="flex flex-col">
                  <h5>Hash Mine</h5>
                  <span>List of hashes minted by you with analytics around the same</span>
                  </div>
                { authUser.selectedAppPlan && authUser.selectedAppPlan.id === 5 && <Button
                  size="large"
                  onClick={(e)=>{setmodalEvent({event:"mint",item:null}); setaddHash(true)}}
                  className="px-3 float-right brd-4 fw-700 mx-2 rounded-lg text-white bg-hs-pink  hover:border-hs-pink"
                  ># Add Hash</Button>}
              </Col>
            <Col className="py-2"></Col>
            <Col className="py-0">
              <div className="text-gray-70 font-semibold text-base pl-3 opacity-60 pb-0">
                <Tabs
                  defaultActiveKey={activeTab}
                  onChange={(e) => changeTabs(e)}
                >
                  {authUser.selectedAppPlan && authUser.selectedAppPlan.id === 5 &&<><TabPane tab={<span>Your Keywords</span>} key="minted"></TabPane>
                  {/* <TabPane tab={<span>Draft</span>} key="draft"></TabPane> */}
                  <TabPane tab={<span>Rent</span>} key="rented"></TabPane>
                  <TabPane tab={<span>Sale</span>} key="sale"></TabPane>
                  <TabPane tab={<span>Auction</span>} key="auction"></TabPane>
                  <TabPane tab={<span>Mention</span>} key="mention"></TabPane>
                  </>
                  }
                  {authUser.selectedAppPlan?<>{authUser.selectedAppPlan.id !== 5 && authUser.hashTokens > 10000 && <><TabPane tab={<span>Rent</span>} key="rented"></TabPane>
                  <TabPane tab={<span>Sale</span>} key="sale"></TabPane></>}
                  {authUser.selectedAppPlan.id !== 5 && authUser.hashTokens > 4000 && <TabPane tab={<span>Auction</span>} key="auction"></TabPane>}
                  {authUser.selectedAppPlan.id !== 5 && authUser.hashTokens > 2000 && <TabPane tab={<span>Mention</span>} key="mention"></TabPane>}
                  </>:<>{authUser.hashTokens > 10000 && <><TabPane tab={<span>Rent</span>} key="rented"></TabPane>
                  <TabPane tab={<span>Sale</span>} key="sale"></TabPane></>}
                  {authUser.hashTokens > 4000 && <TabPane tab={<span>Auction</span>} key="auction"></TabPane>}
                  { authUser.hashTokens > 2000 && <TabPane tab={<span>Mention</span>} key="mention"></TabPane>}
                  </>
                  }
                  <TabPane tab={<span>Fractions</span>} key="fractions"></TabPane>
                </Tabs>
              </div >
             
            </Col>
           {
             activeTab === "minted"?
             <UserKeywords activeTab={activeTab} setmodalEvent={setmodalEvent} authUser={authUser} setaddHash={setaddHash} openModel={openModel} />
             :
             activeTab === "rented"?
             <KeywordsRent activeTab={activeTab} setmodalEvent={setmodalEvent} authUser={authUser} setaddHash={setaddHash} openModel={openModel} />
             :
             activeTab === "sale"?
             <KeywordsSale activeTab={activeTab} setmodalEvent={setmodalEvent} authUser={authUser} setaddHash={setaddHash} openModel={openModel} />
             :
             activeTab === "auction"?
             <KeywordsAuction activeTab={activeTab} setmodalEvent={setmodalEvent} authUser={authUser} setaddHash={setaddHash} openModel={openModel} />
             :
             activeTab === "mention"?
             <KeywordsMention activeTab={activeTab} setmodalEvent={setmodalEvent} authUser={authUser} setaddHash={setaddHash} openModel={openModel} />
             :
             <KeywordsFractions activeTab={activeTab} setmodalEvent={setmodalEvent} authUser={authUser} setaddHash={setaddHash} openModel={openModel} />

           }
           
            <Col className="py-3"></Col>
          </Row>
        {/* )} */}
      </Container>
      {addHash && (
        <AddHash
          event={modalEvent}
          user={authUser}
          showModalFirst={addHash}
          callback={setHashClose}
          cancelCallBack={()=>setaddHash(false)}
        />
      )}
    </>
  );
};

export default HashMine;
