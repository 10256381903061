
import React, { useEffect, useState } from 'react'
import Marquee from "react-easy-marquee";
import WallCard from './WallCard';
const DisplayWallFull = () => {
    const [wallCount, setwallCount] = useState(4)
   useEffect(() => {
    if(window.innerWidth < 700){
        setwallCount(1)
      }
      else if(window.innerWidth < 1100){
        setwallCount(3)
      }
      
   
     return () => {
       
     }
   }, [])
   
  return (
    <div className='w-100 overflow-hidden h-screen'>
        
        <Marquee className='displya-wall h-screen' duration={200000} background="#fafafa" height="650px" reverse={true}
        axis="y"

        >
        <WallCard size={wallCount} />
        
      </Marquee>
    </div>
  )
}

export default DisplayWallFull