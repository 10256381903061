import SideProfile from "components/SideProfile";
import React, { useEffect, useState } from "react";
import ApiService from "services/ApiService";

const SuggestedUsers = () => {
  const [users, setusers] = useState([]);
  
  useEffect(() => {
    getUsers();

    return () => {};
  }, []);
  const getUsers = async() => {
      const data = await ApiService.PostApiCalls("account/getCreators",{},true)
      if(data[0] && data[1]["IsSuccessful"]){
          setusers(data[1]["list"])
      }
  };
  return users.map((d, k) => <SideProfile key={k} profile={d} />);
};

export default SuggestedUsers;
