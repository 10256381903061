import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import { Button, Spin } from 'antd';
import Utils from 'helper/Utils';
import { useState } from 'react';
import ApiService from 'services/ApiService';

const CheckoutForm = ({tokens,clientSecret,callback,setprocessingCall}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [processing, setprocessing] = useState(false)

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setprocessing(true)
    setprocessingCall(true)
    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      redirect:"if_required",
      confirmParams: {
        return_url: "http://localhost:3000/creator/hashmine",
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      setprocessing(false);
    setprocessingCall(false)

      Utils.showNotification("error", "Oopps", result.error.message,3);
    } else {
        
        const data = {
            tokens:tokens,clientSecret,
            payInfo:{id:result.paymentIntent.id,status:result.paymentIntent.status,currency:result.paymentIntent.currency,created:result.paymentIntent.created,}
        }
        
        const updateTokens = await ApiService.PostApiCalls("/user/updatetransaction",data,true)
        if(updateTokens[0] && updateTokens[1]["IsSuccessful"]){
            Utils.showNotification("success", "Done", "Payment was successful",3);
            
            setprocessing(false);
            setprocessingCall(false)

            callback(tokens);
        }
        else if(updateTokens[0] && !updateTokens[1]["IsSuccessful"]){
            Utils.showNotification("error", "Oopps", updateTokens[1]["FriendlyMessage"],3);
            setprocessing(false);
    setprocessingCall(false)

        }
        else{
            Utils.showNotification("error", "Oopps", "Something went wrong, please try again.",3);
            setprocessing(false);
    setprocessingCall(false)

        }
        //UpdateUserTokens and Transaction

      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <div className='w-75 mx-auto relative'>
    {processing && <div className="h-100 w-100 flex flex-col items-center justify-center absolute bg-opacity-50 bg-gray-100 z-20">
                <Spin />
                <span className='p-3'>Please wait while we process your payment...</span>
              </div>}
    <form onSubmit={handleSubmit} className='flex flex-col'>
      <PaymentElement />
      
      <span className='p-1 mt-4 text-right'><Button size='large'  disabled={!stripe} htmlType="submit" type='primary' className='rounded-md ' >Pay Now</Button></span>
    </form>
    </div>
  )
};

export default CheckoutForm;