import { Button, Divider, Input, Form } from "antd";
import Utils from "helper/Utils";
import React, { useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { InfoCircle, PatchCheckFill, XCircle } from "react-bootstrap-icons";
import ApiService from "services/ApiService";
import { useTranslation } from 'react-i18next';

const SetupWorkspace = ({
  setupinfo,
  AddFeed,
  activeWorkSpace,
  setaddNewPost,
}) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [showModalFirst, setshowModalFirst] = useState(false);
  const [FeedURL, setFeedURL] = useState("");
  const [addingFeed, setAddingFeed] = useState(false);
  const [ModalOption, setModalOption] = useState(0);

  const openModal = (option) => {
    setModalOption(option);
    setshowModalFirst(true);
  };
  const onCreate = (values) => {
    console.log("Received values of form: ", values);
  };
  const submitFeed = async () => {
    if (ModalOption === 0) {
      form
        .validateFields()
        .then(async (values) => {
          form.resetFields();

          const postParams = {
            user: {
              brandInfo: { feedLink: values.feedurl, autoFetch: true },
              isRSSFeedAdded: true,
            },
            workSpace: {
              feedLink: values.feedurl,
              autoFetch: true,
              wid: activeWorkSpace.wid,
            },
          };
          console.log(postParams);
          setAddingFeed(true);

          const call = await ApiService.PostApiCalls(
            "creator/updateWorkspace",
            postParams,
            true
          );
          if (call[0] && call[1]["IsSuccessful"]) {
            setshowModalFirst(false);
            AddFeed(true);
            Utils.showNotification(
              "success",
              "Done",
              "Feed url updated successfully"
            );
          } else if (call[0] && !call[1]["IsSuccessful"]) {
            Utils.showNotification("error", "Oops", call[1]["ErrorMessage"]);
          } else {
            Utils.showNotification(
              "error",
              "Oops",
              "Something went wrong try again!"
            );
          }

          setAddingFeed(false);
        })
        .catch((info) => {
          console.log("Validate Failed:", info);
        });
    } else if (ModalOption === 1) {
      const postParams = {
        user: {
          brandInfo: { autoFetch: false },
          isRSSFeedAdded: false,
        },
        workSpace: {
          autoFetch: false,
          wid: activeWorkSpace.wid,
        },
      };
      console.log(postParams);
      setAddingFeed(true);

      const call = await ApiService.PostApiCalls(
        "creator/updateWorkspace",
        postParams,
        true
      );
      if (call[0] && call[1]["IsSuccessful"]) {
        setshowModalFirst(false);
        AddFeed(true);
        Utils.showNotification(
          "success",
          "Done",
          "Details updated successfully"
        );
      } else if (call[0] && !call[1]["IsSuccessful"]) {
        Utils.showNotification("error", "Oops", call[1]["ErrorMessage"]);
      } else {
        Utils.showNotification(
          "error",
          "Oops",
          "Something went wrong try again!"
        );
      }

      setAddingFeed(false);
    }
  };
  return (
    <>
      <div className="">
        <div className="text-gray-70 font-semibold text-base pl-3 opacity-60 pb-6">
          {t("Setup Progress")}
        </div>
        <div className="p-4 bg-white border-1 shadow rounded-xl">
          <div className="flex flex-row w-full items-center">
            <div className="text-hs-pink text-2xl">
              <PatchCheckFill />
            </div>
            <div className="ml-3 flex flex-col">
              <span className="text-lg font-semibold">
                {t("setup1")}
              </span>
              <span className="text-sm text-gray-400">
              {t("setup11")}
                
              </span>
            </div>
          </div>
          <Divider />

          <div className="flex flex-row w-full items-center">
            <div
              className={
                setupinfo["rssFeed"]
                  ? "text-hs-pink text-2xl"
                  : "text-gray-500 text-2xl"
              }
            >
              {setupinfo["rssFeed"] ? <PatchCheckFill /> : <InfoCircle />}
            </div>
            <div className="ml-3 flex flex-col flex-grow">
              <span className="text-lg font-semibold">
                {t("setup2")}
                
              </span>
              <span className="text-sm text-gray-400">
              {t("setup21")}
                
              </span>
            </div>
            <div>
              {!setupinfo["rssFeed"] && (
                <Button
                  onClick={() => openModal(4)}
                  className="rounded-lg shadow-lg hover:text-hs-pink hover:border-hs-pink"
                >
                  Add
                </Button>
              )}
            </div>
          </div>
          <Divider />

          <div className="flex flex-row w-full items-center">
            <div
              className={
                setupinfo["firstPost"]
                  ? "text-hs-pink text-2xl"
                  : "text-gray-500 text-2xl"
              }
            >
              {setupinfo["firstPost"] ? <PatchCheckFill /> : <InfoCircle />}
            </div>
            <div className="ml-3 flex flex-col flex-grow">
              <span className="text-lg font-semibold">
                
                {t("setup3")}
              </span>
              <span className="text-sm text-gray-400">
              {t("setup31")}
                
              </span>
            </div>
            <div>
              {!setupinfo["firstPost"] && (
                <Button
                  onClick={() => setaddNewPost(true)}
                  className="rounded-lg shadow-lg hover:text-hs-pink hover:border-hs-pink focus:border-hs-pink focus:text-hs-pink"
                >
                  Create Post
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModalFirst}
        onHide={() => setshowModalFirst(false)}
        centered
        contentClassName="campaign-new-modal rounded-2xl"
        backdrop={"static"}
      >
        <Modal.Header>
          <span className="modal-title pb-3 fz-16 ">
            {ModalOption === 0
              ? "Add New Content Source"
              : ModalOption === 1
              ? "Disable RSS Feed Autofetch"
              : ModalOption === 2
              ? "What is an RSS feed?"
              : "Auto Fetch Content Source"}
          </span>
          <XCircle
            size={20}
            className="cursor-pointer "
            onClick={() => setshowModalFirst(false)}
          />
        </Modal.Header>
        {ModalOption === 4 && (
          <Row className="mx-0 ">
            <Col className="px-0 py-3" lg={12} md={12} sm={12}>
              <div className=" p-3  d-flex flex-column ">
                <span className="fz-16 mb-2 pb-2">
                  Drip Campaigns are created automatically for each blog post
                  you publish. Hash Saga will be able to identify when you
                  publish a new blog post if you have an RSS feed (further
                  automating the process). You can still establish Drip
                  Campaigns if you don't have an RSS feed; you'll just have to
                  manually enter the URL of the blog post. If you select Auto
                  Fetch, we will attempt to automatically detect your RSS feed.
                </span>
                <div className="mt-2 d-flex">
                  <Button
                    onClick={() => openModal(0)}
                    type="primary"
                    className="px-3 brd-4 fw-700 mx-2 bg-hs-pink border-hs-pink-dark"
                  >
                    Auto Fetch
                  </Button>
                  <Button
                    onClick={() => openModal(1)}
                    className="px-3 brd-4 fw-700 mx-2 hover:text-hs-pink hover:border-hs-pink"
                  >
                    I don't have one
                  </Button>
                  <Button
                    onClick={() => openModal(2)}
                    className="px-3 brd-4 fw-700 mx-2 hover:text-hs-pink hover:border-hs-pink"
                  >
                    RSS Feed? Need help
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        )}
        {ModalOption === 0 && (
          <Modal.Body className="d-flex flex-column">
            <span className="fz-14 fw-600 pb-3">
              Select the sort of Content Source you'd like to include. Hash Saga
              will monitor the source for new content and turn it into Drip
              Campaigns once it is connected. Enter the URL of your RSS feed
              below.
            </span>

            <span className="">
              <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                initialValues={{ modifier: "public" }}
              >
                <Form.Item
                  name="feedurl"
                  label=""
                  rules={[
                    {
                      type: "url",
                      message: "Invalid url!",
                    },
                    {
                      required: true,
                      message: "Please input the url of the feed!",
                    },
                  ]}
                >
                  <Input
                    className="rounded-md focus:border-hs-pink hover:border-hs-pink"
                    placeholder="Feed url"
                  />
                </Form.Item>
              </Form>
            </span>
            <span className="py-2"></span>
          </Modal.Body>
        )}
        {ModalOption === 1 && (
          <Modal.Body className="d-flex flex-column">
            <span className="fz-14 fw-600 pb-3">
              Press the Disable Autofetch option below if you don't have an RSS
              feed or wish to setup your Drip Campaigns manually.
            </span>

            <span className="py-2"></span>
          </Modal.Body>
        )}
        {ModalOption === 2 && (
          <Modal.Body className="d-flex flex-column">
            <span className="fz-14 fw-600 pb-3">
              An RSS feed is essentially a hidden page on your website that
              displays a list of your most recent blog entries (most RSS feeds
              include the most recent 10 blog posts but some can include more).
              <br />
              <br /> An RSS feed can be thought of as a contents page for your
              blog (but designed for other computers to read).
              <br />
              <br />
              Most blogging platforms come with an RSS feed by default, so it's
              likely you already have one.
            </span>

            <span className="py-2"></span>
          </Modal.Body>
        )}
        <Modal.Footer>
          {ModalOption === 0 && (
            <>
              <span className="self-start flex-grow">
                <Button onClick={() => setModalOption(4)} type="link">
                  Back
                </Button>
              </span>
              <Button
                onClick={submitFeed}
                loading={addingFeed}
                type="primary"
                className="px-3 brd-4 fw-700 mx-2"
              >
                Add Source
              </Button>
            </>
          )}
          {ModalOption === 1 && (
            <>
              <span className="self-start flex-grow">
                <Button onClick={() => setModalOption(4)} type="link">
                  Back
                </Button>
              </span>
              <Button
                onClick={submitFeed}
                loading={addingFeed}
                type="primary"
                className="px-3 brd-4 fw-700 mx-2"
              >
                Disable Autofetch
              </Button>
            </>
          )}
          {ModalOption === 2 && (
            <>
              <span className="self-start flex-grow">
                <Button onClick={() => setModalOption(4)} type="link">
                  Back
                </Button>
              </span>
              <Button
                onClick={() => setModalOption(0)}
                type="primary"
                className="px-3 brd-4 fw-700 mx-2"
              >
                Got it!
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SetupWorkspace;
