import { Row, Col, message, Divider, Button, Input } from "antd";
import { AuthContext } from "app/AuthProvider";
import _ from "lodash";
import ProfileView from "container/profile/ProfileView";
import React, { useContext, useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import ApiService from "services/ApiService";
import SettingsLeftList from "./profilesettings/SettingsLeftList";
import { ArrowBarLeft, ArrowLeft } from "react-bootstrap-icons";
import UserProfile from "./profilesettings/UserProfile";
import UserAccount from "./profilesettings/UserAccount";
import UserSecurity from "./profilesettings/UserSecurity";
import UserNotifications from "./profilesettings/UserNotifications";
import { ToastProvider } from 'react-toast-notifications';
import UserDisplay from "./profilesettings/UserDisplay";
import UserBilling from "./profilesettings/UserBilling";
import DatingProfile from "./profilesettings/DatingProfile";
import { useParams } from "react-router-dom";

const { Search } = Input;


const Profile = (props) => {
  const {
    authUser
  } = useContext(AuthContext);

  const [profile, setprofile] = useState(null);
  const [passtoken, setpasstoken] = useState(false)
  const brPrams = useParams();
  useEffect(() => {
    
    getProfile();
    return () => {};
  }, []);

  
  const getProfile = async () => {
    
    const profileId = brPrams && brPrams["profile"]
      ? brPrams["profile"]
      : "me";
    const res = await ApiService.PostApiCalls(
      `users/${profileId}`,
      { hi: "his" },
      true
    );
    
    if (res[0]) {
      if (res[1]["IsSuccessful"]) {
        setprofile(res[1]["profile"]);
        setpasstoken(res[1]["passtoken"]);
        
        
      } else {
       
      }
    } else {
      
    }
    
  };
  
  return (
    <>
    <ToastProvider><Container className=" bg-white flex-1 h-100  px-2 py-1" fluid>
    <Row gutter={16} className="h-100">
      <Col span={8} className="mb-0 px-0 bg-white   border-l-2">
      <Col span={24} className="py-0 px-0 mb-0">
          <div className="bg-white p-3 px-3 flex space-x-3 items-center">
            <ArrowLeft size={22} /><span className="font-bold">SETTINGS</span>
          </div>
        </Col>
        <Col span={24} className="px-0  bg-white"><SettingsLeftList user={authUser} active={brPrams.action} /></Col>
        
      </Col>
      <Col span={16} className="mb-0 px-0 bg-gray-100 border-l-2">
      {brPrams.action === "profile" && <UserProfile user={authUser} />}
      {brPrams.action === "dating-profile" && <DatingProfile user={authUser} />}
      {brPrams.action === "account" && <UserAccount user={authUser} passtoken={passtoken} params={brPrams} />}
      {brPrams.action === "security" && <UserSecurity user={authUser} />}
      {brPrams.action === "notifications" && <UserNotifications user={authUser} params={brPrams} />}
      {brPrams.action === "display" && <UserDisplay user={authUser}  params={brPrams}  />}
      {brPrams.action === "subscription" && <UserBilling user={authUser}  params={brPrams}  />}
      </Col>
    </Row>
    </Container></ToastProvider>
    </>
  );
};

export default Profile;
