import React,{useEffect,useState} from 'react'
import BlackSea from './BlackSea'
import CalmPink from './CalmPink'
import {PlayCircle,PauseCircle,VolumeUp,VolumeMute} from "react-bootstrap-icons"
import "./preview.css"
const PreviewContainer = ({clips,preview,video}) => {
    const [show,setShow] = useState(false);
    const [playings,setPlayings] = useState(true);
    const [isMuted,setIsMuted] =  useState(video["musicEnabled"]?false:true);
   
    useEffect(() => {
        setShow(preview)
        return () => {
            setShow(false)
            setPlayings(false);
            
        }
    }, [preview])
   
   
    if(!show){
        return null;
    }
    
    return (
        <>
        {
            !show?"":
        <>
        <div className="StagePreviewHolder Rectangle preview">
        <div className="Silde-Preview Rectangle">
        <div className="SlideContent">
         {video["template"]["Id"] === "e1416feb8925e0e2bc4b4be3fd315b2f" &&   <CalmPink callback={(e) => setPlayings(false)} isMuted={isMuted} playings={playings} clips={clips} music={video["music"]} />}
         {video["template"]["Id"] === "c32a6ca2081699e509799c8a203ce4c4" &&   <BlackSea callback={(e) => setPlayings(false)} isMuted={isMuted} playings={playings}clips={clips} music={video["music"]} />}
        </div>
        </div>
        </div>
        <div className='d-flex justify-between'>
        {!playings && <PlayCircle className="cursor-pointer color-red ml-2" size={22} onClick={(e) => setPlayings(!playings)} />}
        {playings && <PauseCircle className="cursor-pointer color-red  ml-2" size={22} onClick={(e) => setPlayings(!playings)} />}
        {
            video["musicEnabled"]?<>
            {!isMuted && <VolumeUp className="cursor-pointer color-red float-right  ml-2" size={22} onClick={(e) => setIsMuted(!isMuted)} />}
        {isMuted && <VolumeMute className="cursor-pointer color-red float-right ml-2" size={22} onClick={(e) => setIsMuted(!isMuted)} />}</>
        :<span className="color-red float-right ml-2 fw-bold">Music is off</span>    
    }    </div>
        
        </>
         } </>
    )
}

export default PreviewContainer
