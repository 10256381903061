import React,{useState} from 'react'
import {Funnel}  from "react-bootstrap-icons"
import { Select ,DatePicker} from 'antd';
import moment from "moment";
import Utils from 'helper/Utils';
const { RangePicker } = DatePicker;
const { Option } = Select;
const AnlyticsHeader = ({userDetails,callback,wid}) => {
    const [defaultDates,setDefaultDate] = useState([moment().add(-8,"day"),moment().add(-1, 'day')])
    const [profile,setProfile] = useState("")
    const socialprofiles = Utils.getWorkSpaceSocialProfiles(userDetails,wid)
    const setAnalyticsPeriod = (e) =>{
        if(e === null){
            setDefaultDate([moment(),moment().add(7, 'day')])
        }
        else{
            // console.log(e[0]);
            setDefaultDate([e[0],e[1]])
        }
        callback("date",[e[0].unix(),e[1].unix()],profile["source"],null,profile["id"])
    }
    const handleChange = (value) => {
        // console.log(value.split("_")[1]);
        if(value === "all"){
            setProfile("all")
        callback("profile",value,null,[defaultDates[0].unix(),defaultDates[1].unix()]) 
        }
        else{
            setProfile(socialprofiles[value])
        callback("profile",socialprofiles[value]["source"],socialprofiles[value]["id"],[defaultDates[0].unix(),defaultDates[1].unix()]) 
        }
               
    } 
    const filterChange = () =>{
        callback("custom","filter") 
    }  
    return (
        <div>
        {/* <div onClick={filterChange} className="mx-2 float-right pt-1 text-center cursor-pointer">
        <Funnel size={20} /></div> */}
         <div className="mx-2 float-right">   
            <Select defaultValue="all" onChange={handleChange} style={{ width: 200 }}>
      <Option value="all">All social profiles</Option>
      {
        socialprofiles.map((profile, index) =>(
            <Option key={index} value={index} >{`${profile.name} - ${profile.source}`} </Option>
        ))
      }
    </Select></div>
    <div className="mx-2 float-right">
    <RangePicker allowClear={false} format="DD/MM/YYYY" onChange={setAnalyticsPeriod} value={defaultDates} defaultValue={defaultDates} /> </div>
        
        </div>
    )
}

export default AnlyticsHeader
