import { Row, Col, message, Divider, Button } from "antd";
import { AuthContext } from "app/AuthProvider";
import _ from "lodash";

import React, { useContext, useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import ApiService from "services/ApiService";
import AddNewCampaign from "./creator/AddNewCampaign";
import AddNewPost from "./creator/AddNewPost";

import AnalyticsView from "./creator/AnalyticsView";
import CampaignPostCard from "./creator/CampaignPostCard";
import CreateNewCampaign from "./creator/CreateNewCampaign";
import DashboardListView from "./creator/DashboardListView";
import NavHeader from "./creator/NavHeader";
import RecentPosts from "./creator/RecentPosts";
import SetupWorkspace from "./creator/SetupWorkspace";
import { useTranslation } from 'react-i18next';
import NewPost from "./creator/NewPost";

const Dashboard = () => {
  const { t } = useTranslation();

  const {
    authUser,
    activeWorkSpace,
    setupCompleted,
    setsetupCompleted,
  } = useContext(AuthContext);
  const [showpost, setShowPost] = useState(false);

  const [showsetup, setshowsetup] = useState(true);
  const [addNewPost, setaddNewPost] = useState(false);
  const [addCampaign, setaddCampaign] = useState(false);
  const callback = (action, data) => {
    if (action === "create-post") {
      setaddNewPost(true);
      // setShowPost(true)
    }
    else{
      setaddCampaign(true)
    }
  };

  useEffect(() => {
    
    const checkOne = _.filter(setupCompleted, function(item) {
      return item === true;
    });
    setshowsetup(checkOne.length === 0 ? false : true);
    return () => {};
  }, [setupCompleted]);

  const AddFeed = () => {
    setsetupCompleted({
      socialProfile: true,
      rssFeed: true,
      firstPost: false,
    });
  };
  
  
  return (
    <>
      <div className="p-0">
        <Container className="p-0">
          <Row className="flex flex-col">
            <Col className="text-right px-6">
              <NavHeader callback={callback} activePlan={authUser.selectedAppPlan}/>
            </Col>
            <Col className="grid grid-flow-row grid-cols-2 space-x-3 pt-4">
              {showsetup && (
                <SetupWorkspace
                  activeWorkSpace={activeWorkSpace}
                  setupinfo={setupCompleted}
                  AddFeed={AddFeed}
                  setaddNewPost={setaddNewPost}
                />
              )}
              {!showsetup && <AnalyticsView />}
              <div className="flex flex-col">
                <div className="text-gray-70 font-semibold text-base pl-3 opacity-60 pb-6">
                {t("Recent Posts")}
                  
                </div>
                <RecentPosts np={t("No Posts")} activeWorkSpace={activeWorkSpace} />
              </div>
            </Col>
            <Col className="py-3"></Col>
            <Col>
              <div className="text-gray-70 font-semibold text-base pl-3 opacity-60 pb-6">
                {/* {t("Upcoming Posts")} */}
                Active Campaigns
              </div>
              
              <DashboardListView activeWorkSpace={activeWorkSpace} type={0}/>
              
            </Col>
            <Col className="py-3"></Col>
            <Col>
              <div className="text-gray-70 font-semibold text-base pl-3 opacity-60 pb-6">
                {t("Draft Campaigns")}
              </div>
              
                <DashboardListView activeWorkSpace={activeWorkSpace} type={1} rv={t("Review")} />
              
            </Col>
          </Row>
        </Container>
      </div>
      {addNewPost && (
        <AddNewPost
          profiles={authUser["socialchannels"]}
          user={authUser}
          showModalFirst={addNewPost}
          callback={setaddNewPost}
        />
      )}
      {addCampaign && (
        <CreateNewCampaign
          profiles={authUser["socialchannels"]}
          user={authUser}
          showModalFirst={addCampaign}
          callback={setaddCampaign}
        />
      )}
       {
          showpost && <NewPost activeWorkSpace={activeWorkSpace} show={showpost} callback={setShowPost} profiles={authUser["socialchannels"]}
          authuser={authUser} />
        }
    </>
  );
};

export default Dashboard;
