import React from "react";
import { Plus } from "react-bootstrap-icons";

const   SideProfile = ({profile}) => {
  return (
    <a href={`/${profile.userName}`}><div list-1 className="flex items-center px-2 mb-3 cursor-pointer">
      <div image className="rounded-md h-12 w-12 overflow-hidden bg-blue-900 text-white flex items-center justify-center">
      {profile.profilePicture ? 
                    <img alt={profile.name} src={profile.profilePicture } />
                      :<span className="font-semibold text-lg">{profile.profileDisplayName[0]}</span>
                  }
        {/* <div
          className="h-12 w-12 bg-cover bg-center"
          style={{
            backgroundImage:
              `url("${profile.profilePicture}")`,
          }}
        ></div> */}
      </div>
      <span className="pl-2 ml-2 text-ht text-sm flex-grow flex flex-col">
        <span>{profile.profileDisplayName}</span>
        <span className="text-xs opacity-60">{profile.creatorSubcategoryText?profile.creatorSubcategoryText:profile.creatorCategoryText}</span>
      </span>
      <span className="opacity-60 cursor-pointer">
        <Plus size={30} />
      </span>
    </div></a>
  );
};

export default SideProfile;
