import React from 'react'

const AnalyticsView = () => {
    
    return (
        <>
        
        <div className="flex flex-col">
          <div className="text-gray-70 font-semibold text-base pl-3 opacity-60 pb-6">
          Performance Analytics
          </div>
          <div className="p-4 bg-white border-1 shadow rounded-xl h-full d-block">
              
              <span className="text-gray-400 font-normal center text-xl">No data yet!</span>
            </div>
        </div></>
    )
}

export default AnalyticsView
