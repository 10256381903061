function fromTimeToSeconds(time){
    let seconds = 0;
    if(time === null){
        return seconds;
    }
    if(time === undefined){
      return seconds;
  }
    var a = time.split(':'); 
    seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]); 
    return seconds;
}
function GetDataFromJson(jsonPath) {
    let path = jsonPath.replace("https://gist-assets.s3.ap-southeast-1.amazonaws.com", 'https://gist-container.s3.ap-south-1.amazonaws.com')
     path = jsonPath.replace("https://gist-assets.s3-ap-southeast-1.amazonaws.com", 'https://gist-container.s3.ap-south-1.amazonaws.com')
    return new Promise((resolve, reject) =>{
        fetch(path)
          .then((response) => response.json())
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
  }

  function SlideDuration(min=7,clips,isFootage,textStay){
    let finalDuration = 0;
    let textsegmentsduration = [];
    let visualsegmentsduration = [];  
    
    
    if(clips.length === 0){
        finalDuration = min;
        return{finalDuration,textsegmentsduration,visualsegmentsduration}
    }
    else{
            for(let k = 0; k < clips.length; k++){
                if(clips[k]["visual"] && clips[k]["visual"]["url"] !== ""){
           if(clips[k]["cliptext"]){
            let data = clips[k];
            const len = data["cliptext"] ? data["cliptext"].length:1;
            let dur = Math.ceil((len/15));
            if(dur < 7){
                dur = 7
            }
            finalDuration = finalDuration+dur;
            textsegmentsduration.push(dur);   
            }
            else{
                const dur = 5;
                finalDuration = finalDuration+dur;
                textsegmentsduration.push(dur);   
            }  
        }
        }
    }
    // else{
    //     //checkOthers Calculation for text
    //     //console.log(TextObjects);
        
    
    //     let validVisuals = [];
    //     ImageObjects["visuals"].forEach(function(data){
    //         if(data !== ""){
    //             validVisuals.push(data);
    //         }     
    //     })
        let totalVisualDuration = 0;
        
        
        
            let vdur = finalDuration;            
            visualsegmentsduration.push(vdur);           
            totalVisualDuration = totalVisualDuration + vdur;
        
               
        if(finalDuration < totalVisualDuration){finalDuration = totalVisualDuration}
        return{finalDuration,textsegmentsduration,visualsegmentsduration}
    
}
export {fromTimeToSeconds,GetDataFromJson,SlideDuration}