import { Col,Button ,Table,Modal, Switch, message, Avatar} from 'antd'
import React from 'react'
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { useEffect } from 'react'
import { useState } from 'react'
import { BoxArrowUpRight, ExclamationCircle, Facebook, Instagram, List, Pinterest, Plus, Share, Trash, Trash2, Trash2Fill, Twitter, Youtube } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'
import ApiService from 'services/ApiService'
const { confirm } = Modal;
const ManageFeed = () => {
  const [loading, setloading] = useState(true)
  const [feedlist, setfeedlist] = useState([])
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  useEffect(() => {
    
    getFeed()
  
    return () => {
      
    }
  }, [])
  const getFeed = async()=>{
    const content = await ApiService.PostApiCalls("/commerce/feed/list",{},true)
    if (content[0] && !content[1]["IsSuccessful"]) {
      setloading(false)
      
    }
    else{
      setloading(false)
      setfeedlist(content[1]["feed"])
    }
  }
  const renderIcon = (source) => {
  if(source.toLowerCase() === "instagram"){
    return (<Instagram color={"#fa7e1e"} size={24} />)
  }
  else if(source.toLowerCase() === "facebook"){
    return (<Facebook color={"#3b5999"} size={24} />)
  }
  else if(source.toLowerCase() === "pinterest"){
    return (<Pinterest color={"#E60023"} size={24}/>)
  }
  else if(source.toLowerCase() === "twitter"){
    return (<Twitter color={"#55acee"} size={24}/>)
  }
  else if(source.toLowerCase() === "youtube"){
    return (<Youtube color={"#CD201F"} size={24}/>)
  }
  else if(source.toLowerCase() === "minted"){
    return (<Avatar src={"https://hashsaga.com/static/media/hash-logo.098fff50.png"} size={24}/>)
  }
  else if(source.toLowerCase() === "blume"){
    return (<Avatar src={"https://hashsaga.com/static/media/hash-logo.098fff50.png"} size={24}/>)
  }
  }
  const renderTitle = (source,record) => {
    if(source.toLowerCase() === "instagram"){
      if(record.feedtype === "Hashtag"){
        return (<div className='flex items-center space-x-1'><span>#{record.searchquery}</span><a href={`https://www.instagram.com/explore/tags/${record.searchquery}/`} target='_blank'><BoxArrowUpRight /></a></div>)
      }
      else 
      if(record.feedtype === "Handle"){
        return (<div className='flex items-center space-x-1'><span>@{record.searchquery}</span><a href={`https://www.instagram.com/${record.searchquery}`} target='_blank'><BoxArrowUpRight /></a></div>)
      }
      return (<div></div>)
    }
    else if(source.toLowerCase() === "facebook"){
      return (<div className='flex items-center space-x-1'><span>{record.feedtype}</span><a href={`https://www.facebook.com/${record.profile_id}`} target='_blank'><BoxArrowUpRight /></a></div>)
    }
    else if(source.toLowerCase() === "pinterest"){
      if(record.feedtype === "Handle"){
        return (<div className='flex items-center space-x-1'><span>@{record.searchquery}</span><a href={`https://in.pinterest.com/${record.searchquery}`} target='_blank'><BoxArrowUpRight /></a></div>)
      }
      else if(record.feedtype === "User Board"){
        return (<div className='flex items-center space-x-1'><span>User Board</span><a href={`${record.searchquery}`} target='_blank'><BoxArrowUpRight /></a></div>)
      }
      
    }
    else if(source.toLowerCase() === "twitter"){
      if(record.feedtype === "Handle"){
        return (<div className='flex items-center space-x-1'><span>@{record.searchquery}</span><a href={`https://x.com/${record.searchquery}`} target='_blank'><BoxArrowUpRight /></a></div>)
      }
      else if(record.feedtype === "Mention"){
        return (<div className='flex items-center space-x-1'><span>@{record.searchquery}</span><a href={`https://twitter.com/search?f=user&q=(%40${record.searchquery})`} target='_blank'><BoxArrowUpRight /></a></div>)
      }
      else if(record.feedtype === "Hashtag"){
        return (<div className='flex items-center space-x-1'><span>#{record.searchquery}</span><a href={`https://twitter.com/search?q=${record.searchquery}`} target='_blank'><BoxArrowUpRight /></a></div>)
      }
    }
    else if(source.toLowerCase() === "youtube"){
      if(record.feedtype === "Channel Url"){
        return (<div className='flex items-center space-x-1'><span>@{record.channelInfo.title}</span><a href={`${record.searchquery}`} target='_blank'><BoxArrowUpRight /></a></div>)
      }
      else if(record.feedtype === "Shorts"){
        return (<div className='flex items-center space-x-1'><span>@{record.channelInfo.title}</span><a href={`${record.searchquery}`} target='_blank'><BoxArrowUpRight /></a></div>)
      }
      else if(record.feedtype === "Play List"){
        return (<div className='flex items-center space-x-1'><List /><span>{record.channelInfo.title}</span><a href={`${record.searchquery}`} target='_blank'><BoxArrowUpRight /></a></div>)
      }
      else if(record.feedtype === "Keywords"){
        return (<div className='flex items-center space-x-1'><span>{record.searchquery} - Keywords</span><a href={`https://www.youtube.com/results?search_query=${record.searchquery}`} target='_blank'><BoxArrowUpRight /></a></div>)
      }
    }
    else if(source.toLowerCase() === "minted"){
      
        return (<div className='flex items-center space-x-1'><span>{record.searchquery} - Keywords</span></div>)
      
    }
    else if(source.toLowerCase() === "blume"){
      
      return (<div className='flex items-center space-x-1'><span>{record.searchquery} - Keywords</span></div>)
    
  }
    return (source)
    }
    const handelApprovalChange = async(id,state) =>{
      message.loading('Action in progress..', async() => {
        const content = await ApiService.PostApiCalls("/commerce/feed/update",{id:id,update:{approval:state}},true)
        if (content[0] && !content[1]["IsSuccessful"]) {
            message.error(content[1]["message"]);
        }
        else {
          message.success(content[1]["message"]);
        }
      });
      
    }
    const handelStatusChange = async(id,state) =>{
      
      message.loading('Action in progress..', async() => {
        const content = await ApiService.PostApiCalls("/commerce/feed/update",{id:id,update:{status:state?"active":"inactive"}},true)
        if (content[0] && !content[1]["IsSuccessful"]) {
            message.error(content[1]["message"]);
        }
        else {
          message.success(content[1]["message"]);
        }
      });
  
    }
    const handelDelete = async(id) =>{
      confirm({
        title: 'Do you want to delete this feed?',
        icon: <ExclamationCircleOutlined />,
        content: 'All the posts collcted for this feed will be removed.',
        async onOk() {
          const content = await ApiService.PostApiCalls("/commerce/feed/delete",{id:id},true)
            if (content[0] && !content[1]["IsSuccessful"]) {
                message.error(content[1]["message"]);
                
            }
            else {
              message.success(content[1]["message"]);
              getFeed()
            }
        },
        onCancel() {},
      });
    }
  const columns = [
    {
      title: 'Name',
      dataIndex: 'searchquery',
      key: 'searchquery',
      render: (_, record)  => <div className='flex space-x-2 items-center'>
        <div className='mr-1'>{renderIcon(record.feedsource)}</div>
        <div className='flex flex-col space-y-1 text-sm'>
          <span className='font-semibold'>{renderTitle(record.feedsource,record)}</span>
          <span className='capitalize text-muted text-xs' >{record.feedsource==="facebook"?record["selectedFeedProfile"]["name"]:record.feedsource}</span>

        </div>

      </div>,
    },
    {
      title: 'Account',
      dataIndex: 'account',
      key: 'account',
    },
    {
      title: 'Moderation',
      dataIndex: 'approval',
      key: 'approval',
      render: (_, record)  => <Switch defaultChecked={record.approval} onChange={(e) => handelApprovalChange(record.feed_id,e)} />
    },
    {
      title: 'Posts',
      dataIndex: 'posts',
      key: 'posts',
      render: (_, record)  => <span>0</span>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, record)  => <Switch defaultChecked={record.status === "active"?true:false} onChange={(e) => handelStatusChange(record.feed_id,e)} />
    },
    {
      title: 'Action',
      dataIndex: 'feed_id',
      key: 'feed_id',
      render: (_, record)  => <Button shape="circle" type="text" onClick={(e) => handelDelete(record.feed_id)}  className='flex items-center justify-center text-pink-600' icon={<Trash />} />
    },
    
  ];
  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };
  
  return (
    <>
    <Col span={24} className="py-0 px-0 mb-0">
        <div className="bg-white p-3 px-3 flex space-x-3 items-center justify-between">
          <span className="font-bold">{"Manage Feed"}</span>
          <Button
             
            type="primary"
            className=" rounded  float-right mr-3"
            
          >
            
          </Button>
        </div>
      </Col>
      <div className="pb-2 bg-gray-100"></div>
      <Col span={24} className="p-3 mb-0 ">
      <div
        style={{
          marginBottom: 16,
        }}
        className='flex justify-between items-center'
      >
         <span>Feed List</span>
        <Link to="./add-feed"><Button className="flex items-center justify-around rounded space-x-1" type='primary'  icon={<Plus size={24} />} >Add Feed</Button></Link>
      </div>
      <Table columns={columns} dataSource={feedlist} pagination={tableParams.pagination} onChange={handleTableChange} />
      </Col>
    </>
  )
}

export default ManageFeed