import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Form, Input, DatePicker, Modal,Select,message, List } from 'antd'
import moment from 'moment';
import ApiService from 'services/ApiService';
const { Option } = Select;
const SiteVistiModal = ({alreadyBroker,ownListing,isopen,details,callback}) => {
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(isopen);
    const [modalDetails, setmodalDetails] = useState(details)
    const [sending, setsending] = useState(false)
    const [addNew, setaddNew] = useState(false)
    const [listVisits, setListVisits] = useState([])
    useEffect(() => {
      
        getSiteVists()
      return () => {
        
      }
    }, [isModalOpen])
    const getSiteVists = async()=>{
        const visits = await ApiService.PostApiCalls("steert/list-sitevisit",{id:details._id,listedBy:ownListing},true)
        if(visits[0] && visits[1]["IsSuccessful"]){
            setListVisits(visits[1]["list"])
        }
    }
    const ApproveReq = async(id)=>{
        const visits = await ApiService.PostApiCalls("steert/approve-sitevisit",{id:id},true)
        if(visits[0] && visits[1]["IsSuccessful"]){
            getSiteVists()
        }
    }
    const handleOk = () => {
     
        setIsModalOpen(false);
        callback()
       
      };
      const handleCancel = () => {
        setIsModalOpen(false);
        
        callback()
      };
      const onFinish = async(values)=>{
          
          const payload = {
              visitorName:values.name,
              visitDate:values.visitDate.unix(),
              visitTime:values.vistiTime,
              visitString: `${values.vistiTime} on ${moment.unix(values.visitDate.unix()).format("Do MMMM")}`,
              scheduledby:{name:alreadyBroker.fullname,userId:alreadyBroker.userId,phone:alreadyBroker.phone},
              listingId:details["_id"],
              listedBy:details["listedBy"]
            }
            
            const added = await ApiService.PostApiCalls("steert/broker/add-sitevisit",payload,true)
            if(added[0] && added[1]["IsSuccessful"]){
                message.success("Site visit scheduled successfully")
                form.resetFields()
                handleCancel()
            }

      }
  return (
    <>
    <Modal bodyStyle={{padding:0}} footer={null} title="Manage Site Visit" visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
    {addNew ?<Form
        name="normal_login"
        className="login"
        initialValues={{
          
          layout: "vertical",
          name:"",visitDate:"",vistiTime:""
          
        }}
        layout={"vertical"}
        onFinish={onFinish}
        >
        <div  className='p-4'>
          
           <Form.Item  name="name"
        label="Visitor Name"
        
        rules={[
          
          {
            required: true,
            message: 'Please input name!',
            whitespace: true,
          },
        ]} >
          <Input className='rounded ' size='large'/>
           </Form.Item>
           <Form.Item  name="visitDate"
        label="Date"
        
        rules={[
          {
            required:true,
            message: 'Date is required!',
          },
         
        ]} >
         <DatePicker className='rounded ' size='large' style={{width:"50%"}}/>
        </Form.Item>
        <Form.Item label="Time" name="vistiTime" rules={[
          {
            required:true,
            message: 'Time is required!',
          },
         
        ]}>
        <Select className='rounded ' size='large'style={{width:"50%"}}>
        <Option value="9 am to 10 am">9 am to 10 am</Option>
        <Option value="10 am to 11 am">10 am to 11 am</Option>
        <Option value="11 am to 12 pm">11 am to 12 pm</Option>
        <Option value="12 pm to 1 pm">12 pm to 1 pm</Option>
        <Option value="1 pm to 2 pm">1 pm to 2 pm</Option>
        <Option value="2 pm to 3 pm">2 pm to 3 pm</Option>
        <Option value="3 pm to 4 pm">3 pm to 4 pm</Option>
        <Option value="4 pm to 5 pm">4 pm to 5 pm</Option>
        <Option value="5 pm to 6 pm">5 pm to 6 pm</Option>
        </Select>
        </Form.Item>
        </div>
           <Form.Item className='mb-0' >
        <div className='flex p-3 pb-0 border-t-2 justify-end space-x-3'>
        <Button type="text" loading={sending}  onClick={()=>setaddNew(false)}   size='large' >
          Cancel
        </Button>
        <Button type="primary" loading={sending}  htmlType="submit" size='large'  className={`px-3 brd-4 fw-700 mx-2 rounded-lg text-white bg-hs-pink  hover:border-hs-pink `}>
          Submit
        </Button>
       </div>
      </Form.Item>
         </Form>
      :
      <div><div className='p-3'><List dataSource={listVisits} 
      renderItem={(item, index) => (
        <List.Item >
          <List.Item.Meta
            
            title={item.visitorName}
            description={item.visitString}
        />
        {ownListing?<div>
            {item.status === 0?
            <Button className='rounded' onClick={()=>ApproveReq(item._id)} type='dashed'>Approve</Button>
            :"Scheduled"
        }

        </div>
        :<div>{item.status === 0?"Pending":item.status === 1?"Scheduled":"Completed"}</div>}
        </List.Item>
      )}
      />  
      </div>
     {!ownListing && <div className='flex p-3 pb-0 border-t-2 justify-end space-x-3'>
        <Button type="primary" onClick={()=>setaddNew(true)}  htmlType="button" size='large'  className={`px-3 brd-4 fw-700 mx-2 rounded-lg text-white bg-hs-pink  hover:border-hs-pink `}>
          Add New
        </Button>
      </div>}
      </div>
        }
    </Modal></>
  )
}

export default SiteVistiModal