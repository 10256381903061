import React,{useState,useEffect} from 'react'
import {Row,Col} from "react-bootstrap"
import SocialProfileImages from "./SocialProfileImages";
import {TimePickerView} from "./TimePickerView"
import {PlusSquare} from "react-bootstrap-icons"
import _ from 'lodash';


const SocialProfileTimeList = ({p,id,saveTimeslots,showName}) => {
    const [timeDefault,settimeDefault] = useState(p.timeSlot ? p.timeSlot : ["09:00","17:00"]);
    const [timeUsed,settimeUsed] = useState(false);
    useEffect(() => {
      saveTimeslots(p["id"],[...timeDefault])
      return () => {
        
      }
    }, [])
    const removeTime = async (value) =>{     
        const timelist = _.remove(timeDefault, function (time,i) {
          return i !== value;
        });       
        await settimeDefault(timelist)
        settimeDefault([...timelist])    
        settimeUsed(!timeUsed)
        saveTimeslots(p["id"],timelist)      
      }
      const updateTime =  (key,value) => {
        let currenList = timeDefault;
        currenList[key] = value;      
        settimeDefault(timeDefault => ([...currenList])); 
        saveTimeslots(p["id"],[...timeDefault])
         
      }
      const addTime = () =>{
          let currenList = [];
          let lastTime = _.last(timeDefault).split(":");
          
          let addnewTime = parseInt(lastTime[0])+1;
          if(lastTime[0] === "23"){
            addnewTime = "00";
          }
          
          addnewTime = addnewTime > 24 ? addnewTime - 24 : addnewTime;
          const newTime = addnewTime.length === 1 ? "0"+addnewTime+":"+lastTime[1] : addnewTime+":"+lastTime[1];
          currenList.push(newTime);
          settimeDefault([...timeDefault, newTime]);  
          
          saveTimeslots(p["id"],[...timeDefault, newTime]) 
         
      }
    return (
        <Row className="timeslots w-100"  >
        <Col  
          lg={3}
          md={4}
          sm={6} className="d-flex" >
         <SocialProfileImages p={p} id={id}  />
         {showName === "yes" && <span>{p.name}</span>}
          </Col> 
          <Col  
          lg={9}
          md={8}
          sm={6} className="d-flex flex-wrap" >
        {
            timeDefault.map((time, i) =>
          <Col key={i}    
          md={3}
          sm={6} className="time-wrapper">
          <TimePickerView time={time} kindex={i} len={timeDefault.length} removeItem={removeTime} changeTime={updateTime}/>
          </Col> 
          )
        }
        {timeDefault.length < 5 && <Col  
          md={3}
          sm={6} className="time-wrapper add-time">
          <div onClick={() => addTime()}  className="in in d-flex flex-row justify-content-center align-items-center">
          <span><div className="bt-icon"><PlusSquare color={"#198754"} size={14} /></div></span>
          </div>
          </Col>}       
          
        </Col>
        
        </Row>
    )
}

export default SocialProfileTimeList
