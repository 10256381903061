import React,{useEffect, useState} from 'react'
import { Input,Button,Select } from 'antd'
import Utils from 'helper/Utils';
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import ApiService from 'services/ApiService';

const { Option } = Select;
const { TextArea } = Input;
const EditableQuote = ({text,updateText,k}) => {
    const [edit, setEdit] = useState(false);
    const [editText, setEditText] = useState(text);
    const [showTimer, setshowTimer] = useState(false)
    const [activeLanguage, setactiveLanguage] = useState("Hindi")
    const [translation, settranslation] = useState(text)
    
    const langList = ["English","Hindi","Spanish","German","French","Japanese","Gujarati","Marathi","Bengali","Tamil","Telugu","Kannada","Malayalam","Nepalese","Urdu",,"Sindhi","Punjabi","Sanskrit","Konkani","Dogri","Assamese","Bhojpuri","Maithili","Meiteilon","Mizo"]
    // const langList = ["Hindi","Gujarati","Marathi","Bengali","Tamil","Telugu","Kannada","Malayalam","Maithili","Nepalese","Sanskrit","Urdu","Assamese","Dogri","Bodo","Manipuri","Oriya","Santali","Sindhi","Punjabi","Konkani","Kashmiri"]
    const lanShortCodes = {
      "Hindi":"hi",
      "Gujarati":"gu",
      "Marathi":"mr",
      "Bengali":"bn",
      "Tamil":"ta",
      "Telugu":"ta",
      "Kannada":"ta",
      "Malayalam":"ta",
      "Nepalese":"ne",
      "Sindhi":"sd",
      "Punjabi":"pa",
      "Urdu":"ur",
      "Spanish":"es",
      "English":"en",
      "Japanese":"ja",
      "German":"de",
      "French":"fr",
      "Sanskrit":"sa",
      "Konkani":"gom",
      "Dogri":"doi",
      "Assamese":"as",
      "Bhojpuri":"bho",
      "Maithili":"mai",
      "Meiteilon":"mni-Mtei",
      "Mizo":"lus"
  }
  // {value:"en",lable:"English"},
  // {value:"es",lable:"Spanish"},
  // {value:"de",lable:"German"},
  // {value:"fr",lable:"French"},
  // {value:"hi",lable:"Hindi"},
  // {value:"ja",lable:"Japanese"},
    const Original = text;
    const inputRef = React.useRef();
    useEffect(() => {
        setshowTimer(false)
    
      return () => {
        
      }
    }, [edit])
    
    const setEditOn = () =>{
        setEdit(true);        
    }
    const setUpdate = () =>{
        updateText(k,editText)
        setEdit(false);
    }
    const setCancel = () =>{
        setEdit(false)
        setEditText(Original)
    }
    const handleChange = (e)=>{
        setactiveLanguage(e)
    }
    const sendTranslateRequestGoogle = async()=>{
      const lcode = lanShortCodes[activeLanguage]
      const res = await ApiService.PostApiCalls("translate/quote",{data:{text:editText,target:lcode}})
        if(res[0] && res[1]["success"]){
            settranslation(res[1]["translation"])
            setEditText(res[1]["translation"])
        }
        else{
            Utils.showNotification("error",res[1]["title"],res[1]["error"])
        }
    }
    const sendTranslateRequest = (e)=>{
        setshowTimer(true)
        Utils.showNotification("success",`Tranlate to ${activeLanguage}`,"Your request has been sent.")
    }
    const renderTime = ({ remainingTime }) => {
        if (remainingTime === 0) {
          return <div className="timer">Too lale...</div>;
        }
      
        return (
          <div className="timer">
           
            <div className="value">{remainingTime}</div>
            
          </div>
        );
      };
    return (
        <div style={!edit?{width: '100%' }:{width: '90%' }}>
         {!edit && <span className="only-text" onClick={() => setEditOn()}>{editText}</span>}   
         {edit && <TextArea
          placeholder="Write quote..."
          autoSize={{ minRows: 2, maxRows: 3 }}
          className="mb-3 custom-scroll w-full"
          value={editText} 
          ref={inputRef}
          autoFocus
          onChange={(e) => setEditText(e.target.value)}
        />} 
        {edit && 
        <div className='flex justify-between px-1'>
        <div><Button type="primary" onClick={() =>setUpdate() } size={"small"} className="mr-2">Update</Button><Button onClick={() => setCancel()}  size={"small"}>Cancel</Button></div>
        <div className='flex space-x-2 items-center'>
        <span>Translate quote:</span>
        <Select defaultValue={activeLanguage} size={"small"} style={{ width: 120 }} onChange={handleChange}>
            {
                langList.map((l,k)=>
                <Option key={k} value={l}>{l}</Option>
                )
            }
    </Select>
    <Button type="primary" onClick={sendTranslateRequestGoogle}  size={"small"} className="mr-2 text-xs">Google Translate</Button>
   {/* {!showTimer ?<Button type="primary" onClick={sendTranslateRequest} className="mr-2 text-xs"  size={"small"}>Human Translate</Button>:
   <CountdownCircleTimer
   isPlaying
   duration={60}
   colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
   colorsTime={[60, 45, 30, 15]}
   size={30}
   strokeWidth={2}
   onComplete={() => setshowTimer(false)}
 >
   {renderTime}
 </CountdownCircleTimer>
   } */}
        </div>
        </div>}  
        </div>
    )
}

export default EditableQuote
