import React,{useState,useEffect,useContext} from 'react'
import {Col} from "react-bootstrap";


import {Menu as Menuant, Dropdown,Button ,Modal, Input } from 'antd';
import { AuthContext } from 'app/AuthProvider';
import ApiService from 'services/ApiService';
const CreateWorkspace = (props) => {
    const wpAllowed = {
        1:1,
        2:3,
        3:6,
        4:10,
        5:100
    }
    const { authUser,setactiveWorkSpace,setAuthUser  } = useContext(AuthContext);
    const [workspacename, setworkspacename] = useState("")
    const workspaceAddedCount = authUser["workspace"].length;
    const workspaceAllowed = wpAllowed[authUser["selectedAppPlan"]?authUser["selectedAppPlan"]["id"]:1];
    const [error, setError] = useState(null)
    const [createdData, setCreatedData] = useState(null)
    const [isSaving,setSaving] = useState(false);

    const createWorkSpace = async()=>{
        setError(null)
        const name = workspacename.trim()
        if(name.length === 0){
            setError("Enter valid workspace name");
            return false;
        }
        setSaving(true);
        const data = {
            
            name: name,
            link: "",
            selectedProfile:[],
            selectedProfileIds:[],
            onWP:true
        }
        
        const created = await ApiService.PostApiCalls(
            "creator/setup/createWorkSpace",
            data,
            true
          );
        if(created[0]){
            setSaving(false);
            if(created[1]["IsSuccessful"]){
                let currentWp = authUser["workspace"];
                currentWp.push(created[1]["workspace"])
                setAuthUser({...authUser,workspace:currentWp})
                setactiveWorkSpace(created[1]["workspace"]); 
                setCreatedData(created[1]["workspace"])

            }
            else{
                setError(created[1]["error"]);
                return false;
            }
        }
        else{
            setSaving(false);
            setError("Network error. Try again");
            return false;
        }
    }
    return (
        <>
        <Col className="px-0 fz-16 py-1 fw-700"
     lg={12}      
     md={12}
     sm={12} >
        {
            (workspaceAddedCount == workspaceAllowed) && <div className="fz-16 d-flex flex-column">
            <span className="color-red fw-600  w-100">Plan restricted to {workspaceAllowed} {workspaceAllowed === 1?"Workspace":"Workspaces"}. You will need to upgrade to continue.</span>

            <span  className="color-red fw-600  w-100 text-center py-4">
                <a href="/account/billing">
                <Button   shape="round" className="fw-bold">Upgrade Plan</Button></a></span>

            <div className="fz-16 w-100 ">
           <Button onClick={()=> props.close()} className="fw-bold brd-4 float-right" type="primary" >Okay!</Button>
        </div>
        </div>
        }
        {
            (workspaceAddedCount < workspaceAllowed) && 
            <>
            {!createdData && <><Col className="px-0 fz-16 py-1 fw-700"
            lg={12}      
            md={12}
            sm={12} >Name <sup className="color-red">*</sup></Col>
             <Col className="px-0 fz-14 py-1 mb-3 fw-600 "
            lg={12}      
            md={12}
            sm={12} ><Input className="ant-input brd-10 py-2 px-2 mt-3" value={workspacename} onChange={(e) => setworkspacename(e.target.value)} placeholder="Add workspace name" /></Col>
             <Col className="px-0 fz-14 py-1 mb-3 fw-600 "
            lg={12}      
            md={12}
            sm={12} ></Col></>}

            {createdData && <><Col className="px-0 fz-16 py-1 fw-700 d-flex flex-column" lg={12}      
                        md={12}
                        sm={12} >
                            <span className="color-red fw-600  w-100">Your new worksace {createdData["name"]} has been created, before you start using it you need add some default settings.</span>

<span  className="color-red fw-600  w-100 text-center py-4">
                <a href={`/workspace/${createdData["slug"]}/settings/workspace`}>
                <Button   shape="round" className="fw-bold">Go to Settings</Button></a></span>
            </Col></>}

            {
         error && <Col className="px-0 fz-12 "
         lg={12}      
         md={12}
         sm={12}>
             <span className="text-center w-100 fw-600 pt-1 fz-14 align-self-center color-red">{error}</span> 
         </Col>
     }
           { !createdData && <Col className="px-0 fz-14 py-1 mb-3 fw-600 text-right "
            lg={12}      
            md={12}
            sm={12} ><Button onClick={()=> createWorkSpace()} className="fw-bold brd-4 " type="primary" loading={isSaving}  >{isSaving ?"Please wait":"Save"}</Button></Col>
            
            }
             <Col className="px-0 fz-14 py-1 mb-1 fw-600 "
            lg={12}      
            md={12}
            sm={12} ></Col>
            </>
        }
       </Col>
        </>
    )
}

export default CreateWorkspace
