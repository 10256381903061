import { Button, Spin } from "antd";
import CardVisual from "components/feed/CardVisual";
import SocialIcon from "container/common/SocialIcon";
import moment from "moment";
import React from "react";
import { CalendarEvent } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

const CampaignCard = ({ content, id,type ,slug,rv}) => {
  
  return (
    <div className="cursor-pointer">
      <Link  to={content["state"] === "active"?`/creator/${slug}/calendar?postid=${content["cid"]}`: content["details"]["campaign-post"] === "video-post"?`/creator/${slug}/campaigns/${content["cid"]}/review/`:`/creator/${slug}/campaigns/${content["cid"]}/review/`}>
        <CardVisual visual={content['details']['campaign-post'] === 'video-post' && content['isVideoReady']?content['videoCover']:content["metaInfo"] && content["metaInfo"]["image"]?content["metaInfo"]["image"]:content["fetchedImges"] && content["fetchedImges"][1]?content["fetchedImges"][1]:content["fetchedImges"] && content["fetchedImges"][0]?content["fetchedImges"][0]:""} type={"image"} CampaignType={content["details"]?content["details"]["campaign-post"]:""}  />
      </Link>
      
      <div className="py-2"></div>
      <div className="px-1 line-clamp-2 font-normal text-h text-sm">
        {content.name}
      </div>
      <div className="py-1"></div>
      <div className="px-2 line-clamp-2 font-normal text-xs text-blue-500">
       <a href={content["link"]} target="_blank" >{content["link"]}</a> 
      </div>
      <div className="py-1"></div>
      <div className="px-2 font-normal text-xs text-h flex flex-row items-center">
        <CalendarEvent />
        <span className="ml-2">
          {content["updatedAt"]?moment.unix(content["updatedAt"]).format("MMM Do, hh:mm a"):moment.unix(content["fetchedAt"]).format("MMM Do, hh:mm a")}
        </span>
      </div>
      <div className="py-2"></div>
      {content["state"] === "active" ? <>{content['details']['campaign-post'] === 'static-post'?<div className="px-2 flex flex-row space-x-2 items-center">
        {
          content["socialprofiles"].map((p,k) => {
            if(content["selectedProfiles"].includes(p["id"])){
          return <SocialIcon key={k} icon={p["source"].toLowerCase()} /> 
        }
        })
        }
        {/* <SocialIcon icon="instagram" />
        <SocialIcon icon="facebook" />
        <SocialIcon icon="twitter" />
        <SocialIcon icon="linkedin" /> */}
      </div>:<>
      {
        content['isVideoReady']?
        <div className="px-2 flex flex-row space-x-2 items-center">
        {
          content["socialprofiles"].map((p,k) => {
            if(content["selectedProfiles"].includes(p["id"])){
          return <SocialIcon key={k} icon={p["source"].toLowerCase()} /> 
        }
        })
        }
        {/* <SocialIcon icon="instagram" />
        <SocialIcon icon="facebook" />
        <SocialIcon icon="twitter" />
        <SocialIcon icon="linkedin" /> */}
      </div>:<><div className="px-2 flex flex-row space-x-2 items-center">
      <div className="flex items-center space-x-3 pt-2"><Spin size="small" /><span className="text-xs text-muted">Processing a video...</span> </div>
      </div></>

      }
      
      </>}</>:
      <div className="px-2 flex flex-row space-x-2 items-center">
      {content.status === 0 ?<div className="flex items-center space-x-3 pt-2"><Spin size="small" /><span className="text-xs text-muted">Preparing a campaign...</span> </div>:<Link to={content["details"]["campaign-post"] === "video-post"?`/creator/${slug}/campaigns/${content["cid"]}/review/`:`/creator/${slug}/campaigns/${content["cid"]}/review/`}>  <Button type="primary" block className="rounded-xl">{rv?rv:"Review"}</Button></Link>}
      </div>}
      <div className="py-1"></div>
    </div>
  );
};

export default CampaignCard;
