import React,{useState} from 'react'
import {Tabs,Tab,Card } from "react-bootstrap";
import {TextareaT,VolumeUp,Gear,Pencil,} from "react-bootstrap-icons";
import CardHeader from "./comp/CardHeader"
import TextView from "./VideoComponents/TextView"
import MusicView from "./VideoComponents/MusicView"
import ThemeView from "./VideoComponents/ThemeView"
import SettingeView from "./VideoComponents/SettingeView"
const VideoEditorTabs = ({clips,activeClip}) => {
    const [activeKey, setCurrentKey] = useState(0)
    const [SeletedMusic,setSeletedMusic] = useState(null)
    // console.log(activeClip);
    const TabList = [
        {label:"Add Text",icon:<TextareaT className="mr-3"/>,secondaryLabel:"Hashtags"},
        {label:"Add Audio",icon:<VolumeUp   className="mr-3"/>,secondaryLabel:"Mentions"},       
        {label:"Add Theme",icon:<Pencil   className="mr-3"/>,secondaryLabel:"Quotes"},
        // {label:"Settings",icon:<Gear   className="mr-3"/>,secondaryLabel:"Quotes"},
        // {label:"Edit",icon:<Pencil   className="mr-3"/>,secondaryLabel:"Quotes"},
      ]
    return (
        <Tabs  defaultActiveKey={activeKey}  onSelect={(k) => setCurrentKey(k)}>
        {
            TabList.map((a,key) => (
                <Tab key={key} eventKey={key} title={<CardHeader isTab={true} activeTags={0} activeKey={activeKey} icon={a.icon} text={a.label}  secondaryLabel={a.secondaryLabel} minQuotesNo={0}/>}>
                <Card.Body className="px-1">
                {key === 0 && <TextView clips={clips} activeClip={activeClip} />}
                {key === 1 && <MusicView current={key} akey={activeKey} music={SeletedMusic} callback={setSeletedMusic} />}
                {key === 2 && <ThemeView current={key} akey={activeKey} />}
                {key === 3 && <SettingeView current={key} akey={activeKey} />}
                {key === 4 && <SettingeView current={key} akey={activeKey} />}
                 </Card.Body>
                </Tab>
            ))
        }
  
 
</Tabs>
    )
}

export default VideoEditorTabs
