import React, { Component } from 'react'
import PostCardHeader from "./PostCardHeader"
import PostCardActions from "./PostCardActions"
import PostCardText from "./PostCardText"
import PostCardImage from "./PostCardImage"
import {Button, Switch} from "antd"
import _ from 'lodash';
export default class PostCard extends Component {
    constructor(props) {
      super(props)    
      this.state = {
        post:this.props.post,
        clips:this.props.videoClips,
        selectedProfiles:this.props.selectedProfiles,
        currentCWProfiles:this.props.currentCWProfiles,
        textLength:999,
        textProfileId:"all",
        isLoading:true,
        textlimit:0,
        textLens : {
            "Facebook":999,
            "Instagram":999,
            "Twitter": 280,
            "Linkedin":1299
        },
        privacyMode:false
      }
    }
    
    componentDidMount(){
      //initiaallen
      const {selectedProfiles,currentCWProfiles,textLens} = this.state;

      const selectedOne = _.filter(currentCWProfiles,function(p){
        return selectedProfiles.includes(p["id"])
      })
      const availableSources = _.map(selectedOne, 'source');
      let textlimits = []
      for(let i=0;i<availableSources.length;i++){
        textlimits.push(textLens[availableSources[i]])
      }
      this.setState({
        textLength:_.min(textlimits),
        isLoading:false
      })
      
    }
    getFullText = (text,link,tags) => {
      let textOnly = text+" "+link;
      for(let i = 0; i <tags.length; i++){
        const old  = textOnly;
        if(tags[i].replace(/ /g,'').length > 0){
        textOnly = textOnly+" "+"#"+tags[i].replace(/ /g,'');
       
      }
    }
      return textOnly.length;
    }
    updateContent = (type,option=null,textextra="") =>{
        const {textProfileId,selectedProfiles} = this.state;
        let tempPost = this.state.post;
        
      
        if(type === "text"){
            if(textProfileId === "all"){
            tempPost["qoute"] = _.chunk(_.shuffle(this.props.availableQuotes), 1)[0][0];
            if(tempPost["all"]){
              console.log(selectedProfiles);
              let temp = tempPost["all"];
              tempPost["tmpqoute"] = _.chunk(_.shuffle(this.props.availableQuotes), 1)[0][0];
              temp["text"] = _.chunk(_.shuffle(this.props.availableQuotes), 1)[0][0];
              tempPost["all"] = temp;
              
            }
          }
          else{
            if(tempPost[textProfileId]){
              let temp = tempPost[textProfileId];
              tempPost["tmpqoute"] = _.chunk(_.shuffle(this.props.availableQuotes), 1)[0][0];
              temp["text"] = _.chunk(_.shuffle(this.props.availableQuotes), 1)[0][0];
              tempPost[textProfileId] = temp;
              
            }
            else{
              let tmp = {
                text:_.chunk(_.shuffle(this.props.availableQuotes), 1)[0][0],
                linktext:" Read full article "+this.props.postLink,
                tags:this.props.post["tags"]
              }
              tempPost["tmpqoute"] = _.chunk(_.shuffle(this.props.availableQuotes), 1)[0][0];
              tempPost[textProfileId] = tmp;
             
            }
          }
        }
        else if(type === "tags"){
            
            tempPost["tags"] = _.shuffle(this.props.post["tags"])
        }
        else if(type === "mentions"){
            tempPost["mentions"] = _.shuffle(this.props.post["mentions"])
        }
        else  if(type === "image"){
            tempPost["image"] =  _.chunk(_.shuffle(this.props.availableImages), 1)[0];
        }
        else if(type === "changeImage"){
            tempPost["image"] =  option;
        }
        else if(type === "useimage"){
          tempPost["useimage"] =  true;
          tempPost["speechbubble"] =  false;
          tempPost["hideImage"] =  false;
        }
        else if(type === "speechbubble"){
          tempPost["useimage"] =  false;
          tempPost["speechbubble"] =  true;
          tempPost["hideImage"] =  false;
        }
        else if(type === "hideImage"){
            tempPost["hideImage"] =  true;
            tempPost["useimage"] =  false;
            tempPost["speechbubble"] =  false;
        }
        else if(type === "speechTemplate"){
            tempPost["speechTemplate"] = option;
            tempPost["speechTemplateText"] = textextra;
        }
        else if(type === "delete"){
            tempPost["deleted"] =  true;
        }
        else if(type === "add"){
            tempPost["deleted"] =  false;
        }
        else if(type === "posttext") {
          
           tempPost[this.state.textProfileId] = option;
        }
       const _self = this;
      if(!this.props.hideTools){
      this.setState({post: tempPost},()=>_self.props.updatePostContent(tempPost,this.props.id))}
       
    }
    updateContentLen = (type,option=null,len = 0) =>{
      if(type === "len"){
        this.setState({textlimit:len})
      }else{
        
     
      }
      
    }
    setTextLength = (n,id) => {
      this.setState({textLength:n,textProfileId:id})
    }
  render() {
    const {post,clips,selectedProfiles,textLength,textProfileId,textlimit,isLoading} = this.state;
    if(isLoading){
      return (<div></div>)
    }
   
    const {customTemplates,id,content} = this.props;
    return (
      <>{post.deleted && <div className="post-card">
        
      <PostCardHeader  selectedProfiles={selectedProfiles} post={post} updateContent={this.updateContent} />
      <div className="no-post">A social post will not send on day {post["day"]} of this campaign.</div>
      <Button className="mx-auto mt-4 d-flex" type="primary" onClick={()=>this.updateContent("add")}>Add day back into the schedule</Button>
      
      </div>}
        {!post.deleted &&<div className="post-card relative  mt-3">
          <div className='absolute p-1 rounded-sm -top-4 right-0'><Switch checkedChildren="Earning Mode" className='bg-hs-pink ' onChange={(checked)=>this.setState({privacyMode:!checked})} checked={!this.state.privacyMode} unCheckedChildren="Privacy Mode" /></div>
        <PostCardHeader textProfileId={textProfileId} textLength={textLength}  postLink={this.props.postLink} textlimit={textlimit} hideTools={this.props.hideTools} selectedProfiles={selectedProfiles} callback={this.setTextLength} post={post} updateContent={this.updateContent} />
        {this.props.hideTools ? "":<PostCardActions key={id} updateContent={this.updateContent} day={id}/>}
        <hr />
        <PostCardText mintedHashs={this.props.mintedHashs} hideTools={this.props.hideTools} textProfileId={textProfileId} postLink={this.props.postLink} id={id} post={post} textLength={textLength} updateContent={this.updateContent} updateContentLen={this.updateContentLen}/>
        <PostCardImage updateContent={this.updateContent}  metaInfo={this.props.metaInfo} callback={this.props.playvideo} id={id} post={post} clips={clips} content={content} customTemplates={customTemplates} /> 
          </div>}
      </>
    )
  }
}



// import React,{useState,useEffect} from 'react'


// const PostCard = ({text,post,customTemplates,content,id}) => {  
//     const [postContent,setPostContent]  = useState(post)
   
//     const updateContent = (type,day) =>{        
        

//     }
//     // console.log(post["day"]);
//     return (
//         <>
//         {postContent && 
//         }
//           </>
//     )
// }

// export default PostCard
