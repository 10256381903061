import { Row, Col,Input, Typography, Modal,Select, Button, Spin,notification,message, AutoComplete, Tag } from "antd";

import React, { useEffect, useState } from "react";
import {timeZoneList,days,Months} from "res/extra/time-date"
import _ from "lodash";
import ApiService from "services/ApiService";
import { ChevronBarDown, InfoCircle ,ChevronDown, XCircle} from "react-bootstrap-icons";

import Utils from "helper/Utils";
import { OverlayTrigger,Tooltip } from "react-bootstrap";
import SocialProfileImages from "./comps/SocialProfileImages";
import SocialProfileTimeList from "./comps/SocialProfileTimeList";
const { Paragraph } = Typography;
const { Option } = Select;

const SettingsDateTime = ({ user, activeWorkSpace }) => {
  const [viewloading, setViewLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const cw = activeWorkSpace
  const [timezone,setTimezone] = useState(cw["timezone"]?cw["timezone"]:"Asia/Kolkata")
  const [old,setoldTimezone] = useState(cw["timezone"]?cw["timezone"]:"Asia/Kolkata")
  const [startday,setStartDay] = useState(cw["startday"]?cw["startday"]:"Monday")  
  const [selectedDays,setSelectedDays] = useState(cw["publishingDays"]?cw["publishingDays"]:[])  
  const [selectedDay,setSelectedDay] = useState("1")  
  const [selectedMonth,setSelectedMonth] = useState("January")  
  const [blockDaysList,setblockDaysList] = useState(cw["blockedDates"]?cw["blockedDates"]:[])  
  const [profiles,  setSocialProfilesList] = useState(Utils.getWorkSpaceSocialProfiles(user,activeWorkSpace["wid"]));
  const [saving, setsaving] = useState(false)
  useEffect(() => {
    
    getDetails()
    return () => {
      
    }
  }, [])
  const getDetails = async()=>{
    setViewLoading(false)
  }
  const renderList = (t) =>{
    let list = [];
    t.map((val,key) => {   
         const tmp = {value:val}
            list.push(tmp)                
    
    })
    return list;
}
const onSelectDay = (data) => {        
    setStartDay(data);
};
const OnCancel =()=>{
    window.location.reload()
}
const ToggleSelectedDay = (key) =>{
  if(selectedDays.includes(key)){            
      var newList = _.remove(selectedDays, function(n) { return n !== key; });            
      setSelectedDays(newList)
  }
  else{
      setSelectedDays([...selectedDays,key])
  }
}
const timezonelist = renderList(timeZoneList)
const onSelectZone = (data) =>{
  setTimezone(data)
  setoldTimezone(data)

}
const setOldSelected = ()=>{
  if(timezone === ""){
      setTimezone(old)
  }
}
const OnSearch = () => {

}
const onChangeZone = (data) =>{
    setTimezone(data);
    // if()
    // setoldTimezone(data)
}
const handleChangeMonth = (value) =>{
    setSelectedMonth(value)
}
const handleChangeDay = (value) =>{
    setSelectedDay(value)      
}
const AddBlockDay = () =>{
    const day = selectedMonth+" "+selectedDay;
    if(!blockDaysList.includes(day)){
        setblockDaysList([...blockDaysList,selectedMonth+" "+selectedDay])
    }       
}
const removeTag = (tag) => {
  if(blockDaysList.includes(tag)){            
      var newList = _.remove(blockDaysList, function(n) { return n !== tag; });            
      setblockDaysList(newList)
  }
}
const renderDays = () =>{
  let rows = [];
      for (var i = 0; i < 31; i++) {
          rows.push(<Option key={i} value={i+1}>{i+1}</Option>);
      }
  return rows;
}
const saveTimeForProfile = async(id,time)=>{
  let p = profiles;
  let newItems = [];
  let forUpdate;
  for(let i = 0; i < p.length; i++){
    let tmp = p[i];
    if(tmp["id"] === id){
    tmp["timeSlot"] = time;
    forUpdate = tmp;
    }
    newItems.push(tmp);
  }     
  setSocialProfilesList([...newItems]);
  const saved = await ApiService.PostApiCalls("/creator/socialchannel/update/"+forUpdate["spid"],{update:{timeSlot:forUpdate["timeSlot"]}})
}
const SaveWpDetails = async()=>{
  
  setsaving(true);
    // const currenWorkspace = activeWorkSpace
    const q = activeWorkSpace["wid"];
    const data = {timezone:timezone,startday:startday,publishingDays:selectedDays,blockedDates:blockDaysList};
    const saved = await ApiService.PostApiCalls(`/creator/workspace/update/${q}`,{workSpace:data},true)
    if (saved[0] && !saved[1]["IsSuccessful"]) {
                   
      notification["error"]({
        message: saved[1]["ErrorMessage"],
        description:""
      });
  }
  else{
      
      notification["success"]({
        message: saved[1]["FriendlyMessage"],
        description:""
      });
    
  }
  setsaving(false);
}
  if(viewloading){
    return (
      <>
      <Col span={24} className="py-0 px-0 mb-0">
        <div className="bg-white p-3 px-3 flex space-x-3 items-center justify-between">
          <span className="font-bold">Dates & Times</span>
          <Button
            onClick={()=>SaveWpDetails()}
            type="primary"
            className=" rounded  float-right mr-3"
            loading={loading} disabled={saving}
          >
           Save Details
          </Button>
        </div>
      </Col>
      <div className="pb-2 bg-gray-100"></div>
      <Col span={24} className="py-0 px-0 mb-0 h-screen flex items-center justify-center">
      <Spin />
      </Col>
      </>
    )
  }
  return (
    <> <Col span={24} className="py-0 px-0 mb-0">
    <div className="bg-white p-3 px-3 flex space-x-3 items-center justify-between">
      <span className="font-bold">Dates & Times</span>
      <Button
         
         onClick={()=>SaveWpDetails()}
            type="primary"
            className=" rounded  float-right mr-3"
            loading={loading} disabled={saving}
      >
         Save Details
      </Button>
    </div>
  </Col>
  <div className="pb-2 bg-gray-100"></div>
  <Col span={24} className="">
  <Row className="p-2">
  <Col span={24} className="p-2">
            <Paragraph className="text-sm text-hs-dark">
            Set your time zone and the days you want information delivered on. Add a blacklist date to ensure that content is never sent out on a specified day of the year (such as Diwali day).
            </Paragraph>
          </Col>
          <Col className="px-0 pr-4 mt-4 " span={24}>
          <Row className="mx-0 all-header-dark">
            <Col className="px-2"
             span={6} ><span className="fw-bold fz-14 mb-2 float-left w-100">Set your timezone</span>
              <span className="fw-600 fz-14 float-left w-100"><AutoComplete
            options={timezonelist} 
            allowClear={true}
            value={timezone}
            style={{ flex: 1,width: '100%' }}
            onSelect={onSelectZone}
            onFocus={()=>setTimezone("")}
            onBlur={setOldSelected}
            onChange={onChangeZone}
            onSearch={OnSearch}
        filterOption={(inputValue, option) =>
      option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
    }
      ><Input className="utm-input mt-2" size="large" value={timezone} placeholder="Add timezone" suffix={<ChevronBarDown />} />
  </AutoComplete></span>
             
             </Col> <Col className="px-0 ml-2 "
             span={16} >
             <span className="fw-bold fz-14 mb-2 float-left w-100 flex items-center flex-row">
             <span>Choose the days that you want to publish the posts</span><span><OverlayTrigger
      key={0}
      placement={"top"}
      overlay={
        <Tooltip id={`tooltip-0`}>
        If none is selected all days will be available for publishing.
        </Tooltip>
      }
    >
     <InfoCircle className="ml-2" size={14} color={"#646464"} />
    </OverlayTrigger></span></span>
              <span className="fw-600 fz-14 float-left w-100 days-list d-flex justify-content-between align-items-center cursor-pointer pt-2">
                  {
                    days.map((day,key) =>(
                        <span onClick={() => ToggleSelectedDay(key)} className={selectedDays.includes(key) ? "border p-2  px-3 rounded bg-hs-pink text-white":"border p-2 px-3 rounded"} key={key}>{day["value"]}</span>

                    ))   
                  }
              </span>
             </Col>
             </Row>
             <Row className="mx-0 all-header-dark mt-4">
            <Col className="px-2 mt-4"
             span={10} ><span className="fw-bold fz-14 mb-2 float-left w-100">What Day Does Each Week Begin On?</span>
             <span className="fw-600 fz-14 mb-2 float-left w-100">
             <AutoComplete
        options={days}
        style={{ flex: 1,width: '50%' }}
        onSelect={onSelectDay}
        value={startday}
      />
             </span>
             </Col>
             <Col className="px-2 block-list mt-4"
             span={12} ><span className="fw-bold fz-14 mb-2 float-left w-100">
             Blacklist Dates</span>
             <span className="fw-600 fz-14 mb-2 float-left w-100">
             <Select defaultValue="1" suffixIcon={<ChevronDown color="#508AD2" size="18" />} style={{ width: 70 }} onChange={handleChangeDay}>
             {
                renderDays()
             }    
     
            </Select>
                    <Select suffixIcon={<ChevronDown color="#508AD2" size="18" />} defaultValue="January" style={{ width: 120,marginLeft:"1rem" }} onChange={handleChangeMonth}>
                    {
                        Months.map((m,key) =>(
                                <Option key={key} value={m["value"]}>{m["value"]}</Option>
                        ))
                    }             
            </Select>
            <Button type="primary" onClick={() => AddBlockDay()} className=" ml-3 fw-bold fz-14 day-time-add-btn" >Add</Button>
             </span>
             </Col>
             </Row>
             <Row className="mx-0 all-header-dark mt-4">
             <Col className="px-0 "
             span={22}>
           <div className="d-flex flex-grow-1 mt-4 px-3 align-items-center flex-wrap" >
            {blockDaysList.map((tag,key) =>(
                <Tag key={key} closable onClose={(e) =>removeTag(tag)} className="tags-added mb-3 d-flex align-items-center" closeIcon={<XCircle color={"#ef4d74"} size={15} /> } >
                <span className="fw-600 fz-14 all-header-dark">{tag}</span>
        </Tag>
            ))}
            </div>
             </Col></Row>

             <Row className="mx-0 all-header-dark mt-1">
             <Col className="px-0 mt-2"
             span={24}>
           <span className="fw-bold fz-14 mb-2 float-left w-100">When would you like to send your content?
           <span className="fz-13 ml-2 fw-400 text-danger">*5 daily slots are allowed per day.</span>
           </span>
             </Col>
             <Col className="px-0 pr-4 mt-4 d-flex flex-wrap"
             span={24} >
                 {
                    profiles.map((p,i)=>(                        
                        <SocialProfileTimeList showName="yes" p={p} key={i} id={i} saveTimeslots={saveTimeForProfile} />
                    ))
                 }
                 
             </Col>
             </Row>
          </Col>
  </Row>
  </Col>
  </>
  )
}

export default SettingsDateTime