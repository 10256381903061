import React, { useEffect, useState } from "react";
import { Col, List, Button, Spin, Result } from "antd";
import _ from "lodash";
import Hashtags from "container/common/Hashtags";
import ApiService from "services/ApiService";
import HeaderDropdownSelection from "./HeaderDropdownSelection";
import HashtagsOffer from "container/common/HashtagsOffer";
const KeywordsSale = ({
  setaddHash,
  activeTab,
  setmodalEvent,
  authUser,
  openModel,
}) => {
  const [hashSale, sethashSale] = useState([]);
  const [loadingData, setloadingData] = useState(true);
  const [activeSelection, setactiveSelection] = useState("Available");
  useEffect(() => {
    
    if (activeSelection === "Available") {
      getAvilableSale(activeTab);
    } else {
      getSaleRequested(activeTab);
    }

    return () => {};
  }, []);

  const getAvilableSale = async (t) => {
    sethashSale([]);
    const salesData = await ApiService.PostApiCalls(
      "keyhashes/sales/list",
      { type: "sale",status:"open" },
      true
    );
    if (salesData[0] && salesData[1]["IsSuccessful"]) {
      sethashSale(salesData[1]["hashKeys"]);
    }

    setloadingData(false);
  };
  const getSaleRequested = async (t) => {
    setloadingData(true);
    sethashSale([]);
    const salesData = await ApiService.PostApiCalls(
      "keyhash/listing/get/sale",
      { type: "sale", status: "open" },
      true
    );
    if (salesData[0] && salesData[1]["IsSuccessful"]) {
      sethashSale(salesData[1]["offers"]);
    }

    setloadingData(false);
  };
  const handleChange = (e)=>{
    setactiveSelection(e);
    if (e === "Available") {
      getAvilableSale(activeTab);
    } else {
      getSaleRequested(activeTab);
    }
  }
  return (
    !loadingData && (
      <><Col className="px-3 pb-2 ml-2">
      <HeaderDropdownSelection
        options={["Available", "Your Request"]}
        defaultSelected={activeSelection}
        callback={handleChange}
      />
    </Col>
      <Col className="px-3">
        <List
          bordered
          itemLayout="horizontal"
          dataSource={hashSale}
          renderItem={(item) => (
            <List.Item>
             {activeSelection === "Available"?<Hashtags
                  hash={item}
                  type={activeTab === "auction" ? "sale" : activeTab}
                  user={authUser}
                  callback={openModel}
                />:<HashtagsOffer
                hash={item}
                type={activeTab === "auction" ? "sale" : activeTab}
                user={authUser}
                callback={openModel}
              />}
            </List.Item>
          )}
        />
      </Col>
      </>
    )

    // <div>KeywordsSale</div>
  );
};

export default KeywordsSale;
