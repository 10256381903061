
import { Col, Row, Statistic } from 'antd'
import DataLoader from 'container/common/DataLoader'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { BadgeAd, Bookmark, CameraVideo, ChatRight, HandThumbsUp, Linkedin, MusicNoteList, Share } from 'react-bootstrap-icons'
import ApiService from 'services/ApiService'

const AccountActivities = ({user}) => {
  
  const [loading, setloading] = useState(true);
  const [activitiesData, setactivitiesData] = useState(null)
  const [socialView, setSocialViews] = useState({_id:0,feed:0,facebook:0,twitter:0,instagram:0,linkedin:0})
  useEffect(() => {
    getActivities()
  
    return () => {
      
    }
  }, [])
  const getActivities = async()=>{
    const listAct = await ApiService.PostApiCalls("user/activities",{},true)
    if(listAct[1] && listAct[1]["IsSuccessful"]){
      setactivitiesData(listAct[1]["list"]);
    }
   
    getSocialViews()

    setloading(false)
  }
  const getSocialViews = async()=>{
    const listAct = await ApiService.PostApiCalls("user/socialviews",{},true)
    if(listAct[1] && listAct[1]["IsSuccessful"]){
      
      if(listAct[1]["list"][0]){
      setSocialViews({...listAct[1]["list"][0]});
    }}
   
  }
  if(loading){
    return(
      <DataLoader />
    )
  }
  
  return (
    <Col span={24} className="p-3 h-full">
    <Row gutter={16} className="px-4">
    <Col span={8}>
      <Statistic title="Likes"  value={activitiesData["likes"][0]?activitiesData["likes"][0]["count"]:0} prefix={<HandThumbsUp size={20} />} />
    </Col>
    <Col span={8}>
      <Statistic title="Comments" value={activitiesData["comments"][0]?activitiesData["comments"][0]["count"]:0} prefix={<ChatRight size={20}/>} />
    </Col>
    <Col span={8}>
      <Statistic title="Video Views" value={activitiesData["videoviews"][0]?parseFloat(activitiesData["videoviews"][0]["count"]).toFixed(2):0} prefix={<CameraVideo size={20}/>}  />
    </Col>
    <Col span={24} className="py-3">
      {/* <Statistic title="Video Views" value={93} prefix={<CameraVideo size={20}/>}  /> */}
    </Col>
    <Col span={8}>
      <Statistic title="Ad Clicks" value={activitiesData["adclicks"][0]?activitiesData["adclicks"][0]["count"]:0} prefix={<BadgeAd size={20}/>}  />
    </Col>
    <Col span={8}>
      <Statistic title="Post Share" value={activitiesData["shares"][0]?activitiesData["shares"][0]["count"]:0} prefix={<Share size={20}/>}  />
    </Col>
    {/* <Col span={8}>
      <Statistic title="Video Views" value={65} prefix={<CameraVideo size={20}/>}  />
    </Col> */}
  </Row>
  {user["role"] === "creator" && <>
  <Row gutter={16} className="px-4 mt-5"><Col className='font-semibold'>Social Profiles Views</Col></Row>
  <Row gutter={16} className="px-4 mt-2">
    
    <Col span={8}>
      <Statistic title="Blu Me"  value={socialView["feed"]} />
    </Col>
    <Col span={8}>
      <Statistic title="Facebook" value={socialView["facebook"]}  />
    </Col>
    <Col span={8}>
      <Statistic title="Instagram" value={socialView["instagram"]}   />
    </Col>
    <Col span={24} className="py-3">
      {/* <Statistic title="Video Views" value={93} prefix={<CameraVideo size={20}/>}  /> */}
    </Col>
    <Col span={8}>
      <Statistic title="Twitter" value={socialView["twitter"]}  />
    </Col>
    <Col span={8}>
      <Statistic title="Linkedin" value={socialView["linkedin"]}   />
    </Col>
    {/* <Col span={8}>
      <Statistic title="Video Views" value={65} prefix={<CameraVideo size={20}/>}  />
    </Col> */}
  </Row></>}
    </Col>
  )
}

export default AccountActivities