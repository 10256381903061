import React from 'react'

const SettingeView = () => {
    return (
        <div>
            Settings
        </div>
    )
}

export default SettingeView
