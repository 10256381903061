
import React, { useState } from 'react'
import { Button, Col, Row,Checkbox, Form, Input,Radio, Select, InputNumber, Modal  } from 'antd'
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import jsonData from '../../../res/extra/country_dial_list.json';
import { useEffect } from 'react';
import Utils from 'helper/Utils';
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { updatePropertyListing } from '../store/dataSlice';
const { Option } = Select;
const propertySize = [
  "1 RK","1 BHK","2 BHK","3 BHK","4 BHK","5 BHK","6 BHK","7 BHK","8 BHK","9 BHK","10 BHK"
]
const FurnishTypes = [
  "Fully Furnished","Semi Furnished","Unfurnished"
]
const furnishingList = [
  {label:"Dining Table",icon:<i className="icon-dining-table"></i>},
  {label:"Washing Machine",icon:<i className="icon-washing-machine"></i>},
  {label:"Sofa",icon:<i className="icon-sofa"></i>},
  {label:"Microwave",icon:<i className="icon-microwave"></i>},
  {label:"Stove",icon:<i className="icon-stove"></i>},
  {label:"Fridge",icon:<i className="icon-fridge"></i>},
  {label:"Water Purifier",icon:<i className="icon-water-purifier"></i>},
  {label:"Gas Pipeline",icon:<i className="icon-gas-pipeline"></i>},
  {label:"AC",icon:<i className="icon-ac"></i>},
  {label:"Bed",icon:<i className="icon-bed"></i>},
  {label:"TV",icon:<i className="icon-tv"></i>},
  {label:"Cupboard",icon:<i className="icon-cupboard"></i>},
  {label:"Geyser",icon:<i className="icon-geyser"></i>},
]
const amenitiesList = [
  {label:"Lift",icon:<i className="icon-elevator"></i>},
  {label:"CCTV",icon:<i className="icon-CCTV"></i>},
  {label:"Gym",icon:<i className="icon-dumbel"></i>},
  {label:"Garden",icon:<i className="icon-landscaped-garden"></i>},
  {label:"Kids Area",icon:<i className="icon-playground"></i>},
  {label:"Sports",icon:<i className="icon-sports"></i>},
  {label:"Swimming Pool",icon:<i className="icon-pool"></i>},
  {label:"Intercom",icon:<i className="icon-intercom"></i>},
  {label:"Gated Community",icon:<i className="icon-gated-society"></i>},
  {label:"Club House",icon:<i className="icon-club-house"></i>},
  {label:"Community Hall",icon:<i className="icon-community-hall"></i>},
  {label:"Regular Water Supply",icon:<i className="icon-water-supply"></i>},
  {label:"Power Backup",icon:<i className="icon-power-backup"></i>},
  {label:"Pet Allowed",icon:<i className="icon-pet"></i>},
  
]
const ListingPropertyDetails = ({next}) => {
  const [form] = Form.useForm()
   
  const dispatch = useDispatch()

    const [isModalOpen, setIsModalOpen] = useState(false);
   
    const [address,setaddress] = useState("")
    const [propertyAdress, setpropertyAdress] = useState({})
    const [disabledLocalitu, setdisabledLocalitu] = useState(false)
    const citys = useSelector((state) => state.webStreet.data.listingBasicInfo)
    const listingPropertyDetails = useSelector((state) => state.webStreet.data.listingPropertyDetails)
  
    const [basicInfo, setbasicInfo] = useState(listingPropertyDetails)
    const [furnitureList, setfurnitureList] = useState(listingPropertyDetails.furnishingList?listingPropertyDetails.furnishingList:[])
    const [AmenitiesList, setAmenitiesList] = useState(listingPropertyDetails.AmenitiesList?listingPropertyDetails.AmenitiesList:[])
    var center = { lat: 19.1655594, lng:72.8435896 };
// Create a bounding box with sides ~80km away from the center point
const defaultBounds = {
  north: center.lat + 0.3,
  south: center.lat - 0.3,
  east: center.lng + 0.3,
  west: center.lng - 0.3,
};
    const searchOptions = {
      bounds: defaultBounds,
      componentRestrictions: {'country':'in'},
      strictBounds: true,
      
    }
    useEffect(() => {
      form.setFieldsValue(basicInfo)
     }, [form, basicInfo])
    const toggleList = (item)=>{
      
      if(furnitureList.includes(item)){
        const newlist = furnitureList.filter(d => d !== item)
        setfurnitureList(newlist)
      }
      else{
        setfurnitureList([...furnitureList,item])
      }
    }
    const toggleListAmenitie = (item)=>{
      
      if(AmenitiesList.includes(item)){
        const newlist = AmenitiesList.filter(d => d !== item)
        setAmenitiesList(newlist)
      }
      else{
        setAmenitiesList([...AmenitiesList,item])
      }
    }
    const showModal = () => {
      setIsModalOpen(true);
    };
    const handleOk = () => {
      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };
    const onFinish = (values) => {
        
        dispatch(updatePropertyListing({type:"listingPropertyDetails",update:{...values,AmenitiesList:AmenitiesList,furnishingList:furnitureList,propertyAdress:basicInfo.propertyAdress}}))
        next(2)
      };
      const handleChange = address => {
        setdisabledLocalitu(false)
        setaddress(address );
      };
     
     const handleSelect = (address,id,suggestion) => {
      setaddress(suggestion.formattedSuggestion.mainText)
      geocodeByAddress(address)
        .then(results => {
          var arrAddress = results[0].address_components;
          console.log(arrAddress);
          let buildingName = suggestion.formattedSuggestion.mainText
          let cityname= "",locaityName="",state="",country="",postalCode=""
          arrAddress.map((data,k)=>{
            for (const type of data.types) {
              if ( type === "sublocality_level_1") {
                locaityName = data.long_name;
              }
              else if (type === 'administrative_area_level_1') {
                state = data.long_name;
              } else if (type === 'country') {
                country = data.long_name;
              }
              else if (type === 'postal_code') {
                postalCode = data.long_name;
              }
            }
            if (data.types[0] == "locality"){ // locality type
                cityname = data.long_name; // here's your town name
                return false; // break the loop
            }
            else if (data.types[0] == "postal_town"){ // locality type
              cityname = data.long_name; // here's your town name
                  return false; // break the loop
              }
              else if (data.types[0] == "postal_town"){ // locality type
                cityname = data.long_name; // here's your town name
                    return false; // break the loop
                }
          })
          
          // setRestroInfo({...restroInfo,city:cityname,address:results[0]["formatted_address"]})
          getLatLng(results[0]).then(latLng => 
            {
              // setRestroInfo({...restroInfo,address:suggestion["formattedSuggestion"]["secondaryText"],name:suggestion["formattedSuggestion"]["mainText"],city:cityname,address:results[0]["formatted_address"],location:{type:"Point",coordinates:[latLng["lat"],latLng["lng"]]}})
             
              setaddress(buildingName)
              setdisabledLocalitu(locaityName?true:false)
              setbasicInfo({...basicInfo,address:address,Locality:locaityName,propertyAdress:{latLng:latLng,fullAdddress:{address:address,locality:locaityName,city:cityname,state:state,country:country,postalCode:postalCode}}})
              setpropertyAdress({address:address,Locality:locaityName,cityname:cityname,latLng:latLng})
            }
            )
        })
        .catch(error => console.error('Error', error));
     }
  return (
    <>
    <Row>
    <Col xs={24} sm={24} md={18} lg={18} xl={18} className='mx-auto '>
    <Form
     form={form}
      name="normal_login"
      className="login-form"
      initialValues={{
        
        layout: "vertical",
        ...basicInfo
      }}
      
      layout={"vertical"}
      onFinish={onFinish}
      
    >
      <Form.Item
        name="PropertySubType"
        label="Property Type"
        rules={[
          {
            required: true,
            message: 'Please select one!',
          },
        ]}
      >
        <Radio.Group defaultValue={basicInfo.PropertySubType} onChange={(e)=>setbasicInfo({...basicInfo,PropertySubType:e.target.value})} buttonStyle="solid" size="large" className='custom-ant-r' >
            
        <Radio.Button className='rounded-md mx-2 '  value="Apartment">Apartment</Radio.Button>
            <Radio.Button className='rounded-md mx-2 '  value="Independent Floor">Independent Floor</Radio.Button>
            <Radio.Button className='rounded-md mx-2 '  value="Independent House">Independent House</Radio.Button>
            <Radio.Button className='rounded-md mx-2 '  value="Villa">Villa</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="address"
        label=""
        className='mb-3'
        rules={[
          {
            required: basicInfo.PropertyType === "Apartment"?true:false,
            message: 'Please fill this to continue',
            whitespace: true,
          },
        ]}
      >
           <PlacesAutocomplete
        // value={address}
        // onChange={handleChange}
        onSelect={handleSelect}
        required
        searchOptions={searchOptions}
        debounce={300}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <Input {...getInputProps({
                placeholder: 'Search by name',
                className: 'rounded-md p-2',
              })} placeholder={basicInfo.PropertyType === "Apartment"?'Building/Project/Society (Mendatory)':'Building/Project/Society (Optional)'} size='large' className='rounded-md p-2' style={{
            width: 560,
          }}/>
           
            
            <div className={suggestions.length > 0?"autocomplete-dropdown-container rounded":"autocomplete-dropdown-container py-2"}>
              {loading && <div className="r__suggestion-item--active d-flex p-2 border">Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'r__suggestion-item--active d-flex flex-column p-2 border'
                  : 'r__suggestion-item d-flex flex-column p-2 border';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span className="fw-600">{suggestion.formattedSuggestion.mainText}</span>
                    <span className="fz-12">{suggestion.formattedSuggestion.secondaryText}</span>
                  </div>
                );
              })}
              {/* <div className='r__suggestion-item--active d-flex flex-column p-2 border bg-white'>Can't find your Building/project/society? Add New</div> */}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
        
      </Form.Item>
      <Form.Item
        name="Locality"
        label=""
        className='mb-3'
        
        rules={[
          {
            required: true,
            message: 'Please select a valid locality',
            whitespace: true,
          },
        ]}
      >
        <Input disabled={disabledLocalitu} placeholder='Locality' size='large' className='rounded-md p-2' style={{
            width: 560,
          }}/>
        
      </Form.Item>
      <Form.Item
        name="BHK"
        label="BHK"
        className='mb-3'
        rules={[
          {
            required: true,
            message: 'Please select the BHK',
          },
        ]}
      >
        <Radio.Group defaultValue={basicInfo.type} buttonStyle="solid" size="large" className='custom-ant-r' >
        {
              propertySize.map((size,i) => <Radio.Button key={i} className='rounded-md mx-2 mb-2'  value={size}>{size}</Radio.Button>)
            }
            
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="BuiltArea"
        label=""
        className='mb-3'
        rules={[
          {
            required: true,
            message: 'Saleable area should be between 150 and more',
            
          },
        ]}
      >
        <InputNumber controls={false} max={1000000000} addonAfter="Sq. ft." placeholder='Built Up Area' className='rounded-md p-1' style={{
            width: 560,
          }}/>
      </Form.Item>
      <Form.Item
        name="FurnishType"
        label="Furnish Type"
        className='mb-3'
        rules={[
          {
            required: true,
            message: 'Please select the furnish type',
          },
        ]}
      >
        <Radio.Group defaultValue={basicInfo.type} buttonStyle="solid" size="large" className='custom-ant-r' >
        {
              FurnishTypes.map((size,i) => <Radio.Button key={i} className='rounded-md mx-2 mb-2'  value={size}>{size}</Radio.Button>)
            }
            
        </Radio.Group>
      </Form.Item>
      <Form.Item className='pb-3'>
        {furnitureList.length === 0 && AmenitiesList.length === 0 ? <Button onClick={showModal} className='text-purple-600 font-semibold' type='text'>+ Add Furnishings / Amenities</Button>
        :
        <div className='flex flex-row items-center max-w-75 p-3 rounded-md bg-white'>
          <div className='flex flex-col space-y-2'>
            {furnitureList.length > 0 && <div><span className='font-bold'>Flat Furnishings: </span><span>{furnitureList.join(", ")}</span></div>}
            {AmenitiesList.length > 0 && <div><span className='font-bold'>Society Amenities: </span><span>{AmenitiesList.join(", ")}</span></div>}
          </div>
          <Button onClick={showModal} className='text-purple-600 font-semibold' type='text'>+ Add More</Button>  
          </div>
      }
      </Form.Item>
      <Form.Item className='w-50 flex flex-row space-x-4'>
        <Button size='large' className='rounded-lg' onClick={()=>next(0)} >Back</Button>
        <Button type="primary" htmlType="submit"  size='large'  className={`px-3 brd-4 fw-700 mx-2 rounded-lg text-white bg-hs-pink  hover:border-hs-pink `}>
          Next, add price details
        </Button>
       
      </Form.Item>
    </Form>
    </Col>
    <Modal title="Add Furnishings and Amenities" width={650} bodyStyle={{padding:0}} wrapClassName='rounded-md' open={isModalOpen} visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}
    
    footer={[
      
        <Button type="primary" onClick={handleOk} block size='large'  className={`px-3 brd-4 fw-700 mx-2 rounded-lg text-white bg-hs-pink  hover:border-hs-pink `}>
        Save
      </Button>
    ]}
    
    >
        <div className='p-3'>
        <p>Flat Furnishings</p>
        
        <div className="rf-group checkbox-group amenities propertyAmenities">
          {
            furnishingList.map((furniture,i)=> <div onClick={()=>toggleList(furniture["label"])} key={i} className={furnitureList.includes(furniture["label"])?"rf-toggle toggle-active checkbox checkbox-0 toggle-tag pills":"rf-toggle checkbox checkbox-0 toggle-tag pills"}>
            <div className="toggle-label"><div>
              {furniture["icon"]} <span>{furniture["label"]}</span></div></div></div>)
          }
          </div>
        <p className='mt-3'>Society Amenities</p>
        <div className="rf-group checkbox-group amenities propertyAmenities">
          {
            amenitiesList.map((furniture,i)=> <div onClick={()=>toggleListAmenitie(furniture["label"])} key={i} className={AmenitiesList.includes(furniture["label"])?"rf-toggle toggle-active checkbox checkbox-0 toggle-tag pills":"rf-toggle checkbox checkbox-0 toggle-tag pills"}>
            <div className="toggle-label"><div>
              {furniture["icon"]} <span>{furniture["label"]}</span></div></div></div>)
          }
          </div>
        </div>
      </Modal>
  </Row>
  
  </>
  )
}

export default ListingPropertyDetails