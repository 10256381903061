import React from 'react'
import { useState ,useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
// import { Redirect } from 'react-router-dom/cjs/react-router-dom'


const HashResult = (props) => {
  const navigate = useNavigate()
    const [redirectString, setredirectString] = useState(null)
    useEffect(() => {
        const params = props["match"]["params"];
        
        
        let filter = {};
        if(params["user"] && params["id"]){
          filter["type"] = "id";
            filter["contentID"] = params["id"]
          }
          else if(params["tag"]){
            filter["type"] = "tag";
            filter["tag"] = params["tag"]
          }
          else if(params["state"]){
            filter["type"] = "tag";
            filter["tag"] = props.location.search.split("q=")[1]
          }
          
          setredirectString("/search/top?q="+filter["tag"])
          
      return () => {
        
      }
    }, [props])
    
    if(redirectString){
        
        return navigate(redirectString) 
    }
  return (
    <div></div>
  )
}

export default HashResult