import React,{useRef,useEffect,useState} from 'react'
import _ from 'lodash';
import {PlayCircle, PlayCircleFill} from "react-bootstrap-icons";
import QuoteTemplate from "components/Reusable/QuoteTemplate"
import Utils from 'helper/Utils';
const PostCardImage = ({post,clips,customTemplates,content,id,callback,metaInfo,updateContent}) => {
    const [selectedSpeech,SetSelectedSpeech] = useState(null)
    const [selectedSpeechText,SetSelectedSpeechText] = useState(post["speechTemplateText"]?post["speechTemplateText"]:content["quotes"][id])
    
    
    useEffect(() => {
        const bubble = post["speechTemplate"]?post["speechTemplate"]:_.chunk(_.shuffle(customTemplates), 1)[0];
        SetSelectedSpeech(bubble)
        setAndUpdateSpeechBubble(bubble)
        return () => {
            
        }
    }, [post["speechbubble"]])
    const setAndUpdateSpeechBubble = (bubble) =>{
        updateContent("speechTemplate",bubble,selectedSpeechText)
    }
    const getImgUrl = (img) =>{
       
        if(img.visualType === "video"){
            
            const imgv =  img.url?img.url:img.src
            const imgpath =" https://hs-container.s3.ap-south-1.amazonaws.com/"+img["assetData"]["bucketpath"]+"/"+img["assetData"]["name"]+"-thumb.jpg"
            return imgpath
        }
        else{
            return img.url?img.url:img.src
        }
        
       }
    return (
        <>
        {clips && <div className="w-100 px-3 pb-3 mt-3"><div className="StagePreviewHolder Rectangle">
        <div className="Silde-Preview Rectangle">
            <div className=" VisualBox">
            <div className="HeroImageBox NoPadding cursor-pointer" onClick={() =>callback()}>
            <PlayCircle className="cursor-pointer position-absolute color-red" style={{zIndex: 10}} size={30}/>
            <div  className="BluredImage "  style={{"backgroundImage": "url("+clips[0]["visual"]["url"]+")"}} ></div><div key={0} className="SlideVisuals imageType opacity-4"  style={{"backgroundImage": "url("+clips[0]["visual"]["url"]+")"}} ></div>
            </div>
            </div>
            </div>
        </div></div>}
        {!clips && <div className="w-100 px-3 pb-3 mt-3">
         <div className="image-container" >{
         post["useimage"] && post["image"][0] && <div className="post-image relative"><img src={getImgUrl(post["image"][0])} alt="" />
         {post["image"][0]["visualType"] === "video" && <div className='absolute bg text-white z-10 inset-0 flex items-center justify-center cursor-pointer'>
        <PlayCircleFill size={26} /></div>}</div>
        }
        
        {
            post["speechbubble"] &&  <>
          {selectedSpeech && selectedSpeech.map((d,key)=>(<QuoteTemplate key={key} d={d} brandName={d["companyName"]} text={selectedSpeechText} brandAuthor={d["authorName"]} themeColor={d["speech_bubble_color"]} brandImage={d["brandImage"]}  />))}
                </>
        }
        {
            post["hideImage"] && <div className="brd-10 overflow-hidden bg-light"><div className="post-image"><img src={Utils.getImageUrl(metaInfo)} alt="" />
            </div><div className="px-3 py-2"><h6>{metaInfo.title}</h6></div></div>
        }
        {
         !post["useimage"] && !post["hideImage"] && !post["speechbubble"] && post["image"][0] && <div className="post-image relative"><img src={getImgUrl(post["image"][0])} alt="" />
         {post["image"][0]["visualType"] === "video" && <div className='absolute bg text-white z-10 inset-0 flex items-center justify-center cursor-pointer'>
        <PlayCircleFill size={26} /></div>}
         </div>
        }
         </div>
        </div>}
        </>
    )
}

export default PostCardImage