import React,{useState} from 'react'

import {ProgressBar ,Row,Card,Col} from "react-bootstrap";
import {Tooltip,Modal,Progress } from "antd";
import {Hash,ArrowRepeat,Trash} from "react-bootstrap-icons";
import ApiService from "services/ApiService"

import FacebookAuth from 'components/socialauths/FacebookAuth';

import TwitterAuth from 'components/socialauths/TwitterAuth';


import InstaAuth from 'components/socialauths/InstaAuth';
import SocialProfileImages from './SocialProfileImages';
const { confirm } = Modal;
const SocialProfileBox = (props) => {
    const [hashTagState,SetHashState] = useState(props.p.hastag ? props.p.hastag:"On")
    const [profile,setProfile] = useState(props.p)
    const toggleHashState = async(state,id) =>{
        const data = {
            id:id,
            action:"update",
            updates:{hastag:state === "Off"?"On":"Off"}
        }
        await ApiService.PostApiCalls("/creator/socialchannel/update/"+id,{update:data["updates"]})
        SetHashState(state === "Off"?"On":"Off")
    }
    const removeProfile = (id) =>{
        const data = {
            id:id,
            action:"remove",           
        }
        confirm({
            title: 'Are you sure?',               
            content: 'Are you sure you want to remove this social profile? Any campaign / social content linked to this profile will be permanently removed.',
            okText: 'Yes',
            cancelText: 'No',
            okButtonProps:{ type: "primary",className:"brd-4" },
            okType: 'danger',
            cancelButtonProps:{ className:"brd-4 mr-3" },
            async onOk() {
                 const wait = await ApiService.PostApiCalls("/creator/socialchannel/delete/"+id,data,true);
                 if(wait[1]){
                    
                    props.removeProfile(id)   
                 }
                               
            },
            onCancel() {
              
            },
          });
    }
    const getPer = (val)=>{
        if(!props.alls){
            return 0;
        }
        let sum = 0;
        props.alls.map((d)=>{
            sum = sum+d["Total"]
        })
        return sum === 0? 0:parseInt((100*val)/sum);
    }
    
    const randomXToY = (minVal,maxVal) =>
    {
    var randVal = minVal+(Math.random()*(maxVal-minVal));
    return Math.round(randVal);
    }
    const now = 1;
    return (
        <>
        {!profile && "null"}
        {profile && <div className="social-profile-container d-flex flex-column justify-content-between position-relative overflow-hidden ">
        <div className="d-flex  w-100 position-relative">
        <SocialProfileImages p={props.p} id={props.p.id}/>
        {!props.analytics &&<span >{props.name}</span>}
        {props.analytics &&  <span className="float-left text-left pt-2 pl-1 ">{props.name}<br></br> {props.count} Clicks</span>}
        {!props.analytics && <div onClick={() => removeProfile(props.p.spid)} className="position-absolute cursor-pointer py-2 abs-right-5">
        <Trash  color="#FF0000" size={18} /></div>}
        </div>
        {!props.analytics && <div className="d-flex w-100 d-flex justify-content-around cursor-pointer">
            {props.p.source === "Facebook" && 
            <div className="fz-10 all-header-dark d-flex align-items-center text-xs">
            <FacebookAuth spid={props.p.spid} change={true} callback={props.getConnectionResponse} />
            </div>}
            <span onClick={() =>toggleHashState(hashTagState,props.p.spid)}>
            <Tooltip overlayClassName="brd-4" color="#2db7f5" title="Would you like to include Hashtags on social content sent out to this profile?" ><div className="fz-10 all-header-dark d-flex align-items-center">
            <Hash size={12} className={hashTagState === "Off"?"mr-1 mrt-m3 color-red text-hs-pink":"mr-1 text-hs-blue mrt-m3" } />
            <span className={hashTagState === "Off"?"text-hs-pink text-xs":"text-xs" }>{hashTagState === "Off"?"Hashtags Off":"Hashtags On"}</span></div></Tooltip>
            </span><Tooltip color="#2db7f5" overlayClassName="brd-4 " title="You should only need to do this if you received an email from us asking you to reconnect this social account." ><div className="fz-10 all-header-dark d-flex align-items-center">
            
            {props.p.source === "Facebook" && 
            <div className="fz-10 all-header-dark d-flex align-items-center  text-xs">
            <FacebookAuth spid={props.p.spid} pid={props.p.id} reconnect={true} callback={props.getConnectionResponse} />
            </div>}
            {props.p.source === "Twitter" && 
            <div className="fz-10 all-header-dark d-flex align-items-center  text-xs">
            <TwitterAuth spid={props.p.spid} pid={props.p.id} reconnect={true} callback={props.getConnectionResponse} />
            </div>}
            {props.p.source === "Instagram" && 
            <div className="fz-10 all-header-dark d-flex align-items-center text-xs">
            <InstaAuth spid={props.p.spid} pid={props.p.id} reconnect={true} callback={props.getConnectionResponse} />
            </div>}
            {props.p.source === "Linkedin" && 
            <div className="fz-10 all-header-dark d-flex align-items-center  text-xs" onClick={()=>props.LinkedinRoconnect(props.p.id,props.p.name,props.p.spid)}>
            <ArrowRepeat size={12} className="mr-1 text-hs-blue mrt-m3" />
        <span>Reconnect</span>
            </div>}
            </div></Tooltip>
            
        </div>}
        {props.analytics && <ProgressBar now={getPer(props.count)} showInfo={false} label={`${getPer(props.count)}%`} className="position-absolute fixed-bottom"/>}
        </div>}
        </>
    )
}

export default SocialProfileBox
