import { Button, Col, Form, Input, Row,Select } from 'antd'
import { AuthContext } from 'app/AuthProvider'
import React from 'react'
import { useContext } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ApiService from 'services/ApiService'
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete';
const CreateGroup = () => {
  const navigate = useNavigate()
const {authUser} = useContext(AuthContext)
  const [category, setcategory] = useState(null)
  const [subcategory, setsubcategory] = useState(null)
  const [privacy, setprivacy] = useState(null)
  const [grpLocation, setgrpLocation] = useState(null)
  const [nextDisabled, setnextDisabled] = useState(false);
  const [sendTopage, setsendTopage] = useState(null)
  const [address,setaddress] = useState("")
  const searchOptions = {
    componentRestrictions: {'country':'in'},
    types: ['locality', 'country']
  }
  const handleChange = address => {
    
    setaddress(address );
    setgrpLocation(address)
  };
 
 const handleSelect = (address,id,suggestion) => {
  setaddress(address);
  setgrpLocation(address)
 }
  const [savingForm, setsavingForm] = useState(false);

    const onFinish = async (values) => {
        const data = {
            name: values.groupName,
            creatorCategoryText: values.topicson === "Other"?values.topicsonOther:values.topicson,
            creatorSubcategoryText: values.subtopicson === "Other"?values.subtopicsonOther:values.subtopicson,
            privacy:values.grpprivacy,
            location:{type:"location",address:grpLocation,coordinates:[0,0]},
            owner:{
                id:authUser.uniqueId,
                email:authUser.email,
                name:authUser.name,
            }
          };
          setnextDisabled(true);
          setsavingForm(true);
          const formsaved = await ApiService.PostApiCalls(
            "group/create",
            data,
            true
          );
          if(formsaved[0] && formsaved[1]["IsSuccessful"]){
            setsendTopage(formsaved[1]["userName"])
          }
          else{
      
          }
          setnextDisabled(false);
          setsavingForm(false);
    }
    const onFinishFailed = async (values) => {
    }
    if (
      sendTopage
    ) {
      return navigate(`/groups/${sendTopage}`);// <Redirect to={`/groups/${sendTopage}`} />;
    }
  return (
    <Row>
        <Col span={14} offset={5} className="rounded-xl p-5 bg-white">
            <Form
                 name="basic"
                 requiredMark={false}
                 layout="vertical"
                 initialValues={{
                   remember: true,
                 }}
                 onFinish={onFinish}
                 onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  wrapperCol={{
                    span: 15,
                  }}
                  
                  label={
                    <span className="font-medium text-base pb-2">
                                         Group Name
  
                    </span>
                  }
                  name="groupName"
                  rules={[{ required: true, message: "Group Name" }]}
                >
                  <Input className="p-2 rounded-xl" placeholder={"Enter name"} />
                </Form.Item>
                <Form.Item
                label={
                  <span className="font-medium text-base pb-2">
                                        What is your social cause?

                  </span>
                }
                wrapperCol={{
                  span: 15,
                }}
                name="topicson"
                rules={[
                  {
                    required: true,
                    message:"Tell what you are here for",
                  },
                ]}
              >
                {/* <Input placeholder="blog, music, videos, art & craft" /> */}
                <Select  placeholder={"Select category"} onChange={(e)=>setcategory(e)}>
          <Select.Option value="Poverty & Hunger">Poverty & Hunger</Select.Option>
          <Select.Option value="Health & Well Being">Health & Well Being</Select.Option>
          <Select.Option value="Quality Education">Quality Education</Select.Option>
          <Select.Option value="Gender Equality">Gender Equality</Select.Option>
          <Select.Option value="Clean Water & Sanitation">Clean Water & Sanitation</Select.Option>
          <Select.Option value="Affordable & Clean Energy">Affordable & Clean Energy</Select.Option>
          <Select.Option value="Decent work and economic growth">Decent work and economic growth</Select.Option>
          <Select.Option value="Industry, Innovation and Infrastructure">Industry, Innovation and Infrastructure</Select.Option>
          <Select.Option value="Traditional Industry revamping">Traditional Industry revamping</Select.Option>
          <Select.Option value="Sustainable Cities and Communities">Sustainable Cities and Communities</Select.Option>
          <Select.Option value="Responsible Consumption & Production">Responsible Consumption & Production</Select.Option>
          <Select.Option value="Climate Action">Climate Action</Select.Option>
          <Select.Option value="Life on Land">Life on Land</Select.Option>
          <Select.Option value="Peace, Justice  & Strong Institutions">Peace, Justice  & Strong Institutions</Select.Option>
          <Select.Option value="journalism">Journalism</Select.Option>
          <Select.Option value="Other">Other</Select.Option>
          
        </Select>
              </Form.Item>
              {
               category === "Other" && <Form.Item
               initialValue=""
               wrapperCol={{
                 span: 15,
               }}
               name="topicsonOther"
               rules={[
                 {
                   required: true,
                   message: "Missing!",
                 },
               ]}
             >
               <Input placeholder="Spacify category" />
             </Form.Item>
             } 
             <Form.Item
                className="py-2"
                wrapperCol={{
                  span: 15,
                }}
                name="subtopicson"
                rules={[
                  {
                    required: true,
                    message: "Select sub-category"
                  },
                ]}
              >
                {/* <Input placeholder="blog, music, videos, art & craft" /> */}
                <Select  placeholder="Select sub-category" onChange={(e)=>setsubcategory(e)}>
          <Select.Option value="Art">Art</Select.Option>
          <Select.Option value="Business Professional-Consultant">Business Professional/Consultant</Select.Option>
          <Select.Option value="Digital">Digital(Blog, Instagram, TikTok, YouTube, etc.)</Select.Option>
          <Select.Option value="Film-TV">Film/TV</Select.Option>
          <Select.Option value="Gaming">Gaming</Select.Option>
          <Select.Option value="Media-Editorial">Media/Editorial</Select.Option>
          <Select.Option value="Music">Music</Select.Option>
          <Select.Option value="Podcast-Social Audio">Podcast/Social Audio</Select.Option>
          <Select.Option value="Sports">Sports</Select.Option>
          <Select.Option value="News-Articles">News & Articles</Select.Option>
          <Select.Option value="Indian-Astrology">Indian Astrology</Select.Option>
          <Select.Option value="Other">Other</Select.Option>
         
          
        </Select>
              </Form.Item>
              {
               subcategory === "Other" && <Form.Item
               initialValue=""
               wrapperCol={{
                 span: 15,
               }}
               name="subtopicsonOther"
               rules={[
                 {
                   required: true,
                   message: "Missing!",
                 },
               ]}
             >
               <Input placeholder="Spacify Sub-category" />
             </Form.Item>
             } 
             <Form.Item
                label={
                  <span className="font-medium text-base pb-2">
                                       Privacy

                  </span>
                }
                wrapperCol={{
                  span: 15,
                }}
                name="grpprivacy"
                rules={[
                  {
                    required: true,
                    message:"Select privacy",
                  },
                ]}
              >
              {/* <Input placeholder="blog, music, videos, art & craft" /> */}
              <Select  placeholder="Select privacy" popupClassName='rounded-xl' onChange={(e)=>setprivacy(e)}>
        <Select.Option  value="Public">Public</Select.Option>
        <Select.Option value="Private">Private</Select.Option>
        
       
        
      </Select>
            </Form.Item>
            <Form.Item
                  wrapperCol={{
                    span: 15,
                  }}
                  
                  label={
                    <span className="font-medium text-base pb-2">
                                         Location
  
                    </span>
                  }
                  value={address}
                  name="grpLocation"
                  rules={[{ required: false, message: "Location" }]}
                ><Input className='d-none' />
                  <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
        required
        searchOptions={searchOptions}
        debounce={300}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: 'Search by city',
                className: 'p-2 rounded-xl w-100 form-control',
              })}
              required
            />
            
            <div className={suggestions.length > 0?"autocomplete-dropdown-container border-2 py-2":"autocomplete-dropdown-container py-2"}>
              {loading && <div className="r__suggestion-item--active d-flex">Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'r__suggestion-item--active d-flex flex-column'
                  : 'r__suggestion-item d-flex flex-column';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span className="fw-600">{suggestion.formattedSuggestion.mainText}</span>
                    <span className="fz-12">{suggestion.formattedSuggestion.secondaryText}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
                  
                </Form.Item>
                
             {/* <Form.Item
                label={
                  <span className="font-medium text-base pb-2">
                   HashSaga  URL
                  </span>
                }
                wrapperCol={{
                  span: 15,
                }}
                initialValue={""}
                name="usernameurl"
                validateStatus={urlValidateStatus}
                help={urlHelpTips}
                rules={[
                  {
                    required: true,
                    message: "Your URL is missing!",
                  },
                ]}
              >
                <Input
                  prefix={
                    <span className="opacity-70">https://hashsaga.com/</span>
                  }
                  onChange={(e) => verifyURL(e)}
                />
              </Form.Item> */}
              <Form.Item
                wrapperCol={{
                  offset: 0,
                  span: 24,
                }}
              >
                <div className="py-3"></div>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  className="float-right rounded-xl bg-red-1 px-4 border-0"
                  loading={savingForm}
                  disabled={nextDisabled}
                >
                  Create
                </Button>
              </Form.Item>
              </Form>
          
          </Col>
    </Row>
  )
}

export default CreateGroup