import React, {createContext, useState} from 'react';
export const AuthContext = createContext();

export const AuthProvider = ({children}) => {
    const [authUser, setAuthUser] = useState(null);
    const [activeWorkSpace, setactiveWorkSpace] = useState(null);
    const [selectedPlan, setselectedPlan] = useState();
    const [showSignup, setshowSignup] = useState(false)
    const [setupCompleted, setsetupCompleted] = useState({
        socialProfile: true,
        rssFeed: false,
        firstPost: false,
      });
    return (
        <AuthContext.Provider
           value={{authUser,setAuthUser,activeWorkSpace,setactiveWorkSpace,setupCompleted, setsetupCompleted,selectedPlan, setselectedPlan,
            showSignup, setshowSignup
          
          }}
           >
            {children}
        </AuthContext.Provider>)
}


