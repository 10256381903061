import { AuthContext } from 'app/AuthProvider'
import AppPlanCard from 'container/common/AppPlanCard'
import React, { useContext, useEffect, useState } from 'react'
import { CircleFill } from 'react-bootstrap-icons'
import ApiService from 'services/ApiService'
import AuthModal from './AuthModal'
import { useTranslation } from 'react-i18next';
import { Col, Radio } from 'antd'

const AppSubPlans = ({callback}) => {
  const { t } = useTranslation();
    const [plans, setplans] = useState([])
  const [isVisible, setisVisible] = useState(false);
    const [planId, setplanId] = useState(2)
    const [billingCycle, setBillingCycle] = useState('yearly');

    const optionsBilling = [
      {
        label: 'Monthly',
        value: 'monthly',
      },
      {
        label: 'Yearly',
        value: 'yearly',
      }
    ];
    useEffect(() => {
        getSubPlans();
      return () => {
        
      }
    }, [])
    const setBillingPeriod = ({ target: { value } }) => {
        
      setBillingCycle(value);
    };
    const getSubPlans = async()=>{
        const planslist = await ApiService.GetApiCalls("subscriptions/list","",false)
        if(planslist[0] && planslist[1]["IsSuccessful"]){
            setplans(planslist[1]["plans"])
        }
    }
    const openWaitingForm = (id)=>{
      setplanId(id)
      setisVisible(true)
  }
  return (
    <>
    <Col className="mx-auto w-full" xs={24} sm={24} md={24} lg={24} xl={24}>
            <div className="text-2xl font-semibold text-center space-x-4">{t("bestplanline")}</div>
            <div className="flex-1 py-4">
            <span className='flex flex-col items-center justify-center space-y-2 py-3 text-sm'>
                    <span className='flex items-center '><strong>Billing Cycle:</strong>&nbsp;<span class="text-hs-pink">Save 15% </span>&nbsp;<span>with yearly</span></span>
                    <span className='flex items-center space-x-2'>
                    <Radio.Group options={optionsBilling} onChange={setBillingPeriod} value={billingCycle} optionType="button"  buttonStyle="solid" className='bg-hs-pink' />
      </span>
                </span>
            </div>
            <div className="flex-1 ">
            <div className="flex justify-between flex-wrap w-full">
                {
                    plans.map((plan,key)=>
                    <AppPlanCard plan={plan} billingCycle={billingCycle} key={key} showaction={true} openWaitingForm={openWaitingForm}   />
                    )
}
</div>

            </div>
            
             <div className="flex-1 py-10"></div>
            </Col>
            <AuthModal
        isVisible={isVisible}
        type={"wait"} planId={planId}
        isWhite={true}
        cancelCallback={() => setisVisible(false)}
        creatorCallback={() => {
          
          setisVisible(false);
        }}
      />
            </>
  )
}

export default AppSubPlans