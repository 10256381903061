import React,{useState,useEffect,useRef,useContext} from 'react';
import {Row,Col} from "react-bootstrap";
import ApiService from "services/ApiService";
import {PlayCircle,PauseCircle} from "react-bootstrap-icons"
import SucessChecked from "res/icons/SucessChecked"

import {Switch} from "antd"
import { VideoContext } from 'app/VideoProvider';

const MusicView = ({akey,music,callback,current}) => {
    const { video,setVideo,videoId} = useContext(VideoContext);
    const [list, setList] = useState([])
    const [soundfile, setsoundfile] = useState("")
    const [playing, setPlaying] = useState(false)
    const [soundid, setsoundid] = useState("")
    const [selected, selectTrack] = useState("")
    const [musicOn,setMusicState] = useState(true)
    
    const audioplayer = useRef();
    const audioplayerSource = useRef();
   
    useEffect(() => {
        
        if((current === parseInt(akey)) && list.length === 0){
            getMusicList();
        }
        
        selectTrack(video?video["musicEnabled"]?video["music"]["id"]:"":"");
        setsoundfile("")
        setsoundid("")
        audioplayer.current.pause();
        setPlaying(false)
      return () => {
        
      };
    }, [akey])
    const getMusicList = async () =>{
        let q = "q=&source=cc&limit=10&offset=0"
        // if(q !== ""){
        //   q = "q="+s+"&source=cc&limit=10&offset=0"
        // }
        const m = await ApiService.GetApiCalls("audio",q, true);
        if (m[0] && m[1]["IsSuccessful"]) {
          setList(m[1]["list"]);
         
        }
        // const m = await ApiService.getMusicList();
        // if(m[0] && m[1]["success"]){
        //     setList(m[1]["music"])
        // }
        
    } 
    const PlayAudio = (a)  =>{
        setsoundfile(a["mp3_url"])
        audioplayerSource.current.src = a["mp3_url"];
        setsoundid(a["uuid"])
        if (audioplayer.current !== null) {
            audioplayer.current.pause();
            audioplayer.current.load();
            audioplayer.current.play();
            setPlaying(true)
        }
    }
    const PauseAudio = (a)  =>{
        setsoundfile("")
        setsoundid("")
        if (audioplayer.current !== null) {
            audioplayer.current.pause();
            setPlaying(false)
        }
    }
    const selectMusicTrack = (m) =>{
        selectTrack(m.uuid)
        // callback(m)
        let videoDetails = video;
        videoDetails["music"] = m;
        videoDetails["musicEnabled"] = musicOn;
        setVideo(videoDetails)
        updateServer({music:m,musicEnabled:musicOn})
    }
    const updateServer = async(input) =>{
        const data = {
            vid:videoId,
            update:input
        }
        // await ApiService.updateVideoData(data);
        await ApiService.PostApiCalls("campaign/videoUpdate",{...data},true)
    }
    const changeMusic = (e) =>{
        if(!e){
            setMusicState(false)
            selectTrack("")
        }
        else{
            setMusicState(true)
            selectTrack(video["music"]["id"]?video["music"]["id"]:"")
        }
        let videoDetails = video;
       
        videoDetails["musicEnabled"] = e;
        setVideo(videoDetails)
        updateServer({musicEnabled:e})
    }
    return (
        <Row className="mx-0 music-list">
            <Col  lg={12}  md={12} sm={12} className="pl-4 text-left my-1" >
                <span className="fw-bold fz-16">Music - </span>
                <Switch  checkedChildren="On" unCheckedChildren="Off" onChange={(e)=>changeMusic(e)} defaultChecked={musicOn} />
            </Col>
         {
             list.map((m,key) =>(
                <Col key={key} lg={6}  md={6} sm={12} className="py-1 text-left my-2" >
                <div className="d-flex flex-row align-items-center justify-content-start">
                {playing && soundid === m.uuid && <PauseCircle size={20} color="#30B67C" onClick={() => PauseAudio(m)} className="mr-2 cursor-pointer"/>}
                {playing && soundid !== m.uuid  && <PlayCircle size={20} onClick={() => PlayAudio(m)} className="mr-2 cursor-pointer"/>}
                {!playing && <PlayCircle size={20} onClick={() => PlayAudio(m)} className="mr-2 cursor-pointer"/>}
                
                <span onClick={() => selectMusicTrack(m)} className="title cursor-pointer"> {m.name}</span>
                { selected === m.uuid && <SucessChecked className="ml-3"/>}
                </div>
               
                </Col>
             ))
         }
         
         <div className="hidden">
             <audio ref={audioplayer} type='audio' >
             <source ref={audioplayerSource} src={soundfile}  />
             </audio>
         </div>
        </Row>
    )
}

export default MusicView
