import React,{useState,useContext} from 'react'
import {Modal} from "react-bootstrap";
import { Button,Spin ,Input,Space,message ,Tooltip,DatePicker } from 'antd';
import Loading from "../../../res/icons/Loading"
import {Upload as UploadIcon,TrashFill,CheckCircle} from "react-bootstrap-icons";
import ImageUpload from "components/Reusable/ImageUpload"
import {EditIcon,DeleteIcon} from "res/icons/ReviewIcons"

import _ from 'lodash';
import moment from 'moment';

import ApiService from "services/ApiService"
import Utils from 'helper/Utils';
import FacebookAuth from 'components/socialauths/FacebookAuth';
import TwitterAuth from 'components/socialauths/TwitterAuth';
import LinkedinAuth from 'components/socialauths/LinkedinAuth';
import InstaAuth from 'components/socialauths/InstaAuth';
import { AuthProvider } from 'app/AuthProvider';
const { TextArea } = Input;
const NewPost = (props) => {
    const user = props.authuser;
    const activeWorkSpace = props.activeWorkSpace;
    const [show, setShow] = useState(props.show);
    const [title,setTtile] = useState("");
    const [content,setContent] = useState("");
    const [buttonOff, setButton] = useState(false)
    const [isProgressing,setProgressing] = useState(false)
    const [timeSelected,setTimeSelected] = useState([])
    const [ActiveImages, setActiveImages] = useState([])
    const [isError,setError] = useState(false);
    const [showUpload,setshowUpload] = useState(true);
    const [view,setView] = useState(1)
    const [selectedProfile,setSelectedProfile] = useState(props.profiles);
    const [selectedProfileIds,setSelectedProfileIds] = useState([]);
    const [incheckList,setincheckList] = useState([]);
    const [socialConnectionType,setSocialConnectionType] = useState(0);
    const [btnText, setBtnText] = useState("Create")
    
    
    
  const handleClose = () => {
      setShow(false)
      props.callback(false)
  };
  const handleDone = () =>{
    window.location.reload();
  }
  const ImageUploadCallback = (images) =>{       
    setActiveImages(images)
    setshowUpload(false)
    // props.callback(images)
    // setTimeout(
    //     () =>  props.update({i:images}), 
    //     500
    // );
}
const getConnectionResponse = async (selected,all,channel) =>{
    
    const wid = user["workspace"][0]["wid"];
    if(channel === "Facebook"){
        const isThere = _.filter(selectedProfile, function (time,i) {
          return time["id"] === selected["id"];
        });    
    
      if(isThere.length === 0){
        let profiles = [...selectedProfile,selected];
        let profilesId = [...selectedProfileIds,selected["id"]];
        setSelectedProfile(profiles);     
        setSelectedProfileIds(profilesId); 
        setincheckList([...incheckList,selected])        
        setSocialConnectionType(1);
        // await ApiService.linkSocialProfile(selected,user["userId"],wid);
        
      }
    }
    else if(channel === "Instagram"){
      const isThere = _.filter(selectedProfile, function (time,i) {
        return time["id"] === selected["id"];
      });    
  
    if(isThere.length === 0){
      let profiles = [...selectedProfile,selected];
      let profilesId = [...selectedProfileIds,selected["id"]];
      setSelectedProfile(profiles);     
      setSelectedProfileIds(profilesId); 
      setincheckList([...incheckList,selected])        
      setSocialConnectionType(1);
      // await ApiService.linkSocialProfile(selected,user["userId"],wid);
      
    }
  }
    if(channel === "Twitter"){
      let profiles = [...selectedProfile,selected];
        let profilesId = [...selectedProfileIds,selected["id"]];
        setSelectedProfile(profiles);     
        setSelectedProfileIds(profilesId);    
        setincheckList([...incheckList,selected])     
        setSocialConnectionType(2);
        // await ApiService.linkSocialProfile(selected,user["userId"],wid);
        
    }
    else if(channel === "Linkedin"){
        let profiles = [...selectedProfile,selected];
        let profilesId = [...selectedProfileIds,selected["id"]];
        setSelectedProfile(profiles);     
        setSelectedProfileIds(profilesId);
        setincheckList([...incheckList,selected])     
        setSocialConnectionType(3);
        // await ApiService.linkSocialProfile(selected,user["userId"],wid);
       
    }
    const added = await ApiService.PostApiCalls(
      "/creator/socialchannel/add",
      { profile: {...selected,wid:activeWorkSpace["wid"]} },
      true
    );
    
  }
  const removeLink = async (k,id) =>{
    console.log(id);
    const profilesId = _.remove(incheckList, function (time,i) {
      return time["id"] !== id;
    });
    
    setincheckList(profilesId); 
    
  }
  const onChange = (value, dateString,key,id) =>{
    if(value){

    }
    else{
      let old = timeSelected;
      const newdate = {
        id:id,key:key,time:0
      }
      setTimeSelected([...timeSelected,newdate])
    }
    
  }
  const onOk = (value,key,id) =>{
      let old = timeSelected;
      const newdate = {
        id:id,key:key,time:value.unix()
      }
      if(old.length === 0){
        setTimeSelected([...timeSelected,newdate])
      }
      else{
        let newon = []
        let has = false;
        for(let i = 0; i < old.length;i++){
            
            if(old[i]["id"] === id){
              newon[i] = newdate;
              has = true;
            }
            else{
              newon.push(old[i])
            }
        }
        if(!has){newon.push(newdate)}
        setTimeSelected(newon)
      }
      
      
      
      
  }
const createPost = async () =>{
    if(view === 1){
    if((content.replace(/ /g,"")).length === 0){

        message.error('Please add content');
        return false;
    }
    setBtnText("Next")
    setView(2)
    }
    else if(view === 2){
        if(incheckList.length === 0){
            message.error('Select atleast one profile');
        return false;
      }
      const allSources = _.map(incheckList, 'source');
      if(allSources.includes("Instagram") && ActiveImages.length ===0){
        message.error('Image is mendatory for Instagram Post');
        return false;
      }
      setBtnText("Save")
      setView(3)
    }
   
    else if(view === 3){
      
        //SavePost and Data
        const postData = {
          title:title,
          content:content,
          image:ActiveImages,
          profiles:incheckList,
          timeSelected:timeSelected
        }
        // console.log(postData);
        setProgressing(true)
        const posted = await ApiService.PostApiCalls("creator/create-custom-post",{post:postData},true);
        if(posted[0] && posted[1]["IsSuccessful"]){
        
          setProgressing(false)
          setView(4)
        }
        
    }
}
const range = (start, end) =>{
  const result = [];
  
  for (let i = start; i < end; i++) {

    if (i % 5 != 0){
      result.push(i);
    }
  }
  return result;
}
const disabledDateTime = ()=>{
  return {
    
    disabledMinutes: () => range(0, 60),
   
  };
}
const disabledDate=(current) =>{        
  return current && current < moment().subtract(1, 'day');
}
const selectLink = (e,k) =>{
    const isThere = _.filter(selectedProfile, function (time,i) {
        return time["id"] === k;
    });   
    let profiles = [...incheckList,isThere[0]]; 
    setincheckList(profiles); 
    
}
const removeImage = (e,k) => {
       
    const remainingimages = _.filter(ActiveImages, function (img,i) {
        return img["uid"] !== k;
      });
     
      setshowUpload(true)
      setActiveImages(remainingimages);
     
}
  const spinIcon =  <div className="anticon anticon-loading anticon-spin ant-spin-dot"><Loading  /></div>;
    return (
        <div>
           <Modal show={show} onHide={handleClose} centered size="lg" contentClassName="campaign-new-modal" backdrop={"static"}>
        <Modal.Header >
          <span className="modal-title">Create Post</span>
        </Modal.Header>
        <Modal.Body>
       {!isProgressing && view === 1 && <div className="p-4 w-100">
       <Space direction="vertical" className="w-100 py-2">
           {/* <Input placeholder="Add title" className="brd-4 py-2 mt-4"  onChange={(e)=>setTtile(e.target.value)} /> */}

           <TextArea showCount maxLength={700} className="brd-4 py-2" onChange={(e)=>setContent(e.target.value)} value={content} placeholder="Add content, hashtags, links etc."
          autoSize={{ minRows: 3, maxRows: 6 }} />
          
          {showUpload && <ImageUpload  prefix={"images"} showUploadList={false} multipleImages={false} callback={ImageUploadCallback} list={ActiveImages} limit={2} listType="none"
        view={<div className="upload-imagebt"><UploadIcon color="#30B67C" /><span className="ml-2 pt-1 fw-700">Upload image</span></div>} 
        viewUploading={<div className="upload-imagebt">
        <Spin size="small" indicator={spinIcon} spinning={true} />
        <span className="ml-2 pt-1 fw-700">Uploading...</span> </div>}

        />}
        <span className="fz-12 fw-600 my-3 px-3">Image is mendatory for Instagram Post</span>
        </Space>
        <div className="images-gallery postnew">
        {
            ActiveImages.map((img,key) =>(
                <div className="item" key={key}>
        <img src={img.url?img.url:img.src} alt=""></img>    
        <div className="image-action">
        <div className="abtn" onClick={(e) => removeImage(e,img["uid"])}><DeleteIcon /></div>
          
        </div>
        </div>
            ))
        }
       
        </div>
       </div>}
       {!isProgressing && view === 2 && <div className="p-4 w-100"> <div className="d-flex justify-content-center my-4">
       <Tooltip placement="top" title={"Connect your Facebook profile to get started with, and select profile or page to connect with Hash Saga. Later, all future campaigns post will be shared on the same profle."}>
    <div className={socialConnectionType === 1 ?"social-connection connect box--shadow  active":"social-connection connect box-shadow"} >
    <FacebookAuth callback={getConnectionResponse} /></div></Tooltip>
    <div className={socialConnectionType === 2 ?"social-connection connect box--shadow active":"social-connection connect box-shadow"} >
    <TwitterAuth callback={getConnectionResponse} /></div>
    <div className={socialConnectionType === 3 ?"social-connection connect box--shadow active":"social-connection connect box-shadow"} >
    <LinkedinAuth callback={getConnectionResponse}   /></div>  
    <div className={socialConnectionType === 4 ?"social-connection connect box--shadow active":"social-connection connect box-shadow"} onClick={() => setSocialConnectionType(4)}>
    <InstaAuth callback={getConnectionResponse}   /></div>
    
    </div>
    {/* <span className="pl-2 fw-600 pt-3">Instagram is required <a target="_blank" href="https://help.instagram.com/502981923235522?fbclid=IwAR216hIf5N_9nLD2kIVZpoEZ-kr_SuCbJ1MF6liJSr-iE_o3op0X9xO4JtM">Instagram Professional accounts</a></span>              */}
    {selectedProfile.length > 0 && <div className="pt-3">
        {
          selectedProfile.map((p,key) =>(
            <div key={key}  className={_.map(incheckList, 'id').includes(p["id"])?"social-connection box-shadow selected w-100 d-flex justify-content-between align-items-center mb-2 purple-4":"social-connection box-shadow selected w-100 d-flex justify-content-between align-items-center  mb-2"} >
          <span className="fz-18 fw-600 px-4 flex align-items-center">
          <img src = {p["source"] === "Facebook" ? p["picture"]["data"]["url"]:p["fb-picture"]} className="mr-3  w-8" height="30"  alt={p["name"]}/>
          <span>{p["name"]} ({p["source"]})</span></span>
          {_.map(incheckList, 'id').includes(p["id"])?<span className="fz-18 fw-700 px-4 cursor-pointer" onClick={()=>removeLink(key,p["id"])} >
          <TrashFill className="cursor-pointer" color={"red"} size={16}/>
          </span>:<span className="fz-18 fw-700 px-4 cursor-pointer" onClick={()=>selectLink(key,p["id"])} >
          <CheckCircle className="cursor-pointer" color={"green"} size={16}/>
          </span>}
          
        </div>
          ))
        }
        </div>}
  </div>
    
      }
      {!isProgressing && view === 3 && <div className="p-4 w-100">
      {incheckList.length > 0 && <div className="pt-3">
        {
          incheckList.map((p,key) =>(
           <> <div key={key}  className={_.map(incheckList, 'id').includes(p["id"])?"social-connection box-shadow selected w-100 d-flex justify-content-between align-items-center mb-2 ":"social-connection box-shadow selected w-100 d-flex justify-content-between align-items-center  mb-2"} >
          <span className="fz-18 fw-600 px-4 flex align-items-center">
          <img src = {p["source"] === "Facebook" ? p["picture"]["data"]["url"]:p["fb-picture"]} className="mr-3 w-8" height="30"  alt={p["name"]}/>
          <span>{p["name"]} ({p["source"]})</span>
          </span>
          
          
        </div><div className="pl-3 pt-2 fz-16 mb-3" ><span className="mr-3">Set Date & Time</span><DatePicker disabledDate={disabledDate} allowClear={false}  format="DD-MM-YYYY HH:mm" showNow={false} disabledTime={disabledDateTime} showTime={{ defaultValue: moment('00:00', 'HH:mm') }} onChange={(e,date)=> onChange(e,date,key,p["id"]) } onOk={(e)=> onOk(e,key,p["id"]) } /><span className="ml-2 fw-12 color-red">If no time selected, posted in next 5 minutes.</span></div></>
          ))
        }
        </div>}</div>}
        {!isProgressing && view === 4 && <div className="p-4 w-100 mt-4">
          <span className="fz-18 fw-600 py-4 mt-5">That's it. Your added post will up soon or on time selected</span>
        </div>
        }
       
        {isProgressing && !isError &&
        <div className="d-flex align-items-center flex-column justify-content-center mt-4">
        <Spin size="small" indicator={spinIcon} spinning={true}  />
        <span className="fw-600 fz-16 mt-4">One moment please</span>
        </div>
        }
        {isProgressing && isError &&
        <div className="d-flex align-items-center flex-column justify-content-center mt-4">
        <span className="fw-600 fz-16 mt-4">Oops there was a problem.</span>
        <span className="fw-600 fz-14 mt-2">Did you enter a valid URL? If you did, your site might be blocking.</span>
        </div>
        }
        </Modal.Body>
        <Modal.Footer className="mt-4">
        {view !== 1 && <Button variant="secondary" className="float-left" onClick={()=>{setView(view-1);setBtnText("Next")}}>
          Back
        </Button>
        }
        {view !== 4 && <><Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        {!isProgressing && <Button type="primary" onClick={(e)=>createPost()}>
        {btnText}
        </Button>} </>}
        {view === 4 && <Button type="primary"  variant="secondary" onClick={handleDone}>
          Done
        </Button>}
        </Modal.Footer>
      </Modal> 
        </div>
    )
}

export default NewPost
