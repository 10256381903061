import React from 'react'
import { Row, Col, Typography, Modal, Button, Spin,notification,message, Divider, AutoComplete, Avatar } from "antd";
import { Facebook, Instagram, Pinterest, Twitter, Youtube } from 'react-bootstrap-icons';
import { useState } from 'react';
import InstagramFeedOptions from './views/InstagramFeedOptions';
import FacebookFeedOptions from './views/FacebookFeedOptions';
import TwitterFeedOptions from './views/TwitterFeedOptions';
import PinterestFeedOptions from './views/PinterestFeedOptions';
import YouTubeFeedOptions from './views/YouTubeFeedOptions';
import ApiService from 'services/ApiService';
import _ from 'lodash';
import { AddHash } from 'container/creator/creator/AddHash';
import MintedKeywordFeed from './views/MintedKeywordFeed';
import BluMeFeedOptions from './views/BluMeFeedOptions';

const AddNewFeed = ({user}) => {
  const [header, setheader] = useState("Select Source")
  const [feedview, setfeedview] = useState("")
  const [showFeedOptionView, setshowFeedOptionView] = useState(false)
  const [optionsMinted, setOptionsMinted] = useState([]);
  const [addHash, setaddHash] = useState(false)
  const [modalEvent, setmodalEvent] = useState({event:"mint",hideAddHash:true,item:null,keywords:[]})
  const [mintedNext, setmintedNext] = useState(false)
  const [mintedKeywords, setmintedKeywords] = useState()
  
  const processFeed = (type)=>{
    setheader(`Create ${type} Feed`)
    setfeedview(type)
    setshowFeedOptionView(true)
  }
  const onSelectChannel = (data,options) => {
    if(options.mintData.minted){
      setmintedKeywords(data)
      setheader(`Create Minted Keyword Feed`)
      setfeedview("minted")
      setshowFeedOptionView(true)
    }
    else{
      setmintedKeywords(data)
      setmodalEvent({event:"mint",hideAddHash:true,item:null,keywords:[{code:"721",value:data,copies:null}]})
      setaddHash(true);
    }
    

    
  };
  const setHashClose=(state=true,keywords=null)=>{
   
    setaddHash(false)
    if(keywords){
      setheader(`Create Minted Keyword Feed`)
      setfeedview("minted")
      setshowFeedOptionView(true)
      
      
    }
    
  }
  const openModel = (event,item=null)=>{
    setmodalEvent({event:event,item:item})
    setaddHash(true);
  }
  const handleSearch = async(value) => {
    let res = [];
    if (!value || value.indexOf('@') >= 0 || value.length < 2) {
      res = [];
      setOptionsMinted(res)
    }
    else{
    const filter = { $or: [
      { "onListing": {$exists:false} },
      { "onListing": false },
      
    ],title:{"$regex": value, "$options": "i"},minted:true,mentionAllowed:{$exists:false}}
    const filterRent = { $or: [
      { "onListing": {$exists:false} },
      { "onListing": true },
      
    ],title:{"$regex": value, "$options": "i"},minted:true,mentionAllowed:{$exists:false}}
    const data = await ApiService.PostApiCalls(
      "creator/hash/list",
      { minted: true,filter:filter,getAll:true,filterRent:filterRent },
      true
    );
    if (data[0] && data[1]["IsSuccessful"]) {
      const minted = _.filter(data[1]["hash"], function(d, i) {
        if (d.minted) {
          return d;
        }
      });
      
      if(minted.length > 0){
        const ssva = minted.filter(item =>
          item.title.toLowerCase() === value.toLowerCase()
        );
        
      minted.map((it,k) => {
          
          res.push({label:(<div key={k} className='flex items-center space-x-3'>
           
            <span className='flex flex-col space-y-1'>
            <span>{it["title"]}</span>
            <span className='text-muted pl-2 text-sm'>Minted</span>
            </span>
            
           
          </div>
        ),value:it["title"],mintData:{minted:true,title:it["title"]}})
        })
        if(!ssva[0]){
        res.push({label:(<div key={value} className='flex items-center space-x-3'>
           
            <span className='flex flex-col space-y-1'>
            <span>{value}</span>
            <span className='text-muted text-sm'>Mint to use</span>
            </span>
            
           
          </div>
        ),value:value,mintData:{minted:false,title:value}})
      }
      }
      else{
        res.push({label:(<div key={value} className='flex items-center space-x-3'>
           
            <span className='flex flex-col space-y-1'>
            <span>{value}</span>
            <span className='text-muted text-sm'>Mint to use</span>
            </span>
            
           
          </div>
        ),value:value,mintData:{minted:false,title:value}})
        
      }
        setOptionsMinted(res)
      
    }
  }
    
  }
  const closeFeedOption = ()=>{
    setheader(`Select Source`)
    setfeedview("")
    setshowFeedOptionView(false)
  }
  return (
    <>
      <Col span={24} className="py-0 px-0 mb-0">
        <div className="bg-white p-3 px-3 flex space-x-3 items-center justify-between">
          <span className="font-bold">{header}</span>
          <Button
             
            type="primary"
            className=" rounded  float-right mr-3"
            
          >
            
          </Button>
        </div>
      </Col>
      <div className="pb-2 bg-gray-100"></div>
      {!showFeedOptionView?<Col span={24} className="py-0 px-0 mb-0 flex flex-col  items-center justify-center">
        <div className='p-5'></div>
        <div className='w-50'>
        <AutoComplete className='rounded-md w-full p-3'
        onSearch={handleSearch}
        onSelect={onSelectChannel}
        options={optionsMinted}
        placeholder="Search minted keyword or mint new" />
        </div>
        
          <div className='w-50'>
        <Divider className='w-full'>Or</Divider></div>
        
        <div className='w-full flex items-center justify-center flex-col space-y-3'>
        <span>Choose Content Network</span>
        <span className=' text-xs text-muted'>Choose the social media platform where you'd like to integrate the feeds.</span>
        <Col span={9} className='flex flex-wrap items-center justify-center space-x-4 mt-4'>
        <Button
             type="default"
             size='large'
             className=" rounded mt-3  float-right flex items-center space-x-2"
             icon={<Instagram color={"#fa7e1e"} />}
             onClick={()=>processFeed("Instagram")}
           >
             Instagram
           </Button>
           <Button
             type="default"
             size='large'
             className=" rounded mt-3  float-right flex items-center space-x-2"
             icon={<Facebook color={"#3b5999"} />}
             onClick={()=>processFeed("Facebook")}
           >
             Facebook
           </Button>
           <Button
             type="default"
             size='large'
             className=" rounded mt-3  float-right flex items-center space-x-2"
             icon={<Avatar src={"https://hs-container.s3.ap-south-1.amazonaws.com/blume.png"} size={20} />}
             onClick={()=>processFeed("BluMe")}
           >
             BluMe
           </Button>
           <Button
             type="default"
             size='large'
             className=" rounded mt-3  float-right flex items-center space-x-2"
             icon={<Twitter color={"#55acee"} />}
             onClick={()=>processFeed("Twitter")}
           >
             Twitter
           </Button>
           {/* <Button
             type="default"
             size='large'
             className=" rounded mt-3  float-right flex items-center space-x-2"
             icon={<Pinterest color={"#E60023"} />}
             onClick={()=>processFeed("Pinterest")}
           >
             Pinterest
           </Button> */}
           <Button
             type="default"
             size='large'
             className=" rounded mt-3  float-right flex items-center space-x-2"
             icon={<Youtube color={"#CD201F"} />}
             onClick={()=>processFeed("YouTube")}
           >
             YouTube
           </Button>
        </Col>
        </div>
        
      </Col>:
      <Col span={12} className="py-0 px-0 mb-0 flex flex-col mx-auto items-center justify-center">
          <div className='p-5'></div>
        <div className='p-3'></div>
        {feedview === "Instagram" && <InstagramFeedOptions closeFeed={closeFeedOption} />}
        {feedview === "BluMe" && <BluMeFeedOptions closeFeed={closeFeedOption} />}
        {feedview === "Facebook" && <FacebookFeedOptions closeFeed={closeFeedOption}/>}
        {feedview === "Twitter" && <TwitterFeedOptions closeFeed={closeFeedOption}/>}
        {feedview === "Pinterest" && <PinterestFeedOptions closeFeed={closeFeedOption}/>}
        {feedview === "YouTube" && <YouTubeFeedOptions closeFeed={closeFeedOption}/>}
        {feedview === "minted" && <MintedKeywordFeed keywords={mintedKeywords}  closeFeed={closeFeedOption}/>}
      </Col>
      }
        {addHash && (
        <AddHash
          event={modalEvent}
          user={user}
          showModalFirst={addHash}
          callback={setHashClose}
          showExtra={true}
          cancelCallBack={()=>setaddHash(false)}
        />
      )}
      </>
  )
}

export default AddNewFeed