import { Skeleton } from 'antd'
import React from 'react'

const LoaderSkeleton = () => {
  return (
    <div className='flex items-center flex-col space-y-2'>
        <Skeleton.Image active  className='w-100 h-40 ' />
    <Skeleton avatar active paragraph={{ rows: 4 }} /></div>
  )
}

export default LoaderSkeleton