import { Spin } from 'antd'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ApiService from 'services/ApiService';

const VeridfyShopfiy = (props) => {
    const { search } = useLocation();
    useEffect(() => {
      verifyShop()
    
      return () => {
        
      }
    }, [])

    const verifyShop = async()=>{
        // const params = props["match"]["params"];
        // console.log(params);
        const params = new URLSearchParams(search);
        const param1 = params.get('code');
        const param2 = params.get('shop');
        if(param1 && param2){
            const state = await ApiService.PostApiCalls("/commerce/shop/verify",{code:param1,shop:param2},true)
            window.location.href = "/creator/social-commerce/products-catalog";

        }
        else{
            window.location.href = "/creator/social-commerce/products-catalog";
        }
  
    }
    
  return (
    <div className='flex items-center justify-center h-screen'>
        <Spin />

    </div>
  )
}

export default VeridfyShopfiy