import { Col } from 'antd'
import FeedCard from 'components/feed/FeedCard'
import React from 'react'
import { Bookmark } from 'react-bootstrap-icons'

const BookmarksAll = ({feedContent=[]}) => {
  return (
    <Col span={24} className="px-0 h-full">
   { feedContent.length === 0 && <div className='flex flex-1 items-center justify-center h-full flex-col' >
        <Bookmark size={40} className="opacity-30"/>
        <span className='text-lg py-2 opacity-40'>No bookmarks yet</span>
    </div>}
    {feedContent && feedContent.length > 0 &&<div className="px-4">
            {
                feedContent.map((feed,key)=>(
                    <FeedCard key={key} post={feed} id={key} />
                ))
            }
        
        
        </div>}
    </Col>
  )
}

export default BookmarksAll