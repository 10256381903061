import React,{useState} from 'react'
import { Tag, Input,message,Button } from 'antd';
import {XCircle} from "react-bootstrap-icons";
import _ from 'lodash';
import { useEffect } from 'react';
import confirm from 'antd/lib/modal/confirm';
import { useTranslation } from 'react-i18next';

const Tags = (props) => {
    const [inputValue, setinputValue] = useState("")
    const [defaultTags, setdefaultTags] = useState(props.tags)
     const { t } = useTranslation();
    

    useEffect(() => {
        setdefaultTags(props.tags)

    },[props.tags])


    const removeTag = (e,k) => {
        const tags = _.remove(defaultTags, function (time,i) {
            return i !== k;
          });
          props.callback(tags)
          setdefaultTags(tags)
        //   setTimeout(
        //     () =>  props.update({t:tags}), 
        //     500
        // );
    }
    const handleInputConfirm = () => {
        if(inputValue.trim() !== ""){
            const tag = inputValue[0] === "#" ?inputValue.substring(1):inputValue; 
            const isEmpty = _.filter(defaultTags, function (t,i) {                
                return t["value"].toLowerCase() === tag.toLowerCase();
              });           
            if(isEmpty.length === 0){
                const tmp = {
                    value:tag.replace(/\s/g,''),
                    code:"721",
                    copies:null
                }
            const newtgs = [...defaultTags,tmp]

            setdefaultTags(newtgs)
            props.callback(newtgs)
            if(props.forceUpdate){
            setTimeout(
                () =>  props.update({t:newtgs}), 
                500
            );
        }
            setinputValue("")
            }else{
                setinputValue("")
                message.error(
                {
                    content: 'Tag is already added',
                    className: 'custom-class',
                    style: {
                      right: '10px',
                      position:"absolute"

                    },duration:2
                  });
            }
        }
        
    }
    const clearAllTags = ()=>{
        
        confirm({
            title: '',
            icon: null,
            content: 'Are you sure you want to clear all tags? You won\'t be able to undo this action.',
            okText: 'Yes',
            cancelText: 'No',
            async onOk() {
                const newtgs = []
                setdefaultTags(newtgs)
                props.callback(newtgs)
                setTimeout(
                    () =>  props.update({t:newtgs}), 
                    500
                );
            },
            onCancel() {
              console.log('Cancel');
            },
          });
    }
    const renderTags = (tag,key) =>{
        
        const tagText = tag["value"].replace(/\s/g,'');
        
        if(tagText.length > 1){
            return <Tag key={key} closable onClose={(e) =>removeTag(e,key)} className="tags-added mb-3 d-flex align-items-center" closeIcon={<XCircle color={"#ef4d74"} size={13} /> } ><span>{tag[0] === "#" ?tagText:"#"+tagText}</span></Tag>
        }
        
    }
    return (
        <div className="d-flex flex-grow-1 align-items-start flex-wrap flex-column" >
            <div className="d-flex w-100 flex-grow-1 align-items-center flex-wrap" >
            {defaultTags.length > 0 && defaultTags.map((tag,key) =>
                
                renderTags(tag,key)
        
            )}
            <Input           
            type="text"
            className="tag-input mb-3"
            placeholder={t("hsplace")}
            value={inputValue}
            onChange={(e) => setinputValue(e.target.value)}
            onBlur={handleInputConfirm}
            onPressEnter={handleInputConfirm}
          />
          {props.getKeywords && <Button size="small" onClick={()=>props.getKeywords()} className="ml-2 rounded-lg mb-3">{t("hsgen")}</Button>}
       
          
        </div>
        <span className='py-2'>
              <Button type="primary" size='small' disabled={!defaultTags.length} onClick={clearAllTags} className='brd-4' >{t("hsclear")}</Button>
              
          </span>
        </div>
    )
}

export default Tags
