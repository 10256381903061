import { Row, Col, Typography, Modal, Button, Spin,notification,message } from "antd";

import React, { useEffect, useState } from "react";

import _ from "lodash";
import ApiService from "services/ApiService";
import SocialProfileBox from "./comps/SocialProfileBox";

import FacebookAuth from 'components/socialauths/FacebookAuth';
import TwitterAuth from 'components/socialauths/TwitterAuth';
import InstaAuth from 'components/socialauths/InstaAuth';
import LinkedinAuth from "components/socialauths/LinkedinAuth";

const { Paragraph, Text } = Typography;

const SettingsSocialProfiles = ({ user, activeWorkSpace }) => {
  const [viewloading, setViewLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [visible,setVisible] = useState(false);
  const [linkedinReconnect, setlinkedinReconnect] = useState(false)
  const [linkedinReconnectData, setlinkedinReconnectData] = useState(null)
  const [selectedProfile,setSelectedProfile] = useState([]);
  const [selectedProfileIds,setSelectedProfileIds] = useState([]);
  const socialConnectionType = 0;
  const ProfileAllowed = 12;
  useEffect(() => {
    getSocialChannels();
    return () => {
      
    }
  }, [])
  const handleCancel = () => {
    setVisible(false);
    setlinkedinReconnect(false);
    setlinkedinReconnectData(null);
  };
  const getSocialChannels = async ()=>{
   
    const q = activeWorkSpace["wid"];
    const details = await ApiService.GetApiCalls(`/creator/socialchannels/${q}`,"",true)
    if (details[0] && details[1]["IsSuccessful"]) {
      setSelectedProfile(details[1]["channels"])
    }
    setViewLoading(false)
  }
  const saveCampaignDetails = ()=>{

  }
  const getConnectionResponse =  async(selected, all, channel)=>{
    const isThere = _.filter(selectedProfile, function (time,i) {
      return time["id"] === selected["id"];
    });    

      if(!selected["isChange"] && isThere.length > 0){
        message.error(
          {
              content: 'Selected profile already connected',
              className: 'custom-class',
              style: {
                right: '10px',
                position:"absolute"

              },duration:2
            });

            return false;
      }
    if (channel === "Facebook") {
      

      if(selected["isChange"]){
        
        let currenAdded = selectedProfile;
        const updated = _.map(currenAdded, function(a) {
          return a["spid"] === selected["spid"] ? selected : a;
        });
        let profilesId = [...selectedProfileIds,selected["id"]];
        setSelectedProfile(updated); 
        setSelectedProfileIds(profilesId); 
      }
      else{
        let profiles = [...selectedProfile, selected];
        let profilesId = [...selectedProfileIds, selected["id"]];
        setSelectedProfile(profiles);
        setSelectedProfileIds(profilesId);
      }
       
      
    } else if (channel === "Twitter") {
      if(selected["isChange"]){
        
        let currenAdded = selectedProfile;
        const updated = _.map(currenAdded, function(a) {
          return a["spid"] === selected["spid"] ? selected : a;
        });
        let profilesId = [...selectedProfileIds,selected["id"]];
        setSelectedProfile(updated); 
        setSelectedProfileIds(profilesId); 
      }
      else{
      let profiles = [...selectedProfile, selected];
      let profilesId = [...selectedProfileIds, selected["id"]];
      setSelectedProfile(profiles);
      setSelectedProfileIds(profilesId);
      }
      
    } 
    else if (channel === "Instagram") {
      if(selected["isChange"]){
        
        let currenAdded = selectedProfile;
        const updated = _.map(currenAdded, function(a) {
          return a["spid"] === selected["spid"] ? selected : a;
        });
        let profilesId = [...selectedProfileIds,selected["id"]];
        setSelectedProfile(updated); 
        setSelectedProfileIds(profilesId); 
      }
      else{
        let profiles = [...selectedProfile, selected];
        let profilesId = [...selectedProfileIds, selected["id"]];
        setSelectedProfile(profiles);
        setSelectedProfileIds(profilesId);
      }
      
      
    }
    else {
      let profiles = [...selectedProfile, selected];
      let profilesId = [...selectedProfileIds, selected["id"]];
      setSelectedProfile(profiles);
      setSelectedProfileIds(profilesId);
      
    }
    
    if(selected["isChange"] && selected["isChange"] === "yes"){
      const saved = await ApiService.PostApiCalls("/creator/socialchannel/update/"+selected["spid"],{update:selected})
      notification["success"]({
        message: saved[1]["FriendlyMessage"],
        description:""
      });
    }else{
    const added = await ApiService.PostApiCalls(
      "/creator/socialchannel/add",
      { profile: {...selected,wid:activeWorkSpace["wid"]} },
      true
    );
    if(added[0] && added[1]["IsSuccessful"]){
      getSocialChannels();
    }
    else{
      notification["error"]({
        message: added[1]["ErrorMessage"],
        description:""
      });
    }
  }
  }
  const removeProfile = (id)=>{
    const profiles = _.remove(selectedProfile, function (p,i) {
      return p.spid !== id;
    });
    setSelectedProfile(profiles);
  }
  const LinkedinRoconnect = ()=>{

  }
  if(viewloading){
    return (
      <>
      <Col span={24} className="py-0 px-0 mb-0">
        <div className="bg-white p-3 px-3 flex space-x-3 items-center justify-between">
          <span className="font-bold">Social Profiles</span>
          <Button
            onClick={() => saveCampaignDetails()}
            type="primary"
            className=" rounded  float-right mr-3"
            loading={loading}
          >
            Save Details
          </Button>
        </div>
      </Col>
      <div className="pb-2 bg-gray-100"></div>
      <Col span={24} className="py-0 px-0 mb-0 h-screen flex items-center justify-center">
      <Spin />
      </Col>
      </>
    )
  }
  return (
    <>
      <Col span={24} className="py-0 px-0 mb-0">
        <div className="bg-white p-3 px-3 flex space-x-3 items-center justify-between">
          <span className="font-bold">Social Profiles</span>
          <Button
             onClick={() => setVisible(true)}
            type="primary"
            className=" rounded  float-right mr-3"
            loading={loading}
          >
            Add Socail Profile
          </Button>
        </div>
      </Col>
      <div className="pb-2 bg-gray-100"></div>
      <Col span={24} className="">
        <Row className="p-2">
          <Col span={24} className="p-2">
            <Paragraph className="text-sm text-hs-dark">
            You are on the Professional plan which means you can add up to 40 social profiles. Profiles listed here will be available for you to send campaign content to. Once you’ve linked your social profiles, head to the Branding area to decide which logo and brand name you’d like us to use in your campaign imagery.
            </Paragraph>
          </Col>
          <Col className="px-0 pr-4 mt-4 " span={24}>
            
          </Col>

         
          <Col className="px-0 my-4 flex flex-wrap" span={24}>

          {
                    selectedProfile.map((p,key)=>(
                        <SocialProfileBox key={key} LinkedinRoconnect={LinkedinRoconnect} getConnectionResponse={getConnectionResponse} removeProfile={removeProfile} className="" p={p} name={p.name} />
                    ))
                 }   

          </Col>
        </Row>
      </Col>
      <Modal className="campaign-new-modal" title={linkedinReconnect?"Reconnect mentioned Linkedin profile":"Adding a social profile"} visible={visible}
               closable={false}            
              footer={[
                  
            <Button key="back" className="brd-4 blueline-btn" onClick={handleCancel}>
              Cancel
            </Button>
          ]}
              >
                {linkedinReconnect && <>
                <Col className="px-0 fz-12 pr-4 "
             span={24}>
               {/* <span className="w-100 py-2 float-left ml-3">Reconnect Profile</span> */}
           <span className="w-100 py-2 fw-700 ml-0 fz-18 mb-3">
           <span >{linkedinReconnectData.name}</span>
           
           </span>
           <span className="w-100 py-2  float-left ml-3 mt-4 text-center">
           <LinkedinAuth spid={linkedinReconnectData.spid} pid={linkedinReconnectData.id} closeBox={() => setVisible(false)} callback={getConnectionResponse} reconnect={true} />
           </span>
             </Col>
             </>}
                {!linkedinReconnect && <>
                <Col className="px-0 fz-12 "
             span={24} >Select the social network that you'd like to add to this Workspace.</Col>
               {ProfileAllowed !== selectedProfile.length && <><div className="d-flex justify-content-center my-4">
    <div className={socialConnectionType === 1 ?"social-connection connect box--shadow  active":"social-connection connect box-shadow"} onClick={() => setVisible(false)}>
    <FacebookAuth callback={getConnectionResponse} /></div>
    <div className={socialConnectionType === 2 ?"social-connection connect box--shadow active":"social-connection connect box-shadow"} onClick={() => setVisible(false)}>
    <TwitterAuth callback={getConnectionResponse} /></div>
    <div className={socialConnectionType === 3 ?"social-connection connect box--shadow active":"social-connection connect box-shadow"} onClick={() => setVisible(false)}>
    <LinkedinAuth  callback={getConnectionResponse} /></div>    
    <div className={socialConnectionType === 4 ?"social-connection connect box--shadow active":"social-connection connect box-shadow"} onClick={() => setVisible(false)}>
    <InstaAuth  callback={getConnectionResponse} /></div> 

    
    </div><span className="pl-2 fw-600 pt-3">Instagram is required <a target="_blank" href="https://help.instagram.com/502981923235522?fbclid=IwAR216hIf5N_9nLD2kIVZpoEZ-kr_SuCbJ1MF6liJSr-iE_o3op0X9xO4JtM">Instagram Professional accounts</a></span> </>} 
    </>}
    {ProfileAllowed === selectedProfile.length && <Col className="px-0 fz-12 pt-4"
             span={24} >
                 <h5>{`With the currently active plan you can add only ${ProfileAllowed} social profiles.`}</h5>
             </Col> }
              </Modal>
    </>
  );
};

export default SettingsSocialProfiles;
