
import React from "react";
import PropTypes from "prop-types";

import { Layout } from "antd";

import HeaderCreator from "components/HeaderCreator";
import SidebarCreator from "components/SidebarCreator";
import { useState } from "react";
import HeaderVideo from "components/HeaderVideo";
import { VideoProvider } from "app/VideoContext";
import SidebarRight from "components/SidebarRight";
const { Sider, Content } = Layout;
const CreatorProfileLayout = ({ children, noNavbar, noFooter }) => {
  const [collapsed, setcollapsed] = useState(children.props.closedSidebar ? true:false)
  
  return (
    <>
      <Layout className="container w-full max-w-full min-h-screen bg-white p-0">
          <HeaderCreator  noPadd={true} showBorder={true}/>
          
          <Layout className="mt-14 pb-0 pt-0 " hasSider={true}>
        <Sider  onCollapse={(e)=>setcollapsed(e)} collapsed={collapsed} collapsible className="fixed bg-transparent hidden lg:block h-screen" width="250" >
          <SidebarCreator sidebarKey={children.props.sidebarKey} collapsed={collapsed}  />
        </Sider> 
            <Content className="py-0 px-0 bg-white" style={collapsed?{marginLeft:80}:{marginLeft:250}} >{children}</Content>
          </Layout>
          {/* <Footer>Footer</Footer> */}
        </Layout>
    </>
  );
};

CreatorProfileLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool,
};

CreatorProfileLayout.defaultProps = {
  noNavbar: false,
  noFooter: false,
};

export default CreatorProfileLayout;
