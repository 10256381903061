

import React from 'react'
import { List, Typography, Divider } from 'antd';
import { ChevronBarRight, ChevronRight } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

const data = [
  {slug: 'profile',lable:"Profile"},
  {slug: 'dating-profile',lable:"Dating Profile"},
  {slug: 'wallet',lable:"Wallet"},
  {slug: 'rewards',lable:"Rewards",submenu:[{slug:"activities",lable:"Activities"}]},
  // {slug: 'security',lable:"Security"},
  {slug: 'settings',lable:"Settings"},
  
  
];
const dataGeneral = [
    
    {slug: 'display',lable:"Display"}
    
  ];
const UserAccountList = ({user,active}) => {
    
  return (
      <>
       {/* <div className='pb-1 bg-gray-100'></div> */}
    <List
      
      className="bg-white pb-3"

      dataSource={data}
      renderItem={item => (
        <List.Item className='cursor-pointer p-2 flex flex-col'>
          <Link className='text-hs-dark px-1 flex w-100' to={`/account-settings/${item["slug"].toLowerCase()}`}>
              <span className={item["slug"].toLowerCase() === active ?'flex justify-between items-center w-100 p-2 bg-blue-50 text-blue-400':'flex justify-between items-center w-100 p-2 hover:bg-blue-50 hover:text-blue-400'}>
              <span>{item["lable"]}</span>
              <ChevronRight size={20} />
          </span></Link>
          {item.submenu && item.submenu.map(subItem =>(
            <Link className='text-hs-dark px-1 flex w-100 pl-3 py-1' to={`/account-settings/${subItem["slug"].toLowerCase()}`}>
              <span className={subItem["slug"].toLowerCase() === active ?'flex justify-between items-center w-100 p-2 bg-blue-50 text-blue-400':'flex justify-between items-center w-100 p-2 hover:bg-blue-50 hover:text-blue-400'}>
              <span>{subItem["lable"]}</span>
              <ChevronRight size={16} color={"#ccc"}/>
          </span></Link>
          ))

          }
        </List.Item>
      )}
    />

    
    </>
  )
}

export default UserAccountList