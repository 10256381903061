/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useState,useEffect,useRef,useCallback } from 'react'
import _ ,{debounce }from 'lodash';
import { Input, } from 'antd'
import Utils from 'helper/Utils';

const { TextArea } = Input;
function useStateCallback(initialState) {
    const [state, setState] = useState(initialState);
    const cbRef = useRef(null); // mutable ref to store current callback
  
    const setStateCallback = useCallback((state, cb) => {
      cbRef.current = cb; // store passed callback to ref
      setState(state);
    }, []);
  
    useEffect(() => {
      // cb.current is `null` on initial render, so we only execute cb on state *updates*
      if (cbRef.current) {
        cbRef.current(state);
        cbRef.current = null; // reset callback after execution
      }
    }, [state]);
  
    return [state, setStateCallback];
  }
const PostCardText = ({post,id,textLength,postLink,updateContent,textProfileId,updateContentLen,hideTools,mintedHashs}) => {
    
    const [edit, setEdit] = useState(false);
    const [editText, setEditText] = useStateCallback("");
    const [lengtherror, setLengtherror] = useState("");
    const [availableLimit, setavailableLimit] = useState();
    const [availableTags, setavailableLimitTags] = useState(post.tags);
    const [availableMentions, setavailableMentions] = useState(post.mentions);
    const [newText,setNewText] = useStateCallback("")
    const [usedLength,setusedLength] = useStateCallback(0)
    const inputRef = React.useRef();
    let timeoutclick;
    let inDebounce;
    // console.log(mintedHashs);
    useEffect(() => {
        setavailableLimitTags(post.tags)
        setavailableMentions(post.mentions)
        
        if(!edit){
            if(post[textProfileId]){
                setEditText(post[textProfileId]["text"]===""?post["qoute"]:post[textProfileId]["text"])
                setNewText(post[textProfileId]["text"]===""?post["qoute"]:post[textProfileId]["text"])
                setavailableLimitTags(post[textProfileId]["tags"])
                setavailableMentions(post[textProfileId]["mentions"])
                if(!hideTools){
                onTextEdit(post[textProfileId]["text"]===""?post["qoute"]:post[textProfileId]["text"])
                }
            }
            else{
                setNewText(post["qoute"])
                setEditText(post["qoute"])
                if(!hideTools){onTextEdit(post["qoute"])}
            }
        }
    },[post["qoute"],post["tmpqoute"],textLength,post.tags])
    const setEditOn = () =>{
        setEdit(true);        
    }
//    const setLimitAvailabelandTags = (ln) =>{

//         const calculatedtext = editText
     
//         try {
//             const l = (ln-calculatedtext.length);
//         setavailableLimit(l);
//         } catch (error) {
            
//         }
        

//     }
    const getTweetText = (text,link,tags,mentions) =>{
        
        let textOnly = text+" "+link;
        if(textOnly.length > textLength){
            const extra = textLength - link.length;
            const textremoved = text.substring(0, extra)
            setEditText(textremoved)
            setNewText(textremoved)
            setavailableLimitTags([])
            setavailableMentions([])
            return [textremoved+" "+link,[],[]];
        }
        else{
            let newTags = [];
            let newMentions = [];
            
            if(tags.length === 0 && mentions.length === 0){
                return [textOnly,[],[]];
            }
            if(textLength === 280){
            for(let i = 0; i <mentions.length; i++){
                const old  = textOnly;
                if(mentions[i].replace(/ /g,'').length > 0){
                textOnly = textOnly+" "+"@"+mentions[i].replace(/ /g,'');
                newMentions.push(mentions[i])
                }
                if(textOnly.length > textLength){
                    setavailableMentions(newMentions.slice(0,-1))
                    setavailableLimitTags([])
                    return [old,[],newMentions.slice(0,-1)];
                }
            }}
            for(let i = 0; i <tags.length; i++){
                const old  = textOnly;
                if(tags[i].replace(/ /g,'').length > 0){
                textOnly = textOnly+" "+"#"+tags[i].replace(/ /g,'');
                newTags.push(tags[i])
            }
                
                if(textOnly.length > textLength){
                    setavailableLimitTags(newTags.slice(0,-1))
                    setavailableMentions(newMentions)
                    return [old,newTags.slice(0,-1),newMentions];
                }
                else{
                    
                    if(i+1 === tags.length){
                        setavailableLimitTags(newTags)
                        setavailableMentions(newMentions)
                        return [textOnly,newTags,newMentions];
                    }
                }
                
            }
            return [text,tags,mentions];
        }
    }
    const updateText = (s,t,m) =>{
       
        const opt = {
            text:s,
            tags:t,
            mentions:m,
            linktext:"Read full article "+postLink,
            textlength:usedLength
        }
        // console.log(opt);
        updateContent("posttext",opt)
    }
    const handler = useCallback(debounce(updateText, 1000), []);
    const debounceFunction = _.debounce(()=>{
        updateText();
        return true;
      }, 2000, {leading:true, trailing:false});
      
    const onTextEdit = (value) =>{
      
        setNewText(value)
        setEditText(value)
        const suppliedTextLength = value.length;
        const dsd =  getTweetText(value,"Read full article "+postLink,post.tags,post.mentions)
        
        const availabelLength = textLength - ("Read full article "+postLink).length;
        setavailableLimit(availabelLength-suppliedTextLength)
        setusedLength(dsd[0].length)
        // console.log(textLength, textLength-dsd.length,dsd.length);
        updateContentLen("len",null,textLength-dsd[0].length)
        
        if(suppliedTextLength > availabelLength){
            setLengtherror("You have exceeded the maximum character limit")
        }
        else{
            setLengtherror("")
        }
        
        setNewText(
            prev => value,
            s => handler(s,dsd[1],dsd[2])
          );
        
    //    _.debounce(()=>{
    //        console.log("hre");
    //         updateText();
    //         return true;
    //       }, 2000, {leading:true, trailing:false});
    
    }
    const renderTags = (tag,key) =>{
        const tagText = tag.replace(/\s/g,'');
        const id = Utils.getHashId(tagText,true,true);
        const fid = _.filter(mintedHashs, function(d){
          return d["hashId"] === id
        })
        
        let tagClass = "mr-2 mb-1"
        if(fid[0]){
        //   t.replace(/\s/g,'').length > 0 && <span className="mr-2 mb-1" key={k}>{t[0] === "#" ?t.replace(/\s/g,''):"#"+t.replace(/\s/g,'')}</span>
            tagClass = "mr-2 mb-1 text-hs-pink"
            
        }
        if(tagText.length > 1){
            return  tag.replace(/\s/g,'').length > 0 && <span key={key}  className={tagClass}  >{tag[0] === "#" ?tagText:"#"+tagText}</span>
        }
        
    }
    return (
        <div className="d-flex flex-grow-1 align-items-center  flex-wrap pl-4 pr-4 pb-0">        
        <div className="post-text  w-100">
        {!edit && <span className="only-text" onClick={() => setEditOn()}>{editText}</span>} 
        {edit && <TextArea
          placeholder="Write quote..."
          autoSize
          className="mb-3 p-0 custom-scroll w-100"
          value={editText} 
          ref={inputRef}
          autoFocus
          bordered={false}
          onBlur={() =>setEdit(false)}
          onChange={(e) => onTextEdit(e.target.value)}         
        />}
        
        </div>  
        <div className="post-tags mt-2 d-flex  flex-wrap">
            {textLength === 280 && availableMentions && availableMentions.map((t,k) => (
              t.replace(/\s/g,'').length > 0 && <span className="mr-2 mb-1 text-primary fw-600" key={k}>{t[0] === "@" ?t.replace(/\s/g,''):"@"+t.replace(/\s/g,'')}</span>  ))}
        </div>
        <div className="post-link mt-2 w-100">
        <span>Read full article </span>
        <a href={postLink} target="_blank" rel="noreferrer" alt="Read full article">{postLink}</a></div>
        <div className="post-tags mt-2 d-flex  flex-wrap">
            {availableTags && availableTags.map((t,k) => (
                renderTags(t,k)
                ))}
        </div>
        
        
        </div>
    )
}

export default PostCardText
