import { Row, Col,Button, Result, Spin } from "antd";
import SettingsLeftList from "container/creator/profilesettings/SettingsLeftList";
import { Container } from "react-bootstrap";

import Utils from "helper/Utils";
import React from "react";
import { useState, useEffect } from "react";
import { ArrowBarLeft } from "react-bootstrap-icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import ApiService from "services/ApiService";
import { useContext } from "react";
import { AuthContext } from "app/AuthProvider";
import BookmarkList from "./BookmarkList";
import _ from "lodash";
import BookmarksAll from "./BookMarks/BookmarksAll";
import BookmarksPhotos from "./BookMarks/BookmarksPhotos";
import BookmarksVideos from "./BookMarks/BookmarksVideos";
import BookmarksNews from "./BookMarks/BookmarksNews";
import BookmarksOthers from "./BookMarks/BookmarksOthers";



const UserBookmarks = (props) => {
  const navigate = useNavigate()
  const brParams = useParams();

  const user = Utils.getLocalInfo("userhash");
  const {
    authUser
  } = useContext(AuthContext);
  const [loading, setloading] = useState(true);
  const [error, seterror] = useState(null);

  const [groups, setgroups] = useState([]);
  const [baseQuery, setbaseQuery] = useState({});
  const [queryParams, setqueryParams] = useState({
    limit: 20,
    offset: 0,
    sort: "desc",
  });
  const data = [
    {slug: 'all',lable:"All Bookmarks"},
    {slug: 'photos',lable:"Photos"},
    {slug: 'videos',lable:"Videos"},
    {slug: 'news',lable:"News"},
    {slug: 'others',lable:"Others"}
    
  ];
  const [current, setcurrent] = useState([{slug: 'all',lable:"All Bookmarks"}])
  useEffect(() => {
    const params = brParams;
    const c = _.filter(data,function(d,i){
      return d.slug === params.action;

    })
    
    setloading(true);
    setgroups([])
    getPosts(params)
    setcurrent(c[0]);
   
    return () => {};
  }, [brParams]);

  const getPosts = async (params) => {
    
    const res = await ApiService.PostApiCalls(
      "list/bookmarks",
      { type:params.action },
      true
    );

    if (res[0]) {
      if (res[1]["IsSuccessful"]) {
        setgroups(res[1]["list"]);
        setloading(false);
        window.scrollTo(0, 0);
    

      } else {
        setloading(false);
      }
    } else {
      setloading(false);
    }
  };
  if (
    user["role"] === "creator" &&
    parseInt(user["creatorProfileStep"]) !== 6
  ) {
    return navigate("/creator/edit/1");// <Redirect to="/creator/edit/1" />;
  }

  return loading ? (
    <div className=" h-100 d-flex justify-center align-items-center bg-white py-3 px-4 rounded-xl ">
      <Spin />
    </div>
  ) : (
    <>
      <Container className=" bg-white flex-1 h-100  px-2 py-0 rounded-xl overflow-hidden" fluid>
    <Row gutter={16} className="h-100">
      <Col span={8} className="mb-0 px-0 bg-white   ">
      <Col span={24} className="py-0 px-0 mb-0 border-b-2">
          <div className="bg-white p-3 px-3 flex space-x-3 items-center">
            <span className="font-bold uppercase">Bookmarks</span>
          </div>
        </Col>
        <Col span={24} className="px-0  bg-white"><BookmarkList user={authUser} active={brParams.action} /></Col>
        
      </Col>
      <Col span={16} className="mb-0 px-0 bg-whitw border-l-2">
      <Col span={24} className="py-0 px-0 mb-2 border-b-2">
      <div className="bg-white p-3 px-3 flex space-x-3 items-center">
        <span className="font-bold uppercase opacity-30">{current["lable"]}</span>
      </div>
     
    </Col>
      {brParams.action === "all" && <BookmarksAll user={authUser} feedContent={groups} />}
      {brParams.action === "photos" && <BookmarksAll user={authUser} feedContent={groups} />}
      {brParams.action === "videos" && <BookmarksAll user={authUser} feedContent={groups} />}
      {brParams.action === "news" && <BookmarksAll user={authUser} feedContent={groups} />}
      {brParams.action === "others" && <BookmarksAll user={authUser} feedContent={groups} />}
      </Col>
    </Row>
    </Container>
    </>
  );
};

export default UserBookmarks;
