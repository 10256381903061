import { PageHeader,Button, Modal, Form, Select,Radio, InputNumber, message, Input, Checkbox } from 'antd'
import React,{useState,useEffect} from 'react'
import { useNavigate,Link, useLocation } from "react-router-dom";
import { toggleEditProfile } from '../store/stateSlice';
import { useDispatch } from 'react-redux';
import jsonData from '../../../res/extra/country_dial_list.json';
import ApiService from 'services/ApiService';
import Utils from 'helper/Utils';
import {
  setKey,
  setDefaults,
  setLanguage,
  setRegion,
  fromAddress,
  fromLatLng,
  fromPlaceId,
  setLocationType,
  geocode,
  RequestType,
} from "react-geocode";
const { Option } = Select;
const toRad = (Value) =>
{
    return Value * Math.PI / 180;
}
 const calcCrow = (lat1, lon1, lat2, lon2) =>
{
  var R = 6371; // km
  var dLat = toRad(lat2-lat1);
  var dLon = toRad(lon2-lon1);
  var lat1 = toRad(lat1);
  var lat2 = toRad(lat2);

  var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  var d = R * c;
  return d;
}
const StreetHeader = ({data,showExtra,authUser,alreadyBroker}) => {
  const [form] = Form.useForm();
  const [formNew] = Form.useForm();
  
  const [basicInfo, setbasicInfo] = useState({fullname:alreadyBroker?alreadyBroker.fullname:"",
  phone:alreadyBroker?alreadyBroker.phone:"",email:alreadyBroker?alreadyBroker.email:"",prefix:"+91",otp:"",connectto:true})
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sending, setsending] = useState(false)
  const [btnDisabled, setbtnDisabled] = useState(true)
  const [authMode, setauthMode] = useState("otp")
  const [addedPhone, setaddedPhone] = useState("")
  const [uploading, setUploading] = useState(false);
  useEffect(() => {
      
    
    //  setbasicInfo(alreadyBroker?alreadyBroker:listingBasicInfo)
    // setshowUpdate(alreadyBroker?false:true)
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(function (position) {
          
          const localLocation = Utils.getLocalInfo("posCords");
          
          
         if(localLocation ){
          const dist = calcCrow(localLocation["lat"],localLocation["long"] ,position.coords.latitude ,position.coords.longitude)
          if(dist > 50){
            Utils.setLocalInfo("posCords",{lat:position.coords.latitude,long:position.coords.longitude})
            geocode(RequestType.LATLNG, `${position.coords.latitude}, ${position.coords.longitude}`)
          .then(({ results }) => {
            const address = results[0].formatted_address;
            const city = Utils.getLocationInfo(results[0].address_components);
            if (city) {
              Utils.setLocalInfo("addressInfo",city)
              setbasicInfo({...basicInfo,city:city?city.city:""})
            } else {
              console.log('City not found in address components.');
            }
          })
          .catch(console.error);
          }
          else{
            const addInfo = Utils.getLocalInfo("addressInfo");
            setbasicInfo({...basicInfo,city:addInfo?addInfo.city:""})
          }
         
         
         }
         else{
          
          geocode(RequestType.LATLNG, `${position.coords.latitude}, ${position.coords.longitude}`)
          .then(({ results }) => {
            const address = results[0].formatted_address;
            const city = Utils.getLocationInfo(results[0].address_components);
            if (city) {
              Utils.setLocalInfo("addressInfo",city)
              setbasicInfo({...basicInfo,city:city?city.city:""})
            } else {
              console.log('City not found in address components.');
            }
          })
          .catch(console.error);
         
          Utils.setLocalInfo("posCords",{lat:position.coords.latitude,long:position.coords.longitude})
          
         }
 
        });
        
      } else {
        console.log("Geolocation is not available in your browser.");
      }
  
   
    
    form.setFieldsValue(basicInfo)

    return () => {
      
    }
  }, [])
  useEffect(() => {
      
    
    
    
    form.setFieldsValue(basicInfo)

    return () => {
      
    }
  }, [form])
  const onFinish = async (values) => {
    setUploading(true)
    const result = await ApiService.PostApiCalls("re/broker/create",{...values},false)
    if(result[0] && result[1]["IsSuccessful"]){
      message.success('Registration successfully.');
      Utils.setLocalInfo("userhash", result[1]["user"]);
      Utils.setCookie("sessionhash", result[1]["token"]);
      formNew.resetFields();
      handleCancel()
      
    }
    else if(result[0] && !result[1]["IsSuccessful"]){
      message.error(result[1]["FriendlyMessage"]);
      
    }
    else{
      message.error(Utils.requestFaileddMsg());
    }
    setUploading(false)
  }

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        style={{
          width: 70,
        }}
        disabled={authMode === "otp"?false:true}
        name="prefix-code"
        showSearch
        defaultValue={['+91']}
        // searchValue='+91'
onChange={()=>{}}
optionLabelProp="label"
showArrow={false}
        dropdownMatchSelectWidth={false}
        // filterSort={(optionA, optionB) =>
        //     (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
        //   }
      >
        {
            jsonData.map((cnt,i) => <Option key={i} value={`${cnt.value}(${cnt.label})`} label={cnt.value} >{`${cnt.value}(${cnt.label})`}</Option>)
        }
        
        
      </Select>
    </Form.Item>
  );
  //<Link to={!authUser? "/creator/edit/start?redirect=streets/broker-registration":"/search/streets/broker-registration"}>
  const gotToView = ()=>{
    if(alreadyBroker){
      navigate("/broker/dashboard")
    }
    else{
      openLoginModal()
    }
  }
  const openLoginModal = ()=>{
    form.resetFields();
    formNew.resetFields();
    setIsModalOpen(true)
  }
  const headersExtra = {
    "home":[
      <Button onClick={()=>gotToView()} type="primary">{alreadyBroker?"Broker Profile":"Broker Login"}</Button>,
      <Link to={"/re/new-listing"}><Button type="primary">List Property</Button></Link>
      
    ],
    "new-listing":[
     
      <Button onClick={()=>dispatch(toggleEditProfile(true))} type="primary">List New Property</Button>
      
    ],
    "broker-registration":[
      
      <Button onClick={()=>dispatch(toggleEditProfile(true))} type="primary">Edit Profile</Button>
      
    ]
  }
  const handleCancel = () => {
    setIsModalOpen(false);
    setaddedPhone("")
    setauthMode("otp")
    form.resetFields();
    formNew.resetFields();
  }
  const onFinishEnter = async(v)=>{
   
    // setsending(true)
    
    if(authMode === "otp"){
      setaddedPhone(v.phone)
      const otps = await ApiService.PostApiCalls("/re/account/verify",{...v,mode:authMode},false)
      if(otps[0] && otps[1]["IsSuccessful"]){
        message.success(otps[1]["FriendlyMessage"])
        setsending(false)
        setauthMode("verifyotp")
        setbtnDisabled(true)
      }
      else if(otps[0] && !otps[1]["IsSuccessful"]){
        message.error(otps[1]["FriendlyMessage"])
        setsending(false)
        
      }
      else{
        message.error(Utils.requestFaileddMsg())
        setsending(false)
      }
    }
    else{
      let connectToUser = {}
      if(v["connectto"] && authUser){
        //TODO: Add info later
        connectToUser = {}
      }
      const otps = await ApiService.PostApiCalls("/re/account/verify",{...v,phone:addedPhone,mode:authMode},false)
      if(otps[0] && otps[1]["IsSuccessful"]){
        const modein = otps[1]["mode"];
        if(modein === "logn"){
          
          setsending(false)
          setauthMode("verifyotp")
          setbtnDisabled(true)
          form.resetFields();
          setaddedPhone("")
          Utils.setLocalInfo("userhash", otps[1]["user"]);
          Utils.setCookie("sessionhash", otps[1]["token"]);
        }
        else{
          setsending(false)
          setauthMode("register")
          setbtnDisabled(true)
        }
        
      }
      else if(otps[0] && !otps[1]["IsSuccessful"]){
        message.error(otps[1]["FriendlyMessage"])
        setsending(false)
        
      }
      else{
        message.error(Utils.requestFaileddMsg())
        setsending(false)
      }
    }
    
  }
  const onChangeIn = (v)=>{
    
    if(authMode === "otp"){
    if(v["phone"].toString().length > 9){
      setbtnDisabled(false)
    }
    else{
      setbtnDisabled(true)
    }
  }
    else{
      if(v["otp"]){
        if(v["otp"].toString().length > 5){
          setbtnDisabled(false)
        }
        else{
          setbtnDisabled(true)
        }
      }
      else{
        console.log(v);
      }
      
    }
  }
  return (
    <>
   { data.title?
    <PageHeader
    className="site-page-header "
    onBack={() => {dispatch(toggleEditProfile(false));navigate("/re/listings")}}
    backIcon={data["back"]}
    ghost={false}
    title={data["title"]}
    subTitle={data["subtitle"]}
    extra={showExtra?headersExtra[showExtra]:null}
  />:<></>
}
<Modal title="Connect with HashSaga.." visible={isModalOpen} onCancel={handleCancel} footer={null}>
<div className='center flex justify-center'>
{authMode === "register"?<>
<Form
      form={formNew}
      name="normal_login"
      className="login-form"
      initialValues={{
        
        layout: "vertical",
        ...basicInfo
      }}
      layout={"vertical"}
      onFinish={onFinish}
      
    >
      <Form.Item
        name="fullname"
        label="Full Name"
        
        rules={[
          {
            required: true,
            message: 'Please input your name!',
            whitespace: true,
          },
        ]}
      >
        <Input placeholder='Name' size='large' className='rounded' style={{
            width: 340,
          }}/>
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
        
        rules={[
          {
            type: 'email',
            message: 'The input is not valid E-mail!',
          },
          {
            required: true,
            message: 'Please input your email!',
            whitespace: true,
          },
        ]}
      >
        <Input placeholder='Email' size='large' className='rounded' style={{
            width: 340,
          }}/>
      </Form.Item>
      <Form.Item
        name="phone"
        label="Contact Phone"
        extra={<Button onClick={()=>setauthMode("otp")} type='link'>Edit</Button>}
        disabled
        defaultValue={addedPhone}
        rules={[
          {
            required: true,
            message: 'Please input your phone number!',
          },
        ]}
      >
        <InputNumber
          addonBefore={prefixSelector}
          placeholder='Enter Phone Number'
          className='rounded'
          controls={false}
          disabled
          name="phone"
          style={{
            width: 340,
          }}
         size='large'
         autoComplete='off'
        />
      </Form.Item>
      
     
      <Form.Item
        name="city"
        
        extra="Location auto-detected"
        rules={[
          {
            required: true,
            message: 'Please input your city!',
            whitespace: true,
          },
        ]}
      >
        <Input placeholder='City' size='large'   className='rounded' style={{
            width: 340,
          }}/>
      </Form.Item>
      <Form.Item
        name="idProofOption"
        label="ID Proof"
        rules={[
          {
            required: true,
            message: 'Please input your Username!',
          },
        ]}
      >
      <Radio.Group defaultValue={basicInfo.idProofOption}  onChange={(e)=>setbasicInfo({...basicInfo,idProofOption:e.target.value})}  buttonStyle="solid" size="large" className='custom-ant-r' >
            <Radio.Button className='rounded-md mx-2 '  value="pancard">Pan Card</Radio.Button>
            <Radio.Button className='rounded-md mx-2 '  value="aadharcard">Aadhadr Card</Radio.Button>
            <Radio.Button className='rounded-md mx-2 '  value="other">Other Govt. ID</Radio.Button>
        </Radio.Group>
      </Form.Item>
      {
        basicInfo.idProofOption && 
        <Form.Item
        name="idProofNumber"
        label="ID Number"
        
        rules={[
          {
            required: true,
            message: 'Please input your ID Number!',
            whitespace: true,
          },
        ]}
      >
        <Input placeholder='ID Number' size='large'   className='rounded' style={{
            width: 340,
          }}/>
      </Form.Item>
      }
      <Form.Item className='w-50'>
        <Button type="primary" loading={uploading}  block htmlType="submit" size='large'  className={`px-3 brd-4 fw-700 mx-2 rounded-lg text-white bg-hs-pink  hover:border-hs-pink `}>
          {alreadyBroker?"Update Profile":"Create Profile"}
        </Button>
       
      </Form.Item>
      
    </Form>
</>:

<Form
        name="normal_login"
        className="login w-75"
        initialValues={{
          
          layout: "vertical",
          ...basicInfo
          
        }}
        onValuesChange={onChangeIn}
        layout={"vertical"}
        onFinish={onFinishEnter}
        form={form}

        >
{authMode === "otp" && <Form.Item
        name="phone"
        
        rules={[
          {
            required: true,
            message: 'Please input your phone number!',
          },
        ]}
      >
        <InputNumber
          addonBefore={prefixSelector}
          placeholder='Enter Phone Number'
          className='rounded'
          controls={false}
          maxLength={10}
          name="phone"
          style={{
            width: 340,
          }}
         size='large'
         autoComplete='off'
        />
      </Form.Item>}
      {authMode === "verifyotp" && <>
      <div className='text-center mb-3 flex items-center justify-center'>{`OTP has been sent to `}<span className='font-bold'>&nbsp;{addedPhone}</span> <Button className='pl-1' onClick={()=>setauthMode("otp")} type='link'>Edit</Button></div>
      <Form.Item
        name="otp"
        
        rules={[
          {
            required: true,
            message: 'Please enter otp!',
          },
        ]}
      >
        <Input
          
          placeholder='OTP'
          className='rounded'
          controls={false}
          maxLength={6}
          onKeyDown={(event) => {
            
            if(event.key === "Backspace"){

            }
            else if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
            }
        }}
          
          name="otp"
          stringMode={true}
          style={{
            width: 340,
          }}
         size='large'
         autoComplete='off'
        />
      </Form.Item></>}
      {
        authUser && authMode === "verifyotp" && <Form.Item name="connectto" defaultValue={basicInfo.connectto} valuePropName={"checked"} >
          <Checkbox checked  >Connect with logged in HashSaga user - {authUser["name"]}</Checkbox>
        </Form.Item>
      }
      <Form.Item className='mb-0 text-center' >
        
       
        <Button type="primary" disabled={btnDisabled}  loading={sending}  htmlType="submit" size='large'  className={btnDisabled?`px-3 brd-4 fw-700 mx-2 rounded-lg  disabled:bg-slate-50 disabled:text-slate-500`:`px-3 brd-4 fw-700 mx-2 rounded-lg text-white bg-hs-pink hover:border-hs-pink`}>
        Continue
        </Button>
       
      </Form.Item>
</Form>}
</div>

</Modal>
</>
  )
}

export default StreetHeader