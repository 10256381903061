import React from 'react'

const MediaContainer = ({mediaFileType,mediaFile}) => {
  return (
    <>
    {
        
        mediaFileType === "video"?
        <video width="640" height="360" controls>
        <source src={mediaFile} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
        :
        <img alt="example" src={mediaFile} />
    }
    </>
  )
}

export default MediaContainer