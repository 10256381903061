import React from "react";
import { AuthProvider } from "./AuthProvider";
import App from "./App";

import { Provider } from "react-redux";
// import { store } from "../store";
import store, { persistor } from 'store'


import { PersistGate } from "redux-persist/integration/react";
import { SocketProvider } from "./SocketProvider";
// import { persistStore } from "redux-persist";
// let persistor = persistStore(store);

const AppProivder = (props) => {
	return (
		<Provider store={store}>
				<PersistGate loading={<p>Loading...</p>} persistor={persistor}>
		<AuthProvider>
			<SocketProvider>
					<App />
			</SocketProvider>
		</AuthProvider>

				</PersistGate>
			</Provider>
	);
};
export default AppProivder;
