import { Row, Col, Typography, Modal, Button,Input, Spin,notification,message, Menu,
  Dropdown,
  Checkbox,
  List, } from "antd";

import React, { useContext, useEffect, useState } from "react";
import { GoogleOAuthProvider ,GoogleLogin,useGoogleLogin} from '@react-oauth/google';
import _ from "lodash";
import ApiService from "services/ApiService";
import { OverlayTrigger,Spinner,Tooltip } from "react-bootstrap";
import { InfoCircle, Link45deg, PencilFill, Plus, TrashFill, Youtube } from "react-bootstrap-icons";
// import GoogleLogin from "react-google-login";
import GoogleBtn from "assets/images/googlebtn.png";
import Utils from "helper/Utils";
import { AuthContext, AuthProvider } from "app/AuthProvider";
const { confirm } = Modal;
const { Paragraph } = Typography;
const SettingsWorkspaceDetails = ({ user }) => {
  const {setactiveWorkSpace,activeWorkSpace,setsetupCompleted,setupCompleted} = useContext(AuthContext)
  const [viewloading, setViewLoading] = useState(false);
  const currentworkspace = activeWorkSpace;
  const [loading, setLoading] = useState(false);
  const [stepEnabled, setstepEnabled] = useState(false);
  const [visible, setVisible] = useState(false);
  const [optionSource, setoptionSource] = useState(0);
  const [savingRssFeed, setsavingRssFeed] = useState(false);
  const [editId, seteditId] = useState(null);
  const [feedError, setfeedError] = useState(null);
  const [channelError, setchannelError] = useState(null);
  const [selectedChannel, setselectedChannel] = useState(null);
  const [spotifyAccounts, setspotifyAccounts] = useState(null);
  const [contentSource, setcontentSource] = useState(
    currentworkspace["contentSources"]
      ? currentworkspace["contentSources"]
      : []
  );
  const [name, setName] = useState(currentworkspace["name"]);
  const [link, setLink] = useState(currentworkspace["link"]);
  const [URL, setType] = useState("Company / Brand site");
  const [rssFeed, setRssFeed] = useState("");
  const [autoFetch, setAutoFetch] = useState(
    currentworkspace["autoFetch"] ? currentworkspace["autoFetch"] : false
  );

  useEffect(() => {
    if(contentSource.length === 0){
      setViewLoading(true)
      getDetails()
    }
    return () => {
      
    }
  }, [])
  const login = useGoogleLogin({
    clientId:"65930754957-030hmaepato0hpjqdnkmrrnbslo2c1c4.apps.googleusercontent.com",
    onSuccess: tokenResponse => responseGoogle(tokenResponse),
    flow: 'auth-code',
    scope:"profile email https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/youtube.force-ssl"
  });
  const getDetails = async()=>{
    const sources = await ApiService.GetApiCalls("creator/workspace/"+currentworkspace["wid"],"",true)
    if(sources[0] && sources[1]["IsSuccessful"]){
      const wp = sources[1]["workspace"]
      setcontentSource(wp["contentSources"]?wp["contentSources"]:[])
    }
    setViewLoading(false)
  }
  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleURLChange = (e) => {
    setType(e.target.value);
  };
  const clickMenu = (e) => {
    setType(e.key);
  };
  const menu = (
    <Menu onClick={clickMenu}>
      <Menu.Item key="--------">--------</Menu.Item>
      <Menu.Item key="Personal site">Personal site</Menu.Item>
      <Menu.Item key="Company / Brand site">Company / Brand site</Menu.Item>
    </Menu>
  );
  const saveWorkspace = async()=>{
    setLoading(true);
    const forUser = {
      brandInfo: { name: name, link: link, type: URL, autoFetch: autoFetch },
      isRSSFeedAdded: contentSource.length > 0 ? true : false,
      userId: user["uniqueId"],
    };
    const forWorkSpace = {
      name: name,
      link: link,
      type: URL,
      autoFetch: autoFetch,
      wid: currentworkspace["wid"],
    };
    // await setactiveWorkSpace(contentSource.length > 0 ? true : false, rssFeed);
    const saved = await ApiService.PostApiCalls("creator/workspace/update/"+currentworkspace["wid"],{user:forUser, workSpace:forWorkSpace});
    if (saved) {
      setactiveWorkSpace({...activeWorkSpace,name: name,
        link: link,
        type: URL,
        autoFetch: autoFetch,})
      setLoading(false);
     
      notification["success"]({
        message: "Details have been updated",
        description:""
      });
    }
  }
  const editContentSource = (item)=>{
    if (item.type === "feed") {
      setRssFeed(item.link);
      setVisible(true);
      setoptionSource(1);
      setsavingRssFeed(false);
      setfeedError(null);
      seteditId(item.id);
    } else {
      // setRssFeed(item.link)
      setVisible(true);
      setoptionSource(2);
      setsavingRssFeed(false);
      setfeedError(null);
      seteditId(item.id);
      setchannelError(null);
      setselectedChannel(null);
    }
  }
  const removeContentSource = (id,type) => {
    confirm({
      title: "Are you sure you want to delete this Content Source?",
      content: "This action can not be reversed",
      okText: "Delete",
      okButtonProps: { type: "primary", className: "brd-4" },
      okType: "danger",
      cancelButtonProps: { className: "brd-4 mr-3" },
      async onOk() {
        await ApiService.PostApiCalls("creator/workspace/removesource", {
          id: id,
          type:type,
          wid: currentworkspace["wid"],
        },true);
        const newSources = _.filter(contentSource, function(d, i) {
          return d.id !== id;
        });
        setcontentSource(newSources);
        notification["success"]({
          message: "Source has been deleted",
          description:""
        });
        
      },
      onCancel() {},
    });
  }
  const AddContentSource = ()=>{
    setVisible(true);
    // login()
    setoptionSource(0);
    setsavingRssFeed(false);
    setfeedError(null);
    seteditId(null);
    setchannelError(null);
    setselectedChannel(null);
    setspotifyAccounts(null)
  }
  const addRssFeed = async (type) => {
    setsavingRssFeed(true);
    setfeedError(null);
    let cdata = {
      type: type,
      link: rssFeed,
      wid: currentworkspace["wid"],
      createdId: contentSource.length,
      editId: editId,
    };
    if (type === "ytchannel") {
      cdata["channel"] = selectedChannel["user"];
    } else {
      const forWorkSpace = {
        autoFetch: true,
        isRSSFeedAdded: true,
        feedLink: rssFeed,
      };
      cdata["workspace"] = forWorkSpace;
    }
    const saved = await ApiService.PostApiCalls("creator/workspace/addsource", cdata,true);
    if (saved[0] && saved[1]["success"]) {
      const data = saved[1]["data"];
      if (editId) {
        const newSources = _.filter(contentSource, function(d, i) {
          if (d.id === editId) {
            d.link =
              type === "ytchannel" ? selectedChannel["user"]["name"] : rssFeed;
            return d;
          } else {
            return d;
          }
        });
        setcontentSource(newSources);
        setsetupCompleted({...setupCompleted,rssFeed:true})
      } else {
        setcontentSource([...contentSource, data]);
      }

      setVisible(false);
      message.success({
        content: "New content source has been added",
        className: "custom-class",
        duration: 2,
      });
      setsavingRssFeed(false);
    } else if (saved[0] && !saved[1]["success"]) {
      setfeedError(
        "The source of the content could not be verified. If you haven't already, try including the URL of one of your most recent blog posts."
      );
    }

    setsavingRssFeed(false);
  }
  const responseGoogle = async (data) => {
    ///creator/workspace/addyoutube
    setchannelError(null)
    console.log(data);
    const add = await ApiService.PostApiCalls("creator/workspace/addyoutube", {
      code: data.code,
      wid: currentworkspace["wid"],
    },true);
    if (add[0] && add[1]["IsSuccessful"]) {
      setselectedChannel(add[1]["data"]);
    } else {
      setchannelError(
        add[1]["err"]?add[1]["err"]:"Error while processing YouTube Channel request, try again!"
      );
    }
  }
  if(viewloading){
    return (
      <>
      <Col span={24} className="py-0 px-0 mb-0">
        <div className="bg-white p-3 px-3 flex space-x-3 items-center justify-between">
          <span className="font-bold">Workspace Details</span>
          <Button
            
            type="primary"
            className=" rounded  float-right mr-3"
            onClick={saveWorkspace}
                disabled={loading}
          >
           Save Details
          </Button>
        </div>
      </Col>
      <div className="pb-2 bg-gray-100"></div>
      <Col span={24} className="py-0 px-0 mb-0 h-screen flex items-center justify-center">
      <Spin />
      </Col>
      </>
    )
  }
  return (
    <> <Col span={24} className="py-0 px-0 mb-0">
    <div className="bg-white p-3 px-3 flex space-x-3 items-center justify-between">
      <span className="font-bold">Workspace Details</span>
      <Button
         
        type="primary"
        className=" rounded  float-right mr-3"
        loading={loading}
      >
      Save Details
      </Button>
    </div>
  </Col>
  <div className="pb-2 bg-gray-100"></div>
  <Col span={24} className="">
  <Row className="p-2">
  <Col span={24} className="p-2">
            <Paragraph className="text-sm text-hs-dark">
            Keep your Workspace information current. We recommend double-checking that the RSS Feed URL or YouTube Channel we've included below is correct.
            </Paragraph>
          </Col>
          <Col className="px-0 pr-4 mt-4 " span={24}>
          <Row className="mt-3 mx-0 pr-3">
              <Col
                span={8}
                className="campaign-new-info fz-14 fw-600 mb-md-3 mb-sm-3 px-2"
              >
                <span className="mb-2 float-left mr-2">Name</span>
                <OverlayTrigger
                  key={0}
                  placement={"top"}
                  overlay={
                    <Tooltip id={`tooltip-0`}>
                      Change name for workspace
                    </Tooltip>
                  }
                >
                  <InfoCircle className="ml-2" size={14} color={"#646464"} />
                </OverlayTrigger>
                <Input
                  placeholder="Add name"
                  value={name}
                  onChange={handleNameChange}
                />
              </Col>
              <Col
                span={8}
                className="campaign-new-info fz-14 fw-600 px-2"
              >
                <span className="mb-2 float-left  mr-2">Blog Type</span>
                <OverlayTrigger
                  key={0}
                  placement={"top"}
                  overlay={
                    <Tooltip id={`tooltip-0`}>
                      Select personal or company brand
                    </Tooltip>
                  }
                >
                  <InfoCircle className="ml-2" size={14} color={"#646464"} />
                </OverlayTrigger>
                <Dropdown overlay={menu} trigger={["click"]}>
                  <Input
                    placeholder="Type"
                    value={URL}
                    disabled
                    onChange={handleURLChange}
                  />
                </Dropdown>
              </Col>
              <Col
                span={8}
                className="campaign-new-info fz-14 fw-600 mb-md-3 mb-sm-3 px-2"
              >
                <span className="mb-2 float-left  mr-2">Workspace Link</span>
                <OverlayTrigger
                  key={0}
                  placement={"top"}
                  overlay={
                    <Tooltip id={`tooltip-0`}>
                      Enter your website link of company, blog, website, brand
                      etc. This is not for as a content source.
                    </Tooltip>
                  }
                >
                  <InfoCircle className="ml-2" size={14} color={"#646464"} />
                </OverlayTrigger>
                <Input
                  placeholder="Enter your website link of company, blog, website, brand etc."
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                />
              </Col>
            </Row>
          </Col>
          <Col className="px-0 pr-4 mt-2 " span={24}>
          <Row className="mt-4  mx-0">
              <Col
                span={24}
                className="campaign-new-info fz-14 fw-600 mb-md-3 mb-sm-3 px-3"
              >
                <span className="mb-2 float-left mr-2">
                  <Checkbox
                    defaultChecked={autoFetch}
                    onChange={(e) => setAutoFetch(e.target.checked)}
                    className="mr-4 wsp-auto"
                  />
                  Auto fetch articles?
                </span>
                <OverlayTrigger
                  key={0}
                  placement={"top"}
                  overlay={
                    <Tooltip id={`tooltip-0`}>
                      Select to fetch articles from the RSS feed.
                    </Tooltip>
                  }
                >
                  <InfoCircle className="ml-2" size={14} color={"#646464"} />
                </OverlayTrigger>
              </Col>
            </Row>
          </Col> <Col className="px-0 pr-4 mt-2 " span={24}>
          <Row className="mt-4  mx-0">
              <Col
                span={24}
                className="campaign-new-info fz-14 fw-600 mb-md-3 mb-sm-3 px-3 text-right"
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={saveWorkspace}
                  disabled={loading}
                  className=" brd-4 rounded-md"
                >
                  {loading && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="mr-2"
                    />
                  )}
                  <span>{loading ? "Saving workspace..." : "Save Details"}</span>
                </Button>
              </Col>
            </Row>
          </Col>
          <Col className="px-0 pr-4 mt-4 " span={24}>
          <Row className="mt-4  mx-0">
              <Col
                span={24}
                className="campaign-new-info fz-14 fw-600 pr-5"
              >
                <div className="w-100 d-flex flex-column mb-3">
                  <span className="mb-2 float-left fz-16 fw-700">
                    Content Sources
                  </span>
                  <span>
                    Each Source you put here will be monitored by Hash Saga. When fresh content is discovered, it is
                    instantly converted into a Drip Campaign and made available
                    for your evaluation.
                  </span>
                </div>
                <div className="w-50">
                  {contentSource && (
                    <List
                      className="border-2 bg-aqua bg-light  brd-10 px-3"
                      itemLayout="horizontal"
                      dataSource={contentSource}
                      renderItem={(item) => (
                        <List.Item>
                          <List.Item.Meta
                            title={
                              <span className="fw-600 fz-16">
                                {item.type === "feed"
                                  ? "RSS Feed":item.type === "spotify"
                                  ? "Spotify Account"
                                  
                                  : "YouTube Channel"}
                              </span>
                            }
                            description={
                              <span className="d-flex flex-row align-items-center justify-content-between ">
                                <span className="d-flex flex-row align-items-center bg-white p-2 brd-4">
                                  {item.type === "feed" ? (
                                    <Link45deg />
                                  ) :(
                                    <Youtube />
                                  )}
                                  <span className="pl-2">{item.type === "spotify" ?item.name:item.link}</span>
                                </span>
                                <span className="d-flex">
                                { !item.type === "spotify" &&  <span
                                    onClick={() => editContentSource(item)}
                                    className="bg-white brd-4 p-2 shadow-lg cursor-pointer d-flex"
                                  >
                                    <PencilFill />
                                  </span>}
                                  <span
                                    onClick={() => removeContentSource(item.id,item.type)}
                                    className="bg-white brd-4 p-2 shadow-lg cursor-pointer d-flex ml-2"
                                  >
                                    <TrashFill />
                                  </span>
                                </span>
                              </span>
                            }
                          />
                        </List.Item>
                      )}
                    ></List>
                  )}
                </div>
              </Col>
              {contentSource.length < 3 && (
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  className="campaign-new-info fz-14 fw-600 pr-5 py-2"
                >
                  <Button
                    onClick={AddContentSource}
                    type="primary"
                    className="d-flex align-items-center fw-600 brd-4"
                  >
                    <Plus size={22} className="mr-1 wsp-input" />
                    Add Content Source
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
  </Row>
  </Col>
  <Modal
        visible={visible}
        title="Add new content source"
        okText="Create"
        cancelText="Cancel"
        onCancel={() => setVisible(false)}
        footer={null}
      >
        {optionSource === 0 && (
          <div className="d-flex flex-col flex-column">
            <span className="fz-16">
              Select the sort of Content Source you'd want to include. Hash Saga will monitor the source for new content and transform
              it into Drip Campaigns once it is linked.
            </span>
            <span className="py-2"></span>
            <span
              onClick={() => setoptionSource(1)}
              className="p-4 brd-4 shadow-sm d-flex align-items-center cursor-pointer color-sidebar-menu"
            >
              <Link45deg size="24" />
              <span className="fz-18 ml-2 fw-600">RSS Feed</span>
            </span>
            <span className="py-2"></span>
            <span
              onClick={() => setoptionSource(2)}
              className="p-4 brd-4 shadow-sm d-flex align-items-center  cursor-pointer"
            >
              <Youtube size="24" color="#dd4b39" />
              <span className="fz-18 ml-2 fw-600" style={{ color: "#dd4b39" }}>
                YouTube Channel
              </span>
            </span>
            <span className="py-2"></span>
            {/* <span
              onClick={() => setoptionSource(3)}
              className="p-4 brd-4 shadow-sm d-flex align-items-center  cursor-pointer"
            >
              <Spotify size="24" color="#1DB954" />
              <span className="fz-18 ml-2 fw-600" style={{ color: "#1DB954" }}>
              Spotify Account
              </span>
            </span> */}
            <span className="py-2"></span>
          </div>
        )}
        {optionSource === 1 && (
          <div className="d-flex flex-col flex-column">
            <span className="fz-16">
              Enter the URL of your RSS feed if you know it. If not, input the
              URL of a recent blog post, and we should be able to figure out the
              RSS feed from there.
            </span>
            <span className="py-2"></span>
            {feedError && (
              <span className="bg-danger text-white p-3 brd-4 fw-600">
                {feedError}
              </span>
            )}
            <span className="py-2"></span>
            <span className="campaign-new-infos d-flex ">
              <span className="wsp-input d-flex ant-input-rss py-0 flex-grow-1 noRightBorder">
                <Input
                  bordered={false}
                  placeholder="Add rss feed"
                  value={rssFeed}
                  onChange={(e) => setRssFeed(e.target.value)}
                />
              </span>
              <span className="wsp-input d-flex ant-input-rss py-0 noLeftBorder bg-blue ">
                <Button
                  loading={savingRssFeed}
                  onClick={() => addRssFeed("feed")}
                  type="primary"
                  size="large"
                >
                  Save
                </Button>
              </span>
            </span>
            <span className="py-2"></span>
          </div>
        )}
        {optionSource === 2 && (
          <div className="d-flex flex-col flex-column">
            <span className="fz-16">
              To link your YouTube account, please click the icon below. You'll
              be able to pick the Channel you'd want us to monitor after you've
              joined.
            </span>
            <span className="py-2"></span>
            {channelError && (
              <span className="bg-danger text-white p-3 brd-4 fw-600">
                {channelError}
              </span>
            )}
            <span className="py-2"></span>
            {!selectedChannel && (
              <span>
                <div
                      className="btn-social cursor-pointer"
                      onClick={login}
                    >
                      <img src={GoogleBtn} alt="Google" width="40%" />
                    </div>
                {/* <GoogleLogin
                  clientId={"65930754957-030hmaepato0hpjqdnkmrrnbslo2c1c4.apps.googleusercontent.com"}
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  scope="profile email https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/youtube.force-ssl"
                  accessType="offline"
                  responseType="code"
                  prompt="consent"
                  render={(renderProps) => (
                    <div
                      className="btn-social cursor-pointer"
                      onClick={renderProps.onClick}
                    >
                      <img src={GoogleBtn} alt="Google" width="40%" />
                    </div>
                  )}
                /> */}
              </span>
            )}
            {selectedChannel && (
              <span className="campaign-new-infos d-flex ">
                <span className="wsp-input d-flex ant-input-rss py-0 flex-grow-1 pl-3 noRightBorder align-items-center">
                  <Youtube size="20" color="#dd4b39" />
                  <span className="px-2 fw-700 fz-16">
                    {selectedChannel["user"]["name"]}
                  </span>
                </span>
                <span className="wsp-input d-flex ant-input-rss py-0 noLeftBorder bg-blue ">
                  <Button
                    loading={savingRssFeed}
                    onClick={() => addRssFeed("ytchannel")}
                    type="primary"
                    size="large"
                  >
                    Save
                  </Button>
                </span>
              </span>
            )}
            <span className="py-2"></span>
          </div>
        )}
        {optionSource === 3 && (
          <div className="d-flex flex-col flex-column">
            <span className="fz-16">
              To link your Spotify account, please click the icon below. You'll
              be able to pick the account you'd want us to monitor after you've
              joined.
            </span>
            <span className="py-2"></span>
            {channelError && (
              <span className="bg-danger text-white p-3 brd-4 fw-600">
                {channelError}
              </span>
            )}
            <span className="py-2"></span>
            {/* {!spotifyAccounts && (
              <span className="col-5 px-0">
               <a href={`${process.env.REACT_APP_SPOTIFY_LOGIN}?path=${props.location.pathname}&id=${userDetails["userId"]}&wid=${currentworkspace[0]["wid"]}`}> <span className="spotify-btn">
                <Spotify size="18" className="mx-2" />
                <span>Login with Spotify</span>
                </span></a>
              </span>
            )} */}
            {/* {spotifyAccounts && (
              <span className="campaign-new-infos d-flex ">
                <span className="wsp-input d-flex ant-input-rss py-0 flex-grow-1 pl-3 noRightBorder align-items-center">
                  <Youtube size="20" color="#dd4b39" />
                  <span className="px-2 fw-700 fz-16">
                    {spotifyAccounts["user"]["name"]}
                  </span>
                </span>
                <span className="wsp-input d-flex ant-input-rss py-0 noLeftBorder bg-blue ">
                  <Button
                    loading={savingRssFeed}
                    onClick={() => addRssFeed("ytchannel")}
                    type="primary"
                    size="large"
                  >
                    Save
                  </Button>
                </span>
              </span>
            )} */}
            <span className="py-2"></span>
          </div>
        )}
      </Modal>
  </>
  )
}

export default SettingsWorkspaceDetails