import React from 'react'
import YouTubePlayer from './YouTubePlayer'
import MediaContainer from './MediaContainer'

const ContentMedia = ({post}) => {
  return (
    <>
    {
        post["netname"] === "YouTube"?
        <YouTubePlayer videoId={post["contentSourceId"]} />
        :
        post["netname"] === "blume"?
        <MediaContainer mediaFile={post["mediaFile"]}  mediaFileType={post["mediaFileType"]} />
        :
        <img alt="example" src={post["mediaFile"]} />
    }
    </>
    
  )
}

export default ContentMedia