import { Button, Spin } from "antd";
import _ from "lodash";
import React, { useState, useEffect } from "react";
import ApiService from "services/ApiService";
import CommentCard from "./CommentCard";
import NewComment from "./NewComment";

const Comments = ({content,callback}) => {
  const [loading, setloading] = useState(true);
  const [commentsAddded, setcommentsAddded] = useState([])
  const [loadedAllComments, setloadedAllComments] = useState(false)
  const [queryParams, setqueryParams] = useState({limit:5,offset:0,sort:"desc"})
  useEffect(() => {
      if(loading){
        getComments();
      }
    //
      
    return () => {};
  }, [commentsAddded]);
  const getComments = async() => {
    const queryString = new URLSearchParams(queryParams).toString()
    
   
      const comments = await ApiService.GetApiCalls(`posts/${content["contentID"]}/comments`,queryString,true)
      if(comments[0] && comments[1]["IsSuccessful"]){
          setloading(false);
          setcommentsAddded([...commentsAddded,...comments[1]["comments"]])
          setloadedAllComments(comments[1]["comments"].length < 5?true:false)
          const nextOffest = queryParams["offset"]+5;
          setqueryParams({...queryParams,offset:nextOffest})
          
      }
      
  };
  const appendNewComment = (comment)=>{
    let reversedFirst = _.reverse(commentsAddded);
    setcommentsAddded([])
   
    reversedFirst.push(comment)
    let reversedArray = _.reverse(reversedFirst);
    setcommentsAddded(reversedFirst)
    
  }
  return (
      <>
    {loading && <div className="flex items-center justify-center p-4">
      <Spin size="small" />
    </div>}
    {!loading && commentsAddded.length === 0 && <div className="flex items-center justify-center p-4">
      No Comments
    </div>}
    {!loading && commentsAddded.length > 0 && <>
      {
          commentsAddded.map((comment,key)=>(
            <CommentCard key={key} content={comment} id={key} />
        ))
      }
    </>}
    {!loading && !loadedAllComments && <div className="flex items-center justify-center p-1">
     <Button onClick={()=>getComments()} type="link">Show more comments</Button>
    </div>}
    {/* Add CommentBox */}
    <NewComment content={content} callback={callback} appendNewComment={appendNewComment} />
    </>
  );
};

export default Comments;
