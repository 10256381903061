import React, { useEffect } from 'react'
import HomeSectionOne from './components/HomeSectionOne'
import NavBarHome from './components/NavBar'

import HomeSctionTwo from './components/HomeSctionTwo'
import HomeSectionThree from './components/HomeSectionThree'

const HomePage = () => {
    useEffect(() => {
        
        
    
      return () => {
        
      }
    }, [null])
    
    return (
        <>
        <NavBarHome />
        <div className="min-h-screen relative">
        <HomeSectionOne />
        <HomeSctionTwo />
        <HomeSectionThree />
        </div>
        </>
    )
}

export default HomePage
