import React, { useContext, useEffect, useState } from "react";
import home1 from "assets/images/home-1/Card.png";
import home2 from "assets/images/home-2/Cards.png";
import home3 from "assets/images/home-3/Cards.png";
import home4 from "assets/images/home-4/Cards.png";
import home5 from "assets/images/home-5/Image.png";
import HomeDots from "assets/images/home-dots.svg";
import AuthModal from "./AuthModal";
import { Modal, Button, Input, Col } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { Search } from "react-bootstrap-icons";
import { AuthContext } from "app/AuthProvider";
import { useTranslation } from 'react-i18next';

const HomeSectionOne = () => {
  // const {} = useContext(AuthContext)
  const navigate = useNavigate()
  const { t } = useTranslation();

  const [isVisible, setisVisible] = useState(false);
  const [showSetup, setshowSetup] = useState(false);
  const [type, settype] = useState("follower");
  const [redirect, setredirect] = useState(null);
  useEffect(() => {
    
  
    return () => {
      
    }
  }, [])
  
  const initAuth = (type) => {
    settype(type);
    setisVisible(true);
  };
  
  const searchon = (e) => {
    if (e.key === "Enter") {
      setredirect(e.target.value);
    }
  };
 
  if (redirect) {
    return navigate("/search/top?q=" + redirect) ;//<Redirect to={"/search/top?q=" + redirect} />;
  }
  return (
    <>
      <Col className="flex justify-around items-center h-screen px-0 mx-auto relative" xs={24} sm={24} md={12} lg={12} xl={12}>
        <div className="flex items-center w-full justify-center flex-col">
          <div className="text-white text-xl tracking-wide leading-relaxed py-6">
          
    {t("home_header")}

          </div>
          <div className="flex items-center w-100 justify-center px-10">
            <Input
              placeholder={t("home_search_placeholder")}
              className="rounded-md w-full p-2"
              size="large"
              prefix={<Search className="opacity-50" />}
              onKeyPress={(e) => searchon(e)}
            />
          </div>
          <div className="flex-1 w-100">
            {/* <div className="flex w-auto justify-center mt-10">
              <button
                onClick={() => initAuth("Follower")}
                className="bg-blue-1 font-normal p-3 px-6 text-white rounded-3xl"
              >{t("I am Followers")}
                
              </button>
              <Link to={"/creator/edit/start"} ><button
                className="bg-red-1 p-3 px-6 text-white ml-8 rounded-3xl"
              >{t("ICreators")}
              </button></Link>
            </div> */}
            {/* 
            //Disbaled for timebieng*/}
            <div className="flex w-auto justify-center mt-7 space-x-6">
              {/* <Link to={"/accept-crypto-demo"}>
                <Button
                  size="large"
                  className="font-normal rounded-md text-hs-pink  bg-white border-hs-pink"
                >
                  {t("Accept Crypto")}
                </Button>
              </Link> */}
              {/* <Link to={"/donate-crypto"}>
                <Button
                  size="large"
                  className="font-normal text-white rounded-md bg-hs-pink border-hs-pink"
                >
                  {t("Donate Crypto")}
                </Button>
              </Link> */}
            </div> 
          </div>
        </div>
        {/* <div className="flex-1">
          <div className="text-white text-3xl tracking-wide leading-relaxed">
            A Market Place for <br></br>Creators by creators
          </div>
          <div className="flex w-auto justify-start mt-7">
            <button
              onClick={() => initAuth("Follower")}
              className="bg-blue-1 font-normal p-3 px-6 text-white rounded-3xl"
            >
              I am Follower
            </button>
            <button
              onClick={() => initAuth("Creator")}
              className="bg-red-1 p-3 px-6 text-white ml-8 rounded-3xl"
            >
              I Am Creator
            </button>
          </div>
          <div className="flex w-auto justify-start mt-7 space-x-6">
            <Link to={"/accept-crypto-demo"}><Button size="large" className="font-normal rounded-md text-hs-pink  bg-white border-hs-pink">Accept Crypto</Button></Link>
            <Link to={"/donate-crypto"}><Button size="large" className="font-normal text-white rounded-md bg-hs-pink border-hs-pink">Donate Crypto</Button></Link>
            
          </div>
        </div> */}
        {/* <div className="flex-1 bg-yellow-500 object-center relative">
          <div className="absolute " style={{ top: "-65px" }}>
            <img src={home1} alt=""></img>
          </div>
          <div
            className="absolute "
            style={{
              left: "15%",
              top: "-100px",
              width: "256px",
              height: "256px",
            }}
          >
            <img src={home2} alt=""></img>
          </div>
          <div
            className="absolute "
            style={{ left: "30%", top: "-145px", zIndex: 3 }}
          >
            <img src={home3} alt=""></img>
          </div>
          <div
            className="absolute "
            style={{
              left: "60%",
              top: "-100px",
              zIndex: 2,
              width: "256px",
              height: "256px",
            }}
          >
            <img src={home4} alt=""></img>
          </div>
          <div
            className="absolute "
            style={{ right: "-10%", top: "-65px", zIndex: 1 }}
          >
            <img src={home5} alt=""></img>
          </div>
        </div> */}

        <div className="absolute right-0  bottom-0">
          <img src={HomeDots} alt="" />
        </div>
      </Col>
      <AuthModal
        isVisible={isVisible}
        type={type}
        cancelCallback={() => setisVisible(false)}
        creatorCallback={() => {
          setshowSetup(true);
          setisVisible(false);
        }}
      />
      <Modal
        title={""}
        footer={null}
        visible={showSetup}
        closable={false}
        bodyStyle={{ paddingBottom: ".5rem" }}
        maskStyle={{ backgroundColor: "#000000e3" }}
      >
        <p>It's fantastic to have you on board. Thank you for registering! </p>
        <p>Complete creator setup with minmum steps.</p>
        <p className="py-2 space-x-3">
          <Link to="/creator/edit/1">
            <Button
              type="primary"
              className="rounded-md bg-red-1 px-4 border-0"
            >
              Complete Setup
            </Button>
          </Link>
          <Link to="/">
            <Button className="rounded-md">I will do later</Button>
          </Link>
        </p>
      </Modal>
    </>
  );
};

export default HomeSectionOne;
