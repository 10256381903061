import { Col, Divider, List } from "antd";
import React from "react";
import { ArrowLeft, Check2, ChevronRight } from "react-bootstrap-icons";
import { Link } from 'react-router-dom';


const UserDisplay = ({user,params}) => {
  const listData = [ {slug: 'language',lable:"Language"},]
  const listLanData = [ {slug: 'en',lable:"English"},{slug: 'ja',lable:"Japanese"},{slug: 'hi',lable:"Hindi"},{slug: 'de',lable:"Deutsch"},{slug: 'fr',lable:"Français"}]
  const active = "as";
  const lanList = {
    'en':"English",
    'ja':"Japanese",
    'hi':"Hindi",
    'de':"Deutsch",
    'fr':"Français",
  }
  
  const activelan = localStorage.getItem('appLocale')?localStorage.getItem('appLocale'):'en';
  const changeLocale = (locale) =>{
    localStorage.setItem('appLocale',locale);

    window.location.reload();


}
  return (
    <>
    {!params.type && <><Col span={24} className="py-0 px-0 mb-2">
      <div className="bg-white p-3 px-3 flex space-x-3 items-center">
        
        <span className="font-bold">DISPLAY</span>
      </div>
    </Col>
    <Col span={24} className="h-100 bg-white py-0 px-0 flex flex-col">
    <List
      header={<div className='font-semibold px-3'>
          Customize your view
          </div>}
      className="bg-white pb-3"
      dataSource={listData}
      renderItem={item => (
        <List.Item className='cursor-pointer p-2'>
             <Link className='text-hs-dark px-1 flex w-100' to={`/my/settings/display/${item["slug"].toLowerCase()}`}>
              <span className={item["slug"].toLowerCase() === active ?'flex justify-between items-center w-100 p-2 bg-blue-50 text-blue-400':'flex justify-between items-center rounded-sm w-100 p-2 px-3 hover:bg-blue-50 hover:text-blue-400'}>
              <List.Item.Meta
         
          title={item.lable}
          description={lanList[activelan]}
        />
              
              <ChevronRight size={20} />
              </span></Link>
        </List.Item>
     )}
    />
    </Col></>}
    {params.type === 'language' && <><Col span={24} className="py-0 px-0 mb-2">
      <div className="bg-white p-3 px-3 flex space-x-3 items-center">
      <Link className="text-hs-dark" to={"../display"}> <ArrowLeft size={22} /></Link>
        <span className="font-bold">DISPLAY</span>
      </div>
    </Col>
    <Col span={24} className="h-100 bg-white py-0 px-0 flex flex-col">
    <List
      header={<div className='font-semibold px-3'>
          Select language
          </div>}
      className="bg-white pb-3"
      dataSource={listLanData}
      renderItem={item => (
        <List.Item className='cursor-pointer p-2' onClick={()=>changeLocale(item.slug)}>
             
              <span className={item["slug"].toLowerCase() === active ?'flex justify-between items-center w-100 p-2 bg-blue-50 text-blue-400':'flex justify-between items-center rounded-sm w-100 p-2 px-3 hover:bg-blue-50 hover:text-blue-400'}>
              <List.Item.Meta
         
          title={item.lable}
          
        />
              
              {activelan === item.slug && <Check2 size={24} color="green" />}
              </span>
        </List.Item>
     )}
    />
    </Col></>}
    </>
  );
};

export default UserDisplay;
