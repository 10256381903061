import React,{useState,useEffect,useContext} from 'react'
import ApiService from "services/ApiService";
import { VideoContext } from 'app/VideoProvider';
const ThemeView = ({akey,current}) => {
    const { video,setVideo,videoId} = useContext(VideoContext);
    const [list,setList] = useState([])
    const [selected,setSelected] = useState([])

    useEffect(() => {
        if((current === parseInt(akey)) && list.length === 0){
            getListTemplate()
        }       
        setSelected(video?video["template"]:"")
      return () => {
        
      };
    }, [akey])
    const getListTemplate =  async() =>{
        const m = await ApiService.GetApiCalls("videoTemplates","", true)
        if (m[0] && m[1]["IsSuccessful"]) {
            setList(m[1]["list"])
        }
    }
    const updateTemplate = (t) =>{
        setSelected(t)
        let videoDetails = video;
        videoDetails["template"] = t;
        setVideo(videoDetails)
        updateServer({template:t})
    }
    const updateServer = async(input) =>{
        const data = {
            vid:videoId,
            update:input
        }
        // console.log(data);
        
        await ApiService.PostApiCalls("campaign/videoUpdate",{...data},true)
        // await ApiService.updateVideoData(data);
    }
    return (
        <div className="d-flex flex-row flex-wrap align-items-center justify-content-around">
            {
                list.map((t,key)=>(
                    <div key={key} onClick={()=>updateTemplate(t)} className={selected["Id"]===t["Id"] ?"cursor-pointer template-image selected":"cursor-pointer template-image"}>
                        <img src={t["GifFile"]} alt={t["Name"]}/>
                        <div className="ThemeName">{t["Name"]}</div>
                    </div>

                ))
            }
            
        </div>
    )
}

export default ThemeView
