import React, { useEffect, useState } from "react";
import {
  
  Col,
  List,
  Button,
  Spin,
  Result,
  Select,
} from "antd";
import _ from "lodash";
import Hashtags from "container/common/Hashtags";
import ApiService from "services/ApiService";
import HeaderDropdownSelection from "./HeaderDropdownSelection";
import HashtagsSales from "./sale/HashtagsSales";
const UserKeywords = ({ setaddHash,activeTab, setmodalEvent, authUser, openModel }) => {
  const [loadingData, setloadingData] = useState(true);
  const [mintedHash, setmintedHash] = useState([]);
  const [activeSelection, setactiveSelection] = useState("Minted")
  
  const [draftHash, setdraftHash] = useState([]);
  useEffect(() => {
    getMinedData(activeTab);
    return () => {};
  }, [activeSelection]);
  const getMinedData = async (activeTab) => {
    setloadingData(true)
    setmintedHash([])
    let filter = {}
    if(activeSelection === "Minted"){
      filter = { $or: [
        { "onListing": {$exists:false} },
        { "onListing": false },
      ],minted:true,mentionAllowed:{$exists:false}}
      mintedRequest(filter)
    }
    else if(activeSelection === "Sale"){
      filter = {type:["sale","auction"],minted:true}
      saleRequest(filter)
    }
    else if(activeSelection === "Mention Allowed"){
      filter = {type:["mention"],minted:true}
      saleRequest(filter)
    }
    else {
      filter = {type:["rent"],minted:true}
      saleRequest(filter)
    }
    
  };
  const mintedRequest = async(filter) =>{
    const data = await ApiService.PostApiCalls(
      "creator/hash/list",
      { minted: true,filter:filter },
      true
    );
    if (data[0] && data[1]["IsSuccessful"]) {
      const minted = _.filter(data[1]["hash"], function(d, i) {
        if (d.minted) {
          return d;
        }
      });
      setmintedHash(minted);
      const drafts = _.filter(data[1]["hash"], function(d, i) {
        if (!d.minted) {
          return d;
        }
      });

      setdraftHash(drafts);
    }
    setloadingData(false)
  }
  const saleRequest = async(filter)=>{
    const data = await ApiService.PostApiCalls(
      "keyhashes/user/sales/list",
      { minted: true,...filter },
      true
    );
    if (data[0] && data[1]["IsSuccessful"]) {
      
      setmintedHash(data[1]["salesList"]);
      
    }
    setloadingData(false)

  }
  const handleChange = (e)=>{
    setactiveSelection(e)
  }
  return (
    <>
      {loadingData && (
        <div className="py-5 flex flex-col items-center justify-center">
          <Spin />
          <span className="py-2 text-base font-semibold">Tips</span>
          <span className="py-2 text-sm">
            Content with at least 1,500 words generates 30% more clicks on
            average.
          </span>
        </div>
      )}
      {/* {!loadingData && mintedHash.length === 0 && (
        <Col
          className="px-0 py-0 my-0 d-flex align-items-center justify-content-center loader-height"
          lg={24}
          md={24}
          sm={24}
        >
          <Result
            status="500"
            title=""
            subTitle="You have not minted any Hash yet!"
            extra={
              <Button
                onClick={(e) => {
                  setmodalEvent({ event: "mint", item: null });
                  setaddHash(true);
                }}
                type="primary"
              >
                Start Now
              </Button>
            }
          />
        </Col>
      )} */}
      {!loadingData  && (
        <>
        <Col className="px-3 pb-2 ml-2">
          <HeaderDropdownSelection options={["Minted", "Rented","Sale","Mention Allowed"]} defaultSelected={activeSelection} callback={handleChange}/>
        </Col>
        <Col className="px-3">
          {activeSelection === "Minted"?
          <List
          bordered
          itemLayout="horizontal"
          dataSource={mintedHash}
          renderItem={(item) => (
            <List.Item>
              {" "}
              <Hashtags
                hash={item}
                type={"minted"}
                user={authUser}
                callback={openModel}
              />
            </List.Item>
          )}
        />:
        <List
        bordered
        itemLayout="horizontal"
        dataSource={mintedHash}
        renderItem={(item) => (
          <List.Item>
            {" "}
            <HashtagsSales
              hash={item}
              type={"minted"}
              user={authUser}
              callback={openModel}
            />
          </List.Item>
        )}
      />}
        </Col>
        </>
      )}
    </>
    // <div>UserKeywords</div>
  );
};

export default UserKeywords;
