import { Button, Col, Form, Input, Switch } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { List, Select, Divider,notification } from "antd";
import { ArrowLeft, ChevronBarRight, ChevronRight } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import ApiService from "services/ApiService";
import { AuthContext } from "app/AuthProvider";
import Utils from "helper/Utils";
import { useToasts } from 'react-toast-notifications';
import jsonData from '../../../res/extra/country_dial_info.json';
import _ from "lodash";

const { Option } = Select;
const UserAccountInfo = ({user,header,passtoken,hideHeader}) => {
    
    const {setAuthUser,authUser} = useContext(AuthContext)
    const { addToast } = useToasts()
    const [userName, setuserName] = useState(user["userName"])
    const [email, setEmail] = useState(user["email"])
    const [phone, setphone] = useState(user["phone"] && user["phone"]['number']?user["phone"]['number']:"")
    const [isdCode, setisdCode] = useState(user["phone"] && user["phone"]['dial_code']?user["phone"]['dial_code']:"")
    const [selectedCodeFlag, setselectedCodeFlag] = useState(user["phone"] && user["phone"]['name']?user["phone"]['name']:"")
    const [isSaving, setisSaving] = useState(false);
    const [disabled, setdisabled] = useState(true);
    const [hasError, sethasError] = useState(null)
    const [apiError, setapiError] = useState(null)
    const [isdCountry, setisdCountry] = useState(user['phone']?_.omit(user['phone'],['number']):{})
    const [showSMSNumber, setshowSMSNumber] = useState(user['twoFaAuth']&&user['twoFaAuth']['number']?true:false)
    const [form] = Form.useForm();
    useEffect(() => {
      
      if(header === '2fa')  {
        setphone(user['twoFaAuth']&&user['twoFaAuth']['number']?user['twoFaAuth']['number']:user["phone"] && user["phone"]['number']?user["phone"]['number']:"")
        setselectedCodeFlag(user['twoFaAuth']&&user['twoFaAuth']['name']?user['twoFaAuth']['name']:user["phone"] && user["phone"]['name']?user["phone"]['name']:"")
        setisdCode(user['twoFaAuth']&&user['twoFaAuth']['dial_code']?user['twoFaAuth']['dial_code']:user["dial_code"] && user["phone"]['dial_code']?user["phone"]['dial_code']:"")
        setisdCountry(user['twoFaAuth'] ? _.omit(user['twoFaAuth'],['number']):user['phone'] ? _.omit(user['phone'],['number']):'')
      }
      return () => {
        
      }
    }, [])
    const handleChange = (e)=>{
        // _.map(_.filter(jsonData , function(d)=> return e ===d.name ))
        const d = _.filter(jsonData,function(obj) {return obj.name === e})
        
        setselectedCodeFlag(d[0]["name"])
        setisdCode(d[0]["dial_code"])
        setisdCountry(d[0])
        setdisabled(false)


    }
    const headerList = {
        "username":"Change Username",
        "email":"Change Email",
        "phone":"Change Phone Number",
        "password":"Change Password",
        "2fa":"Two Factor AUTHENTICATION",
      
      }
    
        const updateInput = (e) =>{
            if(e.target.value.trim() === ""){
                setuserName(user["userName"])
                setdisabled(true)
            }
            else if(e.target.value.trim() === user["userName"]){
                
                setdisabled(true)
            }
            else{
                // verifyURL(e.target.value.trim())
            }
        }
        const verifyURL = async (value) => {
            setisSaving(true);
            sethasError(null)
            const data = {
              url: value,
              email: user.email,
            };
            if (data.url.trim() !== "") {
              const verfied = await ApiService.PostApiCalls(
                "creator/setup/verifyURL",
                data,
                true
              );
              if (verfied[0] && !verfied[1]["IsSuccessful"]) {
                sethasError(verfied[1]["ErrorMessage"])
                setdisabled(true)
                
              } else {
                const saved  = await ApiService.PostApiCalls(
                    "creator/setup/update",
                    {userName:value},
                    true
                  );
                  if (saved[0] && !saved[1]["IsSuccessful"]) {
                   
                      notification["error"]({
                        message: saved[1]["ErrorMessage"],
                        description:""
                      });
                  }
                  else{
                      setAuthUser({...authUser,userName:value})
                      notification["success"]({
                        message: saved[1]["FriendlyMessage"],
                        description:""
                      });
                    
                  }
              }
              setisSaving(false);
            }
          };
        const changeInput = (e) =>{
            if(header === "email"){
                setEmail(e.target.value.trim())
                if(e.target.value.trim() === user["email"]){
                    setdisabled(true)
                }
                else{
                    setdisabled(false)
                }
            }
            else  if(header === "phone"){
                const phoneAdded = e.target.value.trim().replace(/[^\0-9]/ig, "")
                setphone(phoneAdded)
                if(user["phone"] && e.target.value.trim() === user["phone"]["number"]){
                    // setdisabled(true)
                }
                else{
                    setdisabled(false)
                }
            }
            else  if(header === "2fa"){
              const phoneAdded = e.target.value.trim().replace(/[^\0-9]/ig, "")
              setphone(phoneAdded)
              if(user["twoFaAuth"] && e.target.value.trim() === user["twoFaAuth"]["number"]){
                  setdisabled(true)
              }
              else{
                  setdisabled(false)
              }
          }
            else if(header === "username"){
                setuserName(e.target.value.trim())
            if(e.target.value.trim() === user["userName"]){
                setdisabled(true)
            }
            else{
                setdisabled(false)
            }
            }
           
            
        }
        
        const saveHandle = async()=>{
            if(header === "email"){
                if(!Utils.ValidateEmail(email)){
                    sethasError("Please provide valid email")
                }
                else{
                    setisSaving(true)
                    const saved  = await ApiService.PostApiCalls(
                        "creator/setup/update",
                        {email:email},
                        true
                      );
                      if (saved[0] && !saved[1]["IsSuccessful"]) {
                        notification["error"]({
                            message: saved[1]["ErrorMessage"],
                            description:""
                          });
                      }
                      else{
                        setAuthUser({...authUser,email:email})
                        notification["success"]({
                            message: saved[1]["FriendlyMessage"],
                            description:""
                          });
                        
                        //   
                      }
                      setisSaving(false)
                }
            }
            else if(header === "username"){
                setisSaving(true)
                verifyURL(userName)
            }
            else if(header === "phone"){
                
                const saved  = await ApiService.PostApiCalls(
                    "creator/setup/update",
                    {phone:{...isdCountry,number:phone}},
                    true
                  );
                  if (saved[0] && !saved[1]["IsSuccessful"]) {
                    notification["error"]({
                        message: saved[1]["ErrorMessage"],
                        description:""
                      });
                  }
                  else{
                    setAuthUser({...authUser,phone:{...isdCountry,number:phone}})
                    setselectedCodeFlag(isdCountry["name"])
                    notification["success"]({
                        message: saved[1]["FriendlyMessage"],
                        description:""
                      });
                    
                    //   
                  }
                  setisSaving(false)
            }
           
        }
        const onFinish = async(values) => {
            setisSaving(true);
            setapiError(null)
            const data = {currentpassword:passtoken?values.currentpassword:null,password:values.password}
            const saved = await ApiService.PostApiCalls( "creator/setup/update",{...data},true)
            
            if (saved[0] && !saved[1]["IsSuccessful"]) {
                setapiError(saved[1]["ErrorMessage"])
            }
            else if (saved[0] && saved[1]["IsSuccessful"]) {
                notification["success"]({
                    message: saved[1]["FriendlyMessage"],
                    description:""
                  });
                  form.resetFields();
            }
            else{
                setapiError('Something went wrong, please try again.')

            }
            

            setisSaving(false);

          };
          const on2FAFinish = async(values) => {
            setisSaving(true);
            setapiError(null)
            const data = {twoFaAuth:{...isdCountry,number:phone}}
            console.log(data);
            const saved = await ApiService.PostApiCalls( "creator/setup/update",{...data},true)
            
            if (saved[0] && !saved[1]["IsSuccessful"]) {
                setapiError(saved[1]["ErrorMessage"])
            }
            else if (saved[0] && saved[1]["IsSuccessful"]) {
                notification["success"]({
                    message: saved[1]["FriendlyMessage"],
                    description:""
                  });
                  form.resetFields();
            }
            else{
                setapiError('Something went wrong, please try again.')

            }
            

            setisSaving(false);

          };
          
          const onFinishFailed = (errorInfo) => {
            
          };
          const on2FaFinishFailed = (errorInfo) => {
            
          };
          const onChangeSMS = (checked) => {
            setshowSMSNumber(checked)
            
          };
  return (
    <><Col span={24} className="py-0 px-0 mb-2">
   {hideHeader?'': <div className="bg-white p-3 px-3 flex space-x-3 items-center">
    <Link className="text-hs-dark" to={"../account"}> <ArrowLeft size={22} /></Link>
    <span className="font-bold uppercase">{headerList[header]}</span>
    </div>}
  </Col>
  {header === "username" &&<Col span={24} className="px-0 h-100 bg-white">
      <div className="p-3 w-75">
          <Input status={hasError?"error":""} onFocus={()=>sethasError(null)} className="p-2 rounded-md mb-1" prefix="@" value={userName} onBlur={updateInput} onChange={changeInput}/>
          {hasError?<span className="text-xs p-2 opacity-60 text-danger">{"Username is not available."}</span>:<span className="text-xs p-2 opacity-60">{`https://hashsaga.com/${user["userName"]}`}</span>}
      </div>
      
      <Divider className="my-2 px-0" />
      <div className="px-6 w-100 text-right">
          <Button type="primary" size="large" onClick={saveHandle} loading={isSaving} disabled={disabled} className={disabled?"rounded-md bg-hs-pink border-white text-white opacity-50":"rounded-md bg-hs-pink border-white text-white"} >Save</Button>
          </div></Col>}
          {header === "email" &&<Col span={24} className="px-0 h-100 bg-white">
      <div className="p-3 w-75">
          <Input status={hasError?"error":""} onFocus={()=>sethasError(null)} className="p-2 rounded-md mb-1" value={email}  onChange={changeInput}/>
          {hasError?<span className="text-xs p-2 opacity-60 text-danger">{hasError}</span>:""}
      </div>
      
      <Divider className="my-2 px-0" />
      <div className="px-6 w-100 text-right">
          <Button type="primary" size="large" onClick={saveHandle} loading={isSaving} disabled={disabled} className={disabled?"rounded-md bg-hs-pink border-white text-white opacity-50":"rounded-md bg-hs-pink border-white text-white"} >Save</Button>
          </div></Col>}
          {header === "phone" &&<Col span={24} className="px-0 h-100 bg-white">
      <div className="p-3 w-75">
          <div className="flex items-center">
              <Select dropdownMatchSelectWidth={false}  optionLabelProp="label" showSearch style={{ width: 180 }} className="px-2 text-lg" defaultValue={[selectedCodeFlag]}  onChange={handleChange}>
              {
                  jsonData.map((d,i)=>
                  <Option className="hover:bg-white" key={i} label={<span>{d["flag"]} {d["dial_code"]}</span>} value={d["name"]}><div className=" rounded-md hover:bg-blue-100 p-2 flex space-x-2 items-center"><span className="text-lg">{d["flag"]}</span><span>{d["name"]} ({d["dial_code"]})</span></div></Option>
                  )
              }
          </Select>
          <Input status={hasError?"error":""}  maxLength={10} placeholder="Enter valid mobile number" onFocus={()=>sethasError(null)} className="p-2 rounded-md mb-0" value={phone}  onChange={changeInput}/>
          </div> {hasError?<span className="text-xs p-2 opacity-60 text-danger">{hasError}</span>:""}
      </div>
      
      <Divider className="my-2 px-0" />
      <div className="px-6 w-100 text-right">
          <Button type="primary" size="large" onClick={saveHandle} loading={isSaving} disabled={disabled} className={disabled?"rounded-md bg-hs-pink border-white text-white opacity-50":"rounded-md bg-hs-pink border-white text-white"} >Save</Button>
          </div></Col>}
          {header === "password" &&<Col span={24} className="px-0 h-100 bg-white">
            <Form name="basic" form={form}
      labelCol={{
        span: 0,
      }}
      wrapperCol={{
        span: 24,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off">
        {passtoken &&  <> <div className="p-4 w-75">
          <div className=" w-100">
             
          <Form.Item
        label=""
        name="currentpassword"
        rules={[
          {
            required: true,
            message: 'Current password field is required!',
          },
        ]}
      ><Input.Password  name="currentpassword" maxLength={10} placeholder="Current Password"  className="p-2 rounded-md mb-1" />
          </Form.Item>
          </div>
      </div>
      <Divider className="my-2 px-0" />
      </>}
      <div className="p-4 w-75">
          <div className=" w-100">
             
          <Form.Item
        label=""
        name="password"
        rules={[
          {
            required: true,
            message: 'New password field is required!',
          },{ min: 8, message: 'Password must have a minimum length of 8' },
          {
            pattern: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$'),
            message: 'Password must contain at least one lowercase letter, uppercase letter, number, and special character'
        }
        ]}
      ><Input.Password  name="password" maxLength={10} placeholder="New Password" className="p-2 rounded-md mb-1" />
          </Form.Item>
          <div className="py-2"></div>
          <Form.Item
        label=""
        name="password1"
        rules={[
          {
            required: true,
            message: 'Confirm password field is required!',
          },({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Passwords do not match.'));
            },
          }),
        ]}
      ><Input.Password  name="password1" maxLength={10} placeholder="Confirm New Password" className="p-2 rounded-md mb-1" />
          </Form.Item>
          </div>
      </div>
     
      
      <Divider className="my-2 px-0" />
      <div className="px-6 w-100 flex justify-between items-center">
         <span className="text-danger font-semibold" >{apiError}</span>
          <Button type="primary" htmlType="submit" size="large"  loading={isSaving}  className={"rounded-md bg-hs-pink border-white text-white"} >Save</Button>
          </div>
          </Form></Col>}
          {header === "2fa" &&<Col span={24} className="px-0 h-100 bg-white">
            <Form name="basic" form={form}
      labelCol={{
        span: 0,
      }}
      wrapperCol={{
        span: 24,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={on2FAFinish}
      onFinishFailed={on2FaFinishFailed}
      autoComplete="off">
        <> <div className="p-4 pb-1 w-75">
          <div className=" w-100 flex flex-row items-center justify-between">
          <span>Verification via SMS</span>
          <Form.Item
        label=""
        name="currentpassword"
        className="mb-0"
        rules={[
          {
            required: true,
            message: 'Current password field is required!',
          },
        ]}
      >
        <Switch defaultValue={showSMSNumber} checked={showSMSNumber} onChange={onChangeSMS} />
          </Form.Item>
          </div>
      </div>
      <Divider className="my-0 px-0" />
      </>
     {showSMSNumber &&  <><div className="p-4 w-75">
          <div className=" w-100">
             
         
          <div className="flex items-center">
              <Select dropdownMatchSelectWidth={false}  optionLabelProp="label" showSearch style={{ width: 180 }} className="px-2 text-lg" defaultValue={[selectedCodeFlag]}  onChange={handleChange}>
              {
                  jsonData.map((d,i)=>
                  <Option className="hover:bg-white" key={i} label={<span>{d["flag"]} {d["dial_code"]}</span>} value={d["name"]}><div className=" rounded-md hover:bg-blue-100 p-2 flex space-x-2 items-center"><span className="text-lg">{d["flag"]}</span><span>{d["name"]} ({d["dial_code"]})</span></div></Option>
                  )
              }
          </Select>
          <Input status={hasError?"error":""}  maxLength={10} placeholder="Enter valid mobile number" onFocus={()=>sethasError(null)} className="p-2 rounded-md mb-0" value={phone}  onChange={changeInput}/>
          </div>
          
          </div>
      </div>
       <Divider className="my-2 px-0" />
       <div className="px-6 w-100 flex justify-between items-center">
          <span className="text-danger font-semibold" >{apiError}</span>
           <Button type="primary" htmlType="submit" size="large"  loading={isSaving}  className={"rounded-md bg-hs-pink border-white text-white"} >Save</Button>
           </div></>}
     
      
     
          </Form></Col>}
    </>
  )
}

export default UserAccountInfo