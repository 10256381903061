import React,{useEffect,useState} from "react";
import { Link } from "react-router-dom";
import Utils from "helper/Utils";
const SideTags = ({ tag }) => {
  const [first, setfirst] = useState();
  const [ctStyle, setctStyle] = useState("pb-1 ")
  const [bgStyle, setbgStyle] = useState({marginBottom:20})
  useEffect(() => {
    
      const count = tag.currentValue?tag.currentValue["postCount"]: Math.floor(Math.random() * 50000) + 1;
      setfirst(Utils.numberFormatter(count,1))
      if(count > 50 && count < 100){
          setctStyle("pb-1 text-rbRed ")
      }
      else if(count >= 100 && count < 150){
        setctStyle("pb-1 text-rbOrange ")

          // setctStyle({fontFamily:textstyles.tfsemibold,fontSize:textstyles.ts3,color:colors.rbOrange})
      }
      else if(count >= 150 && count < 200){
        setctStyle("pb-1 text-rbYellow ")

          // setctStyle({fontFamily:textstyles.tfsemibold,fontSize:textstyles.ts3,color:colors.rbYellow})
          // setbgStyle({marginBottom:20,backgroundColor:colors.shadow})
      }
      else if(count > 200 && count < 250){
        setctStyle("pb-1 text-rbGreen ")

          // setctStyle({fontFamily:textstyles.tfsemibold,fontSize:textstyles.ts3,color:colors.rbGreen})
      }
      else if(count > 250 && count < 300){
        setctStyle("pb-1 text-rbBlue ")

          // setctStyle({fontFamily:textstyles.tfsemibold,fontSize:textstyles.ts3,color:colors.rbBlue})
      }
      else if(count > 300 && count < 350){
        setctStyle("pb-1 text-rbIndigo ")

          // setctStyle({fontFamily:textstyles.tfsemibold,fontSize:textstyles.ts3,color:colors.rbIndigo})
      }
      else if(count > 350 ){
        setctStyle("pb-1 text-rbViolet ")

          // setctStyle({fontFamily:textstyles.tfsemibold,fontSize:textstyles.ts3,color:colors.rbViolet})
      }
  
    return () => {
      
    }
  }, [])
  return (
    <div className="text-h font-semibold cursor-pointer flex flex-col mb-2">
      <Link to={`/search/posts?q=${(tag.title).toLowerCase()}`}><span className={ctStyle}>#{tag.title}</span></Link>
      <span className="text-xs opacity-60 font-normal ">{first} posts</span>
    </div>
  );
};

export default SideTags;
