import { Button, List, Tag } from 'antd';
import Utils from 'helper/Utils';
import React, { useEffect, useState } from 'react'
import ApiService from 'services/ApiService';

const BorkerActivities = ({alreadyBroker}) => {
    console.log(alreadyBroker);
    const [list, setlist] = useState([])
    useEffect(() => {
      
        getList()
    
      return () => {
        
      }
    }, [])
    const getList = async()=>{
        const result = await ApiService.PostApiCalls("steert/broker/activities",{id:alreadyBroker.userId},true)
        if(result[0]){
            setlist(result[1]["data"])
            // setloading(false)
          }
    }
    const showModal = ()=>{

    }
  return (
    <div className="px-4 pb-4 mt-2 ">
    <List
  itemLayout="vertical"
  size="large"
  pagination={{
    onChange: (page) => {
      console.log(page);
    },
    pageSize: 20,
  }}
  dataSource={list}
  footer={
    <div>
      More available
    </div>
  }
  renderItem={(item) => (
    <List.Item
      key={item.ListingDetail.title}
      className='bg-white rounded-md mb-3 p-3'
     
    >
     <div className='flex space-x-3 flex-row w-100'>
     <img
          width={272}
          alt="logo" className='rounded-lg'
          src={item.ListingDetail["listingImages"][0]}
        />
        <div className='flex flex-col space-y-1 flex-1'>
        <span className='font-semibold text-lg'>₹{Utils.getAmountInFormat(parseInt(item.ListingDetail.price))} </span>
        <span><span className='font-semibold'>{`${item.ListingDetail.BHK}`}</span>{` for ${(item.ListingDetail.listingFor).toLowerCase()} in ${item.ListingDetail.Locality}`}</span>
         <span>By - {item["Owner"][0].name}</span>
      
          <div className='flex items-end flex-1 justify-between'>
            <span className='flex flex-row space-x-1'>
          
            </span>
            
            <Button onClick={()=>showModal(item)} className={`px-3 brd-4 fw-700 mx-2 rounded-lg text-white bg-hs-pink  hover:border-hs-pink `}>View Requests</Button>
          </div>
          </div>
     </div>
       
     
    </List.Item>
     )}
     />
     </div>
  )
}

export default BorkerActivities