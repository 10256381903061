import React, { Component } from 'react'
import { Upload as AntUpload, } from 'antd';
import {startUpload,UploadSingle} from "services/FileUpload"
function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
export default class ImageUpload extends Component {
    constructor(props) {
      super(props)
        
      this.state = {
        show:false,
        previewVisible: false,
        save: false,
        previewImage: '',
        previewTitle: '',
        fileList:this.props.list,
        imageList:this.props.list,
        uploadedCount:0,
        isUploading:false,
        progressCount: 0,
        imgPrifix:this.props.cid?this.props.campaign.cid:this.props.prefix
      }
    }
    componentDidUpdate(prevProps, prevState){
        if(prevProps.list.length !== this.props.list.length){
            this.setState({
                fileList:this.props.list,
                imageList:this.props.list,
            })
        }
       
    }
    handlePreview = async file => {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj);
        }
    
        this.setState({
          previewImage: file.url || file.preview,
          previewVisible: true,
          previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        });
      };
     updateProgressBar = (val,id,uid) =>{
        const {fileList} = this.state;
        let newList = [];
        for(let i=0; i<fileList.length; i++){
            let tmp = fileList[i];
            if(tmp.uid === uid){
                tmp["percent"] = val;
            }
            newList.push(tmp);
        }
        this.setState({fileList: newList})
    }
     UploadFinish = (value,name,type,cid,uid) =>{ 
         const {uploadedCount} = this.state;
        this.setState({uploadedCount:uploadedCount+1})
        let imagepath = "https://hs-container.s3.ap-south-1.amazonaws.com/" + name
        const {fileList} = this.state;
        let newList = [];
        for(let i=0; i<fileList.length; i++){
            let tmp = fileList[i];
            if(tmp.uid === uid){
                tmp["status"] = "done";
                tmp["url"] = imagepath;
            }
            newList.push(tmp);
        }
        this.setState({fileList: newList})
        this.VerifyAll()
    }
    VerifyAll = () =>{
        const {progressCount,uploadedCount,fileList} = this.state;
        if(progressCount === uploadedCount){
            this.setState({uploadedCount:0,progressCount:0,save:false, isUploading:false})
            if(this.props.reset){
              this.setState({fileList:[]})
            }
        }
        this.props.callback(fileList)
    }
     UploadFail = () =>{

    }
     customUploadRequest = (e) =>{
        const UploadState = startUpload(e.file.size);
        const {progressCount,imgPrifix} = this.state;
        if(UploadState === "single")    
        {        
          this.setState({
            isUploading:true,
            save:true,
            progressCount:progressCount+1,
          })
          UploadSingle(e,this.updateProgressBar,this.UploadFinish,this.UploadFail,progressCount,"","hashapp/campaign-images/"+imgPrifix+"/images")
          .then(res => {          
           
          })
          .catch(error => {            
          });
         
        }
    }
    handleCancel = () => this.setState({ previewVisible: false });

    handleChange = ({ fileList }) => this.setState({ fileList });

    saveImages = () =>{
        const {fileList} = this.state;
        this.props.callbackImages(fileList);
        this.setState({show:false,imageList:fileList});
    }

  render() {
     
     const {previewVisible,save,imageList,isUploading, previewImage, fileList, previewTitle,show} = this.state;
    return (
      <>
        <AntUpload
          multiple={this.props.multipleImages?this.props.multipleImages:true}
          listType={this.props.listType}
          fileList={fileList}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
          customRequest={this.customUploadRequest}
          disabled={isUploading?true:false}
          accept="image/*"
          showUploadList={this.props.showUploadList}
        >
         {this.props.limit && fileList.length < this.props.limit && <div>
         {isUploading && this.props.viewUploading}
         {!isUploading && this.props.view}
        
      </div>}
        </AntUpload>
      </>
    )
  }
}
