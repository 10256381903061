
import { Button, Statistic,Modal,notification } from "antd";
import Utils from "helper/Utils";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { ArrowDown, ArrowDownShort, ArrowUp, Clock, ClockFill, Coin } from "react-bootstrap-icons";
import ApiService from "services/ApiService";
import { Link } from "react-router-dom";
import { AddHash } from "container/creator/creator/AddHash";
import { HashtagSaleModal } from "./HashtagSaleModal";
import HashIcon from "components/HashIcon";
const { confirm } = Modal;
const HashtagsSales = (props) => {
  const [processOn, setprocessOn] = useState(false);
  const [userTokens, setuserTokens] = useState(0);
  const [userTokensOg, setuserTokensOg] = useState(0);
  const [showModal, setshowModal] = useState(false)
  const [modalEvent, setmodalEvent] = useState({event:"mint",item:null})
  const [type, settype] = useState(props.type)
  const [item, setitem] = useState(props.hash);
  useEffect(() => {
    
  
    return () => {
      
    }
  }, [item,type])
  const getRndInteger = (min, max)=> {
    return Math.floor(Math.random() * (max - min)) + min;
  }
  const randomNumber = getRndInteger(-15,20)
  const CancelListing = async(id)=>{
    confirm({
      title: `Do you want to cancel ${item.listingType} request?`,
      icon: <ExclamationCircleOutlined />,
      content: "",
      async onOk() {
        const data = await ApiService.PostApiCalls("keyhash/listing/cancel",{hashId:props.hash.hashId},true)
        if(data[0] && data[1]["IsSuccessful"]){
          setitem(data[1]["hash"])
          notification["success"]({
            message: Utils.capitalizeFirstLetter(item.listingType+' request has been cancelled.'),
          });
        }
        else{
          notification["error"]({
            message: 'Request failed!',
          });
        }
        
      },
      onCancel() {},
    });
  }
  const cancelSale = async (id)=>{
    
    confirm({
      title: 'Do you want to cancel this hash sale?',
      icon: <ExclamationCircleOutlined />,
      content: "",
      async onOk() {
        const data = await ApiService.PostApiCalls("keyhash/sales/cancel",{hashId:props.hash.hashId},true)
        if(data[0] && data[1]["IsSuccessful"]){
          setitem(data[1]["hash"])
          notification["success"]({
            message: 'Sale has been cancelled.',
          });
        }
        else{
          notification["error"]({
            message: 'Request failed!',
          });
        }
        
      },
      onCancel() {},
    });
  }
  
  const cancelCalled = (hash=null)=>{
    setshowModal(false);
    if(hash){
      setitem(hash)
    }
  }
  // console.log(item.listingStatus);
  return (
    <>
      {item && <div className="flex flex-1 justify-between">
          <div>
      <span className="flex space-x-3 items-center pn-2"><h6 className="mb-0">{`#${props.hash.title.replace(/ /g, "")}`}</h6></span>
            <span className="text-xs text-muted px-1">
              {item.minted
                ? `Minted on ${moment
                    .unix(props.hash.updatedAt)
                    .format("MMM DD, YYYY ")}`
                : `Added on ${moment
                    .unix(props.hash.createdAt)
                    .format("MMM DD, YYYY ")}`}
            </span>
            
            <div className="py-2"></div>
            {
                item.listingStatus === "completed" && item["SalesOffer"].length > 0 && <> 
                {item.listingType === "rent" ?
                <div className="flex flex-col space-y-2 py-2 items-start">
                
                <span className="flex flex-row space-x-3 items-center">
                <span className="font-semibold">Rent : </span>
                <span className="flex items-center space-x-2">
                  <HashIcon />
                  <span>{item["SalesOffer"][0]["offerOptions"]["price"]}</span>
                </span>
              </span>
              <span className="flex flex-row space-x-3 items-center">
                <span className="font-semibold">Offer By: </span>
                <span>
                  <a
                    target={"_blank"}
                    href={"/" + item.author[0]["userName"]}
                  >
                    {item.author[0]["name"]}
                  </a> <span className="text-xs">on {moment
                    .unix(item["SalesOffer"][0].updatedAt)
                    .format("MMM DD, YYYY ")}</span>
                </span>
              </span>
              <span className="flex flex-row space-x-3 items-center">
                <span className="font-semibold">Rent type: </span>
                <span className="flex items-center space-x-2">
                  
                  <span>Per {item["SalesOffer"][0]["offerOptions"]["rentOption"]}</span>
                </span>
              </span>
              <span className="flex flex-row space-x-3 items-center">
                <span className="font-semibold">Requested till: </span>
                <span className="text-xs">{moment
                    .unix(item["SalesOffer"][0]["listingEnddate"])
                    .format("MMM DD, YYYY ")}</span>
              </span>
                
                </div>
                :item.listingType === "mention" ?<div className="flex flex-col space-y-2 py-2 items-start">
                
                <span className="flex flex-row space-x-3 items-center">
                <span className="font-semibold">Mention Handle: </span>
                <span className="flex items-center space-x-2">
                
                  <span>{item["SalesOffer"][0]["offerOptions"]["mentionHandle"]}</span>
                </span>
              </span>
              <span className="flex flex-row space-x-3 items-center">
                <span className="font-semibold">Offer By: </span>
                <span>
                  <a
                    target={"_blank"}
                    href={"/" + item.author[0]["userName"]}
                  >
                    {item.author[0]["name"]}
                  </a> <span className="text-xs">on {moment
                    .unix(item["SalesOffer"][0].updatedAt)
                    .format("MMM DD, YYYY ")}</span>
                </span>
              </span>
              <span className="flex flex-row space-x-3 items-center">
                <span className="font-semibold">Requested till...: </span>
                <span className="text-xs">{moment
                    .unix(item["SalesOffer"][0]["offerOptions"]["endDate"])
                    .format("MMM DD, YYYY ")}</span>
              </span>
                
                </div> : <div className="flex flex-col space-y-2 py-2 items-start">
                
                <span className="flex flex-row space-x-3 items-center">
                <span className="font-semibold">Price : </span>
                <span className="flex items-center space-x-2">
                <HashIcon />
                  <span>{item["SalesOffer"][0]["offerOptions"]["price"]}</span>
                </span>
              </span>
              <span className="flex flex-row space-x-3 items-center">
                <span className="font-semibold">Sold to: </span>
                <span>
                  <a
                    target={"_blank"}
                    href={"/" + item.author[0]["userName"]}
                  >
                    {item.author[0]["name"]}
                  </a> <span className="text-xs">on {moment
                    .unix(item["SalesOffer"][0].updatedAt)
                    .format("MMM DD, YYYY ")}</span>
                </span>
              </span>
             
                
                </div>}
                </>
                
            }
            {
                item.listingStatus === "open" && item["SalesOffer"].length === 0 && <> <div className="flex flex-row space-x-1 py-2 items-center">
                <Clock /> 
                <span className="font-semibold p-2 bg-gray-100 normal-case">{`${Utils.capitalizeFirstLetter(item.listingType)} request is live.`}</span>
                <Button
                      onClick={CancelListing}
                      className="px-3 brd-4 fw-700  rounded-lg hover:text-hs-pink hover:border-hs-pink"
                    >
                      Cancel Request
                    </Button>
                </div></>
                
            }
            {
                item.listingStatus === "open" && item["SalesOffer"].length > 0 &&  <> <div className="flex flex-col py-2 px-2 items-start">
                
                {item.options["type"] === "1"?<span className="font-semibold py-3 px-0 bg-gray-100">One offer has been received.</span>
                :<span className="font-semibold py-3 px-0 bg-gray-100">{`${item["SalesOffer"].length} bids have been received.`}</span>}
                <Button
                      onClick={()=>{setmodalEvent({event:"sale",item:item});setshowModal(true)}}
                      className="px-3 brd-4 fw-700  rounded-lg text-white bg-hs-pink  hover:border-hs-pink"
                    >
                      View & Confirm Offer
                    </Button>
                </div></>
                
            }
           
             {
                item.listingStatus === "pending" && <> <div className="flex flex-col py-2 px-2 items-start">
                
                {item.options["type"] === "1"?<span className="font-semibold py-3 px-0 bg-gray-100">One offer has been received.</span>
                :<span className="font-semibold py-3 px-0 bg-gray-100">{`${item["SalesOffer"].length} bids have been received.`}</span>}
                <Button
                      onClick={()=>{setmodalEvent({event:"sale",item:item});setshowModal(true)}}
                      className="px-3 brd-4 fw-700  rounded-lg text-white bg-hs-pink  hover:border-hs-pink"
                    >
                      View & Confirm Offer
                    </Button>
                </div></>
                
            }
            </div>
            <span className="rounded-md overflow-hidden mx-2">
            <img src={`https://hs-container.s3.ap-south-1.amazonaws.com/app-public/hashkeys/frame/${item["mintedBy"]}/${item["hashId"]}.png`} className="h-28" />
            </span>
          </div>}
      {!item && <div></div>}
      {showModal && (
        <HashtagSaleModal
          event={modalEvent}
          user={props.user}
          showModalFirst={showModal}
          callback={setshowModal}
          cancelCallBack={cancelCalled}
        />
      )}
    </>
  );
};

export default HashtagsSales;
