import React from 'react'

const Regenerate = (props) => {
    return (
        <svg
      xmlns="http://www.w3.org/2000/svg"
      width={13.784}
      height={18.226}
      viewBox="0 0 13.784 18.226"
      {...props}
    >
      <defs>
        <style>
          {".prefix__br{fill:#1694e3;stroke:#1694e3;stroke-width:.25px}"}
        </style>
      </defs>
      <path
        d="M11.588 1.45H8.739a2.019 2.019 0 00-3.689 0H2.197A1.949 1.949 0 00.25 3.4v12.629a1.949 1.949 0 001.947 1.947h9.391a1.949 1.949 0 001.947-1.947V3.4a1.949 1.949 0 00-1.947-1.95zm-6.222.89a.443.443 0 00.433-.348 1.126 1.126 0 012.187 0 .443.443 0 00.433.348h.879v.389a1.062 1.062 0 01-1.06 1.06H5.55a1.062 1.062 0 01-1.06-1.06V2.34zm7.284 13.689a1.061 1.061 0 01-1.06 1.06H2.197a1.062 1.062 0 01-1.06-1.06V3.4a1.062 1.062 0 011.06-1.06h1.4v.389A1.949 1.949 0 005.55 4.675h2.687a1.949 1.949 0 001.946-1.946V2.34h1.4A1.062 1.062 0 0112.65 3.4zm0 0"
        stroke="#2f80fa"
        strokeWidth={0.5}
        fill="#1694e3"
      />
      <path
        className="prefix__br"
        d="M9.825 6.15a.3.3 0 00-.3.3v1.073a3.829 3.829 0 00-6.492 2.758.3.3 0 10.59 0A3.246 3.246 0 019.271 8.09l-1.307.436a.3.3 0 10.186.561l1.771-.59a.3.3 0 00.2-.281V6.45a.3.3 0 00-.296-.3zM10.415 9.986a.3.3 0 00-.3.3 3.246 3.246 0 01-5.64 2.191l1.307-.436a.3.3 0 10-.186-.561l-1.771.59a.3.3 0 00-.2.281v1.768a.3.3 0 10.59 0v-1.08a3.829 3.829 0 006.492-2.758.3.3 0 00-.292-.295z"
      />
    </svg>
    )
}

export default Regenerate
