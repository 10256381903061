import Utils from "helper/Utils";
import React,{useEffect, useState,useContext} from "react";
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from "react-router-dom";
import ApiService from "services/ApiService";
import { AuthRoutes, AppRoutes,CommenRoutes } from "./HomeRoutes";
import NotFound from "./NotFound";
import withTracker from "./withTracker";
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';
import { AuthContext } from "./AuthProvider";
import { PushSpinner,SpiralSpinner,GuardSpinner } from "react-spinners-kit";
import { Container } from "react-bootstrap";
import { Col, Row } from "antd";
import ViewLoader from "container/common/ViewLoader";
export default function App(props) {
  const {authUser,setAuthUser,setactiveWorkSpace,setsetupCompleted} = useContext(AuthContext)
  let session = Utils.getCookie("sessionhash");
  const [verfying, setverfying] = useState(true)
  const [loading, setloading] = useState(true);
  const [showDisplay, setshowDisplay] = useState(false)
  
  useEffect(() => {
   console.log(window.location.pathname);
    const tracker = Utils.getCookie("personalization_id");
    
    if(!tracker){
      const id = Utils.randomString(32);
      Utils.setCookie("personalization_id",id)
    }
    if(window.location.pathname === "/beta/display"){
      
      setshowDisplay(true)
    }
    else if(window.location.pathname.includes("/re/")){
      setshowDisplay(false)
      setverfying(true)
      verifyAuthBroker()
    }
    else{
      setshowDisplay(false)
      setverfying(true)
      verifyAuth()
    }
   
    return () => {
      
    }
  }, [authUser])

  const verifyAuth = async()=>{
   
    if(!authUser){
      
    const v = await ApiService.PostApiCalls("account/veriyAuth",{token:session},true);
   
    if(v[0] && v[1]["IsSuccessful"]){
      const currentUser = v[1]["user"];
      
      setAuthUser(v[1]["user"])
      if(v[1]["user"] && v[1]["user"]["workspace"])
      {
      const activeWorkSpace = v[1]["user"]["workspace"][0]?v[1]["user"]["workspace"][0]:null
      setactiveWorkSpace(v[1]["user"]["workspace"][0]?v[1]["user"]["workspace"][0]:null)
      if (currentUser["setupFinished"] && currentUser["posts"].length > 0) {
        setsetupCompleted({
          socialProfile: true,
          rssFeed: true,
          firstPost: true,
        });
        
      } else if (activeWorkSpace && activeWorkSpace["autoFetch"] === undefined) {
          setsetupCompleted({
            socialProfile: true,
            rssFeed: false,
            firstPost: currentUser["posts"].length > 0?true:false,
          });
        }
      else if (activeWorkSpace && activeWorkSpace["autoFetch"]) {
        setsetupCompleted({
          socialProfile: true,
          rssFeed: true,
          firstPost: currentUser["posts"].length > 0?true:false,
        });
      }
      else{
        setsetupCompleted({
          socialProfile: false,
          rssFeed: false,
          firstPost: false,
        });
      }
      }
      setverfying(false)
      
     
    }
    else if(v[0] && !v[1]["IsSuccessful"]){
      session = false;
    Utils.processAuthOut()

      setverfying(false)

    }
    else{
      Utils.processAuthOut()

      session = false;
      setverfying(false)
    }
  }
  setverfying(false)
  }
  const verifyAuthBroker = async()=>{
    if(!authUser){
    const v = await ApiService.PostApiCalls("re/veriyAuth",{token:session},true);
    if(v[0] && v[1]["IsSuccessful"]){
      const currentUser = v[1]["user"];
      
      setAuthUser(v[1]["user"])

    }
    else{
      Utils.processAuthOut()

    }
    setverfying(false)

  }
    setverfying(false)

  }
  if(showDisplay){
    return (
      <Router basename={"/beta"}>
        <Routes>
        <Route exact path="/linkedin" component={LinkedInPopUp} />
        
          {
            AppRoutes.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  element={<route.layout {...props}>
                  <route.component
                    {...props}
                    mode={route.mode}
                    hidenav={route.hidenav}
                    hideExtraNav={route.hideExtraNav}
                    sidebarKey={route.sidebarKey}
                    isInit={route.isInit}
                    isHome={route.isHome}
                    hideSideBar={route.hideSideBar}
                    isVideo={route.isVideo}
                    isProfile={route.isProfile}
                    {...route.props}
                  />
                </route.layout>}
                />
              );
            })}
            
          {/* <Route path="*" component={Signup} /> */}
          {/* <Route  component={<NoMatch />} /> */}
          <Route element={NotFound} />
        </Routes>
      </Router>
    );
  }
  if(verfying){
    return (
      <ViewLoader />
    )
   }
  return (
    <Router basename={"/"}>
      <Routes>
      {/* <Route exact path="/linkedin" component={LinkedInPopUp} /> */}
      
      {
            CommenRoutes.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  element={<route.layout {...props}>
                  <route.component
                    {...props}
                    mode={route.mode}
                    hidenav={route.hidenav}
                    hideExtraNav={route.hideExtraNav}
                    sidebarKey={route.sidebarKey}
                    isInit={route.isInit}
                    isHome={route.isHome}
                    hideSideBar={route.hideSideBar}
                    isVideo={route.isVideo}
                    isProfile={route.isProfile}
                    {...route.props}
                  />
                </route.layout>}
                />
              );
            })}
        {!authUser &&
          AuthRoutes.map((route, index) =>  (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                element={<route.layout {...props}>
                <route.component
                  {...props}
                  mode={route.mode}
                  hideExtraNav={route.hideExtraNav}
                  isInit={route.isInit}
                  isHome={route.isHome}
                  {...route.props}
                />
              </route.layout>}
              />
            ))}
          
        {authUser && 
          AppRoutes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                element={<route.layout {...props}>
                <route.component
                  {...props}
                  mode={route.mode}
                  hidenav={route.hidenav}
                  hideExtraNav={route.hideExtraNav}
                  sidebarKey={route.sidebarKey}
                  isInit={route.isInit}
                  isHome={route.isHome}
                  hideSideBar={route.hideSideBar}
                  isVideo={route.isVideo}
                  isProfile={route.isProfile}
                  {...route.props}
                />
              </route.layout>}
              />
            );
          }
          )
          
          }
          
        {/* <Route path="*" component={Signup} /> */}
        {/* <Route  component={<NoMatch />} /> */}
        {/* <Route element={NotFound} /> */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
}
