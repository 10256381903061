import React from 'react'
import {ChevronLeft,ChevronRight} from "react-bootstrap-icons";
import {Container,Row,Col,ButtonGroup } from "react-bootstrap";
import { Button } from 'antd';
export default class CalendarToolbar extends React.Component {

    constructor(props) {
      super(props)
    
      this.state = {
         todayDisabled:true
      }
    }
    clickAction = (source) =>{
      this.setState({todayDisabled:source === "TODAY"?true:false})
      this.props.onNavigate(source)
    }
    render() {
    const {todayDisabled} = this.state;
     
      return (
        <Container>
         <Row className="mx-0 mt-2 pb-4">
             <Col className="px-0">
            <div className="month-lable">
                {this.props.label}
            </div>
            </Col>
            <Col className="px-0 text-right">
            <ButtonGroup>
              <Button className="calender-tool-pre-btn" variant="light" onClick={() => this.clickAction('PREV')}><ChevronLeft></ChevronLeft></Button>
              <Button disabled={todayDisabled} className="calender-tool-today-btn" variant="light" onClick={() => this.clickAction('TODAY')}>Today</Button>
              <Button className="calender-tool-next-btn" variant="light" onClick={() => this.clickAction('NEXT')}><ChevronRight></ChevronRight></Button>
            </ButtonGroup>

            </Col>
            
          
         </Row>   
        </Container>
        
      );
    }
  }