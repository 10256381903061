import { Tooltip } from 'antd';
import { AuthContext } from 'app/AuthProvider';
import Utils from 'helper/Utils';
import React from 'react'
import { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Heart, ChatRight, Cursor, HeartFill, Eye, Bookmark, BookmarkFill } from "react-bootstrap-icons";
import { BsReply } from "react-icons/bs";
const CardFooter = ({id,stats,callback}) => {
  const {authUser} = useContext(AuthContext)
    const [liked, setliked] = useState(stats["userLiked"][0]?true:false)
    const [bookmarked, setbookmarked] = useState(stats["userMarked"] && stats["userMarked"][0]?true:false)
    const [post, setpost] = useState(stats)
    useEffect(() => {
      
      // useState(stats["userLiked"][0]?true:false)
      return () => {
        
      }
    }, [liked,post])
    const getRndInteger = (min, max)=> {
      return Math.floor(Math.random() * (max - min)) + min;
    }
    const viewCount = stats["viewCount"]+stats["socialViewCount"]["facebook"]+stats["socialViewCount"]["twitter"]+stats["socialViewCount"]["instagram"]+stats["socialViewCount"]["linkedin"];;
    const addReaction = ()=>{
      callback("like",liked?0:1,"")
      setliked(!liked)
    }
    const addBookmark = ()=>{
      callback("bookmark",bookmarked?0:1,"")
      setbookmarked(!bookmarked)
    }
    const updateCommentCount = (post)=>{
      setpost(post)
    }
    
      const fillWithRandom = (max, total, len = 4) => {
        let arr = new Array(len);
        let sum = 0;
        do {
          for (let i = 0; i < len; i++) {
              arr[i] = Math.random();
          }
          sum = arr.reduce((acc, val) => acc + val, 0);
          const scale = (total - len) / sum;
          arr = arr.map(val => Math.min(max, Math.round(val * scale) + 1));
          sum = arr.reduce((acc, val) => acc + val, 0);
        } while (sum - total);
        return arr;
    };
    // console.log(fillWithRandom(4, viewCount));
    const showSocialStats = ()=>{
      // const stats = fillWithRandom(4,viewCount)
      return(
        <div className='flex flex-col space-y-1  px-1'>
          <span className='flex justify-between space-x-3'>
            <span>BluMe</span>
            <span>{Utils.numberFormatter(stats["viewCount"],1)}</span>
          </span>
          <span className='flex justify-between space-x-3'>
            <span>Facebook</span>
            <span>{Utils.numberFormatter(stats["socialViewCount"]["facebook"],1)}</span>
          </span>
          <span className='flex justify-between  space-x-3'>
            <span>Twitter</span>
            <span>{Utils.numberFormatter(stats["socialViewCount"]["twitter"],1)}</span>
          </span>
          <span className='flex justify-between space-x-3'>
            <span>Instagram</span>
            <span>{Utils.numberFormatter(stats["socialViewCount"]["instagram"],1)}</span>
          </span>
          <span className='flex justify-between space-x-3'>
            <span>Linkedin</span>
            <span>{Utils.numberFormatter(stats["socialViewCount"]["linkedin"],1)}</span>
          </span>
          
        </div>
      )
    }
    return (
       
        <div footeraction className="flex flex-row px-2">
          {authUser && <><Tooltip title={liked?"Unlike":"Like"} overlayClassName="rounded" overlayInnerStyle={{borderRadius: "0.25rem",fontSize: "0.75rem"}}>
        <div like className="flex flex-row items-center mr-3 cursor-pointer" onClick={addReaction}>
          {!liked ? <Heart />:<HeartFill color={"#F6327D"} />}
          

          <span className="pl-2 text-hb">{stats["likeCount"]}</span>
        </div></Tooltip>
        <div like className="flex flex-row items-center mr-3 cursor-pointer" onClick={()=>callback("comment","","")}>
          <ChatRight />
          <span className="pl-2 text-hb">{stats["commentCount"]}</span>
        </div>
        <Tooltip title={bookmarked?"Remove from bookmarks":"Add to bookmarks"} overlayClassName="rounded" overlayInnerStyle={{borderRadius: "0.25rem",fontSize: "0.75rem"}}>
        <div like className="flex flex-row items-center mr-3 cursor-pointer" onClick={addBookmark}>
          {!bookmarked ? <Bookmark />:<BookmarkFill color={"#0079FF"} />}
          

          
        </div></Tooltip></>}
        {/* <div like className="flex flex-row items-center mr-3 cursor-pointer">
          <BsReply size={20} />
        </div>
        <div like className="flex flex-row items-center mr-3 cursor-pointer">
          <Cursor size={20} />
        </div> */}
       {stats["profileType"] === "user" && <Tooltip placement="topRight"  title={showSocialStats} overlayClassName="rounded" overlayInnerStyle={{borderRadius: "0.25rem",fontSize: "0.75rem"}}>
        <div className="flex flex-grow items-center justify-end cursor-pointer">
        <Eye />
          <span className="pl-2 text-hb">{Utils.numberFormatter(viewCount,1)}</span>
          {/* <span className="pl-2 text-hb">{stats["viewCount"]}</span> */}
        </div>
        </Tooltip>}
        
      </div>
    )
}

export default CardFooter
