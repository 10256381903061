
import { Button, Statistic, Modal, notification, Tooltip, message } from "antd";
import Utils from "helper/Utils";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import {
  ArrowDown,
  ArrowDownShort,
  ArrowUp,
  Clock,
  ClockFill,
  Coin,
} from "react-bootstrap-icons";
import ApiService from "services/ApiService";
import { Link } from "react-router-dom";
import MentionRequestModal from "../mention/MentionRequestModal";
import HashIcon from "components/HashIcon";
import { HASH_DEFAULT_GASFEE } from "constants/hashkeys.constant";


const { confirm } = Modal;
const HashtagFraction = (props) => {
  const [processOn, setprocessOn] = useState(false);
  
  
  const [showModal, setshowModal] = useState(false);
  const [modalEvent, setmodalEvent] = useState({ event: "mint", item: null });
  const [type, settype] = useState(props.type);
  const [item, setitem] = useState(props.hash);
  
  useEffect(() => {
    
    return () => {};
  }, [item, type]);
  const getRndInteger = (min, max) => {
    return Math.floor(Math.random() * (max - min)) + min;
  };
  const randomNumber = getRndInteger(-15, 20);
  

  const buyHash = () => {
    setmodalEvent({ event: "auction", item: item });
    setshowModal(true);
  };
  const cancelCalled = (hash = null,state=false) => {
    setshowModal(false);
    if (hash) {
      setitem(hash);
    }
  };
  const copyLink =(text) =>{
    navigator.clipboard.writeText(text)
      .then(() => message.info('Link copied to clipboard',1))
      .catch(err => console.error('Copy failed:', err));
  }
  return (
    <>
     {type === "Available" && props.hash.hashId && <div className="flex flex-1 justify-between">
        <div>
          <span className="flex space-x-3 items-center pn-2">
            <h6 className="mb-0">{`#${props.hash.title.replace(/ /g, "")}`}</h6>
            <span className="text-xs">
              {` - `}
              {item.author[0]?<Link to={"/" + item.author[0]["userName"]}>
                {item.author[0]["name"]}
              </Link>:"Admin"}
            </span>
          </span>
          <span className="text-xs text-muted px-1">
            {item.minted
              ? `Minted on ${moment
                  .unix(props.hash.updatedAt / 1000)
                  .format("MMM DD, YYYY ")}`
              : `Added on ${moment
                  .unix(props.hash.createdAt)
                  .format("MMM DD, YYYY ")}`}
          </span>
          <div className="py-2"></div>
          <span className="text-md py-2 mb-2 font-semibold px-2">
          <div className="flex flex-col  py-0 items-start">
                  <span className="flex flex-row">
                  <span className="font-semibold  bg-gray-100">Price</span>
                  
              <span className="flex flex-row items-center ml-1">
              <HashIcon />
              <span className="flex-grow ml-1">{item["defaultPrice"]}</span>
            </span></span>
            <span className="flex flex-row">
              <span className="font-semibold  bg-gray-100">Available Fractions - </span>
              
          <span className="flex flex-row items-center ml-1">
          
          <span className="flex-grow ml-1">{item["fractionValues"]}</span>
        </span></span>
            <span className="flex flex-row text-xs mx-0 py-2">Gas Fees - <HashIcon /> {` ${HASH_DEFAULT_GASFEE}`}</span>
           
                  </div>
          </span>
            
          
            <div className="flex flex-row space-x-3 py-2">
              <Button
                loading={processOn}
                onClick={buyHash}
                className="px-3 brd-4 fw-700  rounded-lg text-white bg-hs-pink  hover:border-hs-pink"
              >
                Buy Fraction
              </Button>
            </div>
            
          
        </div>
      </div>}
      {type === "Your Fractions" && <div className="flex flex-1 justify-between">
        <div>
          <span className="flex space-x-3 items-center pn-2">
            <h6 className="mb-0">{`#${item.hasInfo.title.replace(/ /g, "")}`}</h6>
            {/* <span className="text-xs">
              {` - `}
              <Link to={"/" + item.author[0]["userName"]}>
                {item.author[0]["name"]}
              </Link>
            </span> */}
          </span>
          <span className="text-xs text-muted px-1">
            {item.createdAt
              ? `Minted on ${moment
                  .unix(props.hash.updatedAt)
                  .format("MMM DD, YYYY ")}`
              : `Added on ${moment
                  .unix(props.hash.createdAt)
                  .format("MMM DD, YYYY ")}`}
          </span>
          <div className="py-2"></div>
          <span className="flex flex-row">
              <span className=" bg-gray-100 text-hs-dark font-semibold w-50">Collected Fractions: </span>
              
          <span className="flex flex-row items-center ml-2 pl-4">
          
          <span className="flex-grow ml-1">{item["collectedFraction"]}</span>
        </span></span>
        <div className="py-2"></div>

        <span className="flex flex-row">
                  <span className="text-hs-dark font-semibold w-50 bg-gray-100">Pucrhased Value:</span>
                  
              <span className="flex flex-row items-center ml-2 pl-4">
              <HashIcon />
              <span className="flex-grow ml-1">{item["chargedTokens"]}</span>
            </span></span>
        <div className="py-2"></div>

            <span className="flex flex-row">
                  <span className="text-hs-dark font-semibold  bg-gray-100">Affiliation Link:</span>
                  
              <span className=" items-center ml-2 pl-4">
              
              <Tooltip title="Click to copy" placement="right"><span className="flex-grow ml-1 text-hs-dark cursor-pointer" onClick={()=>copyLink(item["affiliateLink"])}>{item["affiliateLink"]}</span></Tooltip>
            </span></span>
            <div className="py-2"></div>
        
        </div>
      </div>}
      {!item && <div></div>}
      {showModal && (
        <MentionRequestModal
          event={modalEvent}
          user={props.user}
          userTokens={props.userTokens}
          showModalFirst={showModal}
          callback={setshowModal}
          cancelCallBack={cancelCalled}
          forFraction={true}
        />
      )}
    </>
  );
};

export default HashtagFraction;
