import { Row, Col, Input, Button } from "antd";
import { AuthContext } from "app/AuthProvider";
import React, { useContext, useEffect } from "react";
import { Container } from "react-bootstrap";
import { ToastProvider } from "react-toast-notifications";
import LeftMenu from "./LeftMenu";
import SettingsBlacklistPhrases from "./SettingsBlacklistPhrases";
import SettingsBranding from "./SettingsBranding";
import SettingsContentTemplates from "./SettingsContentTemplates";
import SettingsDateTime from "./SettingsDateTime";
import SettingsDefault from "./SettingsDefault";
import SettingsScheduleTemplates from "./SettingsScheduleTemplates";
import SettingsSocialProfiles from "./SettingsSocialProfiles";
import SettingsWorkspaceDetails from "./SettingsWorkspaceDetails";
import { GoogleOAuthProvider ,GoogleLogin,useGoogleLogin} from '@react-oauth/google';
import { useParams } from "react-router-dom";
const AllSettings = (props) => {
  const { authUser, activeWorkSpace,setactiveWorkSpace,setAuthUser } = useContext(AuthContext);
  const brPrams = useParams();
  useEffect(() => {
    return () => {};
  }, []);
  return (
    <>
      <ToastProvider>
        <Container className=" bg-white flex-1 h-100  px-2 py-1" fluid>
          <Row gutter={16} className="h-100">
            <Col span={5} className="mb-0 px-0 bg-white   border-l-2">
              <Col span={24} className="py-0 px-0 mb-0">
                <div className="bg-white p-3 px-3 flex space-x-3 items-center">
                  <span className="font-bold">{activeWorkSpace["name"]}</span>
                  <Button></Button>
                </div>
              </Col>
              <Col span={24} className="px-0  bg-white">
                <LeftMenu
                  user={authUser}
                  activeWorkSpace={activeWorkSpace}
                  setactiveWorkSpace={setactiveWorkSpace}
                  active={brPrams.option}
                  setAuthUser={setAuthUser}
                />
              </Col>
            </Col>
            <Col span={19} className="mb-0 px-0 bg-white border-l-2">
            {brPrams.option === "campaigns" && <SettingsDefault user={authUser} activeWorkSpace={activeWorkSpace} />}
            {brPrams.option === "social-profiles" && <SettingsSocialProfiles user={authUser} activeWorkSpace={activeWorkSpace} />}
            {brPrams.option === "dates-times" && <SettingsDateTime user={authUser} activeWorkSpace={activeWorkSpace} />}
            {brPrams.option === "schedule-templates" && <SettingsScheduleTemplates user={authUser} activeWorkSpace={activeWorkSpace} />}
            {brPrams.option === "content-templates" && <SettingsContentTemplates user={authUser} activeWorkSpace={activeWorkSpace} />}
            {brPrams.option === "branding" && <SettingsBranding user={authUser} activeWorkSpace={activeWorkSpace} />}
            {brPrams.option === "blacklist-phrases" && <SettingsBlacklistPhrases user={authUser} activeWorkSpace={activeWorkSpace} />}
            {brPrams.option === "workspace-details" && <GoogleOAuthProvider clientId="65930754957-030hmaepato0hpjqdnkmrrnbslo2c1c4.apps.googleusercontent.com"><SettingsWorkspaceDetails user={authUser} activeWorkSpace={activeWorkSpace} /></GoogleOAuthProvider>}
            </Col>
          </Row>
        </Container>
      </ToastProvider>
    </>
  );
};
export default AllSettings;
