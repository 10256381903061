import React from 'react'

const VisualDisplay = (props) => {
    console.log(props.visual);
    return (
        <>
        {props.visual.type === "video/mp4"?
        <div className="mt-2 rounded-xl overflow-hidden flex justify-center w-full h-64 relative" >
            <div className="bg-center bg-contain bg-no-repeat absolute flex items-center filter blur-sm -m-5 justify-center blurVisualImage">
                <video src={props.visual.url} muted  />
            </div>
            <div className="w-full h-full bg-center bg-contain bg-no-repeat absolute flex items-center justify-center">
                <video src={props.visual.url} controls className="h-full w-auto" />
            </div>
        </div>
        :
        <div className="mt-2 rounded-xl overflow-hidden flex justify-center w-full h-64 relative" >
                <div className="bg-center bg-cover bg-no-repeat absolute filter blur-sm -m-5 blurVisualImage" style={{backgroundImage:`url(${props.visual.url})`}}></div>
                <div className="w-full h-full bg-center bg-contain bg-no-repeat absolute " style={{backgroundImage:`url(${props.visual.url})`}}></div>
                
        </div>}
        </>
    )
}

export default VisualDisplay
