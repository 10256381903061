
import { Button, DatePicker, Input, InputNumber } from "antd";
import { HASH_DEFAULT_GASFEE } from "constants/hashkeys.constant";
import AutoCloseAlert from "container/common/AutoCloseAlert";
import BuyHashCard from "container/common/BuyHashCard";
import Utils from "helper/Utils";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import InputGroupWithExtras from "react-bootstrap/esm/InputGroup";
import ApiService from "services/ApiService";
const MentionRequestModal = ({
    showModalFirst,
    callback,
    cancelCallBack,
    profiles,
    user,
    event,forFraction=false,userTokens
  }) => {
    const [show, setshow] = useState(showModalFirst);
    const [processOn, setprocessOn] = useState(false);
    const [mentionHandle, setmentionHandle] = useState("")
    const [endDate, setendDate] = useState(0)
    const [showAlert, setshowAlert] = useState(null)
    const [hashItem, sethashItem] = useState(event.item)
    const [selectedFractions, setselectedFractions] = useState(1)
    const [needMoreTokens, setneedMoreTokens] = useState(false)
    const [textMsg, settextMsg] = useState()
    const [finalCharge, setfinalCharge] = useState(0)
    const [showBuyView, setshowBuyView] = useState(false)
    const [prceedBuy, setprceedBuy] = useState(true)
    const [buyValue, setbuyValue] = useState(0);
    const [userTokensOg, setuserTokensOg] = useState(userTokens?userTokens["hashTokens"]:0)

    useEffect(() => {
      
      let finalPrice = event.item["defaultPrice"];
      if(selectedFractions > 0){
        const pricePerFraction = event.item["defaultPrice"]/event.item["fractionValues"]
        finalPrice = parseInt(pricePerFraction * selectedFractions);
        
      }
      setneedMoreTokens((finalPrice+HASH_DEFAULT_GASFEE)>userTokensOg?true:false)
      setfinalCharge(finalPrice+HASH_DEFAULT_GASFEE)
      settextMsg (`Your account will be dedcucted for ${finalPrice+HASH_DEFAULT_GASFEE} Hashs tokens.`);
      return () => {
        
      }
    }, [])
    
    const onChangeDate = (date, dateString)=>{
        if(date){
            if(date.unix() > event.item.endDate && event.item.endDate !== 0){
                setshowAlert({message:"End date can't be greater than hash end date.",type:"error",duration:2000})
            }else{
            setendDate(date.unix());
        }
        }
        else{
            setendDate(0);
        }
        
        
      }
      
    const confirmMention = async () => {
     
        const data = {offer:{mentionHandle:mentionHandle, endDate:endDate},mintedBy:hashItem["mintedBy"], hashId:event.item["hashId"],listingType:hashItem["listingType"],listingEnddate:hashItem["listingEnddate"]}
        const added = await ApiService.PostApiCalls("keyhash/listing/request",{payload:data},true)
        if(added[0] && added[1]["IsSuccessful"]){
            Utils.showNotification(
                "success",
                "Done",
                added[1]["FriendlyMessage"]
              );  
              sethashItem({...hashItem,requestedOptions:data,listingStatus:"pending"}) 
              cancelCallBack({...hashItem,requestedOptions:data,listingStatus:"pending"})

        }
        else{
            if(!added[1]["IsSuccessful"]){
                Utils.showNotification("error", "Oopps", added[1]["ErrorMessage"]);
            }
            else{
                Utils.showNotification("error", "Oopps", "Something went wrong");
            }
            
        }
    };
    const updateFractions =(e) =>{
      setselectedFractions(e)
      let finalPrice = event.item["defaultPrice"];
      if(e > 0){
        const pricePerFraction = event.item["defaultPrice"]/event.item["fractionValues"]
        finalPrice = parseInt(pricePerFraction * e);
        
      }
      setneedMoreTokens((finalPrice+HASH_DEFAULT_GASFEE)>userTokensOg?true:false)
      setfinalCharge(finalPrice+HASH_DEFAULT_GASFEE)
      setbuyValue((finalPrice+HASH_DEFAULT_GASFEE)-userTokensOg)
      settextMsg (`Your account will be dedcucted for ${finalPrice+HASH_DEFAULT_GASFEE} Hashs tokens.`);
      

    }
    const confirmBuy = async() =>{
        const data = {selectedFractions:selectedFractions,charge:finalCharge,hash:event["item"]}
        
        const res = await ApiService.PostApiCalls("keyhashes/buyfractions",data,true);
        if(res[0] && res[1]["IsSuccessful"]){
          Utils.showNotification(
            "success",
            "Done",
            res[1]["FriendlyMessage"]
          );  
          setshow(false);
          cancelCallBack(null,true);
          
          // window.location.reload();
        }
        else{
          if(!res[1]["IsSuccessful"]){
            Utils.showNotification("error", "Oopps", res[1]["ErrorMessage"],3);
            if(res[1]["code"] === 100){
              setneedMoreTokens(true)
            }
           
          }
          else{
              Utils.showNotification("error", "Oopps", "Something went wrong",3);
          }
        }
    }
    const updateBuyBack = (val) =>{
      let finalPrice = event.item["defaultPrice"];
      const pricePerFraction = event.item["defaultPrice"]/event.item["fractionValues"]
        finalPrice = parseInt(pricePerFraction * selectedFractions);
      setuserTokensOg((val+userTokensOg))
      setshowBuyView(false)
      setneedMoreTokens(false)
    }
    return (
        <>
      <Modal
        show={show}
        onHide={() => {
          setshow(false);
          callback(false);
        }}
        centered size="lg"
        contentClassName="campaign-new-modal rounded-2xl p-0"
        backdrop={"static"}
      >
       <Modal.Header className="mp-2 p-2 border-bottom ">
          <div className="flex flex-row w-full justify-between space-x-4 font-semibold items-center p-2">
             {forFraction?<span>{`Buy fractions - ${event.item["title"]}`}</span>: <span>{`Request mention for - ${event.item["title"]}`}</span>}
              
              
            </div>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column pt-0">
        <div className="py-3"></div>
        <div className="flex flex-row w-full justify-between space-x-4 font-semibold items-center">
        {forFraction? <>{showBuyView?
        <div className="flex flex-col w-100 space-y-3 p-0">
            
            
        <BuyHashCard backCallback={()=>{setshowBuyView(!showBuyView);setprceedBuy(true)}}   defaultValue={buyValue} tokens={buyValue} successCallback={updateBuyBack} />
       </div>
        
        :<div className="flex flex-col space-y-4 px-3">
            <span className="flex flex-row space-x-3 items-center">
              <span>Available Fractions
                :</span>
              <span>{event.item["fractionValues"]}
                </span>
              </span>
              <span className="flex flex-row space-x-3 items-center">
              <span>Price
                :</span>
              <span>{event.item["defaultPrice"]}
                </span>
              </span>
              <span className="flex flex-row space-x-3 items-center">
              <span>Select
                :</span>
              <span>
              <InputNumber value={selectedFractions} min={1} max={event.item["fractionValues"]} onChange={(e)=>updateFractions(e)} /></span>
              </span>
              <span className="text-sm font-light">{textMsg}</span>
              <span className="flex flex-row space-x-3 items-center">
              <span>Your Balance
                :</span>
              <span>{userTokensOg}
                </span>
              </span>
          </div>}</>
          :<div className="flex flex-col space-y-4 px-3">
            <span className="flex flex-row space-x-3 items-center">
              <span>Your mention handle
                :</span>
              <span className="flex flex-col pb-2"><Input prefix={<span>@</span>} placeholder="Add one here" value={mentionHandle} onChange={e=>setmentionHandle(e.target.value.trim())} />
              <span className='muted text-xs text-muted mt-2'>* Only for X.com, add valid X.com handles.</span></span>
              
              </span>
              <span className="flex flex-row space-x-3 items-center">
              <span>End date
                :</span>
              <span>
              <DatePicker onChange={onChangeDate} disabledDate={Utils.disabledDate} /></span>
              </span>
          </div>}
        </div>

        {showAlert && <div className="py-3 ">
            <AutoCloseAlert message={showAlert["message"]} type={showAlert["type"]} duration={showAlert["duration"]} callback={()=>setshowAlert(null)} />
            </div>}
        <div className="py-3"></div>
        </Modal.Body>
        {!showBuyView && <Modal.Footer className="border-top py-2 px-3 justify-end space-x-4">
          {
            needMoreTokens && <Button
            type="primary"
            loading={processOn}
            onClick={()=>{setshowBuyView(!showBuyView);}}
            
            className="px-3 brd-4 fw-700 mx-2 rounded-lg border-0 flex items-center"
          >
            Buy Hashs
          </Button>
          }
          {forFraction?<Button
            type="primary"
            loading={processOn}
            onClick={() => confirmBuy()}
            disabled={needMoreTokens}
            className="px-3 brd-4 fw-700 mx-2 rounded-lg border-0 flex items-center"
          >
            Confirm Request
          </Button>:<Button
            type="primary"
            loading={processOn}
            onClick={() => confirmMention()}
            disabled={endDate === 0 ?true:mentionHandle.trim() === ""?true:false}
            className="px-3 brd-4 fw-700 mx-2 rounded-lg border-0 flex items-center"
          >
            Confirm Mention Request
          </Button>}
  
          <Button
            // size="large"
            onClick={() => {
              setshow(false);
              cancelCallBack();
            }}
            className="px-3 brd-4 fw-700 mx-2 rounded-lg hover:text-hs-pink hover:border-hs-pink"
          >
            Cancel
          </Button>
        </Modal.Footer>}
      </Modal>
      </>
    );
}

export default MentionRequestModal;
