import React from 'react'

export const Review = (props) => {
    return (
        <svg
      xmlns="http://www.w3.org/2000/svg"
      width={13.784}
      height={18.226}
      viewBox="0 0 13.784 18.226"
      {...props}
    >
      <defs>
        <style>
          {".prefix__av{fill:#1694e3;stroke:#1694e3;stroke-width:.5px}"}
        </style>
      </defs>
      <path
        className="prefix__av"
        d="M11.588 1.45H8.739a2.019 2.019 0 00-3.689 0H2.197A1.949 1.949 0 00.25 3.4v12.629a1.949 1.949 0 001.947 1.947h9.391a1.949 1.949 0 001.947-1.947V3.4a1.949 1.949 0 00-1.947-1.95zm-6.222.89a.443.443 0 00.433-.348 1.126 1.126 0 012.187 0 .443.443 0 00.433.348h.879v.389a1.062 1.062 0 01-1.06 1.06H5.55a1.062 1.062 0 01-1.06-1.06V2.34zm7.284 13.689a1.061 1.061 0 01-1.06 1.06H2.197a1.062 1.062 0 01-1.06-1.06V3.4a1.062 1.062 0 011.06-1.06h1.4v.389A1.949 1.949 0 005.55 4.675h2.687a1.949 1.949 0 001.946-1.946V2.34h1.4A1.062 1.062 0 0112.65 3.4zm0 0"
      />
      <path
        className="prefix__av"
        d="M9.087 8.438l-2.909 2.84-1.477-1.442a.443.443 0 00-.625.628l.006.006 1.787 1.745a.443.443 0 00.619 0l3.219-3.143a.443.443 0 00-.619-.634zm0 0"
      />
    </svg>
    )
}
