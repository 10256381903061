import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import ApiService from "services/ApiService";
import CampaignCard from "./CampaignCard";

const DashboardListView = ({ type, activeWorkSpace,rv }) => {
  const [loading, setloading] = useState(true);
  const [data, setdata] = useState([]);
  useEffect(() => {
    loadContent();
    return () => {
      setdata([]);
    };
  }, [type]);
  const loadContent = async () => {
    const data = {
      wid: activeWorkSpace["wid"],
      state: type === 0 ? "active" : "inprocess",
      limit: 4,
    };
    const content = await ApiService.PostApiCalls(
      "creator/homeContent",
      data,
      true
    );
    setdata(content[1]["campaigns"]);
    setloading(false);
  };
  
  return (
    <div className="grid grid-cols-4 gap-5 ">
      {data.map((feed, key) => (
        <div key={key} className="p-2 bg-white border-1 shadow rounded-xl">
          <CampaignCard
            slug={activeWorkSpace["slug"]}
            content={feed}
            id={key}
            type={1}
            rv={rv} 
          />
        </div>
      ))}
    </div>
  );
};

export default DashboardListView;
